import type { Market } from '@oms/generated/frontend';
import type {
  AdvancedSelectQueryFn,
  AdvancedSelectQueryPayload,
  ComboBoxItem
} from '@oms/frontend-foundation';
import { map } from 'rxjs';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { MarketsDocument, type MarketsQuery } from '@oms/generated/frontend';

type MarketFragment = Omit<Market, 'nodeId' | 'tradeCounterpartiesByMicCode'>;

export const watchActiveMarketsQuery: AdvancedSelectQueryFn<MarketFragment> = (container) => {
  const apolloClientRPC = container.resolve(ApolloClientRPC);
  return {
    type: 'watchAll',
    preLoad: true,
    query: () => {
      const observable = apolloClientRPC
        .watchQuery<MarketsQuery>({
          query: MarketsDocument,
          fetchPolicy: 'cache-and-network'
        })
        .pipe(
          map((data) => {
            const comboBoxItems = data?.data?.markets?.nodes.map((market) => {
              const comboItem: ComboBoxItem<MarketFragment> = {
                id: market.exchangeMic || '',
                label: market.exchangeMic || '',
                type: 'item',
                value: { exchangeMic: market.exchangeMic || '', id: market.id || '' }
              };
              return comboItem;
            });
            const shape: AdvancedSelectQueryPayload<MarketFragment> = {
              isFetching: false,
              results: comboBoxItems
            };
            return shape;
          })
        );
      return observable;
    }
  };
};

export default watchActiveMarketsQuery;
