import type { VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { FormSummary } from '@oms/frontend-foundation';
import { useMemo } from 'react';
import { type OrderSummaryFieldName, orderSummaryData } from '@app/common/summaries/order-fields';

const orderFields: OrderSummaryFieldName[] = [
  'id',
  'clientOrderId',
  'sideType',
  'locate',
  'quantity',
  'notionalAmount',
  'investorAccount.name',
  'investorAccount.accountType',
  'limitPrice',
  'stopPrice',
  'orderType',
  'orderEntryType',
  'capacity',
  'workflow',
  'timeInForce',
  'expiryDateTime',
  'updatedTime',
  'lastExecutionTime',
  'orderTags',
  'status',
  'transmittedTimestamp',
  'captureTimestamp',
  'validatedTimestamp',
  'createdTimestamp',
  'receivedTimestamp',
  'handlingInstruction',
  'enteredBy.email',
  'owner',
  'workingQuantity',
  'openQuantity',
  'executedQuantity',
  'leavesQuantity',
  'averagePrice',
  'instrument.mappings.displayCode',
  'customerNotes',
  'representativeCode.code',
  'orderCharges',
  'fixWorkflowId',
  'todayExecutedQuantity',
  'todayAveragePrice'
];

const settleFields: OrderSummaryFieldName[] = ['tradeCurrency', 'settleCurrency', 'settleType', 'settleDate'];

export const allOrderFields = [...orderFields, ...settleFields];

export const InvestorOrderDetailsSummary = ({
  investorOrder
}: {
  investorOrder: VisibleInvestorOrderInfoWithAllocationsFragment;
}) => {
  const data = useMemo(() => {
    return [
      orderSummaryData('ORDER', orderFields, investorOrder),
      orderSummaryData('SETTLE', settleFields, investorOrder)
    ];
  }, [investorOrder]);

  return <FormSummary labeledData={data} />;
};
