// TODO: Note, using multiple packages across both apps seems to cause some type issues in rxdb hooks
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Provider } from 'rxdb-hooks-v5';
import { type ReactWindowActorWrapperComp } from '@valstro/workspace-react';
import { ToolbarProvider } from '@app/common/toolbar/toolbar-context';
import { ErrorBoundary } from '@app/containers/error-boundary/error-boundary.container';
import { useOfflineDatabase } from '@app/data-access/offline/offline-database';
import { ConditionalWrapper } from '@oms/shared-frontend/ui-design-system';
import { LauncherFrameContainer } from '@app/containers/launcher-frame/launcher-frame.container';
import { IS_BROWSER } from '@app/common/workspace/workspace.constants';

export const RootWindowWrapper: ReactWindowActorWrapperComp = ({ children }) => {
  const offlineDatabase = useOfflineDatabase();

  return (
    <ConditionalWrapper
      condition={IS_BROWSER}
      wrapper={(c) => <LauncherFrameContainer showShortcut={true}>{c}</LauncherFrameContainer>}
    >
      <ErrorBoundary>
        <ToolbarProvider>
          <Provider db={offlineDatabase as any}>{children}</Provider>
        </ToolbarProvider>
      </ErrorBoundary>
    </ConditionalWrapper>
  );
};
