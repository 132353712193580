/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { forwardRef, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useFormControl, FormControlProps } from '../form-control/form-control';
import * as styles from './css/checkbox-button.css';
import type { CheckboxButtonVariants } from './css/checkbox-button.css';
import { inputOffsetOutline } from '../../system/styles.css';
import { useControllableState } from '../../hooks/use-controllable';
import { Box } from '../../system/components/box/box';
import { dataAttr } from '../../system/utils/dom';
import { __DEV__ } from '../../system/utils/assertion';
import { CheckIcon } from '@radix-ui/react-icons';
import { getBase } from '../../theme/foundations/colors';

type OmittedProps = 'isRequired' | 'as' | 'value' | 'label';

export type UniqueCheckboxButtonProps = {
  isDefaultChecked?: boolean;
  isChecked?: boolean;
  id?: string;
  name?: string;
  value?: string | number;
  /**
   * A11y: A label that describes the input
   */
  'aria-label'?: string;
  /**
   * A11y: The id of the element that describes the input
   */
  'aria-describedby'?: string;
  /**
   * A11y: Refers to the id of the element that labels the checkbox-button element.
   */
  'aria-labelledby'?: string;
  /**
   * The children is the label to be displayed to the right of the checkbox-button.
   */
  children?: React.ReactNode;
  onChange?: (checked: boolean) => void;
  tabIndex?: number;
  /**
   * By default the checkbox input is hidden
   */
  isCheckboxVisible?: boolean;
  label?: string | string[];
  testId?: string;
};

export type CheckboxButtonProps = UniqueCheckboxButtonProps &
  CheckboxButtonVariants &
  Omit<FormControlProps, OmittedProps>;

export const CheckboxButton = React.memo(
  forwardRef<HTMLInputElement, CheckboxButtonProps>((props, ref) => {
    const {
      id,
      name,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      'aria-describedby': ariaDescribedby,
      isDefaultChecked,
      isChecked,
      size = 'sm',
      onChange,
      children,
      className,
      tabIndex,
      isInvalid: _isInvalid,
      isReadOnly: _isReadOnly,
      sx = {},
      isCheckboxVisible,
      label,
      value,
      testId,
      ...rest
    } = props;

    const { isDisabled, isInvalid, isReadOnly } = useFormControl(props);

    const [isCheckedState, setIsCheckedState] = useControllableState<boolean>({
      value: isChecked,
      defaultValue: isDefaultChecked || false,
      onChange
    });

    const onChangeCallback = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        if (setIsCheckedState) {
          setIsCheckedState(checked);
        }
      },
      [setIsCheckedState]
    );

    const labels = useMemo(() => {
      if (!label) {
        return null;
      }
      const labels = Array.isArray(label) ? label : [label];
      const withPrimary = labels.length > 1;
      return labels.map((label, index) => {
        const style = styles.checkboxButtonLabelRecipe({
          size,
          type: withPrimary ? (index === 0 ? 'primary' : 'secondary') : undefined
        });
        return (
          <span key={`${index}`} className={clsx(style)}>
            {label}
          </span>
        );
      });
    }, [label, size]);

    return (
      <Box
        as="label"
        sx={{
          ...sx
        }}
        className={clsx(
          isCheckedState && styles.checkboxButtonChecked,
          styles.checkboxButtonRecipe(),
          isDisabled && styles.checkboxButtonDisabled,
          className
        )}
        data-testid={testId}
        {...rest}
      >
        <Box
          as="input"
          type="checkbox"
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          aria-describedby={ariaDescribedby}
          id={id}
          ref={ref}
          name={name}
          value={value}
          tabIndex={tabIndex}
          onChange={isReadOnly ? undefined : onChangeCallback}
          checked={isCheckedState}
          disabled={isDisabled}
          aria-disabled={dataAttr(isDisabled)}
          readOnly={isReadOnly}
          aria-readonly={dataAttr(isReadOnly)}
          aria-invalid={dataAttr(isInvalid)}
          aria-checked={isCheckedState}
          className={clsx(
            'check-input',
            styles.checkboxButtonCheckboxRecipe({ size, visible: isCheckboxVisible }),
            inputOffsetOutline
          )}
        />
        <Box
          sx={{
            borderRadius: 'full',
            backgroundColor: isCheckedState ? 'icons.active' : 'transparent',
            borderColor: isCheckedState ? 'transparent' : 'white',
            borderStyle: 'solid',
            borderWidth: '1px'
          }}
        >
          <CheckIcon color={isCheckedState ? getBase('BlueGrey', 1000) : 'transparent'} />
        </Box>
        {labels && <span className={clsx(styles.checkboxButtonLabelRecipe({ size }))}>{labels}</span>}
        {children && <span className={clsx(styles.checkboxButtonLabelRecipe({ size }))}>{children}</span>}
      </Box>
    );
  })
);

if (__DEV__) {
  CheckboxButton.displayName = 'CheckboxButton';
}
