import React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import type {
  TabsContentProps as RadixTabsContentProps,
  TabsListProps as RadixTabsListProps,
  TabsTriggerProps as RadixTabsTriggerProps,
  TabsProps as RadixTabsProps
} from '@radix-ui/react-tabs';
import { Box } from '../../system/components/box/box';
import type { BoxProps } from '../../system/components/box/box';
import clsx from 'clsx';
import * as styles from './tabs.css';

// TODO: Implement vertical tabs

export type TabsProps<TValue extends string = string> = {
  defaultValue?: TValue;
  orientation?: 'horizontal' | 'vertical';
} & Omit<RadixTabsProps, 'orientation' | 'defaultValue'> &
  BoxProps;

export const Tabs = React.forwardRef<HTMLDivElement, TabsProps>(({ children, className, ...props }, ref) => {
  return (
    <TabsPrimitive.Root asChild={true} ref={ref} {...props}>
      <Box className={clsx(styles.tabsContent, className)} style={props.style}>
        {children}
      </Box>
    </TabsPrimitive.Root>
  );
});

export const TabTrigger = React.forwardRef<
  HTMLButtonElement,
  RadixTabsTriggerProps & { variant?: 'primary' | 'secondary' }
>(({ asChild = false, variant = 'primary', className, children, ...rest }, ref) => {
  const variantClass = {
    [styles.tabTrigger]: variant === 'primary',
    [styles.tabTriggerSecondary]: variant === 'secondary'
  };

  return (
    <TabsPrimitive.Trigger
      ref={ref}
      asChild={asChild}
      className={clsx(!asChild && variantClass, className)}
      {...rest}
    >
      {children}
    </TabsPrimitive.Trigger>
  );
});

export type TabsListProps = {} & RadixTabsListProps & BoxProps & { variant?: 'primary' | 'secondary' };

export const TabsList = React.forwardRef<HTMLDivElement, TabsListProps>(
  ({ children, className, variant = 'primary', ...props }, ref) => {
    const variantClass = {
      [styles.tabsList]: variant === 'primary',
      [styles.tabsListSecondary]: variant === 'secondary'
    };

    return (
      <TabsPrimitive.List asChild={true} ref={ref} {...props}>
        <Box className={clsx(variantClass, className)}>{children}</Box>
      </TabsPrimitive.List>
    );
  }
);

export type TabContentProps = {} & RadixTabsContentProps & BoxProps;

export const TabContent = React.forwardRef<HTMLDivElement, TabContentProps>(
  ({ children, sx = {}, className, ...props }, ref) => {
    const { p, padding } = sx;
    const pad = p !== undefined ? p : padding !== undefined ? padding : 0;
    return (
      <TabsPrimitive.Content asChild={true} ref={ref} {...props}>
        <Box sx={{ ...sx, p: pad }} className={clsx(styles.tabsContent, className)}>
          {children}
        </Box>
      </TabsPrimitive.Content>
    );
  }
);
