import type { Field } from '@data-driven-forms/react-form-renderer';
import type { FormOptions, ISelectField } from '@oms/frontend-foundation';
import { type RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';

export const ROUTE_ORDER_INVESTOR_ORDER_IS_SIDE_ENABLED = 'routeOrderInvestorOrderIsSideEnabled';

export const routeOrderInvestorOrderIsSideEnabled =
  () =>
  (_props: Field, _field: Field, formOptions: FormOptions): Partial<ISelectField> => {
    const formValues: Partial<RouteOrderFormValues> = formOptions.getState().values;
    const { hiddenFormInfo } = formValues || {};

    switch (hiddenFormInfo?.type) {
      case 'modify':
      case 'route': {
        return {
          isDisabled: true,
          forceIsDisabled: true
        };
      }
      default:
        return {};
    }
  };
