import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import type {
  AnyRecord,
  ComboBoxItem,
  FieldContract,
  IAdvancedSelectField,
  ModifiedFieldForOptions
} from '@oms/frontend-foundation';
import type { InvestorAccountFragment } from '@oms/generated/frontend';
import type { AccountComboBoxValue } from './account.field.queries';

export const createAccountField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedSelectField<TOutputContract[TFieldKey]>>
) => {
  return fc.field(key, 'advanced-select').advancedSelectOptions<AccountComboBoxValue>({
    autoSizeWidthToTrigger: false,
    width: 200,
    label: 'Account',
    query: ADVANCED_SELECT_QUERY_ENUM.VISIBLE_ACCOUNTS_WATCH_QUERY,
    ...overrides
  });
};

export const createAccountComboboxItem = <TValue>(
  account: Partial<InvestorAccountFragment>,
  useIdOnly?: boolean
): ComboBoxItem<TValue> => {
  return {
    type: 'item',
    id: account?.id || '',
    label: account.name || '',
    value: (useIdOnly ? account?.id || '' : account) as TValue
  };
};
