import { type MultiSelectListControl } from '@valstro/fixatdl-core';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { type ControlTransformer, getListItems } from './control.transformer.types';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const multiSelectListControl: ControlTransformer<MultiSelectListControl> = (
  multiSelectListControl,
  _query,
  formValues
) => {
  return {
    component: fixatdlFormComponentTypes.MULTI_SELECT_LIST,
    name: multiSelectListControl.id,
    initialValue: AtdlFormats.coerceArray(formValues[multiSelectListControl.id], { format: 'array' }),
    label: multiSelectListControl.label,
    options: getListItems(multiSelectListControl.listItems)
  };
};
