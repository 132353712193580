import { Box } from '@oms/shared-frontend/ui-design-system';
import { COMMAND_RENDER_REGISTRY } from './command.render.registry';
import { type CommandRenderContext } from './command.render.strategy.types';
import { RocketIcon } from '@radix-ui/react-icons';

/**
 * Allows customization of what gets rendered inside of an action by command id.
 * @param ctx An object that provides context when rendering the content inside an action.
 * @returns What should be rendered inside the action in the ui.
 */
export const renderCommand = (ctx: CommandRenderContext) => {
  const { actionContext$, ...action } = ctx;
  const { confirmation, label } = action;
  const commandStrategy = COMMAND_RENDER_REGISTRY[action.commandId];

  return commandStrategy ? (
    commandStrategy(ctx)
  ) : (
    <Box
      as={'span'}
      style={{
        overflow: 'hidden',
        width: '100%',
        textAlign: 'center',
        lineHeight: 1,
        whiteSpace: 'normal',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3
      }}
    >
      {!confirmation && (
        <>
          <RocketIcon style={{ display: 'inline', verticalAlign: 'text-bottom' }} />
          &nbsp;
        </>
      )}
      {label}
    </Box>
  );
};
