import { useCallback } from 'react';
import type { AppSnapshot } from '@app/app-config/snapshot-layout.config';
import { useAppWorkspace } from '../workspace/workspace.hooks';
import { applyAppSnapshot, takeAppSnapshot } from './snapshot-layout.util';

export function useTakeAppSnapshot() {
  const appWorkspace = useAppWorkspace();
  return useCallback(() => takeAppSnapshot(appWorkspace), [appWorkspace]);
}

export function useApplyAppSnapshot() {
  const appWorkspace = useAppWorkspace();
  return useCallback(
    (snapshot: Partial<AppSnapshot>) => applyAppSnapshot(appWorkspace, snapshot),
    [appWorkspace]
  );
}
