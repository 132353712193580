import { FieldDefinition } from '@oms/frontend-foundation';
import { type ICoverageUserFieldOptions } from './coverage-user-field.component';

export const createCoverageUserField = (name: string, options: ICoverageUserFieldOptions) => {
  return FieldDefinition.box(
    `${name}-wrapper`,
    [
      {
        name,
        component: 'coverage-user',
        ...options
      }
    ],
    {
      sx: {
        marginLeft: 'small',
        marginTop: 'small',
        alignSelf: 'center'
      }
    }
  );
};
