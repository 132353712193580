import type{ ValidatorMapper } from '@data-driven-forms/react-form-renderer';
import type { FoundationFormMappers } from '@oms/frontend-foundation';
import {
  FORM_ACTION_MAPPER,
  FORM_COMPONENT_MAPPER,
  FORM_VALIDATOR_MAPPER,
  FORM_CONDITION_MAPPER
} from '@app/forms/form-builder/mappers/form.mappers';
import { ADVANCED_SELECT_QUERY_MAP, FORM_MAP } from '@app/generated/mappers';
import { ROUTE_ORDER_VALIDATOR_MAPPER } from '@app/widgets/trading/route-order/validators/route-order-validator.mapper';
import { ROUTE_ORDER_ACTION_MAPPER } from '@app/widgets/trading/route-order/actions/route-order-action.mapper';
import {
  IO_RUNWAY_DISABLE_ACTION,
  ioRunwayDisableAction
} from './widgets/trading/investor-order-entry/actions/investor.order.runway.disable.action';
import { ORDER_SETTINGS_ACTION_MAPPER } from '@app/widgets/user/user-preferences/order-settings/actions/order-settings.action.mapper';
import { ActionCommandField } from './actions/forms/action-command/action-command.field';
import { FORM_COMPONENT_TYPE } from './forms/form-builder/common/form.contracts';
import {
  BUTTON_CONFIG_ALLOWED_COMMANDS_ACTION,
  buttonConfigAllowedCommands
} from './actions/forms/action-button/allowed-commands.action';
import { ORDER_SETTINGS_VALIDATOR_MAPPER } from '@app/widgets/user/user-preferences/order-settings/validators/order-settings-validator.mapper';

export const FORM_MAPPERS: FoundationFormMappers = {
  componentMapper: {
    ...FORM_COMPONENT_MAPPER,
    // this field needs to be imported here directly because it requires a reference to all other forms in the app and would make a circular dependency.
    [FORM_COMPONENT_TYPE.ACTION_COMMAND]: ActionCommandField
  },
  validatorMapper: {
    ...(FORM_VALIDATOR_MAPPER as ValidatorMapper),
    ...ROUTE_ORDER_VALIDATOR_MAPPER,
    ...ORDER_SETTINGS_VALIDATOR_MAPPER
  },
  actionMapper: {
    ...FORM_ACTION_MAPPER,
    ...ROUTE_ORDER_ACTION_MAPPER,
    ...ORDER_SETTINGS_ACTION_MAPPER,
    [IO_RUNWAY_DISABLE_ACTION]: ioRunwayDisableAction,
    [BUTTON_CONFIG_ALLOWED_COMMANDS_ACTION]: buttonConfigAllowedCommands
  },
  advancedSelectQueryMapper: {
    ...ADVANCED_SELECT_QUERY_MAP
  },
  conditionMapper: {
    ...FORM_CONDITION_MAPPER
  },
  formBuilderMapper: {
    ...FORM_MAP
  },
  formBuilderTemplateMapper: {}
};
