import { useCallback } from 'react';
import {
  type TradingOrderAuditTrail,
  TradingOrderAuditTrailService
} from '@app/data-access/services/trading/trading-orders/trading-order-audit-trail.service';
import type { VisibleTradingOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { useVGrid, VGrid, type GridBuilder } from '@oms/frontend-vgrid';
import { auditTrailColumnLibrary } from './trading-order-view.audit-trail.columns';
import { OfflineGridEventHandler } from '@app/data-access/services/system/grids/offline-grid.event-handler';

export const TradingOrderAuditTrailGrid = ({
  tradingOrderId
}: {
  tradingOrderId: VisibleTradingOrderInfoWithAllocationsFragment['id'];
}) => {
  const auditTrailService = useService(TradingOrderAuditTrailService);

  const builder = useCallback(
    (b: GridBuilder<TradingOrderAuditTrail>) =>
      b
        .columnLibrary(auditTrailColumnLibrary)
        .datasource((d) => d.source(auditTrailService.getAuditTrail$(tradingOrderId)).rowId((r) => r.data.id))
        .injectEvents([OfflineGridEventHandler])
        .sideBar(),
    [auditTrailService, tradingOrderId]
  );

  const props = useVGrid('trading-order-audit-trail', builder, [auditTrailService]);
  return <VGrid {...props} />;
};

export default TradingOrderAuditTrailGrid;
