import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import {
  type ModifiedFieldForOptions,
  type AnyRecord,
  type FieldContract,
  type IAdvancedMultiSelectField
} from '@oms/frontend-foundation';
import { TagTypeEnum } from '@oms/generated/frontend';

export const createTagsField = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedMultiSelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'advanced-multi-select').advancedSelectMultiOptions<string>({
    autoSizeWidthToTrigger: false,
    width: 200,
    label: 'Tags',
    query: ADVANCED_SELECT_QUERY_ENUM.TAGS_WATCH_QUERY,
    queryProps: {
      filters: [TagTypeEnum.Trade]
    },
    ...overrides
  });
