import React from 'react';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { TimePicker as TimePickerComponent } from '@oms/shared-frontend/ui-design-system';
import type { FieldProps, ICommonField } from '../../types';
import { useFieldApi } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { type Validator } from '@data-driven-forms/react-form-renderer';

export interface ITimePicker<TValidator = Validator>
  extends ICommonField<typeof FORM_COMPONENT_TYPE.TIME_PICKER, string, TValidator> {}

export const TimePicker: React.FC<FieldProps<ITimePicker>> = (props) => {
  const {
    meta,
    label,
    input,
    isVisible,
    helperText,
    isRequired,
    dataType,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    isFeatureField,
    isPrimaryField,
    requiredFieldIndicatorStyle,
    autoFocus = false,
    options,
    hideFormControls = false,
    ...rest
  } = useFieldApi<ITimePicker>(props);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  const extraProps = {
    isReadOnly,
    isRequired,
    isDisabled,
    isInvalid,
    isFeatureField,
    isPrimaryField,
    requiredFieldIndicatorStyle,
    hideFormControls
  };

  return (
    <FieldWrapper meta={meta} label={label} {...extraProps}>
      <TimePickerComponent {...input} {...rest} autoFocus={autoFocus} />
    </FieldWrapper>
  );
};
