import { map, type OperatorFunction, tap } from 'rxjs';
import { unionBy, uniqBy } from 'lodash';
import type { MontageUnboundTradingOrderFragment } from '@oms/generated/frontend';
import {
  getMontageItemTypeForSide,
  montageSortOrderComparator
} from './montage.utils';
import type { MontageItem, MontageFilter } from '../montage.types';
import type { PricePartitionMapOptions } from './price-partition-subject.class';
import type PricePartitionMapSubject from './price-partition-subject.class';

// Unbound trading order operators ------------------------------------------------ /

type UnboundTradingOrderOperator = OperatorFunction<MontageUnboundTradingOrderFragment[], MontageUnboundTradingOrderFragment[]>;

export const applyMontageFilterToOrder =
  (
    filter?: MontageFilter
  ): UnboundTradingOrderOperator =>
  (observable$) =>
    observable$.pipe(
      map((unboundTradingOrders) => {
        if (!filter) return unboundTradingOrders;
        return unboundTradingOrders.filter(
          ({ side }) => filter.type === getMontageItemTypeForSide(side, 'ask')
        );
      })
    );

// Montage item operators ------------------------------------------------ /

type MontageItemsOperator = OperatorFunction<MontageItem[], MontageItem[]>;

/** Ensures Montage items are unique by given key (defaulting to `id`) */
export const uniqueItemsBy =
  (key: keyof MontageItem = 'id'): MontageItemsOperator =>
  (observable$) =>
    observable$.pipe(map((items) => uniqBy(items, (item) => item[key])));

/** Sorts Montage items by given compare fn (defaults to Montage sort order comparator) */
export const sort =
  (compareFn = montageSortOrderComparator): MontageItemsOperator =>
  (observable$) =>
    observable$.pipe(map((items) => items.sort(compareFn)));

/** Adds targeting indices to each Montage item (defaults to using index) */
export const addTargetingIndices =
  (getTargetingIndex?: (item: MontageItem, index: number) => number): MontageItemsOperator =>
  (observable$) =>
    observable$.pipe(
      map((items) => {
        items.forEach((item, index) => {
          item.targetingIndex = getTargetingIndex?.(item, index) || index;
        });
        return items;
      })
    );

/** Updates a `PricePartitionMapSubject` for the current `MontageItem`s */
export const updatePricePartitionMap =
  (pricePartitionMap$: PricePartitionMapSubject, options?: PricePartitionMapOptions): MontageItemsOperator =>
  (observable$) =>
    observable$.pipe(
      tap((items) => {
        pricePartitionMap$.nextFromItems(items, options);
      })
    );

// Merge Montage item operators ------------------------------------------------ /

type MergeMontageItemsOperator = OperatorFunction<[MontageItem[], MontageItem[]], MontageItem[]>;

/** Merge Montage item feeds are unique by given key (defaulting to `id`) */
export const mergeMontageItemFeeds =
  (uniqueByKey: keyof MontageItem = 'id'): MergeMontageItemsOperator =>
  (observable$) =>
    observable$.pipe(map((itemFeeds) => unionBy(...itemFeeds, uniqueByKey)));
