import type { Field } from '@data-driven-forms/react-form-renderer';
import type { FormOptions, INumberFormatField } from '@oms/frontend-foundation';
import { OrderType } from '@oms/generated/frontend';
import { type RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';

export const ROUTE_ORDER_INVESTOR_ORDER_IS_PRICE_ENABLED = 'routeOrderInvestorOrderIsPriceEnabled';

export const routeOrderInvestorOrderIsPriceEnabled =
  () =>
  (_props: Field, _field: Field, formOptions: FormOptions): Partial<INumberFormatField> => {
    const formValues: Partial<RouteOrderFormValues> = formOptions.getState().values;
    const { hiddenFormInfo } = formValues || {};

    switch (hiddenFormInfo?.type) {
      case 'modify':
      case 'create':
        return {};
      case 'route': {
        const originalOrderType = hiddenFormInfo?.investorOrder?.orderType;
        switch (originalOrderType) {
          case OrderType.Market:
            return {};
          case OrderType.Limit:
            return {};
          default:
            return {};
        }
      }
      default:
        return {};
    }
  };
