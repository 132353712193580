import {
  type DisplayGridItemProps,
  type NumericValueProps,
  useControllableState,
  type CombinedDisplayFieldComponentProps
} from '@oms/shared-frontend/ui-design-system';
import { APP_DISPLAY_FIELD_COMPONENT_TYPE, AppDisplayGrid } from '@oms/frontend-foundation';
import { useCallback, useMemo } from 'react';
import type { MarketDataDisplayProps, MarketDataItemsUnion, MarketDataPayload } from './market-data.types';
import { marketDataDisplayItemsMap } from './market-data.display.util';

/**
 * MarketDataDisplay
 *
 * This component is used to display market data in any grid layout.
 * It's a simple component, without real-time data or business logic
 */
export const MarketDataDisplay: React.FC<MarketDataDisplayProps> = ({
  level1Event,
  values,
  enableTracking = true,
  trackableValues = [],
  trackableRangeValues = {},
  layout = 'vertical',
  instrumentDetails,
  orderQuantity,
  gridProps,
  onManualUpdateNumeric,
  onRealTimeUpdateNumeric,
  numericUpdateType: _numericUpdateType,
  setNumericUpdateType: _setNumericUpdateType,
  width,
  sx,
  testId
}) => {
  /**
   * Set up externally controllable state for the price update type.
   */
  const [numericUpdateType, setNumericUpdateType] = useControllableState({
    value: _numericUpdateType,
    onChange: _setNumericUpdateType,
    defaultValue: null
  });

  /**
   * Handle single click's on market data items
   */
  const handleSingleClickValue = useCallback(
    (key: MarketDataItemsUnion, value: string | number | (string | number)[]) => {
      if (!value || !trackableValues.includes(key)) {
        return;
      }

      if (setNumericUpdateType) {
        setNumericUpdateType(null);
      }

      if (onManualUpdateNumeric) {
        onManualUpdateNumeric(key, value);
      }
    },
    [onManualUpdateNumeric, setNumericUpdateType, trackableValues]
  );

  const getNumericFieldTextProps = useCallback(
    (item: DisplayGridItemProps | null, key: MarketDataItemsUnion, value: number) => {
      return {
        onClick: () => {
          if (item && item.component && item.component.type === APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric) {
            handleSingleClickValue(key, value);
          }
        },
        onDoubleClick: () => {
          if (
            (item &&
              item.component &&
              item.component.type === APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric &&
              trackableValues.includes(key)) ||
            key in trackableRangeValues
          ) {
            if (trackableValues.includes(key)) {
              setNumericUpdateType(key);
            }
          }
        },
        style: {
          textDecoration: numericUpdateType === key && enableTracking ? 'underline' : 'none'
        }
      };
    },
    [
      handleSingleClickValue,
      numericUpdateType,
      setNumericUpdateType,
      trackableRangeValues,
      trackableValues,
      enableTracking
    ]
  );

  /**
   *  Create props object for each component of a range field
   *  Callbacks are in the textProps, which are passed down to the Text component,
   *  which is under hood of the Numeric componen
   */
  const getValuePropsForRangeFields = useCallback(
    (item: DisplayGridItemProps, key: MarketDataItemsUnion) => {
      if (item?.component?.type !== APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric) {
        return null;
      }

      const values = 'value' in item.component ? item.component.value : [];

      if (key in trackableRangeValues && Array.isArray(values)) {
        return values.map((value, index) => {
          if (typeof value === 'number') {
            const trackableRangeKey = trackableRangeValues[key][index];
            return {
              textProps: getNumericFieldTextProps(item, trackableRangeKey, value),
              value
            };
          }
          return null;
        });
      }

      return null;
    },
    [getNumericFieldTextProps, trackableRangeValues]
  );

  /**
   * Generate the display grid items
   */
  const displayGridItems = useMemo(() => {
    const marketData: MarketDataPayload = { ...level1Event, orderQuantity, instrumentDetails };
    const items: (DisplayGridItemProps | null)[] = values.map((value) => {
      let item: DisplayGridItemProps | null = null;
      const key = typeof value === 'string' ? value : value.key;
      const displayOverride = typeof value !== 'string' ? value.displayOverride : null;

      if (typeof value === 'string') {
        item = marketDataDisplayItemsMap[value](marketData, layout);
      } else {
        const defaultDisplayItem = marketDataDisplayItemsMap[value.key](marketData, layout);
        item = defaultDisplayItem
          ? {
              ...defaultDisplayItem,
              colSpan: value.colSpan,
              rowSpan: value.rowSpan,
              ...(value.displayOverride || {})
            }
          : null;
      }

      // Add click handlers and styling
      if (item && item.component && item.component.type === APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric) {
        const valuesWithTextProps =
          key in trackableRangeValues && 'value' in item.component && Array.isArray(item.component.value)
            ? getValuePropsForRangeFields(item, key)
            : null;

        item = {
          ...item,
          ...displayOverride,
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
          component: {
            ...item.component,
            ...(valuesWithTextProps ? { value: valuesWithTextProps as NumericValueProps } : null),
            textProps:
              'value' in item.component
                ? getNumericFieldTextProps(item, key, item.component.value as number)
                : {}
          } as CombinedDisplayFieldComponentProps
        };
      }

      return item;
    });
    return items.flatMap((item) => (item !== null ? [item] : []));
  }, [
    level1Event,
    orderQuantity,
    instrumentDetails,
    values,
    layout,
    trackableRangeValues,
    getNumericFieldTextProps,
    getValuePropsForRangeFields
  ]);

  /**
   * Get the real time update for different price types
   */
  const realTimeUpdateNumeric = useMemo(() => {
    switch (numericUpdateType) {
      case 'bid': {
        return level1Event?.bidPrice;
      }
      case 'ask': {
        return level1Event?.askPrice;
      }
      case 'last': {
        return level1Event?.lastTradePrice;
      }
      case 'vwap': {
        return level1Event?.vwap;
      }
    }

    return 0;
  }, [
    numericUpdateType,
    level1Event?.bidPrice,
    level1Event?.askPrice,
    level1Event?.lastTradePrice,
    level1Event?.vwap
  ]);

  /**
   * Run callback when real time update & price update type is set
   */
  if (numericUpdateType !== null && realTimeUpdateNumeric && onRealTimeUpdateNumeric && enableTracking) {
    onRealTimeUpdateNumeric(numericUpdateType, realTimeUpdateNumeric);
  }

  /**
   * Render the display grid
   */
  return (
    <AppDisplayGrid
      layout={layout}
      gridProps={gridProps}
      items={displayGridItems}
      width={width}
      sx={sx}
      testId={testId}
    />
  );
};
