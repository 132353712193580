import type { ICellRendererParams } from '@ag-grid-community/core';
import { type PrincipalFillGridRow } from '@app/widgets/trading/principal-fill/principal-fill-field/principal-fill-field.component';
import { OrderSide } from '@oms/generated/frontend';
import { Text } from '@oms/shared-frontend/ui-design-system';

export const SIDE_ONLY_CELL_RENDERER = 'sideOnlyCellRenderer';

const positivePriceTypes = [OrderSide.Buy];

export const SideOnlyCellRenderer: React.FC<ICellRendererParams<PrincipalFillGridRow>> = ({ value }) => {
  return (
    <Text
      sx={{
        color: positivePriceTypes.includes(value as OrderSide) ? 'price.positive' : 'price.negative'
      }}
    >
      {value}
    </Text>
  );
};
