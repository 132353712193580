import type { QueryConstructor, UseRxQueryOptions } from 'rxdb-hooks-v5';
import { useRxCollection, useRxData } from 'rxdb-hooks-v5';
import { useMemo } from 'react';
import type { DeepReadonly, RxDocument } from 'rxdb-v15';
import { cleanMaybe } from '@oms/shared/util';

export type UseRxDocumentReturn<T> = {
  document?: RxDocument<T>;
  data?: DeepReadonly<T>;
  collection?: ReturnType<typeof useRxCollection<T>>;
  isFetching: boolean;
};

/**
 * Fetches & subscribes to a single document from a collection
 * rxdb-hooks-v5 removed the useRxDocument hook, so we're adding it back here
 *
 * @param collectionKey - The name of the collection
 * @param queryConstructor - A function that returns an RxQuery
 * @returns An object containing the RxDocument, the data, the isFetching state, and the RxCollection
 */
export function useRxDocument<T>(
  collectionKey: string,
  queryConstructor: QueryConstructor<T>,
  options?: Omit<UseRxQueryOptions, 'json'>
): UseRxDocumentReturn<T> {
  const _collection = useRxCollection<T>(collectionKey);
  const { result, isFetching } = useRxData<T>(collectionKey, queryConstructor, options);
  const document = useMemo(() => result[0] as RxDocument<T> | undefined, [result]);
  const data = useMemo(() => document?.toJSON(), [document]);
  const collection = useMemo(() => cleanMaybe(_collection), [_collection]);
  return useMemo(
    () => ({ document, data, isFetching, collection }),
    [document, data, isFetching, collection]
  );
}
