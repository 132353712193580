import { type AtdlStrategyBasicInfoFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { useCallback, useState } from 'react';
import { RouteOrderService } from '@app/data-access/services/trading/routing/route-order.service';
import { type FIXatdlStrategyValueComboBoxItem } from './fixatdl-strategy-field.contracts';

export const useStrategies = () => {
  const [options, setOptions] = useState<FIXatdlStrategyValueComboBoxItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const service = useService(RouteOrderService);

  const getOptions = useCallback(
    async ({ executionVenueId, countryId }: { executionVenueId?: string; countryId?: string | null }) => {
      if (!executionVenueId) {
        setOptions([]);
        return;
      }
      setIsLoading(true);

      const res = await service.getAtdlForExecutionVenue({ executionVenueId, countryId });

      if (res.isSuccess()) {
        const results = res.value.data.getATDLForExecutionVenue || [];

        const opts = results
          .reduce((acc, curr) => {
            if (curr?.strategies) {
              acc.push(...curr.strategies);
            }
            return acc;
          }, [] as AtdlStrategyBasicInfoFragment[])
          .map((strategy) => {
            const label = strategy.uiRep || strategy.name;
            return {
              type: 'item',
              id: strategy.name,
              isDisabled: false,
              label,
              value: {
                strategyName: strategy.name,
                strategyParams: [],
                isLayoutsPopulated: strategy.isLayoutsPopulated
              }
            } as FIXatdlStrategyValueComboBoxItem;
          });

        setOptions(opts);
      }

      setIsLoading(false);
    },
    [service]
  );

  return {
    options,
    getOptions,
    isLoading
  };
};
