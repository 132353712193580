import { inject, singleton } from 'tsyringe';
import { getOperationName } from '@apollo/client/utilities';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import {
  CreateCurrencyDocument,
  type CreateCurrencyMutation,
  type CreateCurrencyMutationVariables,
  CurrenciesDocument,
  type CurrenciesQuery,
  type CurrenciesQueryVariables,
  type CurrencyFragment,
  DeleteCurrencyDocument,
  type DeleteCurrencyMutation,
  type DeleteCurrencyMutationVariables,
  UpdateCurrencyDocument,
  type UpdateCurrencyMutation,
  type UpdateCurrencyMutationVariables
} from '@oms/generated/frontend';
import { type Observable, catchError, map, of } from 'rxjs';
import type { DataSourceCommon } from '@oms/frontend-foundation';

const CURRENCIES_QUERY_NAME = getOperationName(CurrenciesDocument);

@singleton()
export class CurrenciesService {
  private _apolloClient: ApolloClientRPC;
  private _gqlResponse: GQLResponse;
  public static refetchQueryName = CURRENCIES_QUERY_NAME;
  public static refetchQueries = CURRENCIES_QUERY_NAME ? [CURRENCIES_QUERY_NAME] : [];

  constructor(
    @inject(ApolloClientRPC) apolloClient: ApolloClientRPC,
    @inject(GQLResponse) gqlResponse: GQLResponse
  ) {
    this._apolloClient = apolloClient;
    this._gqlResponse = gqlResponse;
  }

  /**
   * @param id - Lookup query by currency ID, such as "USD" or "EUR"
   * @returns A list of `CurrencyFragment` objects matching your query filter
   */
  async getById(id: string): Promise<CurrencyFragment | null> {
    const result = await this._apolloClient.query<CurrenciesQuery, CurrenciesQueryVariables>({
      query: CurrenciesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        offset: 0,
        first: 1000,
        filter: {
          id: { includesInsensitive: id }
        }
      }
    });
    const { currencies } = result.data;
    const { nodes } = currencies ?? {};
    return nodes && nodes.length ? nodes[0] : null;
  }

  watchAll$(id?: string): Observable<DataSourceCommon<CurrencyFragment>> {
    return this._apolloClient
      .watchQuery<CurrenciesQuery, CurrenciesQueryVariables>({
        query: CurrenciesDocument,
        variables: {
          offset: 0,
          first: 1000,
          filter: id
            ? {
                id: { includesInsensitive: id }
              }
            : undefined
        }
      })
      .pipe(
        map((res) => {
          const data = res.data;
          const results = data.currencies?.nodes ? (data.currencies.nodes as CurrencyFragment[]) : [];
          return { isFetching: false, results };
        }),
        catchError((e) => {
          console.error(e);
          return of({ isFetching: false, results: [], error: e as Error });
        })
      );
  }

  create(record: CurrencyFragment) {
    const mutation = this._gqlResponse.wrapMutate<CreateCurrencyMutation, CreateCurrencyMutationVariables>({
      mutation: CreateCurrencyDocument,
      refetchQueries: CurrenciesService.refetchQueries,
      variables: {
        currency: {
          id: record.id,
          longName: record.longName,
          active: record.active
        }
      }
    });
    return mutation.exec();
  }

  update(record: CurrencyFragment) {
    const mutation = this._gqlResponse.wrapMutate<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>({
      mutation: UpdateCurrencyDocument,
      variables: {
        id: record.id,
        patch: {
          id: record.id,
          longName: record.longName,
          active: record.active
        }
      }
    });
    return mutation.exec();
  }

  delete(ids: string[]) {
    return Promise.all(
      ids.map((id) =>
        this._gqlResponse
          .wrapMutate<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>({
            mutation: DeleteCurrencyDocument,
            refetchQueries: CurrenciesService.refetchQueries,
            variables: {
              id
            }
          })
          .exec()
      )
    );
  }
}
