/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  ApolloCache,
  DefaultContext,
  FetchResult,
  OperationVariables,
  ObservableQuery,
  QueryOptions,
  ApolloQueryResult,
  SubscriptionOptions,
  NormalizedCacheObject
} from '@apollo/client';
import { ApolloClient, WatchQueryOptions, ApolloClientOptions, ApolloError } from '@apollo/client';
import type { Subscription as ZenSubscription } from 'zen-observable-ts';
import type { Subscriber as RxSubscriber } from 'rxjs';
import { Observable as RxObservable } from 'rxjs';
import { uuid as uuidv4, type Unsubscribe } from '@valstro/remote-link';
import { parseJSON } from '@oms/shared/util';
import type {
  ACRequestEvent,
  ACRequestMutationEvent,
  ACRequestQueryEvent,
  ACRequestRefetchQueriesEvent,
  ACRequestSubscribeEvent,
  ACRequestSubscribeUnsubscribeEvent,
  ACRequestWatchQueryEvent,
  ACRequestWatchQueryRefetchEvent,
  ACRequestWatchQueryStartPollingEvent,
  ACRequestWatchQueryStopPollingEvent,
  ACRequestWatchQuerySubscribeEvent,
  ACRequestWatchQuerySubscribeUnsubscribeEvent,
  ACResponseEvent,
  ACUniqueMessageID,
  ACUniqueQuerySubscriptionID,
  SerializableMutationOptions,
  SerializableRefetchQueriesOptions,
  SerializableRefetchQueriesResult
} from './apollo-client-rpc.types';
import { ApolloClientRPCOptions } from './apollo-client-rpc.types';
import { acReqChannel, acRequestResponse, acRespChannel } from './apollo-client-rpc.util';

/**
 * ExposedApolloClient - Creates & exposes an ApolloClient instance that can be used
 * in the main thread, and proxied via the ApolloClientRPC in other threads.
 *
 * This is useful for when you want a single source of truth
 * for your queries, mutations, subscriptions & cache in multi-process applications.
 *
 * @example **Example usage:**
 * ```ts
 * const apolloClient = new ExposedApolloClient('remote-client', {
 *    cache: new InMemoryCache(),
 *    uri: 'http://localhost:3333/graphql',
 * });
 * ```
 *
 * @todo Add support for exceptions thrown in the Exposed Apollo Client
 */
export class ExposedApolloClient<TCacheShape> extends ApolloClient<TCacheShape> {
  private observableQueryMap = new Map<
    ACUniqueMessageID,
    {
      observable: ObservableQuery;
      subscriptionMap: Map<ACUniqueQuerySubscriptionID, ZenSubscription>;
    }
  >();
  private subscriptionMap = new Map<ACUniqueQuerySubscriptionID, ZenSubscription>();
  private pendingUnsubscriptions = new Set<ACUniqueQuerySubscriptionID>();
  private telemetryInterval: NodeJS.Timeout | undefined;
  constructor(public clientId: string, options: ApolloClientOptions<TCacheShape>) {
    super(options);
    this.expose();
  }

  public override stop() {
    super.stop();
    this.unexpose();
  }

  private expose() {
    acReqChannel.addEventListener('message', this.handleRequests.bind(this));

    /*
    Uncomment this if we need this for sentry, but I don't think it's needed.

    this.telemetryInterval = setInterval(() => {
      console.debug('[ExposedApolloClient] Telemetry', {
        that: this,
        observableQueryMapSize: this.observableQueryMap.size,
        observableQueryMap: this.observableQueryMap,
        subscriptionMap: this.subscriptionMap.size,
        pendingUnsubscriptions: this.pendingUnsubscriptions.size
      });
    }, 10_000);
    */
  }

  private unexpose() {
    acReqChannel.removeEventListener('message', this.handleRequests.bind(this));
    if (this.telemetryInterval) {
      clearInterval(this.telemetryInterval);
    }
  }

  private async handleRequests(event: ACRequestEvent) {
    if (event.meta.clientId !== this.clientId) return;
    try {
      switch (event.type) {
        case 'query':
          event.payload.errorPolicy = 'all';
          await this.handleQuery(event);
          break;
        case 'mutation':
          event.payload.errorPolicy = 'all';
          await this.handleMutation(event);
          break;
        case 'refetchQueries':
          await this.handleRefetchQueries(event);
          break;
        case 'subscribe':
          this.handleSubscribe(event);
          break;
        case 'subscribe__unsubscribe':
          this.handleSubscribeUnsubscribe(event);
          break;
        case 'watchQuery':
          event.payload.errorPolicy = 'all';
          this.handleWatchQuery(event);
          break;
        case 'watchQuery__subscribe':
          this.handleWatchQuerySubscribe(event);
          break;
        case 'watchQuery__subscribe__unsubscribe':
          this.handleWatchQuerySubscribeUnsubscribe(event);
          break;
        case 'watchQuery__refetch':
          this.handleWatchQueryRefetch(event);
          break;
        case 'watchQuery__startPolling':
          this.handleWatchQueryStartPolling(event);
          break;
        case 'watchQuery__stopPolling':
          this.handleWatchQueryStopPolling(event);
          break;
      }
    } catch (e) {
      if (e instanceof ApolloError) {
        const serializedError = parseJSON<ApolloError>(JSON.stringify(e));
        if (!serializedError) throw new Error('Could not serialize apollo error');
        acRespChannel
          .postMessage({
            type: 'apolloError',
            payload: serializedError,
            meta: {
              uuid: event.meta.uuid,
              clientId: this.clientId
            }
          })
          .catch(console.error);
      } else {
        throw e;
      }
    }
  }

  private async handleQuery(event: ACRequestQueryEvent): Promise<void> {
    const result = await this.query(event.payload);
    acRespChannel
      .postMessage({
        type: 'query',
        payload: result,
        meta: {
          uuid: event.meta.uuid,
          clientId: this.clientId
        }
      })
      .catch(console.error);
  }

  private async handleRefetchQueries(event: ACRequestRefetchQueriesEvent): Promise<void> {
    const result = await this.refetchQueries(event.payload);
    acRespChannel
      .postMessage({
        type: 'refetchQueries',
        payload: result,
        meta: {
          uuid: event.meta.uuid,
          clientId: this.clientId
        }
      })
      .catch(console.error);
  }

  private async handleMutation(event: ACRequestMutationEvent): Promise<void> {
    const result = await this.mutate(event.payload);
    acRespChannel
      .postMessage({
        type: 'mutation',
        payload: result,
        meta: {
          uuid: event.meta.uuid,
          clientId: this.clientId
        }
      })
      .catch(console.error);
  }

  private handleSubscribe(event: ACRequestSubscribeEvent): void {
    const queryUuid = event.meta.uuid;

    const existingSub = this.subscriptionMap.get(queryUuid);
    if (existingSub) {
      throw new Error('Subscription already exists');
    }

    const subscription = this.subscribe(event.payload).subscribe(
      (result) => {
        acRespChannel
          .postMessage({
            type: 'subscribe',
            payload: result,
            meta: {
              uuid: queryUuid,
              clientId: this.clientId
            }
          })
          .catch(console.error);
      },
      (err) => {
        console.error('Subscription error', err);
      }
    ); // TODO: Add error handling here...

    this.subscriptionMap.set(queryUuid, subscription);
  }

  private handleSubscribeUnsubscribe(event: ACRequestSubscribeUnsubscribeEvent): void {
    const subscription = this.subscriptionMap.get(event.payload.queryId);
    if (!subscription) {
      // Unsubscribe event fired too early, because we need to async create an observableQuery (for ex. in react strict mode issue)
      // So we need to keep track of the queries to unsubscribe from, to stop them being created in the first place.
      this.pendingUnsubscriptions.add(event.payload.queryId);
      return;
    }

    subscription.unsubscribe();
    this.subscriptionMap.delete(event.payload.queryId);
  }

  private handleWatchQuery(event: ACRequestWatchQueryEvent): void {
    const queryId = event.meta.uuid;
    const existingObservableQuery = this.observableQueryMap.get(queryId);

    if (!existingObservableQuery) {
      const observable = this.watchQuery(event.payload);

      this.observableQueryMap.set(queryId, {
        observable,
        subscriptionMap: new Map()
      });
    }

    acRespChannel
      .postMessage({
        type: 'watchQuery',
        payload: {
          queryId
        },
        meta: {
          uuid: queryId,
          clientId: this.clientId
        }
      })
      .catch(console.error);
  }

  private handleWatchQuerySubscribe(event: ACRequestWatchQuerySubscribeEvent): void {
    const { queryId, querySubscriptionId } = event.payload;

    const observableQuery = this.observableQueryMap.get(queryId);
    if (!observableQuery) {
      throw new Error('Observable not found');
    }

    if (observableQuery.subscriptionMap.has(querySubscriptionId)) {
      throw new Error('Subscription already exists');
    }

    const subscription = observableQuery.observable.subscribe((result) => {
      acRespChannel
        .postMessage({
          type: 'watchQuery__subscribe',
          payload: { result, querySubscriptionId },
          meta: {
            uuid: queryId,
            clientId: this.clientId
          }
        })
        .catch(console.error);
    });

    observableQuery.subscriptionMap.set(querySubscriptionId, subscription);
  }

  private handleWatchQuerySubscribeUnsubscribe(event: ACRequestWatchQuerySubscribeUnsubscribeEvent): void {
    const { queryId, querySubscriptionId } = event.payload;
    const observableQuery = this.observableQueryMap.get(queryId);
    if (!observableQuery) {
      throw new Error('Observable not found');
    }
    const subscription = observableQuery.subscriptionMap.get(querySubscriptionId);
    if (!subscription) {
      return;
    }
    subscription.unsubscribe();
    observableQuery.subscriptionMap.delete(querySubscriptionId);

    if (observableQuery.subscriptionMap.size === 0) {
      this.observableQueryMap.delete(queryId);
    }

    acRespChannel
      .postMessage({
        type: 'watchQuery__subscribe__unsubscribe',
        payload: { queryId, querySubscriptionId },
        meta: {
          uuid: queryId,
          clientId: this.clientId
        }
      })
      .catch(console.error);
  }

  private handleWatchQueryRefetch(event: ACRequestWatchQueryRefetchEvent): void {
    const observableQuery = this.observableQueryMap.get(event.payload.queryId);
    if (!observableQuery) {
      throw new Error('Observable not found');
    }
    observableQuery.observable
      .refetch(event.payload)
      .then((result) => {
        acRespChannel
          .postMessage({
            type: 'watchQuery__refetch',
            payload: result,
            meta: {
              uuid: event.meta.uuid,
              clientId: this.clientId
            }
          })
          .catch(console.error);
      })
      .catch(console.error);
  }

  private handleWatchQueryStartPolling(event: ACRequestWatchQueryStartPollingEvent): void {
    const observableQuery = this.observableQueryMap.get(event.payload.queryId);
    if (!observableQuery) {
      throw new Error('Observable not found');
    }
    observableQuery.observable.startPolling(event.payload.pollInterval);
  }

  private handleWatchQueryStopPolling(event: ACRequestWatchQueryStopPollingEvent): void {
    const observableQuery = this.observableQueryMap.get(event.payload.queryId);
    if (!observableQuery) {
      throw new Error('Observable not found');
    }
    observableQuery.observable.stopPolling();
  }
}

/**
 * ApolloClientRPC - Creates an RPC client that can be used to communicate with an ExposedApolloClient
 *
 * Supported methods:
 * - query
 * - mutate (altered for serializable options)
 * - refetchQueries (experimental - altered for serializable options)
 * - watchQuery
 * - - subscribe
 * - - refetch
 * - - startPolling
 * - - stopPolling
 * - - unsubscribe
 * - subscribe
 * - unsubscribe
 *
 * @example **Example usage:**
 * ```ts
 * const apolloClientRPC = new ApolloClientRPC('remote-client', {
 *    timeout: 10_000,
 *    destroyEventListener: isTauri()
 *      ? async (cb) => {
 *          const unsub = await appWindow.onCloseRequested(cb);
 *          return () => {
 *            unsub();
 *          };
 *        }
 *      : (cb) => {
 *          window.addEventListener('beforeunload', cb);
 *          return () => {
 *            window.removeEventListener('beforeunload', cb);
 *          };
 *        }
 * });
 *
 * const observable = apolloClientRPC.watchQuery<
 *    GetTradesQuery,
 *    GetTradesQueryVariables
 * >({
 *    query: GetTradesDocument,
 *    variables: {},
 * });
 *
 * const subscription = observable.subscribe((result) => {
 *    console.log('New query result', result);
 * });
 *
 * observable.refetch().then((result) => {
 *   console.log('Refetch result', result);
 * });
 *
 * observable.startPolling(1000);
 * observable.stopPolling();
 *
 * subscription.unsubscribe();
 * ```
 *
 * @todo Add support for exceptions thrown in the Exposed Apollo Client
 */
export class ApolloClientRPC<TCacheShape = NormalizedCacheObject> {
  private destroyUnsub: Unsubscribe | undefined;
  private timeout = 10_000;
  private subscriptionUnsubs = new Set<Unsubscribe>();
  private telemetryInterval: NodeJS.Timeout | undefined;
  private customObservableQueries = new Set<CustomObservableQuery<any, any>>();

  constructor(public clientId: string, options: ApolloClientRPCOptions) {
    if (options.timeout) this.timeout = options.timeout;
    if (options.destroyEventListener) {
      this.listenForDestroy(options.destroyEventListener).catch(console.error);
    }

    /*
    Uncomment this if we need this for sentry, but I don't think it's needed.

    this.telemetryInterval = setInterval(() => {
      console.debug('[ApolloClientRPC] Telemetry', {
        that: this,
        customObservableQueryMap: this.customObservableQueries.size,
        subscriptionUnsubs: this.subscriptionUnsubs.size
      });
    }, 10_000);
    */
  }

  public query<T = any, TVariables extends OperationVariables = OperationVariables>(
    options: QueryOptions<TVariables, T>
  ): Promise<ApolloQueryResult<T>> {
    return acRequestResponse(this.clientId, 'query', options, this.timeout);
  }

  public mutate<
    TData = any,
    TVariables extends OperationVariables = OperationVariables,
    TContext extends Record<string, any> = DefaultContext,
    TCache extends ApolloCache<any> = ApolloCache<any>
  >(options: SerializableMutationOptions<TData, TVariables, TContext, TCache>): Promise<FetchResult<TData>> {
    return acRequestResponse(this.clientId, 'mutation', options, this.timeout);
  }

  public refetchQueries<
    TCache extends ApolloCache<any> = ApolloCache<TCacheShape>,
    TResult = ApolloQueryResult<any>
  >(options: SerializableRefetchQueriesOptions): Promise<SerializableRefetchQueriesResult<TResult>> {
    return acRequestResponse(this.clientId, 'refetchQueries', options, this.timeout);
  }

  public watchQuery<T = any, TVariables extends OperationVariables = OperationVariables>(
    options: WatchQueryOptions<TVariables, T>
  ): CustomObservableQuery<T, TVariables> {
    const observableQuery = new CustomObservableQuery<T, TVariables>(this.clientId, options, this.timeout);
    this.customObservableQueries.add(observableQuery);
    return observableQuery;
  }

  public subscribe<T = any, TVariables extends OperationVariables = OperationVariables>(
    options: SubscriptionOptions<TVariables, T>
  ): RxObservable<FetchResult<T>> {
    return new RxObservable<FetchResult<T>>((subscriber) => {
      const uuid = uuidv4();

      const responseHandler = (e: ACResponseEvent) => {
        if (e.meta.clientId !== this.clientId) return;
        if (e.meta.uuid !== uuid) return;
        if (e.type !== 'subscribe') return;
        subscriber.next(e.payload);
      };

      acRespChannel.addEventListener('message', responseHandler);

      acReqChannel
        .postMessage({
          type: 'subscribe',
          payload: options,
          meta: {
            uuid,
            clientId: this.clientId
          }
        })
        .catch(console.error);

      const unsubscribe = () => {
        acReqChannel
          .postMessage({
            type: 'subscribe__unsubscribe',
            payload: {
              queryId: uuid
            },
            meta: {
              uuid,
              clientId: this.clientId
            }
          })
          .catch(console.error);
        acRespChannel.removeEventListener('message', responseHandler);
        this.subscriptionUnsubs.delete(unsubscribe);
      };

      this.subscriptionUnsubs.add(unsubscribe);
      return unsubscribe;
    });
  }

  public destroy() {
    this.subscriptionUnsubs.forEach((unsubscribe) => {
      unsubscribe();
    });
    this.customObservableQueries.forEach((observableQuery) => {
      observableQuery.dispose();
    });
    this.customObservableQueries.clear();
    this.destroyUnsub?.();
    if (this.telemetryInterval) {
      clearInterval(this.telemetryInterval);
    }
  }

  private async listenForDestroy(listener: Required<ApolloClientRPCOptions>['destroyEventListener']) {
    this.destroyUnsub = await listener(() => this.destroy());
  }
}

/**
 * CustomObservableQuery - A custom observable query that can be used to subscribe to a query
 * and receive updates via the ApolloClientRPC.
 *
 * Note: This replaces the default ObservableQuery from ApolloClient, and is used internally by the ApolloClientRPC.
 */

export class CustomObservableQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
> extends RxObservable<ApolloQueryResult<TData>> {
  private _hasCreatedObservable = false;
  private _queryId = uuidv4();
  private _queue: Array<(...args: any[]) => any> = [];
  private subs: RxSubscriber<ApolloQueryResult<TData>>[] = [];

  constructor(
    public clientId: string,
    private _options: WatchQueryOptions<TVariables, TData>,
    private _timeout = 10_000
  ) {
    super((subscriber) => {
      this.subs.push(subscriber);
      const querySubscriptionId = uuidv4();
      this._createObservable().catch(console.error);
      const responseHandler = (e: ACResponseEvent) => {
        if (e.meta.clientId !== this.clientId) return;
        if (e.meta.uuid !== this._queryId) return;
        switch (e.type) {
          case 'watchQuery__subscribe__unsubscribe': {
            if (e.payload.querySubscriptionId === querySubscriptionId) {
              subscriber.unsubscribe();
              subscriber.complete();
            }
            break;
          }
          case 'watchQuery__subscribe': {
            if (e.payload.querySubscriptionId === querySubscriptionId) {
              subscriber.next(e.payload.result);
            }
            break;
          }
        }
      };
      acRespChannel.addEventListener('message', responseHandler);

      this._queue.push(() => {
        acReqChannel
          .postMessage({
            type: 'watchQuery__subscribe',
            payload: {
              queryId: this._queryId,
              querySubscriptionId
            },
            meta: {
              uuid: this._queryId,
              clientId: this.clientId
            }
          } as ACRequestEvent)
          .catch(console.error);
      });

      // Run immediately if we have already created the observable
      if (this._hasCreatedObservable) {
        this._drainQueue();
      }

      return () => {
        acReqChannel
          .postMessage({
            type: 'watchQuery__subscribe__unsubscribe',
            payload: {
              queryId: this._queryId,
              querySubscriptionId
            },
            meta: {
              uuid: this._queryId,
              clientId: this.clientId
            }
          } as ACRequestEvent)
          .catch(console.error);
        acRespChannel.removeEventListener('message', responseHandler);
      };
    });
  }

  public dispose() {
    this.subs.forEach((s) => s.complete());
    this.subs = [];
  }

  public refetch(variables?: Partial<TVariables>): Promise<ApolloQueryResult<TData>> {
    return acRequestResponse(
      this.clientId,
      'watchQuery__refetch',
      {
        queryId: this._queryId,
        variables
      },
      this._timeout
    );
  }

  public startPolling(pollInterval: number): void {
    acReqChannel
      .postMessage({
        type: 'watchQuery__startPolling',
        payload: {
          pollInterval,
          queryId: this._queryId
        },
        meta: {
          uuid: this._queryId,
          clientId: this.clientId
        }
      })
      .catch(console.error);
  }

  public stopPolling(): void {
    acReqChannel
      .postMessage({
        type: 'watchQuery__stopPolling',
        payload: {
          queryId: this._queryId
        },
        meta: {
          uuid: this._queryId,
          clientId: this.clientId
        }
      })
      .catch(console.error);
  }

  private async _createObservable() {
    await acRequestResponse(this.clientId, 'watchQuery', this._options, this._timeout, this._queryId);

    this._hasCreatedObservable = true;
    this._drainQueue();
  }

  private _drainQueue() {
    const queue = this._queue;
    this._queue = [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    queue.forEach((fn) => fn());
  }
}
