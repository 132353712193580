import { Flex } from '@oms/shared-frontend/ui-design-system';
import { DepthGrid } from './depth-grid/depth.grid';
import { useMontageInstrumentDetails } from '@app/data-access/services/trading/montage/hooks/use-montage-instrument-details.hook';
import { useSyncDepthScroll } from './use-sync-depth-scroll';

export const MontageDepthGrid = () => {
  const [ref, bidRef, askRef] = useSyncDepthScroll();
  const instrumentDetails = useMontageInstrumentDetails();

  return (
    <Flex
      ref={ref}
      direction="row"
      sx={{ height: 'full', width: 'full', overflow: 'hidden', borderRadius: 'base' }}
    >
      {instrumentDetails ? (
        <>
          <Flex direction="column" sx={{ height: 'full', width: 'full', marginRight: 2 }} ref={bidRef}>
            <DepthGrid type="bid" />
          </Flex>
          <Flex direction="column" sx={{ height: 'full', width: 'full' }} ref={askRef}>
            <DepthGrid type="ask" />
          </Flex>
        </>
      ) : (
        <Flex sx={{ height: 'full', width: 'full' }} align="center" justify="center">
          <p>Please select an instrument</p>
        </Flex>
      )}
    </Flex>
  );
};
