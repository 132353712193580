import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { TradingOrdersService } from '@app/data-access/services/trading/trading-orders/trading-orders.service';
import type {
  RouteOrderFormInput,
  RouteOrderFormType,
  RouteOrderFormInfo
} from '@app/widgets/trading/route-order/route-order.form-common';
import type { RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';
import {
  TimeInForce,
  DestinationType,
  type InstrumentDetailsSimpleFragment,
  type VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment,
  type VisibleTradingOrderInfoWithAllocationsFragment as TOFragment,
  OrderType,
  type TagFragment,
  type InvestorAccount
} from '@oms/generated/frontend';
import { convertToNumber, formatNumber } from '@oms/shared/util';
import { createInstrumentComboboxItem } from '@app/forms/common/fields/symbol-field/symbol.field.common';
import type { DependencyContainer } from 'tsyringe';
import {
  createVenueComboboxItem,
  createVenueComboboxItemFromDestinationType
} from '@app/forms/common/fields/venue-field/venue.field.common';
import { createRoutableUserComboboxItem } from './advanced-select-queries/routable-users.select-query';
import { createTagComboboxItem } from '@app/forms/common/fields/order-tags-field/order-tags.field.queries';
import { createRepCodeComboboxItem } from '@app/forms/common/fields/rep-code-field/rep-code.field.queries';
import { createCurrencyComboboxItem } from '@app/forms/common/fields/currency-field/currency.field.queries';
import { createStrategyComboboxItem } from './fixatdl-strategy-field/fixatdl-strategy-field.util';
import { createAccountComboboxItem } from '@app/forms/common/fields/account-field/account.field.common';
import { tifFormValues } from '@app/forms/common/fields/TIF-field/TIF-field.common';

/**
 * Fetch Order Info from IDs provided in input
 * - Fetch Investor Order (investorOrderId)
 * - Fetch Trading Order (tradingOrderId)
 * - Create Trading Order (no IDs provided)
 *
 * @param input - RouteOrderFormInput
 * @param container - DependencyContainer
 * @returns RouteOrderFormInfo
 */
export async function getOrderInfoFromInput(
  input: RouteOrderFormInput,
  container: DependencyContainer
): Promise<RouteOrderFormInfo> {
  const investorOrderService = container.resolve(InvestorOrdersService);
  const tradingOrderService = container.resolve(TradingOrdersService);

  const hiddenType: RouteOrderFormType = input.investorOrderId
    ? 'route'
    : input.tradingOrderId
    ? 'modify'
    : 'create';

  let investorOrder: IOFragment | undefined;
  let tradingOrder: TOFragment | undefined;

  switch (hiddenType) {
    case 'route': {
      if (!input.investorOrderId) throw new Error('Investor Order ID is required');
      const result = await investorOrderService.getById(input.investorOrderId);
      if (result.isSuccess() && result.value.data.visibleInvestorOrder) {
        investorOrder = result.value.data.visibleInvestorOrder as IOFragment;
        return {
          type: 'route',
          investorOrder
        };
      } else if (result.isFailure()) {
        console.error(result.errors);
        throw new Error('Failed to get investor order');
      }
      break;
    }
    case 'modify': {
      if (!input.tradingOrderId) throw new Error('Trading Order ID is required');

      const result = await tradingOrderService.getById(input.tradingOrderId);
      if (result.isSuccess() && result.value.data.visibleTradingOrder) {
        tradingOrder = result.value.data.visibleTradingOrder as TOFragment;
        return {
          type: 'modify',
          tradingOrder
        };
      } else if (result.isFailure()) {
        console.error(result.errors);
        throw new Error('Failed to get investor order');
      }
      break;
    }
  }

  return {
    type: 'create',
    options: input.initialCreateOptions
  };
}

/**
 * Get Initial Common Route & Modify Field Values based on Order/Trading Info
 *
 * @param inputs - InitialOrderBasedFieldInputs
 * @returns Partial<RouteOrderFormValues>
 */
export function getInitialCommonRouteModifyFieldValues<
  T extends (IOFragment | TOFragment) & { tradingAccount?: InvestorAccount | null }
>(orderInfo: T): Partial<RouteOrderFormValues> {
  const {
    sideType,
    instrument,
    limitPrice,
    quantity,
    timeInForce,
    gtdTimestamp,
    tradeCurrency,
    settleDate,
    settleType,
    customerNotes,
    orderTags,
    representativeCode,
    locate,
    ...rest
  } = orderInfo;
  const formValues: Partial<RouteOrderFormValues> = {};
  formValues.sideType = sideType ? sideType : undefined;
  formValues.limitPrice = typeof limitPrice === 'number' ? formatNumber(limitPrice) : undefined;
  const orderQuantity = 'openQuantity' in rest ? rest.openQuantity : quantity;
  formValues.quantity = typeof orderQuantity === 'number' ? formatNumber(orderQuantity) : undefined;
  formValues.timeInForce = timeInForce ? timeInForce : undefined;
  formValues.instrument = instrument
    ? createInstrumentComboboxItem<InstrumentDetailsSimpleFragment>(instrument)
    : undefined;
  formValues.tradeCurrency = tradeCurrency
    ? createCurrencyComboboxItem({
        id: tradeCurrency,
        longName: tradeCurrency
      })
    : undefined;
  formValues.repCode = representativeCode ? createRepCodeComboboxItem(representativeCode) : undefined;
  formValues.customerNotes = customerNotes ? customerNotes : undefined;
  formValues.settlementType = settleType ? settleType : undefined;
  formValues.settlementDate = settleDate ? String(settleDate) : undefined;
  formValues.orderTags = orderTags
    ? orderTags.map((tag) => createTagComboboxItem(tag as TagFragment))
    : undefined;
  formValues.locate = locate || undefined;
  formValues.firmAccount = rest.tradingAccount ? createAccountComboboxItem(rest.tradingAccount) : undefined;

  return { ...formValues, ...tifFormValues(orderInfo) };
}

/**
 * Get Initial Modify-Only Field Values based on Trading Order Info
 *
 * @param tradingOrder - VisibleTradingOrderInfoWithAllocationsFragment
 * @returns Partial<RouteOrderFormValues>
 */
export function getInitialModifyOnlyFieldValues(
  orderInfo: RouteOrderFormInfo
): Partial<RouteOrderFormValues> {
  if (orderInfo.type !== 'modify') {
    throw new Error('Invalid order type');
  }
  const routeOrderFormValues: Partial<RouteOrderFormValues> = {};
  const { destinationType, destinationName, venueDestination, traderDestination } = orderInfo.tradingOrder;
  const venueDestinationId = venueDestination?.id;
  const traderDestinationId = traderDestination?.id;

  if (destinationType === DestinationType.PrimaryTrader || destinationType === DestinationType.Trader) {
    // set vene to trader enum
    routeOrderFormValues.venue = createVenueComboboxItemFromDestinationType(destinationType);
  }

  if (destinationType === DestinationType.Venue && destinationName && venueDestinationId) {
    // set venue to actual venue by looking it up
    routeOrderFormValues.venue = createVenueComboboxItem({
      id: venueDestinationId,
      executionVenueDescription: destinationName
    });
  }

  if (destinationType === DestinationType.Trader && destinationName && traderDestinationId) {
    // set trader if destination is trader
    routeOrderFormValues.trader = createRoutableUserComboboxItem({
      id: traderDestinationId,
      label: destinationName
    });
  }

  const strategy = orderInfo.tradingOrder.strategy;
  const venueId = routeOrderFormValues.venue?.id;

  if (strategy && strategy?.name && strategy?.parameters) {
    routeOrderFormValues.strategy = createStrategyComboboxItem(strategy, venueId);
  }

  routeOrderFormValues.venueNotes = orderInfo?.tradingOrder?.venueNotes || '';

  return routeOrderFormValues;
}

/**
 * Get Initial Create-Only Field Values based on Create Options
 *
 * @param options - VisibleTradingOrderInfoWithAllocationsFragment
 * @returns Partial<RouteOrderFormValues>
 */
export function getInitialCreateOnlyFieldValues(
  initialCreateOptions: Required<RouteOrderFormInput>['initialCreateOptions']
): Partial<RouteOrderFormValues> {
  const { instrumentId, instrumentDisplayCode, ...rest } = initialCreateOptions;
  const routeOrderFormValues: Partial<RouteOrderFormValues> = { ...rest };
  routeOrderFormValues.instrument = routeOrderFormValues.instrument
    ? routeOrderFormValues.instrument
    : createInstrumentComboboxItem<InstrumentDetailsSimpleFragment>({
        id: instrumentId,
        mappings: {
          displayCode: instrumentDisplayCode
        }
      });

  routeOrderFormValues.limitPrice = initialCreateOptions.limitPrice;

  routeOrderFormValues.sideType = initialCreateOptions.sideType;

  routeOrderFormValues.quantity = initialCreateOptions.quantity;

  routeOrderFormValues.timeInForce = initialCreateOptions.timeInForce;

  routeOrderFormValues.gtdTimestamp = initialCreateOptions.gtdTimestamp;

  return routeOrderFormValues;
}

/**
 * Sanitize Form Values to Common Output for
 * - Create Trading Order
 * - Modify Trading Order
 * - Route Investor Order
 *
 * @param formValues - RouteOrderFormValues
 * @returns - A common sanitized output for create, modify, and route
 */
export function sanitizeFormValuesToCommonOutput(formValues: RouteOrderFormValues) {
  const {
    instrument: _instrumentComboItem,
    sideType,
    quantity: _quantity,
    limitPrice: _limitPriceOrMarket,
    timeInForce: _timeInForceComboItem,
    venue: _venueComboItem,
    locate,
    gtdTimestamp: _gtdTimestamp,
    tifDuration,
    orderTags: _orderTags,
    customerNotes: _customerNotes,
    venueNotes
  } = formValues;

  const instrument = _instrumentComboItem
    ? {
        id: _instrumentComboItem.value.id
      }
    : undefined;

  const timeInForce = _timeInForceComboItem || TimeInForce.Day;
  const limitPrice = _limitPriceOrMarket ? convertToNumber(_limitPriceOrMarket) : undefined;
  const orderType = limitPrice ? OrderType.Limit : OrderType.Market;
  const quantity = convertToNumber(_quantity);
  const gtdTimestamp = _gtdTimestamp ? _gtdTimestamp : undefined;
  // These have to be null otherwise they're not being passed to the mutation
  // and the BE thinks they're unchanged keeping the original values.
  // It’s better to use nulls instead of empty arrays as well as it doesn’t take more space in the DB.
  const orderTags = _orderTags?.map((tag) => ({ id: tag.value.id })) || null;
  const customerNotes = _customerNotes ?? null;

  const venueDestination = _venueComboItem
    ? {
        id: _venueComboItem.value
      }
    : undefined;

  const destinationType = venueDestination ? DestinationType.Venue : undefined;

  return {
    instrument,
    sideType,
    quantity,
    limitPrice,
    timeInForce,
    gtdTimestamp,
    tifDuration,
    orderTags,
    orderType,
    destinationType,
    venueDestination,
    locate,
    customerNotes,
    venueNotes
  };
}
