import { t } from '@oms/codegen/translations';
import type { ColumnBuilderCallback } from '@oms/frontend-vgrid';
import type { PositionHistoryRow } from '@app/common/types/positions/positions.types';
import { instrumentCols } from '@app/common/grids/columns/instrument-cols';
import {
  sharedCurrencyCol,
  sharedDateTimeCol,
  sharedDefaultCol,
  sharedQuantityCol
} from '@app/common/grids/columns/generic-cols';
import { sharedNameCol } from '@app/common/grids/columns/reference-data-cols';
import { sharedPositionsPrice } from '@app/common/grids/columns/positions-cols';

type HistoryColumn = ColumnBuilderCallback<PositionHistoryRow>;

const [instrumentDisplayCode, instrumentLongName] = instrumentCols<PositionHistoryRow>({
  include: ['displayCode', 'longName']
});

export const defaultColumn: HistoryColumn = (c) => sharedDefaultCol(c).minWidth(180);

export const id: HistoryColumn = (c) => sharedNameCol(c, 'id').hide(true);

export const instrumentSymbolData: Record<'displayCode' | 'name', HistoryColumn> = {
  displayCode: (c) => instrumentDisplayCode(c).field('instrumentSymbolData.displayCode').width(90),
  name: (c) => instrumentLongName(c).field('instrumentSymbolData.name').header(t('app.positions.instrument.name')).shortHeader(t('app.positions.instrument.name', { ns: 'short' })).hide(true)
};

export const eventTimestamp: HistoryColumn = (c) => sharedDateTimeCol(c, 'eventTimestamp').header('Timestamp').sort('desc').minWidth(290);

export const event: HistoryColumn = (c) => c.field('event').header('Position Event');

export const eventUserId: HistoryColumn = (c) => c.field('eventUserId').header('Position Event User Id');

export const eventId: HistoryColumn = (c) => c.field('eventId').header('Position Event Identifier');

export const account: HistoryColumn = (c) => sharedNameCol(c, 'account').header('Firm Account');

export const eventCurrency: HistoryColumn = (c) => sharedCurrencyCol(c, 'eventCurrency').header('Position Event Currency');

export const eventQuantity: HistoryColumn = (c) => sharedQuantityCol(c, 'eventQuantity').header('Position Event Quantity');

export const eventPrice: HistoryColumn = (c) => sharedPositionsPrice(c, 'eventPrice').header('Position Event Price');

export const quantity: HistoryColumn = (c) => sharedQuantityCol(c, 'quantity').header('Position Quantity');

export const averagePrice: HistoryColumn = (c) => sharedQuantityCol(c, 'averagePrice').header('Position Average Price');

export const realizedPnL: HistoryColumn = (c) => c.field('realizedPnL').header('Position Realized PnL');

export const updateEventTimestamp: HistoryColumn = (c) => sharedDateTimeCol(c, 'updateEventTimestamp').header('Position Update Event Timestamp');

export const updateEventId: HistoryColumn = (c) => c.field('updateEventId').header('Update Event Identifier');

export const updateEvent: HistoryColumn = (c) => c.field('updateEvent').header('Update Event');
