import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { openConfirmation } from '@app/generated/sdk';
import type { ActionComponentConfig, ActionContext, ActionDefFactory } from '@oms/frontend-vgrid';
import {
  InvestorOrderStatus,
  type VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment
} from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';

export const acceptInvestorOrderOnChange = async (
  ctx: ActionContext<IOFragment, ActionComponentConfig<IOFragment>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];
  const isOnlyOneRowSelected = data.length === 1;

  const isUnacceptedIO =
    isOnlyOneRowSelected &&
    selectedRow &&
    'status' in selectedRow &&
    selectedRow?.status === InvestorOrderStatus.Unaccepted &&
    selectedRow?.__typename === 'VisibleInvestorOrder';

  ctx.notify({ isDisabled: !isUnacceptedIO });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }

    const id = selectedRow.id;

    if (!id) {
      return;
    }

    const orderService = ctx.appContainer.resolve(InvestorOrdersService);

    try {
      ctx.notify({ isLoading: true, rowData: selectedRow });

      const resp = await orderService.acceptOrder(id);
      if (resp.isSuccess()) {
        ctx.notify({ isLoading: false, rowData: selectedRow });
      } else {
        const msgs = resp?.errors.map((e) => e.message).join(', ');
        await openConfirmation(ctx.workspace, PROCESS_ID.LEADER, {
          title: 'Error accepting order',
          componentProps: {
            message: `Error accepting order: ${msgs}`,
            autoClose: true,
            hideCancelButton: true,
            confirmButtonText: 'Ok'
          }
        });
        throw new Error(msgs);
      }
    } catch (e) {
      ctx.notify({ isLoading: false, rowData: selectedRow });
      console.error(e);
    }
  }
};

export const acceptInvestorOrderAction: ActionDefFactory<IOFragment> = (builder) =>
  builder
    .name('accept_order')
    .toolbar((t) =>
      t.component('action-button').id('accept_order_button').location('HorizontalToolbarRight').props({
        isDisabled: true,
        content: 'Accept'
      })
    )
    .menu((m) =>
      m.name('Accept').visible(({ rowData }) => rowData?.status === InvestorOrderStatus.Unaccepted)
    )
    .onChange<ActionComponentConfig<IOFragment>>(acceptInvestorOrderOnChange);
