import { OrderTagsService } from '@app/data-access/services/reference-data/order-tags/order-tags.service';
import { type UseFieldApiProps } from '@data-driven-forms/react-form-renderer';
import {
  type AdvancedSelectQueryFn,
  type DataSourceCommon,
  type ComboBoxItem,
  type AdvancedSelectParams
} from '@oms/frontend-foundation';
import { type TagFragment } from '@oms/generated/frontend';
import { map } from 'rxjs';

export const createTagComboboxItem = (
  tag: Partial<TagFragment> & Pick<TagFragment, 'id' | 'tagName'>
): ComboBoxItem<TagFragment> => {
  return {
    id: tag.id,
    // Workaround for enrichment until migrating to ID only driven comboxboxes:
    // Since enrichment returns only id, we put it in the label and sublabel as well
    label: tag.tagName ?? tag.id,
    sublabel: tag?.tagCode ?? tag.id,
    value: tag as TagFragment,
    type: 'item'
  };
};

export const watchAllOrderTagsQuery: AdvancedSelectQueryFn<TagFragment> = (container) => {
  const orderTagsService = container.resolve(OrderTagsService);

  return {
    type: 'watchAll',
    preLoad: true,
    query(params) {
      const typedParams: AdvancedSelectParams<
        TagFragment,
        UseFieldApiProps<ComboBoxItem<TagFragment> | ComboBoxItem<TagFragment>[], HTMLElement>
      > = params;

      return orderTagsService.watchAll$(...(typedParams?.queryProps?.filters || [])).pipe(
        map<DataSourceCommon<TagFragment>, DataSourceCommon<ComboBoxItem<TagFragment>>>((r) => ({
          results: (r.results || []).map((d) => createTagComboboxItem(d)),
          isFetching: r.isFetching,
          error: r.error
        }))
      );
    }
  };
};
