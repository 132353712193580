import {
  FormContract,
  FieldDefinition,
  type InferFormValuesFromFormContract
} from '@oms/frontend-foundation';
import {
  bidInitiateOrder,
  bidQuantityType,
  bidQuantityValue,
  bidOrderSize,
  bidPriceType,
  bidTimeInForceType,
  bidGtdTimestamp,
  bidTifDuration,
  bidDisplaySize,
  bidDestinationId,
  bidStrategy,
  askSideType,
  bidSideType,
  askDestinationId,
  askDisplaySize,
  askGtdTimestamp,
  askInitiateOrder,
  askPriceType,
  askQuantityType,
  askQuantityValue,
  askOrderSize,
  askStrategy,
  askTimeInForceType,
  askTifDuration,
  hideOddLots,
  displayQuotesInShares,
  sendAttributable
} from './montage-preferences/montage-preferences.form-fields';
import { orderSettingsField } from './order-settings/order-settings.form-fields';
import { hiddenFormInfo } from './user-preferences.form-fields';
import { makeTIFDisplayFields } from '@app/forms/common/fields/TIF-field/TIF-field.common';
import { QuantityType } from '@oms/generated/frontend';
import type { UserPreferencesFieldContract } from './user-preferences.types';

export const userPreferencesFormContract = FormContract.create<UserPreferencesFieldContract>()
  .fields({
    // hidden fields
    hiddenFormInfo,
    // bid
    bidInitiateOrder,
    bidQuantityType,
    bidQuantityValue,
    bidOrderSize,
    bidPriceType,
    bidDestinationId,
    bidDisplaySize,
    bidTimeInForceType,
    bidGtdTimestamp,
    bidTifDuration,
    bidSideType,
    bidStrategy,
    // ask
    askInitiateOrder,
    askQuantityType,
    askQuantityValue,
    askOrderSize,
    askPriceType,
    askDestinationId,
    askDisplaySize,
    askTimeInForceType,
    askGtdTimestamp,
    askTifDuration,
    askSideType,
    askStrategy,
    // common
    hideOddLots,
    displayQuotesInShares,
    sendAttributable,
    // order settings
    orderSettings: orderSettingsField
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box(
        'wrapper',
        [
          FieldDefinition.heading('Left click settings', {
            sx: {
              marginBottom: 'medium'
            }
          }),
          FieldDefinition.box('user-preferences-tabs', [
            {
              name: 'left-click-settings-tabs',
              component: 'tabs',
              tabs: {
                defaultValue: 'montageSettings',
                orientation: 'horizontal',
                config: {
                  montageSettings: {
                    title: 'Montage settings',
                    fields: [
                      FieldDefinition.box(
                        'montage-settings-wrapper',
                        [
                          {
                            name: 'left-click-settings-tabs',
                            component: 'tabs',
                            tabs: {
                              defaultValue: 'bidSettings',
                              orientation: 'horizontal',
                              config: {
                                bidSettings: {
                                  title: 'Bid settings',
                                  fields: [
                                    FieldDefinition.box('bidSettings-section-wrapper', [
                                      f.bidInitiateOrder,
                                      FieldDefinition.simpleGrid(`bidSettings-main-fields`, 3, [
                                        f.bidSideType,
                                        FieldDefinition.box(`bidSettings-quantity-fields-wrapper`, [
                                          FieldDefinition.box(
                                            'bidSettings-quantity-fields-wrapper',
                                            [
                                              FieldDefinition.box(
                                                `bidSettings-quantity-type`,
                                                [f.bidQuantityType],
                                                {
                                                  style: {
                                                    flexGrow: 1
                                                  }
                                                }
                                              ),
                                              FieldDefinition.box(
                                                `bidSettings-quantity-value`,
                                                [f.bidQuantityValue],
                                                {
                                                  condition: {
                                                    when: `${f.bidQuantityType.name}`,
                                                    is: QuantityType.ExplicitValue
                                                  },
                                                  style: {
                                                    marginLeft: '4px',
                                                    width: 'calc(72% - 4px)'
                                                  }
                                                }
                                              ),
                                              FieldDefinition.box(
                                                `bidSettings-quantity-orderSize`,
                                                [f.bidOrderSize],
                                                {
                                                  condition: {
                                                    when: `${f.bidQuantityType.name}`,
                                                    is: QuantityType.OrderSizes
                                                  },
                                                  style: {
                                                    marginLeft: '4px',
                                                    width: 'calc(72% - 4px)'
                                                  }
                                                }
                                              )
                                            ],
                                            {
                                              sx: {
                                                display: 'flex'
                                              }
                                            }
                                          )
                                        ]),
                                        f.bidPriceType,
                                        makeTIFDisplayFields(
                                          'bidTimeInForceGroup',
                                          f.bidTimeInForceType,
                                          f.bidGtdTimestamp,
                                          f.bidTifDuration
                                        ),
                                        f.bidDisplaySize,
                                        f.bidDestinationId
                                      ]),
                                      FieldDefinition.simpleGrid(`bidSettings-strategy-fields`, 3, [
                                        f.bidStrategy
                                      ])
                                    ])
                                  ]
                                },
                                askSettings: {
                                  title: 'Ask settings',
                                  fields: [
                                    FieldDefinition.box('askSettings-section-wrapper', [
                                      f.askInitiateOrder,
                                      FieldDefinition.simpleGrid(`askSettings-main-fields`, 3, [
                                        f.askSideType,
                                        FieldDefinition.box(`askSettings-quantity-fields-wrapper`, [
                                          FieldDefinition.box(
                                            'askSettings-quantity-fields-wrapper',
                                            [
                                              FieldDefinition.box(
                                                `askSettings-quantity-type`,
                                                [f.askQuantityType],
                                                {
                                                  style: {
                                                    flexGrow: 1
                                                  }
                                                }
                                              ),
                                              FieldDefinition.box(
                                                `askSettings-quantity-value`,
                                                [f.askQuantityValue],
                                                {
                                                  condition: {
                                                    when: `${f.askQuantityType.name}`,
                                                    is: QuantityType.ExplicitValue
                                                  },
                                                  style: {
                                                    marginLeft: '4px',
                                                    width: 'calc(72% - 4px)'
                                                  }
                                                }
                                              ),
                                              FieldDefinition.box(
                                                `askSettings-quantity-orderSize`,
                                                [f.askOrderSize],
                                                {
                                                  condition: {
                                                    when: `${f.askQuantityType.name}`,
                                                    is: QuantityType.OrderSizes
                                                  },
                                                  style: {
                                                    marginLeft: '4px',
                                                    width: 'calc(72% - 4px)'
                                                  }
                                                }
                                              )
                                            ],
                                            {
                                              sx: {
                                                display: 'flex'
                                              }
                                            }
                                          )
                                        ]),
                                        f.askPriceType,
                                        makeTIFDisplayFields(
                                          'askTimeInForceGroup',
                                          f.askTimeInForceType,
                                          f.askGtdTimestamp,
                                          f.askTifDuration
                                        ),
                                        f.askDisplaySize,
                                        f.askDestinationId
                                      ]),
                                      FieldDefinition.simpleGrid(`askSettings-strategy-fields`, 3, [
                                        f.askStrategy
                                      ])
                                    ])
                                  ]
                                }
                              }
                            }
                          }
                        ],
                        {
                          sx: {
                            marginBottom: 'large'
                          }
                        }
                      ),
                      FieldDefinition.box('', [f.hideOddLots, f.displayQuotesInShares, f.sendAttributable], {
                        sx: { display: 'flex', justifyContent: 'flex-start' }
                      })
                    ]
                  },
                  orderSettings: {
                    title: 'Order settings',
                    fields: [f.orderSettings]
                  }
                }
              }
            }
          ])
        ],
        {
          sx: { padding: 4 }
        }
      )
    ]
  }))
  .template('simple', {
    showSubmitButton: true,
    showResetButton: true,
    submitLabel: 'Save',
    resetLabel: 'Reset'
  });

export type UserPreferencesFormContractType = typeof userPreferencesFormContract;
export type UserPreferencesContractFormValues =
  InferFormValuesFromFormContract<UserPreferencesFormContractType>;
