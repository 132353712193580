import {
  ModalWindow,
  ReactBrowserModalWindowActorSchemaBuilder,
  type ReactActorComponentProps
} from '@valstro/workspace-react';
import { commonCommandPaletteSchemaOverrides } from './command-palette.common.actor';
import { CommonCommandPaletteComponent } from './command-palette.component';
import { COMMON_PLATFORM_NAME } from '@valstro/workspace';
import {
  COMMON_COMMAND_PALETTE,
  type CommandPaletteContext,
  type CommandPaletteOperations,
  type CommonCommandPaletteActorSchema,
  type CommonCommandPaletteActorSchemaOptions
} from '@app/common/command-palette/command-palette.contracts';

export const BROWSER_COMMAND_PALETTE_ACTOR_NAME = 'browser-command-palette';

const View: React.FC<ReactActorComponentProps<CommonCommandPaletteActorSchema>> = ({ actor }) => {
  return (
    <ModalWindow<CommonCommandPaletteActorSchema> actor={actor}>
      <CommonCommandPaletteComponent actor={actor} />
    </ModalWindow>
  );
};

const BrowserCommandPaletteActorSchemaBuilder = ReactBrowserModalWindowActorSchemaBuilder.extend<
  CommandPaletteContext,
  CommandPaletteOperations
>((prevSchema) => ({
  ...commonCommandPaletteSchemaOverrides(prevSchema),
  type: COMMON_COMMAND_PALETTE.TYPE,
  name: BROWSER_COMMAND_PALETTE_ACTOR_NAME,
  supportedPlatforms: [COMMON_PLATFORM_NAME.BROWSER]
})).extendView(View);

export const browserCommandPaletteActor =
  BrowserCommandPaletteActorSchemaBuilder.optionsCreator<CommonCommandPaletteActorSchemaOptions>();

export type BrowserCommandPaletteActorSchema = typeof BrowserCommandPaletteActorSchemaBuilder.schema;
