import { CurrenciesService } from '@app/data-access/services/reference-data/currencies/currencies.service';
import type {
  AdvancedSelectQueryFn,
  AdvancedSelectQueryTypeaheadParams,
  ComboBoxItem,
  IAdvancedSelectField
} from '@oms/frontend-foundation';
import type { CurrencyFragment } from '@oms/generated/frontend';
import type { Subscription } from 'rxjs';

export type CurrencyComboBoxData = CurrencyFragment;
export type CurrencyComboBoxValue = CurrencyFragment;
export type AdvancedSelectCurrencyField = IAdvancedSelectField<CurrencyComboBoxValue>;

export const createCurrencyComboboxItem = (
  currency: Partial<CurrencyFragment> & Pick<CurrencyFragment, 'id' | 'longName'>
): ComboBoxItem<CurrencyFragment> => {
  return {
    id: currency.id,
    value: currency,
    sublabel: String(currency.longName || ''),
    label: currency.id,
    type: 'item'
  };
};

export const watchAllCurrenciesQuery: AdvancedSelectQueryFn<CurrencyFragment> = (container) => {
  const currenciesService = container.resolve(CurrenciesService);

  let subscription: Subscription | undefined;

  const query = ({ inputValue, callback }: AdvancedSelectQueryTypeaheadParams<CurrencyFragment>) => {
    subscription?.unsubscribe();

    const allCurrencies$ = currenciesService.watchAll$(inputValue);

    subscription = allCurrencies$.subscribe(({ results, isFetching, error }) => {
      if (results) {
        callback({
          results: results.filter((c) => c.id).map((c) => createCurrencyComboboxItem(c)),
          isFetching: false
        });
      }

      if (isFetching) {
        callback({
          isFetching
        });
      }

      if (error) {
        callback({
          results: [],
          isFetching: false,
          error
        });
      }
    });
  };

  /**
   * Clean up fn to cancel any pending requests
   */
  return {
    type: 'typeahead',
    query: (params) => {
      query(params);
    },
    unsubscribe: () => {
      subscription?.unsubscribe();
    }
  };
};

export default watchAllCurrenciesQuery;
