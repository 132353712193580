import { inject, singleton } from 'tsyringe';
import { type Observable, map, merge } from 'rxjs';
import type { FetchPolicy } from '@apollo/client';
import { Logger, compactMap } from '@oms/shared/util';
import { type DataSourceCommon, asObservableDataSource } from '@oms/frontend-foundation';
import {
  type WorkingOpenQuantityUpdatedSubscription,
  type WorkingOpenQuantityUpdatedSubscriptionVariables,
  WorkingOpenQuantityUpdatedDocument,
  type WorkingOpenAggregate,
  type GetWorkingOpenQuantityByInstrumentAndOwnerIdQueryVariables,
  GetWorkingOpenQuantityByInstrumentAndOwnerIdDocument,
  type GetWorkingOpenQuantityByInstrumentAndOwnerIdQuery
} from '@oms/generated/frontend';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';

@singleton()
export class WorkingOpenQuantityService {
  protected name: string = 'WorkingOpenQuantityService';
  protected logger: Logger;
  protected fetchPolicy: FetchPolicy = 'network-only';

  constructor(
    @inject(ApolloClientRPC) protected apolloClient: ApolloClientRPC,
    @inject(GQLResponse) protected gqlResponse: GQLResponse
  ) {
    this.logger = Logger.labeled(this.name);
  }

  public watchAll$ = (
    variables?: GetWorkingOpenQuantityByInstrumentAndOwnerIdQueryVariables
  ): Observable<DataSourceCommon<WorkingOpenAggregate>> => {
    return merge(
      this._watchQuery_GetWorkingOpenQuantityByInstrumentAndOwnerId$(variables),
      this._subscribe_WorkingOpenQuantityUpdatedSubscription$()
    ).pipe(
      asObservableDataSource({
        onError: (e) => {
          this.logger.scope('watchAll$').error(e);
        }
      })
    );
  };

  protected _watchQuery_GetWorkingOpenQuantityByInstrumentAndOwnerId$(
    variables?: GetWorkingOpenQuantityByInstrumentAndOwnerIdQueryVariables
  ): Observable<WorkingOpenAggregate[]> {
    return this.apolloClient
      .watchQuery<
        GetWorkingOpenQuantityByInstrumentAndOwnerIdQuery,
        GetWorkingOpenQuantityByInstrumentAndOwnerIdQueryVariables
      >({
        query: GetWorkingOpenQuantityByInstrumentAndOwnerIdDocument,
        variables,
        fetchPolicy: this.fetchPolicy
      })
      .pipe(map(({ data }) => compactMap([data.getWorkingOpenQuantityByInstrumentAndOwnerId])));
  }

  protected _subscribe_WorkingOpenQuantityUpdatedSubscription$(): Observable<WorkingOpenAggregate[]> {
    return this.apolloClient
      .subscribe<WorkingOpenQuantityUpdatedSubscription, WorkingOpenQuantityUpdatedSubscriptionVariables>({
        query: WorkingOpenQuantityUpdatedDocument,
        fetchPolicy: this.fetchPolicy
      })
      .pipe(map(({ data }) => compactMap([data?.workingOpenQuantityUpdated?.workingOpenAggregate])));
  }
}

export default WorkingOpenQuantityService;
