import { singleton } from 'tsyringe';
import { type EventHandler, type EventSource, type GridEvent } from '@oms/frontend-vgrid';
import type { CrossPrincipalFillRow } from '@app/widgets/trading/cross-principal-fill/cross-principal-fill.form-common';
import { handleOnCellValueChanged, handleOnRowClicked } from './cross-principal-fill.state.handler';

// we need to prevent events from the grid row if a user interacts with the quantity input
// (preventing propagation from the input doesn't stop grid row click event)
const triggeredFromQuantityInput = (target: EventTarget | null | undefined): boolean => {
  if (!target) return false;

  if (target instanceof Element) {
    return target.getAttribute('col-id') === 'state.fillQuantity' ||
      (target.tagName === 'INPUT' && 'type' in target && target.type !== 'checkbox');
  }

  return false;
};

abstract class OrderCrossEventHandlerService implements EventHandler<CrossPrincipalFillRow> {
  abstract name: string;

  public addEvents(eventSource: EventSource<keyof GridEvent, CrossPrincipalFillRow>): void {
    eventSource.add('onRowClicked', (args) => {
      if (!triggeredFromQuantityInput(args.event?.target)) {
        handleOnRowClicked(args.context.formId, args.data!);
      }
    });

    eventSource.add('onCellValueChanged', (args) => {
      handleOnCellValueChanged(args.context.formId, args.data);
    });
  }

  public removeEvents(): void { }
}

@singleton()
export class OrderCrossBuySideService extends OrderCrossEventHandlerService {
  public name = 'order-cross-buy-side-events';
}

@singleton()
export class OrderCrossSellSideService extends OrderCrossEventHandlerService {
  public name = 'order-cross-sell-side-events';
}

