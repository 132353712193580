import { useRef } from 'react';
import { useVGrid, VGrid, ROW_SELECTION_COUNT_ACTION_TYPE, toClientsideGridData } from '@oms/frontend-vgrid';
import type { CurrencyFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { CurrenciesService } from '@app/data-access/services/reference-data/currencies/currencies.service';
import { createAction, updateAction, deleteAction } from './currencies-grid.actions';
import { currencyColumnLibrary } from './currencies-grid.columns';
import { OfflineGridEventHandler } from '@app/data-access/services/system/grids/offline-grid.event-handler';

export const CurrenciesGridWidgetComponent = () => {
  const currenciesService = useService(CurrenciesService);
  const query$ = useRef(currenciesService.watchAll$().pipe(toClientsideGridData()));

  const gridProps = useVGrid<CurrencyFragment>(
    'currencies',
    (builder) =>
      builder
        .sideBar()
        .columnLibrary(currencyColumnLibrary)
        .rowSelection((c) => c.multiple())
        .datasource((d) => d.source(query$.current).rowId((r) => r.data.id))
        .injectEvents([OfflineGridEventHandler])
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(createAction)
                .action(updateAction)
                .action(deleteAction)
            )
            .settings((s) => s.fromFile({}))
        ),
    []
  );

  return <VGrid {...gridProps} />;
};

export default CurrenciesGridWidgetComponent;
