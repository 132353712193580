import { useState, useCallback, useEffect } from 'react';
import { InputGroup, InputGroupProps } from './input-group';
import { Tag } from '../tag/tag';

export type InputMultiProps = Omit<InputGroupProps, 'onChange'> & {
  inputValue?: string;
  onChange?: (value: string) => void;
  onSelectionChange: (values: string[]) => void;
};

export const InputMulti = ({
  value = '',
  inputValue: _inputValue = '',
  onChange = () => null,
  onSelectionChange = () => null,
  ...props
}: InputMultiProps) => {
  const [inputValue, setInputValue] = useState<string>(_inputValue);
  const [selections, setSelections] = useState(new Set<string>(Array.isArray(value) ? value : [value]));

  useEffect(() => {
    if (!value || (value && !(value as string[]).length)) {
      setSelections(new Set());
    }
  }, [value]);

  const handleRemove = useCallback(
    (selection: string) => {
      const newValues = new Set(selections);
      newValues.delete(selection);

      setSelections(newValues);
      onSelectionChange(Array.from(newValues.values()));
    },
    [onSelectionChange, selections]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Backspace' && inputValue === '') {
        const lastValue = Array.from(selections).pop();
        handleRemove(lastValue);
        return;
      }

      if (e.key !== 'Enter') return;

      e.preventDefault();

      if (inputValue) {
        const newValues = new Set(selections);
        newValues.add(inputValue);

        setSelections(newValues);
        onSelectionChange(Array.from(newValues.values()));
        setInputValue('');
      }
    },
    [handleRemove, inputValue, onSelectionChange, selections]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.currentTarget.value);
      onChange(e.currentTarget.value);
    },
    [onChange]
  );

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <InputGroup {...props} value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyDown}>
      {Array.from(selections.values()).map((selection) => (
        // eslint-disable-next-line react/jsx-no-bind
        <Tag key={selection} onDelete={() => handleRemove(selection)}>
          {selection}
        </Tag>
      ))}
    </InputGroup>
  );
};
