import {
  TabbableField,
  FieldTypeEnum,
  InputTypeEnum,
  TabbableServiceSettings
} from './tabbable.service.types';
import {
  isAlphaKeyOnly,
  isNumberKeyOnly,
  isNumericKeyOnly,
  getFieldType,
  isTimeKeyOnly,
  isNumericKeyWithShortcutsOnly,
  selectOptionByNumber
} from './tabbable.service.utils';
export class TabbableService {
  private readonly defaultOptions: TabbableServiceSettings = {
    sequence: false,
    sequenceIfEmpty: false,
    sequenceSelectAsNumeric: false
  };

  private readonly fields: Map<HTMLElement, TabbableField> = new Map<HTMLElement, TabbableField>();
  private ref: HTMLElement | null = null;
  private options: TabbableServiceSettings = this.defaultOptions;

  constructor(options: Partial<TabbableServiceSettings> = {}) {
    this.options = { ...this.defaultOptions, ...options };
    this._setFields.bind(this);
  }

  /**
   * Start service
   * - Stops service is already running
   * - Adds event listeners
   * - Resets/adds options
   * - Assigns/maps fields in tabbable
   *
   * @param ref
   * @param options
   */
  public start(ref: HTMLElement, options: Partial<TabbableServiceSettings> = {}) {
    if (this.ref) {
      this.stop();
    }

    this.ref = ref;
    this.options = { ...this.defaultOptions, ...options };

    this.ref.addEventListener('keydown', this.onKeyDown.bind(this));
    this.ref.addEventListener('focusin', this.onFocusIn.bind(this));
    this.ref.addEventListener('focusout', this.onFocusOut.bind(this));

    this._setFields();
  }

  /**
   * Stops the service
   * - Cleans up listeners
   */
  public stop() {
    this.ref?.removeEventListener('keydown', this.onKeyDown.bind(this));
    this.ref?.removeEventListener('focusin', this.onFocusIn.bind(this));
    this.ref?.removeEventListener('focusin', this.onFocusOut.bind(this));
  }

  /**
   * Update settings
   *
   * @param options
   */
  public updateSettings(options: Partial<TabbableServiceSettings> = {}) {
    this.options = { ...this.defaultOptions, ...options };
  }

  /**
   * Useful to restart/refresh service with new options/ref
   *
   * @param ref
   * @param options
   */
  public refresh(ref?: HTMLElement, options?: Partial<TabbableServiceSettings>) {
    this.start(ref ? ref : this.ref, options);
  }

  /**
   * Key down handler for any element inside ref
   *
   * @param e
   */
  private onKeyDown(e: KeyboardEvent) {
    if (this.options.sequence) {
      // Handle sequencing functionality (aplha-numeric-alpha-numeric)
      this.handleSequencing(e);
    }
  }

  /**
   * Focus handler for an any element inside of ref
   *
   * @param e
   */
  private onFocusIn(e: FocusEvent) {
    const target = e.target as HTMLElement;

    // Set focused state
    if (this.fields.has(target)) {
      this.fields.get(target).isFocused = true;
    }
  }

  /**
   * Blur handler for unfocusing any element inside of ref
   *
   * @param e
   */
  private onFocusOut(e: FocusEvent) {
    const target = e.target as HTMLElement;

    // Remove focus state
    if (this.fields.has(target)) {
      this.fields.get(target).isFocused = false;
    }
  }

  private handleSequencing(e: KeyboardEvent) {
    e.stopImmediatePropagation();

    // Get current field
    const field = this.fields.get(e.target as HTMLElement);

    // Guard to check field exists in tabbable + isFocused
    if (!field || !field.isFocused) {
      return;
    }

    // Get the next field
    const [$nextField, nextField] = this._getNextField(field) || [];

    // Make sure another field exists
    if (!nextField) {
      return;
    }

    // Check the tabbing behaviour matches the conditions to sequence
    const isAlphaToNumberOrNumeric = field.fieldType === FieldTypeEnum.ALPHANUMERIC && isNumberKeyOnly(e.key);

    const isNumberToAlpha = field.fieldType === FieldTypeEnum.NUMBER && isAlphaKeyOnly(e.key);

    const isNumericToAlpha =
      field.fieldType === FieldTypeEnum.NUMERIC && !isNumericKeyOnly(e.key) && isAlphaKeyOnly(e.key);

    const isNumericWithShortcutsToAlpha =
      field.fieldType === FieldTypeEnum.NUMERIC_SHORTCUTS &&
      !isNumericKeyWithShortcutsOnly(e.key) &&
      isAlphaKeyOnly(e.key);

    const isTimeToAlpha =
      field.fieldType === FieldTypeEnum.TIME && !isTimeKeyOnly(e.key) && isAlphaKeyOnly(e.key);

    // Dont Sequence If Empty
    const doesNotHaveFieldValue = !field.isSelect && (field.element as HTMLInputElement).value === '';
    const dontSequenceIfEmpty = !this.options.sequenceIfEmpty && doesNotHaveFieldValue;

    // Do not sequence if the conditions are not met
    if (
      dontSequenceIfEmpty ||
      (!isAlphaToNumberOrNumeric &&
        !isNumberToAlpha &&
        !isNumericToAlpha &&
        !isTimeToAlpha &&
        !isNumericWithShortcutsToAlpha)
    ) {
      return;
    }

    const isAlphaNumericToAlphaNumeric =
      this.options.sequence &&
      field.fieldType === FieldTypeEnum.ALPHANUMERIC &&
      nextField.fieldType === FieldTypeEnum.ALPHANUMERIC;

    // Guard to stop tabbing from an alphanumeric field, if the next field is also alphanumeric
    // and is NOT a select.
    if (isAlphaNumericToAlphaNumeric && nextField.isSelect === false) {
      return;
    }

    // Guard to stop alphanumeric-alphanumeric jumping if next field is a select and a key is a number
    // i.e preventing sequenceSelectAsNumeric behaviour
    if (
      isAlphaNumericToAlphaNumeric &&
      nextField.isSelect === true &&
      this.options.sequenceSelectAsNumeric === false
    ) {
      return;
    }

    // If tabbing from alphanumeric-alphanumeric but the next field is a select
    // we can use numbers to pick the option. 0 = skip (so long as the field after is numeric)
    if (
      this.options.sequenceSelectAsNumeric &&
      isAlphaNumericToAlphaNumeric &&
      nextField.isSelect === true &&
      isAlphaToNumberOrNumeric
    ) {
      // If 0, skip select, and jump straight there.
      if (e.key === '0') {
        const [$nextNextField, nextNextField] = this._getNextField(nextField) || [];
        if ($nextNextField) {
          e.preventDefault();
          e.stopPropagation();
          this._focusFieldOrNextAvailableField([$nextNextField, nextNextField]);
        } else {
          this._focusFieldOrNextAvailableField([$nextField, nextField]);
        }

        return;
      }

      const selectNumber = Number(e.key);

      if (isNaN(selectNumber) === false) {
        $nextField.focus();
        selectOptionByNumber($nextField as HTMLSelectElement, selectNumber);
      }

      return;
    }

    // Focus next available field
    const [$focusedField, focusedField] = this._focusFieldOrNextAvailableField([$nextField, nextField]);

    // If the field is an alphanumeric field
    // Add the last typed key into the field
    if (
      this.options.sequence &&
      e.key &&
      $focusedField instanceof HTMLInputElement &&
      focusedField.fieldType === FieldTypeEnum.ALPHANUMERIC
    ) {
      setTimeout(() => {
        $focusedField.value = `${e.key}${$focusedField.value}`;
      }, 100);
    }
  }

  /**
   * Helper function to focus a field, or the next available one
   *
   * @param startingField: [HTMLElement, TabbableField]
   */
  private _focusFieldOrNextAvailableField(
    startingField: [HTMLElement, TabbableField]
  ): [HTMLElement, TabbableField] | null {
    const [$field, field] = startingField;

    if (($field as HTMLInputElement).readOnly || ($field as HTMLInputElement).disabled) {
      const [$nextField, nextField] = this._getNextField(field);
      if ($nextField) {
        return this._focusFieldOrNextAvailableField([$nextField, nextField]);
      } else {
        return null;
      }
    } else {
      $field.focus();
      return [$field, field];
    }
  }

  /**
   * Helper function to get the next field
   *
   * @param field
   * @returns TabbableField
   */
  private _getNextField(field: TabbableField) {
    return Array.from(this.fields).find(([_k, v]) => v.index === field.index + 1);
  }

  /**
   * Helper function to find all tabbable fields inside tabbable ref
   * and assign/map them with the correct field type
   */
  private _setFields() {
    this.ref.querySelectorAll('input, select, textrea').forEach(($element, index) => {
      this.fields.set($element as HTMLElement, {
        element: $element as HTMLElement,
        index,
        fieldType: getFieldType($element),
        isFocused: document.activeElement === $element,
        isSelect: $element.tagName === InputTypeEnum.SELECT
      });
    });
  }
}
