import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { type MontageBumpPriceFormOutput } from './bump-price.form-common';
import { FieldContract } from '@oms/frontend-foundation';
import { makeCommonRequiredProps } from '@app/forms/common/fields/shared-field-props';

const fc = FieldContract.create<MontageBumpPriceFormOutput, AdditionalFormFieldUnion>();

export const bumpPrice = fc.field('bumpPrice', 'number-format').options({
  ...makeCommonRequiredProps(),
  label: 'Bump price',
  wrapperSx: {
    width: 'full'
  }
});
