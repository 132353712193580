import { openCancelExecutions } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import { type ExecutionInfoFragment } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';

export const cancelExecutionsOnChange = async (
  ctx: ActionContext<ExecutionInfoFragment, ActionComponentConfig<ExecutionInfoFragment>>
) => {
  const { lifecycle, data } = ctx;
  const investorOrderId = data[0]?.investorOrderId;
  const executionIds = data.map((e) => e.id);

  const isDisabled = !investorOrderId || data.length === 0 || data.some((e) => !e.executedQuantity);

  ctx.notify({ isDisabled });

  if (lifecycle === 'change') {
    if (isDisabled) {
      return;
    }

    await openCancelExecutions(PROCESS_ID.LEADER, {
      title: `${t('app.orders.orderMonitor.cancelExecutions')}: ${executionIds.length}`,
      form: {
        formId: `cancel-executions-${investorOrderId}`,
        input: {
          orderId: investorOrderId,
          executionIds
        }
      }
    });
  }
};

export const cancelExecutionsAction: ActionDefFactory<ExecutionInfoFragment> = (builder) =>
  builder
    .name('cancel_executions')
    .toolbar((t) =>
      t.component('action-button').id('cancel_executions_button').location('HorizontalToolbarRight').props({
        isDisabled: true,
        content: 'Cancel'
      })
    )
    .menu((m) => m.name('Cancel').visible(({ rowData }) => !!rowData))
    .onChange<ActionComponentConfig<ExecutionInfoFragment>>(cancelExecutionsOnChange);
