import { inject, singleton } from 'tsyringe';
import {
  type RequestCrossTradeMutationVariables,
  type RequestCrossTradeMutation,
  type VisibleInvestorOrder,
  RequestCrossTradeDocument,
  OrderType,
  OrderSide,
  OrderSettleType
} from '@oms/generated/frontend';
import { type Observable, map } from 'rxjs';
import { type CrossPrincipalFillRow } from '@app/widgets/trading/cross-principal-fill/cross-principal-fill.form-common';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import { getState$ } from './cross-principal-fill.state';
import { InvestorOrdersService } from '../investor-orders/investor-orders.service';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { getVisibleInvestorOrderFilter } from '@app/data-access/services/trading/data-access.trading.utils';

@singleton()
export class CrossPrincipalFillService {
  constructor(
    @inject(GQLResponse) private gqlResponse: GQLResponse,
    @inject(InvestorOrdersService) private ordersService: InvestorOrdersService,
    @inject(AuthService) private authService: AuthService
  ) {}

  async getById(investorOrderId: string) {
    return await this.ordersService.getById(investorOrderId);
  }

  async getOrders(
    { instrument, calculatedSettlementDate, settleType }: Partial<VisibleInvestorOrder>,
    orderSide: OrderSide
  ) {
    if (!instrument) {
      console.error('Instrument required!');
      return;
    }

    const calculatedSettlementDateFilter =
      calculatedSettlementDate && settleType !== OrderSettleType.WhenIssued
        ? { calculatedSettlementDate: { equalTo: calculatedSettlementDate } }
        : undefined;

    const orderSettleTypeFilter =
      settleType === OrderSettleType.WhenIssued
        ? { settleType: { equalTo: OrderSettleType.WhenIssued } }
        : undefined;

    const ownerId = this.authService.getUserId() || '';

    return await this.ordersService.getOrders({
      filter: {
        ...getVisibleInvestorOrderFilter(instrument.id, orderSide, ownerId),
        ...calculatedSettlementDateFilter,
        ...orderSettleTypeFilter
      }
    });
  }

  getRows$(formId: string, side: OrderSide): Observable<CrossPrincipalFillRow[]> {
    return getState$(formId).pipe(
      map((state) =>
        (side === OrderSide.Buy ? state.buySideRows : state.sellSideRows).map((row) => {
          return {
            ...row,
            price: row.order.limitPrice || OrderType.Market
          };
        })
      )
    );
  }

  async requestCrossTrade(variables: RequestCrossTradeMutationVariables) {
    const mutation = this.gqlResponse.wrapMutate<
      RequestCrossTradeMutation,
      RequestCrossTradeMutationVariables
    >({
      mutation: RequestCrossTradeDocument,
      variables
    });

    return await mutation.awaitAsyncResponse().exec();
  }
}
