import type { RxDocument } from 'rxdb-v15';
import type { Optional } from '@oms/shared/util-types';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { getCollectionsFrom } from '../../utils';
import { MONTAGE_STATE_DEFAULTS } from './montage.collection.constants';
import type { MontageCollection, MontageDocType } from './montage.collection.types';

export const setMontageStateFromCollection = async (
  scopedActorId: string,
  collection: MontageCollection,
  stateChange: Partial<MontageDocType> = {}
): Promise<Optional<RxDocument<MontageDocType>>> => {
  const document = await collection.findOne({ selector: { scopedActorId: { $eq: scopedActorId } } }).exec();
  if (document) {
    return await document.incrementalPatch({ ...stateChange });
  } else {
    return await collection.incrementalUpsert({ scopedActorId, ...MONTAGE_STATE_DEFAULTS, ...stateChange });
  }
};

export const setMontageState = async (
  scopedActorId: string,
  workspace: AppWorkspace,
  stateChange: Partial<MontageDocType> = {}
): Promise<Optional<RxDocument<MontageDocType>>> => {
  const collection = getCollectionsFrom(workspace).montage;
  return await setMontageStateFromCollection(scopedActorId, collection, stateChange);
};
