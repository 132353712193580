import {
  type ActionDefFactory,
  INLINE_SPLIT_BUTTON_ACTION,
  type SplitButtonActionComponentConfig
} from '@oms/frontend-vgrid';
import { type IoRepairRequestFragment } from '@oms/generated/frontend';
import { retryInvestorOrdersOnChange } from './retry.investor-orders.action';
import { repairInvestorOrdersOnChange } from './repair.investor-orders.action';

export const repairQueueInlineActions: ActionDefFactory<IoRepairRequestFragment> = (
  builder
) =>
  builder
    .name('inline_repair_queue_split_button')
    .inline((col) =>
      col
        .header('Actions')
        .initialWidth(110)
        .filter(false)
        .cell((c) =>
          c.renderer(INLINE_SPLIT_BUTTON_ACTION, {
            variant: 'secondary',
            actionsConfig: [
              { id: 'retry', title: 'Retry' },
              { id: 'repair', title: 'Repair' }
            ]
          })
        )
    )
    .lifecycles('change', 'init')
    .onChange<SplitButtonActionComponentConfig<IoRepairRequestFragment>>(
      async (ctx) => {
        const { state, lifecycle } = ctx;

        switch (lifecycle) {
          case 'change': {
            const selectedId = state.selectedId;
            switch (selectedId) {
              case 'retry':
                return await retryInvestorOrdersOnChange(ctx);
              case 'repair':
                return await repairInvestorOrdersOnChange(ctx);
              default:
                throw new Error(`onChange not defined for ${selectedId}`);
            }
          }
        }
      }
    );
