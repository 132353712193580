import { type DisplayGridProps, DisplayGrid, Switch, Flex } from '@oms/shared-frontend/ui-design-system';
import { useCallback, type FC, type ChangeEventHandler, useEffect, useState } from 'react';
import { type Validator } from '@data-driven-forms/react-form-renderer';
import {
  useEnhancedFormApi,
  type FieldProps,
  type ICommonField,
  useFieldApi
} from '@oms/frontend-foundation';
import { type FORM_COMPONENT_TYPE } from '@app/forms/form-builder/common/form.contracts';
import * as styles from './trade-through-obligation-field.component.css';

const gridProps: DisplayGridProps = {
  gridProps: {
    columns: 9
  },
  items: [
    {
      label: 'Trade through',
      component: {
        type: 'Text',
        value: 'Yes'
      },
      colSpan: 3
    },
    {
      label: 'Side',
      component: {
        type: 'Text',
        value: 'Buy'
      },
      colSpan: 2
    },
    {
      label: 'Qty',
      component: {
        type: 'Numeric',
        value: 1200,
        format: 'quantity'
      },
      colSpan: 2
    },
    {
      label: 'Avg px',
      component: {
        type: 'Numeric',
        value: 270.06,
        format: 'number'
      },
      colSpan: 2
    }
  ]
};

export type TradeThrough = { allowSweep: boolean };

export interface ITradeThroughObligation<TValidator = Validator>
  extends ICommonField<typeof FORM_COMPONENT_TYPE.TRADE_THROUGH_OBLIGATION, TradeThrough, TValidator> {}

export const TradeThroughObligation: FC<FieldProps<{}>> = (props) => {
  const formApi = useEnhancedFormApi();

  const {
    input: { value }
  } = useFieldApi<{}, TradeThrough>(props);

  const [allowSweep, setAllowSweep] = useState(value.allowSweep);

  useEffect(() => {
    setAllowSweep(value.allowSweep);
  }, [value.allowSweep]);

  const handleAllowSeepChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      formApi.change(props.name, { allowSweep: target.checked });
    },
    [formApi, props.name]
  );

  return (
    <Flex>
      <DisplayGrid {...gridProps} labelMargin={6} width="550px" />
      <Switch
        checked={allowSweep}
        onChange={handleAllowSeepChange}
        sx={{ marginLeft: 10 }}
        className={styles.allowSweepSwitch}
      >
        Allow sweep
      </Switch>
    </Flex>
  );
};
