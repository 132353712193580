import { INumericInputOptions } from '../contracts/config-types';
import { formattedToRaw, rawToFormatted } from './formatting-utils';

/**
 * Given some input stiring, attempt to parse a valid number
 *
 * @param str string to parse
 * @param options INumericInputOptions instance
 * @returns A formatting string if correctly transformed, else an empty string
 */
export const parseValueString = (str: string, options: INumericInputOptions): string => {
  let multiplier = 1;
  let parsed = '';

  for (const c of str) {
    if (!isNaN(Number(c))) {
      // If a number
      parsed += c;
    } else if (c === options.decimal && parsed.indexOf(c) === -1) {
      // If a decimal (and no decimals exist so far)
      parsed += options.decimal;
    } else if (options.shortcuts[c] && typeof options.shortcuts[c] === 'number') {
      // If a shortcut
      multiplier *= options.shortcuts[c] as number;
    } else if (c === '-' && !parsed.length) {
      // If a minus sign (and parsed string is currently empty)
      parsed = c;
    }
  }

  if (!parsed.length) {
    return '';
  }

  // Need to ensure that delimiter is a '.' before parsing to number
  const normalisedNumber = formattedToRaw(parsed, options);
  // Then swap it back in
  const adjusted = rawToFormatted((normalisedNumber || 0) * multiplier, options);
  const tooLarge = adjusted.indexOf('e') !== -1;

  if (tooLarge) {
    return '';
  } else {
    return adjusted;
  }
};
