import { openRouteOrder } from '@app/generated/sdk';
import { type ActionDefFactory } from '@oms/frontend-vgrid';
import { PROCESS_ID } from '@valstro/workspace';
import { PlusIcon } from '@radix-ui/react-icons';
import { FORM_ENUM } from '@app/generated/common';
import type { AnyRecord } from '@oms/frontend-foundation';

export function createOpenEntryTradingOrderAction<T extends AnyRecord>(): ActionDefFactory<T> {
  return (builder) =>
    builder
      .name('open_trading_order_entry_trading_order')
      .toolbar((t) =>
        t
          .id('open_trading_order_entry_trading_order_button')
          .component('action-button')
          .location('HorizontalToolbarLeft')
          .props({
            variant: 'link',
            leftIcon: <PlusIcon />,
            content: 'Trading Order Entry'
          })
      )
      .onChange((ctx) => {
        if (ctx.lifecycle !== 'change') return;
        openRouteOrder(PROCESS_ID.LEADER, {
          title: 'Trading Order Entry',
          windowOptions: {
            meta: {
              widgetType: `${FORM_ENUM.ROUTE_ORDER}-CREATE` // Differentiate the widgetType so remember window feature saves this type of window separately
            }
          },
          form: {
            formId: `trading-order-entry`,
            input: {}
          }
        }).catch((e) => console.error(e));
      });
}
