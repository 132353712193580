import isEqual from 'lodash/isEqual';
import { type AnyFormValues } from '../../../types';

// Returns field name that triggered the last form values change
export const getUpdatedFieldName = (prevValues: AnyFormValues, newValues: AnyFormValues) => {
  for (const key in newValues) {
    if (!(key in prevValues) || !isEqual(prevValues[key], newValues[key])) {
      return key;
    }
  }

  for (const key in prevValues) {
    if (!(key in newValues)) {
      return key;
    }
  }

  return null;
};
