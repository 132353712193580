import { Box, HStack } from '@oms/shared-frontend/ui-design-system';
import { type FC, useEffect, useMemo } from 'react';
import { type Validator, useFieldApi } from '@data-driven-forms/react-form-renderer';
import { OrderSide } from '@oms/generated/frontend';
import { useDebouncedCallback } from 'beautiful-react-hooks';
import {
  type FieldProps,
  useService,
  type ICommonField,
  useEnhancedFormApi,
  useFormBuilderTemplate
} from '@oms/frontend-foundation';
import {
  type CrossPrincipalFillRow,
  type CrossPrincipalFillOutput,
  type CrossPrincipalFillOrders
} from '../cross-principal-fill.form-common';
import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import {
  buyOrderCrossColumnLibrary,
  sellOrderCrossColumnLibrary
} from './cross-principal-fill-orders-column-library';
import {
  OrderCrossBuySideService,
  OrderCrossSellSideService
} from '@app/data-access/services/trading/cross-principal-fill/cross-principal-fill.event-handler.service';
import { useDeepCompareEffect } from 'react-use';
import { CrossPrincipalFillService } from '@app/data-access/services/trading/cross-principal-fill/cross-principal-fill.service';
import { type FORM_COMPONENT_TYPE } from '@app/forms/form-builder/common/form.contracts';
import { useObservableState } from 'observable-hooks';
import {
  clearState,
  getCrossPrincipalFillOrders$,
} from '@app/data-access/services/trading/cross-principal-fill/cross-principal-fill.state';
import { handleOnValueChanged } from '@app/data-access/services/trading/cross-principal-fill/cross-principal-fill.state.handler';

export const CrossPrincipalFillBuySideGrid: FC<{ formId: string }> = ({ formId }) => {
  const service = useService(CrossPrincipalFillService);

  const gridProps = useVGrid<CrossPrincipalFillRow>(
    'cross-principal-fill-buy-side',
    (builder) =>
      builder
        .columnLibrary(buyOrderCrossColumnLibrary)
        .datasource((d) =>
          d.source(service.getRows$(formId, OrderSide.Buy)).rowId((r) => r.data.order.id || '')
        )
        .rowStateRules({
          disabled: (params) => {
            return params.data?.state.isDisabled || false;
          }
        })
        .injectEvents([OrderCrossBuySideService])
        .context({ side: OrderSide.Buy, formId })
        .stopEditingWhenCellsLoseFocus(),
    []
  );

  return <VGrid {...gridProps} />;
};

export const CrossPrincipalFillSellSideGrid: FC<{ formId: string }> = ({ formId }) => {
  const service = useService(CrossPrincipalFillService);

  const gridProps = useVGrid<CrossPrincipalFillRow>(
    'cross-principal-fill-sell-side',
    (builder) =>
      builder
        .columnLibrary(sellOrderCrossColumnLibrary)
        .datasource((d) =>
          d.source(service.getRows$(formId, OrderSide.Sell)).rowId((r) => r.data.order.id || '')
        )
        .rowStateRules({
          disabled: (params) => {
            return params.data?.state.isDisabled || false;
          }
        })
        .injectEvents([OrderCrossSellSideService])
        .context({ side: OrderSide.Sell, formId })
        .stopEditingWhenCellsLoseFocus(),
    []
  );

  return <VGrid {...gridProps} />;
};

export interface ICrossPrincipalFillOrdersGrid<TValidator = Validator>
  extends ICommonField<
    typeof FORM_COMPONENT_TYPE.CROSS_PRINCIPAL_FILL,
    CrossPrincipalFillOrders,
    TValidator
  > {}

export const CrossPrincipalFillOrdersGrid: FC<FieldProps<{}>> = (props) => {
  const { subscribe } = useEnhancedFormApi<CrossPrincipalFillOutput>();
  const {
    input: { onChange }
  } = useFieldApi(props);
  const service = useService(CrossPrincipalFillService);
  const { formId } = useFormBuilderTemplate();

  const memoizedValue$ = useMemo(() => {
    return getCrossPrincipalFillOrders$(formId);
  }, [formId]);

  const value = useObservableState(memoizedValue$);

  useEffect(() => {
    return () => {
      clearState(formId);
    };
  }, [formId]);

  useDeepCompareEffect(() => {
    onChange(value);
  }, [value]);

  const handleChange = useDebouncedCallback(
    ({ values }) => {
      void handleOnValueChanged(formId, values);
    },
    [service, formId],
    400
  );

  useEffect(() => {
    const unsub = subscribe(handleChange, { values: true });

    return () => {
      unsub();
    };
  }, [subscribe, handleChange]);

  return (
    <HStack spacing={2} style={{ width: '100%', minHeight: '200px' }} className="ag-auto-scroll-overflow">
      <Box sx={{ flex: 1 }} data-testid="buy-side-grid">
        <CrossPrincipalFillBuySideGrid formId={formId} />
      </Box>
      <Box sx={{ flex: 1 }} data-testid="sell-side-grid">
        <CrossPrincipalFillSellSideGrid formId={formId} />
      </Box>
    </HStack>
  );
};
