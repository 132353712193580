import { PROCESS_ID } from '@valstro/workspace';
import { openRejectWithReason } from '@app/generated/sdk';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { TradeRepairRequestService } from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import type { ActionContext, ActionComponentConfig, ActionDefFactory } from '@oms/frontend-vgrid';
import { type TradeRepairRequestFragment } from '@oms/generated/frontend';

const rejectRepairQueueTradesOnChange = async (
  ctx: ActionContext<TradeRepairRequestFragment, ActionComponentConfig<TradeRepairRequestFragment>>
) => {
  const { appContainer, lifecycle, data, workspace } = ctx;

  const selectedRows = data.length;

  ctx.notify({ isDisabled: selectedRows === 0 });

  if (lifecycle === 'change' && selectedRows > 0) {
    const [_, api] = await openRejectWithReason(workspace, PROCESS_ID.LEADER);
    const event = await api.awaitFirstEvent;
    switch (event.type) {
      case DIALOG_EVENT_TYPE.OK: {
        const tradeRepairRequestIds = data.map((row) => row.id || '');

        const tradeRepairRequestService = appContainer.resolve(TradeRepairRequestService);

        const response = await tradeRepairRequestService.rejectWithReason(
          tradeRepairRequestIds,
          event.payload as string
        );

        if (!response.isSuccess()) {
          console.error(response.errors);
        }
        break;
      }
    }
  }
};

export const rejectRepairQueueTradesAction: ActionDefFactory<TradeRepairRequestFragment> = (builder) =>
  builder
    .name('reject_repair_queue_trades')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('reject_repair_queue_trades_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: 'Reject'
        })
    )
    .menu((m) =>
      m
        .name('Reject')
        .visible(({ rowData }: { rowData?: TradeRepairRequestFragment }) => rowData?.id !== undefined)
    )
    .onChange<ActionComponentConfig<TradeRepairRequestFragment>>(rejectRepairQueueTradesOnChange);
