import type { OfflineDatabaseCollections } from '@app/data-access/offline/collections';
import type { GridTemplateDocument } from '@app/data-access/offline/collections/grid-templates.collection';
import {
  type GridsDocType,
  type GridsDocument,
  getCompositeId
} from '@app/data-access/offline/collections/grids.collection';
import { TEMPORARY_SNAPSHOT_ID } from '@app/data-access/offline/collections/snapshots.collection';
import { OfflineDb } from '@app/data-access/offline/offline-database';
import { inject, singleton } from 'tsyringe';

@singleton()
export class OfflineGridService {
  private collections: OfflineDatabaseCollections;

  constructor(@inject(OfflineDb) private offlineDb: OfflineDb) {
    this.collections = this.offlineDb.collections;
  }

  public getAll() {
    return this.collections.grids.find().exec();
  }

  public async find(
    gridId: string
  ): Promise<{ doc: GridsDocument; currentGridTemplateDoc: GridTemplateDocument | null } | null> {
    // Note, always use the temporary snapshot id for loading a grid
    // Because when loading a snapshot, we replace the snapshot grids with the temporary snapshot grids
    // This allows us to keep an auto-save that can be applied to a snapshot (if one is taken)
    // It also allows us to clean orphaned grids when a snapshot is loaded
    // thanks @doron
    const doc = await this.collections.grids.findOne(getCompositeId(gridId, TEMPORARY_SNAPSHOT_ID)).exec();

    if (!doc) {
      return null;
    }

    const currentGridTemplateDoc = (await doc.populate('currentGridTemplate')) as GridTemplateDocument | null;

    return {
      doc,
      currentGridTemplateDoc
    };
  }

  public findByWidgetActorId(widgetActorId: string) {
    return this.collections.grids.find({ selector: { widgetActorId } }).exec();
  }

  public create(options: Pick<GridsDocType, 'currentGridTemplate' | 'gridId' | 'state' | 'widgetActorId'>) {
    return this.collections.grids.upsert({
      // Note, always use the temporary snapshot id for loading a grid
      // Because when loading a snapshot, we replace the snapshot grids with the temporary snapshot grids
      // This allows us to keep an auto-save that can be applied to a snapshot (if one is taken)
      // It also allows us to clean orphaned grids when a snapshot is loaded
      // thanks @doron
      id: getCompositeId(options.gridId, TEMPORARY_SNAPSHOT_ID),
      snapshotId: TEMPORARY_SNAPSHOT_ID,
      ...options
    });
  }

  public updateDoc(doc: GridsDocument, options: Partial<GridsDocType>) {
    return doc.incrementalUpdate({
      $set: options
    });
  }

  public bulkRemove(gridIds: string[]) {
    return this.collections.grids.bulkRemove(gridIds);
  }
}
