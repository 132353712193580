import { Plugin } from '@valstro/workspace';
import { createLogger } from '@oms/shared/util';
import { createWorkspaceLeaderWindowReady$ } from '../../common/workspace/workspace.rxjs';
import {
  MDaaSProviderService,
  type IMDaaSProviderService
} from '../../data-access/services/marketdata/mdaas-provider.service';
import { Mdg2Client } from '@fds/wm-typescript-mdg2-client';
import { isTauri } from '@valstro/workspace';
import { Command } from '@tauri-apps/api/shell';
import { type AppWorkspace } from '@app/app-config/workspace.config';
import { AppState } from '@app/data-access/memory/app.stream';
import { combineLatest } from 'rxjs';
import { Level2WebsocketService } from '@app/data-access/services/marketdata/level2-websocket.service';
import { container, type DependencyContainer } from 'tsyringe';

export const marketdataPluginLogger = createLogger({ label: 'Marketdata Plugin' });

export interface MarketdataPluginOptions {
  MdgClient?: new (...args: any[]) => Mdg2Client;
  container: DependencyContainer;
}

/**
 * Creates the marketdata provider when the leader window is ready
 */
export const marketdataPlugin = (options: MarketdataPluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-marketdata-plugin',
    pluginFn: ({ workspace }) => {
      const appStateService = container.resolve(AppState);
      let marketdataProvider: IMDaaSProviderService | undefined;
      let sidecarStarted = false;
      let websocketService: Level2WebsocketService;

      const { MdgClient = Mdg2Client } = options || {};
      const leaderWindowReady$ = createWorkspaceLeaderWindowReady$(workspace);
      const ready$ = combineLatest([leaderWindowReady$, appStateService.ready$]);
      const sideCarSubscription = ready$.subscribe(() => {
        if (sidecarStarted) {
          return;
        }
        if (isTauri()) {
          const command = Command.sidecar('lib/factset-level2');
          command
            .execute()
            .then((_cmdOutput) => {
              sidecarStarted = true;
            })
            .catch((e) => {
              sidecarStarted = false;
              console.error('Error starting sidecar', e);
            });
        }

        // Do this regardless of isTauri() because we might be developing in browser with sidecar running manually
        const { container } = workspace.meta;

        if (!container) {
          throw new Error('Container is not defined for marketdata plugin');
        }

        websocketService = container.resolve(Level2WebsocketService);

        websocketService.connect();
      });

      const subscription = leaderWindowReady$.subscribe(() => {
        marketdataProvider?.dispose();
        const mdg2Client = new MdgClient(undefined, { shouldRestartSubscriptions: true });
        marketdataProvider = new MDaaSProviderService(workspace, mdg2Client);

        marketdataProvider.initialize();
        marketdataPluginLogger.info('Marketdata provider initialized');
      });

      // Cleanup function
      return async () => {
        await Promise.allSettled([
          sideCarSubscription.unsubscribe(),
          marketdataProvider?.dispose(),
          subscription.unsubscribe(),
          websocketService.destroy()
        ]);

        if (sidecarStarted) {
          new Command('npx', 'kill-port 8080').execute().then(
            (_) => {
              // Server killed
            },
            (_err) => console.error('This should not happen , but...', _err)
          );

          sidecarStarted = false;
        }
      };
    }
  });
