import { compactMap, convert } from '@oms/shared/util';
import type { ComboBoxDefaultValue, ComboBoxItem } from '../combo-box.types';
import type { AnyKey, Maybe, Optional, ValuedAndLabeled } from '@oms/shared/util-types';

type GetID<T extends ComboBoxDefaultValue> = {
  id?: (value: T) => string;
};
type GetLabel<T extends ComboBoxDefaultValue> = {
  label?: (value: T) => string;
};
type GetSublabel<T extends ComboBoxDefaultValue> = {
  sublabel?: (value: T) => Optional<string>;
};
type GetValue<T extends ComboBoxDefaultValue, V> = {
  value?: (value: T) => V;
};
type GetStringValue<T extends ComboBoxDefaultValue, V extends string> = {
  value?: (value: T) => V;
};
type GetIsDisabled<T extends ComboBoxDefaultValue> = {
  isDisabled?: (value: T) => Optional<boolean>;
};
type GetAvatar<T extends ComboBoxDefaultValue> = {
  showAvatar?: (value: T) => Optional<boolean>;
  avatarSrc?: (value: T) => Optional<string>;
  avatarCount?: (value: T) => Optional<number>;
};
type CommonOptions<T extends ComboBoxDefaultValue> = GetID<T> &
  GetLabel<T> &
  GetSublabel<T> &
  GetIsDisabled<T> &
  GetAvatar<T>;
type BooleanOptions = Parameters<typeof convert.boolean.to.string>[1] & CommonOptions<boolean>;
type StringOptions<S extends string> = CommonOptions<S>;
type NumberOptions<N extends number> = CommonOptions<N>;
type RecordOptions<R extends Record<string, unknown>> = CommonOptions<R>;
type RecordWithValueOptions<R extends Record<string, unknown>, V> = CommonOptions<R> & GetValue<R, V>;
type RecordWithStringValueOptions<R extends Record<string, unknown>, V extends string> = CommonOptions<R> &
  GetStringValue<R, V>;
type ValuedAndLabeledOptions<V extends ComboBoxDefaultValue> = CommonOptions<V>;
type EnumOptions<V extends string | number> = CommonOptions<V>;

/** Build a combo box item from common input types */
export const comboBoxItemFrom = {
  boolean: (value: Maybe<boolean>, options?: BooleanOptions): Optional<ComboBoxItem<boolean>> => {
    if (typeof value !== 'boolean') return;
    const { id, label, sublabel, type = 'yes-no', capitalize = true, isDisabled, ...rest } = options ?? {};
    return {
      type: 'item',
      id: id ? id(value) : value.toString(),
      value: value,
      label: label ? label(value) : convert.boolean.to.string(value, { type, capitalize, ...rest }),
      sublabel: sublabel ? sublabel(value) : undefined,
      isDisabled: isDisabled ? isDisabled(value) : undefined,
      showAvatar: rest.showAvatar ? rest.showAvatar(value) : undefined,
      avatarSrc: rest.avatarSrc ? rest.avatarSrc(value) : undefined,
      avatarCount: rest.avatarCount ? rest.avatarCount(value) : undefined
    };
  },
  string: <S extends string>(value: Maybe<S>, options?: StringOptions<S>): Optional<ComboBoxItem<S>> => {
    if (typeof value !== 'string') return;
    const { id, label, sublabel, isDisabled, ...rest } = options ?? {};
    return {
      type: 'item',
      id: id ? id(value) : value.toString(),
      value,
      label: label ? label(value) : value,
      sublabel: sublabel ? sublabel(value) : undefined,
      isDisabled: isDisabled ? isDisabled(value) : undefined,
      showAvatar: rest.showAvatar ? rest.showAvatar(value) : undefined,
      avatarSrc: rest.avatarSrc ? rest.avatarSrc(value) : undefined,
      avatarCount: rest.avatarCount ? rest.avatarCount(value) : undefined
    };
  },
  number: <N extends number>(value: Maybe<N>, options?: NumberOptions<N>): Optional<ComboBoxItem<N>> => {
    if (typeof value !== 'number') return;
    const { id, label, sublabel, isDisabled, ...rest } = options ?? {};
    return {
      type: 'item',
      id: id ? id(value) : value.toString(),
      value,
      label: label ? label(value) : value.toString(),
      sublabel: sublabel ? sublabel(value) : undefined,
      isDisabled: isDisabled ? isDisabled(value) : undefined,
      showAvatar: rest.showAvatar ? rest.showAvatar(value) : undefined,
      avatarSrc: rest.avatarSrc ? rest.avatarSrc(value) : undefined,
      avatarCount: rest.avatarCount ? rest.avatarCount(value) : undefined
    };
  },
  record: <R extends Record<string, unknown>>(
    value: Maybe<R>,
    options?: RecordOptions<R>
  ): Optional<ComboBoxItem<R>> => {
    if (!value) return;
    const { id, label, sublabel, isDisabled, ...rest } = options ?? {};
    return {
      type: 'item',
      id: id ? id(value) : value.toString(),
      value,
      label: label ? label(value) : value.toString(),
      sublabel: sublabel ? sublabel(value) : undefined,
      isDisabled: isDisabled ? isDisabled(value) : undefined,
      showAvatar: rest.showAvatar ? rest.showAvatar(value) : undefined,
      avatarSrc: rest.avatarSrc ? rest.avatarSrc(value) : undefined,
      avatarCount: rest.avatarCount ? rest.avatarCount(value) : undefined
    };
  },
  recordWithValue: <R extends Record<string, unknown>, V>(
    value: Maybe<R>,
    options?: RecordWithValueOptions<R, V>
  ): Optional<ComboBoxItem<V>> => {
    if (!value) return;
    const { id, label, sublabel, value: getValue, isDisabled, ...rest } = options ?? {};
    const _id = id ? id(value) : value.toString();
    return {
      type: 'item',
      id: _id,
      value: getValue ? getValue(value) : (_id as V),
      label: label ? label(value) : value.toString(),
      sublabel: sublabel ? sublabel(value) : undefined,
      isDisabled: isDisabled ? isDisabled(value) : undefined,
      showAvatar: rest.showAvatar ? rest.showAvatar(value) : undefined,
      avatarSrc: rest.avatarSrc ? rest.avatarSrc(value) : undefined,
      avatarCount: rest.avatarCount ? rest.avatarCount(value) : undefined
    };
  },
  recordWithStringValue: <R extends Record<string, unknown>, V extends string>(
    value: Maybe<R>,
    options?: RecordWithStringValueOptions<R, V>
  ): Optional<ComboBoxItem<V>> => {
    if (!value) return;
    const { id, label, sublabel, value: getValue, isDisabled, ...rest } = options ?? {};
    const _id = id ? id(value) : value.toString();
    return {
      type: 'item',
      id: _id,
      value: getValue ? getValue(value) : (_id as V),
      label: label ? label(value) : value.toString(),
      sublabel: sublabel ? sublabel(value) : undefined,
      isDisabled: isDisabled ? isDisabled(value) : undefined,
      showAvatar: rest.showAvatar ? rest.showAvatar(value) : undefined,
      avatarSrc: rest.avatarSrc ? rest.avatarSrc(value) : undefined,
      avatarCount: rest.avatarCount ? rest.avatarCount(value) : undefined
    };
  },
  valuedAndLabeled: <V extends ComboBoxDefaultValue, L extends string>(
    input: Maybe<ValuedAndLabeled<V, L>>,
    options?: ValuedAndLabeledOptions<V>
  ): Optional<ComboBoxItem<V>> => {
    if (!input) return;
    const { value, label } = input;
    const { id, label: _label, sublabel, isDisabled, ...rest } = options ?? {};
    return {
      type: 'item',
      id: id ? id(value) : `${value}`,
      value,
      label: _label ? _label(value) : (label as string),
      sublabel: sublabel ? sublabel(value) : undefined,
      isDisabled: isDisabled ? isDisabled(value) : undefined,
      showAvatar: rest.showAvatar ? rest.showAvatar(value) : undefined,
      avatarSrc: rest.avatarSrc ? rest.avatarSrc(value) : undefined,
      avatarCount: rest.avatarCount ? rest.avatarCount(value) : undefined
    };
  },
  enum: <V extends string | number>(value: Maybe<V>, options?: EnumOptions<V>): Optional<ComboBoxItem<V>> => {
    if (typeof value !== 'string') return;
    const { id, label, sublabel, isDisabled, ...rest } = options ?? {};
    return {
      type: 'item',
      id: id ? id(value) : value.toString(),
      value,
      label: label ? label(value) : value,
      sublabel: sublabel ? sublabel(value) : undefined,
      isDisabled: isDisabled ? isDisabled(value) : undefined,
      showAvatar: rest.showAvatar ? rest.showAvatar(value) : undefined,
      avatarSrc: rest.avatarSrc ? rest.avatarSrc(value) : undefined,
      avatarCount: rest.avatarCount ? rest.avatarCount(value) : undefined
    };
  }
};

/** Build a list of  combo box items from a list of common input types */
export const comboBoxItemsFrom = {
  boolean: (values: Maybe<boolean>[], options?: BooleanOptions): ComboBoxItem<boolean>[] =>
    compactMap(values, (value) => comboBoxItemFrom.boolean(value, options)),
  string: <S extends string>(values: Maybe<S>[], options?: StringOptions<S>): ComboBoxItem<S>[] =>
    compactMap(values, (value) => comboBoxItemFrom.string(value, options)),
  number: <N extends number>(values: Maybe<N>[], options?: NumberOptions<N>): ComboBoxItem<N>[] =>
    compactMap(values, (value) => comboBoxItemFrom.number(value, options)),
  record: <R extends Record<string, unknown>>(
    values: Maybe<R>[],
    options?: RecordOptions<R>
  ): ComboBoxItem<R>[] => compactMap(values, (value) => comboBoxItemFrom.record(value, options)),
  recordWithValue: <R extends Record<string, unknown>, V>(
    values: Maybe<R>[],
    options?: RecordWithValueOptions<R, V>
  ): ComboBoxItem<V>[] => compactMap(values, (value) => comboBoxItemFrom.recordWithValue(value, options)),
  recordWithStringValue: <R extends Record<string, unknown>, V extends string>(
    values: Maybe<R>[],
    options?: RecordWithStringValueOptions<R, V>
  ): ComboBoxItem<V>[] =>
    compactMap(values, (value) => comboBoxItemFrom.recordWithStringValue(value, options)),
  valuedAndLabeled: <V extends ComboBoxDefaultValue, L extends string>(
    input: Maybe<ValuedAndLabeled<V, L>>[],
    options?: ValuedAndLabeledOptions<V>
  ): ComboBoxItem<V>[] => compactMap(input, (value) => comboBoxItemFrom.valuedAndLabeled(value, options)),
  enum: <V extends string | number, E extends Record<AnyKey, V>>(
    enumType: E,
    options?: EnumOptions<V>
  ): ComboBoxItem<V>[] =>
    compactMap(Object.values(enumType), (value) => comboBoxItemFrom.enum(value, options))
};
