import type {
  AdvancedFilterModel,
  ColDef,
  ColGroupDef,
  DateFilterModel,
  FilterModel,
  IServerSideGetRowsRequest,
  NumberFilterModel,
  SetFilterModel,
  SortModelItem,
  TextFilterModel
} from '@ag-grid-community/core';
import { ColumnBuilder, type ColumnBuilderCallback } from '@oms/frontend-vgrid';
import {
  type DateFilter,
  type DateTimeFilter,
  type Exact,
  type FloatFilter,
  type InputMaybe,
  type IntFilter,
  type Scalars,
  type StringFilter
} from '@oms/generated/frontend';
import type { AnyRecord } from '@valstro/workspace';
import { sentenceCase } from 'change-case';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';

export type RelaySetFilter<T = any> = {
  distinctFrom?: InputMaybe<T>;
  equalTo?: InputMaybe<T>;
  greaterThan?: InputMaybe<T>;
  greaterThanOrEqualTo?: InputMaybe<T>;
  in?: InputMaybe<Array<T>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lessThan?: InputMaybe<T>;
  lessThanOrEqualTo?: InputMaybe<T>;
  notDistinctFrom?: InputMaybe<T>;
  notEqualTo?: InputMaybe<T>;
  notIn?: InputMaybe<Array<T>>;
};

export type RelayUUIDFilter = {
  distinctFrom?: InputMaybe<Scalars['UUID']>;
  equalTo?: InputMaybe<Scalars['UUID']>;
  greaterThan?: InputMaybe<Scalars['UUID']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<Scalars['UUID']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lessThan?: InputMaybe<Scalars['UUID']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  notDistinctFrom?: InputMaybe<Scalars['UUID']>;
  notEqualTo?: InputMaybe<Scalars['UUID']>;
  notIn?: InputMaybe<Array<Scalars['UUID']>>;
};

export type AnyRelayFilter<T = any> = {
  and?: InputMaybe<Array<T> | T>;
  or?: InputMaybe<Array<T> | T>;
} & {
  [key: string]: InputMaybe<any>;
};

export type AnyRelayFilterOp<T = any> = {
  and: Array<T>;
  or: Array<T>;
};

export type RelayOutputShape = Exact<{
  filter?: InputMaybe<AnyRelayFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<any> | any>;
}>;

export type RelayQueryKeys<TFilters extends AnyRecord> = {
  validSortKeys: Array<keyof TFilters>;
  setFilterKeys: Array<keyof TFilters>;
  dateAndTimeFilterKeys: Array<keyof TFilters>;
  numericFilterKeys: Array<keyof TFilters>;
  stringFilterKeys: Array<keyof TFilters>;
  uuidFilterKeys: Array<keyof TFilters>;
};

type AgGridStringFilter =
  | 'agNumberColumnFilter'
  | 'agTextColumnFilter'
  | 'agDateColumnFilter'
  | 'agMultiColumnFilter'
  | 'agSetColumnFilter';

function filterKeys(type: AgGridStringFilter, colDefs: (ColDef | ColGroupDef)[]): Array<string> {
  const keys: string[] = [];
  colDefs.forEach((def) => {
    if ('field' in def && def.field && 'filter' in def && def.filter === type) {
      keys.push(def.field);
    }

    if ('children' in def) {
      keys.push(...filterKeys(type, def.children));
    }
  });

  return Array.from(new Set(keys));
}

function sortableKeys(colDefs: (ColDef | ColGroupDef)[]): Array<string> {
  const keys: string[] = [];
  colDefs.forEach((def) => {
    if ('field' in def && def.field && (('sortable' in def && def.sortable) || ('sort' in def && def.sort))) {
      keys.push(def.field);
    }

    if ('children' in def) {
      keys.push(...sortableKeys(def.children));
    }
  });

  return Array.from(new Set(keys));
}

function createNestedObjectFromStringDotPath<T>(dotPath: string, value: T): Record<string, any> {
  const path = dotPath.split('.');
  const last = path.pop();

  // Check if last is undefined, which can happen if dotPath is empty
  if (last === undefined) {
    throw new Error('dotPath cannot be empty');
  }

  const obj = path.reduce((o, k) => {
    // Correct the typing here for intermediate objects
    if (!o[k]) {
      o[k] = {};
    }
    return o[k];
  }, {} as Record<string, any>);

  // Now we are sure last is not undefined
  obj[last] = value;
  return obj;
}

export type FiltersShape<TData extends AnyRecord = AnyRecord> = {
  [K in keyof TData]?: any;
};
export class RelayQueryBuilder<TOuput extends RelayOutputShape = RelayOutputShape> {
  protected _graphileQueryKeys: RelayQueryKeys<FiltersShape>;
  private _allQueryFilterKeys: (keyof FiltersShape)[] = [];

  private constructor(columns: (ColumnBuilderCallback<any> | ColDef)[]) {
    const colDefs = columns.map((arg) => {
      if (isFunction(arg)) {
        const builder = arg(new ColumnBuilder<AnyRecord>());
        return builder.build();
      } else {
        return arg;
      }
    });

    this._graphileQueryKeys = {
      uuidFilterKeys: [],
      stringFilterKeys: filterKeys('agTextColumnFilter', colDefs),
      numericFilterKeys: filterKeys('agNumberColumnFilter', colDefs),
      dateAndTimeFilterKeys: filterKeys('agDateColumnFilter', colDefs),
      setFilterKeys: filterKeys('agSetColumnFilter', colDefs),
      validSortKeys: sortableKeys(colDefs)
    };

    this._allQueryFilterKeys = [
      ...this._graphileQueryKeys.uuidFilterKeys,
      ...this._graphileQueryKeys.stringFilterKeys,
      ...this._graphileQueryKeys.numericFilterKeys,
      ...this._graphileQueryKeys.dateAndTimeFilterKeys,
      ...this._graphileQueryKeys.setFilterKeys
    ];
  }

  static create<TOuput extends AnyRecord>(
    columns: (ColumnBuilderCallback<any> | ColDef)[]
  ): RelayQueryBuilder<TOuput> {
    return new RelayQueryBuilder(columns);
  }

  public buildQuery(request: IServerSideGetRowsRequest): TOuput {
    const startRow = request.startRow || 0;
    const endRow = request.endRow || 100;
    const offset = startRow;
    const first = endRow - startRow;

    const orderBy = this._buildSort(request.sortModel);
    const filter = this._buildFilters(request.filterModel); // TODO: Use new Ag Grid filter model with conditions array....

    const query = {
      first,
      offset,
      orderBy,
      filter
    } as TOuput;

    return query;
  }

  protected _buildSort(sortModel: SortModelItem[]): Array<string> {
    const sort: Array<string> = [];

    const sortItems: SortModelItem[] = sortModel as SortModelItem[];

    sortItems.forEach(({ colId, sort: direction }) => {
      if (!this._graphileQueryKeys.validSortKeys.includes(colId)) {
        return;
      }

      const sortValue = `${sentenceCase(colId.toString())
        .toUpperCase()
        .replace(/ /g, '_')}_${direction.toUpperCase()}`;

      sort.push(sortValue);
    });

    return sort;
  }

  protected _buildFilters(filterModel: FilterModel | AdvancedFilterModel | null) {
    if (!filterModel) {
      return undefined;
    }

    const filter: AnyRelayFilterOp = {
      and: [],
      or: []
    };

    for (const [key, value] of Object.entries(filterModel)) {
      if (!value || !this._allQueryFilterKeys.includes(key)) {
        continue;
      }

      if ('operator' in value) {
        const filterItemOps = this._buildConditionalFilter(key, value);
        const removeExisting = filter.and.filter((f) => !f[key]);
        const op = value.operator === 'AND' ? 'and' : 'or';
        const arr = op === 'and' ? filterItemOps.and : filterItemOps.or;
        filter.and = [
          ...removeExisting,
          {
            [op]: arr
          }
        ];
      } else {
        const { rootKey, filterValue } = this._buildNestedFilter(key, value);

        if (!filterValue) {
          continue;
        }

        const filterItem = { [rootKey]: filterValue };
        const removeExisting = filter.and.filter((f) => !f[key]);
        filter.and = [...removeExisting, filterItem];
      }
    }

    return isEmpty(filter) ? undefined : filter;
  }

  protected _buildNestedFilter(key: string, value: any) {
    if (!key.includes('.')) {
      return {
        rootKey: key,
        filterValue: this._buildFilter(key, value)
      };
    }

    const firstItemName = key.split('.')[0];
    const lastItemName = key.split('.').pop();
    if (!lastItemName) {
      return {
        rootKey: firstItemName,
        filterValue: undefined
      };
    }
    const filterValue = createNestedObjectFromStringDotPath(key, this._buildFilter(lastItemName, value));
    if (!filterValue || isEmpty(filterValue)) {
      return {
        rootKey: firstItemName,
        filterValue: undefined
      };
    }

    return {
      rootKey: firstItemName,
      filterValue: filterValue
    };
  }

  protected _buildFilter(key: string, value: any) {
    switch (value.filterType) {
      case 'set':
        return this._buildSetFilter(key, value);
      case 'text':
        return this._buildTextLikeFilter(key, value);
      case 'number':
        return this._buildNumberFilter(key, value);
      case 'date':
        return this._buildDateFilter(key, value);
      default:
        return undefined;
    }
  }

  private _buildConditionalFilter(key: string, value: any): AnyRelayFilterOp {
    const opFilter: AnyRelayFilterOp = {
      and: [],
      or: []
    };
    const { operator, condition1, condition2 } = value;
    const { rootKey: rootKey1, filterValue: filter1Val } = this._buildNestedFilter(key, condition1);
    const { rootKey: rootKey2, filterValue: filter2Val } = this._buildNestedFilter(key, condition2);

    if (!filter1Val || !filter2Val) {
      return opFilter;
    }

    const filter1 = { [rootKey1]: filter1Val };
    const filter2 = { [rootKey2]: filter2Val };

    switch (operator) {
      case 'AND':
        opFilter.and.push(filter1, filter2);
        return opFilter;
      case 'OR':
        opFilter.or.push(filter1, filter2);
        return opFilter;
      default:
        return opFilter;
    }
  }

  protected _buildSetFilter(key: string, value: SetFilterModel): RelaySetFilter | undefined {
    if (!this._graphileQueryKeys.setFilterKeys.includes(key)) {
      return undefined;
    }

    const { values } = value;
    if (Array.isArray(values)) {
      return {
        in: values
      };
    } else {
      return {
        equalTo: value
      };
    }
  }

  protected _buildTextLikeFilter(key: string, value: TextFilterModel): StringFilter | undefined {
    if (this._graphileQueryKeys.uuidFilterKeys.includes(key)) {
      return this._buildUUIDFilter(key, value);
    }

    return this._buildTextFilter(key, value);
  }

  protected _buildTextFilter(key: string, value: TextFilterModel): StringFilter | undefined {
    if (!this._graphileQueryKeys.stringFilterKeys.includes(key)) {
      return undefined;
    }

    switch (value.type) {
      case 'contains':
        return {
          includes: value.filter
        };
      case 'notContains':
        return { notIncludes: value.filter };
      case 'equals':
        return { equalTo: value.filter };
      case 'notEqual':
        return { notEqualTo: value.filter };
      case 'startsWith':
        return { startsWith: value.filter };
      case 'endsWith':
        return { endsWith: value.filter };
      case 'blank':
        return { isNull: true };
      case 'notBlank':
        return { notEqualTo: null };
      default:
        return undefined;
    }
  }

  protected _buildUUIDFilter(key: string, value: TextFilterModel): RelayUUIDFilter | undefined {
    if (!this._graphileQueryKeys.uuidFilterKeys.includes(key)) {
      return undefined;
    }

    switch (value.type) {
      case 'contains':
        return {
          equalTo: value.filter
        };
      case 'notContains':
        return { notEqualTo: value.filter };
      case 'equals':
        return { equalTo: value.filter };
      case 'notEqual':
        return { notEqualTo: value.filter };
      case 'startsWith':
        return { equalTo: value.filter };
      case 'endsWith':
        return { equalTo: value.filter };
      case 'blank':
        return { isNull: true };
      case 'notBlank':
        return { notEqualTo: null };
      default:
        return undefined;
    }
  }

  protected _buildNumberFilter(key: string, value: NumberFilterModel): FloatFilter | IntFilter | undefined {
    if (!this._graphileQueryKeys.numericFilterKeys.includes(key)) {
      return undefined;
    }

    switch (value.type) {
      case 'equals':
        return { equalTo: value.filter };
      case 'notEqual':
        return { notEqualTo: value.filter };
      case 'lessThan':
        return { lessThan: value.filter };
      case 'lessThanOrEqual':
        return { lessThanOrEqualTo: value.filter };
      case 'greaterThan':
        return { greaterThan: value.filter };
      case 'greaterThanOrEqual':
        return { greaterThanOrEqualTo: value.filter };
      case 'inRange':
        return { greaterThanOrEqualTo: value.filter, lessThanOrEqualTo: value.filterTo };
      case 'blank':
        return { equalTo: 0 };
      case 'notBlank':
        return { notEqualTo: 0 };
      default:
        return undefined;
    }
  }

  protected _getDayAndTime(agGridDateString?: string | null) {
    if (!agGridDateString) return [null, null];
    const [day, time] = agGridDateString.split(' ');
    return [new Date(day).getTime(), time] as const;
  }

  protected _buildDateFilter(key: string, value: DateFilterModel): DateFilter | DateTimeFilter | undefined {
    if (!this._graphileQueryKeys.dateAndTimeFilterKeys.includes(key)) {
      return undefined;
    }

    const dayInSeconds = 86400000;
    const [dayFromUnix] = this._getDayAndTime(value.dateFrom);
    const [dayToUnix] = this._getDayAndTime(value.dateTo);
    const startOfDayFrom = new Date(dayFromUnix || 0).toISOString();
    const endOfDayFrom = new Date(dayFromUnix || 0 + dayInSeconds).toISOString();
    const startOfDayToUnix = dayToUnix || dayInSeconds;
    const endOfDayTo = new Date(startOfDayToUnix + dayInSeconds).toISOString();

    switch (value.type) {
      case 'equals':
        return { greaterThanOrEqualTo: startOfDayFrom, lessThanOrEqualTo: endOfDayFrom };
      case 'notEqual':
        return { greaterThanOrEqualTo: endOfDayFrom, lessThanOrEqualTo: startOfDayFrom };
      case 'lessThan':
        return { lessThan: startOfDayFrom };
      case 'greaterThan':
        return { greaterThan: startOfDayFrom };
      case 'inRange':
        return { greaterThanOrEqualTo: endOfDayFrom, lessThanOrEqualTo: endOfDayTo };
      case 'blank':
        return { equalTo: null };
      case 'notBlank':
        return { notEqualTo: null };
      default:
        return undefined;
    }
  }
}
