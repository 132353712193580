import {
  type VisibleInvestorOrderInfoWithAllocationsFragment,
  InvestorOrderStatus
} from '@oms/generated/frontend';
import { CellBadgeClassEnum, type ColumnBuilderCallback, type ColumnLibrary } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import {
  sharedDateTimeCol,
  sharedDefaultCol,
  sharedIdCol,
  sharedQuantityCol,
  sharedTextCol,
  sharedTimestampCol
} from '@app/common/grids/columns/generic-cols';
import {
  sharedCreatedTimestampCol,
  sharedExpiryDateTimeCol,
  sharedInvestorAccountCol,
  sharedLimitPriceCol,
  sharedOrderEntryTypeCol,
  sharedOrderStatusWithoutMapperCol,
  sharedOrderTifCol,
  sharedOrderTypeCol,
  sharedOrderVisibilityReasonCol,
  sharedOwnerCol,
  sharedOwnerIdCol,
  sharedSideCol,
  sharedTransmittedTimestampCol
} from '@app/common/grids/columns/order-cols';
import { instrumentCols } from '@app/common/grids/columns/instrument-cols';
import { mapOrderStatus } from '@app/common/mappers/map-order-status';

type NewOrdersColumnBuilder = ColumnBuilderCallback<VisibleInvestorOrderInfoWithAllocationsFragment>;

const defaultCol: NewOrdersColumnBuilder = (c) => sharedDefaultCol(c);

const idCol: NewOrdersColumnBuilder = (c) => sharedIdCol(c, 'id').hide();

const investorAccountCol: NewOrdersColumnBuilder = (c) => sharedInvestorAccountCol(c, 'investorAccount.name');

const sideCol: NewOrdersColumnBuilder = (c) => sharedSideCol(c, 'sideType');

const limitPriceCol: NewOrdersColumnBuilder = (c) => sharedLimitPriceCol(c, 'limitPrice');

const quantityCol: NewOrdersColumnBuilder = (c) =>
  sharedQuantityCol(c, 'quantity')
    .header(t('app.orders.newOrders.quantity'))
    .shortHeader(t('app.orders.newOrders.quantity', { ns: 'short' }));

// In the Order Monitor, transmittedTimestamp has column header "Transmitted Timestamp".
// In New Orders, the column header is "Order Time".
const transmittedTimestampCol: NewOrdersColumnBuilder = (c) =>
  sharedTransmittedTimestampCol(c)
    .sort('desc')
    .header(t('app.orders.newOrders.orderTime'))
    .shortHeader(t('app.orders.newOrders.orderTime', { ns: 'short' }));

const orderTypeCol: NewOrdersColumnBuilder = (c) => sharedOrderTypeCol(c).hide();

const orderStatusCol: NewOrdersColumnBuilder = (c) =>
  sharedOrderStatusWithoutMapperCol(c)
    .filter('agSetColumnFilter')
    .filterParams<InvestorOrderStatus>({
      values: Object.values(InvestorOrderStatus),
      valueFormatter: ({ value }) => mapOrderStatus(value)
    })
    .cell((c) => c.badge(CellBadgeClassEnum.Capital, (data) => mapOrderStatus(data?.status)))
    .hide();

const clientOrderIdCol: NewOrdersColumnBuilder = (c) =>
  sharedIdCol(c, 'clientOrderId')
    .header(t('app.orders.orderMonitor.clientOrderId'))
    .shortHeader(t('app.orders.orderMonitor.clientOrderId', { ns: 'short' }))
    .hide();

const orderEntryTypeCol: NewOrdersColumnBuilder = (c) => sharedOrderEntryTypeCol(c).hide();

const ownerCol: NewOrdersColumnBuilder = (c) =>
  sharedOwnerCol<VisibleInvestorOrderInfoWithAllocationsFragment>(c);

const ownerIdCol: NewOrdersColumnBuilder = (c) =>
  sharedOwnerIdCol<VisibleInvestorOrderInfoWithAllocationsFragment>(c);

const orderVisibilityReasonCol: NewOrdersColumnBuilder = (c) => sharedOrderVisibilityReasonCol(c);

const orderTifCol: NewOrdersColumnBuilder = (c) => sharedOrderTifCol(c).hide();

const sendingDeskCol: NewOrdersColumnBuilder = (c) =>
  sharedTextCol(c, 'sendingDesk')
    .header(t('app.orders.orderMonitor.sendingDesk'))
    .shortHeader(t('app.orders.orderMonitor.sendingDesk', { ns: 'short' }))
    .hide();

const underlyingAccountCol: NewOrdersColumnBuilder = (c) =>
  sharedTextCol(c, 'underlyingAccount')
    .header(t('app.orders.orderMonitor.underlyingAccount'))
    .shortHeader(t('app.orders.orderMonitor.underlyingAccount', { ns: 'short' }))
    .hide();

const createdTimestampCol: NewOrdersColumnBuilder = (c) => sharedCreatedTimestampCol(c).hide();

const validatedTimestampCol: NewOrdersColumnBuilder = (c) =>
  sharedTimestampCol(c, 'validatedTimestamp')
    .header(t('app.orders.orderMonitor.validatedTimestamp'))
    .shortHeader(t('app.orders.orderMonitor.validatedTimestamp', { ns: 'short' }))
    .sort('desc')
    .hide();

const updatedTimeCol: NewOrdersColumnBuilder = (c) =>
  sharedDateTimeCol(c, 'updatedTime')
    .header(t('app.orders.orderMonitor.updatedTime'))
    .shortHeader(t('app.orders.orderMonitor.updatedTime', { ns: 'short' }))
    .hide();

const expiryDateTimeCol: NewOrdersColumnBuilder = (c) => sharedExpiryDateTimeCol(c);

export const newOrdersColumnLibrary: ColumnLibrary<VisibleInvestorOrderInfoWithAllocationsFragment> = {
  defaultColumn: defaultCol,
  columns: [
    idCol,
    investorAccountCol,
    ...instrumentCols<VisibleInvestorOrderInfoWithAllocationsFragment>({
      displayCode: (cb) =>
        cb
          .cell((c) => c.renderer('agGroupCellRenderer'))
          .filter(false)
          .sortable(false),
      longName: (cb) => cb.hide(),
      cusip: (cb) => cb.hide(),
      exchCode: (cb) => cb.hide()
    }),
    sideCol,
    limitPriceCol,
    quantityCol,
    transmittedTimestampCol,
    orderTypeCol,
    orderStatusCol,
    clientOrderIdCol,
    orderEntryTypeCol,
    ownerCol,
    ownerIdCol,
    orderVisibilityReasonCol,
    orderTifCol,
    sendingDeskCol,
    underlyingAccountCol,
    createdTimestampCol,
    validatedTimestampCol,
    updatedTimeCol,
    expiryDateTimeCol
  ]
};
