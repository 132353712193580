import { type TradeRepairRequestFragment } from '@oms/generated/frontend';
import {
  useVGrid,
  VGrid,
  CLEAR_GRID_FILTER_ACTION_TYPE,
  toClientsideGridData,
  ROW_SELECTION_COUNT_ACTION_TYPE
} from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { TradeRepairRequestService } from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import { tradeRepairRequestColumnLibrary } from './repair-queue.trades.columns';
import { refreshServersideAction } from '@app/common/grids/actions/refresh-serverside.action';
import { useRef } from 'react';
import { groupByTradesAction } from './grid-actions/group-by.repair-queue.trades.action';
import { repairCreateTradeAction } from './grid-actions/repair-create.trades.action';
import { rejectRepairQueueTradesAction } from './grid-actions/reject.repair-queue.trades.action';
import { retryCreateTradeAction } from './grid-actions/retry.trades.action';

export const REPAIR_QUEUE_TRADES = 'repair-queue-trades';

export const RepairQueueTradesGridWidget = () => {
  const datasourceService = useService(TradeRepairRequestService);
  const query$ = useRef(datasourceService.watchAll$().pipe(toClientsideGridData()));

  const gridProps = useVGrid<TradeRepairRequestFragment>(
    REPAIR_QUEUE_TRADES,
    (b) =>
      b
        .columnLibrary(tradeRepairRequestColumnLibrary)
        .datasource((d) => d.source(query$.current).rowId(({ data }) => data?.id as string))
        .rowSelection((c) => c.multiple())
        .sideBar()
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(groupByTradesAction)
                .action(CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(retryCreateTradeAction)
                .action(rejectRepairQueueTradesAction)
                .action(refreshServersideAction)
                .action(repairCreateTradeAction)
            )
            .settings((s) => s.fromFile({}))
        ),
    [query$]
  );

  return <VGrid {...gridProps} />;
};

export default RepairQueueTradesGridWidget;
