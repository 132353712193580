import { useCallback } from 'react';
import type { RxDocument } from 'rxdb-v15';
import type { Optional } from '@oms/shared/util-types';
import { useService } from '@oms/frontend-foundation';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';
import type { MontageDocType } from '../montage.collection.types';
import { setMontageState } from '../montage.collection.utils';

/**
 * @param [options] Set montage state options object
 * @returns A memoized callback to set Montage state
 */
export const useSetMontageState = () => {
  const scopedActorId = useScopedActorId();
  const workspace = useService(AppWorkspace);

  return useCallback(
    (stateChange?: Partial<MontageDocType>): Promise<Optional<RxDocument<MontageDocType>>> =>
      setMontageState(scopedActorId, workspace, stateChange),
    [scopedActorId, workspace]
  );
};

export default useSetMontageState;
