import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import { InvestorOrderEntryService } from '@app/data-access/services/trading/investor-order-entry/investor-order-entry.service';
import { InvestorOrderRepairRequestService } from '@app/data-access/services/trading/repair-requests/investor-order-repair-requests.service';
import type { InvestorOrderEntryInput, InvestorOrderEntryOutput } from './investor-order-entry.form-common';
import {
  investorOrderEntryContract,
  type InvestorOrderEntryContractType,
  type InvestorOrderEntryValues
} from './investor-order-entry.form-contract';
import {
  type TagFragment,
  type OrderSideType,
  type ModifyOrderEnrichedData,
  type AddOrderEnrichedData,
  RepairCategory
} from '@oms/generated/frontend';
import { createTagComboboxItem } from '@app/forms/common/fields/order-tags-field/order-tags.field.queries';
import { createRepCodeComboboxItem } from '@app/forms/common/fields/rep-code-field/rep-code.field.queries';
import { createCurrencyComboboxItem } from '@app/forms/common/fields/currency-field/currency.field.queries';
import {
  getInitialCommonIOFieldValues,
  getInitialCommonFormValues,
  getInitialRepairOnlyFormValues,
  sanitizeFormValuesToCommonOutput,
  sanitizeFormValuesToCreateOnlyOutput,
  sanitizeFormValuesToRepairOnlyOutput,
  sanitizeFormValuesToUpdateOnlyOutput
} from './investor-order-entry.form-sanitizers';
import { getInvestorOrderFromRepair } from './investor-order-entry.form-utils';
import { tifFormValues } from '@app/forms/common/fields/TIF-field/TIF-field.common';
import { createChargeScheduleDisplay } from './charge.schedule.display';
import { DISPLAY_FIELD_COMPONENT_TYPE, type GridColValues } from '@oms/shared-frontend/ui-design-system';
import type { ResponsiveValue } from '@oms/shared-frontend/ui-design-system/css';

export const investorOrderEntryBuilder = FormBuilder.create<
  InvestorOrderEntryInput,
  InvestorOrderEntryOutput
>('io-entry-form')
  .contract<InvestorOrderEntryContractType>(investorOrderEntryContract)
  .type('io-entry')
  .sanitizer((s) =>
    s
      .input(async function initializeFormValues(input, ctx) {
        const orderInfo = await getInitialCommonIOFieldValues(input, ctx.container);

        let initialFormValues: Partial<InvestorOrderEntryValues> = {
          id: input.id
        };

        switch (orderInfo.type) {
          case 'create':
            initialFormValues = {
              ...initialFormValues,
              ...getInitialCommonFormValues()
            };
            break;

          case 'update':
            initialFormValues = {
              ...initialFormValues,
              ...getInitialCommonFormValues(orderInfo.investorOrder)
            };
            break;

          case 'repair': {
            const investorOrderFromRepair = getInvestorOrderFromRepair(orderInfo.repairIO);

            initialFormValues = {
              ...initialFormValues,
              ...getInitialCommonFormValues(investorOrderFromRepair),
              // use instrument and investorAccountId from the repair request
              // because it has all needed data for typeahead form fields (id and label)
              ...getInitialRepairOnlyFormValues(orderInfo.repairIO)
            };
            break;
          }
        }

        return {
          hiddenFormInfo: orderInfo,
          ...initialFormValues
        };
      })
      .output(function sanitize(formValues) {
        if (
          !formValues?.quantity ||
          !formValues?.sideType ||
          !formValues?.investorAccountId ||
          !formValues.instrument ||
          !formValues.hiddenFormInfo
        ) {
          return;
        }

        const { hiddenFormInfo } = formValues;
        const commonOutput = sanitizeFormValuesToCommonOutput(formValues);

        switch (hiddenFormInfo.type) {
          case 'create':
            return {
              entryType: 'create',
              output: {
                ...commonOutput,
                ...sanitizeFormValuesToCreateOnlyOutput(formValues)
              }
            };
          case 'update':
            return {
              entryType: 'update',
              output: { ...commonOutput, ...sanitizeFormValuesToUpdateOnlyOutput(formValues) }
            };
          case 'repair': {
            const { repairIO } = hiddenFormInfo;
            const ioResponse = getInvestorOrderFromRepair(repairIO);
            return {
              entryType: 'repair',
              output: {
                ...commonOutput,
                // TODO: check if all fields are needed
                ...sanitizeFormValuesToCreateOnlyOutput(formValues),
                // repair specific fields from getRepairIO responce
                // and override orderEntryType field
                ...sanitizeFormValuesToRepairOnlyOutput(ioResponse)
              },
              repairIoId: repairIO.id ?? '',
              repairCategory: repairIO.category
            };
          }
        }
      })
  )
  .change(async (event, ctx) => {
    const ioEntrySvc = ctx.container.resolve(InvestorOrderEntryService);
    const repairRequestSvc = ctx.container.resolve(InvestorOrderRepairRequestService);

    switch (event.type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const { entryType, output } = event.payload.output;
        const enrichmentIgnoreList = ['representativeCode', 'orderTags'].filter((f) =>
          event.payload.modifiedFields.includes(f as keyof InvestorOrderEntryValues)
        );

        switch (entryType) {
          case 'create':
            return await ioEntrySvc.create({ order: output, charges: [], enrichmentIgnoreList }, false);
          case 'update':
            return await ioEntrySvc.update(output, false);
          case 'repair': {
            const { repairIoId, repairCategory } = event.payload.output;
            if (repairCategory === RepairCategory.IoCreate) {
              return await repairRequestSvc.repairIOCreate(repairIoId, { order: output, charges: [] });
            } else if (repairCategory === RepairCategory.IoModify) {
              return await repairRequestSvc.repairIOModify(repairIoId, { order: output, charges: [] });
            }
          }
        }
        break;
      }
      case FORM_EVENT_TYPE.VALUES_CHANGED:
        {
          const entryType = event.payload.formValues.hiddenFormInfo?.type ?? 'create';
          if (entryType !== 'create' && entryType !== 'repair') {
            break;
          }

          const formValuesDiff = event.payload.formValuesDiff;
          if (formValuesDiff.instrument) {
            ctx.notify({
              type: 'SET_FIELD_VALUES',
              payload: {
                fieldValues: {
                  settleCurrency: undefined,
                  tradeCurrency: undefined
                }
              }
            });
          }
          if (formValuesDiff.investorAccountId) {
            ctx.notify({
              type: 'SET_FIELD_VALUES',
              payload: {
                fieldValues: {
                  orderTags: undefined,
                  representativeCode: undefined,
                  primaryBenchmark: undefined
                }
              }
            });
          }
        }

        break;

      case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED: {
        const { entryType, output } = event.payload.output;
        const enrichmentIgnoreList = ['representativeCode', 'orderTags'].filter(
          (f) =>
            entryType === 'repair' ||
            event.payload.modifiedFields.includes(f as keyof InvestorOrderEntryValues)
        );

        const response =
          entryType === 'create' || entryType === 'repair'
            ? await ioEntrySvc.create({ order: output, charges: [], enrichmentIgnoreList }, true)
            : await ioEntrySvc.update(output, true);

        if (response.isSuccess() && response.value.data) {
          const { data } = response.value;
          const enrichedData =
            'modifyInvestorOrder' in data
              ? data.modifyInvestorOrder?.enrichedData
              : 'addOrdersWithCharges' in data
              ? data.addOrdersWithCharges?.enrichedData?.orders?.[0]
              : undefined;

          const ioEntryVals = Object.entries(enrichedData ?? {}).reduce<Partial<InvestorOrderEntryValues>>(
            (formVals, entry) => {
              const [key, val] = entry;
              const enrichedKeys = key as keyof (ModifyOrderEnrichedData & AddOrderEnrichedData);

              // Backend should not return values that were added to the ignore list
              // If we process them, it will mark the updated fields as unmodified
              // which will make them eligible for enrichment next time.
              // Added here as extra protection.
              if (enrichmentIgnoreList.includes(key)) {
                return formVals;
              }

              switch (enrichedKeys) {
                case 'locate':
                  formVals['locate'] = val || undefined;
                  break;
                case 'sideType':
                  formVals['sideType'] = val as OrderSideType;
                  break;
                case 'gtdTimestamp':
                  formVals = { ...formVals, ...tifFormValues({ gtdTimestamp: val }) };
                  break;
                case 'orderTags':
                  formVals.orderTags =
                    val && val.length ? val.map((tag: TagFragment) => createTagComboboxItem(tag)) : undefined;
                  break;
                case 'settleCurrency':
                  formVals.settleCurrency = val
                    ? createCurrencyComboboxItem({
                        id: val,
                        longName: val
                      })
                    : undefined;
                  break;
                case 'representativeCode':
                  formVals.representativeCode = val ? createRepCodeComboboxItem(val) : undefined;
                  break;
                case 'tradeCurrency':
                  formVals.tradeCurrency = val
                    ? createCurrencyComboboxItem({
                        id: val,
                        longName: val
                      })
                    : undefined;
                  break;
                case 'chargeSchedules': {
                  const components = createChargeScheduleDisplay(val);
                  formVals.chargeSchedule = val
                    ? {
                        gridProps: {
                          columns: (1 + components.length) as ResponsiveValue<GridColValues>
                        },
                        items: [
                          {
                            component: {
                              type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
                              value: 'Fee schedule'
                            }
                          },
                          ...components
                        ],
                        style: {
                          display: 'flex'
                        }
                      }
                    : undefined;

                  break;
                }
              }

              return formVals;
            },
            {}
          );

          if (Object.keys(ioEntryVals).length) {
            ctx.notify({
              type: 'SET_FIELD_VALUES',
              payload: { fieldValues: ioEntryVals }
            });
          }
        }
        return response;
      }
    }
  });

export type InvestorOrderEntryBuilderType = typeof investorOrderEntryBuilder;
export default investorOrderEntryBuilder;
