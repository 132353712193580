import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import { convertToNumber } from '@oms/shared/util';
import type { TradeInfoFragment } from '@oms/generated/frontend';
import { TradesService } from '@app/data-access/services/trading/trades/trades.service';
import { TradeRepairRequestService } from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import { type TradeModifyInput, type TradeModifyOutput, TradeModifyEntryType } from './trade-modify.form-common';
import {
  tradeModifyContract,
  type TradeModifyContractType,
  type TradeModifyValues
} from './trade-modify.form-contract';
import { getTradeInfo, getInitialCommonFormValues } from './trade-modify.form-sanitizers';

export const tradeModifyBuilder = FormBuilder.create<TradeModifyInput, TradeModifyOutput>('trade-modify-form')
  .contract<TradeModifyContractType>(tradeModifyContract)
  .type('trade-modify')
  .sanitizer((s) =>
    s
      .input(async function initializeFormValues(input, ctx) {
        const tradeInfo = await getTradeInfo(input, ctx.container);
        let initialFormValues: Partial<TradeModifyValues> = {
          id: input.id
        };

        if (tradeInfo?.type === TradeModifyEntryType.TradeRepair) {
          // Temporary part to get trade info
          const mockTrade = {
            id: "989eef36-db66-4df1-9d4c-e95574ebc9e2",
            tradeQuantity: 6,
            venueOrderId: "10021588",
            normalizedLiquidityIndicator: null,
            rawLiquidityIndicator: "1",
            lastMarket: null,
            tradeDateTime: "2024-08-15T15:51:01.491+00:00",
            createdDateTime: "2024-08-15T15:51:02.755+00:00",
            updatedDateTime: "2024-08-15T15:51:03.947+00:00",
            status: "ACTIVE",
            capacity: null,
            tradePrice: 153,
            tradeCounterpartyId: null,
            tradingOrderId: "e6047f02-f253-49d7-bea6-4f528c5dc8ef",
            tradingOrder: {
              tradingAccount: {
                id: "fbe1f8e7-58a9-499a-9212-63c98d7caa00",
                name: "modifytrade-DEV-5966-PWaccount",
                __typename: "InvestorAccount"
              },
              sideType: "SELL",
              locate: "",
              __typename: "TradingOrder"
            },
            enteredBy: null,
            side: "SELL",
            settleDate: "2024-08-19",
            settleType: "REGULAR",
            fixMessage: "8=FIX.4.4|9=400|35=8|34=19|49=fixsimBroker|52=20240815-15:51:01.553|56=valstroUSIntegration42|115=simPFill|128=DEV-5966|6=151.2000|11=e6047f02-f253-49d7-bea6-4f528c5dc8ef|14=5.000000000000|15=USD|17=10152638593338614910000|21=3|22=1|31=151.200000000000|32=5|37=10021588|38=10.0|39=1|40=2|44=151.200000|47=A|48=88160R101|54=2|55=TSLA|59=0|60=20240815-15:51:01.491|126=20240816-21:00:00.000|150=F|151=5|207=XNAS|851=1|10=018|",
            instrument: {
              __typename: "InstrumentDetails",
              id: "BBG000N9MNX3",
              longName: "Tesla, Inc.",
              mappings: {
                id: "BBG000N9MNX3",
                displayCode: "TSLA-US",
                __typename: "InstrumentMapping"
              }
            },
            tradeContraAccount: null,
            __typename: "Trade"
          } as TradeInfoFragment;
          // end of temporary part

          initialFormValues = {
            ...initialFormValues,
            ...getInitialCommonFormValues(mockTrade)
          };
        }

        if (tradeInfo?.type === TradeModifyEntryType.TradeModify) {
          initialFormValues = {
            ...initialFormValues,
            ...getInitialCommonFormValues(tradeInfo.trade)
          };
        }

        return {
          hiddenFormInfo: tradeInfo,
          ...initialFormValues
        };
      })
      .output(function sanitizeFormValuesToOutput(formValues) {
        if (
          !formValues?.id ||
          !formValues?.instrument ||
          !formValues?.tradeQuantity ||
          !formValues?.hiddenFormInfo
        ) {
          return;
        }

        const output: TradeModifyOutput['output'] = {
          id: formValues.id
        };
        const { hiddenFormInfo } = formValues;
        const tradeContraAccountId = formValues.tradeContraAccountId?.value;

        output.tradeQuantity = formValues.tradeQuantity ? convertToNumber(formValues.tradeQuantity) : null;
        output.tradePrice = formValues.tradePrice ? convertToNumber(formValues.tradePrice) : null;
        output.tradeDateTime = formValues.tradeDateTime ? formValues.tradeDateTime : undefined;
        if (tradeContraAccountId) {
          output.tradeContraAccount = {
            id: tradeContraAccountId
          };
        }
        output.tradeTagsId = formValues.tradeTags?.map((tag) => tag.value);
        output.settleDate = formValues.settleDate || undefined;
        output.settleType = formValues.settleType;

        return {
          type: hiddenFormInfo.type,
          output
        };
      })
  )
  .change(async (event, ctx) => {
    const tradesService = ctx.container.resolve(TradesService);
    const repairService = ctx.container.resolve(TradeRepairRequestService);

    switch (event.type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const formOutput = event.payload.output;
        switch (formOutput.type) {
          case TradeModifyEntryType.TradeModify: {
            return await tradesService.modify(formOutput.output);
          }
          case TradeModifyEntryType.TradeRepair: {
            return await repairService.repairCreateTradeRepairRequest(formOutput.output.id, formOutput.output);
          }
        }
      }
    }
  });

export type TradeModifyBuilderType = typeof tradeModifyBuilder;

export default tradeModifyBuilder;
