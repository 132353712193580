import {
  Plugin,
  type CreateActorOptions,
  type ActorSnapshotDefinition,
  COMMON_ACTOR_TYPE,
  type CommonWindowActorSchema,
  type WidgetContext
} from '@valstro/workspace';
import { type AppWorkspace } from '@app/app-config/workspace.config';
import { createLogger } from '@oms/shared/util';
import { AppState } from '@app/data-access/memory/app.stream';
import { type Subscription, take } from 'rxjs';
import { SnapshotsService } from '@app/data-access/services/system/snapshots/snapshots.service';
import type { OfflineDb } from '@app/data-access/offline/offline-database';
import { OfflineDatabaseSignal } from '@app/data-access/memory/offline-database.signal';
import type { DependencyContainer } from 'tsyringe';
import { traverseAndApplyTransformationToObject } from '@oms/frontend-foundation';
import { omit } from 'lodash';
import { FLEX_LAYOUT_ACTOR_TYPE, type FlexLayoutContext } from '@valstro/workspace-react';
import type { RegistryExcludePropsFromSnapshotMapper } from '@app/app-config/registry.config';

export const snapshotsAndRecoveryPluginLogger = createLogger({ label: 'Snapshots And Recovery Plugin' });

interface WorkspaceSnapshotAndRecoveryPluginOptions {
  excludeWindowWidgetCategories?: string[];
  excludeWindowWidgetTypes?: string[];
  excludePropsFromSnapshot?: RegistryExcludePropsFromSnapshotMapper;
  container: DependencyContainer;
}

/**
 * Workspace Snapshot & Recovery plugin
 * - Applies persisted snapshot globally & per tab on Browser
 * - Saves snapshot every second
 */
export const snapshotsAndRecoveryPlugin = ({
  container,
  excludeWindowWidgetCategories = [],
  excludeWindowWidgetTypes = [],
  excludePropsFromSnapshot = {}
}: WorkspaceSnapshotAndRecoveryPluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-snapshots-and-recovery-plugin',
    pluginFn: ({ workspace }) => {
      const appState = container.resolve(AppState);
      const signalService = container.resolve(OfflineDatabaseSignal);

      // Register rxdb hooks for snapshot
      const offlineReadySub = signalService.ready$.subscribe(({ db }) => {
        registerSnapshotHooks(db);
      });

      // Add hook to omit/filter "snapshots" widget from snapshot itself.
      // We do this because we don't want to save the snapshots widget in the snapshot itself.
      workspace
        .addActorHook<CommonWindowActorSchema>(COMMON_ACTOR_TYPE.WINDOW)
        // eslint-disable-next-line require-await
        .after('takeSnapshot', async (snapshot) => {
          // Filter out window categories from snapshot
          snapshot = filterChildrenFromSnapshot(snapshot, (child) => {
            if (child.type !== COMMON_ACTOR_TYPE.WINDOW) {
              return false;
            }

            const meta = child.context?.meta || {};
            const windowType = meta.windowType as string | undefined;
            const category = meta.widgetCategory as string | undefined;
            const type = meta.widgetType as string | undefined;

            const isDialog = windowType === 'dialog';
            const matchesCategory = !!category && excludeWindowWidgetCategories.includes(category);
            const matchesType = !!type && excludeWindowWidgetTypes.includes(type);

            return isDialog || matchesCategory || matchesType;
          });

          // Omit specific props from specific widgets when saving a snapshot
          snapshot = traverseAndApplyTransformationToObject(
            snapshot,
            (obj) => {
              if (isWidgetActor(obj) || isFlexLayoutActor(obj)) {
                const props = obj.context?.componentProps || {};
                const widgetType = props?.widgetType as string | undefined;
                const excludeProps = widgetType ? excludePropsFromSnapshot[widgetType] || [] : [];
                return excludeProps.length > 0;
              }
              return false;
            },
            (obj: CreateActorOptions) => {
              if (isWidgetActor(obj) || isFlexLayoutActor(obj)) {
                const props = obj.context?.componentProps || {};
                const widgetType = props?.widgetType as string | undefined;
                const excludeProps = widgetType ? excludePropsFromSnapshot[widgetType] || [] : [];
                if (!excludeProps.length) return obj;

                // Omit specific props from specific widgets
                obj.context = {
                  ...obj.context,
                  componentProps: {
                    ...omit(props, excludeProps),
                    widgetType // Never allow omitting widgetType
                  }
                };
              }
              return obj;
            }
          );

          return snapshot;
        });

      let isAppReadySub: Subscription | undefined = undefined;
      workspace.addHook('leaderElection', ({ isLeader }) => {
        if (!isLeader) return;

        if (isAppReadySub) {
          isAppReadySub.unsubscribe();
        }

        // Load last loaded snapshot when app is ready
        const appReadyOnce$ = appState.ready$.pipe(take(1));
        isAppReadySub = appReadyOnce$.subscribe(() => {
          const container = workspace.meta.container;
          if (!container) {
            throw new Error(
              'Container is not defined. Have you added `foundationWorkspacePlugin` to the workspace?'
            );
            return;
          }
          const service = container.resolve(SnapshotsService);
          service
            .getCurrent()
            .then((snapshot) => {
              if (snapshot) {
                service.load(snapshot).catch(console.error);
              }
            })
            .catch(console.error);
        });
      });

      return function unsubscribe() {
        offlineReadySub.unsubscribe();
        isAppReadySub?.unsubscribe();
      };
    }
  });

/**
 * Type guard for WidgetActor
 *
 * @param obj - any
 * @returns boolean
 */
function isWidgetActor(obj: any): obj is CreateActorOptions<WidgetContext> {
  return 'type' in obj && obj.type === COMMON_ACTOR_TYPE.WIDGET;
}

/**
 * Type guard for FlexLayoutActor
 *
 * @param obj - any
 * @returns boolean
 */
function isFlexLayoutActor(obj: any): obj is CreateActorOptions<FlexLayoutContext> {
  return 'type' in obj && obj.type === FLEX_LAYOUT_ACTOR_TYPE;
}

/**
 * Register snapshot based rxdb hooks
 */
function registerSnapshotHooks(service: OfflineDb) {
  service.collections.snapshots.postRemove((data) => pruneGridsOnRemoveSnapshot(service, data.id), false);
}

/**
 * Delete all grids with the matching snapshotId just taken
 *
 * @param db - OfflineDb
 * @param snapshotId - string
 */
async function pruneGridsOnRemoveSnapshot(db: OfflineDb, snapshotId: string) {
  const gridsWithMatchingSnapshotId = await db.collections.grids.find({ selector: { snapshotId } }).exec();
  const gridIds = gridsWithMatchingSnapshotId.map((grid) => grid.id);
  await db.collections.grids.bulkRemove(gridIds);
}

/**
 * Util to omit / filter children from snapshot based on predicate
 *
 * @param snapshot - ActorSnapshotDefinition
 * @param predidate - (child: CreateActorOptions) => boolean (returns true to remove child)
 * @returns ActorSnapshotDefinition
 */
function filterChildrenFromSnapshot(
  snapshot: ActorSnapshotDefinition,
  predidate: (child: CreateActorOptions) => boolean
): ActorSnapshotDefinition {
  const { children, ...rest } = snapshot;
  const newChildren = children?.filter((child) => !predidate(child));
  return {
    ...rest,
    children: newChildren?.filter((child) => filterChildrenFromSnapshot(child, predidate)) || []
  };
}
