import { type ActionCommands } from './command.registry.types';
import { MONTAGE_BUMP_PRICE_ACTION } from '@app/widgets/trading/montage/commands/bump-price/bump-price.action';
import { MONTAGE_TARGET_TRADING_ORDER_ACTION } from '@app/widgets/trading/montage/commands/target-trading-order/target-trading-order.action';
import { CREATE_UNBOUND_TO_ACTION } from '@app/widgets/trading/route-order/commands/create-unbound-trading-order/create-unbound-trading-order.action';
import { type ActionDefFactory } from '@oms/frontend-vgrid';

/**
 * Register all commands here to be used by actions.
 */
export const ACTION_COMMAND_REGISTRY: Record<ActionCommands, ActionDefFactory> = {
  create_unbound_to: CREATE_UNBOUND_TO_ACTION,
  montage_bump_price: MONTAGE_BUMP_PRICE_ACTION,
  montage_target_trading_order: MONTAGE_TARGET_TRADING_ORDER_ACTION
};
