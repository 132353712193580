import { type ReactWidgetActorWrapperComp, useCurrentWindow } from '@valstro/workspace-react';
import { useAppStateStream } from '@app/data-access/memory/app.stream';
import { Center, Flex } from '@oms/shared-frontend/ui-design-system';
import { APP_STATE_TYPE } from '@app/common/app/app.contracts';
import { BROWSER_TAB_WINDOW_ACTOR_NAME } from '@valstro/workspace';
import { LauncherFrameContainer } from '@app/containers/launcher-frame/launcher-frame.container';
import type { ValstroEntitlement } from '@app/common/auth/keycloak.types';
import { useMemo } from 'react';
import { useUserRoles } from '@app/data-access/services/system/auth/auth.hooks';

export const WidgetWrapper: ReactWidgetActorWrapperComp = ({ children }) => {
  const window = useCurrentWindow();
  const canAccess = useUserRoles();
  const initMeta = window.initialDefinition.context.meta || {};
  const requiredRoles = (initMeta.requiredRoles as ValstroEntitlement[]) ?? [];
  const isDialog = initMeta.windowType === 'dialog';
  const isUserAuthorized = canAccess(requiredRoles);

  const isBrowserTab = window.name === BROWSER_TAB_WINDOW_ACTOR_NAME;
  const { state } = useAppStateStream();

  const component = useMemo(
    () => (
      <Flex
        sx={{
          position: 'absolute',
          inset: 0
        }}
        direction="column"
      >
        {children}
      </Flex>
    ),
    [children]
  );

  if (isDialog) {
    return component;
  }

  switch (state) {
    case APP_STATE_TYPE.IDLE:
      return null;
    case APP_STATE_TYPE.AUTHENTICATING:
      return <Center>Authenticating...</Center>;
    case APP_STATE_TYPE.UNAUTHORIZED:
      return <Center>Unauthorized</Center>;
    case APP_STATE_TYPE.DATA_ACCESS_CONNECTING:
      return <Center>Connecting...</Center>;
    case APP_STATE_TYPE.DATA_ACCESS_DISCONNECTED:
      return <Center>Data connection lost. Please refresh or restart.</Center>;
    case APP_STATE_TYPE.READY:
      switch (true) {
        case !isUserAuthorized:
          return <Center>Unauthorized</Center>;
        case isBrowserTab:
          return <LauncherFrameContainer>{children}</LauncherFrameContainer>;
        default:
          return component;
      }
    default:
      return null;
  }
};
