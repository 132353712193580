import { type ColDef } from '@ag-grid-community/core';
import {
  RepairErrorCodeRenderer,
  RepairErrorMessageRenderer
} from '@app/common/grids/cell-renderers/errors/errors.cell-renderer';
import { type ActionDefFactory } from '@oms/frontend-vgrid';
import { type TradeRepairRequestFragment } from '@oms/generated/frontend';

// "Group By" options for the Repair Queue's Trades grid
enum GroupByOptions {
  NOT_GROUPED = 'Not Grouped',
  CATEGORY = 'Group by Category',
  ACCOUNT = 'Group by Account',
  COUNTERPARTY = 'Group by Counterparty',
  SYMBOL = 'Group by Symbol',
  ERROR_CODE = 'Group by Error Code',
  ERROR_MESSAGE = 'Group by Error Message'
}

function groupByAndHide(columnDef: ColDef<TradeRepairRequestFragment> | undefined) {
  if (columnDef) {
    if (columnDef.field === 'errors') {
      columnDef.valueGetter = ({ data }) => {
        if (!data) {
          return null;
        }
        if (columnDef.headerName === 'Error Code') {
          if (data?.errors?.length === 1) {
            return data?.errors[0]?.errorCode;
          }
          if (data?.errors && data?.errors?.length > 1) {
            return 'Multiple error codes';
          }
        } else if (columnDef.headerName === 'Error Message') {
          if (data?.errors?.length === 1) {
            return data?.errors[0]?.message;
          }
          if (data?.errors && data?.errors?.length > 1) {
            return 'Multiple error messages';
          }
        }
      };

      columnDef.cellRenderer = 'agGroupCellRenderer';
    }
    columnDef.rowGroup = true;
    columnDef.hide = true;
  }
}

function ungroupByAndUnhide(columnDef: ColDef<TradeRepairRequestFragment> | undefined) {
  if (columnDef) {
    if (columnDef.field === 'errors') {
      columnDef.valueGetter = undefined;
      if (columnDef.headerName === 'Error Code') {
        columnDef.cellRenderer = RepairErrorCodeRenderer;
      } else if (columnDef.headerName === 'Error Message') {
        columnDef.cellRenderer = RepairErrorMessageRenderer;
      }
    }
    columnDef.rowGroup = false;
    columnDef.hide = false;
  }
}

export const groupByTradesAction: ActionDefFactory<TradeRepairRequestFragment> = (builder) =>
  builder
    .name('group_by_trades')
    .lifecycles('change')
    .toolbar((t) =>
      t
        .component('action-dropdown')
        .id('group_by_trades_filter')
        .location('HorizontalToolbarLeft')
        .props({
          options: Object.values(GroupByOptions),
          size: 'sm',
          style: { width: '15rem' }
        })
    )
    .onChange((e) => {
      if (e.lifecycle === 'change') {
        // Get the various columns we can possibly group by
        const columnDefs = e.api.getColumnDefs() as ColDef<TradeRepairRequestFragment>[];
        const categoryColumn = columnDefs.find(({ colId }) => colId === 'category');
        const accountNameColumn = columnDefs.find(({ colId }) => colId === 'tradeAccount.name');
        const counterpartyCodeColumn = columnDefs.find(
          ({ colId }) => colId === 'tradeCounterparty.tradeCounterpartyCode'
        );
        const symbolColumn = columnDefs.find(({ colId }) => colId === 'tradeInstrument.mappings.displayCode');
        // Both the "Error Code" and "Error Message" columns use 'errors' as the field. As such the colId of these
        // have DIFFERENT colId values ('errors' and 'errors_1' respectively.)
        // Find these two columns using 'field' instead of the usual 'colId'.
        const errorCodeColumn = columnDefs.find(
          ({ field, headerName }) => field === 'errors' && headerName === 'Error Code'
        );
        const errorMessageColumn = columnDefs.find(
          ({ field, headerName }) => field === 'errors' && headerName === 'Error Message'
        );

        switch (e.value) {
          case GroupByOptions.CATEGORY: {
            groupByAndHide(categoryColumn);
            ungroupByAndUnhide(accountNameColumn);
            ungroupByAndUnhide(counterpartyCodeColumn);
            ungroupByAndUnhide(symbolColumn);
            ungroupByAndUnhide(errorCodeColumn);
            ungroupByAndUnhide(errorMessageColumn);
            break;
          }
          case GroupByOptions.ACCOUNT: {
            groupByAndHide(accountNameColumn);
            ungroupByAndUnhide(categoryColumn);
            ungroupByAndUnhide(counterpartyCodeColumn);
            ungroupByAndUnhide(symbolColumn);
            ungroupByAndUnhide(errorCodeColumn);
            ungroupByAndUnhide(errorMessageColumn);
            break;
          }

          case GroupByOptions.COUNTERPARTY: {
            groupByAndHide(counterpartyCodeColumn);
            ungroupByAndUnhide(categoryColumn);
            ungroupByAndUnhide(accountNameColumn);
            ungroupByAndUnhide(symbolColumn);
            ungroupByAndUnhide(errorCodeColumn);
            ungroupByAndUnhide(errorMessageColumn);
            break;
          }

          case GroupByOptions.SYMBOL: {
            groupByAndHide(symbolColumn);
            ungroupByAndUnhide(categoryColumn);
            ungroupByAndUnhide(accountNameColumn);
            ungroupByAndUnhide(counterpartyCodeColumn);
            ungroupByAndUnhide(errorCodeColumn);
            ungroupByAndUnhide(errorMessageColumn);
            break;
          }
          case GroupByOptions.ERROR_CODE: {
            groupByAndHide(errorCodeColumn);
            ungroupByAndUnhide(categoryColumn);
            ungroupByAndUnhide(accountNameColumn);
            ungroupByAndUnhide(counterpartyCodeColumn);
            ungroupByAndUnhide(symbolColumn);
            ungroupByAndUnhide(errorMessageColumn);
            break;
          }
          case GroupByOptions.ERROR_MESSAGE: {
            groupByAndHide(errorMessageColumn);
            ungroupByAndUnhide(categoryColumn);
            ungroupByAndUnhide(accountNameColumn);
            ungroupByAndUnhide(counterpartyCodeColumn);
            ungroupByAndUnhide(symbolColumn);
            ungroupByAndUnhide(errorCodeColumn);
            break;
          }
          case GroupByOptions.NOT_GROUPED:
          default: {
            ungroupByAndUnhide(categoryColumn);
            ungroupByAndUnhide(accountNameColumn);
            ungroupByAndUnhide(counterpartyCodeColumn);
            ungroupByAndUnhide(symbolColumn);
            ungroupByAndUnhide(errorCodeColumn);
            ungroupByAndUnhide(errorMessageColumn);
            break;
          }
        }
        e.api.setGridOption('columnDefs', [...columnDefs]);
      }
    });
