/// <reference types="vite-plugin-svgr/client" />
import { useCallback } from 'react';
import CompactIcon from '../window-header/icons/compact.svg?react';
import StandardIcon from '../window-header/icons/standard.svg?react';
import ExpandedIcon from '../window-header/icons/expanded.svg?react';
import { useCurrentWindow, type FlexLayoutActorSchema } from '@valstro/workspace-react';
import { Actor, type WinSizeOptions } from '@valstro/workspace';
import { expandedMontageLayout, expandedMontageWindowSize } from './layouts/expanded.layout';
import { compactMontageLayout, compactMontageWindowSize } from './layouts/compact.layout';
import { standardMontageLayout, standardMontageWindowSize } from './layouts/standard.layout';
import { type MontageLayout } from '@app/data-access/services/trading/montage/montage.types';

const nextMontageLayout: Record<MontageLayout, MontageLayout> = {
  compact: 'expanded',
  expanded: 'standard',
  standard: 'compact'
};

const montageLayouts: Record<MontageLayout, typeof compactMontageLayout> = {
  compact: compactMontageLayout,
  expanded: expandedMontageLayout,
  standard: standardMontageLayout
};

const montageWindowSizes: Record<MontageLayout, WinSizeOptions> = {
  compact: compactMontageWindowSize,
  expanded: expandedMontageWindowSize,
  standard: standardMontageWindowSize
};

export function MontageTypeCarouselToggle({
  layout,
  flexLayoutActorId
}: {
  layout: MontageLayout;
  flexLayoutActorId: string | undefined;
}) {
  const windowActor = useCurrentWindow();

  const setLayout = useCallback(async () => {
    if (!flexLayoutActorId) {
      throw new Error('actorId is required');
    }

    const actor = await Actor.get<FlexLayoutActorSchema>(flexLayoutActorId);

    const nextState = nextMontageLayout[layout];

    try {
      await actor.operations.setLayout(montageLayouts[nextState]);

      const nextWindowSize = montageWindowSizes[nextState];

      nextWindowSize.useLogicalPixels = true; // ensures the window size is calculated correctly irrespective of the user's screen size

      await windowActor.operations.setSize(nextWindowSize);
    } catch (e) {
      throw new Error(`Failed to set layout: ${e}`);
    }
  }, [flexLayoutActorId, layout, windowActor.operations]);

  const handleClick = useCallback(() => {
    void setLayout();
  }, [setLayout]);

  return layout === 'compact' ? (
    <CompactIcon onClick={handleClick} style={{ cursor: 'pointer', marginLeft: '0.75em' }} />
  ) : layout === 'expanded' ? (
    <ExpandedIcon onClick={handleClick} style={{ cursor: 'pointer', marginLeft: '0.75em' }} />
  ) : (
    <StandardIcon onClick={handleClick} style={{ cursor: 'pointer', marginLeft: '0.75em' }} />
  );
}
