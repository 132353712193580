import { inject, singleton } from 'tsyringe';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import {
  type GetTradingOrderByIdQuery,
  type GetTradingOrderByIdQueryVariables,
  GetTradingOrderByIdDocument,
  type VisibleTradingOrderInfoWithAllocationsFragment,
  type CancelTradingOrderMutation,
  type CancelTradingOrderMutationVariables,
  CancelTradingOrderDocument,
  type CancelTradingOrdersMutation,
  type CancelTradingOrdersMutationVariables,
  CancelTradingOrdersDocument,
  type CancelTradingOrdersInput,
  type BulkForceCancelTradingOrdersMutation,
  type BulkForceCancelTradingOrdersMutationVariables,
  BulkForceCancelTradingOrdersDocument,
  type AddTradingOrderMutation,
  type AddTradingOrderMutationVariables,
  AddTradingOrderDocument,
  type ModifyTradingOrderInput,
  type ModifyTradingOrderMutation,
  type ModifyTradingOrderMutationVariables,
  ModifyTradingOrderDocument,
  MontageUnboundTradingOrdersDocument,
  type FirmAccountDefaultsQuery,
  type FirmAccountDefaultsQueryVariables,
  FirmAccountDefaultsDocument
} from '@oms/generated/frontend';
import { map } from 'rxjs';
import type { AwaitGQLResultType } from '@oms/frontend-foundation';
import type { CreateTradingOrderOutput } from '@app/common/types/orders/orders.types';

@singleton()
export class TradingOrdersService {
  constructor(
    @inject(ApolloClientRPC) private apolloClient: ApolloClientRPC,
    @inject(GQLResponse) private gqlResponse: GQLResponse
  ) {}

  public getById(id: string) {
    return this.gqlResponse
      .wrapQuery<GetTradingOrderByIdQuery, GetTradingOrderByIdQueryVariables>({
        query: GetTradingOrderByIdDocument,
        variables: {
          id
        }
      })
      .exec();
  }

  public pollById$(id: string, pollInterval = 5000) {
    return this.apolloClient
      .watchQuery<GetTradingOrderByIdQuery, GetTradingOrderByIdQueryVariables>({
        query: GetTradingOrderByIdDocument,
        variables: {
          id
        },
        pollInterval
      })
      .pipe(
        map((result) => result.data?.visibleTradingOrder as VisibleTradingOrderInfoWithAllocationsFragment)
      );
  }

  public create(
    input: CreateTradingOrderOutput,
    options: { dryRun: boolean } = { dryRun: false }
  ): AwaitGQLResultType<AddTradingOrderMutation> {
    const { dryRun } = options;
    const gqlResponse = this.gqlResponse.wrapMutate<
      AddTradingOrderMutation,
      AddTradingOrderMutationVariables
    >({
      mutation: AddTradingOrderDocument,
      variables: {
        tradingOrder: input.tradingOrder,
        investorOrderIds: input.investorOrderIds,
        dryRun
      },
      refetchQueries: [GetTradingOrderByIdDocument, MontageUnboundTradingOrdersDocument]
    });

    return gqlResponse.awaitAsyncResponse().exec();
  }

  public modify(
    input: ModifyTradingOrderInput,
    options?: { dryRun: boolean }
  ): AwaitGQLResultType<ModifyTradingOrderMutation> {
    const { dryRun = false } = options || {};
    const gqlResponse = this.gqlResponse.wrapMutate<
      ModifyTradingOrderMutation,
      ModifyTradingOrderMutationVariables
    >({
      mutation: ModifyTradingOrderDocument,
      variables: {
        modification: input,
        dryRun
      },
      refetchQueries: [GetTradingOrderByIdDocument, MontageUnboundTradingOrdersDocument]
    });

    return gqlResponse.awaitAsyncResponse().exec();
  }

  public cancel(id: string): AwaitGQLResultType<CancelTradingOrderMutation> {
    const gqlResponse = this.gqlResponse.wrapMutate<
      CancelTradingOrderMutation,
      CancelTradingOrderMutationVariables
    >({
      mutation: CancelTradingOrderDocument,
      variables: { id },
      refetchQueries: [GetTradingOrderByIdDocument, MontageUnboundTradingOrdersDocument]
    });

    return gqlResponse.awaitAsyncResponse().exec();
  }

  public cancelTradingOrders(
    input: CancelTradingOrdersInput
  ): AwaitGQLResultType<CancelTradingOrdersMutation> {
    const gqlResponse = this.gqlResponse.wrapMutate<
      CancelTradingOrdersMutation,
      CancelTradingOrdersMutationVariables
    >({
      mutation: CancelTradingOrdersDocument,
      variables: { input }
    });

    return gqlResponse.awaitAsyncResponse().exec();
  }

  public forceCancelTradingOrders(
    orderIds: string[]
  ): AwaitGQLResultType<BulkForceCancelTradingOrdersMutation> {
    const gqlResponse = this.gqlResponse.wrapMutate<
      BulkForceCancelTradingOrdersMutation,
      BulkForceCancelTradingOrdersMutationVariables
    >({
      mutation: BulkForceCancelTradingOrdersDocument,
      variables: { orderIds: orderIds }
    });

    return gqlResponse.awaitAsyncResponse().exec();
  }

  public async getDefaultFirmAccount(userId?: string, instrumentId?: string) {
    return await this.apolloClient
      .query<FirmAccountDefaultsQuery, FirmAccountDefaultsQueryVariables>({
        query: FirmAccountDefaultsDocument,
        fetchPolicy: 'no-cache',
        variables: { userId: userId, instrumentId: instrumentId }
      })
      .then((res) => {
        const userDefaultsFirmAccount = res.data.getUserDefaults?.firmAccount;
        const instrumentCoverageFirmAccount = res.data.getInstrumentCoverage?.defaultFirmAccount;
        const firmAccount = userDefaultsFirmAccount || instrumentCoverageFirmAccount;

        return firmAccount;
      });
  }
}
