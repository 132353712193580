import { inject, singleton } from 'tsyringe';
import {
  type AllocationMap,
  GetInvestorOrdersByTradingOrderIdDocument,
  type GetInvestorOrdersByTradingOrderIdQuery,
  type GetInvestorOrdersByTradingOrderIdQueryVariables,
  type VisibleInvestorOrderInfoWithAllocationsFragment
} from '@oms/generated/frontend';
import { map } from 'rxjs';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';

export type TradingOrderInvestorOrderRow = VisibleInvestorOrderInfoWithAllocationsFragment & {
  mappedQuantity: number;
};

@singleton()
export class TradingOrderInvestorOrdersService {
  constructor(@inject(ApolloClientRPC) private apolloClient: ApolloClientRPC) {}

  public watchAll$(tradingOrderId?: string, pollInterval = 5000) {
    return this.apolloClient
      .watchQuery<GetInvestorOrdersByTradingOrderIdQuery, GetInvestorOrdersByTradingOrderIdQueryVariables>({
        query: GetInvestorOrdersByTradingOrderIdDocument,
        pollInterval,
        fetchPolicy: 'no-cache',
        variables: {
          id: tradingOrderId ?? ''
        }
      })
      .pipe(
        map((res) => {
          const allocationMapsResults = res.data.tradingOrder?.allocationMaps?.nodes
            ? (res.data.tradingOrder.allocationMaps.nodes as AllocationMap[])
            : [];
          // Investor Order values are in allocationMaps.investorOrder
          // Mapped Quantity is in allocationMaps.allocatedQuantity
          // Combine them.
          const combinedResults = allocationMapsResults.map((res) => {
            return {
              ...res.visibleInvestorOrder,
              mappedQuantity: res.allocatedQuantity
            };
          }) as TradingOrderInvestorOrderRow[];

          return combinedResults;
        })
      );
  }
}
