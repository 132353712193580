import { type FC, useRef, useMemo, useEffect } from 'react';
import { type VGridReadyEvent, type ComponentLocationKeys, VGrid, useVGrid } from '@oms/frontend-vgrid';
import { type ApplyColumnStateParams, type MenuItemDef, RowNode } from '@ag-grid-community/core';
import type { MontageItem } from '@app/data-access/services/trading/montage/montage.types';
import { useMontageLayout } from '../use-montage-layout.hooks';
import { useDepthGridData } from './hooks/use-depth-grid-data.hook';
import { useLevel2Websocket } from './hooks/use-level2-websocket.hook';
import { LeftClickHandler } from './left-click.handler';
import { depthGridColumns } from './depth.grid.columns';
import type { DepthGridProps } from './depth-grid.types';
import { ButtonConfigSubject } from '@app/actions/events/button.config.subject';
import { setupGridActions } from '@app/actions/grids/setup.configurable.actions';
import { type ComboBoxItem } from '@oms/frontend-foundation';
import {
  type MontageInstrumentFragment,
  TradingOrderCategory,
  type InstrumentDetailsSimpleFragment
} from '@oms/generated/frontend';
import { useMontageInstrumentDetails } from '@app/data-access/services/trading/montage/hooks/use-montage-instrument-details.hook';
import { ToolbarService } from '@oms/frontend-vgrid';
import { useClosestFlexLayoutActor } from '@valstro/workspace-react';
import { MontageContextMenuItem } from './contextmenu/montage-contextmenu-item';
import { QuoteMontageMenuItem } from './contextmenu/quote-contextmenu-item';
import { type Optional } from '@oms/shared/util-types';

const DEPTH_GRID_TYPE = 'DEPTH_GRID';

const getDepthGridType = (type: DepthGridProps['type']): string => type.toUpperCase();

const convertToInstrumentCombo = (
  instrument?: MontageInstrumentFragment
): Optional<ComboBoxItem<InstrumentDetailsSimpleFragment>> =>
  instrument
    ? {
        type: 'item',
        id: instrument.id,
        value: {
          __typename: 'InstrumentDetails',
          id: instrument.id,
          longName: instrument.longName,
          mappings: {
            __typename: 'InstrumentMapping',
            displayCode: instrument.mappings.displayCode,
            figi: instrument.mappings.displayCode
          },
          listingExchange: {
            __typename: 'Exchange',
            countryId: undefined
          },
          active: instrument.active
        },
        label: instrument.mappings.displayCode || ''
      }
    : undefined;

export const DepthGrid: FC<DepthGridProps> = ({ type }) => {
  const flexLayoutActorId = useClosestFlexLayoutActor()?.id;
  useLevel2Websocket();

  const { data$, targetState, pricePartitionMap$ } = useDepthGridData({ type });

  const gridActionContext$ = useRef(new ButtonConfigSubject());
  const toolbarService = useRef<ToolbarService>();

  const { layout } = useMontageLayout();

  const instrument = useMontageInstrumentDetails();

  useEffect(
    function setupInstrumentCommandState() {
      const instrumentCombo = convertToInstrumentCombo(instrument);
      gridActionContext$.current.forCommand({
        commandId: 'create_unbound_to',
        payload: {
          instrument: instrumentCombo,
          category: TradingOrderCategory.Montage
        }
      });
    },
    [instrument]
  );

  useEffect(
    function setupMontageTargetState() {
      if (targetState) {
        gridActionContext$.current.forCommands(
          {
            commandId: 'montage_target_trading_order',
            payload: { targetState }
          },
          {
            commandId: 'montage_bump_price',
            payload: { targetState }
          }
        );
      }
    },
    [targetState]
  );

  const gridContext = useMemo(
    () => ({
      instrumentDisplayCode: instrument?.mappings?.displayCode,
      instrumentId: instrument?.id
    }),
    [instrument]
  );

  const toolbarLocation: ComponentLocationKeys =
    type === 'bid' ? 'LeftVerticalToolbar' : 'RightVerticalToolbar';

  useEffect(() => {
    if (layout === 'compact' && toolbarService.current) {
      toolbarService.current.update({
        locations: [toolbarLocation],
        strategy: 'hidden'
      });
    } else if (toolbarService.current) {
      toolbarService.current.update({
        locations: [toolbarLocation],
        strategy: 'visible'
      });
    }
  }, [layout, toolbarLocation]);

  const gridType = `${getDepthGridType(type)}_${DEPTH_GRID_TYPE}`;

  const gridProps = useVGrid<MontageItem>(
    gridType,
    (b) =>
      setupGridActions({
        meta: {
          widgetTypeId: 'MONTAGE_DATA_PANEL',
          props: {
            shape: 'fat'
          },
          actionContext$: gridActionContext$.current,
          allowedCommands: ['create_unbound_to', 'montage_bump_price', 'montage_target_trading_order']
        },
        grid: b
          .onVGridReady((e: VGridReadyEvent<MontageItem>) => {
            e.gridApi.addEventListener('firstDataRendered', () => {
              e.gridApi.sizeColumnsToFit();
            });

            e.gridApi.setGridOption('onCellContextMenu', ({ event, node }) => {
              if (node && node.data && node instanceof RowNode && event instanceof MouseEvent) {
                e.gridApi.showContextMenu({
                  rowNode: node,
                  y: event.clientY,
                  x: event.clientX,
                  value: node.data as MontageItem
                });
              }
            });

            e.gridApi.setGridOption('getContextMenuItems', ({ node }) => {
              const modifyMenuItem: MenuItemDef<MontageItem, any> = {
                name: 'Modify',
                menuItem: MontageContextMenuItem,
                menuItemParams: {
                  type: node?.data?.type
                }
              };

              const cancelMenuItem: MenuItemDef<MontageItem, any> = {
                name: 'Cancel',
                menuItem: MontageContextMenuItem,
                menuItemParams: {
                  type: node?.data?.type
                }
              };

              const quoteMenuItem: MenuItemDef<MontageItem, any> = {
                name: 'Quote',
                menuItem: QuoteMontageMenuItem,
                menuItemParams: {
                  instrument: instrument?.mappings.displayCode || ''
                }
              };

              const showQuoteMenuItem = instrument?.listingExchange?.countryId === 'US' && instrument?.active;

              return [
                modifyMenuItem,
                'separator',
                cancelMenuItem,
                ...(showQuoteMenuItem ? ['separator', quoteMenuItem] : [])
              ];
            });

            toolbarService.current = e.container.resolve(ToolbarService);
            const initialColumnState: ApplyColumnStateParams =
              type === 'ask'
                ? {
                    state: [
                      { colId: 'limitPrice', sort: 'asc', sortIndex: 0 },
                      { colId: 'size', sort: 'desc', sortIndex: 1 },
                      { colId: 'counterpartyId', sort: 'asc', sortIndex: 2 }
                    ]
                  }
                : {
                    state: [
                      { colId: 'limitPrice', sort: 'desc', sortIndex: 0 },
                      { colId: 'size', sort: 'desc', sortIndex: 1 },
                      { colId: 'counterpartyId', sort: 'asc', sortIndex: 2 }
                    ]
                  };
            e.gridApi.applyColumnState(initialColumnState);
            e.gridApi.sizeColumnsToFit();
          })
          .context(gridContext)
          .injectEvents([LeftClickHandler])
          .columnLibrary(depthGridColumns({ type, targetState, pricePartitionMap$ }))
          .datasource((d) => d.source(data$).rowId(({ data }) => data.id))
          .toolbarStrategies({ [toolbarLocation]: 'visible' })
      }),
    [toolbarLocation, layout, instrument?.id],
    { gridId: `${flexLayoutActorId}${getDepthGridType(type)}` }
  );

  return (
    <VGrid<MontageItem> {...gridProps} scrollbarWidth={type === 'bid' ? 0 : undefined} animateRows={false} />
  );
};
