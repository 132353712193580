import {
  Button,
  type ButtonProps,
  Center,
  Flex,
  Text,
  type AlertBannerStackItem,
  AlertBannerStack
} from '@oms/shared-frontend/ui-design-system';

export interface ConfirmationDialogDisplayProps {
  message: string;
  alerts?: AlertBannerStackItem[];
  onConfirm: () => void;
  onCancel?: () => void;
  confirmButtonText?: string;
  confirmButtonProps?: ButtonProps;
  cancelButtonText?: string;
  isConfirmDisabled?: boolean;
  isCancelDisabled?: boolean;
  hideCancelButton?: boolean;
}

export const ConfirmationDialogDisplay: React.FC<React.PropsWithChildren<ConfirmationDialogDisplayProps>> = ({
  message,
  alerts = [],
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  confirmButtonProps = {},
  onCancel,
  onConfirm,
  isConfirmDisabled = false,
  isCancelDisabled = false,
  hideCancelButton = false,
  children
}) => {
  return (
    <Flex
      aria-label="confirmation dialog"
      role="dialog"
      direction="column"
      sx={{ backgroundColor: 'layout.level1', position: 'absolute', inset: 0, gap: 0, p: 0 }}
    >
      {alerts.length > 0 && <AlertBannerStack variant="sm" alerts={alerts} sx={{ overflowY: 'scroll' }} />}

      <Center sx={{ flexDirection: 'column' }}>
        <Text
          as="div"
          type="largeR"
          sx={{
            textAlign: 'center',
            color: 'text.tertiary'
          }}
        >
          {message}
        </Text>
        {children}
      </Center>

      <Flex sx={{ marginTop: 'auto', gap: 6, padding: 6 }}>
        {hideCancelButton !== true && onCancel && (
          <Button
            size="md"
            sx={{
              flexGrow: 1
            }}
            variant="secondary"
            onClick={onCancel}
            isDisabled={isCancelDisabled}
            aria-label={`Confirm ${cancelButtonText}`}
          >
            {cancelButtonText}
          </Button>
        )}

        <Button
          size="md"
          palette="danger"
          variant="primary"
          sx={{
            flexGrow: 1
          }}
          onClick={onConfirm}
          isDisabled={isConfirmDisabled}
          aria-label={`Confirm ${confirmButtonText}`}
          {...confirmButtonProps}
        >
          {confirmButtonText}
        </Button>
      </Flex>
    </Flex>
  );
};
