import { Lifecycle, scoped, inject } from 'tsyringe';
import type {
  VisibilityReason,
  VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment
} from '@oms/generated/frontend';
import {
  type EventHandler,
  type EventSource,
  type GridEvent,
  ContextMenuService,
  VGridInstance,
  type VGridContextInstance
} from '@oms/frontend-vgrid';
import type { GridApi } from '@ag-grid-community/core';
import { ORDER_VISBILITY_FIELD_NAME, OWNER_ID_FIELD_NAME } from '@app/common/grids/columns/order-cols';
import { IO_VIS_KEY, IO_VIS_VALUE, type IOVisKey } from './investor-order-monitor.visibility.filters';
import debounce from 'lodash/debounce';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

@scoped(Lifecycle.ContainerScoped)
export class InvestorOrderMonitorVisibilityEventHander implements EventHandler<IOFragment> {
  public name = 'investor-order-visiblity-events';
  private currentVisibilityFilter: IOVisKey = IO_VIS_KEY.ALL;
  private api: GridApi<IOFragment> | undefined;
  private authService: AuthService;

  constructor(
    @inject(VGridInstance.Context) context: VGridContextInstance,
    @inject(ContextMenuService)
    private contextMenuService: ContextMenuService<IOFragment>
  ) {
    this.authService = context.parentContainer.resolve(AuthService);
  }

  public addEvents(eventSource: EventSource<keyof GridEvent, IOFragment>): void {
    eventSource.add('onGridReady', ({ api }) => {
      this.api = api;
      this.renderVisibilityContextMenuItems();
    });
    eventSource.add('onFilterChanged', () => {
      this.handleVisibilityFilterChange();
    });
  }

  public removeEvents(): void {}

  /**
   * Re-render the visibility context menu items
   * with the correct checked state
   * when the filter changes
   */
  private handleVisibilityFilterChange = () => {
    const api = this.assertApi();
    const currentFilters = api.getFilterModel() as {
      [ORDER_VISBILITY_FIELD_NAME]: {
        filterType: string;
        values: VisibilityReason[];
      };
      [OWNER_ID_FIELD_NAME]: {
        filterType: string;
        operator: string;
        filter?: string;
        condition1: {
          filterType: string;
          type: string;
          filter?: string;
        };
        condition2: {
          filterType: string;
          type: string;
        };
      };
    };

    const selectedValues = currentFilters[ORDER_VISBILITY_FIELD_NAME]?.values || [];
    const hasOwnerIdCondition =
      currentFilters[OWNER_ID_FIELD_NAME]?.condition1?.filter === this.authService.getUserId() || '';
    const hasOwnerIdFilter =
      currentFilters[OWNER_ID_FIELD_NAME]?.filter === this.authService.getUserId() || '';
    const hasOwnerId = hasOwnerIdCondition || hasOwnerIdFilter;

    switch (true) {
      case hasOwnerId &&
        this.haveSameStrings(
          selectedValues,
          IO_VIS_VALUE[IO_VIS_KEY.MINE].map((x) => x.toString())
        ):
        this.currentVisibilityFilter = IO_VIS_KEY.MINE;
        break;
      case this.haveSameStrings(
        selectedValues,
        IO_VIS_VALUE[IO_VIS_KEY.COVERAGE_TEAM_WITH_PRIMARY].map((x) => x.toString())
      ):
        this.currentVisibilityFilter = IO_VIS_KEY.COVERAGE_TEAM_WITH_PRIMARY;
        break;
      case !hasOwnerId &&
        this.haveSameStrings(
          selectedValues,
          IO_VIS_VALUE[IO_VIS_KEY.COVERAGE_PRIMARY].map((x) => x.toString())
        ):
        this.currentVisibilityFilter = IO_VIS_KEY.COVERAGE_PRIMARY;
        break;
      case this.haveSameStrings(
        selectedValues,
        IO_VIS_VALUE[IO_VIS_KEY.COVERAGE_TEAM].map((x) => x.toString())
      ):
        this.currentVisibilityFilter = IO_VIS_KEY.COVERAGE_TEAM;
        break;
      case this.haveSameStrings(
        selectedValues,
        IO_VIS_VALUE[IO_VIS_KEY.ALL].map((x: string) => x.toString())
      ):
        this.currentVisibilityFilter = IO_VIS_KEY.ALL;
        break;
      default:
        this.currentVisibilityFilter = IO_VIS_KEY.ALL;
        break;
    }

    this.renderVisibilityContextMenuItems();
  };

  private debouncedVisibilityFilterChange = () => debounce(this.handleVisibilityFilterChange, 100);

  /**
   * Render the visibility context menu items
   * with the correct checked state
   */
  private renderVisibilityContextMenuItems() {
    const checkedKey = this.currentVisibilityFilter;

    this.contextMenuService.upsert([
      {
        id: 'visibility',
        pinToBottom: true,
        callback: () => ({
          name: 'Visibility',
          subMenu: [
            {
              // If the ownerId filter is set to the logged in user AND visibility is set to Owner, then we know it's the "My Orders" filter
              checked: checkedKey === IO_VIS_KEY.MINE,
              name: 'My Orders',
              action: () => this.selectedVisibilityFilter(IO_VIS_KEY.MINE)
            },
            {
              checked: checkedKey === IO_VIS_KEY.COVERAGE_PRIMARY,
              name: 'Primary Coverage Orders',
              action: () => this.selectedVisibilityFilter(IO_VIS_KEY.COVERAGE_PRIMARY)
            },
            {
              checked: checkedKey === IO_VIS_KEY.COVERAGE_TEAM,
              name: 'Team Coverage Orders',
              action: () => this.selectedVisibilityFilter(IO_VIS_KEY.COVERAGE_TEAM)
            },
            {
              checked: checkedKey === IO_VIS_KEY.COVERAGE_TEAM_WITH_PRIMARY,
              name: 'Team Coverage Orders (Inc. Primary)',
              action: () => this.selectedVisibilityFilter(IO_VIS_KEY.COVERAGE_TEAM_WITH_PRIMARY)
            },
            {
              checked: checkedKey === IO_VIS_KEY.ALL,
              name: 'All Orders',
              action: () => this.selectedVisibilityFilter(IO_VIS_KEY.ALL)
            }
          ]
        })
      }
    ]);
  }

  /**
   * Update the visibility filter in Ag grid.
   * @param selectedKey
   */
  private selectedVisibilityFilter(selectedKey: IOVisKey) {
    const api = this.assertApi();
    this.currentVisibilityFilter = selectedKey;
    this.renderVisibilityContextMenuItems();
    const currentFilters = api.getFilterModel();

    api.setFilterModel({
      ...currentFilters,
      [ORDER_VISBILITY_FIELD_NAME]:
        selectedKey === IO_VIS_KEY.ALL
          ? undefined
          : {
              type: 'set',
              values: IO_VIS_VALUE[selectedKey]
            },
      [OWNER_ID_FIELD_NAME]:
        selectedKey !== IO_VIS_KEY.MINE
          ? undefined
          : {
              filterType: 'text',
              operator: 'OR',
              condition1: {
                filterType: 'text',
                type: 'equals',
                filter: this.authService.getUserId() || ''
              },
              condition2: {
                filterType: 'text',
                type: 'blank'
              }
            }
    });
  }

  private assertApi(): GridApi<IOFragment> {
    if (!this.api) {
      throw new Error('Grid API is not available');
    }
    return this.api;
  }

  private haveSameStrings(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  }
}
