import React from 'react';
import type { FORM_COMPONENT_TYPE } from '../../common/form.contracts';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import {
  FieldWrapper,
  useFieldApi,
  useFieldFocus,
  type FieldProps,
  type ICommonTextField
} from '@oms/frontend-foundation';
import { Input } from '@oms/shared-frontend/ui-design-system';

export interface IExampleField<TValidator = Validator>
  extends ICommonTextField<typeof FORM_COMPONENT_TYPE.EXAMPLE, string, TValidator> {}

export const ExampleField: React.FC<FieldProps<IExampleField>> = (props) => {
  const {
    meta,
    label,
    input: { name, onBlur, onChange, onFocus, value },
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    isFeatureField,
    isPrimaryField,
    requiredFieldIndicatorStyle,
    hideFormControls,
    style,
    sx,
    wrapperSx = {},
    formatter,
    initialValue: _initialValue,
    value: _value,
    ...rest
  } = useFieldApi<IExampleField, string>(props);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  // Combine field/input props
  const fieldProps = {
    onBlur,
    onChange,
    onFocus,
    value,
    hidden: isVisible === false,
    style,
    sx,
    formatter,
    ...rest,
    name
  };

  // Combine field wrapper props
  const fieldWrapperProps = {
    meta,
    label,
    isReadOnly,
    isRequired,
    isDisabled,
    isInvalid,
    requiredFieldIndicatorStyle,
    helperText,
    hideFormControls,
    isFeatureField,
    isPrimaryField,
    isVisible,
    sx: wrapperSx
  };

  // Handle selectAllOnFocus & autoFocus
  const [inputRef, onInputFocus] = useFieldFocus<HTMLInputElement>(props);

  return (
    <FieldWrapper {...fieldWrapperProps}>
      <Input ref={inputRef} {...fieldProps} onFocus={onInputFocus} />
    </FieldWrapper>
  );
};
