import { type ApolloCache, ApolloError, type ApolloQueryResult, type QueryOptions } from '@apollo/client';
import type {
  DefaultContext,
  FetchResult,
  NormalizedCacheObject,
  OperationVariables,
  ServerError as ServerErrorType,
  ServerParseError as ServerParseErrorType
} from '@apollo/client';
import type { GraphQLErrors } from '@apollo/client/errors';
import {
  AsyncError,
  AsyncTimeoutError,
  ErrorTypeEnum,
  GeneralError,
  ServerError,
  ServerParseError,
  FieldValidationError,
  InternalServerError,
  ValidationError
} from '@oms/shared/oms-common';
import type {
  IAllCustomErrors,
  IGeneralError,
  IInternalServerError,
  IFieldValidationError
} from '@oms/shared/oms-common';
import { type Subscription, filter, map, take, timeout } from 'rxjs';
import {
  type AllGQLResponseErrors,
  type AwaitAsyncResponseOptions,
  type AwaitAsyncResponseResolver,
  type AwaitGQLMutationResp,
  type AwaitGQLQueryResp,
  GQLResult,
  extractGQLEnvelope,
  EnvelopeFeedbackError
} from '@oms/frontend-foundation';
import { createLogger, UUID } from '@oms/shared/util';
import type { GraphQLError } from 'graphql';
import {
  type AsyncResponseInfoFragment,
  type OnAsyncResponseSubscription,
  OnAsyncResponseDocument
} from '@oms/generated/frontend';
import { t } from '@oms/codegen/translations';
import { getEnvVar } from '@app/common/env/env.util';
import { ApolloClientRPC } from '../apollo-client-rpc';
import type { SerializableMutationOptions } from '../apollo-client-rpc.types';
import { inject, singleton } from 'tsyringe';

/**
 * A wrapper around a GraphQL mutation response.
 *
 * This class is used to wrap a GraphQL mutation response and provide a `GQLResult` type
 * that can be used to determine if the mutation was successful or not.
 *
 * Usage:
 *
 * const gqlResponse = container.resolve(GQLResponse);
 *
 * const mutation = gqlResponse.wrapMutate<AddTradingOrderMutation, AddTradingOrderMutationVariables>({
 *   mutation: AddTradingOrderDocument,
 *   variables: input
 * });
 *
 * const result = await mutation.exec();
 *
 * result.mapSync(
 *    (result) => {
 *      const boom = result.data?.addTradingOrder?.operationId;
 *    },
 *    {
 *      GENERAL_ERROR: (generalErrors) => {
 *        this.#logger.error(generalErrors);
 *      },
 *      FIELD_VALIDATION_ERROR: (validationErrors) => {
 *        this.#logger.error(validationErrors);
 *      }
 *    },
 *    (remainingErrors) => {
 *      this.#logger.error(remainingErrors);
 *    }
 * );
 */

type GQLResponseOptions<P extends AwaitGQLQueryResp | AwaitGQLMutationResp> = {
  promise?: P;
  operationId?: string;
  type?: 'query' | 'mutation';
  // defaults to true
  failWithFeedback?: boolean;
  isDryRun?: boolean;
};

@singleton()
export class GQLResponse<P extends AwaitGQLQueryResp | AwaitGQLMutationResp = Promise<FetchResult>> {
  #type: 'query' | 'mutation' | 'unknown' = 'unknown';
  #apolloClientRPC?: ApolloClientRPC;
  #promise?: P;
  #isAsyncResponse: boolean;
  #asyncTimeout: number;
  #customAsyncResolver?: AwaitAsyncResponseResolver;
  #failWithFeedback = false;
  #isDryRun = false;
  #result: GQLResult<Awaited<P>, never> | GQLResult<never, AllGQLResponseErrors> | null;
  operationId: string | null = null;
  #logger: ReturnType<typeof createLogger>;
  #defaultErrorMessage = t('app.common.dialogs.unknownErrorMessage');

  constructor(@inject(ApolloClientRPC) apolloClientRPC: ApolloClientRPC, options?: GQLResponseOptions<P>) {
    this.#type = options?.type || 'unknown';
    this.#result = null;
    this.#promise = options?.promise;
    this.#isAsyncResponse = false;
    this.#asyncTimeout = 0; // 0 = Infinite timeout
    this.operationId = options?.operationId || null;
    this.#apolloClientRPC = apolloClientRPC;
    this.#logger = createLogger({ label: 'GQLResponse' });
    this.#failWithFeedback = !!options?.failWithFeedback ?? true;
    this.#isDryRun = !!options?.isDryRun;
  }

  /**
   * Create an apollo query wrapped in a `GQLResponse` instance
   *
   * @param options - An apollo client query
   * @returns A `GQLResponse` instance holding the query promise
   */
  public wrapQuery<T = any, TVariables extends OperationVariables = OperationVariables>(
    options: QueryOptions<TVariables, T>
  ): GQLResponse<Promise<ApolloQueryResult<T>>> {
    if (!this.#apolloClientRPC) throw new Error('Apollo client not found');
    const queryPromise = this.#apolloClientRPC.query<T, TVariables>(options);
    return new GQLResponse(this.#apolloClientRPC, {
      promise: queryPromise,
      type: 'query'
    });
  }

  /**
   * Create an apollo mutation wrapped in a `GQLResponse` instance
   *
   * @param options - An apollo client mutation
   * @returns A `GQLResponse` instance holding the mutation promise
   */
  public wrapMutate<
    TData = any,
    TVariables extends OperationVariables = OperationVariables,
    TContext extends Record<string, any> = DefaultContext,
    TCache extends ApolloCache<NormalizedCacheObject> = ApolloCache<NormalizedCacheObject>
  >(
    options: SerializableMutationOptions<TData, TVariables, TContext>,
    customOperationId?: string
  ): GQLResponse<Promise<FetchResult<TData>>> {
    if (!this.#apolloClientRPC) throw new Error('Apollo client not found');
    const operationId =
      customOperationId ||
      ((options?.context?.headers as Record<string, string>)?.['x-valstro-operation-id'] as
        | string
        | undefined) ||
      UUID();
    const extraHeaders = { 'x-valstro-operation-id': operationId };
    const mutationPromise = this.#apolloClientRPC.mutate<TData, TVariables, TContext, TCache>({
      ...options,
      context: {
        ...(options?.context || {}),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          ...(options?.context?.headers || {}),
          ...extraHeaders
        }
      } as unknown as TContext
    });
    const isDryRun = options?.variables?.dryRun === true;
    return new GQLResponse(this.#apolloClientRPC, {
      promise: mutationPromise,
      operationId,
      type: 'mutation',
      failWithFeedback: isDryRun === false,
      isDryRun
    });
  }

  /**
   * Do not fail with feedback errors, force return a success result
   * Note: This gets automatically set if the mutation variables has `dryRun` set to true
   * but can be manually overridden
   *
   * @returns the same instance that returns a success result when the envelope has feedback errors OR enrichedData
   */
  public noFailWithFeedback() {
    this.#failWithFeedback = false;
    return this;
  }

  /**
   * Fail with feedback errors
   * Note: This gets automatically set if the mutation variables has `dryRun` set to true
   * but can be manually overridden
   *
   * @returns the same instance that returns a failure result when the envelope has feedback errors
   */
  public failWithFeedback() {
    this.#failWithFeedback = false;
    return this;
  }

  /**
   * Get result of the response (if it has been executed)
   * @returns The result of the mutation (if it has been executed)
   */
  get result() {
    return this.#result;
  }

  /**
   * Wait for an async response from GQL subscription and timeout if not received
   * Note: Do not use this if your mutation does not have corresponding async response
   * @param options - AwaitAsyncResponseOptions
   * @returns the same instance that now waits for an async response
   */
  awaitAsyncResponse(options?: AwaitAsyncResponseOptions) {
    if (this.#isDryRun) {
      return this;
    }

    if (this.#type === 'query') {
      throw new Error('Cannot await async response for a query');
    }

    if (this.#type === 'unknown') {
      throw new Error('Please wrap a mutation before awaiting async response');
    }

    // In storybook, mock apollo client can't send async response
    // so we ignore this in storybook
    const isRunningInStorybook = getEnvVar('STORYBOOK') === 'true';
    if (isRunningInStorybook) {
      return this;
    }

    const timeout = typeof options === 'number' ? options : options?.timeout || 0;
    const resolver = typeof options === 'object' ? options?.resolver : undefined;
    this.#asyncTimeout = timeout;
    this.#isAsyncResponse = true;
    this.#customAsyncResolver = resolver;
    return this;
  }

  /**
   * @returns the same instance that does NOT wait for an async response
   */
  syncResponse() {
    this.#isAsyncResponse = false;
    return this;
  }

  /**
   * Execute the mutation and return the result
   * @returns The result of the mutation
   */
  exec = async (): Promise<GQLResult<Awaited<P>, AllGQLResponseErrors>> => {
    if (this.#promise === undefined) {
      throw new Error('No promise found');
    }

    if (this.#isAsyncResponse && !this.operationId) {
      throw new Error('No operationId found');
    }

    try {
      const normalizedPromise = this.#normalizePromise(this.#promise);
      const promiseResult =
        this.#isAsyncResponse && this.operationId && this.#isDryRun === false
          ? await this.#normalizedPromiseWithAsyncResponse(normalizedPromise, this.operationId)
          : await normalizedPromise;

      this.#result = GQLResult.success(promiseResult);
      return this.#result;
    } catch (error) {
      if (Array.isArray(error)) {
        this.#result = GQLResult.failure(error);
        return this.#result;
      } else {
        this.#result = GQLResult.failure([error as Error | GraphQLError]);
        return this.#result;
      }
    }
  };

  /**
   * Runs both the normalizedMutation and the asyncResponsePromise promise
   * - If normalizedMutation resolves AND asyncResponsePromise resolves = resolve with normalizedMutation result
   * - If normalizedMutation resolves AND asyncResponsePromise rejects = reject with asyncResponsePromise errors
   * - If normalizedMutation rejects = reject with normalizedMutation errors AND abort asyncResponsePromise
   * - If asyncResponsePromise rejects BEFORE normalizedMutation = reject with asyncResponsePromise errors
   * - If asyncResponsePromise resolves BEFORE normalizedMutation = wait for normalizedMutation to resolve/reject
   *
   * @param normalizedMutation - Promise wrapped in a normalized promise
   * @param operationId - The operationId of the asyncResponsePromise
   * @returns A promise that resolves with the asyncResponsePromise or rejects with a normalized errors
   */
  #normalizedPromiseWithAsyncResponse = (normalizedMutation: Promise<Awaited<P>>, operationId: string) => {
    return new Promise<Awaited<P>>((resolve, reject) => {
      const abortController = new AbortController();
      const asyncResponsePromise = this.#normalizedAsyncResponse(
        abortController.signal,
        operationId,
        this.#customAsyncResolver
      );

      let result: Awaited<P> | undefined;
      let asyncResult: boolean | undefined;

      asyncResponsePromise
        .then(() => {
          asyncResult = true;
          if (result) {
            resolve(result);
          }
        })
        .catch((e) => {
          reject(e);
        });

      normalizedMutation
        .then((r) => {
          result = r;
          if (asyncResult === true) {
            resolve(result);
          } else if (this.#failWithFeedback === false) {
            const envelope = extractGQLEnvelope(result);
            if (envelope && envelope.feedback && (envelope.feedback.length > 0 || envelope.enrichedData)) {
              abortController.abort();
              resolve(result);
            }
          }
        })
        .catch((e) => {
          abortController.abort();
          reject(e);
        });
    });
  };

  /**
   * Wrap the promise in a normalized promise that will reject with ALL errors (including feedback errors in a 200 response)
   * and resolve with the response (if successful & no feedback errors)
   *
   * @returns The promise of the response
   */
  #normalizePromise = (promise: P) => {
    return new Promise<Awaited<P>>((resolve, reject) => {
      promise
        .then((result) => {
          const errors = this.#sanitizedGraphQLErrors((result as FetchResult).errors);

          if (errors.length > 0) {
            reject(errors);
          }

          const envelope = extractGQLEnvelope(result);
          const feedback = envelope?.feedback || [];
          const feedbackErrors = feedback.filter((f) => f.level === 'Error');
          if (
            this.#failWithFeedback &&
            envelope &&
            envelope.feedback &&
            envelope.feedback.length > 0 &&
            feedbackErrors.length > 0
          ) {
            reject(envelope.feedback.map((feedback) => new EnvelopeFeedbackError(feedback)));
          }

          resolve(result as Awaited<P>);
        })
        .catch((error) => {
          reject(this.#sanitizeMutationThrownErrors(error));
        });
    });
  };

  /**
   * Handle the async response
   * and resolve with the async response or reject with a normalized errors
   *
   * @param customAsyncResolver - Custom resolver for async response
   * @returns A promise that resolves with the async response or rejects with a normalized errors
   */
  #normalizedAsyncResponse = (
    signal: AbortSignal,
    operationId: string,
    customAsyncResolver?: AwaitAsyncResponseResolver
  ) => {
    return new Promise<AsyncResponseInfoFragment | void>((resolve, reject) => {
      let subscription: Subscription | undefined = undefined;
      const abortHandler = () => {
        if (subscription) {
          subscription.unsubscribe();
        }
        resolve();
      };
      signal.addEventListener('abort', abortHandler);

      // Custom resolver
      if (customAsyncResolver) {
        const rejectWrapper = (message: string) => {
          signal.removeEventListener('abort', abortHandler);
          reject([new AsyncError(message)]);
        };
        const resolveWrapper = (...args: any[]) => {
          signal.removeEventListener('abort', abortHandler);
          resolve(...args);
        };
        customAsyncResolver(operationId, resolveWrapper, rejectWrapper).catch(this.#logger.error);
        return;
      }

      if (!this.#apolloClientRPC) throw new Error('Apollo client not found');

      let asyncResponse$ = this.#apolloClientRPC
        .subscribe<OnAsyncResponseSubscription>({
          query: OnAsyncResponseDocument
        })
        .pipe(
          filter((e) => e?.data?.asyncResponse?.operationId === operationId),
          map((e) => e.data?.asyncResponse as AsyncResponseInfoFragment)
        );

      if (this.#asyncTimeout > 0) {
        asyncResponse$ = asyncResponse$.pipe(timeout(this.#asyncTimeout));
      }

      subscription = asyncResponse$.pipe(take(1)).subscribe({
        next: (fragment) => {
          if (fragment.success) {
            signal.removeEventListener('abort', abortHandler);
            resolve(fragment);
          } else {
            const errorExtensions = fragment.error?.extensions || [];
            const errors =
              errorExtensions.length === 0
                ? [new AsyncError(fragment.error?.message || 'Async response failed')]
                : errorExtensions.map(
                    (e) =>
                      new AsyncError(fragment.error?.message || 'Async response failed', e.code || undefined)
                  );
            signal.removeEventListener('abort', abortHandler);
            reject(errors);
          }
        },
        error: (e) => {
          this.#logger.error(e);
          const timeoutError = new AsyncTimeoutError();
          signal.removeEventListener('abort', abortHandler);
          reject([timeoutError]);
        }
      });
    });
  };

  /**
   * Extract custom errors from catch block
   * @param error - The error thrown
   * @returns An array of custom errors
   */
  #sanitizeMutationThrownErrors = (error: unknown) => {
    // Handle feedback errors thrown from the mutation
    if (Array.isArray(error) && error.length > 0 && error[0] instanceof EnvelopeFeedbackError) {
      return error;
    }

    if (error instanceof ApolloError) {
      const allErrors: AllGQLResponseErrors = [];
      const graphqlErrors = this.#sanitizedGraphQLErrors(error.graphQLErrors);

      if (graphqlErrors.length > 0) {
        allErrors.push(...graphqlErrors);
      }

      if (error.networkError) {
        switch (true) {
          case 'bodyText' in error.networkError: {
            allErrors.push(
              new ServerParseError(error.networkError.message, error.networkError as ServerParseErrorType)
            );
            break;
          }
          case 'result' in error.networkError: {
            allErrors.push(
              new ServerError(error.networkError.message, error.networkError as ServerErrorType)
            );
            break;
          }
          default: {
            allErrors.push(new Error(error.networkError.message));
          }
        }
      }

      if (error.clientErrors) {
        allErrors.push(...error.clientErrors.map((clientError) => new Error(clientError.message)));
      }

      return allErrors;
    } else {
      return [error as Error | GraphQLError];
    }
  };

  /**
   * Extract custom errors from the response
   * @param graphQLErrors - The errors from the GQL response
   * @returns An array of custom errors
   * @private
   */
  #sanitizedGraphQLErrors = (graphQLErrors: GraphQLErrors | null | undefined) => {
    const errors: AllGQLResponseErrors = [];

    // Pull out custom GraphQL errors and return GQLResult.failure
    if (graphQLErrors && graphQLErrors.length > 0) {
      graphQLErrors.forEach((error) => {
        const customError = error as IAllCustomErrors;
        const handleUnknownError = () => {
          this.#logger.warn(
            `Unknown error type${error.message ? ` with message: ${error.message}` : ''}`,
            customError
          );
          this.#logger.error(customError);
          const message = error.message || this.#defaultErrorMessage;
          const code =
            typeof customError === 'object' &&
            'extensions' in customError &&
            customError.extensions &&
            typeof customError.extensions === 'object' &&
            'code' in customError.extensions &&
            typeof customError.extensions.code === 'string'
              ? customError.extensions.code
              : undefined;
          errors.push(new GeneralError(message, code));
        };
        switch (customError?.extensions?.type) {
          case ErrorTypeEnum.FIELD_VALIDATION_ERROR: {
            const extensions = customError.extensions as IFieldValidationError['extensions'];
            errors.push(new FieldValidationError(extensions.data));
            break;
          }
          case ErrorTypeEnum.VALIDATION_ERROR: {
            const extensions = customError.extensions;
            errors.push(new ValidationError(extensions.data));
            break;
          }
          case ErrorTypeEnum.GENERAL_ERROR: {
            const extensions = customError.extensions as IGeneralError['extensions'];
            errors.push(new GeneralError(extensions.data));
            break;
          }
          case ErrorTypeEnum.INTERNAL_SERVER_ERROR: {
            const extensions = customError.extensions as IInternalServerError['extensions'];
            const code = extensions.exception?.code;
            switch (code) {
              case '23505':
                errors.push(new InternalServerError(t('app.errors.duplicateKeyValue'), code));
                break;
              // Implement other code handling as needed
              default: {
                errors.push(
                  new InternalServerError(customError?.message || this.#defaultErrorMessage, code || '')
                );
                break;
              }
            }
            break;
          }
          default: {
            handleUnknownError();
          }
        }
      });
    }

    return errors;
  };
}
