import { type Maybe, type UserPreferences } from '@oms/generated/frontend';
import type { OrderSizeProfile } from './order-settings/order-settings.types';
import type { UserPreferencesOperation } from './user-preferences.types';
import {
  defaultMontageSettingsInput,
  montageSettingsFormValuesToOutput,
  montageSettingsToFormValues
} from './montage-preferences/montage-preferences.util';
import { orderSettingsToFormValues, orderSettingsToOutput } from './order-settings/order-settings.util';
import type { UserPreferencesContractFormValues } from './user-preferences.form-contract';
import type { UserPreferencesFieldContract } from './user-preferences.types';

export const sanitizeFormValuesToInput = (
  input: Maybe<UserPreferences>
): UserPreferencesContractFormValues => {
  const type: UserPreferencesOperation = input?.id ? 'update' : 'create';
  const montageSettings = input?.montagePreferences;
  const orderSettings = input?.orderSettings;
  const orderSizeProfile = orderSettings ? (orderSettings[0] as OrderSizeProfile) : undefined;

  const orderSettingsInput = orderSettingsToFormValues(orderSettings);
  const montageSettingsInput = montageSettingsToFormValues(montageSettings, orderSizeProfile);

  switch (type) {
    case 'create': {
      return {
        hiddenFormInfo: { type },

        ...defaultMontageSettingsInput
      };
    }
    case 'update': {
      return {
        hiddenFormInfo: { type },

        ...montageSettingsInput,
        ...orderSettingsInput
      };
    }
  }
};

export const sanitizeFormValuesToOutput = (
  formValues: UserPreferencesContractFormValues
): UserPreferencesFieldContract => {
  const montageSettings = montageSettingsFormValuesToOutput(formValues);
  const orderSettings = orderSettingsToOutput(formValues?.orderSettings);

  return { ...montageSettings, ...orderSettings };
};
