import { FormContract, type InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import type { ActionButtonLayoutFormOutput } from './action-button-layout.contracts';
import { actionButtonLayoutField } from './action-button-layout.form-fields';

export const actionButtonLayoutContract = FormContract.create<ActionButtonLayoutFormOutput>()
  .fields({
    layout: actionButtonLayoutField
  })
  .schema((f) => ({
    fields: [f.layout]
  }))
  .template('simple', {
    submitLabel: 'Save and close',
    showResetButton: true,
    showSubmitButton: true,
    resetLabel: 'Reset to defaults'
  });

export type ActionButtonLayoutContractType = typeof actionButtonLayoutContract;
export type ActionButtonLayoutValues = InferFormValuesFromFormContract<ActionButtonLayoutContractType>;
