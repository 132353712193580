import { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import './date-picker.scss';

import { useControllableState } from '../../hooks/use-controllable';
import { DatePickerProps } from './date-picker.types';
import { Input } from '../input/input';
import { __DEV__ } from '../../system/utils/assertion';
import { useFormControl } from '../form-control/form-control';

type OmitDatePickerProps = Omit<
  DatePickerProps,
  'onChange' | 'value' | 'selectsRange' | 'isInvalid' | 'isDisabled' | 'isReadOnly'
>;

export type DateRangePickerProps = {
  onChange?: (dates: [Date, Date]) => void;
  value?: [Date, Date];
  isInvalid?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
} & OmitDatePickerProps;

export const DateRangePicker = forwardRef<HTMLInputElement, DateRangePickerProps>((props, ref) => {
  /* 
    This hook grabs context from the FormControl component,
    We can now use this and merge it with the isDisabled that may / may not be passed directly into this comp
  */
  const { isReadOnly, isDisabled, isInvalid, isRequired } = useFormControl(props);

  // These are ALL the date picker props we are interested in
  const {
    id,
    name,
    selected,
    showMonthDropdown,
    showYearDropdown,
    dropdownMode,
    placeholderText = 'MM/DD/YYYY - MM/DD/YYYY',
    dateFormat = undefined,
    dateFormatCalendar,
    inline,
    startDate,
    endDate,
    showTimeSelect,
    timeFormat,
    timeIntervals,
    timeCaption,
    maxDate,
    minDate,
    isClearable = false,
    showPopperArrow = false,
    withPortal,
    onCalendarClose,
    /* 
      InputProps which we'll combine with FormControl props to give the input 
      the correct values and to style itself through aria attributes.
    */
    isReadOnly: _isReadOnly,
    isDisabled: _isDisabled,
    isInvalid: _isInvalid,

    // External controlling props
    onChange: _onChange,
    value: _value,
    /* 
      These are also important for DDF to run validators.
      These actually already match both InputProps and ReactDatePickerProps.
      However, because we are using our own custom Input, we pass them here.
    */
    onBlur,
    onFocus,
    /* 
      Because we are hi-jacking the onKeyPress event to format the date,
      We also need to make sure to run the same event if it gets passed down.
    */
    onKeyPress,

    // The rest are styling props
    ...inputAndBoxStylingProps
  } = props;

  /* 
    Using this handy hook to merge the incoming value/onChange with an internat setState.
    This means, if _value / _onChange is passed as props, it will use those, otherwise just an internal useState.
  */
  const [value, onChange] = useControllableState({
    value: _value,
    onChange: _onChange,
    defaultValue: null
  });

  return (
    <ReactDatePicker
      selected={value?.[0] ? value[0] : null} // ReactDatePicker using selected to control its value
      startDate={value?.[0] ? value[0] : null}
      endDate={value?.[1] ? value[1] : null}
      onChange={onChange} // And onChange to change the value/selected
      selectsRange={true}
      withPortal={withPortal}
      inline={inline}
      onCalendarClose={onCalendarClose}
      customInput={<Input ref={ref} {...inputAndBoxStylingProps} />}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      dropdownMode={dropdownMode}
      maxDate={maxDate}
      minDate={minDate}
      isClearable={isClearable}
      showPopperArrow={showPopperArrow}
      // These are InputProps which ReactDatePicker adds to the customInput
      id={id}
      name={name}
      placeholderText={placeholderText}
      autoComplete="off"
      onBlur={onBlur}
      onFocus={onFocus}
      readOnly={isReadOnly || _isReadOnly}
      disabled={isDisabled || _isDisabled}
      required={isRequired}
      ariaInvalid={isInvalid || _isInvalid ? 'true' : undefined}
      ariaRequired={isRequired ? 'true' : undefined}
      // Time picker
      showTimeSelect={showTimeSelect}
      timeFormat={timeFormat}
      timeIntervals={timeIntervals}
      timeCaption={timeCaption}
      dateFormat={dateFormat}
    />
  );
});

if (__DEV__) {
  DateRangePicker.displayName = 'DateRangePicker';
}
