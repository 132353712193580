import { Box, Flex, Spinner, type FlexProps } from '@oms/shared-frontend/ui-design-system';
import { type FC, useMemo, type PropsWithChildren } from 'react';
import type { Labeled, Valued } from '@oms/shared/util-types';
import { cleanMaybe } from '@oms/shared/util';
import type { MontageDocType } from '@app/data-access/offline/collections/montage/montage.collection.types';
import type { ActorScoped } from '@app/data-access/offline/types';
import type { ParsedIdentityToken } from '@app/common/auth/keycloak.types';
import { useMontageInstrumentDetails } from '@app/data-access/services/trading/montage/hooks/use-montage-instrument-details.hook';
import { useMontageInvestorAccountDetails } from '@app/data-access/services/trading/montage/hooks/use-montage-investor-account-details.hook';

const NONE = 'None';

const Label: FC<Labeled> = ({ label }) => <Flex sx={{ fontWeight: 'baseR' }}>{`${label}: `}</Flex>;

const Value: FC<Valued & FlexProps['sx']> = ({ value, ...sx }) => (
  <Flex sx={{ fontWeight: 'largeB', ...sx }}>{value}</Flex>
);

const Block: FC<Labeled & Partial<Valued> & FlexProps['sx'] & { isLoading?: boolean }> = ({
  label,
  value,
  isLoading,
  ...sx
}) => (
  <Flex direction="column" justify="space-around" sx={{ margin: 'small' }}>
    <Label label={label} />
    {isLoading ? <Spinner /> : <Value value={value || NONE} {...sx} />}
  </Flex>
);

const Wrapper: FC<PropsWithChildren<FlexProps>> = ({ children, ...props }) => (
  <Flex direction="row" justify="space-around" style={{ width: '100%' }} {...props}>
    {children}
  </Flex>
);

const DevOnlyWrapper: FC<PropsWithChildren<FlexProps>> = ({ children, ...props }) => (
  <Box sx={{ backgroundColor: 'layout.level3', padding: 'medium', margin: 'small', borderRadius: 'md' }}>
    <Box sx={{ fontSize: 'mediumR', textAlign: 'center', color: 'BlueGrey.500' }}>
      {'[For dev purposes only]'}
    </Box>
    <Wrapper {...props}>{children}</Wrapper>
  </Box>
);

type TempMontageFilterStateDisplayProps = Omit<MontageDocType, keyof ActorScoped> & {
  user?: ParsedIdentityToken;
  direction?: FlexProps['direction'];
};

/** Temporary for debugging */
export const TempMontageFilterStateDisplay: FC<TempMontageFilterStateDisplayProps> = ({
  instrumentTrackingEnabled,
  user,
  direction
}) => {
  const [trackingStatus, trackingColor] = useMemo(() => {
    const statusText = instrumentTrackingEnabled ? 'Enabled' : 'Disabled';
    const color = instrumentTrackingEnabled ? 'Yellow.500' : 'BlueGrey.500';
    return [statusText, color] as [typeof statusText, typeof color];
  }, [instrumentTrackingEnabled]);

  const instrumentDetails = useMontageInstrumentDetails();
  const investorAccountDetails = useMontageInvestorAccountDetails();

  const [instrumentText, instrumentColor] = useMemo(() => {
    const statusText = (() => {
      if (!instrumentDetails) return NONE;
      const name = cleanMaybe(instrumentDetails.longName);
      const displayCode = cleanMaybe(instrumentDetails.mappings.displayCode, '[NOCODE]');
      return `${displayCode}${name ? ` (${name})` : ''}`;
    })();
    const color = instrumentDetails ? 'BrandBlue.500' : 'BlueGrey.500';
    return [statusText, color] as [typeof statusText, typeof color];
  }, [instrumentDetails]);

  const [accountText, accountColor] = useMemo(() => {
    const statusText = (() => {
      if (!investorAccountDetails) return NONE;
      return cleanMaybe(investorAccountDetails?.name, '[NO NAME]');
    })();
    const color = investorAccountDetails ? 'Green.500' : 'BlueGrey.500';
    return [statusText, color] as [typeof statusText, typeof color];
  }, [investorAccountDetails]);

  return (
    <DevOnlyWrapper direction={direction}>
      <Block label="Instrument tracking" value={trackingStatus} color={trackingColor} />
      <Block label="Instrument" value={instrumentText} color={instrumentColor} />
      <Block label="Investor account" value={accountText} color={accountColor} />
      <Block label="User" value={user?.name} color="BrandBlue.500" />
    </DevOnlyWrapper>
  );
};

export default TempMontageFilterStateDisplay;
