import { inject, singleton } from 'tsyringe';
import {
  type AuditTrailInfoFragment,
  GetAuditStreamForTradingOrderDocument,
  type GetAuditStreamForTradingOrderQuery,
  type GetAuditStreamForTradingOrderQueryVariables
} from '@oms/generated/frontend';
import { map } from 'rxjs';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';

export type TradingOrderAuditTrail = {
  id: string;
  eventTimestamp: string;
  event: string;
  description: string;
};

@singleton()
export class TradingOrderAuditTrailService {
  constructor(@inject(ApolloClientRPC) private apolloClient: ApolloClientRPC) {}

  public getAuditTrail$(tradingOrderId: string, pollInterval = 5000) {
    return this.apolloClient
      .watchQuery<GetAuditStreamForTradingOrderQuery, GetAuditStreamForTradingOrderQueryVariables>({
        query: GetAuditStreamForTradingOrderDocument,
        pollInterval,
        fetchPolicy: 'no-cache',
        variables: {
          id: tradingOrderId
        }
      })
      .pipe(
        map((res) => {
          const results =
            (res.data.getAuditStreamForTradingOrder as AuditTrailInfoFragment[]) ||
            ([] as AuditTrailInfoFragment[]);

          const sanitized = results.map(
            (r): TradingOrderAuditTrail => ({
              id: `${r.aggregateId}-${r.aggregateVersion}`,
              eventTimestamp: r.timestamp,
              event: r.aggregateType,
              description: JSON.stringify(r.payload)
            })
          );

          return sanitized;
        })
      );
  }
}
