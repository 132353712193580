import { parsePhoneNumber, CountryCode, NumberFormat, FormatNumberOptions } from 'libphonenumber-js';
import { FormatterFunction } from './types';

type Options = FormatNumberOptions & {
  defaultCountry?: CountryCode;
};

export const makePhoneNumberFormatter =
  (format: NumberFormat, options?: Options): FormatterFunction =>
  ({ value }) => {
    const { defaultCountry = 'US', ...rest } = options ?? {};
    const input = typeof value === 'string' ? value : value.toString();
    if (input.length < 5) return input;
    try {
      const phoneNumber = parsePhoneNumber(input, defaultCountry);
      if (!phoneNumber.isValid()) return input;
      return phoneNumber.format(format, { ...rest });
    } catch (e) {
      return input;
    }
  };

export default makePhoneNumberFormatter;
