import { Actor, type CommonWindowActorSchema, type MonitorInfo, type PlatformInfo } from '@valstro/workspace';
import { type AppWorkspace } from '@app/app-config/workspace.config';
import { IS_TAURI } from '@app/common/workspace/workspace.constants';

export function getCustomBrowserInfo(): PlatformInfo {
  return {
    name: 'browser',
    version: navigator.userAgent,
    platform: navigator.platform,
    locale: navigator.languages ? navigator.languages[0] : navigator.language,
    monitors: [
      // Only use one monitor for browser
      {
        name: null,
        width: window.innerWidth, // use innerWidth to get the browser window width vs screen.width which is the monitor width
        height: window.innerHeight, // use innerHeight to get the browser window height vs screen.height which is the monitor height
        x: 0,
        y: 0,
        scaleFactor: 1
      }
    ]
  };
}

export async function getMonitorsInfo(workspace: AppWorkspace): Promise<MonitorInfo[]> {
  const platform = IS_TAURI ? await workspace.getPlatformInfo() : getCustomBrowserInfo();
  return platform?.monitors || [];
}

export interface WindowInfo {
  x: number;
  y: number;
  width: number;
  height: number;
}

export async function getWindowInfo(windowActor: Actor<CommonWindowActorSchema>): Promise<WindowInfo | null> {
  const definition = await windowActor.getDefinition();
  const { x, y, width, height } = definition.context;

  if (x === undefined || y === undefined || width === undefined || height === undefined) {
    return null;
  }

  return {
    x,
    y,
    width,
    height
  };
}

export function convertMonitorToWindowInfo(monitor: MonitorInfo): WindowInfo {
  return {
    x: monitor.x,
    y: monitor.y,
    width: monitor.width,
    height: monitor.height
  };
}

export function calculateIntersectionArea(rect1: WindowInfo, rect2: WindowInfo) {
  const x_overlap = Math.max(
    0,
    Math.min(rect1.x + rect1.width, rect2.x + rect2.width) - Math.max(rect1.x, rect2.x)
  );

  const y_overlap = Math.max(
    0,
    Math.min(rect1.y + rect1.height, rect2.y + rect2.height) - Math.max(rect1.y, rect2.y)
  );

  return x_overlap * y_overlap;
}

export interface IsWindowOffScreenResult {
  isOffScreen: boolean;
  monitors: MonitorInfo[];
  windowInfo: WindowInfo;
}

export async function isWindowOffScreen(
  monitors: MonitorInfo[],
  windowInfo: WindowInfo,
  threshold: number = 0.5
): Promise<IsWindowOffScreenResult> {
  for (const monitor of monitors) {
    const intersectionArea = calculateIntersectionArea(windowInfo, convertMonitorToWindowInfo(monitor));
    const windowArea = windowInfo.width * windowInfo.height;
    const amount = intersectionArea / windowArea;

    if (amount >= threshold) {
      return {
        isOffScreen: false,
        monitors,
        windowInfo
      };
    }
  }

  return {
    isOffScreen: true,
    monitors,
    windowInfo
  };
}

export async function findNearestMonitor(monitors: MonitorInfo[], windowInfo: WindowInfo) {
  let maxIntersection = 0;
  let nearestMonitor = convertMonitorToWindowInfo(monitors[0]);

  for (const monitor of monitors) {
    const intersectionArea = calculateIntersectionArea(windowInfo, convertMonitorToWindowInfo(monitor));
    if (intersectionArea > maxIntersection) {
      maxIntersection = intersectionArea;
      nearestMonitor = convertMonitorToWindowInfo(monitor);
    }
  }

  return nearestMonitor;
}

export async function moveToNearestMonitor(
  windowActor: Actor<CommonWindowActorSchema>,
  nearestMonitor: WindowInfo,
  windowInfo: WindowInfo
) {
  let newX = windowInfo.x;
  let newY = windowInfo.y;

  if (windowInfo.x < nearestMonitor.x) {
    newX = nearestMonitor.x;
  } else if (windowInfo.x + windowInfo.width > nearestMonitor.x + nearestMonitor.width) {
    newX = nearestMonitor.x + nearestMonitor.width - windowInfo.width;
  }

  if (windowInfo.y < nearestMonitor.y) {
    newY = nearestMonitor.y;
  } else if (windowInfo.y + windowInfo.height > nearestMonitor.y + nearestMonitor.height) {
    newY = nearestMonitor.y + nearestMonitor.height - windowInfo.height;
  }

  await windowActor.operations.setPosition({
    x: newX,
    y: newY
  });
}

export async function ensureWindowFitsMonitor(
  windowActor: Actor<CommonWindowActorSchema>,
  windowInfo: WindowInfo,
  monitor: WindowInfo
) {
  let doesFit = true;
  let width = windowInfo.width;
  let height = windowInfo.height;

  if (windowInfo.width > monitor.width) {
    width = monitor.width;
    doesFit = false;
  }

  if (windowInfo.height > monitor.height) {
    height = monitor.height;
    doesFit = false;
  }

  if (doesFit) {
    return;
  }

  await windowActor.operations.setSize({
    width,
    height
  });
}

export async function checkAndMoveWindow(
  workspace: AppWorkspace,
  windowActor: Actor<CommonWindowActorSchema>
) {
  const [monitors, windowInfo] = await Promise.all([getMonitorsInfo(workspace), getWindowInfo(windowActor)]);

  if (!windowInfo) {
    console.warn('Cannot checkAndMoveWindow because windowInfo is null');
    return;
  }

  const nearestMonitor = await findNearestMonitor(monitors, windowInfo);

  await ensureWindowFitsMonitor(windowActor, windowInfo, nearestMonitor);

  const { isOffScreen } = await isWindowOffScreen(monitors, windowInfo);

  if (isOffScreen) {
    await moveToNearestMonitor(windowActor, nearestMonitor, windowInfo);
  }
}

export async function checkAndMoveWindowById(workspace: AppWorkspace, windowActorId: string) {
  const windowActor = await Actor.get<CommonWindowActorSchema>(windowActorId);
  return await checkAndMoveWindow(workspace, windowActor);
}
