import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  AlertBannerStack,
  type AlertBannerStackProps,
  type AlertBannerStackItem
} from '@oms/shared-frontend/ui-design-system';
import { useFormBuilderTemplate } from '../../templates/common/form-builder-template.context';
import {
  FORM_RENDERER_EVENT_TYPE,
  formRendererEvent$,
  type FormRendererEventSetFeedback
} from '../../../form-builder.events.renderer';
import { filter, map } from 'rxjs';
import { alertBannerConfig } from './constants';
import convertAlert from './util';
import useResizeObserver from '@react-hook/resize-observer';
import { useCurrentWindow } from '@valstro/workspace-react';
import type { FeedbackWrapper } from '../../../../../graphql/graphql-envelope';

export type FormBuilderFeedbackProps = {
  initialFeedback?: FeedbackWrapper[];
  sx?: AlertBannerStackProps['sx'];
  onFeedbackSizeChange?: (entry: ResizeObserverEntry) => void;
  /**
   * Window will grow to encompass the feedback
   * Defaults to `true`
   */
  shouldGrowWindow?: boolean;
};

export function FormBuilderFeedback({
  sx,
  onFeedbackSizeChange: _onFeedbackSizeChange,
  shouldGrowWindow = true,
  initialFeedback = []
}: FormBuilderFeedbackProps) {
  const prevWindowSize = useRef({ width: 0, height: 0 });
  const previousHeight = useRef(0);
  const [alerts, setAlerts] = useState<Array<AlertBannerStackItem>>(
    initialFeedback.map((item) => convertAlert.formValidationAlertItem.item(item).toAlertBannerStackItem())
  );
  const { formId } = useFormBuilderTemplate();
  const window = useCurrentWindow();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window
      .context()
      .then((ctx) => {
        prevWindowSize.current = {
          width: ctx.width || 0,
          height: ctx.height || 0
        };
      })
      .catch((err) => console.error(err));

    const unlisten = window.listen('context', (ctx) => {
      prevWindowSize.current = {
        ...prevWindowSize.current,
        width: ctx.width || 0,
        height: ctx.height || 0
      };
    });

    return () => {
      unlisten();
    };
  }, [window]);

  const onFeedbackSizeChange = useCallback(
    (entry: ResizeObserverEntry) => {
      _onFeedbackSizeChange?.(entry);
      if (!shouldGrowWindow) return;

      if (prevWindowSize.current.height === 0 || prevWindowSize.current.width === 0) {
        return;
      }

      const newHeight = entry.contentRect.height;
      const delta = newHeight - previousHeight.current;
      const nextHeight = prevWindowSize.current.height + delta;

      if (delta !== 0) {
        window.operations
          .setSize({ height: nextHeight, width: prevWindowSize.current.width })
          .then(() => {
            previousHeight.current = newHeight;
          })
          .catch(console.error);
      }
    },
    [_onFeedbackSizeChange, shouldGrowWindow, window.operations]
  );

  useResizeObserver(ref, onFeedbackSizeChange);

  useLayoutEffect(() => {
    formRendererEvent$
      .pipe(
        filter(
          (event) => event.meta.formId === formId && event.type === FORM_RENDERER_EVENT_TYPE.SET_FEEDBACK
        ),
        map((event) => event as FormRendererEventSetFeedback)
      )
      .subscribe(({ payload }) => {
        const { feedback } = payload;
        const alerts = feedback.map((item) =>
          convertAlert.formValidationAlertItem.item(item).toAlertBannerStackItem()
        );
        setAlerts(alerts);
      });
  }, [formId]);

  return (
    <div ref={ref}>
      {alerts.length > 0 && <AlertBannerStack sx={sx} config={alertBannerConfig} alerts={alerts} />}
    </div>
  );
}
