import { SplitButton, type SplitButtonActions } from '@oms/shared-frontend/ui-design-system';
import { type FC, useMemo } from 'react';
import { useActionProps } from '../../../hooks/action.events.hook';
import { omit } from 'lodash';
import { ActionComponentConfig, ActionComponentProps } from '../../../models/actions.model';
import type { AnyRecord } from '@oms/frontend-foundation';

export type SplitButtonActionConfig = Omit<SplitButtonActions[number], 'action'> & {
  id: string;
};

export interface SplitButtonActionComponentConfig<TData extends AnyRecord>
  extends ActionComponentConfig<TData> {
  selectedId: string;
}

export interface SplitButtonActionProps<TData extends AnyRecord> {
  actionsConfig?: SplitButtonActionConfig[];
  // Used to indicate when the action config is for inline action in a row, and
  // passing the initial row data so that it will be available for the init
  // lifecycle event
  rowData?: TData;
}

export const SplitButtonAction: FC<SplitButtonActionProps<any> & ActionComponentProps<any>> = (props) => {
  const propChanges = useActionProps<any, SplitButtonActionProps<any> & ActionComponentProps<any>>(props);
  const { actionsConfig = [], onChange, id: actionId, isVisible = true } = propChanges;

  const actions: SplitButtonActions = useMemo(
    () =>
      actionsConfig.map(({ title, id }) => ({
        title,
        id,
        action: () => {
          const c = onChange as (args: any) => void; // TODO: Fix these types
          return c({
            actionId,
            state: { ...omit(propChanges, 'rowData'), selectedId: id },
            // Most up to date row data to be passed to onChange
            data: [propChanges.rowData].filter(Boolean).map((d) => d as AnyRecord),
            lifecycle: 'change'
          });
        }
      })),
    [actionId, actionsConfig, onChange, propChanges]
  );

  if (isVisible === false) {
    return null;
  }

  return (
    <SplitButton
      {...(omit(propChanges, 'onChange', 'content', 'actionType', 'isVisible', 'instanceId') as any)}
      actions={actions}
      sx={{ flexGrow: propChanges.rowData ? 1 : 0 }}
      isFullWidth={!!propChanges.rowData}
    />
  );
};
