import { useEffect, useState } from 'react';
import { useMontageState } from '@app/data-access/offline/collections/montage/hooks/use-montage-state.hook';
import { type VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';

export const useEligibleOrders = () => {
  const { instrumentId } = useMontageState();

  const [selectedOrders, setSelectedOrders] = useState<
    Array<VisibleInvestorOrderInfoWithAllocationsFragment>
  >([]);

  useEffect(() => {
    setSelectedOrders([]);
  }, [instrumentId]);

  return { selectedOrders, setSelectedOrders, instrumentId };
};
