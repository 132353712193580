import {
  FormContract,
  FieldDefinition,
  type InferFormValuesFromFormContract
} from '@oms/frontend-foundation';
import type { PrincipalFillFormOutput } from './principal-fill.contracts';
import {
  firmAccount,
  instrument,
  capacity,
  quantity,
  price,
  investorOrders,
  tradeDateTime,
  locate,
  tradeTagIds,
  tradeThrough
} from './principal-fill.form-fields';
import {
  createAdditionalAllFields,
  createEnrichedField
} from '@app/forms/form-builder/fields/additional-fields/additional-fields.common';
import { createMarketDataField } from '@app/forms/form-builder/fields/market-data-field/market-data-field.common';

export const principalFillContract = FormContract.create<PrincipalFillFormOutput>()
  .fields({
    firmAccount,
    instrument,
    capacity,
    quantity,
    price,
    investorOrders,
    tradeDateTime,
    locate,
    tradeTagIds,
    tradeThrough
  })
  .schema((f) => ({
    fields: [
      createMarketDataField('market-data', {
        instrumentFieldName: 'instrument',
        trackableFieldMap: {
          bid: 'price',
          ask: 'price'
        }
      }),
      FieldDefinition.runway('runway', [
        { ...f.firmAccount, isPrimaryField: true },
        { ...f.instrument, isPrimaryField: true, isDisabled: true },
        { ...f.capacity, isPrimaryField: true, style: { minWidth: 120 } },
        { ...f.quantity, isPrimaryField: true },
        { ...f.price, isPrimaryField: true }
      ]),
      {
        name: 'investor-orders-section',
        component: 'section',
        fields: [[f.investorOrders]],
        style: { minHeight: '120px' },
        sx: {
          backgroundColor: 'layout.level1',
          marginTop: 1,
          padding: 0
        }
      },
      {
        name: 'trade-through-section',
        component: 'section',
        fields: [[f.tradeThrough]],
        sx: {
          backgroundColor: 'layout.level1',
          marginTop: 1,
        }
      },
      createAdditionalAllFields({
        fixedFields: ['tradeDateTime', 'locate', 'tradeTagIds'],
        columns: 2,
        fields: [
          [createEnrichedField(f.tradeDateTime, { colSpan: 1 })],
          [createEnrichedField(f.locate, { colSpan: 1 })],
          [createEnrichedField(f.tradeTagIds, { colSpan: 2 })]
        ]
      })
    ]
  }))
  .template('simple', { showSubmitButton: false });

export const principalFillFormContractDefinition = principalFillContract.build();

export type PrincipalFillContractType = typeof principalFillContract;

export type PrincipalFillFormValues = InferFormValuesFromFormContract<PrincipalFillContractType>;
