import React, { type FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { from, of, type Observable, map, catchError } from 'rxjs';
import { useEnhancedFormApi, useService, type FieldProps, type ICommonField } from '@oms/frontend-foundation';
import { LabelWithAvatar } from '@oms/shared-frontend/ui-design-system';
import { cleanMaybe } from '@oms/shared/util';
import { type Optional } from '@oms/shared/util-types';
import AccountsService from '@app/data-access/services/reference-data/accounts/accounts.service';
import { type FORM_COMPONENT_TYPE } from '../../common/form.contracts';

export type ICoverageUserFieldOptions = {
  accountFieldName: string;
  layoutFieldName?: string;
};

export type ICoverageUserField = ICommonField<typeof FORM_COMPONENT_TYPE.COVERAGE_USER_FIELD> &
  ICoverageUserFieldOptions;

export type CoverageUser = {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  username?: string | null;
  avatar?: string | null;
};

const fetchCoverageUser = (
  accountsService: AccountsService,
  accountId: Optional<string>
): Observable<Optional<CoverageUser>> => {
  if (!accountId) return of(undefined);
  return from(accountsService.getInvestorAccount(accountId)).pipe(
    map((accountData) => cleanMaybe(accountData?.defaultCoverageUser)),
    catchError((error) => {
      console.error('Error fetching coverage user:', error);
      return of(undefined);
    })
  );
};

const getUserLabel = (user: CoverageUser): string => {
  if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user?.username) {
    return user.username;
  } else {
    return '';
  }
};

const CoverageUserFieldComponent: FC<FieldProps<ICoverageUserField>> = ({
  accountFieldName,
  layoutFieldName
}) => {
  const formApi = useEnhancedFormApi();
  const accountsService = useService(AccountsService);
  const [coverageUser, setCoverageUser] = useState<Optional<CoverageUser>>(undefined);

  const accountId: Optional<string> = formApi.getFieldState(accountFieldName)?.value?.id;

  const isExpandedLayout: boolean = layoutFieldName
    ? formApi.getFieldState(layoutFieldName)?.value === 'expanded'
    : false;

  useEffect(() => {
    const subscription = fetchCoverageUser(accountsService, accountId).subscribe(setCoverageUser);

    return () => subscription.unsubscribe();
  }, [accountId, accountsService]);

  return coverageUser ? (
    <LabelWithAvatar
      label={getUserLabel(coverageUser)}
      src={cleanMaybe(coverageUser?.avatar)}
      isLabelVisible={isExpandedLayout}
    />
  ) : null;
};

export const CoverageUserField = React.memo(CoverageUserFieldComponent, isEqual);
