import { GridApi } from '@ag-grid-community/core';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { EventHandler } from './event.handler';
import { EventSource, GridEventType } from './event.source';
import { Observable, Subscription } from 'rxjs';
import { Datasource } from '../models/datasource.model';
import { VGridInstance } from '../models/v.instance.model';
//
@scoped(Lifecycle.ContainerScoped)
export class DatasourceEventHandler implements EventHandler<any> {
  private datasource: Datasource<any>;

  public name = 'datasource-event-handler';
  private gridApi: GridApi | undefined;
  private subscription?: Subscription;

  constructor(@inject(VGridInstance.Datasource) datasource: Datasource<any>) {
    this.datasource = datasource;
  }

  public addEvents(eventSource: EventSource<GridEventType, any>) {
    eventSource
      .add('onGridReady', (e) => {
        this.gridApi = e.api;

        if (this.gridApi.isDestroyed()) return;

        if (this.datasource.data$ && Array.isArray(this.datasource.data$)) {
          this.gridApi?.updateGridOptions({ rowData: this.datasource.data$ });
        }

        if (this.datasource.rowId && this.datasource.data$ && this.datasource.data$ instanceof Observable) {
          this.subscription = this.datasource.data$.subscribe((trans) => {
            if (this.gridApi?.isDestroyed()) return;
            this.gridApi?.updateGridOptions({ rowData: trans });
          });
        } else if (this.datasource.data$ && 'getRows' in this.datasource.data$) {
          this.gridApi?.setGridOption('serverSideDatasource', this.datasource.data$);
        }
      })
      .add('onGridPreDestroyed', (_e) => {
        this.subscription?.unsubscribe();
      });
  }

  public removeEvents() {
    if (this.gridApi) {
      this.gridApi.dispatchEvent({ type: 'destroy' });
    }
  }
}
