import { sharedDefaultCol } from '@app/common/grids/columns/generic-cols';
import { type ColumnBuilderCallback, type ColumnLibrary } from '@oms/frontend-vgrid';
import { type TradingOrderAuditTrail } from '@app/data-access/services/trading/trading-orders/trading-order-audit-trail.service';
import { sharedDateTimeCol, sharedTextCol } from '@app/common/grids/columns/generic-cols';

const defaultAuditTrailCol: ColumnBuilderCallback<TradingOrderAuditTrail> = (c) =>
  sharedDefaultCol<TradingOrderAuditTrail>(c).minWidth(180);

const eventTimestampCol: ColumnBuilderCallback<TradingOrderAuditTrail> = (c) =>
  sharedDateTimeCol(c, 'eventTimestamp').header('Timestamp').sort('desc').minWidth(200);

const eventCol: ColumnBuilderCallback<TradingOrderAuditTrail> = (c) =>
  sharedTextCol(c, 'event').header('Event').width(200);

const descriptionCol: ColumnBuilderCallback<TradingOrderAuditTrail> = (c) =>
  sharedTextCol(c, 'description').header('Description').width(500);

export const auditTrailColumnLibrary: ColumnLibrary<TradingOrderAuditTrail> = {
  defaultColumn: defaultAuditTrailCol,
  columns: [eventTimestampCol, eventCol, descriptionCol]
};
