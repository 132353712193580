import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import { sharedDateTimeCol, sharedDefaultCol, sharedIdCol } from '@app/common/grids/columns/generic-cols';
import { sharedNameCol, sharedActiveCol } from '@app/common/grids/columns/reference-data-cols';
import type { SnapshotComputedDocType } from '@app/data-access/offline/collections/snapshots.collection';

const defaultCol: ColumnBuilderCallback<SnapshotComputedDocType> = (c) =>
  sharedDefaultCol<SnapshotComputedDocType>(c);

const idCol: ColumnBuilderCallback<SnapshotComputedDocType> = (c) =>
  sharedIdCol<SnapshotComputedDocType>(c, 'id').header('ID').hide();

const nameCol: ColumnBuilderCallback<SnapshotComputedDocType> = (c) =>
  sharedNameCol<SnapshotComputedDocType>(c, 'name');

const createdAtCol: ColumnBuilderCallback<SnapshotComputedDocType> = (c) =>
  sharedDateTimeCol<SnapshotComputedDocType>(c, 'createdAt').header('Created At');

const isActiveCol: ColumnBuilderCallback<SnapshotComputedDocType> = (c) =>
  sharedActiveCol<SnapshotComputedDocType>(c, 'isActive');

export const commonSnapshotGridColumnLibrary: ColumnLibrary<SnapshotComputedDocType> = {
  defaultColumn: defaultCol,
  columns: [idCol, nameCol, createdAtCol, isActiveCol]
};
