declare const __PROCESS_ENV__: string | Record<string, string> | null | undefined;
declare const __IS_VITE__: boolean | undefined;

export const isVite = !!__IS_VITE__;
let parsedCache: Record<string, string> | NodeJS.ProcessEnv | undefined;

export const getEnvVar = (key: string, defaultValue = ''): string => {
  let envs = parsedCache || __PROCESS_ENV__ || process.env;

  if (typeof envs === 'string') {
    envs = JSON.parse(envs) as Record<string, string>;
    parsedCache = envs;
  }

  const envVar = envs?.[key];

  if (!envVar) {
    return defaultValue;
  }

  return `${envVar}`;
};
