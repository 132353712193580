import {
  APP_DISPLAY_FIELD_COMPONENT_TYPE,
  AppDisplayGrid,
  type AppDisplayGridProps
} from '@oms/frontend-foundation';
import { useMemo } from 'react';
import { Box, type Sprinkles, Text } from '@oms/shared-frontend/ui-design-system';
import type { VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import {
  type UpdatedInvestorOrder,
  withUpdatedInvestorOrder
} from '@app/data-access/hoc/with-updated-investor-order';
import { paneStyle } from './investor-order-view.style';

const getDisplayGridItems = (
  values: VisibleInvestorOrderInfoWithAllocationsFragment
): AppDisplayGridProps['items'] => {
  return [
    {
      label: 'Side',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Badge,
        value: values.sideType || []
      }
    },
    {
      label: 'Instrument',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: values?.instrument?.mappings.displayCode || ''
      }
    },
    {
      label: 'Account',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: values?.investorAccount?.name || ''
      }
    },
    {
      label: 'Quantity',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: values.quantity || 0,
        format: 'quantity'
      }
    },
    {
      label: 'Price',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Badge,
        value: values.orderType || []
      }
    }
  ];
};

const gridProps: AppDisplayGridProps['gridProps'] = {
  columns: 3,
  rows: 2,
  columnGap: 10,
  rowGap: 3
};

const sx: Sprinkles = { ...paneStyle, padding: 5 };

export const InvestorOrderViewOrderInformationWidget = ({ data }: UpdatedInvestorOrder) => {
  const displayGridItems = useMemo(() => (data ? getDisplayGridItems(data) : []), [data]);
  return (
    <Box sx={{ ...sx, padding: 5 }}>
      <Text sx={{ color: 'white', marginBottom: 3, display: 'block' }}>Order Information</Text>
      <AppDisplayGrid items={displayGridItems} labelSize="small" gridProps={gridProps} />
    </Box>
  );
};

export default withUpdatedInvestorOrder(InvestorOrderViewOrderInformationWidget, { sx });
