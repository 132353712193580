import type { ZodObject, ZodRawShape, infer as ZodInfer } from 'zod';
import type { WindowContext } from '@valstro/workspace';
import type { IJsonModel } from 'flexlayout-react';
import type { ValstroEntitlement } from '@app/common/auth/keycloak.types';
import type {
  AnyRecord,
  FeedbackWrapper,
  FormContractDefinition,
  FormSaveType
} from '@oms/frontend-foundation';

/**
 * Common object category
 */
export const COMMON_OBJECT_CATEGORY = {
  TRADING: 'TRADING',
  REFERENCE_DATA: 'REFERENCE_DATA',
  MARKET_DATA: 'MARKET_DATA',
  EXAMPLE: 'EXAMPLE',
  SYSTEM: 'SYSTEM'
} as const;

export type CommonObjectCategory = keyof typeof COMMON_OBJECT_CATEGORY;

/**
 * Dictionary definitions & utils
 */
export type AnyZodObject = ZodObject<ZodRawShape>;
export type AnyShape = ZodRawShape;
export interface RegistryDefinitionCommon {
  key: string; // These are strings because codegen can accept new values and add it to the enum in the generated code
  title: string;
  /**
   * If true, the widget will can only be opened once (non-fungible)
   * It will use the key as the windowId
   * @default false
   */
  isUnique?: boolean;
  /**
   * If set in the config file, the widget will always be unique
   * If set adhoc when opening the widget, it will open a new widget if another one is NOT already open
   */
  windowId?: string;
  windowOptions?: Partial<WindowContext>;
  openTabInBrowser?: boolean;
  requiredRoles?: ValstroEntitlement[];
  exludeFromDictionary?: boolean;
}

export interface RegistryDefinitionCommonWithObjects extends RegistryDefinitionCommon {
  showInCommandPalette?: boolean;
  objectCategory?: string; // These are strings because codegen can accept new values and add it to the enum in the generated code
}

export interface RegistryDefinitionComponent<TPropsSchema extends AnyZodObject = AnyZodObject>
  extends RegistryDefinitionCommonWithObjects {
  type: 'component';
  schema?: TPropsSchema;
  excludePropsFromSnapshot?: Array<keyof ZodInfer<TPropsSchema>>;
  componentProps?: Partial<ZodInfer<TPropsSchema>>;
}

export type RegistryDefinitionComponentWithCompKey<TProps> = Omit<
  RegistryDefinitionComponent,
  'componentProps'
> & {
  componentKey: string;
  componentProps?: TProps;
};

export interface RegistryDefinitionLayout<TPropsSchema extends AnyZodObject = AnyZodObject>
  extends RegistryDefinitionCommonWithObjects {
  type: 'layout';
  flexLayoutActorId?: string;
  jsonModel: IJsonModel;
  schema?: TPropsSchema;
  excludePropsFromSnapshot?: Array<keyof ZodInfer<TPropsSchema>>;
  componentProps?: Partial<ZodInfer<TPropsSchema>>;
}

export type RegistryDefinitionLayoutWithComp<TProps> = Omit<RegistryDefinitionLayout, 'componentProps'> & {
  componentProps?: TProps;
};

export interface RegistryDefinitionDialog<TPropsSchema extends AnyZodObject = AnyZodObject>
  extends Omit<RegistryDefinitionCommon, 'windowId'> {
  type: 'dialog';
  schema: TPropsSchema;
  componentProps?: ZodInfer<TPropsSchema>;
}

export type RegistryDefinitionDialogWithCompKey<TProps> = Omit<
  RegistryDefinitionDialog,
  'componentProps' | 'schema'
> & {
  componentKey: string;
  componentProps?: TProps;
};

export interface RegistryDefinitionForm<TInputContractSchema extends AnyZodObject = AnyZodObject>
  extends RegistryDefinitionCommonWithObjects {
  type: 'form';
  schema: TInputContractSchema;
  excludePropsFromSnapshot?: Array<keyof ZodInfer<TInputContractSchema>>;
  form?: {
    input: Partial<ZodInfer<TInputContractSchema>>;
    formSaveType?: FormSaveType;
  };
}

export type RegistryDefinitionFormWithFormKey<TInputProps> = Omit<
  RegistryDefinitionForm,
  'form' | 'schema'
> & {
  formKey: string;
  form?: Partial<Omit<FormContractDefinition<AnyRecord>, 'fieldDefinitions'>> & {
    formId?: string;
    input?: Partial<TInputProps>;
    formType?: string;
    formSaveType?: FormSaveType;
    initialFeedback?: FeedbackWrapper[];
    triggerValidationOnOpen?: boolean;
  };
};

export interface RegistryDefinitionAdvancedSelectQuery {
  type: 'advanced_select_query';
  key: string; // These are strings because codegen can accept new values and add it to the enum in the generated code
}

export type RegistryExcludePropsFromSnapshotMapper = Record<string, string[]>;

export type RegistryDefinition<TPropsSchema extends AnyZodObject = AnyZodObject> =
  | RegistryDefinitionComponent<TPropsSchema>
  | RegistryDefinitionLayout<TPropsSchema>
  | RegistryDefinitionDialog<TPropsSchema>
  | RegistryDefinitionForm<TPropsSchema>
  | RegistryDefinitionAdvancedSelectQuery;

export type InferRegistryFormSchema<T extends RegistryDefinitionForm<any>> = ZodInfer<T['schema']>;
export type InferRegistryComponentProps<T extends RegistryDefinitionComponent<any>> = ZodInfer<
  Required<T>['schema']
>;
export type InferRegistryDialogProps<T extends RegistryDefinitionDialog<any>> = ZodInfer<T['schema']>;

export type OpenableRegistryDefinition<TProps> =
  | RegistryDefinitionDialogWithCompKey<TProps>
  | RegistryDefinitionFormWithFormKey<TProps>
  | RegistryDefinitionComponentWithCompKey<TProps>
  | RegistryDefinitionLayoutWithComp<TProps>;

export const registerComponent = <TPropsSchema extends AnyZodObject>(
  definition: Omit<RegistryDefinitionComponent<TPropsSchema>, 'type'>
): RegistryDefinitionComponent<TPropsSchema> => {
  return { ...definition, type: 'component' };
};

export const registerLayout = <TPropsSchema extends AnyZodObject>(
  definition: Omit<RegistryDefinitionLayout<TPropsSchema>, 'type'>
): RegistryDefinitionLayout<TPropsSchema> => {
  return { ...definition, type: 'layout' };
};

export const registerDialog = <TPropsSchema extends AnyZodObject>(
  definition: Omit<RegistryDefinitionDialog<TPropsSchema>, 'type'>
): RegistryDefinitionDialog<TPropsSchema> => {
  return { ...definition, type: 'dialog' };
};

export const registerForm = <TInputContractSchema extends AnyZodObject>(
  definition: Omit<RegistryDefinitionForm<TInputContractSchema>, 'type'>
): RegistryDefinitionForm<TInputContractSchema> => {
  return { ...definition, type: 'form' };
};

export const registerAdvancedSelectQuery = (
  definition: Omit<RegistryDefinitionAdvancedSelectQuery, 'type'>
): RegistryDefinitionAdvancedSelectQuery => {
  return { ...definition, type: 'advanced_select_query' };
};
