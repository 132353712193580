import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import {
  type AdvancedSelectQueryFn,
  type AdvancedSelectQueryTypeaheadParams,
  type ComboBoxItemUnion
} from '@oms/frontend-foundation';
import type { Subscription } from 'rxjs';
import type { OperationVariables, WatchQueryOptions } from '@apollo/client';

export type TypeaheadQueryOptions<
  ComboValue = any,
  T = any,
  TVariables extends OperationVariables = OperationVariables
> = {
  queryFn: (inputValue: string) => WatchQueryOptions<TVariables, T>;
  mapper: (data: T) => ComboBoxItemUnion<ComboValue>[];
};

export function typeaheadQuery<
  ComboValue = any,
  T = any,
  TVariables extends OperationVariables = OperationVariables
>({ queryFn, mapper }: TypeaheadQueryOptions<ComboValue, T, TVariables>): AdvancedSelectQueryFn<ComboValue> {
  return (container) => {
    const apolloClient = container.resolve(ApolloClientRPC);
    let hasResults = false;
    let lastSub: Subscription | undefined;

    const query = ({ inputValue, callback }: AdvancedSelectQueryTypeaheadParams<ComboValue>) => {
      if (lastSub) {
        lastSub.unsubscribe();
      }

      if (!inputValue) {
        hasResults = false;
        return;
      }

      if (hasResults === false) {
        callback({
          isFetching: true,
          results: []
        });
      }

      const watchQueryOptions = queryFn(inputValue);
      lastSub = apolloClient.watchQuery<T, TVariables>(watchQueryOptions).subscribe((result) => {
        const { data, loading: isFetching, error } = result;
        const results = mapper(data);
        hasResults = results.length > 0;

        callback({
          results,
          isFetching,
          error
        });
      });
    };

    return {
      type: 'typeahead',
      query,
      unsubscribe: () => {
        if (lastSub) {
          lastSub.unsubscribe();
        }
      }
    };
  };
}
