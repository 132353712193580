import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import {
  rejectInvestorOrderFormContract,
  type RejectInvestorOrderFormInput,
  type RejectInvestorOrderFormContractType,
  type RejectInvestorOrderFormValues,
  type RejectInvestorOrderFormOutput
} from './reject-investor-order.form-contract';
import { FormBuilder, FORM_EVENT_TYPE } from '@oms/frontend-foundation';

export const rejectInvestorOrderFormBuilder = FormBuilder.create<
  RejectInvestorOrderFormInput,
  RejectInvestorOrderFormOutput
>('reject-investor-order-form')
  .contract<RejectInvestorOrderFormContractType>(rejectInvestorOrderFormContract)
  .type('rejectInvestorOrder')
  .sanitizer((s) =>
    s
      .input(function sanitize(input) {
        if (!input.id) {
          throw new Error('Can not reject an investor order without an id');
        }

        const formValues: RejectInvestorOrderFormValues = {
          id: input.id
        };

        return formValues;
      })
      .output(function sanitize(formValues) {
        if (!formValues.id) return;

        const output: RejectInvestorOrderFormOutput = {
          id: formValues.id,
          rejectText: formValues.rejectText
        };

        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const service = ctx.container.resolve(InvestorOrdersService);
        return await service.rejectOrder(
          event.payload.output.id,
          event.payload.output.rejectText ?? undefined
        );
      }
    }
  });

export type RejectInvestorOrderFormBuilderType = typeof rejectInvestorOrderFormBuilder;

export default rejectInvestorOrderFormBuilder;
