import { type AnyRecord } from '@valstro/workspace';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import {
  type FieldContract,
  type INumberFormatField,
  type ModifiedFieldForOptions
} from '@oms/frontend-foundation';

export const createQuantityField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<INumberFormatField>
) =>
  fc.field(key, 'number-format').options<INumberFormatField>({
    label: 'Quantity',
    format: 'quantity',
    clipFormLabels: false,
    sx: {
      textAlign: 'right'
    },
    ...overrides,
    validate: [{ type: 'min-number-value', value: 1, message: 'Min 1 share' }, ...(overrides?.validate || [])]
  });
