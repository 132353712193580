import {
  type AdditionalFieldsDocType,
  ADDITIONAL_FIELDS_COLLECTION_NAME
} from '@app/data-access/offline/collections/additional-fields.collection';
import { useCallback, useMemo } from 'react';
import type { QueryConstructor } from 'rxdb-hooks-v5';
import { useRxCollection, useRxData } from 'rxdb-hooks-v5';

export const useAdditionalFieldsState = (formType: string) => {
  if (!formType) {
    throw new Error(`formType is required. Please add .type('my-form-type') to the form builder.`);
  }

  const queryConstructor: QueryConstructor<AdditionalFieldsDocType> = useCallback(
    (c) => c.findOne(formType),
    [formType]
  );

  const collection = useRxCollection<AdditionalFieldsDocType>(ADDITIONAL_FIELDS_COLLECTION_NAME);
  const { result } = useRxData<AdditionalFieldsDocType>(ADDITIONAL_FIELDS_COLLECTION_NAME, queryConstructor, {
    json: true,
    pageSize: 1
  });

  const updateFields = useCallback(
    (selectedFields: string[]) => {
      if (collection) {
        collection.upsert({ formType, selectedFields }).catch(console.error);
      }
    },
    [collection, formType]
  );

  const removeFields = useCallback(
    (fields: string[]) => {
      const selectedFields = result.length
        ? result[0].selectedFields.filter((field) => !fields.includes(field))
        : [];

      updateFields(selectedFields);
    },
    [result, updateFields]
  );

  const addFields = useCallback(
    (fields: string[]) => {
      const selectedFields = result.length
        ? Array.from(new Set([...result[0].selectedFields, ...fields]))
        : fields;

      updateFields(selectedFields);
    },
    [result, updateFields]
  );

  return useMemo(
    () => ({
      addFields,
      removeFields,
      selectedFields: result?.[0]?.selectedFields ? Array.from(result[0].selectedFields) : []
    }),
    [addFields, removeFields, result]
  );
};
