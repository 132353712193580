import { BehaviorSubject, Observable } from 'rxjs';

/** Any type that could be undefined */
export type Optional<T> = T | undefined;
export type Maybe<T> = Optional<T> | null;

export type WithRequiredFields<T, K extends keyof T> = T & Required<Pick<T, K>>;
/** Removes null from the speficied fields on the type */
export type MakeFieldsRequired<T, K extends keyof T> = T & {
  [P in K]-?: NonNullable<T[P]>;
};

/** Removes null from all the fields on the type */
export type MakeAllFieldsRequired<T> = Required<{
  [P in keyof T]: NonNullable<T[P]>;
}>;

/**
 * Modifies type `T` such that it cannot be `null`, (but could be `undefined`).
 * Arrays must not contain `null` or `undefined` values
 */
export type NoNull<T> = T extends null ? undefined : T extends Array<infer U> ? Array<NonNullable<U>> : T;

export type RequireSome<T, Keys extends keyof T = keyof T> = Required<Pick<T, Keys>> & Exclude<T, Keys>;

export type OptionSome<T, Keys extends keyof T = keyof T> = Partial<Pick<T, Keys>> & Exclude<T, Keys>;

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  { [K in Keys]-?: Required<Pick<T, K>> }[Keys];

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  { [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>> }[Keys];

export type Extends<T, U extends T> = U;

// Like Partial<T> but recursively for deep objects. Extracted from redux types
export type DeepPartial<T> = { [K in keyof T]?: DeepPartial<T[K]> };
export type DeepRequired<T> = { [K in keyof T]-?: DeepRequired<T[K]> };

// extracts the return type of a promise, or a function that returns a promise
// see https://stackoverflow.com/questions/48011353/how-to-unwrap-type-of-a-promise?rq=1
export type AsyncReturnType<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer V>
  ? V
  : T;

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type OptionalSome<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

/**
 * Describes a type predicate function that returns a boolean which safely casts at type at runtime.
 * - [For more info on type predicates](https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates)
 */
export type TypePredicate<InputType, TestedType extends InputType> = (
  input: InputType
) => input is TestedType;

// Mapped types ------------------------------------------------------------ /
// https://www.typescriptlang.org/docs/handbook/2/mapped-types.html

/** A type with all the values converted to the given generic `Value` type */
export type ValueConversionOf<Type, Value> = {
  [Property in keyof Type]: Value;
};

/** A type with all the values converted to strings */
export type StringsOf<Type> = ValueConversionOf<Type, string>;

/** A type with all the values converted to strings */
export type FlagsOf<Type> = ValueConversionOf<Type, boolean>;

/** A type with all the values converted to unknown */
export type UnknownOf<Type> = ValueConversionOf<Type, unknown>;

/** Removes 'optional' attributes from a type's properties. From [TypeScript docs](https://www.typescriptlang.org/docs/handbook/2/mapped-types.html). */
export type Concrete<Type> = {
  [Property in keyof Type]-?: Type[Property];
};

/** Converts all properties to Observables of that type */
export type ObservablesOf<Type> = {
  [Property in keyof Type as `${string & Property}$`]-?: Observable<Type[Property]>;
};

/** Converts all properties to `BehaviorSubject`s of that type */
export type BehaviorSubjectsOf<Type> = {
  [Property in keyof Type as `${string & Property}$`]-?: BehaviorSubject<Type[Property]>;
};

/** Converts keys of a type to plural form */
export type PluralsOf<Type> = Type extends string ? `${Type}s` : {
  [Property in keyof Type as `${string & Property}s`]: Type[Property];
};

/** Converts all properties to setters for that type */
export type SettersFor<Type> = {
  [Property in keyof Type as `set${Capitalize<string & Property>}`]-?: (value: Type[Property]) => void;
};

/**
 * Makes an object of getters from a source type. This is useful if you need to know how to
 * extract info from an unknown generic.
 *
 * For example, say we need to extract a string label and numeric value from type `User`
 *
 * ```ts
 * interface User {
 *   id: string;
 *   name: string;
 *   age: number;
 * }
 * const getters: GettersFrom<User, { label: string, value: string }> = {
 *   getLabel: (user) => user.name,
 *   getValue: (user) => user.age
 * };
 * ```
 *
 * Use with `Partial` to make getters optional.
 */
export type GettersFrom<SourceType, DestinationValues> = {
  [Property in keyof DestinationValues as `get${Capitalize<string & Property>}`]: (source: SourceType) => DestinationValues[Property];
};

/** Removes the possibility of `null` from all values of a type, but allows `undefined` */
export type RemoveNullFrom<Type> = {
  [Property in keyof Type]: NoNull<Type[Property]>;
};

/** Allows the possibility of `null` or `undefined` for all type properties */
export type MaybePropsOf<Type> = {
  [Property in keyof Type]: Maybe<Type[Property]>;
};

// Case conversion ----- /
// From https://stackoverflow.com/questions/60269936/typescript-convert-generic-object-from-snake-to-camel-case

export type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S;

export type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;

export type CamelToPascalCase<S extends string> = Capitalize<S>;
export type PascalToCamelCase<S extends string> = Uncapitalize<S>;
export type PascalToSnakeCase<S extends string> = CamelToSnakeCase<Uncapitalize<S>>;
export type SnakeToPascalCase<S extends string> = Capitalize<SnakeToCamelCase<S>>;

export type CamelToUpperSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Uppercase<T>}${CamelToUpperSnakeCase<U>}`
  : S;
export type PascalToUpperSnakeCase<S extends string> = CamelToUpperSnakeCase<Uncapitalize<S>>;

// From https://stackoverflow.com/questions/55142177/how-to-build-a-type-from-enum-values-in-typescript

export type StringValues<T> = {
  [K in keyof T]: T[K] extends string ? T[K] : never;
}[keyof T];

export type NumberValues<T> = {
  [K in keyof T]: T[K] extends number ? T[K] : never;
}[keyof T];

export type EnumAsUnion<T> = `${StringValues<T>}` | NumberValues<T>;

export function whereNotUndefined<T>(x: T | undefined | null): x is T {
  return !!x;
}

interface HasId {
  id?: string;
  __typename?: string;
}

interface HasNonOptionalId {
  id: string;
  __typename?: string;
}

/**
 * Good for turning a thick entity into a thin entity
 * @param entity an entity with an ID property
 * @returns a new object literal with just the ID property or null
 */
export const pickIdOrNull = (
  entity: HasId | HasNonOptionalId,
  typename?: string
): HasNonOptionalId | null => {
  const typeblock = typename ? { __typename: typename } : null;
  return entity && entity.id ? { id: entity.id, ...typeblock } : null;
};
