import { type DoubleSpinnerControl } from '@valstro/fixatdl-core';
import { type ControlTransformer } from './control.transformer.types';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const doubleSpinnerControl: ControlTransformer<DoubleSpinnerControl> = (
  doubleSpinnerControl,
  _query,
  formValues
) => {
  return {
    component: fixatdlFormComponentTypes.DOUBLE_SPINNER,
    name: doubleSpinnerControl.id,
    initialValue: AtdlFormats.coerceValue(formValues[doubleSpinnerControl.id]),
    label: doubleSpinnerControl.label,
    innerIncrement: doubleSpinnerControl.increment,
    innerIncrementPolicy: doubleSpinnerControl.innerIncrementPolicy,
    outerIncrement: doubleSpinnerControl.outerIncrement,
    outerIncrementPolicy: doubleSpinnerControl.outerIncrementPolicy
  };
};
