import { type Observable } from 'rxjs';
import type { DataSourceCommon } from '@oms/frontend-foundation';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import ServerSideRowDataHelper from '@app/data-access/services/trading/positions/common/tree-grid/helpers/server-side-row-data-helper.abstract.class';
import type { ServerSideRowDataHelperOptions } from '@app/data-access/services/trading/positions/common/tree-grid/helpers/server-side-row-data-helper.types';
import type { NestedTreeData } from '@app/data-access/services/trading/positions/common/tree-grid/types/tree-data.types';
import type { PositionsGridType } from '../types';

export class PositionsRowDataHelper extends ServerSideRowDataHelper<PositionRow> {
  public static forDataSource(
    data$: Observable<DataSourceCommon<NestedTreeData<PositionRow>>>,
    gridType: Exclude<PositionsGridType, 'positions-history-grid'>
  ): PositionsRowDataHelper {
    return new PositionsRowDataHelper(gridType, data$);
  }

  protected options?: ServerSideRowDataHelperOptions = undefined;

  protected getGroupKey({ id }: PositionRow): string {
    return id;
  }
}

export default PositionsRowDataHelper;
