import { ICellRendererParams } from '@ag-grid-community/core';
import { ProgressBar } from '@oms/shared-frontend/ui-design-system';
import { formattedStringToNumber } from '@oms/shared/util';
import React from 'react';

/**
 * Const name registered with ag-grid
 */
export const PROGRESS_RENDERER = 'ProgressRenderer';

/**
 * Progress renderer bar for ag-grid
 */
export const ProgressRenderer: React.FC<ICellRendererParams> = ({ data }) => {
  const denominator = formattedStringToNumber(data?.quantity);
  const numerator = formattedStringToNumber(data?.executedQuantity);

  return <ProgressBar type="advanced" denominator={denominator} numerator={numerator} />;
};
