import { FORM_VALIDATOR_MAPPER } from '@app/forms/form-builder/mappers/form.mappers';
import { type Validator } from '@data-driven-forms/react-form-renderer';
import type { AnyKey, Optional } from '@oms/shared/util-types';
import { type AnyObject } from 'final-form';
import { type ReactNode } from 'react';
import { mapToObject } from '@oms/shared/util';

/** A strongly-typed generic alternative to the `ValidatorFunction` type from `@data-driven-forms/react-form-renderer` */
export type OmsValidatorFunction<
  R,
  V,
  AV extends Record<AnyKey, unknown> = Record<AnyKey, unknown>,
  M extends Record<AnyKey, unknown> = Record<AnyKey, unknown>
> = (value: V, allValues?: AV, meta?: M) => Optional<Promise<R> | ReactNode>;

export type ValidatorType = keyof typeof FORM_VALIDATOR_MAPPER | 'required';

export interface ValidatorConfiguration extends AnyObject {
  type: ValidatorType;
  message?: string;
  msg?: string;
  warning?: boolean;
}

export const requiredValidator: Validator = {
  type: 'required',
  message: 'Required'
};

export const allOmsFormValidatorTypes = Object.keys(FORM_VALIDATOR_MAPPER) as (keyof typeof FORM_VALIDATOR_MAPPER)[];

export const OmsFormValidatorPreset: Record<ValidatorType, Validator> = {
  required: requiredValidator,
  ...mapToObject(
    allOmsFormValidatorTypes,
    (type) => type,
    (type): Validator => ({
      type
    })
  )
};
