import { useClosestFlexLayoutActorProps } from '@valstro/workspace-react';
import { TabContent, TabTrigger, Tabs, TabsList } from '@oms/shared-frontend/ui-design-system';
import type { VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { InvestorOrderAuditTrailGrid } from './investor-order-view.audit-trail.grid';
import { InvestorOrderExecutionsGrid } from './investor-order-view.executions.grid';
import { InvestorOrderTradingOrdersGrid } from './investor-order-view.trading-orders.grid';
import { paneStyle } from './investor-order-view.style';

export const InvestorOrderViewTabbedGridsWidget = () => {
  const [props] = useClosestFlexLayoutActorProps<{
    id: VisibleInvestorOrderInfoWithAllocationsFragment['id'];
  }>();

  if (!props) {
    throw new Error('InvestorOrderViewTabbedGridsWidget requires an investor order ID');
  }
  const { id: investorOrderId } = props;

  return (
    <Tabs
      defaultValue="tabExecutions"
      sx={{ ...paneStyle, position: 'absolute', inset: 0, display: 'flex', flexDirection: 'column' }}
    >
      <TabsList style={{ padding: 0, marginTop: 1, marginLeft: 15, marginBottom: 0 }}>
        <TabTrigger value="tabExecutions">Executions</TabTrigger>
        <TabTrigger value="tabTradingOrders">Trading Orders</TabTrigger>
        <TabTrigger value="tabAuditTrail">Audit Trail</TabTrigger>
      </TabsList>

      <TabContent
        value="tabExecutions"
        sx={{
          height: 'full',
          flexDirection: 'column',
          borderBottomStyle: 'solid',
          borderBottomWidth: '4px',
          borderColor: 'layout.level1'
        }}
      >
        <InvestorOrderExecutionsGrid id={investorOrderId} />
      </TabContent>
      <TabContent
        value="tabTradingOrders"
        sx={{
          height: 'full',
          flexDirection: 'column',
          borderBottomStyle: 'solid',
          borderBottomWidth: '4px',
          borderColor: 'layout.level1'
        }}
      >
        <InvestorOrderTradingOrdersGrid id={investorOrderId} />
      </TabContent>
      <TabContent
        value="tabAuditTrail"
        sx={{
          height: 'full',
          flexDirection: 'column',
          borderBottomStyle: 'solid',
          borderBottomWidth: '4px',
          borderColor: 'layout.level1'
        }}
      >
        <InvestorOrderAuditTrailGrid investorOrderId={investorOrderId} />
      </TabContent>
    </Tabs>
  );
};

export default InvestorOrderViewTabbedGridsWidget;
