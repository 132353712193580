import { inject, singleton } from 'tsyringe';
import { map, type Observable, catchError, of, startWith } from 'rxjs';
import {
  CreateRepresentativeCodeDocument,
  type CreateRepresentativeCodeMutation,
  type CreateRepresentativeCodeMutationVariables,
  DeleteRepresentativeCodeDocument,
  type DeleteRepresentativeCodeMutation,
  type DeleteRepresentativeCodeMutationVariables,
  GetAllRepresentativeCodesDocument,
  type GetAllRepresentativeCodesQuery,
  type GetAllRepresentativeCodesQueryVariables,
  UpdateRepresentativeCodeDocument,
  type UpdateRepresentativeCodeMutation,
  type RepresentativeCode
} from '@oms/generated/frontend';
import { asDataSource, type DataSourceCommon, type ICrudService } from '@oms/frontend-foundation';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { getOperationName } from '@apollo/client/utilities';

const REPRESENTATIVE_CODE_QUERY_NAME = getOperationName(GetAllRepresentativeCodesDocument);

@singleton()
export class RepresentativeCodesService implements ICrudService<RepresentativeCode> {
  private _apolloClient: ApolloClientRPC;
  public static refetchQueryName = REPRESENTATIVE_CODE_QUERY_NAME;
  public static refetchQueries = REPRESENTATIVE_CODE_QUERY_NAME ? [REPRESENTATIVE_CODE_QUERY_NAME] : [];

  constructor(@inject(ApolloClientRPC) apolloClient: ApolloClientRPC) {
    this._apolloClient = apolloClient;
  }

  watchAll$(): Observable<DataSourceCommon<RepresentativeCode>> {
    return this._apolloClient
      .watchQuery<GetAllRepresentativeCodesQuery, GetAllRepresentativeCodesQueryVariables>({
        query: GetAllRepresentativeCodesDocument,
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map((res) => {
          const results = res.data.getAllRepresentativeCodes
            ? (res.data.getAllRepresentativeCodes as RepresentativeCode[])
            : [];
          return asDataSource(results);
        }),
        catchError((e) => {
          console.error(e);
          return of(asDataSource([]));
        }),
        startWith(asDataSource([], { isFetching: true }))
      );
  }

  create(records: RepresentativeCode[]) {
    return this._apolloClient.mutate<
      CreateRepresentativeCodeMutation,
      CreateRepresentativeCodeMutationVariables
    >({
      mutation: CreateRepresentativeCodeDocument,
      refetchQueries: RepresentativeCodesService.refetchQueries,
      variables: {
        input: records[0]
      }
    });
  }

  update(records: RepresentativeCode[]) {
    return this._apolloClient.mutate<UpdateRepresentativeCodeMutation>({
      mutation: UpdateRepresentativeCodeDocument,
      refetchQueries: RepresentativeCodesService.refetchQueries,
      variables: {
        id: records[0].id,
        input: records[0]
      }
    });
  }

  delete(records: RepresentativeCode[]) {
    return Promise.all(
      records.map((r) =>
        this._apolloClient.mutate<
          DeleteRepresentativeCodeMutation,
          DeleteRepresentativeCodeMutationVariables
        >({
          mutation: DeleteRepresentativeCodeDocument,
          refetchQueries: RepresentativeCodesService.refetchQueries,
          variables: {
            id: r.id as string
          }
        })
      )
    );
  }
}
