import { useCallback } from 'react';
import { TradesService } from '@app/data-access/services/trading/trades/trades.service';
import type {
  TradeInfoFragment,
  VisibleTradingOrderInfoWithAllocationsFragment
} from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import {
  useVGrid,
  VGrid,
  type GridBuilder,
  CLEAR_GRID_FILTER_ACTION_TYPE,
  ROW_SELECTION_COUNT_ACTION_TYPE
} from '@oms/frontend-vgrid';
import { tradingOrderTradesColumnLibrary } from './trading-order-view.trades.columns';
import { cancelTradeAction } from './grid-actions/cancel.trade.action';
import { openModifyTradeAction } from './grid-actions/open-modify.trade.action';
import { OfflineGridEventHandler } from '@app/data-access/services/system/grids/offline-grid.event-handler';

export const TradingOrderTradesGrid = ({
  id
}: {
  id: VisibleTradingOrderInfoWithAllocationsFragment['id'];
}) => {
  const toTradesService = useService(TradesService);

  const builder = useCallback(
    (b: GridBuilder<TradeInfoFragment>) =>
      b
        .columnLibrary(tradingOrderTradesColumnLibrary)
        .datasource((d) => d.source(toTradesService.watchAll$(id)).rowId((r) => r.data.id))
        .injectEvents([OfflineGridEventHandler])
        .sideBar()
        .rowSelection((c) => c.multiple())
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(openModifyTradeAction)
                .action(cancelTradeAction)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
            )
            .settings((s) => s.fromFile({}))
        ),
    [toTradesService, id]
  );

  const props = useVGrid('trading-order-trades', builder, [toTradesService]);
  return <VGrid {...props} />;
};

export default TradingOrderTradesGrid;
