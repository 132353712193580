import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import {
  type AnyRecord,
  type FieldContract,
  type ITextField,
  type ModifiedFieldForOptions
} from '@oms/frontend-foundation';

export const createCustomerNotesField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<ITextField>
) =>
  fc.field(key, 'text-field').options<ITextField>({
    label: 'Customer Notes',
    ...overrides
  });
