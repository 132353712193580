import { Input, InputProps } from '../input/input';
import clsx from 'clsx';
import { ClockIcon } from '@radix-ui/react-icons';
import { Icon } from '../icon/icon';
import { IMask, useIMask } from 'react-imask';
import { useCallback, useEffect, useMemo, forwardRef } from 'react';
import omit from 'lodash/omit';
import { format, parse } from 'date-fns';
import { Box } from '../../system/components/box/box';
import { useMergedRef } from '../../system/hooks/use-merge-ref';
import { __DEV__ } from '../../system/utils/assertion';

type OmitProps = 'type';

export type TimePickerProps = {
  format?: '24' | '12';
  includeSeconds?: boolean;
} & Omit<InputProps, OmitProps>;

export const TIME_INPUT_MASK_CONFIG: (props: Pick<TimePickerProps, 'includeSeconds' | 'format'>) => Omit<
  IMask.MaskedDateOptions,
  'mask'
> & {
  mask: IMask.AnyMaskedOptions['mask'];
} = (props) => {
  const twelveHour = props.format === '12';
  let mask = props.includeSeconds ? 'hh:mm:ss' : 'hh:mm';
  mask = twelveHour ? `${mask} aa` : mask;
  const dateFormat = `${twelveHour ? 'hh' : 'HH'}:mm${props.includeSeconds ? ':ss' : ''}${
    twelveHour ? ' aa' : ''
  }`;

  return {
    mask,
    blocks: {
      hh: {
        mask: IMask.MaskedRange,
        from: twelveHour ? 1 : 0,
        to: twelveHour ? 12 : 23,
        maxLength: 2,
        placeholderChar: 'h'
      },
      mm: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        maxLength: 2,
        placeholderChar: 'm'
      },
      ss: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        maxLength: 2,
        placeholderChar: 's'
      },
      aa: {
        mask: IMask.MaskedEnum,
        enum: ['AM', 'PM', 'am', 'pm'],
        placeholderChar: '-'
      }
    },
    prepare: (value) => (value || '').toUpperCase(),
    format: (date: Date) => {
      return format(new Date(date), dateFormat);
    },
    parse: (str) => {
      const date = parse(str, dateFormat, new Date());

      return isNaN(date.getTime()) ? undefined : date;
    },
    autofix: true, // defaults to `false`, see details

    // also Pattern options can be set
    lazy: false
  };
};

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>((props, ref) => {
  const { className, placeholder, children, pattern, format = '24', includeSeconds = true, ...rest } = props;

  const onAcceptCb = useCallback(
    (_val: string, maskRef: IMask.InputMask<IMask.MaskedDateOptions>, e?: InputEvent) => {
      if (e) {
        props.onChange &&
          props.onChange({
            ...e,
            target: {
              ...e.target,
              value: isNaN(maskRef.typedValue?.getTime()) ? undefined : maskRef.typedValue.toString()
            }
          } as any);
      }
    },
    [props]
  );

  const maskConfig = useMemo(() => {
    return TIME_INPUT_MASK_CONFIG({ includeSeconds, format });
  }, [includeSeconds, format]);

  const {
    ref: iMaskInputRef,
    setValue,
    value,
    maskRef
  } = useIMask(maskConfig as IMask.AnyMaskedOptions, {
    onAccept: onAcceptCb
  });

  useEffect(() => {
    if (props.value) {
      setValue(maskConfig.format(props.value as any));
      maskRef.current.updateValue();
    }
  }, [maskConfig, maskRef, props.value, setValue]);

  return (
    <Box sx={{ display: 'inline-flex', flexDirection: 'row' }}>
      <Input
        {...omit(rest, 'value')}
        className={clsx(className)}
        ref={useMergedRef(ref, iMaskInputRef)}
        type="text"
        value={value}
      />
      <Icon
        label={'Clock'}
        as={ClockIcon}
        style={{ left: 'auto', right: '10px' }}
        sx={{
          color: 'icons.semiMinor',
          position: 'absolute',
          alignSelf: 'center',
          justifySelf: 'center'
        }}
      />
    </Box>
  );
});

if (__DEV__) {
  TimePicker.displayName = 'TimePicker';
}
