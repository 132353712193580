import { type FC, useCallback, useMemo, useState } from 'react';
import { Box, Flex, TabContent, TabTrigger, Tabs, TabsList } from '@oms/shared-frontend/ui-design-system';
import { useMontageState } from '@app/data-access/offline/collections/montage/hooks/use-montage-state.hook';
import { MarketDataWidget } from '@app/data-access/services/marketdata/components/market-data.widget';
import { useMontageInstrumentDetails } from '@app/data-access/services/trading/montage/hooks/use-montage-instrument-details.hook';
import { useUser } from '@app/data-access/services/system/auth/auth.hooks';
import { EligibleOrders } from './montage.eligibleorders.panel.widget';
import { marketDataToggleBorder, marketDataToggleText, tabContentInner } from './montage.side-panel.css';
import { paneStyle } from './montage.style';
import { TempMontageFilterStateDisplay } from './temp-montage-filter-state-display.component';

export const MontageSidePanelWidget: FC = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { instrumentId, instrumentTrackingEnabled, investorAccountId } = useMontageState();
  const user = useUser();

  const instrumentDetails = useMontageInstrumentDetails();

  const handleToggle = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const instrumentDataContent = useMemo(() => {
    return instrumentDetails ? (
      <Flex direction="column">
        <Flex align="center" justify="center" sx={{ marginBottom: 'medium' }}>
          <div className={marketDataToggleBorder} />
          <div className={marketDataToggleText} onClick={handleToggle}>
            Show {isExpanded ? 'Less' : 'More'}
          </div>
          <div className={marketDataToggleBorder} />
        </Flex>
        <MarketDataWidget
          instrumentDetails={instrumentDetails}
          type={isExpanded ? 'om-grid-montage-only' : 'om-grid-short-montage-only'}
        />
      </Flex>
    ) : null;
  }, [handleToggle, instrumentDetails, isExpanded]);

  return (
    <Tabs
      defaultValue="tabInstrumentData"
      sx={{
        ...paneStyle,
        marginTop: '2px',
        height: 'full',
        flexDirection: 'column',
        display: 'flex',
        borderRadius: 'md',
        mb: 'small',
        mr: 'small'
      }}
    >
      <TabsList sx={{ paddingLeft: 4, marginBottom: 'medium' }}>
        <TabTrigger value="tabInstrumentData">Instrument data</TabTrigger>
        <TabTrigger value="tabEligibleOrders">Eligible orders</TabTrigger>
        <TabTrigger value="tabTimeAndSales">Time & sales</TabTrigger>
      </TabsList>

      <TabContent value="tabInstrumentData" sx={{ flexDirection: 'column', overflowY: 'auto' }}>
        <Box className={tabContentInner}>{instrumentDataContent}</Box>
      </TabContent>
      <TabContent value="tabEligibleOrders" sx={{ flexDirection: 'column', overflowY: 'auto' }}>
        <Box className={tabContentInner}>
          <EligibleOrders />
        </Box>
      </TabContent>
      <TabContent value="tabTimeAndSales" sx={{ flexDirection: 'column', overflowY: 'auto' }}>
        <Box className={tabContentInner}>
          <TempMontageFilterStateDisplay
            instrumentTrackingEnabled={instrumentTrackingEnabled}
            instrumentId={instrumentId}
            investorAccountId={investorAccountId}
            user={user}
            direction="column"
          />
        </Box>
      </TabContent>
    </Tabs>
  );
};

export default MontageSidePanelWidget;
