import { type Field, type FormOptions, type UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import {
  type ControlState,
  StateRules,
  StrategyQuery,
  type Strategy,
  AtdlFormats
} from '@valstro/fixatdl-core';

type FieldConfig = { prop: string; invert?: boolean; defaultValue?: any };

export interface StateRuleActionOptions {
  strat: Strategy;
  fieldConfig: Record<keyof ControlState, FieldConfig>;
}

export const stateRulesAction: (
  opts: StateRuleActionOptions
) => (_props: Field, field: UseFieldApiConfig, formOptions: FormOptions) => any =
  ({ strat, fieldConfig }) =>
  (_props: Field, field: UseFieldApiConfig, formOptions: FormOptions) => {
    const { getState } = formOptions;
    const query = StrategyQuery.from(strat);
    const formState = getState();
    const control = query.findControl(field.input.name);
    const expressions = StateRules.evaluate(
      control?.control.stateRules || [],
      query.values(formState.values)
    );

    const result = {} as { [key: string]: any };
    let mergedExpressions: ControlState = {};
    expressions.forEach((e) => {
      mergedExpressions = {
        value: AtdlFormats.isEmpty(e.value) ? mergedExpressions.value : e.value,
        visible: AtdlFormats.isBoolean(e.visible) ? e.visible : mergedExpressions.visible,
        enabled: AtdlFormats.isBoolean(e.enabled) ? e.enabled : mergedExpressions.enabled
      };
    });

    (Object.keys(fieldConfig) as Array<keyof ControlState>).forEach((c) => {
      const { prop, invert = false, defaultValue } = fieldConfig[c] as FieldConfig;
      result[prop] =
        invert && AtdlFormats.isBoolean(mergedExpressions[c]) ? !mergedExpressions[c] : mergedExpressions[c];
      result[prop] = AtdlFormats.isEmpty(result[prop]) ? defaultValue : result[prop];
    });

    return result;
  };
