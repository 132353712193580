import { useEffect } from 'react';
import { Logger } from '@oms/shared/util';
import { useService } from '@oms/frontend-foundation';
import { Level2WebsocketService } from '@app/data-access/services/marketdata/level2-websocket.service';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';

const logger = Logger.labeled('useLevel2Websocket');

export const useLevel2Websocket = () => {
  const scopedActorId = useScopedActorId();

  const level2WebsocketService = useService(Level2WebsocketService);

  useEffect(() => {
    // TEMPORARY: Create a connection to the sidecar from within the Montage window until we figure out how to only have one connection and pass values between windows in Tauri
    level2WebsocketService.connect();

    const subscription = level2WebsocketService.subscribeToInstrumentFrom(scopedActorId, (e) => {
      logger.scope(`subscribeToInstrumentFrom.${scopedActorId}`).error(e);
    });

    return () => {
      subscription.unsubscribe();
      level2WebsocketService.reset();
    };
  }, [scopedActorId, level2WebsocketService]);
};
