import { type ExtendedUserObject } from '@app/common/types/users/types';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import {
  type IAdvancedSelectField,
  type AnyRecord,
  type FieldContract,
  type IAdvancedMultiSelectField,
  type ModifiedFieldForOptions
} from '@oms/frontend-foundation';

export const createActiveUserMultiField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedMultiSelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'advanced-multi-select').advancedSelectMultiOptions<ExtendedUserObject>({
    autoFocus: false,
    width: 150,
    label: 'Owner',
    query: ADVANCED_SELECT_QUERY_ENUM.ACTIVE_USER_WATCH_QUERY,
    ...overrides
  });

export const createActiveUserField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedSelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'advanced-select').advancedSelectOptions<ExtendedUserObject>({
    width: 200,
    label: 'Owner',
    query: ADVANCED_SELECT_QUERY_ENUM.ACTIVE_USER_WATCH_QUERY,
    ...overrides
  });
