import { useCallback, useMemo } from 'react';
import { type QueryConstructor } from 'rxdb-hooks-v5';
import type { ActorScoped } from '../types';
import { useScopedActorId } from './use-scoped-actor-id.hook';
import { useRxDocument } from '@app/common/rxdb/rxdb.hooks';

type UseRxScopedActorDataReturn<T extends ActorScoped> = ActorScoped & ReturnType<typeof useRxDocument<T>>;

export const useRxScopedActorCollection = <T extends ActorScoped>(
  collectionName: string,
  options?: Parameters<typeof useRxDocument<T>>[2]
): UseRxScopedActorDataReturn<T> => {
  const scopedActorId = useScopedActorId();

  const queryConstructor: QueryConstructor<T> = useCallback((c) => c.findOne(scopedActorId), [scopedActorId]);

  const { document, data, collection, isFetching } = useRxDocument<T>(
    collectionName,
    queryConstructor,
    options
  );

  return useMemo(
    () => ({ scopedActorId, document, data, collection, isFetching }),
    [scopedActorId, document, data, collection, isFetching]
  );
};

export default useRxScopedActorCollection;
