import { COMMON_PLATFORM_NAME } from '@valstro/workspace';
import type { AppSnapshot } from '@app/app-config/snapshot-layout.config';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { confirm as tauriConfirm } from '@tauri-apps/api/dialog';

export async function takeAppSnapshot(workspace: AppWorkspace): Promise<AppSnapshot> {
  const platformInfo = await workspace.getPlatformInfo();
  const snapshot = await workspace.getLeaderActor().takeSnapshot();
  if (!platformInfo) {
    throw new Error('Platform info is missing');
  }
  return {
    children: snapshot.children,
    platformInfo
  };
}

export async function applyAppSnapshot(
  workspace: AppWorkspace,
  snapshot: Partial<AppSnapshot>
): Promise<boolean> {
  const platformInfo = await workspace.getPlatformInfo();
  if (!platformInfo) {
    throw new Error('Platform info is missing');
  }

  let shouldContinue = true;

  if (snapshot.platformInfo && platformInfo.name !== snapshot.platformInfo.name) {
    const getConfirmMessage = (name1: string, name2: string) =>
      `The platform (${name1}) of the snapshot is different from the current platform (${name2}). This may cause an undesirable layout or unintended issues. Do you want to continue?`;

    if (platformInfo.name === COMMON_PLATFORM_NAME.BROWSER) {
      // eslint-disable-next-line no-restricted-globals
      shouldContinue = confirm(getConfirmMessage(snapshot.platformInfo.name, COMMON_PLATFORM_NAME.BROWSER));
    } else if (platformInfo.name === COMMON_PLATFORM_NAME.TAURI) {
      shouldContinue = await tauriConfirm(
        getConfirmMessage(snapshot.platformInfo.name, COMMON_PLATFORM_NAME.TAURI),
        'Are you sure?'
      );
    }
  }

  if (!shouldContinue) {
    return false;
  }

  await workspace.getLeaderActor().applySnapshot({ children: snapshot.children || [] });

  return true;
}
