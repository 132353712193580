import { type DependencyList, useLayoutEffect } from 'react';
import { type AddToolbarActionArgs } from '../toolbar-context.types';
import { useToolbar } from './use-toolbar.hook';
import { useFlexLayoutNodeContext } from '@valstro/workspace-react';

export const useAddToolbarActions = (actions: AddToolbarActionArgs[], dependencies: DependencyList = []) => {
  const { addToolbarAction, removeToolbarAction } = useToolbar();
  const nodeCtx = useFlexLayoutNodeContext();
  const tabSetNode = nodeCtx.tabSetNode;

  useLayoutEffect(() => {
    actions.forEach(({ location, component, id, index }) => {
      switch (location) {
        case 'window': {
          addToolbarAction({ location, component, id, index });
          break;
        }
        case 'flexlayout-tabset': {
          if (!tabSetNode) return;
          const tabSetId = tabSetNode.getId();
          addToolbarAction({
            location,
            component,
            id: createFlexLayoutActionId(id, tabSetId),
            index,
            tabSetId
          });
          break;
        }
        default: {
          return;
        }
      }
    });

    return () => {
      actions.forEach(({ location, id }) => {
        removeToolbarAction({ location, id });
      });
    };

    // addToolbarAction and removeToolbarAction are memoized in useToolbar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToolbarAction, removeToolbarAction, tabSetNode, ...dependencies]);
};

export const useAddToolbarAction = (action: AddToolbarActionArgs, dependencies: DependencyList = []) => {
  useAddToolbarActions([action], dependencies);
};

function createFlexLayoutActionId(id: string, tabSetId: string) {
  return `${id}-${tabSetId}`;
}
