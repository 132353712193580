import { DateTime } from 'luxon';
import type { Optional } from '@oms/shared/util-types';
import { mapTif } from '@app/common/mappers/map-tif';
import {
  createFormValidator,
  type AdditionalFormFieldUnion
} from '@app/forms/form-builder/mappers/form.mappers';
import type { Field } from '@data-driven-forms/react-form-renderer';
import {
  FieldDefinition,
  type AnyRecord,
  type FieldContract,
  type AnyFieldDefinition,
  type ModifiedFieldForOptions,
  type ISelectField,
  type IBoxField
} from '@oms/frontend-foundation';
import { TimeInForce } from '@oms/generated/frontend';
import {
  getDurationInMMSS,
  getDurationInSeconds
} from '@app/forms/common/validators/validate-duration-input/validate-duration-input.validator';

/**
 * @deprecated Use createTIFFields instead with gtdUseMarketEndTime flag
 */
export const createTIFField = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<ISelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'select').options<ISelectField<any>>({
    label: 'TIF',
    options: Object.values(TimeInForce)
      .filter((value) => value !== TimeInForce.TifNotSupported)
      .map((value) => ({ value, label: mapTif(value) })),
    ...overrides
  });

/**
 * @deprecated Use createTIFFields instead with gtdUseMarketEndTime flag
 */
export const createGTDTimestampField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  tifKey: string,
  overrides?: Omit<Partial<any>, 'component'>
) =>
  fc.field(key, 'native-date-picker').options<any>({
    ...overrides,
    timePicker: true,
    validate: [{ type: 'afterDate', message: 'Must be future date.' }],
    condition: {
      when: `${tifKey}`,
      is: TimeInForce.Gtd,
      then: { visible: true },
      else: {
        set: { [key]: '' },
        visible: false
      }
    }
  });

export const createTifDurationField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  tifKey: string,
  overrides?: Omit<Partial<any>, 'component'>
) =>
  fc.field(key, 'text-field').options<any>({
    ...overrides,
    placeholder: 'mm:ss',
    validate: [createFormValidator('validateDurationInput', { formatType: 'mmss' })],
    condition: {
      when: `${tifKey}`,
      is: TimeInForce.Duration,
      then: { visible: true },
      else: {
        set: { [key]: '' },
        visible: false
      }
    }
  });

const TIF_FIELD_KEYS = {
  gtdDate: 'gtdDate',
  gtdTime: 'gtdTime',
  gtdTimestamp: 'gtdTimestamp',
  tifDuration: 'tifDuration'
} as const;

export const createTIFFields = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  tifKey: TFieldKey,
  overrides?: Omit<Partial<any>, 'component'>
) => {
  const tifField = fc.field(tifKey, 'select').options<ISelectField<any>>({
    label: 'TIF',
    options: Object.values(TimeInForce)
      .filter((value) => value !== TimeInForce.TifNotSupported)
      .map((value) => ({ value, label: mapTif(value) })),
    ...overrides
  });

  const gtdDateField = fc.field(TIF_FIELD_KEYS.gtdDate, 'native-date-picker').options({
    timePicker: false,
    validate: [
      { type: 'required', message: 'This field is required' },
      createFormValidator('futureGTD', { message: 'Must be future GTD' })
    ],
    condition: {
      when: String(tifKey),
      is: TimeInForce.Gtd,
      then: { visible: true },
      else: {
        set: { [TIF_FIELD_KEYS.gtdDate]: '' },
        visible: false
      }
    }
  });

  const gtdTimeField = fc.field(TIF_FIELD_KEYS.gtdTime, 'native-time-picker').options({
    condition: {
      when: String(tifKey),
      is: TimeInForce.Gtd,
      then: { visible: true },
      else: {
        set: { [TIF_FIELD_KEYS.gtdTime]: '' },
        visible: false
      }
    }
  });

  const gtdTimestampField = fc.field(TIF_FIELD_KEYS.gtdTimestamp, 'hidden-field');

  const tifDurationField = fc.field(TIF_FIELD_KEYS.tifDuration, 'text-field').options({
    placeholder: 'mm:ss',
    condition: {
      when: String(tifKey),
      is: TimeInForce.Duration,
      then: { visible: true },
      else: {
        set: { [TIF_FIELD_KEYS.tifDuration]: '' },
        visible: false
      }
    },
    validate: [createFormValidator('validateDurationInput', { formatType: 'mmss' })]
  });

  return {
    timeInForce: tifField,
    [TIF_FIELD_KEYS.gtdDate]: gtdDateField,
    [TIF_FIELD_KEYS.gtdTime]: gtdTimeField,
    [TIF_FIELD_KEYS.gtdTimestamp]: gtdTimestampField,
    [TIF_FIELD_KEYS.tifDuration]: tifDurationField
  };
};

/**
 * @deprecated should be moved to makeTIFSplitDisplayFields
 */
export const makeTIFDisplayFields = <
  T1 extends AnyFieldDefinition | ReturnType<AnyFieldDefinition['build']>,
  T2 extends AnyFieldDefinition | ReturnType<AnyFieldDefinition['build']>,
  T3 extends AnyFieldDefinition | ReturnType<AnyFieldDefinition['build']>
>(
  groupName = 'timeInForceGroup',
  timeInForceField: T1,
  gtdTimestampField: T2,
  tifDurationField: T3
) => {
  const timeInForceFieldDef =
    timeInForceField instanceof FieldDefinition
      ? (timeInForceField.build() as Field)
      : (timeInForceField as Field);

  const gtdTimestampFieldDef =
    gtdTimestampField instanceof FieldDefinition
      ? (gtdTimestampField.build() as Field)
      : (gtdTimestampField as Field);

  const tifDurationFieldDef =
    tifDurationField instanceof FieldDefinition
      ? (tifDurationField.build() as Field)
      : (tifDurationField as Field);

  return FieldDefinition.box(
    groupName,
    [
      FieldDefinition.box(`${groupName}-left`, [timeInForceFieldDef], {
        style: {
          flexGrow: 1
        }
      }),
      FieldDefinition.box(`${groupName}-right`, [gtdTimestampFieldDef], {
        condition: {
          when: `${timeInForceFieldDef.name}`,
          is: TimeInForce.Gtd,
          then: { visible: true },
          else: {
            set: { [gtdTimestampFieldDef.name]: '' },
            visible: false
          }
        },
        style: {
          marginLeft: '4px',
          width: 'calc(72% - 4px)'
        }
      }),
      FieldDefinition.box(`${groupName}-right`, [tifDurationFieldDef], {
        condition: {
          when: `${timeInForceFieldDef.name}`,
          is: TimeInForce.Duration,
          then: { visible: true },
          else: {
            set: { [tifDurationFieldDef.name]: '' },
            visible: false
          }
        },
        style: {
          marginLeft: '4px',
          width: 'calc(72% - 4px)'
        }
      })
    ],
    {
      sx: {
        display: 'flex'
      }
    }
  );
};

type TifFieldBase = AnyFieldDefinition | ReturnType<AnyFieldDefinition['build']>;

export const makeTIFSplitDisplayFields = <
  TimeInForceFieldType extends TifFieldBase,
  GtdDateFieldType extends TifFieldBase,
  GtdTimeFieldType extends TifFieldBase,
  TifDurationFieldType extends TifFieldBase
>(
  groupName = 'timeInForceGroup',
  timeInForceField: TimeInForceFieldType,
  tifFields?: {
    gtdDate?: GtdDateFieldType;
    gtdTime?: GtdTimeFieldType;
    tifDuration?: TifDurationFieldType;
  }
) => {
  const timeInForceFieldDef =
    timeInForceField instanceof FieldDefinition
      ? (timeInForceField.build() as Field)
      : (timeInForceField as Field);

  const { gtdDate, gtdTime, tifDuration } = tifFields ?? {};

  const gtdDateFieldDef =
    gtdDate instanceof FieldDefinition ? (gtdDate.build() as Field) : (gtdDate as Optional<Field>);

  const gtdTimeFieldDef =
    gtdTime instanceof FieldDefinition ? (gtdTime.build() as Field) : (gtdTime as Optional<Field>);

  const tifDurationFieldDef =
    tifDuration instanceof FieldDefinition
      ? (tifDuration.build() as Field)
      : (tifDuration as Optional<Field>);

  const commonStyle: IBoxField['style'] = {
    marginLeft: '4px',
    width: 'calc((88% - 4px) / 2)'
  };

  const tifField = timeInForceFieldDef.name;
  const rightName = `${groupName}-right`;

  const fields: Parameters<typeof FieldDefinition.box>[1] = [
    FieldDefinition.box(`${groupName}-left`, [timeInForceFieldDef], {
      style: {
        flexGrow: 1
      }
    })
  ];
  if (gtdDateFieldDef) {
    fields.push(
      FieldDefinition.box(`${groupName}-middle`, [gtdDateFieldDef], {
        condition: {
          when: tifField,
          is: TimeInForce.Gtd,
          then: { visible: true },
          else: {
            set: { [gtdDateFieldDef.name]: '' },
            visible: false
          }
        },
        style: commonStyle
      })
    );
  }
  if (gtdTimeFieldDef) {
    fields.push(
      FieldDefinition.box(rightName, [gtdTimeFieldDef], {
        condition: {
          when: tifField,
          is: TimeInForce.Gtd,
          then: { visible: true },
          else: {
            set: { [gtdTimeFieldDef.name]: '' },
            visible: false
          }
        },
        style: commonStyle
      })
    );
  }
  if (tifDurationFieldDef) {
    fields.push(
      FieldDefinition.box(rightName, [tifDurationFieldDef], {
        condition: {
          when: tifField,
          is: TimeInForce.Duration,
          then: { visible: true },
          else: {
            set: { [tifDurationFieldDef.name]: '' },
            visible: false
          }
        },
        style: commonStyle
      })
    );
  }

  return FieldDefinition.box(groupName, fields, {
    sx: {
      display: 'flex'
    }
  });
};

export const tifOutput = ({
  timeInForce,
  gtdDate,
  gtdTime,
  tifDuration
}: {
  timeInForce?: TimeInForce | null;
  gtdDate?: string | null;
  gtdTime?: string | null;
  tifDuration?: string | null;
}) => {
  const tifDurationOutput =
    timeInForce === TimeInForce.Duration && tifDuration
      ? getDurationInSeconds(tifDuration, { formatType: 'mmss' })
      : null;

  if (timeInForce !== TimeInForce.Gtd || !gtdDate) {
    return {
      timeInForce,
      gtdUseMarketEndTime: false,
      gtdTimestamp: null,
      tifDuration: tifDurationOutput
    };
  }

  const gtdUseMarketEndTime = !gtdTime;
  // If we don't have the time we don't want to convert toUTC to prevent
  // midnight to become a different day due to timezones
  const gtdTimestamp =
    gtdUseMarketEndTime && gtdDate ? gtdDate : DateTime.fromISO(`${gtdDate}T${gtdTime}`).toUTC().toISO();

  return {
    timeInForce: TimeInForce.Gtd,
    gtdUseMarketEndTime,
    gtdTimestamp,
    tifDuration: tifDurationOutput
  };
};

export const tifFormValues = (
  suppliedValues?: {
    timeInForce?: TimeInForce | null;
    gtdTimestamp?: string | null;
    tifDuration?: number | string | null;
  } | null
) => {
  const formValues: {
    timeInForce?: TimeInForce | null;
    gtdDate?: string | null;
    gtdTime?: string | null;
    tifDuration?: string;
  } = {};

  const { timeInForce, gtdTimestamp, tifDuration } = suppliedValues || {};

  // This is called both for form enrichment and initial form setup
  if ('timeInForce' in (suppliedValues || {})) {
    formValues.timeInForce = timeInForce || undefined;
  }
  if (gtdTimestamp) {
    // if gtdTimestamp is supplied set it to GTD as the backend doesn't send it during enrichment
    formValues.timeInForce = TimeInForce.Gtd;
  }
  if (tifDuration) {
    // if tifDuration is supplied set it to Duration in case the backend won't send it during enrichment
    formValues.timeInForce = TimeInForce.Duration;
  }
  const dateTime = DateTime.fromISO(gtdTimestamp || '').toLocal();
  formValues.gtdDate = dateTime.toISODate();
  formValues.gtdTime = dateTime.toISOTime({ includeOffset: false });

  // Handle tifDuration
  switch (typeof tifDuration) {
    // For Montage - Create TO tifDuration is already formatted as 'mm:ss' (GetUserPreferences query).
    // Therefore, no conversion is needed, and we can assign it directly to formValues.tifDuration.
    case 'string':
      formValues.tifDuration = tifDuration;
      break;
    case 'number':
      formValues.tifDuration = getDurationInMMSS(tifDuration);
      break;
    default:
      formValues.tifDuration = undefined;
      break;
  }

  return formValues;
};
