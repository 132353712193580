import { type AdvancedSelectQueryFn, getAdvancedSelectQueryReturn } from '@oms/frontend-foundation';
import {
  type TimezoneFragment,
  TimezonesService
} from '@app/data-access/services/reference-data/timezones/timezones.service';
import { type ComboBoxItem, comboBoxItemFrom } from '@oms/frontend-foundation';

export const timezoneResultMapper = (
  timezone: TimezoneFragment
): ComboBoxItem<TimezoneFragment> | undefined =>
  comboBoxItemFrom.record(timezone, {
    id: ({ id }: TimezoneFragment) => id ?? '',
    label: ({ name }: TimezoneFragment) => name ?? ''
  });

export const watchAllTimezonesQuery: AdvancedSelectQueryFn<TimezoneFragment> = () => {
  return getAdvancedSelectQueryReturn<TimezoneFragment, TimezoneFragment>({
    queryFn: new TimezonesService().watchAllStaticData$,
    resultMapper: timezoneResultMapper,
    preLoad: true
  });
};

export default watchAllTimezonesQuery;
