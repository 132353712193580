/* eslint-disable @typescript-eslint/no-explicit-any */
import { createGlobalChannel as channel, uuid as uuidv4 } from '@valstro/remote-link';
import type {
  ACEventType,
  ACExtractPayload,
  ACRequestEvent,
  ACResponseEvent,
  ACUniqueMessageID
} from './apollo-client-rpc.types';
import { ApolloError } from '@apollo/client';

export const acReqChannel = channel<ACRequestEvent>('ac-request');
export const acRespChannel = channel<ACResponseEvent>('ac-response');

export function acRequestResponse<RequestType extends ACEventType<ACRequestEvent | ACResponseEvent>>(
  clientId: string,
  type: RequestType,
  options: ACExtractPayload<ACRequestEvent | ACResponseEvent, RequestType>,
  timeoutTime = 15_000,
  customUuid?: ACUniqueMessageID
) {
  return new Promise<ACExtractPayload<ACResponseEvent, RequestType>>((resolve, reject) => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    const uuid = customUuid ? customUuid : uuidv4();

    const responseHandler = (event: ACResponseEvent) => {
      if (event.meta.clientId !== clientId) return;
      if (event.type !== 'apolloError' && event.type !== type) return;
      if (event.meta.uuid !== uuid) return;
      acRespChannel.removeEventListener('message', responseHandler);
      if (timeout) clearTimeout(timeout);
      if (event.type === 'apolloError') {
        reject(
          new ApolloError({
            errorMessage: event.payload.message,
            networkError: event.payload.networkError,
            clientErrors: event.payload.clientErrors,
            graphQLErrors: event.payload.graphQLErrors,
            extraInfo: event.payload.extraInfo
          })
        );
      } else {
        resolve(event.payload as ACExtractPayload<ACResponseEvent, RequestType>);
      }
    };

    acRespChannel.addEventListener('message', responseHandler);

    timeout = setTimeout(() => {
      acRespChannel.removeEventListener('message', responseHandler);
      reject(new Error('Request timed out. Maximum time of 15s exceeded.'));
    }, timeoutTime);

    acReqChannel
      .postMessage({
        type,
        payload: options,
        meta: {
          uuid,
          clientId: clientId
        }
      } as ACRequestEvent)
      .catch(reject);
  });
}
