import type { TradeModifySchemaInput } from './trade-modify.form-builder.config';
import type { TradePatch, InstrumentDetailsSimpleFragment, TradeRepairRequestFragment, TradeInfoFragment } from '@oms/generated/frontend';
import type { AccountComboBoxValue } from '@app/forms/common/fields/account-field/account.field.queries';

export enum TradeModifyEntryType {
  TradeModify = 'trade-modify',
  TradeRepair = 'trade-repair'
}

export type TradeModifyInput = TradeModifySchemaInput;

export type TradeModifyFormInfo =
  | {
    type: TradeModifyEntryType.TradeModify;
    trade: TradeInfoFragment;
    }
  | {
    type: TradeModifyEntryType.TradeRepair;
    tradeRepair: TradeRepairRequestFragment;
  };

export type TradeModifyFieldContract = {
  id: string;
  hiddenFormInfo: TradeModifyFormInfo;
  side: TradePatch['side'];
  instrument: InstrumentDetailsSimpleFragment;
  tradeQuantity: TradePatch['tradeQuantity'];
  tradePrice: TradePatch['tradePrice'];
  tradeDateTime: TradePatch['tradeDateTime'];
  tradeCounterpartyId: TradePatch['tradeCounterpartyId'];
  tradeContraAccountId: AccountComboBoxValue;
  settleType: TradePatch['settleType'];
  settleDate: TradePatch['settleDate'];
  tradeTags?: string[];
};

// TODO: check with BE when implemented
export type TradeModifyOutput = { 
  type: TradeModifyEntryType,
  output: TradePatch & {
    id: string,
    tradeTagsId?: string[]
  },
};
