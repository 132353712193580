import { isIdentifiable } from '@oms/shared/util-types';
import { isEqual } from 'lodash';
import hash from 'object-hash';
import type { AnyKey, Optional, UpdatesFor, Maybe } from '@oms/shared/util-types';

type AnyObject<V = any> = Record<AnyKey, V>;
type TopValuesArray = [string, number][];
export type NumberValueObject = { [key: string]: number };

export const removeUndefinedProperties = (obj: AnyObject) => {
  if (typeof obj !== 'object') {
    return {};
  }

  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === undefined) {
      delete newObj[key];
    }
  });

  return newObj;
};

export const flattenObject = <T extends AnyObject, R extends AnyObject>(obj: T): R => {
  const flattened: R = {} as R;
  Object.keys(obj).forEach((key: keyof T) => {
    const value = obj[key];
    if (typeof value === 'object' && value !== null) {
      Object.assign(flattened, flattenObject(value as T));
    } else {
      flattened[key] = value;
    }
  });
  return flattened as R;
};

export const flattenHash = (obj: AnyObject) => hash(flattenObject(obj));

export const sortObj = <T extends AnyObject, I extends keyof T>(
  item?: T,
  ignoreProperties: I[] = []
): Optional<Omit<T, I> | Omit<T, I>[]> => {
  if (!item) {
    return item;
  }

  if (typeof item === 'object' && !(item instanceof Array)) {
    const keys = Object.keys(item);
    const sortedObj: Partial<T> = {};

    keys.sort(function (key1, key2) {
      (key1 = key1.toLowerCase()), (key2 = key2.toLowerCase());
      if (key1 < key2) return -1;
      if (key1 > key2) return 1;
      return 0;
    });

    for (const i in keys) {
      const key = keys[i];
      if (!ignoreProperties.includes(key as I)) {
        sortedObj[key as keyof T] = sortObj(item[key as keyof T], ignoreProperties) as Optional<T[keyof T]>;
      }
    }

    return sortedObj as Optional<Omit<T, I>>;
  } else if (Array.isArray(item)) {
    const sortedArray = [];
    let i = 0;
    const len = item.length;
    while (i < len) {
      sortedArray.push(sortObj(item[i], ignoreProperties));
      i++;
    }
    return sortedArray as Omit<T, I>[];
  } else {
    return item;
  }
};

export const sortedObjectHash = <T extends AnyObject, I extends keyof T>(
  obj?: T,
  ignoreProperties: I[] = []
) => hash(sortObj(obj, ignoreProperties) ?? null);

export const uniformObjectHash = (obj: AnyObject | string, ignoreProperties: string[] = []) => {
  try {
    return typeof obj === 'string'
      ? sortedObjectHash(JSON.parse(obj), ignoreProperties)
      : sortedObjectHash(obj, ignoreProperties);
  } catch (e) {
    console.error('Could not uniformly hash object', obj, e);
    return hash(obj);
  }
};

/**
 * Maps an object similar to how an array might be mapped.
 * @param object - The source object to map
 * @param transform - A callback function that returns a new value result based on the source object's key and value
 * @returns A new object with the same keys as the source, but a transformed value.
 */
export const mapObject = <ResultValue, Key extends AnyKey, Value>(
  object: Partial<Record<Key, Value>>,
  transform: (key: Key, value: Value) => ResultValue
): Record<Key, ResultValue> => {
  const result: Partial<Record<Key, ResultValue>> = {};
  Object.entries(object).forEach((entry) => {
    const [key, value] = entry as [Key, Value];
    result[key] = transform(key, value);
  });
  return result as Record<Key, ResultValue>;
};

/**
 * Maps an original array into an object of keys and values.
 *
 * @param array - Any array of values
 * @param key - A callback the derives an object key from the value.
 * @param [transform] - An optional callback that transforms the value.
 * @returns An object mapped from the original array of values
 */
export const mapToObject = <Key extends AnyKey, Value, ResultValue = Value>(
  array: Value[],
  key: (value: Value) => Maybe<Key>,
  transform?: (value: Value) => ResultValue
): Record<Key, ResultValue> => {
  const result: Partial<Record<Key, ResultValue>> = {};
  array.forEach((value) => {
    const k = key(value);
    if (typeof k !== 'undefined' && k !== null)
      result[k] = transform?.(value) ?? (value as unknown as ResultValue);
  });
  return result as Record<Key, ResultValue>;
};

/**
 * Maps an original array into a `Map` of keys and values.
 *
 * @param array - Any array of values
 * @param key - A callback the derives an object key from the value.
 * @param [transform] - An optional callback that transforms the value.
 * @returns An object mapped from the original array of values
 */
export const mapToMap = <Key extends AnyKey, Value, ResultValue = Value>(
  array: Value[],
  key: (value: Value) => Maybe<Key>,
  transform?: (value: Value) => ResultValue
): Map<Key, ResultValue> => {
  const map = new Map<Key, ResultValue>();
  array.forEach((value) => {
    const k = key(value);
    if (typeof k !== 'undefined' && k !== null)
      map.set(k, transform?.(value) ?? (value as unknown as ResultValue));
  });
  return map;
};

export type CircularReplacer = (key: string, value: unknown) => string | unknown;
export type CircularValueResolver = (key: string, value: unknown) => string;

/**
 * Using `JSON.stringify` on an object will result in an error if there is a circular reference. However, it
 * takes a `replacer` as it's second param. This util makes a function that can be passed to that param to resolve.
 * ```ts
 * const circularReference = { otherData: 123 };
 * circularReference.myself = circularReference;
 * JSON.stringify(circularReference); // ❌ TypeError: cyclic object value
 * JSON.stringify(circularReference, makeCircularReplacer()); // ✔️ {"otherData":123, "myself": "[CIRCULAR]"}
 * ```
 * @param [replacement] - A string replacement or function that takes key/value to be replaced and returns a string. Default: "[CIRCULAR]"
 * @returns A function that can be passed to the second param of `JSON.stringify` to resolve circular references.
 */
export const makeCircularReplacer = (
  replacement: string | CircularValueResolver = '[CIRCULAR]'
): CircularReplacer => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return typeof replacement === 'string' ? replacement : replacement(key, value);
      }
      seen.add(value);
    }
    return value;
  };
};

/**
 * Verify JSON data and return the parsed object if valid without throwing an error.
 *
 * @param data
 * @param verifyPredicate
 * @param onError
 * @returns { valid: boolean, data: T | null }
 */
export const jsonVerify = <T extends object, E = any>(
  data: string,
  verifyPredicate: (d: T) => boolean = () => true,
  onError?: (e: E) => void
): { valid: boolean; data: T | null } => {
  try {
    const obj = JSON.parse(data) as T;
    return {
      valid: verifyPredicate(obj),
      data: obj
    };
  } catch (e) {
    onError && onError(e as E);
    return {
      valid: false,
      data: null
    };
  }
};

/**
 * Class used to allow calls to a dict, setting a default value when one does not exist.
 * Analogous to python's `defaultdict` object.
 * @param value The default value for the dict.
 */
export class DefaultDict<Value> {
  constructor(defaultVal: Value) {
    return new Proxy<Record<AnyKey, Value>>({} as Record<AnyKey, Value>, {
      get: (target, name) => (name in target ? target[name] : defaultVal) as Value
    });
  }
}

type GenerateSummaryReturn = { totalCount: number; topValues: TopValuesArray };

/**
 * Returns the sum of each value in the object, and the top values.
 * @param numberObject Number Object to sum and get top {@link topAmount} values of.
 * @param topAmount The amount of values to return, by value decreasing.
 * @returns Returns object containing the {@link totalCount} which contains sum of values in object,
 * and the {@link topAmount} amount of values to return.
 */
export const GenerateSummary = (
  numberObject: NumberValueObject,
  topAmount: number
): GenerateSummaryReturn => {
  let totalCount = 0;
  const valueArray: TopValuesArray = [];
  for (const key in numberObject) {
    totalCount += numberObject[key];
    valueArray.push([key, numberObject[key]]);
  }
  const topValues = valueArray.sort((a, b) => b[1] - a[1]);
  topValues.length = topValues.length > topAmount ? topAmount : topValues.length;
  return { totalCount, topValues };
};

// ---------------------------------------------------------------- /

type ProcessUpdateOutput<T extends Record<string, any>> = [updated: T, changedFields: UpdatesFor<T>];

/**
 * Applies selective updates to an object and returns a new object with
 * the requested changes applied.
 * > Note: This is a pure function and will not mutate the original.
 *
 * @param original - Any object to apply an update to
 * @param update - A partial of that type with changed values
 * @returns A tuple containing (1) a new object with the changes applied (The original will not be mutated) and (2) an object with the changed properties and original values.
 */
export const applyUpdate = <T extends Record<string, any>>(
  original: T,
  update: UpdatesFor<T>
): ProcessUpdateOutput<T> => {
  const updated = { ...original };
  const changedFields: UpdatesFor<T> = {};
  for (const key in update) {
    const originalValue = original[key];
    const changeValue = update[key];
    if (typeof changeValue === 'undefined') continue;
    if (changeValue === null) {
      delete updated[key];
    } else {
      updated[key] = changeValue;
    }
    changedFields[key] = originalValue;
  }
  return [updated, changedFields];
};

/**
 * Applies selective updates to an object and returns a new object with
 * the requested changes applied.
 * > Note: This is a pure function and will not mutate the original.
 *
 * @param original - Any object to apply an update to
 * @param update - An array of one or more partial objects of that type with changed values
 * @returns A tuple containing (1) a new object with the changes applied (The original will not be mutated) and (2) an object with the changed properties and original values.
 */
export const applyUpdates = <T extends Record<string, any>>(
  original: T,
  updates: UpdatesFor<T>[] = []
): ProcessUpdateOutput<T> =>
  updates.reduce<ProcessUpdateOutput<T>>(
    (acc, current) => {
      const [prevAcc, prevChangedFields] = acc;
      const [updated, changedFields] = applyUpdate(prevAcc, current);
      const mergedChangedFields = { ...changedFields, ...prevChangedFields };
      for (const key in mergedChangedFields) {
        if (mergedChangedFields[key] === updated[key]) delete mergedChangedFields[key];
      }
      return [updated, mergedChangedFields];
    },
    [original, {}]
  );

// ------------------------------------------------------------------ /

const isEqualValue = <T = unknown>(a: T, b: T): boolean => {
  if (typeof a !== 'object' || a === null) {
    return a === b;
  }
  const aObj = a as AnyObject;
  const bObj = b as AnyObject;

  if (isIdentifiable(aObj) && isIdentifiable(bObj)) {
    return aObj.id === bObj.id;
  }
  return isEqual(aObj, bObj);
};

/**
 * Compares two objects and shows the changed properties.
 *
 * @param original - The original object before the update.
 * @param current - The final mutated object after the update.
 * @returns An object with only the changed properties and new values.
 */
export const getObjectDiff = <T extends Record<string, any>>(original: T, current: T): UpdatesFor<T> => {
  const updated: UpdatesFor<T> = {};
  for (const key in { ...original, ...current }) {
    const currentValue = current[key];
    const previousValue = original[key];
    if (isEqualValue(previousValue, currentValue)) continue;
    // If there was a value that was removed, send `null` as signal to remove.
    if (previousValue && !currentValue) {
      updated[key] = null;
      continue;
    }
    updated[key] = currentValue;
  }
  return updated;
};

/**
 * Checks if a property is explicitly defined, even if `undefined` not just omitted.
 *
 * @param input - Any object
 * @param property - Any possible property of that object to test.
 * @returns A boolean stating that the property is explicitly defined (though could still be `undefined` or `null`)
 */
export const hasProperty = <T extends Record<AnyKey, unknown>>(input: T, property: keyof T): boolean =>
  Object.prototype.hasOwnProperty.call(input, property);

interface IsNotEmptyObjectOptions {
  considerNullAsAValue?: boolean;
}

/**
 * Check that an object has at least one defined value.
 *
 * @param input - Any object.
 * @param [options] - Optional flags and configurations
 * @param [options.considerNullAsAValue] - When true, `null` will be considered as a defined value. If omitted, it will be considered as a non-value.
 * @returns A boolean expressing that the object has at least one defined value.
 */
export const isNotEmptyObject = (
  input: Record<AnyKey, unknown>,
  options?: IsNotEmptyObjectOptions
): boolean => {
  const { considerNullAsAValue } = options ?? {};
  for (const value of Object.values(input)) {
    if (considerNullAsAValue) {
      if (typeof value !== 'undefined') return true;
    } else {
      if (typeof value !== 'undefined' && value !== null) return true;
    }
  }
  return false;
};

export const parseJSON = <T>(jsonString: string): T | null => {
  try {
    return JSON.parse(jsonString) as T;
  } catch (e) {
    return null;
  }
};

type OnChangeCallback<T extends Record<AnyKey, unknown>, R> = (current: T, previous: T) => R;

type DiffOptions<T extends Record<AnyKey, unknown>, R> = {
  onChange?: OnChangeCallback<T, R>;
  onNoChange?: OnChangeCallback<T, R>;
  customComparator?: typeof isEqual;
};

const getIsEqualFn = (customComparator?: typeof isEqual): typeof isEqual => {
  if (!customComparator) return isEqual;
  return customComparator;
};

export const diffObjectProperty = <T extends Record<AnyKey, unknown>, R>(
  previous: T,
  current: T,
  key: keyof T,
  options?: DiffOptions<T, R>
): R | undefined => {
  const { onChange, onNoChange, customComparator } = options || {};
  if (getIsEqualFn(customComparator)(current[key], previous[key])) {
    return onNoChange?.(current, previous);
  } else {
    return onChange?.(current, previous);
  }
};

export const getFromDynamicObject = <R>(
  object: Maybe<Record<string, unknown>>,
  key: string,
  predicate: (value: unknown) => value is R
): Optional<R> => {
  if (typeof object !== 'object' || object === null) return undefined;
  const value = object[key];
  return predicate(value) ? value : undefined;
};

export const getStringFromDynamicObject = (
  object: Maybe<Record<string, unknown>>,
  key: string
): Optional<string> =>
  getFromDynamicObject(object, key, (value): value is string => typeof value === 'string');
