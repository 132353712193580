import { type TradeInfoFragment } from '@oms/generated/frontend';
import {
  sharedDateTimeCol,
  sharedDefaultCol,
  sharedIdCol,
  sharedPriceCol,
  sharedQuantityCol,
  sharedTextCol,
  sharedUserCol
} from '@app/common/grids/columns/generic-cols';
import { type ColumnBuilderCallback, type ColumnLibrary } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import {
  sharedCapacityCol,
  sharedSettleDateCol,
  sharedSettleTypeCol,
  sharedSideOnlyCol
} from '@app/common/grids/columns/order-cols';

const defaultCol: ColumnBuilderCallback<TradeInfoFragment> = (c) => sharedDefaultCol<TradeInfoFragment>(c);

const idCol: ColumnBuilderCallback<TradeInfoFragment> = (c) => sharedIdCol(c, 'id').hide();

const tradePriceCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedPriceCol(c, 'tradePrice').header(t('app.trades.tradePrice')).format('price-trade');

const sideCol: ColumnBuilderCallback<TradeInfoFragment> = (c) => sharedSideOnlyCol(c);

const capacityCol: ColumnBuilderCallback<TradeInfoFragment> = (c) => sharedCapacityCol(c);

const locateCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedTextCol(c, 'tradingOrder.locate')
    .header(t('app.trades.locate'))
    .shortHeader(t('app.trades.locate', { ns: 'short' }));

const tradeQuantityCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedQuantityCol(c, 'tradeQuantity').header(t('app.trades.tradeQuantity'));

const tradeCounterpartyIdCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedIdCol(c, 'tradeCounterpartyId').header(t('app.trades.tradeCounterpartyId')).width(168);

const lastMarketCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedTextCol(c, 'lastMarket').header(t('app.trades.lastMarket'));

const tradeDateTimeCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedDateTimeCol(c, 'tradeDateTime').header(t('app.trades.tradeDateTime'));

const createdDateTimeCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedDateTimeCol(c, 'createdDateTime').header(t('app.trades.createdDateTime'));

const updatedDateTimeCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedDateTimeCol(c, 'updatedDateTime').header(t('app.trades.updatedDateTime'));

const enteredByCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedUserCol(c, 'enteredBy').header(t('app.trades.enteredBy'));

const rawLiquidityIndicatorCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedTextCol(c, 'rawLiquidityIndicator')
    .header(t('app.trades.rawLiquidityIndicator'))
    .shortHeader(t('app.trades.rawLiquidityIndicator', { ns: 'short' }));

const normalizedLiquidityIndicatorCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedTextCol(c, 'normalizedLiquidityIndicator')
    .header(t('app.trades.normalizedLiquidityIndicator'))
    .shortHeader(t('app.trades.normalizedLiquidityIndicator', { ns: 'short' }));

const statusCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedTextCol(c, 'status').header(t('app.trades.status'));

const tradeIdCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedIdCol(c, 'id').header(t('app.trades.id')).hide();

const settleTypeCol: ColumnBuilderCallback<TradeInfoFragment> = (c) => sharedSettleTypeCol(c);

const settleDateCol: ColumnBuilderCallback<TradeInfoFragment> = (c) => sharedSettleDateCol(c);

const tradeContraAccountCol: ColumnBuilderCallback<TradeInfoFragment> = (c) =>
  sharedTextCol(c, 'tradeContraAccount.name')
    .header(t('app.trades.tradeContraAccount'))
    .shortHeader(t('app.trades.tradeContraAccount', { ns: 'short' }))
    .width(180);

export const tradingOrderTradesColumnLibrary: ColumnLibrary<TradeInfoFragment> = {
  defaultColumn: defaultCol,
  columns: [
    idCol,
    tradePriceCol,
    locateCol,
    sideCol,
    tradeQuantityCol,
    capacityCol,
    tradeCounterpartyIdCol,
    tradeContraAccountCol,
    lastMarketCol,
    tradeDateTimeCol,
    createdDateTimeCol,
    updatedDateTimeCol,
    enteredByCol,
    rawLiquidityIndicatorCol,
    normalizedLiquidityIndicatorCol,
    statusCol,
    tradeIdCol,
    settleTypeCol,
    settleDateCol
  ]
};
