import type { RxCollectionCreator } from 'rxdb-v15';
import {
  EXAMPLE_LAYOUT_COLLECTION_NAME,
  EXAMPLE_LAYOUT_COLLECTION,
  type ExampleLayoutCollection
} from './collections/example-layout.collection';
import {
  snapshotSchema,
  SNAPSHOT_COLLECTION_NAME,
  type SnapshotCollection
} from './collections/snapshots.collection';
import {
  WINDOW_CONTEXTS_COLLECTION_NAME,
  windowContextsSchema,
  type WindowContextsCollection
} from './collections/window-contexts.collection';
import {
  gridTemplateSchema,
  GRID_TEMPLATE_COLLECTION_NAME,
  type GridTemplateCollection
} from './collections/grid-templates.collection';
import { gridsSchema, GRIDS_COLLECTION_NAME, type GridsCollection } from './collections/grids.collection';
import {
  ADDITIONAL_FIELDS_COLLECTION_NAME,
  ADDITIONAL_FIELDS_COLLECTION,
  type AdditionalFieldsCollection
} from './collections/additional-fields.collection';
import { MONTAGE_COLLECTION_NAME } from './collections/montage/montage.collection.constants';
import MONTAGE_COLLECTION from './collections/montage/montage.collection';
import type { MontageCollection } from './collections/montage/montage.collection.types';
import {
  ACTION_COLLECTION,
  ACTION_COLLECTION_NAME,
  type ActionsCollection
} from '@app/actions/collections/action.collection';

export const OFFLINE_DB_COLLECTIONS_MAP: Record<string, RxCollectionCreator<any>> = {
  [SNAPSHOT_COLLECTION_NAME]: {
    schema: snapshotSchema
  },
  [EXAMPLE_LAYOUT_COLLECTION_NAME]: EXAMPLE_LAYOUT_COLLECTION,
  [ADDITIONAL_FIELDS_COLLECTION_NAME]: ADDITIONAL_FIELDS_COLLECTION,
  [MONTAGE_COLLECTION_NAME]: MONTAGE_COLLECTION,
  [GRID_TEMPLATE_COLLECTION_NAME]: {
    schema: gridTemplateSchema
  },
  [GRIDS_COLLECTION_NAME]: {
    schema: gridsSchema
  },
  [WINDOW_CONTEXTS_COLLECTION_NAME]: {
    schema: windowContextsSchema
  },
  [ACTION_COLLECTION_NAME]: ACTION_COLLECTION as RxCollectionCreator<any>
};

export type OfflineDatabaseCollections = {
  [SNAPSHOT_COLLECTION_NAME]: SnapshotCollection;
  [EXAMPLE_LAYOUT_COLLECTION_NAME]: ExampleLayoutCollection;
  [ADDITIONAL_FIELDS_COLLECTION_NAME]: AdditionalFieldsCollection;
  [MONTAGE_COLLECTION_NAME]: MontageCollection;
  [ACTION_COLLECTION_NAME]: ActionsCollection;
  [GRID_TEMPLATE_COLLECTION_NAME]: GridTemplateCollection;
  [GRIDS_COLLECTION_NAME]: GridsCollection;
  [WINDOW_CONTEXTS_COLLECTION_NAME]: WindowContextsCollection;
};

export type OfflineDatabaseCollectionType = keyof OfflineDatabaseCollections;
