import { useEffect } from 'react';
import {
  type InvestorOrderStatus,
  ModificationType,
  type VisibleInvestorOrderInfoWithAllocationsFragment,
  type VisibleModificationInfoFragment
} from '@oms/generated/frontend';
import { Box, Spinner, type Sprinkles, VStack } from '@oms/shared-frontend/ui-design-system';
import { PENDING_MODIFICATIONS } from './pending-modifications.grid.widget';
import { useGridSelectionEvent } from '@app/data-access/memory/grid.events';
import { useUpdatedInvestorOrder } from '@app/data-access/hoc/with-updated-investor-order';
import { InvestorOrderDetailsSummary } from '../investor-order-view/investor-order-view.order-details.widget';
import { InvestorOrderDetailsSummaryOfChanges } from './pending-modifications.summary-of-changes';

const applyRequestedModificationsToInvestorOrder = (
  pendingModification: VisibleModificationInfoFragment,
  investorOrder: VisibleInvestorOrderInfoWithAllocationsFragment
): VisibleInvestorOrderInfoWithAllocationsFragment => {
  const modifiedInvestorOrder = structuredClone(investorOrder);

  Object.entries(pendingModification.requested ?? {}).forEach(([key, value]) => {
    if (key === 'price') {
      key = 'limitPrice';
    }
    modifiedInvestorOrder[key as keyof VisibleInvestorOrderInfoWithAllocationsFragment] = value;
  });
  // Although Cancel / Modify is not an order status, we want to show the status of the
  // modification instead of the investor order so we override the status for presentation
  // I would have used tsignore but linting complains :/ and FormSummary displays it correctly.
  modifiedInvestorOrder.status = ((pendingModification.requests ?? []).some(
    (request) => request?.type === ModificationType.Cancel
  )
    ? ModificationType.Cancel
    : ModificationType.Modify) as unknown as InvestorOrderStatus;

  return modifiedInvestorOrder;
};

const sx: Sprinkles = {
  paddingTop: 5,
  backgroundColor: 'layout.level2',
  height: 'full',
  overflowY: 'auto'
};

const textSx: Sprinkles = {
  ...sx,
  padding: 5,
  display: 'flex',
  flexDirection: 'column',
  placeContent: 'center',
  textAlign: 'center'
};

const noPaddingTop: Sprinkles = {
  ...sx,
  paddingTop: 0
};

const PendingModificationsSidePanelWidget = () => {
  const selectedRowsEvent = useGridSelectionEvent<
    VisibleModificationInfoFragment,
    { pendingModificationId: string | null }
  >(PENDING_MODIFICATIONS, 'scoped');
  const pendingModification = selectedRowsEvent?.payload.selectedRows[0];
  const investorOrderId = pendingModification?.investorOrderId;
  const [investorOrder, isLoading, error, setInvestorOrderId] = useUpdatedInvestorOrder(investorOrderId);

  const IOWithModifications =
    pendingModification && investorOrder
      ? applyRequestedModificationsToInvestorOrder(pendingModification, investorOrder)
      : undefined;

  useEffect(() => {
    setInvestorOrderId(investorOrderId);
  }, [investorOrderId]);

  switch (true) {
    case !pendingModification:
      return <Box sx={textSx}>Please select a modification from pane on the left. </Box>;

    case isLoading || !IOWithModifications:
      return <Spinner fillArea bgColor={sx.backgroundColor} />;

    case !!error:
      // This includes the case of order not found; InvestorOrdersSubscriptionService.track
      // treats an empty response when filtering on id to be an error.
      return <Box sx={textSx}>Error: {error?.message}</Box>;

    case !!IOWithModifications:
      return (
        <VStack sx={noPaddingTop}>
          <InvestorOrderDetailsSummaryOfChanges
            currentIO={investorOrder ?? {}}
            newIO={IOWithModifications ?? {}}
          />
          {/* Using ! since typescript can't understand IOWithModifications can't be undefined here */}
          <InvestorOrderDetailsSummary investorOrder={IOWithModifications!} />
        </VStack>
      );
  }
};

export default PendingModificationsSidePanelWidget;
