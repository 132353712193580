import { TradingOrdersService } from '@app/data-access/services/trading/trading-orders/trading-orders.service';
import { FORM_EVENT_TYPE, FORM_RENDERER_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import type {
  RouteOrderFormInput,
  RouteOrderFormOutput
} from '@app/widgets/trading/route-order/route-order.form-common';
import type {
  RouteOrderFormContractType,
  RouteOrderFormValues
} from '@app/widgets/trading/route-order/route-order.form-contract';
import { routeOrderContract } from '@app/widgets/trading/route-order/route-order.form-contract';
import {
  type OrderRouteCreateInput,
  type ModifyTradingOrderInput,
  DestinationType,
  OrderSettleType
} from '@oms/generated/frontend';
import { RouteOrderService } from '@app/data-access/services/trading/routing/route-order.service';
import {
  getOrderInfoFromInput,
  getInitialCommonRouteModifyFieldValues,
  sanitizeFormValuesToCommonOutput,
  getInitialCreateOnlyFieldValues,
  getInitialModifyOnlyFieldValues
} from './route-order.form-sanitizers';
import {
  createStrategyComboboxItem,
  createStrategyInput
} from './fixatdl-strategy-field/fixatdl-strategy-field.util';
import { createVenueComboboxItem } from '@app/forms/common/fields/venue-field/venue.field.common';
import { tifOutput } from '@app/forms/common/fields/TIF-field/TIF-field.common';
import { createAccountComboboxItem } from '@app/forms/common/fields/account-field/account.field.common';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

/**
 * Route Order Form Builder
 * - Create Trading Order
 * - Modify Trading Order
 * - Route Investor Order
 */
export const routeOrderBuilder = FormBuilder.create<RouteOrderFormInput, RouteOrderFormOutput>('route-order')
  .contract<RouteOrderFormContractType>(routeOrderContract)
  .type('route-order')
  .sanitizer((s) =>
    s
      .input(async function sanitize(input, ctx) {
        const orderInfo = await getOrderInfoFromInput(input, ctx.container);

        let orderFormValues: Partial<RouteOrderFormValues> = {};

        switch (orderInfo.type) {
          case 'route': {
            orderFormValues = {
              ...getInitialCommonRouteModifyFieldValues(orderInfo.investorOrder)
            };

            break;
          }
          case 'modify': {
            orderFormValues = {
              ...getInitialCommonRouteModifyFieldValues(orderInfo.tradingOrder),
              ...getInitialModifyOnlyFieldValues(orderInfo)
            };

            break;
          }
          case 'create': {
            if (input.initialCreateOptions) {
              orderFormValues = getInitialCreateOnlyFieldValues(input.initialCreateOptions);

              if (input.initialCreateOptions.venueId) {
                orderFormValues.venue = createVenueComboboxItem({
                  executionVenueDescription: input.initialCreateOptions.venueId,
                  id: input.initialCreateOptions.venueId
                });

                if (input.initialCreateOptions.strategy) {
                  orderFormValues.strategy = createStrategyComboboxItem(
                    input.initialCreateOptions.strategy,
                    input.initialCreateOptions.venueId
                  );
                }
              }
            }

            // defaults
            if (!orderFormValues.settlementType) {
              orderFormValues.settlementType = OrderSettleType.Regular;
            }

            if (!orderFormValues.firmAccount) {
              const authService = ctx.container.resolve(AuthService);
              const userId = authService.getUserId() || undefined;

              if (userId) {
                const tradingOrderService = ctx.container.resolve(TradingOrdersService);
                const firmAccount = await tradingOrderService.getDefaultFirmAccount(userId);
                if (firmAccount) {
                  orderFormValues.firmAccount = createAccountComboboxItem(firmAccount);
                }
              }
            }

            break;
          }
        }

        orderFormValues.hiddenFormInfo = orderInfo;

        return orderFormValues;
      })
      .output(function sanitize(formValues) {
        const { hiddenFormInfo } = formValues;
        // Sanitize form values to common output for create, modify, and route
        const {
          destinationType,
          customerNotes,
          venueNotes,
          instrument,
          limitPrice,
          locate,
          orderTags,
          orderType,
          quantity,
          sideType,
          venueDestination
        } = sanitizeFormValuesToCommonOutput(formValues);

        // Don't return an output if any of the required fields are missing
        // We do this, because ON_SANITIZE is called on every change and also contains "output" / "undefined"
        // However, we need to allow a missing destination type through as it needs to be picked up
        // in SANITIZED_VALUES_CHANGED so that the errors can be cleared if venue gets removed
        if (!instrument) return;
        if (!sideType) return;
        if (!quantity) return;
        if (!hiddenFormInfo) return;
        if (!venueDestination && hiddenFormInfo.type !== 'modify') return;

        const commonOutput = {
          quantity,
          limitPrice,
          orderType,
          orderTags,
          locate,
          ...tifOutput(formValues)
        };

        // Handle output for create, modify, and route
        switch (hiddenFormInfo.type) {
          case 'create': {
            const investorOrderIds = formValues.matchedInvestorOrderIds || [];
            const strategyValue = formValues?.strategy?.value;
            const tradingAccount = formValues.firmAccount
              ? {
                  id: formValues.firmAccount.id
                }
              : undefined;

            // Add CreateTradingOrderOutput type when BE integrates gtdUseMarketEndTime with TradingOrderInput.
            const output = {
              investorOrderIds,
              tradingOrder: {
                ...commonOutput,
                instrument,
                sideType,
                destinationType,
                venueDestination,
                tradingAccount,
                settleDate: formValues.settlementDate || undefined,
                settleType: formValues.settlementType,
                strategy: createStrategyInput(strategyValue),
                category: hiddenFormInfo?.options?.category,
                venueNotes,
                customerNotes
              }
            };

            return {
              type: 'create',
              output
            };
          }
          case 'modify': {
            const { tradingOrder } = hiddenFormInfo;
            const strategyValue = formValues?.strategy?.value;
            const output: ModifyTradingOrderInput = {
              ...commonOutput,
              id: tradingOrder.id,
              customerNotes,
              venueNotes,
              representativeCode: formValues.repCode?.value?.id
                ? { id: formValues.repCode.value.id }
                : undefined,
              settleDate: formValues.settlementDate || undefined,
              settleType: formValues.settlementType,
              strategy: createStrategyInput(strategyValue)
              // TODO: Investor Order?
            };
            return {
              type: 'modify',
              output
            };
          }
          case 'route': {
            const { investorOrder } = hiddenFormInfo;
            // Use a separate variable for orderType to avoid overwriting a sanitized form orderType needed for output
            const originalOrderType = investorOrder.orderType;
            const orderId = hiddenFormInfo.investorOrder?.id;
            const { venue, trader } = formValues || {};

            if (!originalOrderType || !orderId) throw new Error('Order Type & Order ID are required');

            const destinationType =
              venue?.value === DestinationType.Trader || venue?.value === DestinationType.PrimaryTrader
                ? venue?.value
                : DestinationType.Venue;

            const destinationId: string | undefined | null =
              destinationType === DestinationType.Venue
                ? venue?.value
                : destinationType === DestinationType.Trader
                  ? trader?.value
                  : undefined;
            const strategyValue = formValues?.strategy?.value;
            const output: OrderRouteCreateInput = {
              ...commonOutput,
              orderId,
              destinationType,
              destinationId,
              customerNotes,
              venueNotes,
              strategy: createStrategyInput(strategyValue)
              // TODO: Investor Order?
            };
            return {
              type: 'route',
              output
            };
          }
        }
      })
  )
  .effect(({ formApi, container }) => {
    let prevInstrument: RouteOrderFormValues['instrument'] | undefined;
    let defaultFirmAccountId: string = '';

    // If the form is in create mode, we don't need to do anything
    if (formApi?.initialValues?.hiddenFormInfo?.type === 'create') {
      const subscriptionInstrument = formApi.get$({ fields: ['instrument'], values: true }).subscribe((e) => {
        if (prevInstrument?.id !== e.values.instrument?.id) {
          const authService = container.resolve(AuthService);
          const userId = authService.getUserId() || undefined;
          const instrumentId = e.values.instrument?.id || undefined;

          if (userId || instrumentId) {
            const tradingOrderService = container.resolve(TradingOrdersService);
            tradingOrderService
              .getDefaultFirmAccount(userId, instrumentId)
              .then((firmAccount) => {
                if (!e.values.firmAccount || e.values.firmAccount.id === defaultFirmAccountId) {
                  formApi.change(
                    'firmAccount',
                    firmAccount ? createAccountComboboxItem(firmAccount) : undefined
                  );
                  defaultFirmAccountId = firmAccount?.id || '';
                }
              })
              .catch(() => {});
          }
        }
        prevInstrument = e.values.instrument;
      });

      return () => {
        subscriptionInstrument.unsubscribe();
      };
    }
    // Re-evaluate strategy on instrument change
    const subscription = formApi.get$({ fields: ['instrument'], values: true }).subscribe((e) => {
      if (prevInstrument?.id !== e.values.instrument?.id) {
        prevInstrument?.id && formApi.change('venue', undefined);
        prevInstrument = e.values.instrument;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  })
  .change(async (event, ctx) => {
    const routeOrderService = ctx.container.resolve(RouteOrderService);
    const tradingOrderService = ctx.container.resolve(TradingOrdersService);

    switch (event.type) {
      case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED: {
        const output = event.payload.output;
        if (!output) {
          break;
        }
        switch (output.type) {
          case 'create': {
            const result = await tradingOrderService.create(
              {
                tradingOrder: output.output.tradingOrder,
                investorOrderIds: output.output.investorOrderIds
              },
              { dryRun: true }
            );

            if (result.isSuccess()) {
              const response = result.value.data?.addTradingOrder;
              const fieldValues = await response?.enrichedData;

              if (fieldValues) {
                ctx.notify({
                  type: FORM_RENDERER_EVENT_TYPE.SET_FIELD_VALUES,
                  payload: {
                    fieldValues: { locate: fieldValues?.locate ?? undefined }
                  }
                });
              }
            }
            return result;
          }
          case 'modify': {
            return await tradingOrderService.modify(output.output, { dryRun: true });
          }
          case 'route': {
            return await routeOrderService.routeInvestorOrder(output.output, { dryRun: true });
          }
        }
        break;
      }
      case FORM_EVENT_TYPE.SUBMIT: {
        const output = event.payload.output;
        switch (output.type) {
          case 'create': {
            return await tradingOrderService.create({
              tradingOrder: output.output.tradingOrder,
              investorOrderIds: output.output.investorOrderIds
            });
          }
          case 'modify': {
            return await tradingOrderService.modify(output.output);
          }
          case 'route': {
            return await routeOrderService.routeInvestorOrder(output.output);
          }
        }
      }
    }
  });

export default routeOrderBuilder;
export type RouteOrderFormBuilderType = typeof routeOrderBuilder;
