import { useMemo } from 'react';
import { Box, Text, Divider } from '@oms/shared-frontend/ui-design-system';
import {
  ApplyTo,
  ChargeType,
  RateType,
  type VisibleInvestorOrderInfoWithAllocationsFragment
} from '@oms/generated/frontend';
import {
  APP_DISPLAY_FIELD_COMPONENT_TYPE,
  AppDisplayGrid,
  type AppDisplayGridProps
} from '@oms/frontend-foundation';
import {
  gridPopoverChargeScale,
  gridPopoverChargeScaleProps,
  gridPopoverChargeSchedule,
  gridPopoverChargeScheduleProps
} from '@app/common/types/charges/charges.utils';
import type { ChargeRow } from '@app/common/types/charges/charges.types';

const getDisplayGridFeeItems = (rows: ChargeRow[] | undefined): AppDisplayGridProps['items'] => {
  if (!rows) return [];

  return rows.flatMap(({ charge, chargeSchedule, amount }) => {
    const gridProps =
      chargeSchedule?.rateType === RateType.Scale
        ? gridPopoverChargeScaleProps
        : gridPopoverChargeScheduleProps;

    const items =
      chargeSchedule?.rateType === RateType.Scale
        ? gridPopoverChargeScale({
            chargeName: charge?.name ?? undefined,
            scaleName: chargeSchedule?.scaleSchedule?.name ?? undefined,
            basis: chargeSchedule?.scaleSchedule?.scale?.basis ?? undefined,
            rateType: chargeSchedule?.scaleSchedule?.scale?.rateType ?? undefined,
            rows: chargeSchedule?.scaleSchedule?.scale?.rows ?? undefined
          })
        : gridPopoverChargeSchedule({
            chargeName: charge?.name ?? undefined,
            rateType: chargeSchedule?.rateType ?? undefined,
            rateValue: chargeSchedule?.rateValue ?? undefined,
            min: chargeSchedule?.minimumChargeValue ?? undefined,
            max: chargeSchedule?.maximumChargeValue ?? undefined
          });

    return [
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: charge?.name || ''
        },
        testId: charge?.name || ''
      },
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.InWindowPopover,
          popoverContent: {
            gridProps,
            items,
            style: { minWidth: '21rem' },
            hasClose: false,
            testId: 'io-view-charges-summary-fees-popover'
          },
          value: {
            component: {
              type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Link,
              text: chargeSchedule?.name || ''
            },
            testId: chargeSchedule?.name || ''
          }
        }
      },
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
          format: 'price',
          value: amount || ''
        },
        testId: amount || ''
      }
    ];
  }) as AppDisplayGridProps['items'];
};

const gridFeeProps: AppDisplayGridProps['gridProps'] = {
  columns: 3,
  rows: 1,
  columnGap: 10,
  rowGap: 3
};

const getDisplayGridMarketItems = (rows: ChargeRow[] | undefined): AppDisplayGridProps['items'] => {
  if (!rows) return [];

  return rows.flatMap(({ charge, amount }) => [
    {
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: charge?.name || ''
      }
    },
    {
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price',
        value: amount || '' // TODO: not yet available
      }
    }
  ]);
};

const gridMarketProps: AppDisplayGridProps['gridProps'] = {
  columns: 2,
  rows: 1,
  columnGap: 10,
  rowGap: 3
};

// A placeholder until charges are moved to orders in a way we can just read
// them as ChargeRow[] and get subscription for free.
const notImplementedYet: ChargeRow = {
  charge: {
    id: '123',
    name: 'To be implemented',
    type: ChargeType.Other,
    applyTo: ApplyTo.InvestorOrder,
    description: 'TODO',
    __typename: 'Charge'
  }
};
const marketChargesData = [notImplementedYet];
const feeData = [notImplementedYet];

export const ChargesSummary = ({
  investorOrder
}: {
  investorOrder: VisibleInvestorOrderInfoWithAllocationsFragment;
}) => {
  const displayGridFeeItems = useMemo(() => {
    return getDisplayGridFeeItems(feeData);
  }, [feeData, investorOrder]);

  const displayGridMarketItems = useMemo(() => {
    return getDisplayGridMarketItems(marketChargesData);
  }, [marketChargesData, investorOrder]);

  return (
    <Box
      sx={{ padding: 5, display: 'flex', flexDirection: 'row', columnGap: 24 }}
      data-testid="io-view-charges-summary"
    >
      <Box
        sx={{
          display: 'flex',
          width: 'half',
          flexDirection: 'column',
          rowGap: 3
        }}
      >
        <Text>Fees</Text>
        <AppDisplayGrid items={displayGridFeeItems} gridProps={gridFeeProps} labelSize="small" />
      </Box>
      <Divider sx={{ height: 'auto' }} orientation="vertical" />
      <Box sx={{ display: 'flex', width: 'half', flexDirection: 'column', rowGap: 3 }}>
        <Text>Market Charges</Text>
        <AppDisplayGrid items={displayGridMarketItems} gridProps={gridMarketProps} labelSize="small" />
      </Box>
    </Box>
  );
};
