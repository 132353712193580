import { openConfirmation } from '@app/generated/sdk';
import { type FoundationWorkspace, useWorkspace } from '@oms/frontend-foundation';
import { dialog } from '@tauri-apps/api';
import { PROCESS_ID, isTauri } from '@valstro/workspace';
import { useCallback } from 'react';

export async function openMessageDialog<T extends FoundationWorkspace>(
  messageCtx: string | { message: string; title: string },
  workspace?: T
) {
  const { title, message } =
    typeof messageCtx === 'string' ? { title: 'Alert', message: messageCtx } : messageCtx;

  try {
    if (!workspace) {
      openSystemDialog(message);
      return;
    }
    await openConfirmation(workspace, PROCESS_ID.LEADER, {
      title,
      componentProps: {
        autoClose: true,
        hideCancelButton: true,
        message,
        confirmButtonText: 'Ok'
      }
    });
  } catch (e) {
    console.error(`Error opening dialog. Using system.`, e);
    openSystemDialog(message);
  }
}

function openSystemDialog(message: string) {
  if (isTauri()) {
    dialog.message(message).catch((e) => {
      console.error(`Error opening tauri dialog. Using browser alert.`, e);
      alert(message);
    });
  } else {
    alert(message);
  }
}

export function useOpenMessageDialog() {
  const workspace = useWorkspace();
  return useCallback(
    (messageCtx: string | { message: string; title: string }) => openMessageDialog(messageCtx, workspace),
    [workspace]
  );
}
