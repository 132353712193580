import {
  type ReactActorComponentProps,
  ReactTauriWindowActorSchemaBuilder,
  ReactTauriWindowView
} from '@valstro/workspace-react';
import { CommonAuthWindowComponent, commonAuthWindowSchemaOverrides } from './auth-window.common.actor';
import { COMMON_PLATFORM_NAME } from '@valstro/workspace';
import { appWindow } from '@tauri-apps/api/window';
import {
  type AuthWindowContext,
  type AuthWindowOperations,
  COMMON_AUTH_WINDOW,
  type CommonAuthWindowActorSchema,
  type CommonAuthWindowActorSchemaOptions
} from '@app/common/auth/auth.contracts';

export const TAURI_AUTH_WINDOW_ACTOR_NAME = 'tauri-auth-window';

const View: React.FC<ReactActorComponentProps<CommonAuthWindowActorSchema>> = ({ actor }) => {
  return (
    <ReactTauriWindowView<CommonAuthWindowActorSchema> actor={actor}>
      <CommonAuthWindowComponent actor={actor} />
    </ReactTauriWindowView>
  );
};

const TauriAuthWindowActorSchemaBuilder = ReactTauriWindowActorSchemaBuilder.extend<
  AuthWindowContext,
  AuthWindowOperations
>((prevSchema) => ({
  ...commonAuthWindowSchemaOverrides(prevSchema),
  initialContext: async (ctx) => ({
    ...(await prevSchema.initialContext(ctx)),
    width: 800,
    height: 700,
    minWidth: 700,
    minHeight: 600,
    isClosable: true,
    alwaysOnTop: true,
    isDecorated: true
  }),
  type: COMMON_AUTH_WINDOW.TYPE,
  name: TAURI_AUTH_WINDOW_ACTOR_NAME,
  supportedPlatforms: [COMMON_PLATFORM_NAME.TAURI],
  selectStrategy: (ctx) => {
    // When selecting the root actor, select this actor if the tauri window ID matches the pre-defined auth window ID
    if (ctx.location === 'root' && ctx.platformName === COMMON_PLATFORM_NAME.TAURI) {
      return appWindow.label === COMMON_AUTH_WINDOW.ID;
    }

    return true;
  }
})).extendView(View);

export const tauriAuthWindowActor =
  TauriAuthWindowActorSchemaBuilder.optionsCreator<CommonAuthWindowActorSchemaOptions>();

export type TauriAuthWindowActorSchema = typeof TauriAuthWindowActorSchemaBuilder.schema;
