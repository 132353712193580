import {
  hiddenFormInfo,
  hiddenStrategyOptions,
  instrument,
  venue,
  sideType,
  quantity,
  timeInForce,
  gtdTime,
  gtdDate,
  gtdTimestamp,
  tifDuration,
  locate,
  strategy,
  limitPrice,
  trader,
  enrichedTrader,
  customerNotes,
  tradeCurrency,
  repCode,
  settlement,
  orderTags,
  matchedInvestorOrderIds,
  enrichedLocate,
  enrichedTradeCurrency,
  enrichedRepCode,
  enrichedSettlement,
  enrichedCustomerNotes,
  enrichedOrderTags,
  venueNotes,
  firmAccount,
  showOnTOFormCondition
} from '@app/widgets/trading/route-order/route-order.form-fields';
import type { RouteOrderFieldContract } from '@app/widgets/trading/route-order/route-order.form-common';
import { makeTIFSplitDisplayFields } from '@app/forms/common/fields/TIF-field/TIF-field.common';
import {
  FieldDefinition,
  FormContract,
  type InferFormValuesFromFormContract
} from '@oms/frontend-foundation';
import {
  createAdditionalAllFields,
  createEnrichedField
} from '@app/forms/form-builder/fields/additional-fields/additional-fields.common';
import { createMarketDataField } from '@app/forms/form-builder/fields/market-data-field/market-data-field.common';

export const routeOrderContract = FormContract.create<RouteOrderFieldContract>()
  .fields({
    hiddenFormInfo,
    hiddenStrategyOptions,
    instrument,
    venue,
    trader,
    sideType,
    quantity,
    timeInForce,
    gtdTime,
    gtdDate,
    gtdTimestamp,
    tifDuration,
    locate,
    strategy,
    limitPrice,
    // route specific fields
    customerNotes,
    venueNotes,
    tradeCurrency,
    repCode,
    settlementType: settlement.settlementType,
    settlementDate: settlement.settlementDate,
    orderTags,
    matchedInvestorOrderIds,
    firmAccount
  })
  .schema((f) => {
    const settlementContainer = settlement.settlementContainer.build();
    return {
      fields: [
        createMarketDataField('market-data', {
          instrumentFieldName: 'instrument',
          trackableFieldMap: {
            bid: 'limitPrice',
            ask: 'limitPrice'
          }
        }),
        // Runway
        FieldDefinition.runway('runway', [
          { ...f.instrument, isPrimaryField: true, style: { minWidth: 100 } },
          { ...f.sideType, isPrimaryField: true, style: { minWidth: 100 } },
          { ...f.quantity, isPrimaryField: true, style: { minWidth: 100 } },
          { ...f.limitPrice, isPrimaryField: true, style: { minWidth: 100 } }
        ]),
        // Additional fields / all fields
        createAdditionalAllFields<RouteOrderFieldContract>({
          groups: ['Order', 'Details', 'Settle'],
          fixedFields: ['venue', 'trader', settlementContainer.name, 'strategy', 'locate'],
          fields: [
            [createEnrichedField(f.firmAccount, { groupName: 'Order', condition: showOnTOFormCondition })],
            [createEnrichedField(f.venue, { groupName: 'Order' })],
            [enrichedTrader],
            [enrichedSettlement],
            [createEnrichedField(f.strategy, { groupName: 'Order' })],
            [
              createEnrichedField(
                makeTIFSplitDisplayFields('timeInForceGroup', f.timeInForce, {
                  gtdDate: f.gtdDate,
                  gtdTime: f.gtdTime,
                  tifDuration: f.tifDuration
                }),
                { groupName: 'Order', colSpan: 2 }
              )
            ],
            [enrichedLocate],
            [enrichedTradeCurrency],
            [enrichedRepCode],
            [enrichedOrderTags],
            [createEnrichedField(f.venueNotes, { groupName: 'Details' })],
            [enrichedCustomerNotes]
          ]
        }),
        // Mappable IOs
        {
          component: 'section',
          name: 'mappable-investor-orders',
          sx: {
            backgroundColor: 'transparent'
          },
          fields: [[f.matchedInvestorOrderIds]]
        }
      ]
    };
  })
  .template('simple', { showSubmitButton: false });

export type RouteOrderFormContractType = typeof routeOrderContract;

export type RouteOrderFormValues = InferFormValuesFromFormContract<RouteOrderFormContractType>;
