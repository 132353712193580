import type { ComponentType } from 'react';
import type { VisibleTradingOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { withUpdatedData, type UpdatedData, type UpdatedDataProps } from './with-updated-data';
import { TradingOrdersSubscriptionService } from '@app/data-access/services/trading/trading-orders/trading-orders.subscriptions.service';

export type UpdatedTradingOrder = UpdatedData<VisibleTradingOrderInfoWithAllocationsFragment>;

export const withUpdatedTradingOrder = (
  Component: ComponentType<UpdatedTradingOrder>,
  props: UpdatedDataProps = {}
) => {
  return withUpdatedData<VisibleTradingOrderInfoWithAllocationsFragment>(
    Component,
    TradingOrdersSubscriptionService,
    props
  );
};
