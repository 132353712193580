import { BehaviorSubject } from 'rxjs';
import { AllActionContextEventsRecord, type AllActionContextEvents } from './types';
import { type ActionCommands } from '../commands/command.registry.types';

export class ButtonConfigSubject extends BehaviorSubject<AllActionContextEventsRecord> {
  constructor(startingState?: AllActionContextEventsRecord) {
    const defaultState: AllActionContextEventsRecord = {
      create_unbound_to: undefined,
      montage_bump_price: undefined,
      montage_target_trading_order: undefined
    };
    super(startingState || defaultState);
  }

  public forCommand(event: AllActionContextEvents): void {
    const currentState = this.getValue();
    this.next({ ...currentState, [event.commandId]: { ...currentState[event.commandId], ...event.payload } });
  }

  public forCommands(...events: AllActionContextEvents[]): void {
    events.forEach((e) => this.forCommand(e));
  }

  public getCommand<TCommand extends ActionCommands>(
    commandId: TCommand
  ): AllActionContextEventsRecord[TCommand] {
    const currentState = this.getValue();
    return currentState[commandId];
  }
}
