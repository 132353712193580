import { type FC, type PropsWithChildren, useCallback, type MouseEvent as ReactMouseEvent } from 'react';
import {
  Box,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
  IconButton
} from '@oms/shared-frontend/ui-design-system';
import { DragHandleDots2Icon, PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import {
  type RuleDragCollectionProps,
  RuleDragItemTypes,
  type RuleGroup as RuleGroupType,
  type RuleGroupProps
} from './rule.types';
import { useDrag } from 'react-dnd';

export const RuleGroup: FC<PropsWithChildren<RuleGroupProps>> = ({
  children,
  level,
  index,
  addGroup,
  addRule = { text: 'Add Rule', onClick: () => {} },
  onDelete = () => {},
  hideActionMenu = false,
  sx = {},
  styles = {}
}) => {
  const [_, drag, preview] = useDrag<RuleGroupProps, any | RuleGroupType, RuleDragCollectionProps>(
    () => ({
      type: RuleDragItemTypes.RULE_GROUP,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        path: []
      }),
      end: (_i, _monitor) => {}
    }),
    [index, level]
  );

  const onAddRule = useCallback(
    (e: ReactMouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      addRule.onClick(e);
    },
    [addRule]
  );

  const onAddRuleGroup = useCallback(
    (e: ReactMouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      addGroup?.onClick(e);
    },
    [addGroup]
  );

  const onClickTrigger = useCallback((e: ReactMouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  return (
    <Box
      ref={preview}
      style={styles}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        borderColor: 'border.subtle',
        borderStyle: 'solid',
        padding: 2,
        margin: 2,
        ...sx
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>{children}</Box>
        {!hideActionMenu && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 0,
              alignItems: 'flex-start',
              justifyContent: 'flex-start'
            }}
          >
            <IconButton
              icon={<TrashIcon />}
              variant={'secondary'}
              sx={{ backgroundColor: 'icons.primary' }}
              aria-label="remove-rule-group"
              onClick={onDelete}
            />
            <IconButton
              as={'div'}
              ref={drag}
              icon={<DragHandleDots2Icon />}
              aria-label="drag rule group"
              variant={'secondary'}
              sx={{ backgroundColor: 'icons.primary' }}
            />
            <DropdownMenuRoot>
              <DropdownMenuTrigger asChild onClick={onClickTrigger}>
                <IconButton
                  icon={<PlusIcon />}
                  aria-label="add-filter-or-group"
                  variant={'secondary'}
                  sx={{ backgroundColor: 'icons.primary' }}
                />
              </DropdownMenuTrigger>

              <DropdownMenuContent sideOffset={5} side="bottom" align="start" style={{ minWidth: 120 }}>
                <DropdownMenuItem onClick={onAddRule}>{addRule.text}</DropdownMenuItem>
                {addGroup && <DropdownMenuItem onClick={onAddRuleGroup}>{addGroup.text}</DropdownMenuItem>}
              </DropdownMenuContent>
            </DropdownMenuRoot>
          </Box>
        )}
      </Box>
    </Box>
  );
};
