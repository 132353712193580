import type {
  AdvancedSelectQueryFn,
  AdvancedSelectQueryPayload,
  ComboBoxItem
} from '@oms/frontend-foundation';
import { Observable } from 'rxjs';

/**
 * Tags Query
 */
export interface ExampleTagEntity {
  id: string;
  name: string;
}

export const exampleTagsWatchAllQuery: AdvancedSelectQueryFn<ExampleTagEntity> = () => {
  return {
    type: 'watchAll',
    preLoad: true,
    query: () => {
      return new Observable<AdvancedSelectQueryPayload<ExampleTagEntity>>((observer) => {
        const items = [
          {
            id: '1',
            name: 'Tag 1'
          },
          {
            id: '2',
            name: 'Tag 2'
          },
          {
            id: '3',
            name: 'Tag 3'
          },
          {
            id: '4',
            name: 'Tag 4'
          },
          {
            id: '5',
            name: 'Tag 5'
          },
          {
            id: '6',
            name: 'Tag 6'
          }
        ].map((r) => {
          const item: ComboBoxItem<ExampleTagEntity> = {
            type: 'item',
            id: r.id,
            label: r.name,
            value: r
          };
          return item;
        });

        observer.next({
          results: items,
          isFetching: false
        });
      });
    }
  };
};

export default exampleTagsWatchAllQuery;
