import type { Field } from '@data-driven-forms/react-form-renderer';
import type { FormOptions, ISelectField } from '@oms/frontend-foundation';
import { type RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';

export const ROUTE_ORDER_INVESTOR_ORDER_IS_TIF_ENABLED = 'routeOrderInvestorOrderIsTifEnabled';

export const routeOrderInvestorOrderIsTifEnabled =
  () =>
  (_props: Field, _field: Field, formOptions: FormOptions): Partial<ISelectField> => {
    const formValues: Partial<RouteOrderFormValues> = formOptions.getState().values;
    const { hiddenFormInfo } = formValues || {};

    switch (hiddenFormInfo?.type) {
      case 'route': {
        return {}; // Used to have this disabled for route orders but @jordi says it should be editable
      }
      default:
        return {};
    }
  };
