import type { Field } from '@data-driven-forms/react-form-renderer';
import type { FormOptions, ISelectField } from '@oms/frontend-foundation';
import { type RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';
import { OrderSideType } from '@oms/generated/frontend';

export const ROUTE_ORDER_INVESTOR_ORDER_IS_LOCATE_ENABLED = 'routeOrderInvestorOrderIsLocateEnabled';

export const routeOrderInvestorOrderIsLocateEnabled =
  () =>
  (_props: Field, _field: Field, formOptions: FormOptions): Partial<ISelectField> => {
    const formValues: Partial<RouteOrderFormValues> = formOptions.getState().values;
    const { sideType } = formValues || {};

    if (sideType !== OrderSideType.Sell && sideType !== OrderSideType.Short) {
      return {
        isDisabled: true,
        forceIsDisabled: true
      };
    }

    return {};
  };
