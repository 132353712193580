import {
  type ColumnBuilderCallback,
  type MarketDataBuilder,
  PROGRESS_RENDERER,
  ColumnBuilder,
  CellBadgeClassEnum
} from '@oms/frontend-vgrid';
import {
  sharedDateTimeCol,
  sharedDefaultCol,
  sharedIdCol,
  sharedPriceCol,
  sharedQuantityCol,
  sharedTextCol,
  sharedTimestampCol
} from '@app/common/grids/columns/generic-cols';
import {
  sharedLimitPriceCol,
  sharedSideCol,
  sharedOrderTypeCol,
  sharedAveragePriceCol,
  sharedSettleCurrencyCol,
  sharedInvestorAccountCol,
  sharedSettleTypeCol,
  sharedSettleDateCol,
  sharedExpiryDateTimeCol,
  sharedOrderTifCol,
  sharedTransmittedTimestampCol,
  sharedOrderEntryTypeCol,
  sharedOrderVisibilityReasonCol,
  sharedOrderTagsCol,
  sharedOwnerCol,
  sharedOwnerIdCol,
  sharedRepresentativeCodeCol,
  sharedCustomerNotesCol,
  sharedTradeCurrencyCol,
  sharedCreatedTimestampCol,
  sharedOrderStatusWithoutMapperCol,
  sharedTodayExecutedQuantityCol,
  sharedTodayAveragePriceCol
} from '@app/common/grids/columns/order-cols';
import { instrumentCols } from '@app/common/grids/columns/instrument-cols';
import {
  type VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment,
  type FigurationInfoFragment,
  InvestorOrderStatus,
  type ChargesRollupInfoFragment
} from '@oms/generated/frontend';
import {
  ChargeFeeTotalCellRenderer,
  ChargeMarketTotalCellRenderer
} from '@app/common/grids/cell-renderers/charges/charge-group-total.cell-renderer';
import { t } from '@oms/codegen/translations';
import {
  commissionRateType,
  commissionRateValue,
  commissionAmount
} from '@app/common/types/charges/charges.utils';
import { mapOrderStatus } from '@app/common/mappers/map-order-status';
import { DateFilter, FloatingDateFilter } from '@app/common/grids/filters/date-filter';
export const OWNER_ID_FIELD_NAME = 'owner.id';
export const ORDER_VISBILITY_FIELD_NAME = 'reason';
export const INVESTOR_ACCOUNT_FIELD_NAME = 'investorAccount';
export const INVESTOR_ACCOUNT_NAME_FIELD_NAME = 'investorAccount.name';
export const INVESTOR_ACCOUNT_ID_FIELD_NAME = 'investorAccount.id';

const defaultCol: ColumnBuilderCallback<IOFragment> = (c) => sharedDefaultCol<IOFragment>(c).floatingFilter();

const idCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedIdCol<IOFragment>(c, 'id')
    .floatingFilter(false)
    .hide()
    .filterParams({
      filterOptions: ['equals']
    });

const orderSideCol: ColumnBuilderCallback<IOFragment> = (c) => sharedSideCol<IOFragment>(c);

const limitPriceCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedLimitPriceCol(c).filter('agNumberColumnFilter');

const totalQuantityCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedQuantityCol(c, 'quantity')
    .header(t('app.orders.orderMonitor.totalQuantity'))
    .shortHeader(t('app.orders.orderMonitor.totalQuantity', { ns: 'short' }));

const leavesQuantityCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedQuantityCol(c, 'leavesQuantity')
    .header(t('app.orders.orderMonitor.leavesQuantity'))
    .shortHeader(t('app.orders.orderMonitor.leavesQuantity', { ns: 'short' }));

const openQuantityCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedQuantityCol(c, 'openQuantity')
    .header(t('app.orders.orderMonitor.openQuantity'))
    .shortHeader(t('app.orders.orderMonitor.openQuantity', { ns: 'short' }));

const executedQuantityCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedQuantityCol(c, 'executedQuantity')
    .header(t('app.orders.orderMonitor.executedQuantity'))
    .shortHeader(t('app.orders.orderMonitor.executedQuantity', { ns: 'short' }))
    .cell((c) => c.renderer(PROGRESS_RENDERER));

const workingQuantityCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedQuantityCol(c, 'workingQuantity')
    .header(t('app.orders.orderMonitor.workingQuantity'))
    .shortHeader(t('app.orders.orderMonitor.workingQuantity', { ns: 'short' }));

const stopPriceCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedPriceCol(c, 'stopPrice')
    .header(t('app.orders.orderMonitor.stopPrice'))
    .shortHeader(t('app.orders.orderMonitor.stopPrice', { ns: 'short' }))
    .hide();

const investorAccountCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedInvestorAccountCol<IOFragment>(c, INVESTOR_ACCOUNT_NAME_FIELD_NAME);

const investorAccountIdCol: ColumnBuilderCallback<IOFragment> = (c) =>
  c
    .field(INVESTOR_ACCOUNT_ID_FIELD_NAME)
    .header(t('app.orders.orderMonitor.investorAccountId'))
    .shortHeader(t('app.orders.orderMonitor.investorAccountId', { ns: 'short' }))
    .hide()
    .width(110)
    .filter('agTextColumnFilter');

const transmittedTimestampCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedTransmittedTimestampCol(c).hide();

const orderTypeCol: ColumnBuilderCallback<IOFragment> = (c) => sharedOrderTypeCol(c);

const orderTifCol: ColumnBuilderCallback<IOFragment> = (c) => sharedOrderTifCol(c);

const orderStatusCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedOrderStatusWithoutMapperCol(c)
    .filter('agSetColumnFilter')
    .filterParams<InvestorOrderStatus>({
      values: Object.values(InvestorOrderStatus),
      valueFormatter: ({ value }) => mapOrderStatus(value)
    })
    .cell((c) => c.badge(CellBadgeClassEnum.Capital, (data) => mapOrderStatus(data?.status)));

const todayExecutedQuantityCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedTodayExecutedQuantityCol(c).colId('todayExecutedQuantity').hide();

const todayAveragePriceCol: ColumnBuilderCallback<IOFragment> = (c) => sharedTodayAveragePriceCol(c).hide();

const expiryDateTimeCol: ColumnBuilderCallback<IOFragment> = (c) => sharedExpiryDateTimeCol(c);

const updatedTimeCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedDateTimeCol(c, 'updatedTime')
    .header(t('app.orders.orderMonitor.updatedTime'))
    .shortHeader(t('app.orders.orderMonitor.updatedTime', { ns: 'short' }))
    .hide();

const lastExecutionTimeCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedDateTimeCol(c, 'lastExecutionTime')
    .header(t('app.orders.orderMonitor.lastExecutionTime'))
    .shortHeader(t('app.orders.orderMonitor.lastExecutionTime', { ns: 'short' }))
    .hide();

const locateCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedTextCol(c, 'locate')
    .header(t('app.orders.orderMonitor.locate'))
    .shortHeader(t('app.orders.orderMonitor.locate', { ns: 'short' }))
    .hide();

const clientOrderIdCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedIdCol(c, 'clientOrderId')
    .header(t('app.orders.orderMonitor.clientOrderId'))
    .shortHeader(t('app.orders.orderMonitor.clientOrderId', { ns: 'short' }));

const orderTagsCol: ColumnBuilderCallback<IOFragment> = (c) => sharedOrderTagsCol(c);

const averagePriceCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedAveragePriceCol<IOFragment>(c, 'averagePrice');

const orderEntryTypeCol: ColumnBuilderCallback<IOFragment> = (c) => sharedOrderEntryTypeCol<IOFragment>(c);

const orderCommentsCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedTextCol(c, 'orderComments')
    .header(t('app.orders.orderMonitor.orderComments'))
    .shortHeader(t('app.orders.orderMonitor.orderComments', { ns: 'short' }))
    .hide();

const customerNotesCol: ColumnBuilderCallback<IOFragment> = (c) => sharedCustomerNotesCol(c);

const tradeCurrencyCol: ColumnBuilderCallback<IOFragment> = (c) => sharedTradeCurrencyCol(c);

const representativeCodeCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedRepresentativeCodeCol<IOFragment>(c).field('representativeCode.code');

const ownerCol: ColumnBuilderCallback<IOFragment> = (c) => sharedOwnerCol<IOFragment>(c);

const ownerIdCol: ColumnBuilderCallback<IOFragment> = (c) => sharedOwnerIdCol<IOFragment>(c);

const sendingDeskCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedTextCol(c, 'sendingDesk')
    .header(t('app.orders.orderMonitor.sendingDesk'))
    .shortHeader(t('app.orders.orderMonitor.sendingDesk', { ns: 'short' }));

const underlyingAccount: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedTextCol(c, 'underlyingAccount')
    .header(t('app.orders.orderMonitor.underlyingAccount'))
    .shortHeader(t('app.orders.orderMonitor.underlyingAccount', { ns: 'short' }));

const settleCurrencyCol: ColumnBuilderCallback<IOFragment> = (c) => sharedSettleCurrencyCol(c);

const settleTypeCol: ColumnBuilderCallback<IOFragment> = (c) => sharedSettleTypeCol(c);

const settleDateCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedSettleDateCol(c).filter(DateFilter).floatingFilterComponent(FloatingDateFilter).show().minWidth(170);

const validatedTimestampCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedTimestampCol(c, 'validatedTimestamp')
    .header(t('app.orders.orderMonitor.validatedTimestamp'))
    .shortHeader(t('app.orders.orderMonitor.validatedTimestamp', { ns: 'short' }))
    .sort('desc');

const createdTimestampCol: ColumnBuilderCallback<IOFragment> = (c) => sharedCreatedTimestampCol(c).hide();

const receivedTimestampCol: ColumnBuilderCallback<IOFragment> = (c) =>
  sharedTimestampCol(c, 'receivedTimestamp')
    .header(t('app.orders.orderMonitor.receivedTimestamp'))
    .shortHeader(t('app.orders.orderMonitor.receivedTimestamp', { ns: 'short' }))
    .hide();

const commissionRateTypeCol: ColumnBuilderCallback<IOFragment> = (c) =>
  c
    .field('compositeCharges')
    .header(t('app.orders.orderMonitor.commissionRateType'))
    .shortHeader(t('app.orders.orderMonitor.commissionRateType', { ns: 'short' }))
    .width(100)
    .filter(false)
    .cell((c) =>
      c.valueFormatter(({ data }) =>
        commissionRateType(
          data?.compositeCharges?.map((cc) => cc.figuration) as FigurationInfoFragment[] | undefined
        )
      )
    );

const commissionRateValueCol: ColumnBuilderCallback<IOFragment> = (c) =>
  c
    .field('compositeCharges')
    .header(t('app.orders.orderMonitor.commissionRateValue'))
    .shortHeader(t('app.orders.orderMonitor.commissionRateValue', { ns: 'short' }))
    .width(100)
    .filter(false)
    .cell((c) =>
      c.valueFormatter(({ data }) =>
        commissionRateValue(
          data?.compositeCharges?.map((cc) => cc.figuration) as FigurationInfoFragment[] | undefined,
          data
        )
      )
    );

const commissionAmountCol: ColumnBuilderCallback<IOFragment> = (c) =>
  c
    .field('chargesRollup')
    .header(t('app.orders.orderMonitor.commissionAmount'))
    .shortHeader(t('app.orders.orderMonitor.commissionAmount', { ns: 'short' }))
    .width(100)
    .filter(false)
    .cell((c) =>
      c.valueFormatter(({ data }) =>
        commissionAmount(data?.chargesRollup as ChargesRollupInfoFragment | undefined)
      )
    );

const feesTotalCol: ColumnBuilderCallback<IOFragment> = (c) =>
  c
    .field('chargesRollup')
    .header(t('app.orders.orderMonitor.feesTotal'))
    .shortHeader(t('app.orders.orderMonitor.feesTotal', { ns: 'short' }))
    .width(100)
    .filter(false)
    .cell((c) => c.renderer(ChargeFeeTotalCellRenderer));

const marketChargeTotalCol: ColumnBuilderCallback<IOFragment> = (c) =>
  c
    .field('chargesRollup')
    .header(t('app.orders.orderMonitor.marketChargesTotal'))
    .shortHeader(t('app.orders.orderMonitor.marketChargesTotal', { ns: 'short' }))
    .width(100)
    .filter(false)
    .cell((c) => c.renderer(ChargeMarketTotalCellRenderer));

const orderVisibilityReasonCol: ColumnBuilderCallback<IOFragment> = (c) => sharedOrderVisibilityReasonCol(c);

export const investorOrderMarketData = (m: MarketDataBuilder<any>) =>
  m.level1((l) =>
    l
      .defaultField((c) => c.minWidth(90).width(100).hide())
      .fields({
        tickerId: 'instrument.mappings.displayCode', // the ticker field to "join" with
        fields: [
          (c) => c.field('bidPrice').show().level1MarketData('bidPrice', 'ticker').filter(false),
          (c) => c.field('askPrice').show().level1MarketData('askPrice', 'ticker').filter(false)
        ]
      })
  );

export const workflowCol: ColumnBuilderCallback<IOFragment> = (c) =>
  c
    .field('workflow')
    .header(t('app.orders.orderMonitor.workflow'))
    .shortHeader(t('app.orders.orderMonitor.workflow', { ns: 'short' }))
    .hide()
    .width(110)
    .filter('agTextColumnFilter');

export const fixWorkflowIdCol: ColumnBuilderCallback<IOFragment> = (c) =>
  c
    .field('fixWorkflowId')
    .header(t('app.orders.orderMonitor.fixWorkflowId'))
    .shortHeader(t('app.orders.orderMonitor.fixWorkflowId', { ns: 'short' }))
    .hide()
    .width(110)
    .filter('agTextColumnFilter');

export const defaultInvestorOrderMonitorColumns: ColumnBuilderCallback<IOFragment>[] = [
  idCol,
  ...instrumentCols<IOFragment>({
    displayCode: (cb) =>
      cb
        .cell((c) => c.renderer('agGroupCellRenderer'))
        .filter(false)
        .sortable(false)
  }),
  orderSideCol,
  limitPriceCol,
  totalQuantityCol,
  leavesQuantityCol,
  openQuantityCol,
  executedQuantityCol,
  workingQuantityCol,
  stopPriceCol,
  investorAccountCol,
  investorAccountIdCol,
  validatedTimestampCol,
  transmittedTimestampCol,
  receivedTimestampCol,
  orderTypeCol,
  commissionRateTypeCol,
  commissionRateValueCol,
  commissionAmountCol,
  feesTotalCol,
  marketChargeTotalCol,
  orderTifCol,
  orderStatusCol,
  todayExecutedQuantityCol,
  todayAveragePriceCol,
  expiryDateTimeCol,
  locateCol,
  clientOrderIdCol,
  orderTagsCol,
  averagePriceCol,
  orderEntryTypeCol,
  orderCommentsCol,
  customerNotesCol,
  ownerCol,
  ownerIdCol,
  representativeCodeCol,
  sendingDeskCol,
  underlyingAccount,
  tradeCurrencyCol,
  settleCurrencyCol,
  settleTypeCol,
  settleDateCol,
  createdTimestampCol,
  orderVisibilityReasonCol,
  updatedTimeCol,
  lastExecutionTimeCol,
  workflowCol,
  fixWorkflowIdCol
];

export const investorOrderMonitorColumnLibrary = (
  disableFilterFields: string[] = [],
  excludeFields: string[] = []
) => {
  const retCols: ColumnBuilderCallback<IOFragment>[] = [];
  defaultInvestorOrderMonitorColumns.forEach((column) => {
    // Since each column is a callback, we must build it first to access the properties.
    const colDef = column(new ColumnBuilder()).build();

    // Fields to be excluded are skipped altogether.
    if (colDef.colId === undefined || !excludeFields.includes(colDef.colId)) {
      // Fields where we're disabling user filtering, override with "filter(false)"
      if (colDef.colId !== undefined && disableFilterFields.includes(colDef.colId)) {
        retCols.push((c) => column(c).filter(false));
      } else {
        retCols.push(column);
      }
    }
  });

  return {
    defaultColumn: defaultCol,
    columns: retCols
  };
};
