import { inject, singleton } from 'tsyringe';
import {
  type GetTradesByTradingOrderIdQuery,
  GetTradesByTradingOrderIdDocument,
  type GetTradesByTradingOrderIdQueryVariables,
  type TradeInfoFragment,
  type CancelTradeMutation,
  type CancelTradeMutationVariables,
  CancelTradeDocument,
  type CancelTradesMutation,
  type CancelTradesMutationVariables,
  CancelTradesDocument,
  type CancelTradesInput,
  type TradePatch,
  type ModifyTradeMutation,
  type ModifyTradeMutationVariables,
  ModifyTradeDocument,
  type GetTradeQuery,
  GetTradeDocument,
  type GetTradeQueryVariables
} from '@oms/generated/frontend';
import { map } from 'rxjs';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';

@singleton()
export class TradesService {
  constructor(
    @inject(ApolloClientRPC) private apolloClient: ApolloClientRPC,
    @inject(GQLResponse) private gqlResponse: GQLResponse
  ) {}

  public watchAll$(tradingOrderId?: string, pollInterval = 5000) {
    return this.apolloClient
      .watchQuery<GetTradesByTradingOrderIdQuery, GetTradesByTradingOrderIdQueryVariables>({
        query: GetTradesByTradingOrderIdDocument,
        pollInterval,
        fetchPolicy: 'no-cache',
        variables: {
          id: tradingOrderId ?? ''
        }
      })
      .pipe(
        map((res) => {
          const tradesResults = (res.data.tradingOrder?.trades?.nodes || []).filter(
            (t) => !!t
          ) as TradeInfoFragment[];
          return tradesResults;
        })
      );
  }

  public getById(tradeId: string) {
    return this.gqlResponse
      .wrapQuery<GetTradeQuery, GetTradeQueryVariables>({
        query: GetTradeDocument,
        variables: {
          id: tradeId
        }
      })
      .exec();
  }

  public cancel(tradeId: string) {
    const mutation = this.gqlResponse.wrapMutate<CancelTradeMutation, CancelTradeMutationVariables>({
      mutation: CancelTradeDocument,
      variables: {
        id: tradeId
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public cancelTrades(input: CancelTradesInput) {
    const mutation = this.gqlResponse.wrapMutate<CancelTradesMutation, CancelTradesMutationVariables>({
      mutation: CancelTradesDocument,
      variables: {
        input
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public modify(input: TradePatch) {
    const mutation = this.gqlResponse.wrapMutate<ModifyTradeMutation, ModifyTradeMutationVariables>({
      mutation: ModifyTradeDocument,
      variables: { modification: input }
    });

    return mutation.awaitAsyncResponse().exec();
  }
}
