import { ArrowBottomLeftIcon } from '@radix-ui/react-icons';
import { IconButton } from '@oms/shared-frontend/ui-design-system';
import { reactFlexLayoutEvents, useCurrentActorContext, useCurrentWindow } from '@valstro/workspace-react';
import { COMMON_AUTH_WINDOW } from '@app/common/auth/auth.contracts';
import type { CommonWindowActorSchema } from '@valstro/workspace';

/**
 * Custom pop-in button for window actions
 */
export const PopInButton: React.FC = () => {
  const currentWindow = useCurrentWindow([COMMON_AUTH_WINDOW.TYPE]);
  const context = useCurrentActorContext<CommonWindowActorSchema>();
  const { parentFlexLayoutActorId, prevFlexLayoutTabJSON } = context?.meta || {};

  if (!parentFlexLayoutActorId || !prevFlexLayoutTabJSON) {
    return null;
  }

  const handlePopIn = () => {
    currentWindow.destroy().catch(console.error);
    reactFlexLayoutEvents
      .emit('addTabWithDragAndDropIndirect', {
        tabJSON: prevFlexLayoutTabJSON,
        flexLayoutActorId: parentFlexLayoutActorId
      })
      .catch(console.error);
  };

  return (
    <IconButton
      size="sm"
      variant="link"
      draggable={true}
      aria-label="Pop in"
      icon={<ArrowBottomLeftIcon />}
      onClick={() => {
        handlePopIn();
      }}
      className="window__toolbar__action"
    />
  );
};
