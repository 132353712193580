import { FC, useMemo } from 'react';
import { Grid, GridProps } from '../../layout/grid/grid';
import { GridItem, GridItemProps } from '../../layout/grid/grid-item';
import { BoxProps } from '../../system/components/box/box';
import { DisplayField, DisplayFieldProps } from '../display-field/display-field';
import type { DisplayFieldComponentProps } from '../display-field/display-field.mapper';
import { Stats, StatsProps } from '../stats/stats';
import { DailyStats, DailyStatsProps } from '../daily-stats/daily-stats';
import { Sprinkles } from '../../system/sprinkles.css';

export const DISPLAY_GRID_CUSTOM_COMPONENT_TYPE = {
  Stats: 'Stats',
  DailyStats: 'DailyStats'
} as const;

export const displayGridComponentMapper = {
  [DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.Stats]: Stats,
  [DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.DailyStats]: DailyStats
} as const;

export type DisplayGridCustomComponentProps = StatsProps | DailyStatsProps;

export type DisplayCompShape = { type?: string } | undefined;

export type CombinedDisplayFieldComponentProps = DisplayFieldComponentProps | DisplayGridCustomComponentProps;

export type UseDisplayCompProps<
  TFieldComps,
  TCustomFieldComps extends DisplayCompShape = undefined
> = TCustomFieldComps extends undefined ? TFieldComps : TFieldComps | TCustomFieldComps;

export type DisplayGridItemProps<TFieldComps extends DisplayCompShape = undefined> = GridItemProps & {
  component?: UseDisplayCompProps<CombinedDisplayFieldComponentProps, TFieldComps>;
  layout?: 'horizontal' | 'vertical';
  labelMargin?: number;
  testId?: string;
};

export type DisplayGridProps<TFieldComps extends DisplayCompShape = undefined> = {
  customDisplayFieldComponents?: {
    [key in TFieldComps['type']]: FC<TFieldComps>;
  };
  gridProps: GridProps;
  items: DisplayGridItemProps<TFieldComps>[];
  layout?: 'horizontal' | 'vertical';
  width?: string;
  labelSize?: DisplayFieldProps['labelSize'];
  labelMargin?: number;
  sx?: Sprinkles;
  style?: BoxProps['style'];
  testId?: string;
};

export function isCustomComponent<TFieldComps extends DisplayCompShape = undefined>(
  item: DisplayGridItemProps<TFieldComps>
): item is { component: DisplayFieldProps<TFieldComps>['component'] } {
  return (
    item?.component?.type === DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.Stats ||
    item?.component?.type === DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.DailyStats
  );
}

export function convertToCustomComponent<TFieldComps extends DisplayCompShape = undefined>(
  item: DisplayGridItemProps<TFieldComps>
) {
  switch (item.component.type) {
    case DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.Stats:
      return item.component as { component: StatsProps };
    case DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.DailyStats:
      return item.component as { component: DailyStatsProps };
    default:
      throw new Error('Unknown DISPLAY_GRID_CUSTOM_COMPONENT: ' + item.component.type);
  }
}

export function DisplayGrid<TFieldComps extends DisplayCompShape = undefined>({
  gridProps,
  items,
  layout,
  width,
  labelSize,
  labelMargin,
  sx = {},
  style = {},
  customDisplayFieldComponents,
  testId
}: DisplayGridProps<TFieldComps>) {
  const customComponent = useMemo(
    () => (component: DisplayFieldProps<TFieldComps>['component']) => {
      const { type } = component;

      switch (type) {
        case DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.Stats: {
          return displayGridComponentMapper[type](component as StatsProps);
        }
        case DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.DailyStats: {
          return displayGridComponentMapper[type](component as DailyStatsProps);
        }
        default:
          return null;
      }
    },
    []
  );

  return (
    <Grid {...gridProps} style={{ width: width || '100%', ...style }} sx={sx} data-testid={testId}>
      {items?.map((item, i) => {
        return (
          <GridItem
            rowSpan={item.rowSpan || 1}
            colSpan={item.colSpan || 1}
            style={{ width: item.width, position: 'relative' }}
            sx={item.sx}
            data-testid="grid-item"
            key={i}
          >
            {isCustomComponent<TFieldComps>(item) ? (
              customComponent(item.component)
            ) : (
              <DisplayField<TFieldComps>
                label={item.label}
                layout={item.layout || layout}
                component={item.component as DisplayFieldProps<TFieldComps>['component']}
                key={item.label}
                labelSize={labelSize}
                style={item.style}
                labelMargin={item.labelMargin || labelMargin}
                customDisplayFieldComponents={customDisplayFieldComponents}
              />
            )}
          </GridItem>
        );
      })}
    </Grid>
  );
}
