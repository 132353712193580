import { map } from 'rxjs';
import type { AdvancedSelectQueryFn } from '@oms/frontend-foundation';
import type { ComboBoxItem } from '@oms/frontend-foundation';
import type { TradeCounterpartyInfoFragment } from '@oms/generated/frontend';
import { TradeCounterpartyService } from '@app/data-access/services/reference-data/trade-counterparty/trade-counterparty.service';

export type TradeCounterpartyId = TradeCounterpartyInfoFragment['id'];
export type TradeCounterpartyComboBoxData = TradeCounterpartyInfoFragment;
export type TradeCounterpartyInfoComboBoxValue = TradeCounterpartyInfoFragment;
export type TradeCounterpartyInfoFieldValue = ComboBoxItem<TradeCounterpartyInfoFragment>;

export const tradeCounterpartyQuery: AdvancedSelectQueryFn<TradeCounterpartyComboBoxData> = (container) => {
  const service = container.resolve(TradeCounterpartyService);

  return {
    query: () => {
      return service.watchAll$().pipe(
        map(({ results, isFetching, error }) => ({
          results: results?.map(
            (r) =>
              ({
                id: r.id ?? '',
                label: r.tradeCounterpartyCode ?? '',
                sublabel: r.tradeCounterpartyDescription ?? '',
                type: 'item',
                value: r
              })
          ),
          isFetching,
          error
        }))
      );
    },
    type: 'watchAll',
    preLoad: true
  };
};

export default tradeCounterpartyQuery;
