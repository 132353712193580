import { useEffect } from 'react';
import { type VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { Box, Spinner, type Sprinkles } from '@oms/shared-frontend/ui-design-system';
import { NEW_ORDERS } from './new-orders.grid.widget';
import { useGridSelectionEvent } from '@app/data-access/memory/grid.events';
import { useUpdatedInvestorOrder } from '@app/data-access/hoc/with-updated-investor-order';
import { InvestorOrderViewSidePanelWidget } from '../investor-order-view/investor-order-view.side-panel.widget';

const sx: Sprinkles = {
  paddingTop: 5,
  backgroundColor: 'layout.level2',
  height: 'full',
  overflowY: 'auto'
};

const textSx: Sprinkles = {
  ...sx,
  padding: 5,
  display: 'flex',
  flexDirection: 'column',
  placeContent: 'center',
  textAlign: 'center'
};

const NewOrdersSidePanelWidget = () => {
  const selectedRowsEvent = useGridSelectionEvent<VisibleInvestorOrderInfoWithAllocationsFragment>(
    NEW_ORDERS,
    'scoped'
  );
  const newOrder = selectedRowsEvent?.payload.selectedRows[0];
  const investorOrderId = newOrder?.id;
  const [investorOrder, isLoading, error, setInvestorOrderId] = useUpdatedInvestorOrder(investorOrderId);

  useEffect(() => {
    setInvestorOrderId(investorOrderId);
  }, [investorOrderId, setInvestorOrderId]);

  switch (true) {
    case !newOrder:
      return <Box sx={textSx}>Please select an order from pane on the left.</Box>;

    case isLoading || !investorOrder:
      return <Spinner fillArea bgColor={sx.backgroundColor} />;

    case !!error:
      // This includes the case of order not found; InvestorOrdersSubscriptionService.track
      // treats an empty response when filtering on id to be an error.
      return <Box sx={textSx}>Error: {error?.message}</Box>;

    case !!investorOrder:
      return <InvestorOrderViewSidePanelWidget data={investorOrder!} />;
  }
};

export default NewOrdersSidePanelWidget;
