import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useCurrentWidget, useClosestFlexLayoutActor } from '@valstro/workspace-react';
import { Center, Input } from '@oms/shared-frontend/ui-design-system';
import { type QueryConstructor, useRxCollection, useRxData } from 'rxdb-hooks-v5';
import debounce from 'lodash/debounce';
import {
  type ExampleLayoutDocType,
  EXAMPLE_LAYOUT_COLLECTION_NAME
} from '@app/data-access/offline/collections/example-layout.collection';

export const ExampleLayoutNodeWidgetComponent: React.FC = () => {
  const [selectedSymbol, setSelectedSymbol] = useExampleLayoutNodeSelectedSymbol();

  return (
    <Center sx={{ backgroundColor: 'layout.level1' }}>
      <Input
        value={selectedSymbol}
        onChange={(e) => setSelectedSymbol(e.target.value)}
        style={{ width: 300 }}
      />
    </Center>
  );
};

export default ExampleLayoutNodeWidgetComponent;

/**
 * Fetches & subscribes to the selectedSymbol and provides a setter
 * Note: This hook is over-engineered however, it's a good example of:
 * - How to scope state to a parent actor
 * - How to debounce state updates
 * - How to use rxdb-hooks-v5
 */
function useExampleLayoutNodeSelectedSymbol() {
  const [value, setValue] = useState('');
  const flexLayout = useClosestFlexLayoutActor(); // Note, still need to rename this to "parent"
  const widget = useCurrentWidget();
  const scopedActorId = flexLayout?.id || widget.id; // Scope the state to the flexLayout FIRST, then the widget

  const queryConstructor: QueryConstructor<ExampleLayoutDocType> = useCallback(
    (c) => c.findOne(scopedActorId),
    [scopedActorId]
  );

  const collection = useRxCollection<ExampleLayoutDocType>(EXAMPLE_LAYOUT_COLLECTION_NAME);
  const { result } = useRxData<ExampleLayoutDocType>(EXAMPLE_LAYOUT_COLLECTION_NAME, queryConstructor, {
    json: true,
    pageSize: 1
  });

  const item = result[0];

  useLayoutEffect(() => {
    if (item) {
      setValue(item?.selectedSymbol || '');
    }
  }, [item]);

  const debouncedSetter = useMemo(() => {
    return debounce((selectedSymbol: string) => {
      if (collection) {
        collection.upsert({ scopedActorId, selectedSymbol }).catch(console.error);
      }
    }, 10);
  }, [collection, scopedActorId]);

  const optimisticSetter = useCallback(
    (selectedSymbol: string) => {
      setValue(selectedSymbol);
      debouncedSetter(selectedSymbol);
    },
    [debouncedSetter]
  );

  return useMemo(() => [value, optimisticSetter] as const, [value, optimisticSetter]);
}
