import { type CheckboxControl } from '@valstro/fixatdl-core';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { type ControlTransformer } from './control.transformer.types';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const checkboxControl: ControlTransformer<CheckboxControl> = (checkboxControl, _query, formVals) => {
  return {
    component: fixatdlFormComponentTypes.CHECKBOX,
    name: checkboxControl.id,
    checkedEnumRef: checkboxControl.checkedEnumRef || 'true',
    uncheckedEnumRef: checkboxControl.uncheckedEnumRef || 'false',
    initialValue: AtdlFormats.coerceValue(formVals[checkboxControl.id]),
    label: checkboxControl.label
  };
};
