import React, { type FC, useCallback } from 'react';
import { type Field, useFormApi } from '@data-driven-forms/react-form-renderer';
import { Box, Flex, IconButton } from '@oms/shared-frontend/ui-design-system';
import type { FORM_COMPONENT_TYPE } from '../../common/form.contracts';
import type { FieldProps, ICommonField } from '@oms/frontend-foundation';
import { type IAllFields } from '../additional-fields/all-fields.component';
import { PlusCircledIcon, MinusCircledIcon } from '@radix-ui/react-icons';

export type IconClickHandler = (fieldName: string) => void;

export interface IEnrichedField extends ICommonField<typeof FORM_COMPONENT_TYPE.ENRICHED_FIELD> {
  field: Field;
  isSelected?: boolean;
  groupName?: string;
  colSpan?: number;
  columns?: number;
  showIcon?: boolean;
  renderStrategy?: IAllFields['renderStrategy'];
  selectedFields?: string[];
  fixedFields?: string[];
  onIconClick?: IconClickHandler;
}

const calculateFlexBasis = (columns = 1, colSpan = 1, margin = 15) => {
  return `calc(${(1 / columns) * colSpan * 100}% - ${margin}px)`;
};

const EnrichedFieldIcon: FC<{
  renderStrategy: IAllFields['renderStrategy'];
  fieldName: string;
  onClick?: IconClickHandler;
}> = ({ renderStrategy, fieldName, onClick }) => {
  const Icon = renderStrategy === 'main-form' ? MinusCircledIcon : PlusCircledIcon;

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(fieldName);
    }
  }, [fieldName, onClick]);

  return (
    <IconButton
      icon={<Icon width={18} height={18} />}
      type="button"
      aria-label="remove-field"
      variant="secondary"
      sx={{ backgroundColor: 'layout.level2' }}
      style={{
        borderRadius: '50%',
        border: 'none',
        top: '4px',
        position: 'absolute',
        right: '-12px'
      }}
      onClick={handleClick}
    />
  );
};

export const EnrichedField: React.FC<FieldProps<IEnrichedField>> = (props) => {
  const { renderForm } = useFormApi();

  const { columns, showIcon, renderStrategy, selectedFields, fixedFields, onIconClick, colSpan, field } =
    props;

  return (
    <Flex
      style={{
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: calculateFlexBasis(columns, colSpan)
      }}
      sx={{
        marginLeft: 5,
        position: 'relative'
      }}
    >
      <Box style={{ flex: 1 }}>{renderForm([field])}</Box>
      {showIcon &&
        ((renderStrategy === 'dialog-form' && !selectedFields?.includes(field.name)) ||
          (renderStrategy === 'main-form' && !fixedFields?.includes(field.name))) && (
          <EnrichedFieldIcon renderStrategy={renderStrategy} fieldName={field.name} onClick={onIconClick} />
        )}
    </Flex>
  );
};
