import {
  FormRenderer,
  FormSpy,
  type ValidatorMapper,
  type FormTemplateRenderProps
} from '@data-driven-forms/react-form-renderer';
import React, { useCallback } from 'react';
import { Flex, Box } from '@oms/shared-frontend/ui-design-system';
import { type FIXatdlMeta, type FIXatdlStrategyValue } from './fixatdl-strategy-field.contracts';
import { isEqual } from 'lodash';
import { strategyErrorsValidator } from '@app/forms/fixatdl/validators/strategy.errors.validator';
import { fixatdlFormComponentMapper } from '@app/forms/fixatdl/components/fixatdl-component.mappers';
import { fixatdlFormValidatorMapper } from '@app/forms/fixatdl/validators';
import { fixatdlFormActionMapper } from '@app/forms/fixatdl/actions/fixatdl-action.mappers';
import { type FIXatdlField } from '@app/forms/fixatdl/fixatdl-form.types';
import { type AtdlFieldPartial } from '@app/forms/fixatdl/components/ddf-transformer/controls/control.transformer.types';
import {
  strategyValuesChanged,
  useAllFieldsStateReset,
  useRouteOrderFormValues
} from './fixatdl-strategy-field.state';
import { type RouteOrderFormValues } from '../route-order.form-contract';
import { useEnhancedFormApi } from '@oms/frontend-foundation';

type FIXatdlStrategyFormTemplateProps = FormTemplateRenderProps &
  Omit<FIXatdlStrategyValue, 'strategyParams' | 'strategyControls'> & {
    getRouteOrderFormValues: () => RouteOrderFormValues | undefined;
  } & FIXatdlMeta;

const FIXatdlStrategyFormTemplate: React.FC<FIXatdlStrategyFormTemplateProps> = React.memo(
  ({ strategyName, formFields, formId, getRouteOrderFormValues }) => {
    const formApi = useEnhancedFormApi();
    useAllFieldsStateReset(formId, formApi);

    return (
      <Flex
        data-testid={`fixatdl-strategy-params-${strategyName}`}
        as="form"
        direction="column"
        role="form"
        sx={{ height: 'full' }}
      >
        <FormSpy subscription={{ errors: true }}>
          {({ errors }: { errors: Record<string, string[]> | undefined }) => {
            const formErrors = errors ? errors['FINAL_FORM/form-error'] : undefined;

            return formErrors?.length ? (
              <Box
                sx={{
                  color: 'Red.400',
                  padding: 3,
                  bgColor: 'Red.900',
                  fontWeight: 'baseB',
                  position: 'relative',
                  width: 'full',
                  opacity: 0.9,
                  marginBottom: 2,
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                {formErrors.map((error) =>
                  error ? (
                    <Box key={error} sx={{ flexDirection: 'row', flexGrow: 1 }}>
                      {error}
                    </Box>
                  ) : null
                )}
              </Box>
            ) : null;
          }}
        </FormSpy>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => {
            // the values are sent to the parent form to be used for validation
            strategyValuesChanged(formId, values, getRouteOrderFormValues);

            return null;
          }}
        </FormSpy>

        <Box sx={{ flexGrow: 1, height: 'full', position: 'relative', overflowY: 'auto' }}>
          {formFields as unknown as React.ReactElement[]}
        </Box>
      </Flex>
    );
  },
  isEqual
);

export type FIXatdlStrategyFormRendererProps = {
  fields: AtdlFieldPartial[];
} & FIXatdlStrategyValue &
  FIXatdlMeta;

export const FIXatdlStrategyFormRenderer = React.memo(
  ({
    fields,
    strategyName,
    venueId,
    formId
  }: Omit<FIXatdlStrategyFormRendererProps, 'strategyParams' | 'strategyControls'>) => {
    const getRouteOrderFormValues = useRouteOrderFormValues(formId);

    const TemplateRenderer = useCallback(
      (props: FormTemplateRenderProps) => {
        return (
          <FIXatdlStrategyFormTemplate
            {...props}
            strategyName={strategyName}
            venueId={venueId}
            formId={formId}
            getRouteOrderFormValues={getRouteOrderFormValues}
          />
        );
      },
      [strategyName, venueId, formId, getRouteOrderFormValues]
    );

    const strategyValidator = useCallback(() => {
      return strategyErrorsValidator(getRouteOrderFormValues?.() || {});
    }, [getRouteOrderFormValues]);

    return (
      <FormRenderer
        schema={{ fields: fields as FIXatdlField[] }}
        componentMapper={fixatdlFormComponentMapper}
        validatorMapper={fixatdlFormValidatorMapper as unknown as ValidatorMapper}
        actionMapper={fixatdlFormActionMapper}
        validate={strategyValidator()}
        FormTemplate={TemplateRenderer}
      />
    );
  },
  isEqual
);
