import { Flex, Text, format } from '@oms/shared-frontend/ui-design-system';
import { type IHeaderGroupParams } from '@ag-grid-community/core';
import { OrderSide } from '@oms/generated/frontend';
import { useMemo, type FC } from 'react';
import { useObservableState } from 'observable-hooks';
import { getCrossPrincipalFillOrders$ } from '@app/data-access/services/trading/cross-principal-fill/cross-principal-fill.state';

export const Header: FC<IHeaderGroupParams> = (props) => {
  const memoizedValue$ = useMemo(() => {
    return getCrossPrincipalFillOrders$(props.context.formId);
  }, [props.context.formId]);

  const value = useObservableState(memoizedValue$);
  const buySide = props.context.side === OrderSide.Buy;
  const rows = value ? (buySide ? value.buyOrders : value.sellOrders) : [];
  const fillQuantity = rows.reduce(
    (acc, row) => acc + ((!row.state.isDisabled && row.state.isChecked && row.order.openQuantity) || 0),
    0
  );

  return (
    <Flex align="center" sx={{ bgColor: 'layout.level3', width: 'full', height: 'full' }}>
      <Text sx={{ marginLeft: 3, color: buySide ? 'price.positive' : 'price.negative' }}>
        {buySide ? 'BUY' : 'SELL'}
      </Text>

      <Flex justify="flex-end" sx={{ marginRight: 1.5 }} style={{ flex: 1 }}>
        Total Open Qty:
      </Flex>
      <Flex
        justify="flex-end"
        style={{ flex: 0.15, marginRight: '102px' }}
        type="baseB"
        sx={{ color: 'white' }}
      >
        {format('quantity', fillQuantity)}
      </Flex>
    </Flex>
  );
};
