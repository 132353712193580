import { type RxDocument, type RxCollection, type RxJsonSchema } from 'rxdb-v15';
import { GRID_TEMPLATE_COLLECTION_NAME, type GridState } from './grid-templates.collection';
import { TEMPORARY_SNAPSHOT_ID } from './snapshots.collection';

export const GRIDS_COLLECTION_NAME = 'grids';

export const gridsSchema: RxJsonSchema<GridsDocType> = {
  title: 'grids schema',
  description: 'describes a specific grids state',
  version: 0,
  keyCompression: false,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 128 // composite key with snapshotId & gridId
    },
    snapshotId: {
      type: 'string',
      maxLength: 37 // UUID length with hash
    },
    gridId: {
      type: 'string',
      maxLength: 37
    },
    widgetActorId: {
      type: 'string',
      maxLength: 37
    },
    currentGridTemplate: {
      type: 'string',
      ref: GRID_TEMPLATE_COLLECTION_NAME
    },
    state: {
      type: 'object'
    }
  },
  required: ['id', 'gridId', 'snapshotId', 'widgetActorId', 'state'],
  indexes: ['gridId', 'snapshotId']
} as const;

export function getSnapshotId(snapshotId?: string) {
  return snapshotId || TEMPORARY_SNAPSHOT_ID;
}

export function getCompositeId(gridId: string, snapshotId?: string) {
  return `${getSnapshotId(snapshotId)}_${gridId}`;
}

export type GridsDocType = {
  id: string;
  gridId: string;
  snapshotId: string;
  widgetActorId: string;
  currentGridTemplate?: string;
  state: GridState;
};

export type GridsDocument = RxDocument<GridsDocType>;

export type GridsCollection = RxCollection<GridsDocType>;
