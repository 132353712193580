import { type Actor, Workspace } from '@valstro/workspace';
import type { FoundationWorkspaceMeta } from '@oms/frontend-foundation';
import type { OfflineDatabase } from '@app/data-access/offline/offline-database';
import type { CommonAuthWindowActorSchema } from '@app/common/auth/auth.contracts';

/**
 * Custom Workspace meta is a place to store specific data relevant to our application.
 */
export type WorkspaceMeta = {
  offlineDatabase: OfflineDatabase;
  authActor: Actor<CommonAuthWindowActorSchema>;
} & FoundationWorkspaceMeta;

/**
 * Extends default Workspace type with our applications meta type.
 */
export type AppWorkspace = Workspace<WorkspaceMeta>;

export type UnconstructedAppWorkspace = new (
  ...args: ConstructorParameters<typeof Workspace>
) => AppWorkspace;

export const AppWorkspace = Workspace as unknown as UnconstructedAppWorkspace;
