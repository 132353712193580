import { type IoRepairRequestFragment, RepairCategory } from '@oms/generated/frontend';
import { type ColumnBuilderCallback, type ColumnLibrary } from '@oms/frontend-vgrid';
// import { t } from '@oms/codegen/translations';
import {
  sharedDefaultCol,
  sharedIdCol,
  sharedQuantityCol,
  sharedTextCol
} from '@app/common/grids/columns/generic-cols';
import { mapRepairCategory } from '@app/common/mappers/map-repair-category';
import {
  RepairErrorCodeRenderer,
  RepairErrorMessageRenderer
} from '@app/common/grids/cell-renderers/errors/errors.cell-renderer';

type IoRepairRequestColumnBuilder = ColumnBuilderCallback<IoRepairRequestFragment>;

const defaultCol: IoRepairRequestColumnBuilder = (c) => sharedDefaultCol(c);

const idCol: IoRepairRequestColumnBuilder = (c) => sharedIdCol(c, 'id').hide();

// TODO: For all columns, apply translations. e.g.:
// .header(t('app.orders.orderMonitor.timeInForce'))
// .shortHeader(t('app.orders.orderMonitor.timeInForce', { ns: 'short' }))

const categoryCol: IoRepairRequestColumnBuilder = (c) =>
  c
    .field('category')
    .header('Category')
    .width(100)
    .filter('agSetColumnFilter')
    .filterParams<RepairCategory>({
      values: Object.values(RepairCategory),
      valueFormatter: ({ value }) => mapRepairCategory(value, 'Unknown')
    });
// TODO: this cell formatter is meant to convert the actual category values
// (e.g. 'IO_CREATE') to the display value (e.g. 'Create'). However, when grouping,
// it messes things up. Look into this.
// .cell((c) => c.valueFormatter(({ data }) => mapRepairCategory(data?.category, 'Unknown')));

const ioInstrumentIdCol: IoRepairRequestColumnBuilder = (c) =>
  sharedIdCol(c, 'ioInstrument.id').header('Instrument ID').width(100).hide();

const ioInstrumentNameCol: IoRepairRequestColumnBuilder = (c) =>
  sharedTextCol(c, 'ioInstrument.mappings.displayCode').header('Symbol').width(100);

const ioAccountIdCol: IoRepairRequestColumnBuilder = (c) =>
  sharedIdCol(c, 'ioAccount.id').header('Account ID').width(100).hide();

const ioAccountNameCol: IoRepairRequestColumnBuilder = (c) =>
  sharedTextCol(c, 'ioAccount.name').header('Account Name').shortHeader('Account').width(150);

// Note: The 'standard' quantity format applies here. Since this is a repair,
// do we want a more forgiving format? (e.g. decimal places, negatives, etc.)
const ioQuantityCol: IoRepairRequestColumnBuilder = (c) =>
  sharedQuantityCol(c, 'ioQuantity').header('Quantity').width(100);

// To achieve the desired decimal places:
// - do NOT use sharedPriceCol nor sharedNumericCol
// - specify 'numericColumn'.
const ioPriceCol: IoRepairRequestColumnBuilder = (c) =>
  sharedTextCol(c, 'ioPrice').header('Price').width(100).type('numericColumn');

// Note: Ideally we should use the sharedTimestampCol. At the moment use 'dateTimeUi'
// formatter registry.
const sourceEventTimestampCol: IoRepairRequestColumnBuilder = (c) =>
  c
    .field('sourceEventTimestamp')
    .header('Event Timestamp')
    .width(200)
    .cell((cb) => cb.valueFormatterRegistry('dateTimeUi'))
    .sort('desc');

const sourceEventPayloadCol: IoRepairRequestColumnBuilder = (c) =>
  sharedTextCol(c, 'sourceEventPayload').header('Event Payload').width(300).hide();

const errorCodeCol: IoRepairRequestColumnBuilder = (c) =>
  c
    .field('errors')
    .header('Error Code')
    .cell((cb) => cb.renderer(RepairErrorCodeRenderer))
    .width(300);

const errorMessageCol: IoRepairRequestColumnBuilder = (c) =>
  c
    .field('errors')
    .header('Error Message')
    .cell((cb) => cb.renderer(RepairErrorMessageRenderer))
    .width(300);

const ioIdCol: IoRepairRequestColumnBuilder = (c) => sharedIdCol(c, 'ioId').header('Order ID').width(300);

export const ioRepairRequestColumnLibrary: ColumnLibrary<IoRepairRequestFragment> = {
  defaultColumn: defaultCol,
  columns: [
    idCol,
    categoryCol,
    ioInstrumentIdCol,
    ioInstrumentNameCol,
    ioAccountIdCol,
    ioAccountNameCol,
    ioQuantityCol,
    ioPriceCol,
    sourceEventTimestampCol,
    sourceEventPayloadCol,
    errorCodeCol,
    errorMessageCol,
    ioIdCol
  ]
};
