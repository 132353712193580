import { type ActionDefFactory } from '@oms/frontend-vgrid';
import { MONTAGE_BUMP_PRICE_ACTION_NAME } from './bump-price.action.types';
import {
  type ActionCommandConfig,
  type ActionCommandContracts
} from '@app/actions/commands/command.registry.types';

export const MONTAGE_BUMP_PRICE_ACTION: ActionDefFactory = (b) =>
  b
    .name(MONTAGE_BUMP_PRICE_ACTION_NAME)
    .toolbar((t) =>
      t.location('LeftVerticalToolbar').component('icon-counter-button').id(MONTAGE_BUMP_PRICE_ACTION_NAME)
    )
    .lifecycles('change')
    .onChange((e) => {
      const { config /*, state */ } = e;
      //const montageService = container.resolve(MontageService);
      //const { countType } = state;
      const { actionContext$ } = config as unknown as ActionCommandConfig<
        ActionCommandContracts['montage_bump_price']
      >;
      if (actionContext$) {
        const bumpPriceState = actionContext$.getCommand('montage_bump_price');

        if (bumpPriceState) {
          const targetState = bumpPriceState.targetState;

          if (targetState?.currentTarget.id && bumpPriceState.bumpPrice) {
            // modify order here.
          }
        }
      }
    });
