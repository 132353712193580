import { type Strategy, StrategyQuery } from '@valstro/fixatdl-core';
import { fixatdlFormComponentTypes } from '../fixatdl-component.mappers';
import { STRATEGY_FIELD } from '../../utils';
import { type AtdlFieldPartial } from './controls/control.transformer.types';
import { transformPanels } from './controls/panel.control.transformer';

export const transformToDdf = (
  strategy: Strategy,
  initialValues: Record<string, any> = {}
): AtdlFieldPartial[] => {
  const panels = new Map<string, number>();
  const layouts = strategy.layouts || [];

  const query = StrategyQuery.from(strategy);
  const formValues = query.values(initialValues);

  return layouts
    .flatMap((l) => transformPanels(l.panels || [], panels, query, formValues))
    .concat({
      component: fixatdlFormComponentTypes.HIDDEN_FIELD,
      name: STRATEGY_FIELD,
      initialValue: JSON.stringify(strategy)
    });
};
