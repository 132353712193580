import React from 'react';
import { MagnifyingGlassIcon, BellIcon } from '@radix-ui/react-icons';
import {
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Center,
  Flex,
  HStack,
  IconButton
} from '@oms/shared-frontend/ui-design-system';
import { useCommandPaletteService } from '@app/data-access/services/system/command-palette/command-palette.hooks';
import { LogoComponent } from '@app/components/logo/logo';
import { type PropsWithChildren, useMemo } from 'react';
import { openSystemPlaceholder } from '@app/generated/sdk';
import { useAuthState } from '@app/data-access/services/system/auth/auth.hooks';
import { getEnvVar } from '@app/common/env/env.util';
import * as styles from './launcher-frame.container.css';
import { useObservableState } from 'observable-hooks';
import { IS_TAURI } from '@app/common/workspace/workspace.constants';
export interface LauncherFrameContainerProps {
  showLauncher?: boolean;
  showShortcut?: boolean;
}

export const LauncherFrameContainer: React.FC<PropsWithChildren<LauncherFrameContainerProps>> = ({
  children,
  showLauncher = true,
  showShortcut = false
}) => {
  const auth = useAuthState();
  const commandPaletteService = useCommandPaletteService();
  const isCommandPaletteReady = useObservableState(commandPaletteService.isReady$, false);

  const openCommandPalette = () => {
    // Timeout is to fix race condition with the blur event.
    // Command palette will be closed if it's opened immediately after the blur event (which it listens to)
    // This is a workaround until we have a better solution.
    setTimeout(() => {
      commandPaletteService.open();
    }, 50);
  };

  const openNotifications = () => {
    if (!auth.isAuthenticated) {
      return;
    }
    openSystemPlaceholder('currentWindow').catch(console.error);
  };

  const openProfile = () => {
    if (!auth.isAuthenticated) {
      return;
    }
    openSystemPlaceholder('currentWindow').catch(console.error);
  };

  const hasChildren = React.Children.count(children) > 0;

  const wrapperStyles = useMemo(
    () => (hasChildren ? { height: '100%', width: '100%' } : { height: 36, width: '100%' }),
    [hasChildren, showLauncher]
  );

  if (showLauncher === false) {
    return (
      <Flex style={wrapperStyles} direction="column">
        {children}
      </Flex>
    );
  }

  return (
    <Flex style={wrapperStyles} direction="column">
      <Flex
        style={{
          height: hasChildren ? 36 : '100%',
          paddingLeft: 6,
          paddingRight: 0,
          width: 'calc(100% - 12px)',
          flexShrink: 1
        }}
        align="center"
      >
        <div
          data-tauri-drag-region=""
          style={{ height: '100%', flexGrow: 1, cursor: IS_TAURI ? 'move' : undefined }}
        >
          <HStack align="center" style={{ height: '100%', alignItems: 'center', pointerEvents: 'none' }}>
            <LogoComponent />
            {IS_TAURI && (
              <Badge style={{ userSelect: 'none' }}>
                {getEnvVar('NX_ENVIRONMENT', shortenNodeEnv(getEnvVar('NODE_ENV')))}
              </Badge>
            )}
          </HStack>
        </div>

        <HStack align="center" sx={{ marginLeft: 'auto' }}>
          <IconButton
            aria-label="Open search"
            variant="ghost"
            disabled={!isCommandPaletteReady}
            data-suppress-event="click--inside"
            icon={<MagnifyingGlassIcon />}
            onMouseUp={openCommandPalette}
          />
          <IconButton
            aria-label="Notifications"
            variant="ghost"
            icon={<BellIcon />}
            onClick={openNotifications}
            isDisabled={!auth.isAuthenticated}
          />
          <Avatar sx={{ cursor: auth.isAuthenticated ? 'pointer' : undefined }} onClick={openProfile}>
            <AvatarBadge
              backgroundColor={auth.isAuthenticated ? 'Green.500' : 'Red.500'}
              position="bottom-right"
            />
          </Avatar>
        </HStack>
      </Flex>
      {hasChildren && (
        <Flex grow={1} sx={{ position: 'relative' }}>
          {showShortcut && (
            <Center sx={{ textAlign: 'center', width: 'full' }}>
              {isCommandPaletteReady && (
                <Box
                  className={styles.commandPaletteShortcut}
                  aria-label="Open commands"
                  onClick={openCommandPalette}
                >
                  <Box as="span" sx={{ paddingRight: 2 }}>
                    Open Commands
                  </Box>
                  <Box as="span" style={{ position: 'relative', top: -1 }}>
                    <Badge>Ctrl</Badge> + <Badge>\</Badge>
                  </Box>
                </Box>
              )}
            </Center>
          )}
          {children}
        </Flex>
      )}
    </Flex>
  );
};

function shortenNodeEnv(nodeEnv = 'DEV'): string {
  if (nodeEnv === 'development') {
    return 'DEV';
  }
  if (nodeEnv === 'production') {
    return 'PROD';
  }
  if (nodeEnv === 'test') {
    return 'TEST';
  }
  return nodeEnv;
}
