import { ModificationStatus } from '@oms/generated/frontend';
import { useVGrid, VGrid, CLEAR_GRID_FILTER_ACTION_TYPE } from '@oms/frontend-vgrid';
import type { VisibleModificationInfoFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { PendingModificationsService } from './pending-modifications.datasource.service';
import { pendingModificationsColumnLibrary } from './pending-modifications.columns';
import { inlinePendingModificationsActions } from './grid-actions/inline.pending-modification.action';
import { acceptAllPendingModificationAction } from './grid-actions/accept-all.pending-modification.action';
import { broadcastGridSelection } from '@app/data-access/memory/grid.events';

export const PENDING_MODIFICATIONS = 'pending-modifications';

export const PendingModificationsGridWidget = () => {
  const datasourceService = useService(PendingModificationsService);

  const gridProps = useVGrid<VisibleModificationInfoFragment>(
    PENDING_MODIFICATIONS,
    (b) =>
      b
        .columnLibrary(pendingModificationsColumnLibrary)
        .datasource((d) =>
          d.source(datasourceService.getPendingModifications$()).rowId((r) => r.data.id || '')
        )
        .rowSelection((c) => c.single().broadcast(broadcastGridSelection()).selectRowsOnFirstDataRender([0]))
        .rowStateRules({
          noAction: (params) => params.data?.status !== ModificationStatus.New,
          takeAction: (params) => params.data?.status === ModificationStatus.New
        })
        .sideBar()
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(inlinePendingModificationsActions)
                .action(acceptAllPendingModificationAction)
            )
            .settings((s) => s.fromFile({}))
        ),
    [datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default PendingModificationsGridWidget;
