import React from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import {
  Box,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger,
  Text,
  Tooltip
} from '@oms/shared-frontend/ui-design-system';
import { type FeedbackWrapper, type RepairError, FormValidationFeedbackLevel } from '@oms/generated/frontend';
export const ERRORS_CELL_RENDERER = 'errorsCellRenderer';

const ErrorText = ({ error }: { error: FeedbackWrapper }) => {
  const warnStyle =
    error?.level === FormValidationFeedbackLevel.Warning ? { color: 'var(--colors-text-warning)' } : {};
  return (
    <Text type={'gridBase'} style={warnStyle}>
      {error?.level}: {error?.message}
    </Text>
  );
};

const ErrorList = ({ errors }: { errors: FeedbackWrapper[] }) => (
  <ul style={{ padding: 0 }}>
    {errors.map((error) => (
      <li key={error?.code} style={{ listStyleType: 'none' }}>
        <ErrorText error={error} />
      </li>
    ))}
  </ul>
);

/**
 * Renderer for the ValidationError object type.
 */
export const ErrorsCellRenderer: React.FC<ICellRendererParams<FeedbackWrapper>> = ({
  value
}: {
  value: FeedbackWrapper[];
}) => {
  if (!value || value.length === 0) return null;

  return value.length === 1 ? (
    <ErrorText error={value[0]} />
  ) : (
    <Tooltip label={<ErrorList errors={value} />}>{value.length} errors</Tooltip>
  );
};

/**
 * Renderer for the RepairError object, meant for the error message column.
 */
export const RepairErrorMessageRenderer: React.FC<ICellRendererParams> = ({
  value
}: {
  value: RepairError[];
}) => {
  if (!value) return null;

  if (value.length === 1) {
    return <span>{value[0]?.message}</span>;
  }

  if (value.length > 1) {
    const list = value.map((error) => (
      <DropdownMenuItem key={error?.errorCode || ''}>{error?.message}</DropdownMenuItem>
    ));

    return (
      <DropdownMenuRoot>
        <DropdownMenuTrigger asChild>
          <Box type="button" role="button" sx={{ cursor: 'pointer', color: 'BrandBlue.300' }}>
            {value.length} error messages
          </Box>
        </DropdownMenuTrigger>
        <DropdownMenuPortal>
          <DropdownMenuContent>{list}</DropdownMenuContent>
        </DropdownMenuPortal>
      </DropdownMenuRoot>
    );
  }

  return null;
};

/**
 * Renderer for the RepairError object, meant for the error code column.
 */
export const RepairErrorCodeRenderer: React.FC<ICellRendererParams> = ({
  value
}: {
  value: RepairError[];
}) => {
  if (!value) return null;

  if (value.length === 1) {
    return <span>{value[0]?.errorCode}</span>;
  }

  if (value.length > 1) {
    const list = value.map((error) => (
      <DropdownMenuItem key={error?.errorCode || ''}>{error?.errorCode}</DropdownMenuItem>
    ));

    return (
      <DropdownMenuRoot>
        <DropdownMenuTrigger asChild>
          <Box type="button" role="button" sx={{ cursor: 'pointer', color: 'BrandBlue.300' }}>
            {value.length} error codes
          </Box>
        </DropdownMenuTrigger>
        <DropdownMenuPortal>
          <DropdownMenuContent>{list}</DropdownMenuContent>
        </DropdownMenuPortal>
      </DropdownMenuRoot>
    );
  }

  return null;
};
