import clsx from 'clsx';
import { SpinnerVariants } from './spinner.css';
import * as styles from './spinner.css';
import { Flex } from '../../layout/flex/flex';
import * as commonStyles from '../../system/styles.css';
import { Box, type BoxProps } from '../../system/components/box/box';
import { ConditionalWrapper } from '../../system/components/conditional-wrapper/conditional-wrapper';
import { Sprinkles } from '../../system/sprinkles.css';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

export type SpinnerProps = {
  fillArea?: boolean;
  hasBg?: boolean;
  bgColor?: Sprinkles['bgColor'];
  zIndex?: number;
  opacity?: number;
} & SpinnerVariants &
  BoxProps;

export const Spinner = polymorphicComponent<'div', SpinnerProps>((props, ref) => {
  const {
    as,
    className,
    children,
    size,
    fillArea,
    hasBg = true,
    bgColor,
    zIndex = 1,
    opacity,
    ...rest
  } = props;

  return (
    <ConditionalWrapper
      condition={fillArea}
      // eslint-disable-next-line react/jsx-no-bind
      wrapper={(c) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: hasBg ? (bgColor ? bgColor : 'layout.level1') : 'transparent'
          }}
          style={{ zIndex, opacity }}
          className={clsx(fillArea && commonStyles.fill)}
        >
          {c}
        </Box>
      )}
    >
      <Flex style={{ zIndex, opacity }}>
        <Box as={as} {...rest} className={clsx(styles.spinner({ size }), className)} ref={ref}>
          <span data-testid="spinner" className="spinner-icon" />
        </Box>
        {children && <Box sx={{ ml: 3 }}>{children}</Box>}
      </Flex>
    </ConditionalWrapper>
  );
});

if (__DEV__) {
  Spinner.displayName = 'Spinner';
}
