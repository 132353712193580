import { type Sprinkles } from '@oms/shared-frontend/ui-design-system';
import { type CSSProperties, type MouseEventHandler, type PropsWithChildren } from 'react';
import { type ICommonField, type ICommonSelectField } from '../../types';
import { type FORM_COMPONENT_TYPE } from '../../contracts';

export type Combinators = 'and' | 'or';

export enum RuleDragItemTypes {
  RULE = 'rule',
  RULE_GROUP = 'rule_group'
}

export interface RuleGroup<TRule = any> {
  combinator: Combinators;
  rules: (TRule | RuleGroup<TRule>)[];
}

export interface RuleDragCollectionProps {
  isDragging?: boolean;
  path: number[];
  // [0] -- first element
  // [0,1] -- second element in first group
}

export interface RuleProps extends PropsWithChildren {
  level?: number;
  index?: number;
  onDelete?: React.MouseEventHandler<HTMLElement>;
  path: number[];
  sx?: Sprinkles;
  styles?: CSSProperties;
}

export interface RuleGroupAction {
  onClick: MouseEventHandler<HTMLElement>;
  text: string;
}

export interface RuleGroupProps extends RuleProps {
  addGroup?: RuleGroupAction;
  addRule?: RuleGroupAction;
  hideActionMenu?: boolean;
}

// TODO: Upgrade to use the new AdvancedSelect when migrated
type RemovedAutoComplete = ICommonSelectField<any>;

export interface RuleBuilderField<
  TField extends ICommonSelectField<any> | RemovedAutoComplete = ICommonSelectField<any>,
  TOperator extends ICommonSelectField<any> | RemovedAutoComplete = ICommonSelectField<any>,
  TFilterValue extends ICommonField<any> = ICommonField<any>
> extends ICommonField<typeof FORM_COMPONENT_TYPE.RULE_BUILDER, RuleGroup> {
  field: TField;
  operator: TOperator;
  filterValue: TFilterValue;
  addRuleText?: string;
  addRuleGroupText?: string;
}
