import {
  type Maybe,
  type MontagePreferencesFragment,
  OrderSide,
  OrderSideType,
  PriceType,
  QuantityType,
  TimeInForce
} from '@oms/generated/frontend';
import { comboBoxItemFrom } from '@oms/frontend-foundation';
import type { MontagePreferencesFieldContract } from './montage-preferences.types';
import { type MontagePreferencesContractFormValues } from './montage-preferences.form-contract';
import { sideTypeMapping } from '@app/common/mappers/order-enums';
import type { OrderSizeProfile } from '../order-settings/order-settings.types';
import {
  createStrategyComboboxItem,
  createStrategyInput
} from '@app/widgets/trading/route-order/fixatdl-strategy-field/fixatdl-strategy-field.util';

export const getOrderSideTypeLabel = (side: OrderSideType): string => sideTypeMapping[side];

export const getQuantityLabel = (quantity: QuantityType): string => {
  switch (quantity) {
    case QuantityType.Blank:
      return 'Blank';
    case QuantityType.ExplicitValue:
      return 'Explicit value';
    case QuantityType.OrderSizes:
      return 'Order sizes';
  }
};

export const getPriceTypeLabel = (type: PriceType): string => {
  switch (type) {
    case PriceType.Blank:
      return 'Blank';
    case PriceType.BidInside:
      return 'Bid';
    case PriceType.AskInside:
      return 'Ask';
    case PriceType.Selection:
      return 'Selection';
    case PriceType.Market:
      return 'Market';
  }
};

export const defaultMontageSettingsInput = {
  askSideType: OrderSide.Sell,
  askQauntityType: QuantityType.Blank,
  askPriceType: PriceType.AskInside,
  askTimeInForceType: TimeInForce.Day,

  bidSideType: OrderSide.Buy,
  bidQauntityType: QuantityType.Blank,
  bidPriceType: PriceType.AskInside,
  bidTimeInForceType: TimeInForce.Day
};

const comboBoxItemFromOrderSize = (profile: Maybe<OrderSizeProfile>) =>
  comboBoxItemFrom.record<OrderSizeProfile>(profile, {
    label: ({ description }) => description,
    id: ({ id }) => id
  });
// TODO: temprorary approach, to prevent the bug with validation and saving quantitiy order size without order size selected from order settings
const blankIfNoOrderSize = (profile: Maybe<OrderSizeProfile>) => (quantityType: Maybe<QuantityType>) =>
  !profile && quantityType === QuantityType.OrderSizes ? QuantityType.Blank : quantityType;

const createQuantityValueOutput = (quantityType: QuantityType, quantityValue: string | number | undefined) =>
  quantityType === QuantityType.ExplicitValue
    ? typeof quantityValue === 'string'
      ? Number.parseFloat(quantityValue)
      : quantityValue
    : undefined;

const createDisplaySizeOutput = (displaySize: string | number | undefined) => {
  const possiblyString = typeof displaySize === 'string' ? Number.parseFloat(displaySize) : displaySize;
  return typeof possiblyString === 'number' && !Number.isNaN(possiblyString) ? possiblyString : undefined;
};

export function montageSettingsToFormValues(
  settings: Maybe<MontagePreferencesFragment>,
  profile: Maybe<OrderSizeProfile>
): Partial<MontagePreferencesContractFormValues> {
  const s = settings;

  const comboBoxFromOrderSize = comboBoxItemFromOrderSize(profile);

  const blankIfNoOrderSizeWithProfile = blankIfNoOrderSize(profile);

  const bidQuantity = blankIfNoOrderSizeWithProfile(s?.bidQuantityType);
  const askQuantity = blankIfNoOrderSizeWithProfile(s?.askQuantityType);

  return {
    // bid
    bidInitiateOrder: s?.bidInitiateOrder ?? false,
    bidSideType: s?.bidSideType,
    ...(s?.bidQuantityType === QuantityType.ExplicitValue
      ? {
          bidQuantityValue: s?.bidQuantityValue ? s.bidQuantityValue : undefined,
          bidQuantityType: s?.bidQuantityType
        }
      : { bidQuantityType: bidQuantity }),
    bidOrderSize: comboBoxFromOrderSize,
    bidPriceType: s?.bidPriceType || undefined,
    bidTimeInForceType: s?.bidTimeInForceType ? s.bidTimeInForceType : undefined,
    bidGtdTimestamp:
      s?.bidTimeInForceType === TimeInForce.Gtd && s?.bidGtdTimestamp ? s.bidGtdTimestamp : undefined,
    bidTifDuration:
      s?.bidTimeInForceType === TimeInForce.Duration && s?.bidTifDuration ? s.bidTifDuration : undefined,
    bidDisplaySize: s?.bidDisplaySize ? Number.parseFloat(String(s.bidDisplaySize)) : undefined,
    bidDestinationId: s?.bidDestinationId
      ? comboBoxItemFrom.string(s?.bidDestinationId, { label: (id) => id })
      : undefined,
    bidStrategy: createStrategyComboboxItem(s?.bidStrategy, s?.bidDestinationId || ''),
    // ask
    askInitiateOrder: s?.askInitiateOrder ?? undefined,
    askSideType: s?.askSideType,
    ...(s?.askQuantityType === QuantityType.ExplicitValue
      ? {
          askQuantityValue: s?.askQuantityValue ? s.askQuantityValue : undefined,
          askQuantityType: s?.askQuantityType
        }
      : { askQuantityType: askQuantity }),
    askOrderSize: comboBoxFromOrderSize,
    askPriceType: s?.askPriceType || undefined,
    askTimeInForceType: s?.askTimeInForceType ? s.askTimeInForceType : undefined,
    askGtdTimestamp:
      s?.askTimeInForceType === TimeInForce.Gtd && s?.askGtdTimestamp ? s.askGtdTimestamp : undefined,
    askTifDuration:
      s?.askTimeInForceType === TimeInForce.Duration && s?.askTifDuration ? s.askTifDuration : undefined,
    askDisplaySize: s?.askDisplaySize ? Number.parseFloat(String(s.askDisplaySize)) : undefined,
    askDestinationId: comboBoxItemFrom.string(s?.askDestinationId, { label: (id) => id }),
    askStrategy: createStrategyComboboxItem(s?.askStrategy, s?.askDestinationId || ''),
    // common
    sendAttributable: s?.sendAttributable ?? undefined,
    hideOddLots: s?.hideOddLots ?? undefined,
    displayQuotesInShares: s?.displayQuotesInShares ?? undefined
  };
}

export const montageSettingsFormValuesToOutput = ({
  bidInitiateOrder,
  bidSideType,
  bidOrderSize,
  bidQuantityType,
  bidQuantityValue,
  bidPriceType,
  bidTimeInForceType,
  bidGtdTimestamp,
  bidTifDuration,
  bidDisplaySize,
  bidDestinationId,
  bidStrategy,
  askInitiateOrder,
  askSideType,
  askQuantityType,
  askQuantityValue,
  askOrderSize,
  askPriceType,
  askTimeInForceType,
  askGtdTimestamp,
  askTifDuration,
  askDisplaySize,
  askDestinationId,
  askStrategy,
  sendAttributable,
  hideOddLots,
  displayQuotesInShares
}: MontagePreferencesContractFormValues): MontagePreferencesFieldContract => {
  const askOrderSizeValue = askOrderSize?.id ? askOrderSize?.id : undefined;
  const bidOrderSizeValue = bidOrderSize?.id ? bidOrderSize?.id : undefined;

  const isAskQuantityOrderSizes = askQuantityType === QuantityType.OrderSizes;
  const isBidQuantityOrderSizes = bidQuantityType === QuantityType.OrderSizes;

  return {
    // bid
    bidInitiateOrder: bidInitiateOrder ?? false,
    bidSideType: bidSideType || OrderSideType.Buy,
    bidPriceType: bidPriceType || PriceType.BidInside,
    bidTimeInForceType: bidTimeInForceType || TimeInForce.Day,
    bidGtdTimestamp: bidTimeInForceType === TimeInForce.Gtd ? (bidGtdTimestamp as string) : undefined,
    bidTifDuration: bidTimeInForceType === TimeInForce.Duration ? bidTifDuration : undefined,
    bidDisplaySize: createDisplaySizeOutput(bidDisplaySize),
    bidDestinationId: bidDestinationId?.id ?? undefined,
    bidStrategy: createStrategyInput(bidStrategy?.value, bidStrategy?.label),
    bidQuantityType: bidQuantityType || QuantityType.Blank,
    bidQuantityValue: createQuantityValueOutput(bidQuantityType, bidQuantityValue),
    bidOrderSize: isBidQuantityOrderSizes ? bidOrderSizeValue : undefined,
    // ask
    askInitiateOrder: askInitiateOrder ?? false,
    askSideType: askSideType || OrderSideType.Sell,
    askPriceType: askPriceType || PriceType.AskInside,
    askTimeInForceType: askTimeInForceType || TimeInForce.Day,
    askGtdTimestamp: askTimeInForceType === TimeInForce.Gtd ? (askGtdTimestamp as string) : undefined,
    askTifDuration: askTimeInForceType === TimeInForce.Duration ? askTifDuration : undefined,
    askDisplaySize: createDisplaySizeOutput(askDisplaySize),
    askDestinationId: askDestinationId?.id ?? undefined,
    askStrategy: createStrategyInput(askStrategy?.value, askStrategy?.label),
    askQuantityType: askQuantityType || QuantityType.Blank,
    askQuantityValue: createQuantityValueOutput(askQuantityType, askQuantityValue),
    askOrderSize: isAskQuantityOrderSizes ? askOrderSizeValue : undefined,
    // common
    sendAttributable: sendAttributable ?? false,
    hideOddLots: hideOddLots ?? false,
    displayQuotesInShares: displayQuotesInShares ?? false
  };
};
