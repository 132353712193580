import { OrderType } from '@oms/generated/frontend';
import type { PrincipalFillGridRow } from './principal-fill-field.component';
import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import { sharedPriceCol, sharedQuantityCol } from '@app/common/grids/columns/generic-cols';
import { sharedSideCol, sharedInvestorAccountCol } from '@app/common/grids/columns/order-cols';

export const sideTypeCol: ColumnBuilderCallback<PrincipalFillGridRow> = (c) => sharedSideCol<PrincipalFillGridRow>(c)

export const investorAccountCol: ColumnBuilderCallback<PrincipalFillGridRow> = (c) =>
  sharedInvestorAccountCol<PrincipalFillGridRow>(c, 'investorAccount.name')
    .header('Account')
    .shortHeader('Account')
    .width(265);

const limitPriceCol: ColumnBuilderCallback<PrincipalFillGridRow> = (c) =>
  sharedPriceCol<PrincipalFillGridRow>(c, 'limitPrice')
    .header('Px')
    .width(140)
    .cell((c) =>
      c.valueFormatter((args) => {
        return args.data?.limitPrice?.toString() || OrderType.Market;
      })
    );

export const openQuantityCol: ColumnBuilderCallback<PrincipalFillGridRow> = (c) =>
  sharedQuantityCol<PrincipalFillGridRow>(c, 'openQuantity')
    .header('Open qty')
    .shortHeader('Open')
    .format('quantity')
    .width(140);

export const fillQuantity: ColumnBuilderCallback<PrincipalFillGridRow> = (c) =>
  sharedQuantityCol<PrincipalFillGridRow>(c, 'fillQuantity')
    .header('Fill qty')
    .shortHeader('Fill')
    .format('quantity')
    .width(140);

export const principalFillColumnLibrary: ColumnLibrary<PrincipalFillGridRow> = {
  columns: [sideTypeCol, investorAccountCol, limitPriceCol, openQuantityCol, fillQuantity]
};
