import { type ValidatorFunction } from '@data-driven-forms/react-form-renderer';
import { convertStringToNumber } from '@oms/shared/util';

export type MinNumberVal = {
  value: number;
  message?: string;
  includeThreshold?: boolean;
  ignoreZero?: boolean;
};

export const minNumberValue: (opts: MinNumberVal) => ValidatorFunction =
  ({ value, message = `Min is ${value}`, includeThreshold = true, ignoreZero = false }) =>
  (formVal: any, _allValues: any) => {
    const convertedFormValue = convertStringToNumber(formVal);
    const isEmpty = !convertedFormValue && (convertedFormValue !== 0 || ignoreZero);
    const isLessThan = includeThreshold ? convertedFormValue < value : convertedFormValue <= value;
    const shouldError = !isEmpty && isLessThan;

    return shouldError ? message : null;
  };
