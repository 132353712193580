import {
  FormContract,
  FieldDefinition,
  type InferFormValuesFromFormContract,
  FieldContract
} from '@oms/frontend-foundation';
import type { SaveSnapshotFormSchemaInput } from './save-snapshot.form-builder.config';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';

// Common
export type SaveSnapshotFormInput = SaveSnapshotFormSchemaInput;
export type SaveSnapshotFormOutput = {
  name: string;
};

// Fields
const fc = FieldContract.create<SaveSnapshotFormOutput, AdditionalFormFieldUnion>();

export const nameField = fc.field('name', 'text-field').options({
  label: 'Name',
  isRequired: true,
  autoFocus: true,
  validate: [{ type: 'required' }]
});

// Form contract
export const saveSnapshotFormContract = FormContract.create<SaveSnapshotFormOutput>()
  .fields({
    name: nameField
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box('wrapper', [f.name], {
        sx: {}
      })
    ]
  }))
  .template('simple', {
    submitLabel: 'Save',
    cancelLabel: 'Cancel',
    formFieldsSx: { px: 4, pb: 2, marginTop: 4 }
  });

export type SaveSnapshotFormContractType = typeof saveSnapshotFormContract;

export type SaveSnapshotFormValues = InferFormValuesFromFormContract<SaveSnapshotFormContractType>;
