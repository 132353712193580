import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import type {
  AnyRecord,
  FieldContract,
  IAdvancedSelectField,
  ModifiedFieldForOptions
} from '@oms/frontend-foundation';
import type { CurrencyFragment } from '@oms/generated/frontend';

export const createCurrencyField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedSelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'advanced-select').advancedSelectOptions<CurrencyFragment>({
    width: 200,
    query: ADVANCED_SELECT_QUERY_ENUM.CURRENCIES_WATCH_QUERY,
    ...overrides
  });
