import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type {
  AnyRecord,
  FieldContract,
  ISelectField,
  ModifiedFieldForOptions
} from '@oms/frontend-foundation';
import { OrderSideType } from '@oms/generated/frontend';

export const createSideField = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<ISelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'select').options<ISelectField<any>>({
    initialValue: OrderSideType.Buy,
    label: 'Side',
    style: {
      minWidth: 'max-content'
    },
    options: [
      { value: OrderSideType.Buy, label: 'Buy' },
      { value: OrderSideType.Btc, label: 'BTC' },
      { value: OrderSideType.Sell, label: 'Sell' },
      { value: OrderSideType.Short, label: 'Short' },
      { value: OrderSideType.Exempt, label: 'Exempt' }
    ],
    ...overrides
  });
