import { FieldDefinition } from '@oms/frontend-foundation';
import { type IMarketDataFieldOptions } from './market-data-field.component';

export const createMarketDataField = (name: string, options: IMarketDataFieldOptions) => {
  return FieldDefinition.box(
    `${name}-wrapper`,
    [
      {
        name,
        component: 'market-data',
        ...options
      }
    ],
    {
      sx: {
        marginBottom: 1
      }
    }
  );
};
