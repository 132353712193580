import type { TradingOrderCategory } from '@oms/generated/frontend';
import type { Maybe } from '@oms/shared/util-types';
import { tradingOrderCategoryMapping } from './order-enums';

export const mapTradingOrderCategory = (
  value?: Maybe<TradingOrderCategory>,
  defaultVal = value || ''
): string => {
  return value && (tradingOrderCategoryMapping[value as never] as string)
    ? tradingOrderCategoryMapping[value as never]
    : defaultVal;
};
