import type { EventHandler, EventSource, GridEventType } from '@oms/frontend-vgrid';
import { inject, injectable } from 'tsyringe';
import { openRouteOrder } from '@app/generated/sdk';
import UsersService from '@app/data-access/services/reference-data/users/users.service';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import {
  type OrderSideType,
  QuantityType,
  TradingOrderCategory,
  type MontagePreferences,
  TimeInForce,
  type OrderSettingsFragment,
  PriceType
} from '@oms/generated/frontend';
import { type CellClickedEvent } from '@ag-grid-community/core';
import { type AnyRecord } from '@valstro/workspace';
import { tifFormValues } from '@app/forms/common/fields/TIF-field/TIF-field.common';
import { MarketDataService } from '@app/data-access/services/marketdata/marketdata.service';
import { IMarketDataService } from '@app/data-access/services/marketdata/marketdata.common';
import { z } from 'zod';
import { type Optional, type Maybe } from '@oms/shared/util-types';
import { type RouteOrderFormInputSchema } from '../../route-order/route-order.form-builder.config';

const cellContextSchema = z.object({
  instrumentId: z.string(),
  instrumentDisplayCode: z.string()
});

const cellSchema = z.object({
  data: z.object({
    type: z.enum(['bid', 'ask']),
    id: z.string(),
    limitPrice: z.number().optional()
  }),
  context: cellContextSchema
});

@injectable()
export class LeftClickHandler implements EventHandler {
  public name = 'left-click-handler';

  constructor(@inject(MarketDataService) private marketDataService: IMarketDataService) {}

  public addEvents(eventSource: EventSource<GridEventType>): void {
    eventSource.add('onCellClicked', async (e) => {
      this.assertCellData(e);

      const context = cellContextSchema.parse(e.api.getGridOption('context'));

      const authService = eventSource.container.resolve(AuthService);
      const userId = authService.getUserId();

      if (!userId) {
        throw new Error('User id not found');
      }

      const usersService = eventSource.container.resolve(UsersService);

      const userPreferencesResponse = await usersService.getUserPreferences(userId);

      const montagePreferences = userPreferencesResponse.data.getUserPreferences?.montagePreferences;

      const orderSettings = userPreferencesResponse.data.getUserPreferences?.orderSettings;

      if (!orderSettings) {
        // Do nothing if the user has no order settings
        return;
      }

      if (!montagePreferences) {
        // Do nothing if the user has no preferences
        return;
      }

      const shouldOpenRouteOrderDialog =
        (e.data.type === 'bid' && montagePreferences.bidInitiateOrder) ||
        (e.data.type === 'ask' && montagePreferences.askInitiateOrder);

      // If the user has not selected either 'initiate order' checkbox, we can return early
      if (!shouldOpenRouteOrderDialog) {
        return;
      }

      if (shouldOpenRouteOrderDialog) {
        await openRouteOrder(undefined, {
          title: `Unbound Trading Order: ${context.instrumentDisplayCode}`,
          form: {
            formId: `unbound-trading-order-${e.data.id}`,
            input: {
              initialCreateOptions: {
                instrumentDisplayCode: context.instrumentDisplayCode,
                instrumentId: context.instrumentId,
                limitPrice: this.getPrice({ montagePreferences, e }),
                quantity: this.getQuantity({ montagePreferences, e, orderSettings }),
                sideType: this.getSideType({ montagePreferences, e }),
                category: TradingOrderCategory.Montage,
                venueId: this.getVenueId({ montagePreferences, e }),
                strategy: this.getStrategy({ montagePreferences, e }),
                ...tifFormValues({
                  timeInForce: this.getTimeInForce({ montagePreferences, e }),
                  gtdTimestamp: this.getGtdTimestamp({ montagePreferences, e }), // This will be `undefined` unless the timeInForce is Gtd
                  tifDuration: this.getDuration({ montagePreferences, e }) // This will be `undefined` unless the timeInForce is Duration
                })
              }
            }
          }
        });
      }
    });
  }

  private assertCellData(input: unknown): asserts input is z.infer<typeof cellSchema> {
    cellSchema.parse(input);
  }

  private getStrategy({
    montagePreferences,
    e
  }: {
    montagePreferences: MontagePreferences;
    e: CellClickedEvent<AnyRecord, any>;
  }): NonNullable<RouteOrderFormInputSchema['initialCreateOptions']>['strategy'] | undefined {
    this.assertCellData(e);

    if (e.data.type === 'bid') {
      return montagePreferences.bidStrategy as NonNullable<
        RouteOrderFormInputSchema['initialCreateOptions']
      >['strategy'];
    }

    if (e.data.type === 'ask') {
      return montagePreferences.askStrategy as NonNullable<
        RouteOrderFormInputSchema['initialCreateOptions']
      >['strategy'];
    }
  }

  private getVenueId({
    montagePreferences,
    e
  }: {
    montagePreferences: MontagePreferences;
    e: CellClickedEvent<AnyRecord, any>;
  }): Maybe<string> {
    this.assertCellData(e);

    if (e.data.type === 'bid') {
      return montagePreferences.bidDestinationId;
    }

    if (e.data.type === 'ask') {
      return montagePreferences.askDestinationId;
    }

    throw new Error('Invalid type, must be "bid" or "ask"');
  }

  private getTimeInForce({
    montagePreferences,
    e
  }: {
    montagePreferences: MontagePreferences;
    e: CellClickedEvent<AnyRecord, any>;
  }): TimeInForce {
    this.assertCellData(e);

    if (e.data.type === 'bid') {
      if (!montagePreferences.bidTimeInForceType) {
        throw new Error('Bid TimeInForce type not found in montage preferences');
      }

      return montagePreferences.bidTimeInForceType;
    }

    if (e.data.type === 'ask') {
      if (!montagePreferences.askTimeInForceType) {
        throw new Error('Ask TimeInForce type not found in montage preferences');
      }

      return montagePreferences.askTimeInForceType;
    }

    throw new Error('Invalid type, must be "bid" or "ask"');
  }

  private getGtdTimestamp({
    montagePreferences,
    e
  }: {
    montagePreferences: MontagePreferences;
    e: CellClickedEvent<AnyRecord, any>;
  }): Optional<string> {
    this.assertCellData(e);

    if (e.data.type === 'bid') {
      if (!montagePreferences.bidTimeInForceType) {
        throw new Error('Bid TimeInForce type not found in montage preferences');
      }

      if (montagePreferences.bidTimeInForceType === TimeInForce.Gtd) {
        if (!montagePreferences.bidGtdTimestamp) {
          throw new Error('Bid TimeInForce Gtd timestamp not found in montage preferences');
        }

        return montagePreferences.bidGtdTimestamp;
      }

      return;
    }

    if (e.data.type === 'ask') {
      if (!montagePreferences.askTimeInForceType) {
        throw new Error('Ask TimeInForce type not found in montage preferences');
      }

      if (montagePreferences.askTimeInForceType === TimeInForce.Gtd) {
        if (!montagePreferences.askGtdTimestamp) {
          throw new Error('Ask TimeInForce Gtd timestamp not found in montage preferences');
        }

        return montagePreferences.askGtdTimestamp;
      }

      return;
    }

    throw new Error('Invalid type, must be "bid" or "ask"');
  }

  private getDuration({
    montagePreferences,
    e
  }: {
    montagePreferences: MontagePreferences;
    e: CellClickedEvent<AnyRecord, any>;
  }): Optional<string> {
    this.assertCellData(e);

    if (e.data.type === 'bid') {
      if (!montagePreferences.bidTimeInForceType) {
        throw new Error('Bid TimeInForce type not found in montage preferences');
      }

      if (montagePreferences.bidTimeInForceType === TimeInForce.Duration) {
        if (!montagePreferences.bidTifDuration) {
          throw new Error('Bid TimeInForce Duration not found in montage preferences');
        }

        return montagePreferences.bidTifDuration;
      }

      return;
    }

    if (e.data.type === 'ask') {
      if (!montagePreferences.askTimeInForceType) {
        throw new Error('Ask TimeInForce type not found in montage preferences');
      }

      if (montagePreferences.askTimeInForceType === TimeInForce.Duration) {
        if (!montagePreferences.askTifDuration) {
          throw new Error('Ask TimeInForce Duration not found in montage preferences');
        }

        return montagePreferences.askTifDuration;
      }

      return;
    }

    throw new Error('Invalid type, must be "bid" or "ask"');
  }

  private getQuantity({
    montagePreferences,
    e,
    orderSettings
  }: {
    montagePreferences: MontagePreferences;
    e: CellClickedEvent<AnyRecord, any>;
    orderSettings: OrderSettingsFragment[];
  }): Optional<number> {
    this.assertCellData(e);

    const context = cellContextSchema.parse(e.api.getGridOption('context'));

    if (e.data.type === 'bid') {
      if (!montagePreferences.bidQuantityType) {
        throw new Error('Bid quantity type not found in montage preferences');
      }

      if (montagePreferences.bidQuantityType === QuantityType.ExplicitValue) {
        if (!montagePreferences.bidQuantityValue) {
          throw new Error(
            'Bid quantity value not found in montage preferences, but bidQuantityType is ExplicitValue'
          );
        }

        return Number(montagePreferences.bidQuantityValue);
      }

      if (montagePreferences.bidQuantityType === QuantityType.Blank) {
        return;
      }

      if (montagePreferences.bidQuantityType === QuantityType.OrderSizes) {
        const level1 = this.marketDataService.read(context.instrumentDisplayCode)?.level1;

        const midPrice = level1?.midPrice;

        if (!midPrice) {
          throw new Error('Mid price not found in level1 data');
        }

        // For now, we only support the first Order Setting
        const foundOrderSetting = orderSettings[0].rows?.find((row) => {
          if (row.priceTo === -1) return true;
          return row.priceTo > midPrice && row.priceFrom < midPrice;
        });

        if (!foundOrderSetting) {
          // we can't determine a quantity based on the users's order settings, so return undefined and let the user enter a value manually
          return;
        }

        return foundOrderSetting.orderSize;
      }
    }

    if (e.data.type === 'ask') {
      if (!montagePreferences.askQuantityType) {
        throw new Error('Ask quantity type not found in montage preferences');
      }

      if (montagePreferences.askQuantityType === QuantityType.ExplicitValue) {
        if (!montagePreferences.askQuantityValue) {
          throw new Error(
            'Ask quantity value not found in montage preferences, but bidQuantityType is ExplicitValue'
          );
        }
        return Number(montagePreferences.askQuantityValue);
      }

      if (montagePreferences.askQuantityType === QuantityType.Blank) {
        return;
      }

      if (montagePreferences.askQuantityType === QuantityType.OrderSizes) {
        const level1 = this.marketDataService.read(context.instrumentDisplayCode)?.level1;

        const midPrice = level1?.midPrice;

        if (!midPrice) {
          throw new Error('Mid price not found in level1 data');
        }

        // For now, we only support the first Order Setting
        const foundOrderSetting = orderSettings[0].rows?.find((row) => {
          if (row.priceTo === -1) return true;
          return row.priceTo > midPrice && row.priceFrom < midPrice;
        });

        if (!foundOrderSetting) {
          // we can't determine a quantity based on the users's order settings, so return undefined and let the user enter a value manually
          return;
        }

        return foundOrderSetting.orderSize;
      }
    }

    throw new Error('Invalid type, must be "bid" or "ask"');
  }

  private getSideType({
    montagePreferences,
    e
  }: {
    montagePreferences: MontagePreferences;
    e: CellClickedEvent<AnyRecord, any>;
  }): OrderSideType {
    this.assertCellData(e);

    if (e.data.type === 'bid') {
      if (!montagePreferences.bidSideType) {
        throw new Error('Bid side type not found in montage preferences');
      }
      return montagePreferences.bidSideType as OrderSideType;
    }

    if (e.data.type === 'ask') {
      if (!montagePreferences.askSideType) {
        throw new Error('Ask side type not found in montage preferences');
      }
      return montagePreferences.askSideType as OrderSideType;
    }

    throw new Error('Invalid side');
  }

  private getPrice({
    montagePreferences,
    e
  }: {
    montagePreferences: MontagePreferences;
    e: CellClickedEvent<AnyRecord, any>;
  }): Optional<number> {
    this.assertCellData(e);
    const context = cellContextSchema.parse(e.api.getGridOption('context'));
    const level1 = this.marketDataService.read(context.instrumentDisplayCode)?.level1;

    if (!level1) {
      throw new Error('Level 1 data not found');
    }

    const { askPrice, bidPrice } = level1;

    const validTypes = ['bid', 'ask'];
    if (!validTypes.includes(e.data.type)) {
      throw new Error('Invalid type, must be "bid" or "ask"');
    }

    const isBidSettings = e.data.type === 'bid';
    const priceType = isBidSettings ? montagePreferences.bidPriceType : montagePreferences.askPriceType;

    if (!priceType) {
      throw new Error(`${isBidSettings ? 'Bid' : 'Ask'} PriceType not found in montage preferences`);
    }

    switch (priceType) {
      case PriceType.Market:
        return; // No price needed for Market type
      case PriceType.Selection:
        return e.data.limitPrice;
      case PriceType.AskInside:
        if (!askPrice) {
          throw new Error('Ask price not found in level1 data');
        }
        return askPrice;
      case PriceType.BidInside:
        if (!bidPrice) {
          throw new Error('Bid price not found in level1 data');
        }
        return bidPrice;
      default:
        throw new Error('Invalid PriceType');
    }
  }

  public removeEvents(): void {}
}
