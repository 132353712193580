import { OrderTagsService } from '@app/data-access/services/reference-data/tags/tags.service';
import { type UseFieldApiProps } from '@data-driven-forms/react-form-renderer';
import {
  type DataSourceCommon,
  type AdvancedSelectParams,
  type AdvancedSelectQueryFn,
  type ComboBoxItem
} from '@oms/frontend-foundation';
import { type TagFragment, type TagTypeEnum } from '@oms/generated/frontend';
import { map } from 'rxjs';

export type TagsComboboxValue = string;

export const watchAllTagsQuery: AdvancedSelectQueryFn<TagsComboboxValue> = (container) => {
  const tagsService = container.resolve(OrderTagsService);

  return {
    type: 'watchAll',
    preLoad: true,
    query: (params: AdvancedSelectParams<any, UseFieldApiProps<any>, { filters?: TagTypeEnum[] }>) =>
      tagsService.watchAll$(...(params?.queryProps?.filters || [])).pipe(
        map<DataSourceCommon<TagFragment>, DataSourceCommon<ComboBoxItem<string>>>((r) => ({
          results: r.results?.map((d) => ({
            type: 'item',
            id: d.id,
            label: d.tagName,
            value: d.id,
            sublabel: d.tagCode
          })),
          isFetching: r.isFetching,
          error: r.error
        }))
      )
  };
};
