import { ColumnApi, GridApi } from '@ag-grid-community/core';
import { inject, InjectionToken, Lifecycle, scoped, ValueProvider } from 'tsyringe';
import { VGridInstance } from '../models/v.instance.model';
import { ShortcutService, type IShortcutService } from '../services/shortcut.service';
import { EventHandler } from './event.handler';
import { EventSource, GridEvent } from './event.source';
import { VGridProps, VGridReadyEvent } from '../components/vgrid.component';
import { AnyRecord } from '@oms/frontend-foundation';
import { GridIdService } from '../services/grid-id.service';

// NOTE: this must run before all handlers / services that need a grid api ref.
@scoped(Lifecycle.ContainerScoped)
export class GridEventHandler implements EventHandler {
  private _serviceHandlerTokens: InjectionToken[] = [];
  private _services: any[] = [];
  private _shortcutService: IShortcutService | undefined;
  public name = 'grid-event-handler';

  constructor(@inject(VGridInstance.InjectedServiceTokens) serviceHandlerTokens: InjectionToken[]) {
    this._serviceHandlerTokens = serviceHandlerTokens;
  }

  public addEvents(eventSource: EventSource<keyof GridEvent>): void {
    // bind onGridReady
    eventSource.add(
      'onGridReady',
      async (e) => {
        const apiValueProvider: ValueProvider<GridApi> = { useValue: e.api };
        // TODO: Breaks ESLint Server
        eventSource.container.register<GridApi>(GridApi, apiValueProvider);
        eventSource.container.register<ColumnApi>(ColumnApi, { useValue: e.columnApi });

        if (this._serviceHandlerTokens.length > 0) {
          this._services = this._serviceHandlerTokens.reduce((result, curr) => {
            const handler = eventSource.container.resolve(curr);
            result.push(handler);
            return result;
          }, [] as any[]);
        }

        this._shortcutService = eventSource.container.resolve(ShortcutService);
        this._shortcutService.init();
        const settings = eventSource.container.resolve<VGridProps<AnyRecord>>(VGridInstance.InitialSettings);
        const gridIdService = eventSource.container.resolve(GridIdService);

        const onVGridReadyEvent: VGridReadyEvent<AnyRecord> = {
          gridApi: e.api,
          columnApi: e.columnApi,
          container: eventSource.container,
          ...gridIdService.allGridIdentifiers
        };

        await settings?.onVGridReady?.(onVGridReadyEvent);
      },
      Number.MIN_SAFE_INTEGER
    );
  }

  public removeEvents(): void {
    this._shortcutService?.destroy();
  }
}
