import { sharedDefaultCol, sharedQuantityCol } from '@app/common/grids/columns/generic-cols';
import { type ColumnBuilderCallback, type ColumnLibrary } from '@oms/frontend-vgrid';
import { type InvestorOrderAuditTrail } from '@app/data-access/services/trading/investor-orders/investor-order-audit-trail.service';
import { sharedDateTimeCol, sharedTextCol, sharedPriceCol } from '@app/common/grids/columns/generic-cols';

const defaultAuditTrailCol: ColumnBuilderCallback<InvestorOrderAuditTrail> = (c) =>
  sharedDefaultCol<InvestorOrderAuditTrail>(c).minWidth(100);

const eventTimestampCol: ColumnBuilderCallback<InvestorOrderAuditTrail> = (c) =>
  sharedDateTimeCol(c, 'eventTimestamp').header('Timestamp').sort('desc').minWidth(200);

const eventCol: ColumnBuilderCallback<InvestorOrderAuditTrail> = (c) =>
  sharedTextCol(c, 'event').header('Event').width(200);

const descriptionCol: ColumnBuilderCallback<InvestorOrderAuditTrail> = (c) =>
  sharedTextCol(c, 'description').header('Description').width(500);

const bidPriceCol: ColumnBuilderCallback<InvestorOrderAuditTrail> = (c) =>
  sharedPriceCol(c, 'bidPrice').header('Bid');

const askPriceCol: ColumnBuilderCallback<InvestorOrderAuditTrail> = (c) =>
  sharedPriceCol(c, 'askPrice').header('Ask');

const lastTradePriceCol: ColumnBuilderCallback<InvestorOrderAuditTrail> = (c) =>
  sharedPriceCol(c, 'lastTradePrice').header('Last Trd').shortHeader('Last');

const cumulativeVolumeCol: ColumnBuilderCallback<InvestorOrderAuditTrail> = (c) =>
  sharedQuantityCol(c, 'cumulativeVolume').header('Cumulative Volume').shortHeader('Vol');

export const auditTrailColumnLibrary: ColumnLibrary<InvestorOrderAuditTrail> = {
  defaultColumn: defaultAuditTrailCol,
  columns: [
    eventTimestampCol,
    eventCol,
    descriptionCol,
    bidPriceCol,
    askPriceCol,
    lastTradePriceCol,
    cumulativeVolumeCol
  ]
};
