import type { CommonWindowActorSchema } from '@valstro/workspace';
import { useActorContext, useCurrentWindow } from '@valstro/workspace-react';

export type WindowMetaData = {
  widgetType?: string;
  windowType?: 'dialog' | 'window';
  widgetCategory?: string;
};
export type AppWindowSchema = CommonWindowActorSchema<unknown, WindowMetaData>;

export const useWindowMetaData = (): WindowMetaData => {
  const windowActor = useCurrentWindow<AppWindowSchema>();
  const context = useActorContext(windowActor);
  const { widgetCategory = '', widgetType = '', windowType = 'window' } = context.meta;
  return { widgetCategory, widgetType, windowType };
};

export default useWindowMetaData;
