import { openTradeModify } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { type TradeInfoFragment } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { getTradeDescription } from '@app/common/types/orders/orders.utils';
import { TradeModifyEntryType } from '@app/widgets/trading/trade-modify/trade-modify.form-common';

export const openModifyTradeOnChange = async (
  ctx: ActionContext<TradeInfoFragment, ActionComponentConfig<TradeInfoFragment>>
) => {
  const { lifecycle, data, workspace } = ctx;

  const selectedRow = data[0];
  const onlyOneRowSelected = data.length === 1;

  ctx.notify({ isDisabled: !onlyOneRowSelected });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }

    const id = selectedRow.id;

    if (!id) {
      return;
    }

    openTradeModify(PROCESS_ID.LEADER, {
      windowId: `trade-modify-${id}`,
      form: {
        formId: `trade-modify-form-${id}`,
        input: {
          id,
          entryType: TradeModifyEntryType.TradeModify
        }
      },
      title: `Modify Trade: ${getTradeDescription(selectedRow)}`
    }).catch((e) => {
      openMessageDialog(`Error: ${e}`, workspace).catch(console.error);
      console.error(e);
    });
  }
};

export const openModifyTradeAction: ActionDefFactory<TradeInfoFragment> = (builder) =>
  builder
    .name('modify_trade')
    .toolbar((t) =>
      t.component('action-button').id('modify_trade_button').location('HorizontalToolbarRight').props({
        isDisabled: true,
        content: 'Modify'
      })
    )
    .menu((m) => m.name('Modify').visible(({ rowData }) => !!rowData))
    .onChange<ActionComponentConfig<TradeInfoFragment>>(openModifyTradeOnChange);
