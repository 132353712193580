import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import type {
  AnyRecord,
  FieldContract,
  IAdvancedSelectField,
  ModifiedFieldForOptions
} from '@oms/frontend-foundation';
import type { RepresentativeCodeInfoFragment } from '@oms/generated/frontend';

export const createRepCodeField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedSelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'advanced-select').advancedSelectOptions<RepresentativeCodeInfoFragment>({
    width: 200,
    label: 'Representative Code',
    query: ADVANCED_SELECT_QUERY_ENUM.REP_CODES_WATCH_QUERY,
    ...overrides
  });
