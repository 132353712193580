import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { openConfirmation } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import {
  InvestorOrderStatus,
  type VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment,
  type CancelInvestorOrdersInput,
  type FeedbackWrapper
} from '@oms/generated/frontend';
import type { FoundationWorkspace } from '@oms/frontend-foundation';
import { PROCESS_ID } from '@valstro/workspace';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { convertAlert } from '@oms/frontend-foundation';

/**
 * Open a Confirmation Dialog with the relevant errors to display, handle the "OK" button
 * click to send the CancelOrders mutation, and handle any failures by recursion.
 */
async function openConfirmationAndListen(
  orderIds: string[],
  errors: FeedbackWrapper[],
  orderService: InvestorOrdersService,
  workspace: FoundationWorkspace,
  numRows: number
) {
  // Convert the FeedbackWrapper[] into AlertBannerStackItem[]
  const alerts = errors.map((item) =>
    convertAlert.formValidationAlertItem.item(item).toAlertBannerStackItem()
  );

  // Invoke the confirmation dialog with the relevant info.
  const [_, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
    title: `Cancel ${numRows} investor order${numRows > 1 ? 's' : ''}`,
    componentProps: {
      autoClose: true,
      alerts: alerts,
      message: `Are you sure you want to cancel ${numRows} investor order${numRows > 1 ? 's' : ''}?`,
      confirmButtonText: t('app.common.retry'),
      cancelButtonText: t('app.common.no')
    }
  });

  const event = await api.awaitFirstEvent;
  switch (event.type) {
    case DIALOG_EVENT_TYPE.OK: {
      const input: CancelInvestorOrdersInput = {
        investorOrderIds: orderIds
      };

      const resp = await orderService.cancelOrders(input);

      if (resp.isSuccess() === false) {
        // The mutation failed. Invoke another confirmation.
        const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];
        await openConfirmationAndListen(orderIds, feedbackWrappers, orderService, workspace, numRows);
      }

      break;
    }
  }
}

export const cancelInvestorOrderOnChange = async (
  ctx: ActionContext<IOFragment, ActionComponentConfig<IOFragment>>
) => {
  const { lifecycle, data } = ctx;

  const activeRows = data.filter((selectedRow) => selectedRow.status === InvestorOrderStatus.Active).length;

  ctx.notify({ isDisabled: activeRows === 0 });

  if (lifecycle === 'change') {
    const orderService = ctx.appContainer.resolve(InvestorOrdersService);

    const [_, api] = await openConfirmation(ctx.workspace, PROCESS_ID.LEADER, {
      title: `Cancel ${activeRows} investor order${activeRows > 1 ? 's' : ''}`,
      componentProps: {
        autoClose: true,
        message: `Are you sure you want to cancel ${activeRows} investor order${activeRows > 1 ? 's' : ''}?`,
        confirmButtonText: t('app.common.yes'),
        cancelButtonText: t('app.common.no')
      }
    });
    const event = await api.awaitFirstEvent;
    switch (event.type) {
      case DIALOG_EVENT_TYPE.OK: {
        // By the time the user clicks past the confirmation dialog, the count of selected rows
        // that are active may have changed.
        const activeIOIds = data
          .filter((selectedRow) => selectedRow.status === InvestorOrderStatus.Active)
          .map((row) => row.id);

        const input: CancelInvestorOrdersInput = {
          investorOrderIds: activeIOIds
        };
        const resp = await orderService.cancelOrders(input);

        if (resp.isSuccess() === false) {
          const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];

          await openConfirmationAndListen(
            activeIOIds,
            feedbackWrappers,
            orderService,
            ctx.workspace,
            activeIOIds.length
          );
        }

        break;
      }
    }
  }
};

export const cancelInvestorOrderAction: ActionDefFactory<IOFragment> = (builder) =>
  builder
    .name('cancel_investor_order')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('cancel_investor_order_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: 'Cancel'
        })
    )
    .menu((m) => m.name('Cancel').visible(({ rowData }) => rowData?.status === InvestorOrderStatus.Active))
    .onChange<ActionComponentConfig<IOFragment>>(cancelInvestorOrderOnChange);
