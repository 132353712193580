import { type RadioButtonListControl } from '@valstro/fixatdl-core';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { type ControlTransformer, getListItems } from './control.transformer.types';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const radioButtonListControl: ControlTransformer<RadioButtonListControl> = (
  radioButtonListControl,
  _query,
  formValues
) => {
  return {
    component: fixatdlFormComponentTypes.RADIO_BUTTON_LIST,
    name: radioButtonListControl.id,
    label: radioButtonListControl.label,
    initialValue: AtdlFormats.coerceValue(formValues[radioButtonListControl.id]),
    orientation: radioButtonListControl.orientation,
    options: getListItems(radioButtonListControl.listItems)
  };
};
