import { TabContent, Tabs, TabsList, TabTrigger } from '@oms/shared-frontend/ui-design-system';
import {
  type UpdatedTradingOrder,
  withUpdatedTradingOrder
} from '@app/data-access/hoc/with-updated-trading-order';
import { paneStyle as sx } from './trading-order-view.style';
import { TradingOrderSummary } from './trading-order-view.trading-order-summary.widget';

export const TradingOrderViewTabbedSummariesWidget = ({ data: tradingOrder }: UpdatedTradingOrder) => {
  return (
    <Tabs defaultValue="order-summary" sx={{ ...sx, padding: 0 }}>
      <TabsList
        style={{
          padding: 0,
          marginLeft: 15,
          marginBottom: 0
        }}
      >
        <TabTrigger value="order-summary" style={{}}>
          Order Summary
        </TabTrigger>
        <TabTrigger value="charges">Charges</TabTrigger>
        <TabTrigger value="cost-plus">Cost Plus</TabTrigger>
      </TabsList>
      <TabContent value="order-summary">
        <TradingOrderSummary tradingOrder={tradingOrder} />
      </TabContent>
      <TabContent value="charges">Charges will be here</TabContent>
      <TabContent value="cost-plus">Cost Plus will be here</TabContent>
    </Tabs>
  );
};

export default withUpdatedTradingOrder(TradingOrderViewTabbedSummariesWidget, { sx });
