import { mapSettleType } from '@app/common/mappers/map-settle-type';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { type Validator } from '@data-driven-forms/react-form-renderer';
import {
  type FieldDefinition,
  type INativeDatePickerField,
  type AnyRecord,
  type FieldContract,
  type ModifiedFieldForOptions,
  type IBoxField,
  type ISelectField
} from '@oms/frontend-foundation';
import { OrderSettleType } from '@oms/generated/frontend';

export type SettlementFields<
  TOutputContract extends AnyRecord,
  TSettlementFieldKey extends keyof TOutputContract,
  TSettlementDateKey extends keyof TOutputContract
> = {
  settlementContainer: FieldDefinition<`settlement-container-${string}-${string}`, 'box', IBoxField>;
  settlementType: FieldDefinition<TSettlementFieldKey, 'select', ISelectField<OrderSettleType>>;
  settlementDate: FieldDefinition<
    TSettlementDateKey,
    'native-date-picker',
    INativeDatePickerField<Validator>
  >;
};

export const createSettlementFields = <
  TOutputContract extends AnyRecord,
  TSettlementFieldKey extends keyof TOutputContract,
  TSettlementDateKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  settlementField: TSettlementFieldKey,
  settlementDate: TSettlementDateKey,
  settlementOverrides: ModifiedFieldForOptions<ISelectField<TOutputContract[TSettlementFieldKey]>> = {},
  settlementDateOverrides: ModifiedFieldForOptions<
    INativeDatePickerField<TOutputContract[TSettlementDateKey]>
  > = {}
): SettlementFields<TOutputContract, TSettlementFieldKey, TSettlementDateKey> => {
  const settlementType = fc.field(settlementField, 'select').options<ISelectField<any>>({
    wrapperSx: {
      display: 'flex',
      width: 'full'
    },
    label: 'Settlement',
    options: Object.values(OrderSettleType).map((value) => ({ value, label: mapSettleType(value) })),
    ...settlementOverrides
  });

  const settlementDateField = fc.field(settlementDate, 'native-date-picker').options<any>({
    wrapperSx: {
      width: 'full'
    },
    label: '',
    validate: [
      { type: 'required', message: 'Required' },
      { type: 'afterDate', message: 'Must be future date.' }
    ],
    ...settlementDateOverrides
  });

  const setSettlementDateField: Record<string, any> = {};
  setSettlementDateField[String(settlementDate)] = '';

  const settlementContainer = fc
    .field(`settlement-container-${String(settlementField)}-${String(settlementDate)}`, 'box')
    .options({
      fields: [
        [
          fc
            .field(`settlement-type-container-${String(settlementField)}`, 'box')
            .options({
              fields: [[settlementType.build()]],
              sx: { width: 'full', marginRight: '2px' }
            })
            .build()
        ],
        [
          fc
            .field(`settlement-date-container-${String(settlementDate)}`, 'box')
            .options({
              fields: [[settlementDateField.build()]],
              style: {
                width: '70%'
              },
              condition: {
                when: String(settlementField),
                is: OrderSettleType.Future,
                then: { visible: true },
                else: { set: setSettlementDateField, visible: false }
              }
            })
            .build()
        ]
      ],
      sx: {
        display: 'flex',
        flexDirection: 'row',
        width: 'full'
      }
    });

  const result: SettlementFields<TOutputContract, TSettlementFieldKey, TSettlementDateKey> = {
    settlementType,
    settlementDate: settlementDateField,
    settlementContainer
  };
  return result;
};
