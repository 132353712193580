import { type FC, useMemo } from 'react';
import { VGrid, useVGrid } from '@oms/frontend-vgrid';
import type { ISetFilterParams, ICellRendererParams } from '@ag-grid-community/core';
import { t } from '@oms/codegen/translations';
import { broadcastGridSelection } from '@app/data-access/memory/grid.events';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import type { TreeDataSlice } from '@app/data-access/services/trading/positions/common/tree-grid/types/tree-data.types';
import { createTreeSetFilterValues } from '@app/data-access/services/trading/positions/common/tree-grid/util/filtering/grid-filter-values.util';
import { usePositionsTreeSubscription } from '@app/data-access/services/trading/positions/util/use-positions-subscription.hook';
import type { PositionsGridType } from '../common/types';
import getColumnLibraryFor from '../common/columns/positions.column-library';
import usePositionsDataSourceService from '../common/services/use-positions-datasource-service.hook';
import usePositionsAccountToolbarActions from './toolbar-actions/use-positions-account-toolbar-actions.hook';

const gridType: PositionsGridType = 'positions-account-grid';

/**
 * Account-selection tree grid for positions widget (upper pane)
 */
export const PositionsAccountGrid: FC = () => {
  usePositionsAccountToolbarActions();

  const data$ = usePositionsTreeSubscription();

  const { dataSourceService, dataHelper } = usePositionsDataSourceService(gridType, data$);

  const filterParams: ISetFilterParams<TreeDataSlice<PositionRow>, string[]> = useMemo(
    () => ({
      treeList: true,
      excelMode: 'windows',
      keyCreator: ({ value }) => (Array.isArray(value) ? (value as string[]).join('.') : ''),
      treeListFormatter: (key) => (key ? dataHelper.lookupDataItemByKey(key)?.name || key : ''),
      values: ({ success }) => {
        const data = dataHelper?.data ?? [];
        success(
          createTreeSetFilterValues(data, {
            getValue: ({ id }) => id
          })
        );
      }
    }),
    [dataHelper]
  );

  const gridProps = useVGrid<TreeDataSlice<PositionRow>>(
    gridType,
    (builder) =>
      builder
        .columnLibrary(getColumnLibraryFor(gridType, dataHelper))
        .datasource((d) =>
          d
            .source(dataSourceService.createServerSideDatasource())
            .rowId(({ data }) => data.hid)
        )
        .makeTree({
          getGroupKey: ({ id }) => id,
          isGroup: ({ hasChildren }) => hasChildren ?? false,
          isGroupOpenByDefault: ({ rowNode }) => rowNode.level < 1
        })
        .defaultColumn((c) => c.filter().sortable().resizable())
        .autoGroupColumnDef({
          field: 'name',
          headerName: t('app.positions.account'),
          suppressSizeToFit: true,
          width: 200,
          cellRendererParams: {
            innerRenderer: ({ data }: ICellRendererParams<TreeDataSlice<PositionRow>>) => data?.name || '',
            suppressCount: true
          },
          filter: 'agSetColumnFilter',
          filterParams,
          sortable: true,
          resizable: true
        })
        .rowSelection((r) => r.single().broadcast(broadcastGridSelection<TreeDataSlice<PositionRow>>()))
        .actions((a) =>
          a
            .schema((s) => s.action('clear-grid-filter').action('row-selection-count'))
            .settings((s) => s.fromFile({}))
        )
        .onVGridReady(({ gridApi }) => {
          gridApi.sizeColumnsToFit();
        })
        .sideBar(),
    []
  );

  return <VGrid {...gridProps} />;
};

export default PositionsAccountGrid;
