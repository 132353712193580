import { type TextFieldControl } from '@valstro/fixatdl-core';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { type ControlTransformer } from './control.transformer.types';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const textControl: ControlTransformer<TextFieldControl> = (textControl, _query, formValues) => {
  return {
    component: fixatdlFormComponentTypes.TEXT,
    name: textControl.id,
    label: textControl.label,
    initialValue: AtdlFormats.coerceValue(formValues[textControl.id])
  };
};
