import { type Signal, createSignal } from '@oms/shared-frontend/rx-broadcast';
import type { OfflineDb } from '../offline/offline-database';
import { type Observable, filter } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { ProcessState } from './process-id.subject';

export type OfflineDataAccessStateNotReady = {
  isReady: false;
  isConnecting: boolean;
  db?: OfflineDb;
  error?: string | null;
};

export type OfflineDataAccessStateReady = {
  isReady: true;
  isConnecting: false;
  db: OfflineDb;
  error?: null;
};

export type OfflineDataAccessState = OfflineDataAccessStateNotReady | OfflineDataAccessStateReady;

export const DEFAULT_OFFLINE_DATA_ACCESS_STATE: OfflineDataAccessState = {
  isReady: false,
  isConnecting: false,
  error: undefined
};

/**
 * Signal for OfflineDatabase
 *
 * Used to keep the current state of offline database (not multi-process)
 * and to listen for changes to the OfflineDataAccessState
 *
 * Used primarily at the framework level to broadcast the current state of data access
 * @see plugin-offline-database
 *
 * @usage
 * ```ts
 * const offlineDatabaseSignal = container.resolve(OfflineDatabaseSignal);
 * const subscription = offlineDatabaseSignal.$.subscribe((state) => {
 *  console.log('Offline database state changed', state);
 * });
 * ```
 *
 * @usage
 * ```ts
 * constructor(@inject(OfflineDatabaseSignal) private offlineDatabaseSignal: OfflineDatabaseSignal) {
 *  const subscription = this.offlineDatabaseSignal.$.subscribe((state) => {
 *    console.log('Offline database state changed', state);
 *  });
 * }
 */
@singleton()
export class OfflineDatabaseSignal {
  public signal: Signal<OfflineDataAccessState>;

  constructor(@inject(ProcessState) private processState: ProcessState) {
    this.signal = createSignal<OfflineDataAccessState>(this.channelName, this.DEFAULT_STATE, {
      initialize$: this.processState.isLeaderProcess$,
      initializeOnce: false
    });
  }

  public get ready$() {
    return this.signal.$.pipe(filter((state) => state.isReady)) as Observable<OfflineDataAccessStateReady>;
  }

  public get channelName() {
    return 'offlineDatabase';
  }

  public get DEFAULT_STATE() {
    return DEFAULT_OFFLINE_DATA_ACCESS_STATE;
  }

  public reset() {
    this.signal.set(this.DEFAULT_STATE);
  }
}
