import type { ValidatorFunction } from '@data-driven-forms/react-form-renderer';
import { DateTime } from 'luxon';

type AfterDate = {
  message?: string;
  date?: number | string;
};

export const afterDate: (opts: AfterDate) => ValidatorFunction =
  ({ date, message = 'Not after date.' }) =>
  (value: string | number | Date | undefined, _allValues?: any, _meta?: object) => {
    if (!value) return null;
    const formValDate =
      value instanceof Date
        ? DateTime.fromJSDate(value)
        : typeof value === 'string'
        ? DateTime.fromISO(value)
        : DateTime.fromMillis(value);
    const configDate = date
      ? typeof date === 'number'
        ? DateTime.fromMillis(date)
        : DateTime.fromISO(date)
      : DateTime.now();
    const notAfterDate =
      formValDate &&
      formValDate.isValid &&
      configDate &&
      configDate.isValid &&
      configDate.toUTC().toMillis() > formValDate.toUTC().toMillis();

    return notAfterDate ? message : null;
  };
