import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Box, type BoxProps } from '../../system/components/box/box';
import * as styles from '../../system/styles.css';
import { TabbableService } from './service/tabbable.service';
import { TabbableServiceSettings } from './service/tabbable.service.types';
import { useMergedRef } from '@valstro/workspace-react';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

// TODO: WIP: 1. Input components (inc button) need to have enter working
// TODO: WIP: 2. Starting tabIndex & focus
// TODO: WIP: 3. Tracking which tab/input you are on
// TODO: WIP: 4. Some components (layout) should not be tabbable.
// TODO: WIP: 5. Monitor key-strokes for invalid input and move to next tab (optional)

export type TabbableProps = {
  /**
   * If `true`, all input elements will show an outline on focus
   * Which is useful to see which elements your are focused on when using tab
   */
  hasFocusOutline?: boolean;

  /**
   * Settings for Tabbing service
   */
  settings?: TabbableServiceSettings;
} & BoxProps;

// TODO: Handle radio & checkbox fields?
// TODO: Write a few tests for sequencing
// TODO: Add a context provider for tabbing (so comps like typeahead can not auto show menu onFocus)

export const Tabbable = polymorphicComponent<'div', TabbableProps>((props, ref) => {
  const { className, hasFocusOutline = true, children, as = 'div', settings, ...rest } = props;
  const containerRef = useRef<HTMLDivElement>();
  const serviceRef = useRef(new TabbableService(settings));

  useEffect(() => {
    serviceRef.current.start(containerRef.current, settings);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      serviceRef.current.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    serviceRef.current.updateSettings(settings);
  }, [settings]);

  return (
    <Box
      as={as}
      ref={useMergedRef(ref, containerRef)}
      className={clsx(hasFocusOutline && styles.focusOutlineVisible, className)}
      {...rest}
    >
      {children}
    </Box>
  );
});

if (__DEV__) {
  Tabbable.displayName = 'Tabbable';
}
