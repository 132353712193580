import { FormBuilder } from '@oms/frontend-foundation';
import montageFiltersFormContract, {
  type MontageFiltersFormContractType,
  type MontageFiltersFormValues
} from './montage-filters.form-contract';
import type { MontageFilterFormInput, MontageFilterFormOutput } from './montage-filters.form-common';
import { instrumentMapper } from '@app/forms/common/fields/symbol-field/symbol.field.queries';
import { MontageService } from '@app/data-access/services/trading/montage/montage.service';
import AccountsService from '@app/data-access/services/reference-data/accounts/accounts.service';
import { accountResultMapper } from '@app/forms/common/fields/account-field/account.field.queries';
import { convertInstrument } from '@app/common/types/instrument/instrument.util';

export const montageFiltersFormBuilder = FormBuilder.create<MontageFilterFormInput, MontageFilterFormOutput>(
  'montage-filter-form'
)
  .contract<MontageFiltersFormContractType>(montageFiltersFormContract)
  .type('montage-filters')
  .sanitizer((s) =>
    s
      .input(
        async (
          { instrumentTrackingEnabled, instrument: instrumentSymbol, investorAccount: investorAccountId },
          { container }
        ): Promise<MontageFiltersFormValues> => {
          const montageService = container.resolve(MontageService);
          const accountsService = container.resolve(AccountsService);

          const [instrument] = instrumentSymbol
            ? await montageService.lookupInstrumentBySymbol(instrumentSymbol)
            : [];
          const investorAccount = investorAccountId
            ? await accountsService.getInvestorAccount(investorAccountId)
            : undefined;

          return {
            instrumentTrackingEnabled,
            instrument: instrument ? instrumentMapper(instrument) : undefined,
            investorAccount: investorAccount ? accountResultMapper(investorAccount) : undefined
          };
        }
      )
      .output(({ instrumentTrackingEnabled, instrument, investorAccount }) => ({
        instrumentTrackingEnabled: instrumentTrackingEnabled ?? false,
        instrument: instrument ? convertInstrument.simpleDetails(instrument.value).toSimple() : undefined,
        investorAccount: investorAccount
          ? {
              id: investorAccount.id,
              label: investorAccount.label,
              description: investorAccount.sublabel
            }
          : undefined
      }))
  )
  .change((_) => {
    // Needs to have this method implemented or it will cause an error
    // Actual implementation is in the widget to have access to React
  });

export default montageFiltersFormBuilder;
