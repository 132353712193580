import { RepresentativeCodesService } from '@app/data-access/services/reference-data/representative-codes/representative-codes.service';
import {
  type AdvancedSelectQueryFn,
  getAdvancedSelectQueryReturn,
  type DataSourceCommon,
  type ComboBoxItem
} from '@oms/frontend-foundation';
import { type RepresentativeCodeInfoFragment } from '@oms/generated/frontend';
import { type Observable } from 'rxjs';

type RepresentativeCodeComboBoxValue = RepresentativeCodeInfoFragment;

export const watchAllRepCodesQuery: AdvancedSelectQueryFn<RepresentativeCodeComboBoxValue> = (container) => {
  const representativeCodesService = container.resolve(RepresentativeCodesService);

  return getAdvancedSelectQueryReturn<RepresentativeCodeComboBoxValue, RepresentativeCodeComboBoxValue>({
    queryFn: representativeCodesService.watchAll$.bind(representativeCodesService) as () => Observable<
      DataSourceCommon<RepresentativeCodeComboBoxValue>
    >,
    resultMapper: (repCode) => ({
      id: repCode.id,
      label: repCode.code,
      sublabel: repCode.description,
      value: repCode,
      type: 'item'
    }),
    preLoad: true
  });
};

export const createRepCodeComboboxItem = (
  repCode: Partial<RepresentativeCodeComboBoxValue> & Pick<RepresentativeCodeComboBoxValue, 'id' | 'code'>
): ComboBoxItem<RepresentativeCodeComboBoxValue> => {
  return {
    id: repCode.id,
    label: repCode.code,
    sublabel: repCode.description || '',
    value: repCode as RepresentativeCodeComboBoxValue,
    type: 'item'
  };
};
