import { type UserPreferencesContractFormValues } from '../../user-preferences.form-contract';
import { orderSettingsFieldIndexes } from '../order-settings.util';

export const VALIDATE_PRICE_TO_VALUE = 'validatePriceToValue';

const message = {
  leaveBlankIfLastRow: 'Leave blank if last row',
  tooLow: 'Too low'
};

export const validatePriceToValue =
  () => (value: number, allValues?: UserPreferencesContractFormValues, meta?: any) => {
    const { name } = meta;
    const [profileIndex, rowIndex] = orderSettingsFieldIndexes(name);

    const orderSettings = allValues?.orderSettings ? allValues.orderSettings : [];

    const priceFrom = orderSettings ? orderSettings[profileIndex]?.rows[rowIndex].priceFrom : 0;
    const isLastRow = rowIndex === orderSettings[profileIndex].rows.length - 1;

    if (isLastRow && typeof value === 'number' && value !== Number.POSITIVE_INFINITY)
      return message.leaveBlankIfLastRow;

    if (!isLastRow && typeof value === 'number' && value === Number.POSITIVE_INFINITY)
      return message.leaveBlankIfLastRow;

    if (!isLastRow && typeof value === 'undefined') return message.tooLow;
    if (!isLastRow && typeof value === 'number' && value === -1) return message.tooLow;
    if (value <= priceFrom) return message.tooLow;
  };
