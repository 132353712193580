import {
  sharedDefaultCol,
  sharedIdCol,
  sharedPriceCol,
  sharedQuantityCol,
  sharedTextCol,
  sharedTimestampCol
} from '@app/common/grids/columns/generic-cols';
import {
  sharedLimitPriceCol,
  sharedSideCol,
  sharedTodayAveragePriceCol,
  sharedTodayExecutedQuantityCol
} from '@app/common/grids/columns/order-cols';
import { type ColumnBuilderCallback, type ColumnLibrary } from '@oms/frontend-vgrid';
import { type TradingOrderInvestorOrderRow } from '@app/data-access/services/trading/trading-orders/trading-order-investor-orders.service';

const defaultCol: ColumnBuilderCallback<TradingOrderInvestorOrderRow> = (c) =>
  sharedDefaultCol<TradingOrderInvestorOrderRow>(c);

const idCol: ColumnBuilderCallback<TradingOrderInvestorOrderRow> = (c) => sharedIdCol(c, 'id').hide();

const limitPriceCol: ColumnBuilderCallback<TradingOrderInvestorOrderRow> = (c) => sharedLimitPriceCol(c);

const sideCol: ColumnBuilderCallback<TradingOrderInvestorOrderRow> = (c) => sharedSideCol(c);

const locateCol: ColumnBuilderCallback<TradingOrderInvestorOrderRow> = (c) =>
  sharedTextCol(c, 'locate').header('Locate').width(82);

const quantityCol: ColumnBuilderCallback<TradingOrderInvestorOrderRow> = (c) =>
  sharedQuantityCol(c, 'quantity').header('Total Quantity', 'Total Qty').width(110);

const mappedQuantityCol: ColumnBuilderCallback<TradingOrderInvestorOrderRow> = (c) =>
  sharedQuantityCol(c, 'mappedQuantity').header('Mapped Quantity', 'Mapped Qty').width(120);

const executedQuantityCol: ColumnBuilderCallback<TradingOrderInvestorOrderRow> = (c) =>
  sharedQuantityCol(c, 'executedQuantity').header('Executed Quantity', 'Exec Qty').width(110);

const averagePriceCol: ColumnBuilderCallback<TradingOrderInvestorOrderRow> = (c) =>
  sharedPriceCol(c, 'averagePrice').header('Average Price', 'Avg Px');

const todayExecutedQuantityCol: ColumnBuilderCallback<TradingOrderInvestorOrderRow> = (c) =>
  sharedTodayExecutedQuantityCol(c);

const todayAveragePriceCol: ColumnBuilderCallback<TradingOrderInvestorOrderRow> = (c) =>
  sharedTodayAveragePriceCol(c);

const createdTimestampCol: ColumnBuilderCallback<TradingOrderInvestorOrderRow> = (c) =>
  sharedTimestampCol(c, 'createdTimestamp').header('Order Time').width(180);

export const tradingOrderInvestorOrdersColumnLibrary: ColumnLibrary<TradingOrderInvestorOrderRow> = {
  defaultColumn: defaultCol,
  columns: [
    idCol,
    limitPriceCol,
    sideCol,
    locateCol,
    quantityCol,
    mappedQuantityCol,
    executedQuantityCol,
    averagePriceCol,
    todayExecutedQuantityCol,
    todayAveragePriceCol,
    createdTimestampCol
  ]
};
