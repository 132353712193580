import type { DependencyContainer } from 'tsyringe';
import type { AnyRecord } from '../../common/type.helpers';
import type { Schema } from '../types';
import type {
  FormBuilderTemplateMapperKey,
  FormBuilderTemplateProps
} from './components/templates/form-builder.template-mapper';
import type { FeedbackWrapper } from '../../graphql/graphql-envelope';

export type FormBuilderDefinitionBase = {
  schema: Schema;
  formType?: string;
};

export const FORM_SAVE_TYPE = {
  UNKNOWN: 'UNKNOWN',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  TEMPLATE: 'TEMPLATE'
} as const;

export type FormSaveType = typeof FORM_SAVE_TYPE[keyof typeof FORM_SAVE_TYPE];

export type RemoteFormBuilderDefinition<
  TFormFieldValues extends AnyRecord = AnyRecord,
  TInputContract extends AnyRecord = AnyRecord
> = {
  schema?: Schema;
  initialFeedback?: FeedbackWrapper[];
  triggerValidationOnOpen?: boolean;
  formId: string;
  formType?: string;
  input?: TInputContract;
  initialValues?: Partial<TFormFieldValues>;
  template?: FormBuilderTemplateMapperKey;
  templateProps?: Partial<FormBuilderTemplateProps[keyof FormBuilderTemplateProps]>;
  formSaveType: FormSaveType;
  formBuilderId: string;
};

export type FormBuilderContextBase = FormBuilderDefinitionBase & {
  formId: string;
  formSaveType: FormSaveType;
  container: DependencyContainer;
  initialFeedback?: FeedbackWrapper[];
  /**
   * @deprecated
   */
  errorMsg?: string;
  /**
   * @deprecated
   */
  setErrorMsg?: (msg: string) => void;
};

export type FormBuilderContext<_TFormFieldValues extends AnyRecord> = FormBuilderContextBase & {
  // Placeholder for future use
};

export type OnValuesChangingCtx<TFormFieldValues extends AnyRecord> = {
  formValuesDiff: Partial<TFormFieldValues>;
  prevFormValues: Partial<TFormFieldValues>;
  formValues: Partial<TFormFieldValues>;
};

export type OnValuesChangedCtx<TFormFieldValues extends AnyRecord> = OnValuesChangingCtx<TFormFieldValues> & {
  // using string & keyof to prevent number and symbol keys
  modifiedFields: Array<string & keyof TFormFieldValues>;
};

export type OnValuesChanged<TFormFieldValues extends AnyRecord> = (
  ctx: OnValuesChangedCtx<TFormFieldValues>
) => Promise<void> | void;

export type OnValuesChanging<TFormFieldValues extends AnyRecord> = (
  ctx: OnValuesChangingCtx<TFormFieldValues>
) => Promise<void> | void;

export type OnSanitizedValuesChangedCtx<
  TFormFieldValues extends AnyRecord,
  TOutputContract extends AnyRecord
> = OnValuesChangedCtx<TFormFieldValues> & {
  output: TOutputContract;
};

export type OnSanitizedValuesChanged<
  TFormFieldValues extends AnyRecord,
  TOutputContract extends AnyRecord
> = (ctx: OnSanitizedValuesChangedCtx<TFormFieldValues, TOutputContract>) => Promise<void> | void;
