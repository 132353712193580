import { Center, Text, VStack, Button } from '@oms/shared-frontend/ui-design-system';
import { ErrorBoundary as ErrorBoundaryComp, type FallbackProps } from 'react-error-boundary';

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <Center role="alert" fillArea>
      <VStack>
        <Text type="mediumB" as="p">
          Something went wrong:
        </Text>
        <Text type="baseR" as="p">
          {error.message}
        </Text>
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </VStack>
    </Center>
  );
}

export const ErrorBoundary: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <ErrorBoundaryComp FallbackComponent={ErrorFallback}>{children}</ErrorBoundaryComp>;
};
