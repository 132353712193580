import type { Builder, BuilderCallback } from '@oms/shared/util-types';
import { isFunction, merge } from 'lodash';
import { container } from 'tsyringe';
import { ColumnBuilder } from './column.builder';
import { ComponentBuilder } from './component.builder';
import { ContextMenuBuilder } from './context.menu.builder';
import type { AnyRecord } from '@oms/frontend-foundation';
import { ActionDef, ActionDefConfig, ActionLifecycle, BuilderActionDefConfig } from '../models/actions.model';

// TODO: Note, I have removed the _TConfig generic type from inside the GridActionBuilder class.
// TODO: It was set-up incorrectly and was causing ESLint errors from outside the builder.
// TODO: Need to put back if we still have a good use case for it.
export class GridActionBuilder<TData extends AnyRecord, _TConfig extends AnyRecord = AnyRecord> {
  private _action: Partial<ActionDef<TData, any>> = {};
  private _inlineColDef?: ColumnBuilder<TData>;
  private _config?: ActionDefConfig;

  public name(name: string): GridActionBuilder<TData> {
    this._action.name = name;

    return this;
  }

  public lifecycles(...lifecycles: ActionLifecycle[]): GridActionBuilder<TData> {
    this._action.lifecycles = lifecycles;
    return this;
  }

  public config<TConfig extends AnyRecord>(
    config: ActionDefConfig<TConfig, Builder>
  ): GridActionBuilder<TData, TConfig> {
    this._config = config;
    return this;
  }

  public inline(cb: BuilderCallback<ColumnBuilder<TData>>): GridActionBuilder<TData> {
    this._inlineColDef = cb(new ColumnBuilder<TData>());

    return this;
  }

  public menu(cb: BuilderCallback<Omit<ContextMenuBuilder<TData>, 'action'>>): GridActionBuilder<TData> {
    this._action.menu = cb(new ContextMenuBuilder<TData>()).build();
    return this;
  }

  public toolbar(cb: BuilderCallback<ComponentBuilder<TData>>): GridActionBuilder<TData> {
    const comp = cb(new ComponentBuilder<TData>()).build();

    if (!comp) {
      throw new Error('Action could not be found.');
    }

    this._action.components = this._action.components || [];
    this._action.components.push(comp);

    return this;
  }

  public onChange<TConfig extends AnyRecord>(
    cb: ActionDef<TData, TConfig>['onChange']
  ): GridActionBuilder<TData, TConfig> {
    this._action.onChange = cb;
    return this;
  }

  public build(): ActionDef<TData> {
    if (this._inlineColDef && this._action.name) {
      this._action.inline = this._inlineColDef.colId(this._action.name).build();
      this._action.inline.cellRendererParams = merge(
        { action: this._action.inline.cellRendererParams || {} } as any,
        {
          action: {
            style: {
              height: '80%',
              lineHeight: '100%',
              top: '-1px'
            }
          }
        }
      );
    }

    if (this._config) {
      const isBuilder = isFunction(this._config.data);

      if (isBuilder && this._config) {
        const config: BuilderActionDefConfig = this._config as BuilderActionDefConfig;
        const data = config.data as BuilderCallback<Builder<AnyRecord>>;
        this._action.config = data(
          (config.container || container).resolve(config.constructor)
        ).build() as ActionDef<TData>['config'];
      } else {
        this._action.config = this._config.data as ActionDef<TData>['config'];
      }
    }

    const res: Partial<ActionDef<TData>> = {
      onChange: this._action.onChange,
      name: this._action.name,
      components: this._action.components,
      inline: this._action.inline,
      menu: this._action.menu,
      config: this._action.config,
      lifecycles: this._action.lifecycles || []
    };

    return res as ActionDef<TData>;
  }
}
