import { ApolloError, type ServerError, type OperationVariables } from '@apollo/client';
import { Observable, Subscriber, type Subscription } from 'rxjs';
import type { UnknownRecord } from '@oms/frontend-foundation';
import type { RelayOutputShape } from '@app/common/grids/filters/filter-builders.relay';

export type ServerSideInitEvent = {
  type: 'init';
};

export type ServerSideRefetchEvent = {
  type: 'refetch';
};

export type ServerSideUpdateEvent<T extends UnknownRecord = UnknownRecord> = {
  type: 'update';
  data?: T | null;
  prevData?: T | null;
};

export type ServerSideAddedEvent<T extends UnknownRecord = UnknownRecord> = {
  type: 'add';
  data?: T | null;
  index?: number;
};

export type ServerSideRemovedEvent = {
  type: 'remove';
  id?: string | null;
};

export type ServerSideEvent<T extends UnknownRecord = UnknownRecord> =
  | ServerSideInitEvent
  | ServerSideRefetchEvent
  | ServerSideUpdateEvent<T>
  | ServerSideAddedEvent<T>
  | ServerSideRemovedEvent;

export type ServerSideViewportStore<T extends UnknownRecord = UnknownRecord> = {
  error?: Error | null;
  nodes: T[];
  totalCount: number;
  lastEvent?: ServerSideEvent<T> | null;
};

export interface IServerSideSubscriptionService<
  T extends UnknownRecord = UnknownRecord,
  TVariables extends OperationVariables = OperationVariables
> {
  query$(variables?: TVariables): Observable<ServerSideViewportStore<T>>;
}

export interface IServerSideSubscriptionDataSource<
  T extends UnknownRecord = UnknownRecord,
  TVariables extends OperationVariables = OperationVariables
> {
  get(variables?: TVariables): Promise<ServerSideViewportStore<T>>;
  added$: Observable<ServerSideAddedEvent<T>>;
  updated$: Observable<ServerSideUpdateEvent<T>>;
  removed$: Observable<ServerSideRemovedEvent>;
  getRowId(row: T): string;
  eventsTransformer(event: ServerSideEvent<T>, variables?: TVariables): ServerSideEvent<T>;
}

export class ServerSideSubscriptionsObservable<
  T extends UnknownRecord = UnknownRecord,
  TVariables extends OperationVariables = OperationVariables
> extends Observable<ServerSideViewportStore<T>> {
  constructor(
    private dataSource: IServerSideSubscriptionDataSource<T, TVariables>,
    private variables?: TVariables
  ) {
    super((sub) => {
      const subscriber = new ServerSideSubscriptionsObservableSubscriber<T, TVariables>(
        sub,
        this.dataSource,
        this.variables
      );
      return () => {
        subscriber.dispose();
      };
    });
  }
}

class ServerSideSubscriptionsObservableSubscriber<
  T extends UnknownRecord = UnknownRecord,
  TVariables extends RelayOutputShape = RelayOutputShape
> {
  private queue: ServerSideEvent<T>[] = [{ type: 'init' }];
  private store: ServerSideViewportStore<T> = {
    nodes: [],
    totalCount: 0,
    lastEvent: null
  };
  private subscriptions: Subscription[] = [];
  private timeout: NodeJS.Timeout | undefined;

  constructor(
    private subscriber: Subscriber<ServerSideViewportStore<T>>,
    private dataSource: IServerSideSubscriptionDataSource<T, TVariables>,
    private variables?: TVariables,
    private queueIntervalMs = 75
  ) {
    const added = this.dataSource.added$.subscribe((e) => {
      this.queue.push(this.dataSource.eventsTransformer(e, variables));
    });
    const updated = this.dataSource.updated$.subscribe((e) => {
      const data = e.data;
      const prevData = data
        ? this.store.nodes.find((n) => this.dataSource.getRowId(n) === this.dataSource.getRowId(data))
        : null;

      const hasFilters = hasRelayFilters(variables);

      // TODO: Note, we could be a LOT smart when to refetch / not refetch if we have the "delta" on the orderUpdated event
      if (prevData) {
        this.queue.push(this.dataSource.eventsTransformer({ ...e, data, prevData }, variables));
      } else if (hasFilters) {
        this.queue.push({ type: 'refetch' });
      }

      this.queue.push(this.dataSource.eventsTransformer({ ...e, data, prevData }, variables));
    });
    const removed = this.dataSource.removed$.subscribe((e) => {
      this.queue.push(this.dataSource.eventsTransformer(e, variables));
    });

    this.subscriptions = [added, updated, removed];

    this.drainQueue().catch(console.error);
  }

  public dispose() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriber.complete();
  }

  private setStore(delta: Partial<ServerSideViewportStore<T>>) {
    Object.assign(this.store, delta);
    this.subscriber.next(this.store);
  }

  private async fetch() {
    try {
      const result = await this.dataSource.get(this.variables);
      this.setStore({ ...result, lastEvent: { type: 'refetch' } });
    } catch (e) {
      let errMessage =
        e instanceof Error ? e.message : e instanceof ApolloError ? e.message : 'Unknown query error';

      if (
        e instanceof ApolloError &&
        e.networkError &&
        'name' in e.networkError &&
        e.networkError.name === 'ServerError'
      ) {
        const _e: ServerError = e.networkError as ServerError;
        console.error('Network error', e.networkError);
        errMessage = _e.result?.errors?.[0]?.message || errMessage;
      }

      this.setStore({
        lastEvent: null,
        error: new Error(errMessage),
        nodes: [],
        totalCount: 0
      });
    }
  }

  private applyUpdate(e: ServerSideUpdateEvent<T>) {
    if (!e.data) {
      return;
    }
    const data = e.data;
    this.setStore({
      lastEvent: e,
      nodes: this.store.nodes
        .map((n) => {
          if (this.dataSource.getRowId(n) === this.dataSource.getRowId(data)) {
            return data;
          } else {
            return n;
          }
        })
        .filter((n): n is T => n !== null && n !== undefined)
    });
  }

  private applyAdd(e: ServerSideAddedEvent<T>) {
    if (!e.data) {
      return;
    }
    if (e.index === 0 || e.index === undefined) {
      this.setStore({
        nodes: [e.data, ...this.store.nodes],
        lastEvent: e
      });
    } else {
      // add it to nodes in the position of the index
      const nodes = [...this.store.nodes];
      nodes.splice(e.index, 0, e.data);
      this.setStore({ nodes, lastEvent: e });
    }
  }

  private applyRemove(e: ServerSideRemovedEvent) {
    this.setStore({
      nodes: this.store.nodes.filter((n) => n.id !== e.id),
      lastEvent: e
    });
  }

  private async drainQueue() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    while (this.queue.length > 0) {
      const shifted = this.queue.shift();
      if (!shifted) {
        continue;
      }

      try {
        switch (shifted.type) {
          case 'init':
            await this.fetch();
            break;
          case 'refetch':
            await this.fetch();
            break;
          case 'update':
            this.applyUpdate(shifted);
            break;
          case 'add':
            this.applyAdd(shifted);
            break;
          case 'remove':
            this.applyRemove(shifted);
            break;
        }
      } catch (e) {
        console.error(e);
      }
    }

    this.timeout = setTimeout(() => {
      this.drainQueue().catch(console.error);
    }, this.queueIntervalMs);
  }
}

export function hasNoRelayFilters<T extends RelayOutputShape>(variables?: T) {
  const hasNoNormalFilters =
    Object.keys(variables?.filter || {}).filter((k) => k !== 'and' && k !== 'or').length === 0;
  const hasNoAndFilters = (variables?.filter?.and || []).length === 0;
  const hasNoOrFilters = (variables?.filter?.or || []).length === 0;
  const hasNoOtherFilters = !variables?.filter || (hasNoNormalFilters && hasNoAndFilters && hasNoOrFilters);
  return hasNoOtherFilters;
}

export function hasRelayFilters<T extends RelayOutputShape>(variables?: T) {
  return !hasNoRelayFilters(variables);
}
