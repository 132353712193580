// import { CoreWindowModel } from '@oms/oms-app/core-oms';
import {
  type FIXatdlStrategyValue,
  type FIXATDLddfValues,
  type FIXatdlStrategyValueComboBoxItem
} from './fixatdl-strategy-field.contracts';
import {
  DestinationType,
  OrderType,
  type StrategyControl,
  type StrategyParameter,
  type StrategyControlInput,
  type StrategyParameterInput
} from '@oms/generated/frontend';
import { AtdlFormats } from '@valstro/fixatdl-core';
import { convertToNumber } from '@oms/shared/util';
import { type RouteOrderFormValues } from '../route-order.form-contract';
import type { AnyRecord, ComboBoxItem, FormOptions } from '@oms/frontend-foundation';
import { omit } from 'lodash';
import type { Optional } from '@oms/shared/util-types';

export const getVenueIdFromRouteForm = (values: AnyRecord, venueIdKey: string): string | undefined => {
  let venueId: string | null = null;

  if (
    venueIdKey in values &&
    typeof values[venueIdKey] === 'object' &&
    values[venueIdKey] &&
    'value' in values[venueIdKey] &&
    typeof values[venueIdKey].value === 'string'
  ) {
    venueId = values[venueIdKey].value;
  }

  if (!venueId) {
    return undefined;
  }

  // These are NOT venues
  const destinationTypes = Object.values(DestinationType) as string[];

  if (destinationTypes.includes(venueId)) {
    return undefined;
  }

  return venueId;
};

export const transformDdfValuesToParams = (
  ddf: FIXATDLddfValues
): {
  parameters: StrategyParameterInput[];
  controls: StrategyControlInput[];
} => {
  const parameters = Object.entries(ddf.parameters).map(([key, value]): StrategyParameterInput => {
    return {
      name: key,
      value: AtdlFormats.coerceEmpty(`${value}`, '{NULL}') as string
    };
  });

  const controls = Object.entries(ddf.controls).map(([key, value]): StrategyControlInput => {
    return {
      id: key,
      value: AtdlFormats.coerceEmpty(`${value}`, '{NULL}') as string
    };
  });

  return {
    parameters,
    controls
  };
};

export const transformParamsAndControlsToDdfValues = (
  params: StrategyParameterInput[],
  controls: StrategyControlInput[]
): Record<string, unknown> => {
  const result: Record<string, unknown> = {};

  params.forEach(({ name, value }) => {
    if (!name) return;

    result[name] = AtdlFormats.coerceValue(value, {
      formats: { date: { format: 'iso' }, time: { format: 'iso-date-time' } }
    });
  });
  controls.forEach(({ id, value }) => {
    if (!id) {
      return;
    }
    if (id in result) {
      console.warn(`Key ${id} already exists. Current value: ${result[id]}, new value: ${value} `);
    }
    result[id] = AtdlFormats.coerceValue(value, {
      formats: { date: { format: 'iso' }, time: { format: 'iso-date-time' } }
    });
  });

  return result;
};

export const sanitizeOrderFormValues = (values: RouteOrderFormValues) => {
  const orderType = values.limitPrice === OrderType.Market ? OrderType.Market : OrderType.Limit;

  const limitPrice =
    orderType === OrderType.Limit && typeof values.limitPrice === 'string'
      ? convertToNumber(values.limitPrice)
      : undefined;

  const quantity = typeof values.quantity === 'string' ? convertToNumber(values.quantity)
    : typeof values.quantity === 'number' ? values.quantity : undefined;

  return {
    ...values,
    limitPrice,
    orderType,
    quantity
  };
};

export const forceStrategyFieldChange = (formApi: FormOptions<RouteOrderFormValues>) => {
  const strategyFieldValue = formApi.getState().values.strategy;
  formApi.change('strategy', structuredClone(strategyFieldValue));
};

export const getFIXatdlStrategyValue = (
  prevStrategyItem: FIXatdlStrategyValueComboBoxItem | null,
  newStrategyItem: FIXatdlStrategyValueComboBoxItem,
  venueId: string,
  routeOrderFormValues: RouteOrderFormValues
): FIXatdlStrategyValue => {
  const { strategyName, strategyParams, strategyControls } = prevStrategyItem
    ? prevStrategyItem.value
    : newStrategyItem?.value || {};

  const shouldResetParams = prevStrategyItem && strategyName !== newStrategyItem.value.strategyName;

  return {
    ...newStrategyItem.value,
    strategyParams: shouldResetParams ? [] : strategyParams || [],
    strategyControls: shouldResetParams ? [] : strategyControls || [],
    venueId,
    orderFormValues: routeOrderFormValues
  } as FIXatdlStrategyValue;
};

export const getStrategyWindowId = (formId: string, strategyName?: string) => {
  return `${formId}-all-fields-dialog-window-${strategyName}`;
};

export function createStrategyInput(stratValue: Optional<AnyRecord>, stratLabel?: Optional<string>) {
  return stratValue
    ? {
        name: stratValue.strategyName,
        uirep: stratLabel ?? "",
        parameters: stratValue.strategyParams,
        controls: stratValue.strategyControls
      }
    : undefined;
}

export function createStrategyComboboxItem(
  strategy: AnyRecord | undefined | null,
  venueId: string | undefined
) {
  return strategy
    ? ({
        id: strategy.name,
        label: strategy.uirep || strategy.name,
        type: 'item',
        value: {
          strategyName: strategy.name || '',
          venueId: venueId || '',
          strategyParams: (strategy.parameters.map((p: StrategyParameter) => omit(p, '__typename')) ||
            []) as StrategyParameter[],
          strategyControls: (strategy.controls?.map((p: StrategyControl) => omit(p, '__typename')) ||
            []) as StrategyControl[]
        }
      } as ComboBoxItem<FIXatdlStrategyValue>)
    : undefined;
}
