import { inject, Lifecycle, scoped } from 'tsyringe';
import { GridColumnHeadersService } from '../services/grid-column-header.service';
import { EventHandler } from './event.handler';
import { EventSource, GridEventType } from './event.source';
import { ColumnApi } from '@ag-grid-community/core';

@scoped(Lifecycle.ContainerScoped)
export class GridColumnHeadersEventHandler implements EventHandler<any> {
  private _service: GridColumnHeadersService;

  public name = 'grid-column-headers-event-handler';

  constructor(@inject(GridColumnHeadersService) service: GridColumnHeadersService) {
    this._service = service;
  }

  private updateColumnHeaders(e: { columnApi: ColumnApi }) {
    e.columnApi.getAllDisplayedColumns().forEach((col) => {
      this._service.colHeaderTextHandler(col.getColId());
    });
  }

  public addEvents(eventSource: EventSource<GridEventType, any>) {
    let storeFired = false;
    eventSource.add('onGridReady', (e) => {
      this.updateColumnHeaders(e);
      this._service.onInit(e).catch(console.error);
    });

    eventSource.add('onFirstDataRendered', (e) => {
      this.updateColumnHeaders(e);
    });

    eventSource.add('onStoreUpdated', (e) => {
      if (storeFired) {
        return;
      }
      storeFired = true;
      this.updateColumnHeaders(e);
    });

    // Note, I think this is deprecated. I am re-using `storeFired` on purpose.
    eventSource.add('onStoreRefreshed', (e) => {
      if (storeFired) {
        return;
      }
      storeFired = true;
      this.updateColumnHeaders(e);
    });

    eventSource.add('onGridColumnsChanged', (e) => {
      this.updateColumnHeaders(e);
    });

    eventSource.add('onGridSizeChanged', (e) => {
      this.updateColumnHeaders(e);
    });

    eventSource.add('onBodyScrollEnd', (e) => {
      this.updateColumnHeaders(e);
    });

    eventSource.add('onColumnVisible', (e) => {
      if (e.visible && e.column) {
        this._service.colHeaderTextHandler(e.column.getColId());
      }
    });

    eventSource.add('onColumnResized', (e) => {
      if (e.column) {
        this._service.colHeaderTextHandler(e.column.getColId());
      }
    });
  }

  public removeEvents() {}
}
