import { useEffect, useMemo } from 'react';
import { useService } from '@oms/frontend-foundation';
import type { MontageFilter } from '@app/data-access/services/trading/montage/montage.types';
import { MontageService } from '@app/data-access/services/trading/montage/montage.service';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';
import { toClientsideGridData } from '@oms/frontend-vgrid';

export const useDepthGridData = ({ type }: MontageFilter) => {
  const scopedActorId = useScopedActorId();

  const montageService = useService(MontageService);

  const merged = useMemo(
    () => montageService.mergeMontageItems(scopedActorId),
    [scopedActorId, montageService]
  );

  const data$ = useMemo(() => {
    merged.filter$.filter({ type });
    return merged.filter$.data$.pipe(toClientsideGridData());
  }, [merged, type]);

  const targetState = useMemo(() => merged.targetState, [merged]);

  useEffect(() => {
    return () => {
      targetState.destroy();
    };
  }, [targetState]);

  const pricePartitionMap$ = useMemo(() => merged.pricePartitionMap$, [merged]);

  return useMemo(
    () => ({
      data$,
      targetState,
      pricePartitionMap$
    }),
    [data$, pricePartitionMap$, targetState]
  );
};
