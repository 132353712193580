import { type RadioButtonControl } from '@valstro/fixatdl-core';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { type ControlTransformer } from './control.transformer.types';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const radioButtonControl: ControlTransformer<RadioButtonControl> = (
  radioButtonControl,
  _query,
  formValues
) => {
  return {
    component: fixatdlFormComponentTypes.RADIO_BUTTON,
    name: radioButtonControl.id,
    initialValue: AtdlFormats.coerceValue(formValues[radioButtonControl.id]),
    label: radioButtonControl.label,
    checkedEnumRef: radioButtonControl.checkedEnumRef || 'true',
    uncheckedEnumRef: radioButtonControl.uncheckedEnumRef || 'false',
    radioGroup: radioButtonControl.radioGroup
  };
};
