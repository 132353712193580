import { Icon } from '../components/icon/icon';
import type { IconProps } from '../components/icon/icon';

export type OmittedIconProps = Omit<IconProps, 'as' | 'label'> & {
  style?: { [key: string]: string | number };
};

export const createIconComponent =
  (icon: React.ElementType, label: string, defaultProps: OmittedIconProps = {}) =>
  (props: OmittedIconProps) =>
    <Icon label={label} as={icon} {...defaultProps} {...props} />;
