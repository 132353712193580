import { type AnyRecord } from '@valstro/workspace';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import {
  type FieldContract,
  type ComboBoxItem,
  type IAdvancedSelectField,
  type ModifiedFieldForOptions
} from '@oms/frontend-foundation';
import { type DestinationType, type ExecutionVenueFragment } from '@oms/generated/frontend';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import type { VenuesComboboxValue } from './venue-field.queries';
import { mapDestinationType } from '@app/common/mappers/map-destination-type';

export const createVenueField = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedSelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'advanced-select').advancedSelectOptions<VenuesComboboxValue>({
    label: 'Venue',
    width: 250,
    autoSizeWidthToTrigger: false,
    autoSizeWidth: false,
    query: ADVANCED_SELECT_QUERY_ENUM.WATCH_All_VENUES_FOR_USER_QUERY,
    ...overrides
  });

export const createVenueComboboxItem = (
  venueFrag: ExecutionVenueFragment
): ComboBoxItem<VenuesComboboxValue> => {
  return {
    type: 'item',
    id: venueFrag.id,
    label: venueFrag.executionVenueDescription,
    value: venueFrag.id
  };
};

export const createVenueComboboxItemFromDestinationType = (
  destinationType: DestinationType
): ComboBoxItem<VenuesComboboxValue> => {
  return {
    type: 'item',
    id: destinationType,
    label: mapDestinationType(destinationType),
    value: destinationType
  };
};
