import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type { CurrencyFormOutput } from './currency.form-common';
import { FieldContract } from '@oms/frontend-foundation';

const ofc = FieldContract.create<CurrencyFormOutput, AdditionalFormFieldUnion>();

export const idField = ofc.field('id', 'text-field').options({
  label: 'Code',
  isRequired: true,
  validate: [{ type: 'required' }]
});

export const longNameField = ofc.field('longName', 'text-field').options({
  label: 'Name',
  isRequired: true,
  validate: [{ type: 'required' }]
});
