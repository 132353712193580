import { Button, Flex, Textarea } from '@oms/shared-frontend/ui-design-system';
import { useState } from 'react';
import { parseJSON } from '@oms/shared/util';
import { useApplyAppSnapshot } from '@app/common/snapshot-layout/snapshot-layout.hooks';
import type { AppSnapshot } from '@app/app-config/snapshot-layout.config';
import { useOpenMessageDialog } from '@app/common/dialog/dialog.common';

export function ImportSnapshotWidget() {
  const [importText, setImportText] = useState('');
  const applyAppSnapshot = useApplyAppSnapshot();
  const openMessageDialog = useOpenMessageDialog();

  const handleImport = async () => {
    if (!importText) {
      openMessageDialog('Please paste your layout / snapshot in the textarea').catch(console.error);
    }
    try {
      const parsed = parseJSON<Partial<AppSnapshot>>(importText);
      if (!parsed) {
        throw new Error('Could not parse OR load. Your layout/snapshot may be invalid');
      }
      await applyAppSnapshot(parsed);
    } catch (error) {
      console.error(error);
      openMessageDialog((error as Error).message).catch(console.error);
    }
  };

  return (
    <Flex sx={{ position: 'absolute', inset: 4, flexDirection: 'column' }}>
      <Textarea
        value={importText}
        onChange={(e) => setImportText(e.target.value)}
        placeholder="Paste your layout / snapshot here"
        sx={{ flexGrow: 1, marginBottom: 4 }}
      />
      <Button
        isFullWidth
        onClick={() => {
          handleImport().catch(console.error);
        }}
        variant="secondary"
      >
        Import
      </Button>
    </Flex>
  );
}

export default ImportSnapshotWidget;
