import { IconButton, IconButtonProps } from './icon-button';
import { Icon } from '../icon/icon';
import { Cross1Icon } from '@radix-ui/react-icons';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

type OmittedProps = 'aria-label' | 'icon';

export type CloseButtonProps = {} & Omit<IconButtonProps, OmittedProps>;

export const CloseButton = polymorphicComponent<'button', CloseButtonProps>((props, ref) => {
  const { children, isLoading, variant = 'ghost', ...rest } = props;

  return (
    <IconButton
      ref={ref}
      variant={variant}
      aria-label="Close"
      isLoading={isLoading}
      icon={!isLoading ? <Icon label="Close Icon" as={Cross1Icon} /> : null}
      {...rest}
    />
  );
});

if (__DEV__) {
  IconButton.displayName = 'IconButton';
}
