import { useMemo } from 'react';
import { COMMON_ACTOR_TYPE, type WidgetActorSchema } from '@valstro/workspace';
import { useClosestFlexLayoutActor, useClosestActorByType } from '@valstro/workspace-react';
import { cleanMaybe } from '@oms/shared/util';

export const useScopedActorId = (): string => {
  const flexLayout = useClosestFlexLayoutActor(); // Note, still need to rename this to "parent"
  const widget = cleanMaybe(useClosestActorByType<WidgetActorSchema>(COMMON_ACTOR_TYPE.WIDGET));
  const window = cleanMaybe(useClosestActorByType<WidgetActorSchema>(COMMON_ACTOR_TYPE.WINDOW));
  return useMemo(() => {
    // Scope the state to the flexLayout FIRST, then the widget, then window... if all else fails, we throw
    const scopedActorId = flexLayout?.id || widget?.id || window?.id;
    if (!scopedActorId) {
      throw new Error('Could not resolve scoped actor ID');
    }
    return scopedActorId;
  }, [flexLayout?.id, widget?.id, window?.id]);
};
