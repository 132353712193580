import { map, type Observable } from 'rxjs';
import {
  GetTagsDocument,
  type GetTagsQuery,
  type TagFragment,
  type TagsEdgeFragment,
  type TagTypeEnum
} from '@oms/generated/frontend';
import { inject, singleton } from 'tsyringe';
import { asDataSource, type DataSourceCommon, type ICrudService } from '@oms/frontend-foundation';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';

@singleton()
export class OrderTagsService implements ICrudService<TagFragment> {
  private _apolloClient: ApolloClientRPC;

  constructor(@inject(ApolloClientRPC) apolloClient: ApolloClientRPC) {
    this._apolloClient = apolloClient;
  }

  watchAll$(...tagTypes: TagTypeEnum[]): Observable<DataSourceCommon<TagFragment>> {
    const result = this._apolloClient.watchQuery<GetTagsQuery>({
      query: GetTagsDocument
    });

    return result.pipe(
      map(({ data }) => {
        const tags = (data.tags?.edges as TagsEdgeFragment[])?.map((e) => e.node);
        if (tagTypes.length) {
          const t = tags.filter((tag) => tag.tagTypes.some((t) => t && tagTypes.includes(t)));
          return asDataSource(t);
        }
        return asDataSource(tags);
      })
    );
  }
}
