import { PROCESS_ID } from '@valstro/workspace';
import { t } from '@oms/codegen/translations';
import { Button, ButtonGroup, Flex } from '@oms/shared-frontend/ui-design-system';
import { useService, useWorkspace } from '@oms/frontend-foundation';
import { Logger } from '@oms/shared/util';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { MontageService } from '@app/data-access/services/trading/montage/montage.service';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';
import { useMontageState } from '@app/data-access/offline/collections/montage/hooks/use-montage-state.hook';
import { openConfirmation } from '@app/generated/sdk';
import { useMontageLayout } from '../use-montage-layout.hooks';
import { useCallback } from 'react';

const logger = Logger.labeled('MontageActionsPanel');

export const MontageActionsPanel = () => {
  const montageService = useService(MontageService);
  const workspace = useWorkspace();
  const scopedActorId = useScopedActorId();
  const { instrumentId } = useMontageState();
  const { layout } = useMontageLayout();

  const isVisible = instrumentId && (layout === 'expanded' || layout === 'standard');

  const handleOnCancelAllClicked = useCallback(async () => {
    try {
      const [_dialog, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
        title: t('app.montage.dialog.cancelOrders.title'),
        componentProps: {
          autoClose: true,
          message: t('app.montage.dialog.cancelOrders.body'),
          confirmButtonText: t('app.common.yes'),
          cancelButtonText: t('app.common.no')
        }
      });

      const result = await api.awaitFirstEvent;

      if (result.type === DIALOG_EVENT_TYPE.OK) {
        const sub = montageService.cancelMontageUnboundTradingOrders$(scopedActorId).subscribe((response) => {
          if (!response.isSuccess()) {
            const msgs = response?.errors.map((e) => e.message).join(', ');
            logger.error(`Error: ${msgs}, ${workspace}`);
          }
        });
        return () => {
          sub.unsubscribe();
        };
      }
    } catch (error) {
      logger.error('Error during orders cancellation:', error);
    }
  }, [workspace, montageService, scopedActorId]);

    if (!isVisible) {
    return null;
  }

  return (
    <Flex justify="flex-end" sx={{ m: 4 }}>
      <ButtonGroup variant="secondary">
        <Button onClick={handleOnCancelAllClicked}>{t('app.montage.cancelAll')}</Button>
        <Button onClick={() => alert('Woohoo!')}>{t('app.montage.addToWatchlist')}</Button>
        <Button onClick={() => alert('Woohoo!')}>{t('app.common.unwind')}</Button>
      </ButtonGroup>
    </Flex>
  );
};
