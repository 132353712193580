import { useVGrid, VGrid, CLEAR_GRID_FILTER_ACTION_TYPE } from '@oms/frontend-vgrid';
import { type VisibleTradingOrderInfoFragment as TOFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { inlineTradingOrderActions } from '../trading-order-monitor/grid-actions/inline.trading-order.actions';
import { createOpenEntryTradingOrderAction } from '../trading-order-monitor/grid-actions/open-entry.trading-order.action';
import { cancelTradingOrderAction } from '../trading-order-monitor/grid-actions/cancel.trading-order.action';
import { modifyTradingOrderAction } from '../trading-order-monitor/grid-actions/modify.trading-order.action';
import { viewTradingOrderAction } from '../trading-order-monitor/grid-actions/view.trading-order.action';
import { refreshServersideAction } from '@app/common/grids/actions/refresh-serverside.action';
import { investorOrderTradingOrderMonitorColumnLibrary } from '../trading-order-monitor/trading-order-monitor.columns';
import { InvestorOrderTradingOrdersService } from '@app/data-access/services/trading/investor-orders/investor-order-trading-orders.service';

export type TradingOrderDetailGridProps = {
  investorOrderId?: string;
};

export const TradingOrdersDetailGrid = ({ investorOrderId }: TradingOrderDetailGridProps) => {
  const datasourceService = useService(InvestorOrderTradingOrdersService);
  const gridProps = useVGrid<TOFragment>(
    'investor-order-monitor-trading-orders',
    (builder) =>
      builder
        .columnLibrary(investorOrderTradingOrderMonitorColumnLibrary)
        .rowSelection((c) => c.multiple())
        // TODO This is should be a subscription based on createServerSideDatasource instead of polling
        .datasource((d) => d.source(datasourceService.watchAll$(investorOrderId)).rowId((r) => r.data?.id))
        .toolbarStrategies('hidden')
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(createOpenEntryTradingOrderAction<TOFragment>())
                .action(CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(viewTradingOrderAction)
                .action(modifyTradingOrderAction)
                .action(cancelTradingOrderAction)
                .action(inlineTradingOrderActions)
                .action(refreshServersideAction)
            )
            .settings((s) => s.fromFile({}))
        ),
    [datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default TradingOrdersDetailGrid;
