import { isEqual } from 'lodash';
import React, { type FC } from 'react';
import { mapFixAtdlListOptions } from '../../utils';
import { Orientation } from '@valstro/fixatdl-core';
import { type FieldProps, type ICommonField, Radio, useFieldApi } from '@oms/frontend-foundation';
import { type RadioButtonListProps } from '../../fixatdl-form.types';

export const RadioButtonList: FC<FieldProps<RadioButtonListProps>> = React.memo(
  ({ initialValue, options, orientation, ...props }) => {
    const {
      input: { value }
    } = useFieldApi<ICommonField<string>>(props);

    return (
      <Radio
        {...props}
        options={mapFixAtdlListOptions(options)}
        isInline={orientation === Orientation.HORIZONTAL ? true : false}
        align="flex-start"
        defaultValue={value || initialValue}
        style={{ height: '2rem', whiteSpace: 'nowrap', marginRight: '10px' }}
        component="radio"
      />
    );
  },
  isEqual
);
