import type { RxCollection, RxCollectionCreator } from 'rxdb-v15';
import type { Action } from '../types/action.types';
import { GRIDS_COLLECTION_NAME } from '@app/data-access/offline/collections/grids.collection';

export const ACTION_COLLECTION_NAME = 'actions';

export const ACTION_COLLECTION: RxCollectionCreator<Action> = {
  schema: {
    keyCompression: false, // set this to true, to enable the keyCompression
    version: 0,
    title: 'action button schema with composite primary',
    primaryKey: {
      key: 'id',
      fields: ['widgetTypeId', 'commandId', 'objectId', 'order', 'locationId', 'gridStateId'],
      separator: '|'
    },
    type: 'object',
    properties: {
      id: {
        type: 'string',
        maxLength: 1024 // <- the primary key must have set maxLength
      },
      widgetTypeId: {
        type: 'string'
      },
      objectId: {
        type: 'string'
      },
      locationId: {
        type: 'string'
      },
      commandId: {
        type: 'string'
      },
      confirmation: {
        type: 'boolean'
      },
      payload: {
        type: 'object'
      },
      label: {
        type: 'string'
      },
      color: {
        type: 'string'
      },
      size: {
        type: 'string'
      },
      order: {
        type: 'number'
      },
      separator: {
        type: 'boolean'
      },
      gridStateId: {
        type: 'string',
        ref: GRIDS_COLLECTION_NAME
      },
      parentId: {
        type: 'string',
        ref: 'actions'
      }
    },
    required: ['id', 'widgetTypeId', 'commandId', 'objectId', 'order', 'locationId', 'gridStateId']
  }
};

export type ActionsCollection = RxCollection<Action>;
