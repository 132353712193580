import { type AnyRecord } from '@valstro/workspace';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { type FieldContract, type ITextField, type ModifiedFieldForOptions } from '@oms/frontend-foundation';
import omit from 'lodash/omit';
import { OrderSideType } from '@oms/generated/frontend';

export const createLocateField = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<ITextField>
) =>
  fc.field(key, 'text-field').options<ITextField>({
    label: 'Locate',
    ...overrides,
    actions: {
      resolveProps: [
        'disableWhen',
        {
          fieldName: 'sideType',
          equals: Object.values(omit(OrderSideType, 'Short')),
          disableProps: ['isDisabled'],
          clearValueWhenDisabled: true
        }
      ],
      ...(overrides?.actions || {})
    }
  });
