import { Text } from '@oms/shared-frontend/ui-design-system';
import { isEqual } from 'lodash';
import React, { type FC } from 'react';
import { type LabelProps } from '../../fixatdl-form.types';
import { type FieldProps, type ICommonField, useFieldApi } from '@oms/frontend-foundation';
import { type fixatdlFormComponentTypes } from '../fixatdl-component.mappers';

export const Label: FC<FieldProps<LabelProps>> = React.memo(({ ...props }) => {
  const {
    input: { value }
  } = useFieldApi<ICommonField<fixatdlFormComponentTypes.LABEL, string>>(props);

  return <Text sx={{ paddingBottom: 4 }}>{value}</Text>;
}, isEqual);
