import {
  type InstrumentDetailsFragment,
  type InstrumentDetailsSimpleFragment,
  type LookupBySymbolSimpleQuery,
  type LookupBySymbolSimpleQueryVariables,
  LookupBySymbolSimpleDocument
} from '@oms/generated/frontend';
import { cleanMaybe } from '@oms/shared/util';
import { type AdvancedSelectQueryFn, type ComboBoxItem } from '@oms/frontend-foundation';
import {
  convertInstrument,
  isInstrumentDetailsFragment,
  isInstrumentDetailsSimpleFragment,
  makeInstrumentsCombobox
} from '@app/common/types/instrument/instrument.util';
import type { AnyInstrumentInput } from '@app/common/types/instrument/instrument.types';
import { typeaheadQuery } from '../../utils/typeahead.util';

export type AdvancedSelectInstrumentFieldValue = ComboBoxItem<InstrumentDetailsFragment>;
export type AdvancedSelectInstrumentFieldValueSimple = ComboBoxItem<InstrumentDetailsSimpleFragment>;

export const instrumentMapper = (instrument: AnyInstrumentInput): AdvancedSelectInstrumentFieldValue => {
  const instrumentDetails: InstrumentDetailsFragment = (() => {
    if (isInstrumentDetailsFragment(instrument)) return instrument;
    if (isInstrumentDetailsSimpleFragment(instrument))
      return convertInstrument.simpleDetails(instrument).toDetails();
    return convertInstrument.simple(instrument).toDetails();
  })();
  return makeInstrumentsCombobox(instrumentDetails) as AdvancedSelectInstrumentFieldValue;
};

export const simpleInstrumentMapper = (
  instrument: AnyInstrumentInput
): AdvancedSelectInstrumentFieldValueSimple => {
  const instrumentDetails: InstrumentDetailsSimpleFragment = (() => {
    if (isInstrumentDetailsFragment(instrument))
      return convertInstrument.details(instrument).toSimpleDetails();
    if (isInstrumentDetailsSimpleFragment(instrument)) return instrument;
    return convertInstrument.simple(instrument).toSimpleDetails();
  })();
  return makeInstrumentsCombobox(instrumentDetails) as AdvancedSelectInstrumentFieldValueSimple;
};

export const lookupInstrumentsQuery: AdvancedSelectQueryFn<InstrumentDetailsFragment> = typeaheadQuery<
  InstrumentDetailsFragment,
  LookupBySymbolSimpleQuery,
  LookupBySymbolSimpleQueryVariables
>({
  queryFn: (inputValue) => ({
    query: LookupBySymbolSimpleDocument,
    variables: { symbol: inputValue },
    fetchPolicy: 'cache-and-network'
  }),
  mapper: (data) => {
    const instruments = cleanMaybe(data.instrumentBySymbol, []);
    const results = (instruments as AnyInstrumentInput[]).map(instrumentMapper);
    return results;
  }
});

export const lookupInstrumentsSimpleQuery: AdvancedSelectQueryFn<InstrumentDetailsSimpleFragment> =
  typeaheadQuery<
    InstrumentDetailsSimpleFragment,
    LookupBySymbolSimpleQuery,
    LookupBySymbolSimpleQueryVariables
  >({
    queryFn: (inputValue) => ({
      query: LookupBySymbolSimpleDocument,
      variables: { symbol: inputValue },
      fetchPolicy: 'cache-and-network'
    }),
    mapper: (data) => {
      const instruments = cleanMaybe(data.instrumentBySymbol, []);
      const results = (instruments as AnyInstrumentInput[]).map(simpleInstrumentMapper);
      return results;
    }
  });
