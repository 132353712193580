import { type OperatorFunction, catchError, finalize, map, of, retry, tap, timer } from 'rxjs';
import type {
  EndpointFilter,
  FactsetEndpoints,
  FactsetFinalizeConfig,
  FactsetMapConfig,
  MarketDataConfig
} from './factset.types';
import get from 'lodash/get';
import set from 'lodash/set';
import type { Level1IntegrationEvent } from '@oms/generated/frontend';
import { createLogger } from '@oms/shared/util';

const logger = createLogger({ label: 'Level1IntegrationEvent' });

export const factsetMap = <T extends object, R extends object>(
  config: FactsetMapConfig<R>
): OperatorFunction<T, R> => {
  const { mappings, result = {} as R } = config;
  return (obs$) =>
    obs$.pipe(
      map((e) => {
        const missingFields: (keyof R)[] = [];
        mappings.forEach(([source, dest]) => {
          const val = get(e, source);
          const exists = typeof val === 'boolean' || !!val;

          if (!exists) {
            missingFields.push(dest);
          }

          exists && set(result, dest, val);
        });
        evaluateEndpointFilter(config.endpoint, config.logMissingFields, () => {
          missingFields.length &&
            logger.warn(`[${config.endpoint}] factset missing fields`, missingFields, ' response ', e);
        });

        return result;
      })
    );
};

export const marketDataErrors = <T>(
  scope: FactsetEndpoints,
  config: MarketDataConfig<Level1IntegrationEvent>
): OperatorFunction<T, T | Level1IntegrationEvent> => {
  return (source$) =>
    source$.pipe(
      retry({
        resetOnSuccess: true,
        delay: (err, count) => {
          const retryTime = Math.min(count * 1000, 30000); // capping retry backoff to 30s.
          evaluateEndpointFilter(scope, config.logRetries, () => {
            logger.error(`[${scope}] Error ${err}. Retrying in ${retryTime / 1000}s.`);
          });
          return timer(retryTime);
        }
      }),
      catchError((err, _caught) => {
        logger.error(`[${scope}] `, err);
        return of(config.result as Level1IntegrationEvent);
      })
    );
};

export const factsetFinalizeEndpoint = <T>(config: FactsetFinalizeConfig): OperatorFunction<T, T> => {
  const { endpoint, job, client } = config;
  return (source$) =>
    source$.pipe(
      finalize(() => {
        if (job.idJob) {
          logger.info(`Closing for ${endpoint}. Job ${job.idJob}.`);
          client
            .unobserveEndpoint(job.idJob)
            .then(() => {
              logger.info(`Closed for ${endpoint}. Job ${job.idJob}.`);
            })
            .catch((e) => logger.error(e));
        }
      })
    );
};

export const logFactsetResponse = <T>(
  endpoint: FactsetEndpoints,
  config: EndpointFilter | undefined
): OperatorFunction<T, T> => {
  return (obs$) =>
    obs$.pipe(
      tap((e) => {
        evaluateEndpointFilter(endpoint, config, () => {
          logger.debug(`Factset ${endpoint} result`, e);
        });
      })
    );
};

export const evaluateEndpointFilter = (
  endpoint: FactsetEndpoints,
  config: EndpointFilter | undefined,
  fn: () => void
) => {
  if (config === true || (Array.isArray(config) && config.includes(endpoint))) {
    fn();
  }
};
