import { isEqual } from 'lodash';
import React, { type FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { mapFixAtdlAdvancedListOptions, mapOptionToComboboxItem } from '../../utils';
import {
  AdvancedMultiSelectField,
  type ComboBoxItem,
  type FieldProps,
  type ICommonField,
  useFieldApi
} from '@oms/frontend-foundation';
import { type FixAtdlListItem, type MultiSelectListProps } from '../../fixatdl-form.types';

const convertValuesToStringArray = (values: Array<ComboBoxItem | string>): string[] | undefined => {
  if (!Array.isArray(values)) {
    return;
  }

  return values.map((v) => {
    if (typeof v === 'object' && v !== null) {
      if ('value' in v) {
        return String(v.value);
      } else {
        throw new Error("Object must contain property 'value'");
      }
    }
    if (['string', 'boolean', 'number'].includes(typeof v)) {
      return String(v);
    }
    throw new Error(`Received unexpected value ${v}`);
  });
};

export const MultiSelectList: FC<FieldProps<MultiSelectListProps>> = React.memo(
  ({ initialValue, options = [], ...props }) => {
    const { input } = useFieldApi<ICommonField<string>>(props);

    const onChangeRef = useRef(input.onChange);
    onChangeRef.current = input.onChange;

    const valueRef = useRef<ComboBoxItem[]>(input.value);
    valueRef.current = input.value as ComboBoxItem[];

    useEffect(() => {
      const initValue = convertValuesToStringArray(valueRef.current) || initialValue;
      onChangeRef.current(initValue);
    }, [initialValue]);

    const handleChange = useCallback((values: ComboBoxItem[]) => {
      onChangeRef.current(convertValuesToStringArray(values));
    }, []);

    const comboboxConvertedValues: ComboBoxItem<string>[] | undefined = useMemo(() => {
      const values = convertValuesToStringArray(input.value) || initialValue;

      return values?.reduce((acc, value) => {
        const option = options.find((o: FixAtdlListItem) => o.enumID === value);

        if (!option) {
          console.error(`Provided initial value '${value}' doesn't match any option`);
          return acc;
        }

        if (!option.uiRep.trim()) {
          return acc;
        }

        return [...acc, mapOptionToComboboxItem(option)];
      }, [] as ComboBoxItem<string>[]);
    }, [initialValue, input.value, options]);

    return (
      <AdvancedMultiSelectField
        {...props}
        initialValue={comboboxConvertedValues}
        options={mapFixAtdlAdvancedListOptions(options)}
        onChange={handleChange}
        value={comboboxConvertedValues}
        component="advanced-multi-select"
      />
    );
  },
  isEqual
);
