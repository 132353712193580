import { mapRateType } from '@app/common/mappers/map-rate-type';
import { mapScaleBasis } from '@app/common/mappers/map-scale-basis';
import { mapScaleRateType } from '@app/common/mappers/map-scale-rate-type';
import {
  ChargeGroup,
  type ChargeScaleDetails,
  type CompositeChargeInfoFragment,
  type GetMatchingChargeSchedulesQuery,
  type Maybe,
  RateType,
  type ScaleBasis,
  type ScaleRateType
} from '@oms/generated/frontend';
import {
  DISPLAY_FIELD_COMPONENT_TYPE,
  type GridColValues,
  type DisplayGridProps
} from '@oms/shared-frontend/ui-design-system';
import type { ResponsiveValue } from '@oms/shared-frontend/ui-design-system/css';
import { groupBy } from 'ramda';

type GridPopoverChargeScaleProps = {
  chargeName?: string;
  scaleName?: string;
  basis?: ScaleBasis | null;
  rateType?: ScaleRateType | null;
  rows?: Maybe<ChargeScaleDetails>[] | null;
};

type GridPopoverChargeScheduleProps = {
  chargeName?: string;
  rateType?: RateType;
  rateValue?: number | null;
  min?: number | null;
  max?: number | null;
};

export const gridPopoverChargeScale = ({
  chargeName,
  scaleName,
  basis,
  rateType,
  rows
}: GridPopoverChargeScaleProps): DisplayGridProps['items'] => {
  const layout: DisplayGridProps['items'] = [
    {
      label: 'Charge',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      },
      layout: 'horizontal'
    },
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: chargeName ?? ''
      },
      layout: 'horizontal'
    },
    {
      label: 'Scale Name',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      },
      layout: 'horizontal',
      labelMargin: 5
    },
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: scaleName ?? ''
      },
      layout: 'horizontal'
    },
    {
      label: 'Scale Basis',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      }
    },
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: basis ? mapScaleBasis(basis) : ''
      }
    },
    {
      label: 'Rate Type',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      }
    },
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: rateType ? mapScaleRateType(rateType) : ''
      }
    }
  ];
  if (!rows || !rows.length) return layout;

  const detailRows: DisplayGridProps['items'] = rows.flatMap((row, index) => {
    const rowNumber = index + 1;
    if (!row) return [];
    const to = row.to === -1 ? '' : row.to;

    return [
      {
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        },
        sx: { borderColor: 'border.subtle' },
        style: { borderBottom: '1px solid', marginTop: 5, marginBottom: 5 }
      },
      {
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        },
        sx: { borderColor: 'border.subtle' },
        style: { borderBottom: '1px solid', marginTop: 5, marginBottom: 5 }
      },
      {
        label: `SCALE ${rowNumber}`,
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        }
      },
      {
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        }
      },
      {
        label: 'Range',
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        }
      },
      {
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: `${row.from}${to ? ` - ${to}` : ''}`
        }
      },
      {
        label: 'Value',
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        }
      },
      {
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
          format: 'price',
          value: row.rateValue || row.rateValue === 0 ? row.rateValue : ''
        }
      }
    ];
  });

  return [...layout, ...detailRows];
};

export const gridPopoverChargeSchedule = ({
  chargeName,
  rateType,
  rateValue,
  min,
  max
}: GridPopoverChargeScheduleProps): DisplayGridProps['items'] => {
  const items: DisplayGridProps['items'] = [
    {
      label: 'Charge',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: chargeName ?? ''
      }
    },
    {
      label: 'Rate Type',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: rateType ? mapRateType(rateType) : ''
      }
    },
    {
      label: 'Rate Value',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price',
        value: rateValue ?? ''
      }
    }
  ];

  if (min) {
    items.push({
      label: 'Min',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price',
        value: min
      }
    });
  }

  if (max) {
    items.push({
      label: 'Max',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price',
        value: max
      }
    });
  }

  return items;
};

export const createChargeScheduleDisplayFromCompositeCharges = (
  compositeCharges: CompositeChargeInfoFragment[]
): DisplayGridProps | undefined => {
  if (!compositeCharges || compositeCharges.length === 0) return undefined;

  const chargeMap = groupBy(
    (composite: CompositeChargeInfoFragment) => composite.chargeName,
    compositeCharges
  );
  const mostRecentCharges = Object.values(chargeMap)
    .reduce((acc, compositeChargesForCharge) => {
      const mostRecentCharge = compositeChargesForCharge.sort(({ date: dateA }, { date: dateB }) =>
        dateB && dateA ? dateB.getTime() - dateA.getTime() : Infinity
      )[0];
      acc.push(mostRecentCharge);

      return acc;
    }, [] as CompositeChargeInfoFragment[])
    .filter((compositeCharge) => compositeCharge.chargeGroup === ChargeGroup.Fee);

  const components: DisplayGridProps['items'] = mostRecentCharges.flatMap((compositeCharge) => {
    const isScale = compositeCharge.rateType === RateType.Scale;
    const gridProps: DisplayGridProps['gridProps'] = {
      columnGap: 0,
      rowGap: 0,
      columns: isScale ? 2 : 1,
      rows: isScale ? 4 : 3
    };
    const chargeSchedule = compositeCharge.figuration?.chargeSchedule;

    const items = isScale
      ? gridPopoverChargeScale({
          chargeName: compositeCharge.scheduleName,
          scaleName: chargeSchedule?.scaleSchedule?.name,
          basis: chargeSchedule?.scaleSchedule?.scale?.basis,
          rateType: chargeSchedule?.scaleSchedule?.scale?.rateType,
          rows: chargeSchedule?.scaleSchedule?.scale?.rows
        })
      : gridPopoverChargeSchedule({
          chargeName: compositeCharge.scheduleName,
          rateType: compositeCharge?.rateType,
          rateValue: compositeCharge?.rateValue,
          min: chargeSchedule?.minimumChargeValue,
          max: chargeSchedule?.maximumChargeValue
        });

    return [
      {
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.InWindowPopover,
          popoverContent: {
            gridProps,
            items,
            style: { minWidth: '21rem', zIndex: 6 },
            hasClose: false
          },
          value: {
            component: {
              type: DISPLAY_FIELD_COMPONENT_TYPE.Link,
              text: compositeCharge.scheduleName
            }
          }
        },
        sx: {
          paddingLeft: 4
        }
      }
    ];
  });

  return {
    gridProps: {
      columns: (1 + components.length) as ResponsiveValue<GridColValues>
    },
    items: [
      {
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: 'Fee schedule'
        }
      },
      ...components
    ],
    style: {
      display: 'flex'
    }
  };
};

export const createChargeScheduleDisplay = (
  schedules: GetMatchingChargeSchedulesQuery['getMatchingChargeSchedules']
): DisplayGridProps['items'] => {
  if (!schedules) return [];

  return schedules.flatMap((chargeSchedule) => {
    const isScale = chargeSchedule?.rateType === RateType.Scale;
    const gridProps: DisplayGridProps['gridProps'] = {
      columnGap: 0,
      rowGap: 0,
      columns: isScale ? 2 : 1,
      rows: isScale ? 4 : 3
    };

    const items = isScale
      ? gridPopoverChargeScale({
          chargeName: chargeSchedule?.charge?.name,
          scaleName: chargeSchedule?.scaleSchedule?.name,
          basis: chargeSchedule?.scaleSchedule?.scale?.basis,
          rateType: chargeSchedule?.scaleSchedule?.scale?.rateType,
          rows: chargeSchedule?.scaleSchedule?.scale?.rows
        })
      : gridPopoverChargeSchedule({
          chargeName: chargeSchedule?.charge?.name,
          rateType: chargeSchedule?.rateType,
          rateValue: chargeSchedule?.rateValue,
          min: chargeSchedule?.minimumChargeValue,
          max: chargeSchedule?.maximumChargeValue
        });

    return [
      {
        component: {
          type: DISPLAY_FIELD_COMPONENT_TYPE.InWindowPopover,
          popoverContent: {
            gridProps,
            items,
            style: { minWidth: '21rem', zIndex: 6 },
            hasClose: false
          },
          value: {
            component: {
              type: DISPLAY_FIELD_COMPONENT_TYPE.Link,
              text: chargeSchedule?.name || ''
            }
          }
        },
        sx: {
          paddingLeft: 4
        }
      }
    ];
  });
};
