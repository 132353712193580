import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import type { ActionButtonFormOutput, ActionButtonFormInput } from './action-button.contracts';
import type { ActionButtonContractType, ActionButtonFormValues } from './action-button.form-contract';
import { actionButtonContract } from './action-button.form-contract';
import { type ActionCommandValue } from '../action-command/action-command.field';
import { COMMANDS_TO_FORMS } from '@app/actions/types/action.types';
import { COMMANDS_TO_META, type ActionCommands } from '@app/actions/commands/command.registry.types';

export const actionButtonFormBuilder = FormBuilder.create<ActionButtonFormInput, ActionButtonFormOutput>(
  'action-button-form'
)
  .contract<ActionButtonContractType>(actionButtonContract)
  .type('action-button-form')
  .sanitizer((s) =>
    s
      .input(function sanitize(incomingValues) {
        const commandId = incomingValues.commandId as ActionCommands;
        const formVals: ActionButtonFormValues = {
          ...incomingValues,
          payload: commandId
            ? {
                form: COMMANDS_TO_FORMS[commandId as ActionCommands],
                payload: incomingValues?.payload || {}
              }
            : {},
          allowsConfirmation: commandId ? COMMANDS_TO_META[commandId].allowsConfirmation : true,
          allowsLabel: commandId ? COMMANDS_TO_META[commandId].allowsLabel : true,
          allowsColor: commandId ? COMMANDS_TO_META[commandId].allowsColor : true,
          allowsSize: incomingValues.locationId?.includes('Vert')
            ? false
            : commandId
            ? COMMANDS_TO_META[commandId].allowsSize
            : true
        };
        return formVals;
      })
      .output(function sanitize(formValues) {
        return formValues as ActionButtonFormOutput;
      })
  )
  .effect(({ formApi }) => {
    let currentCommandId: ActionCommands | undefined;

    const commandIdSub = formApi.get$({ values: true, fields: ['commandId'] }).subscribe((formState) => {
      const { values } = formState;
      const { commandId, locationId } = values;

      if (commandId && commandId !== currentCommandId) {
        currentCommandId = commandId;
        const actionCommandValue: ActionCommandValue = {
          form: COMMANDS_TO_FORMS[commandId as ActionCommands],
          payload: formApi.getState().values?.payload
        };

        formApi.change('payload', actionCommandValue);
        const commandMeta = COMMANDS_TO_META[commandId as ActionCommands];
        formApi.change('allowsLabel', commandMeta.allowsLabel);
        formApi.change('allowsConfirmation', commandMeta.allowsConfirmation);
        formApi.change('allowsColor', commandMeta.allowsColor);
        formApi.change('allowsSize', locationId?.includes('Vert') ? false : commandMeta.allowsSize);
      }
    });

    return () => {
      commandIdSub.unsubscribe();
    };
  })
  .change((event, _ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.MOUNT:
        break;
      case FORM_EVENT_TYPE.UNMOUNT:
        break;
      case FORM_EVENT_TYPE.SUBMIT:
        break;
      case FORM_EVENT_TYPE.VALUES_CHANGED:
        break;
    }
  });

export default actionButtonFormBuilder;

export type ActionButtonFormBuilderType = typeof actionButtonFormBuilder;
