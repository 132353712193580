import { FormContract, type InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import { type MontageBumpPriceFormOutput } from './bump-price.form-common';
import * as fields from './bump-price.form-fields';
import { FieldDefinition } from '@oms/frontend-foundation';

export const montageBumpPriceContract = FormContract.create<MontageBumpPriceFormOutput>()
  .fields({
    bumpPrice: fields.bumpPrice
  })
  .schema((f) => {
    return {
      fields: [
        FieldDefinition.box('bump-price-container', [f.bumpPrice], {
          sx: {
            display: 'flex',
            flexDirection: 'row'
          }
        })
      ]
    };
  })
  .template('simple', { showSubmitButton: false });

export type MontageBumpPriceContractType = typeof montageBumpPriceContract;

export type MontageBumpPriceValues = InferFormValuesFromFormContract<MontageBumpPriceContractType>;
