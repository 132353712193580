import type { DepartmentTypes, DeskTypes, UserFacet } from '@oms/generated/frontend';
import { cleanMaybe, compactMap, UUID } from '@oms/shared/util';
import type { Identifiable, Optional, ValuedAndLabeled } from '@oms/shared/util-types';
import UserDefaults from './user-defaults.class';
import {
  type AnyBaseUserObject,
  type BaseGroupObject,
  type BaseUserObject,
  type ExtendedGroupObject,
  type ExtendedUserAccessObject,
  type ExtendedUserObject,
  isExtendedUserObject,
  type UserAccount,
  type UserAggregationUnit,
  type UserCountry,
  type UserDisplayInfo,
  type UserExecutionVenue,
  type UserGroup,
  type UserOrderTag,
  type UserPositionAccess,
  type UserRole,
  type UserTradingEntity
} from './types';
import { standardizeBaseUserObject } from './util';

export class User
  implements BaseUserObject, ExtendedUserObject, Identifiable, ValuedAndLabeled<BaseUserObject>
{
  protected _user: BaseUserObject;

  #defaults?: UserDefaults;
  #access?: ExtendedUserAccessObject;
  #memoizedManager?: ExtendedUserObject;
  #id: string;

  // 🏗️ Constructor ------------------------------------------------------- /

  public constructor(user: BaseUserObject) {
    this._user = user;
    this.#id = this._user.id ?? UUID();
  }

  // Conversion ------------------------------------------------------- /

  public get label(): string {
    const { id, name, username, email } = this;
    return name ?? username ?? email ?? id;
  }

  public get value(): BaseUserObject {
    return this._user;
  }

  public toObject(): BaseUserObject {
    return this.value;
  }

  public toExtendedObject(): ExtendedUserObject {
    return {
      __typename: this.__typename,
      id: this.id,
      enabled: this.enabled,
      username: this.username,
      name: this.name,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      emailVerified: this.emailVerified,
      phoneNumber: this.phoneNumber,
      avatar: this.avatar,
      team: this.team,
      group: this.group,
      manager: this.manager,
      userRoles: this.userRoles,
      realmRoles: this.realmRoles,
      createdTimestamp: this.createdTimestamp,
      defaults: this.defaults?.toExtendedObject(),
      access: this.access,
      label: this.label,
      displayInfo: this.displayInfo,
      isExtendedUserObject: this.isExtendedUserObject,
      _serializedOriginalObject: this._serializedOriginalObject,
      facets: this.facets
    };
  }

  // 😐 Base ------------------------------------------------------- /

  public get facets(): UserFacet[] {
    return compactMap(cleanMaybe(this._user.facets, []), (facet) => facet);
  }

  public get __typename() {
    return cleanMaybe(this._user.__typename, 'User');
  }

  public get id(): string {
    return this.#id;
  }

  public get enabled(): boolean {
    return this._user.enabled ?? false;
  }

  public get username(): Optional<string> {
    return cleanMaybe(this._user.username);
  }

  public get name(): Optional<string> {
    return User.buildNameFrom(this);
  }

  public get firstName(): Optional<string> {
    return cleanMaybe(this._user.firstName);
  }

  public get lastName(): Optional<string> {
    return cleanMaybe(this._user.lastName);
  }

  public get email(): Optional<string> {
    return cleanMaybe(this._user.email);
  }

  public get emailVerified(): boolean {
    return this._user.emailVerified ?? false;
  }

  public get phoneNumber(): Optional<string> {
    return cleanMaybe(this._user.phoneNumber);
  }

  public get avatar(): Optional<string> {
    return cleanMaybe(this._user.avatar);
  }

  public get group(): Optional<UserGroup> {
    return cleanMaybe(this._user.group);
  }

  public get manager(): Optional<ExtendedUserObject> {
    const userManager = cleanMaybe(this._user.manager);
    if (!userManager) return;
    if (this.#memoizedManager && this.#memoizedManager.id === userManager.id) {
      return this.#memoizedManager;
    }
    this.#memoizedManager = User.extend(userManager);
    return this.#memoizedManager;
  }

  public get realmRoles(): UserRole[] {
    return compactMap(cleanMaybe(this._user.realmRoles, []), (group) => group);
  }

  public get createdTimestamp(): Optional<number> {
    return cleanMaybe(this._user.createdTimestamp);
  }

  public get defaults(): Optional<UserDefaults> {
    if (typeof this.#defaults === 'object') return this.#defaults;
    const userDefaults = cleanMaybe(this._user.defaults);
    if (!userDefaults) return;
    const defaults = new UserDefaults(userDefaults);
    this.#defaults = defaults;
    return defaults;
  }

  public get access(): Optional<ExtendedUserAccessObject> {
    if (this.#access) return this.#access;
    const userAccess = cleanMaybe(this._user.access);
    if (!userAccess) return;
    const access: ExtendedUserAccessObject = {
      id: userAccess.id,
      groupAccess: compactMap(userAccess.groupAccess ?? [], (group) => cleanMaybe(group)),
      executionVenues: compactMap(userAccess.executionVenues ?? [], (venue) => cleanMaybe(venue)),
      positionsAccess: compactMap(userAccess.positionAccess ?? [], (positionAccess) =>
        cleanMaybe(positionAccess)
      )
    };
    this.#access = access;
    return access;
  }

  // 🤯 Extended ------------------------------------------------------- /

  public get userRoles(): UserRole[] {
    return this.realmRoles;
  }

  public get team(): Optional<UserGroup> {
    return cleanMaybe(this._user.group);
  }

  public get aggregationUnit(): Optional<UserAggregationUnit> {
    return this.defaults?.aggregationUnit;
  }

  public get receivingDeskType(): Optional<DeskTypes> {
    return this.defaults?.receivingDeskType;
  }

  public get departmentType(): Optional<DepartmentTypes> {
    return this.defaults?.departmentType;
  }

  public get tradingEntity(): Optional<UserTradingEntity> {
    return this.defaults?.tradingEntity;
  }

  public get location(): Optional<UserCountry> {
    return this.defaults?.country;
  }

  public get firmAccount(): Optional<UserAccount> {
    return this.defaults?.firmAccount;
  }

  public get intermediaryAccount(): Optional<UserAccount> {
    return this.defaults?.intermediaryAccount;
  }

  public get orderTags(): UserOrderTag[] {
    return this.defaults?.orderTags ?? [];
  }

  public get teamAccess(): UserGroup[] {
    return this.access?.groupAccess ?? [];
  }

  public get groupAccess(): UserGroup[] {
    return this.access?.groupAccess ?? [];
  }

  public get executionVenueAccess(): UserExecutionVenue[] {
    return this.access?.executionVenues ?? [];
  }

  public get positionsAccess(): UserPositionAccess[] {
    return this.access?.positionsAccess ?? [];
  }

  public get displayInfo(): UserDisplayInfo {
    const { id, label, avatar } = this;
    return {
      id,
      label,
      avatar
    };
  }

  public get isExtendedUserObject(): true {
    return true;
  }

  public get _serializedOriginalObject(): string {
    return JSON.stringify(this.value);
  }

  // 📢 Public ------------------------------------------------------- /

  public mutate(transform: (current: BaseUserObject) => BaseUserObject): BaseUserObject {
    const original = JSON.parse(this._serializedOriginalObject) as BaseUserObject;
    this._user = transform(this._user);
    return original;
  }

  // 🧊 Static ------------------------------------------------------- /

  public static fromPartial(partialUser?: Partial<AnyBaseUserObject>): User {
    if (partialUser && partialUser instanceof User) return partialUser;
    const basePartialUser = standardizeBaseUserObject(partialUser ?? {});
    if (isExtendedUserObject(basePartialUser)) {
      const { _serializedOriginalObject } = basePartialUser;
      try {
        const deserializedBaseObject = JSON.parse(_serializedOriginalObject) as BaseUserObject;
        return new User(deserializedBaseObject);
      } catch (_e) {
        return new User(basePartialUser);
      }
    }
    const { id = '', ...rest } = basePartialUser ?? {};
    const user: BaseUserObject = { id, ...rest };
    return new User(user);
  }

  public static extend(baseUser?: Partial<AnyBaseUserObject>): ExtendedUserObject {
    if (isExtendedUserObject(baseUser)) return baseUser;
    const baseUserObject = standardizeBaseUserObject(baseUser ?? {});
    if (isExtendedUserObject(baseUserObject)) return baseUserObject;
    return User.fromPartial(baseUserObject).toExtendedObject();
  }

  public static extendGroup(group: Partial<BaseGroupObject>) {
    const groupAsUser: ExtendedGroupObject = {
      __typename: 'GroupRepresentation',
      id: group?.id ?? '',
      enabled: true,
      label: group?.name ?? '',
      name: group?.name ?? ''
    };
    return groupAsUser;
  }

  public static displayInfoFrom(user: ExtendedUserObject): UserDisplayInfo;
  public static displayInfoFrom(users: ExtendedUserObject[]): UserDisplayInfo;
  public static displayInfoFrom(baseUser: AnyBaseUserObject): UserDisplayInfo;
  public static displayInfoFrom(baseUsers: AnyBaseUserObject[]): UserDisplayInfo;
  public static displayInfoFrom(input: AnyBaseUserObject | AnyBaseUserObject[]): UserDisplayInfo {
    if (input instanceof Array) {
      const extendedUsers = input.map((user) => (isExtendedUserObject(user) ? user : User.extend(user)));
      extendedUsers.forEach((user) => this.formatLabelIfGroup(user));
      const count = extendedUsers.length;
      const [first] = extendedUsers;
      if (count < 1 || typeof first !== 'object')
        throw new Error('Must supply at least one user, but none were provided.');
      this.formatLabelIfGroup(first);
      if (count === 1) return first.displayInfo;
      const { id, firstName, avatar } = first;
      return {
        id,
        label: `${firstName} and ${count - 1} more`,
        avatar,
        children: extendedUsers.map(({ displayInfo }) => displayInfo)
      };
    }
    const extendedUser = isExtendedUserObject(input) ? input : User.extend(input);
    this.formatLabelIfGroup(extendedUser);
    return extendedUser.displayInfo;
  }

  public static buildNameFrom(user?: AnyBaseUserObject): Optional<string> {
    if (!user) return;
    const { firstName, lastName } = user;
    if (!firstName && !lastName) return;
    return [firstName, lastName].filter(Boolean).join(' ');
  }

  public static deserialize(serialized: string): Optional<User> {
    try {
      const extendedObject = JSON.parse(serialized) as Partial<BaseUserObject> | undefined;
      if (!isExtendedUserObject(extendedObject)) return;
      return User.fromPartial(extendedObject);
    } catch (_e) {
      return undefined;
    }
  }

  /**
   * Due to the Teams coverage requirement, `obj` may sometimes be a group with `undefined` for the `firstName` and
   * `displayInfo.label`. This function will set them.
   * @param obj
   * @private
   */
  private static formatLabelIfGroup(obj: ExtendedUserObject) {
    if (obj?.group?.name && obj?.group?.id) {
      obj.firstName = obj.group.name;
      obj.displayInfo.id = obj.group.id;
      obj.displayInfo.label = obj.group.name;
      obj.enabled = true;
    }
  }
}

export default User;
