import { type CustomMenuItemProps } from '@ag-grid-community/react';
import {
  type MontageItemType,
  type MontageItem
} from '@app/data-access/services/trading/montage/montage.types';
import { MontageContextMenuItemTitle } from './title';
import { optionStyle, ulStyle, wrapperStyle } from './montage-contextmenu.css';
import { useMontageContextMenuItem } from '../hooks/useMontageContextMenuItem.hook';
import { Option } from './montage-contextmenu-option';
import { OrderType } from '@oms/generated/frontend';
import { openRouteOrder } from '@app/generated/sdk';
import { container } from 'tsyringe';
import { TradingOrdersService } from '@app/data-access/services/trading/trading-orders/trading-orders.service';

type MontageCustomMenuItemProps = CustomMenuItemProps & {
  type: MontageItemType;
};

export const MontageContextMenuItem = ({ name, type }: MontageCustomMenuItemProps) => {
  const { filteredOrders } = useMontageContextMenuItem({ type });

  if (name !== 'Modify' && name !== 'Cancel') {
    throw new Error('Invalid context menu item name');
  }

  return (
    <div className={wrapperStyle}>
      <MontageContextMenuItemTitle name={name} />

      <ul className={ulStyle}>
        {filteredOrders?.length
          ? filteredOrders.map((order) => <ModifyOrCancelOption key={order.id} name={name} order={order} />)
          : '-'}
      </ul>
    </div>
  );
};

async function handleOptionClick({ orderId, name }: { orderId: string; name: 'Modify' | 'Cancel' }) {
  if (name === 'Modify') {
    await openRouteOrder(undefined, {
      title: 'Modify Order',
      form: {
        formId: `modify-trading-order-${orderId}`,
        input: {
          tradingOrderId: orderId
        }
      }
    });
  }

  if (name === 'Cancel') {
    const tradingOrderService = container.resolve(TradingOrdersService);
    await tradingOrderService.cancel(orderId);
  }
}

const ModifyOrCancelOption = ({ order, name }: { order: MontageItem; name: 'Modify' | 'Cancel' }) => {
  const { size, orderType, type } = order;

  const formattedOrderType = type[0]?.toUpperCase(); // Bid -> B, Ask -> A

  const formattedPrice = orderType === OrderType.Limit ? order.limitPrice : 'MKT';

  return (
    <Option className={optionStyle} onClick={() => void handleOptionClick({ orderId: order.id, name })}>
      {formattedOrderType} {size} @ {formattedPrice}
    </Option>
  );
};
