import type { TradingOrderStatus } from '@oms/generated/frontend';
import type { Maybe } from '@oms/shared/util-types';
import { tradingOrderStatusMapping } from './order-enums';

export const mapTradingOrderStatus = (
  value?: Maybe<TradingOrderStatus>,
  defaultVal = value || ''
): string => {
  return value && (tradingOrderStatusMapping[value as never] as string)
    ? (tradingOrderStatusMapping[value as never] as string)
    : defaultVal;
};
