import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { ComboBoxItem, ComboBoxItemUnion } from '../../../combo-box/combo-box.types';
import type { AutocompleteProps } from '../../../popovers/autocomplete/popover.autocomplete';
import { type Subscription } from 'rxjs';
import { type IAdvancedMultiSelectField } from './advanced-multi-select.types';
import { type IAdvancedSelectField } from './advanced-select.types';
import {
  type AdvancedSelectQueryFn,
  type AdvancedSelectQueryPayload,
  type AdvancedSelectQueryReturnWatchAll
} from './query-mapper/query-mapper.types';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { useWorkspaceContainer } from '../../../workspace/workspace.services';
import { type FieldProps } from '../../types';
import { useFieldApi } from '../../helpers';
import { isPromiseLike } from '@oms/shared/util';
import { useDeepCompareEffect } from 'react-use';
import { useWorkspaceFormMappers } from '../../common/form.workspace.hook';
import { useDeepMemo } from '@oms/shared-frontend/ui-design-system';

const defaultProps: AutocompleteProps = {
  options: [],
  value: [],
  name: 'autocomplete',
  inputProps: {
    style: {
      maxWidth: 400
    }
  },
  isMultiSelect: false,
  filterStrategy: 'default',
  onItemClick: undefined
};

export const useAdvancedSelectQuery = (
  props: FieldProps<IAdvancedSelectField<any> | IAdvancedMultiSelectField<any>>,
  isMultiSelect = false
) => {
  const form = useFormApi();
  const field = useFieldApi<
    IAdvancedSelectField | IAdvancedMultiSelectField,
    ComboBoxItem<any> | ComboBoxItem<any>[]
  >(props);
  const {
    meta,
    label,
    input: { name, onBlur, onChange, onFocus, value },
    isLoading: _isLoading,
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    isFeatureField,
    isPrimaryField,
    requiredFieldIndicatorStyle,
    inputProps = {},
    options: _options = [],
    style = {},
    sx = {},
    wrapperSx = {},
    query: _query,
    autoSelectItemOnTab,
    isTypeahead,
    autoSizeWidth,
    autoSizeWidthToTrigger = true,
    width,
    minWidth,
    minHeight,
    allowAnyValue = false,
    formatValue,
    fallbackValue,
    autoFocus,
    filterStrategy,
    optionsOnFocus: _optionsOnFocus,
    queryProps = {},
    onItemClick,
    hideFormControls
  } = field;
  const mappers = useWorkspaceFormMappers();
  const container = useWorkspaceContainer();
  const subscription = useRef<Subscription | undefined>();
  const queryReturn: ReturnType<AdvancedSelectQueryFn> | undefined = useDeepMemo(() => {
    if (!_query) {
      return undefined;
    }
    if (typeof _query === 'function') {
      return _query(container);
    }

    const queryFromMapper = mappers.advancedSelectQueryMapper[_query];
    if (!queryFromMapper) {
      throw new Error(`Advanced select query with key ${_query} not found in workspace meta.`);
    }
    return queryFromMapper(container);
  }, [_query, container, mappers]);

  const [options, setOptions] = useState<ComboBoxItemUnion<any>[]>([]);
  const [isLoading, setIsLoading] = useState(_isLoading !== undefined ? _isLoading : false);

  // Combine field/input props
  const fieldProps = useDeepMemo(
    () => ({
      name,
      onBlur,
      onFocus,
      hidden: isVisible === false,
      style: {
        ...style,
        ...(inputProps.style || {}),
        height: isPrimaryField ? 'var(--controls-heights-md)' : undefined
      },
      sx,
      isReadOnly,
      isDisabled: !!forceIsDisabled || !!_isDisabled
    }),
    [
      name,
      onBlur,
      onFocus,
      isVisible,
      style,
      inputProps.style,
      sx,
      isReadOnly,
      forceIsDisabled,
      _isDisabled,
      isPrimaryField
    ]
  );

  // Combine field wrapper props
  const fieldWrapperProps = useDeepMemo(
    () => ({
      meta,
      label,
      isReadOnly,
      isRequired,
      // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
      isDisabled: !!forceIsDisabled || !!_isDisabled,
      isVisible,
      isInvalid,
      helperText,
      isFeatureField,
      isPrimaryField,
      requiredFieldIndicatorStyle,
      hideFormControls,
      sx: wrapperSx
    }),
    [
      meta,
      label,
      isReadOnly,
      isRequired,
      forceIsDisabled,
      _isDisabled,
      isVisible,
      isInvalid,
      helperText,
      isFeatureField,
      isPrimaryField,
      requiredFieldIndicatorStyle,
      wrapperSx
    ]
  );

  const handleOnChange = useCallback(
    (values: ComboBoxItem<any>[]) => {
      if (isMultiSelect) {
        onChange(values?.length ? values : undefined);
      } else {
        onChange(values?.[0] || undefined);
      }
    },
    [onChange, isMultiSelect]
  );

  // Unsubscribe on unmount or queryReturn change
  useEffect(() => {
    return () => {
      if (queryReturn) {
        queryReturn.unsubscribe?.();
      }
    };
  }, [queryReturn]);

  // Unsubscribe on unmount
  useEffect(() => {
    return () => {
      if (subscription.current) {
        subscription.current?.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (options?.length && !queryReturn) {
      subscription.current = undefined;
      setOptions([]);
    }
  }, [options, queryReturn]);

  // Preload query on mount if preLoad is true
  useDeepCompareEffect(() => {
    if (
      queryReturn &&
      queryReturn.type === 'watchAll' &&
      (queryReturn as AdvancedSelectQueryReturnWatchAll).preLoad &&
      subscription.current === undefined
    ) {
      subscription.current = queryReturn
        .query({
          field,
          form,
          queryProps
        })
        .subscribe((response) => {
          setIsLoading(Boolean(response.isFetching));
          setOptions(response.results as ComboBoxItemUnion<any>[]);
        });
    }
  }, [queryReturn, queryProps]);

  const typeaheadCallback = useCallback((response: AdvancedSelectQueryPayload<any>) => {
    setIsLoading(Boolean(response.isFetching));
    setOptions(response.results as ComboBoxItemUnion<any>[]);
  }, []);

  const debouncedTypeaheadCallback = useDebouncedCallback(
    (response: AdvancedSelectQueryPayload<any>) => {
      typeaheadCallback(response);
    },
    [],
    queryReturn && queryReturn.type === 'typeahead' && queryReturn.debounceMs ? queryReturn.debounceMs : 100
  );

  useEffect(() => {
    return () => {
      // Run unsubscribe on unmount/queryReturn change
      queryReturn?.unsubscribe?.();

      if (queryReturn && queryReturn.type === 'typeahead' && queryReturn.debounceMs) {
        // Cancel debounced callback on unmount/queryReturn change
        debouncedTypeaheadCallback.cancel();
      }
    };
  }, [debouncedTypeaheadCallback, queryReturn]);

  const handleOnInputValueChange = useCallback(
    (val: string) => {
      if (queryReturn && queryReturn.type === 'typeahead') {
        const useInternalDebounce = queryReturn.debounceMs !== undefined;
        if (useInternalDebounce) {
          debouncedTypeaheadCallback.cancel();
          queryReturn?.unsubscribe?.();
        }
        const queryRes = queryReturn.query({
          field,
          form,
          inputValue: val,
          callback: useInternalDebounce ? debouncedTypeaheadCallback : typeaheadCallback,
          queryProps
        });

        if (isPromiseLike(queryRes)) {
          queryRes.catch(console.error);
        }
      }
    },
    [queryReturn, field, form, debouncedTypeaheadCallback, typeaheadCallback, queryProps]
  );

  const optionsOnFocus = useDeepMemo(() => {
    return _optionsOnFocus !== undefined
      ? _optionsOnFocus
      : queryReturn?.type === 'watchAll' || _optionsOnFocus || _options.length > 0;
  }, [_options.length, _optionsOnFocus, queryReturn?.type]);

  const handleOnFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onFocus) {
        onFocus(e);
      }
      if (queryReturn && queryReturn.type === 'watchAll' && subscription.current === undefined) {
        subscription.current = queryReturn
          .query({
            field,
            form,
            queryProps
          })
          .subscribe((response) => {
            setIsLoading(Boolean(response.isFetching));
            setOptions(response.results as ComboBoxItemUnion<any>[]);
          });
      }

      if (queryReturn && queryReturn.type === 'typeahead' && optionsOnFocus) {
        handleOnInputValueChange(e.target.value);
      }
    },
    [field, form, handleOnInputValueChange, onFocus, optionsOnFocus, queryProps, queryReturn]
  );

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onBlur) {
        onBlur(e);
      }

      const shouldUnsubscribe = (queryReturn as AdvancedSelectQueryReturnWatchAll)?.preLoad !== true;

      if (queryReturn && shouldUnsubscribe) {
        queryReturn.unsubscribe?.();
      }

      if (subscription.current && shouldUnsubscribe) {
        subscription.current?.unsubscribe();
        subscription.current = undefined;
      }
    },
    [onBlur, queryReturn]
  );

  const sanitizedValue = useMemo(() => {
    return Array.isArray(value) ? value : value ? [value] : [];
  }, [value]);

  const autoCompleteProps: AutocompleteProps = useMemo(
    () => ({
      ...defaultProps,
      name,
      options: _options.length > 0 ? _options : options,
      isMultiSelect,
      isLoading: _isLoading !== undefined ? _isLoading : isLoading,
      onChange: handleOnChange,
      onInputValueChange: handleOnInputValueChange,
      filterStrategy:
        filterStrategy !== undefined
          ? filterStrategy
          : queryReturn?.type === 'typeahead'
          ? 'none'
          : 'default',
      value: sanitizedValue,
      inputProps: {
        ...inputProps,
        ...fieldProps,
        autoFocus,
        onBlur: handleOnBlur,
        onFocus: handleOnFocus,
        variant: isPrimaryField || isFeatureField ? 'primary' : 'default'
      },
      isTypeahead: isTypeahead !== undefined ? isTypeahead : queryReturn?.type === 'typeahead',
      optionsOnFocus: optionsOnFocus,
      autoSelectItemOnTab:
        autoSelectItemOnTab !== undefined ? autoSelectItemOnTab : isMultiSelect ? false : true,
      autoSizeWidth,
      autoSizeWidthToTrigger,
      width,
      minWidth,
      minHeight,
      allowAnyValue,
      formatValue,
      fallbackValue,
      onItemClick
    }),
    [
      name,
      _options,
      options,
      isMultiSelect,
      isLoading,
      _isLoading,
      handleOnChange,
      handleOnInputValueChange,
      queryReturn?.type,
      sanitizedValue,
      inputProps,
      fieldProps,
      autoFocus,
      handleOnBlur,
      handleOnFocus,
      isPrimaryField,
      isFeatureField,
      isTypeahead,
      optionsOnFocus,
      autoSelectItemOnTab,
      autoSizeWidth,
      autoSizeWidthToTrigger,
      width,
      minWidth,
      minHeight,
      allowAnyValue,
      formatValue,
      fallbackValue,
      filterStrategy,
      onItemClick
    ]
  );

  return useMemo(() => ({ fieldWrapperProps, autoCompleteProps }), [autoCompleteProps, fieldWrapperProps]);
};
