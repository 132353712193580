import type { AnyRecord } from '../../common/type.helpers';
import type { AnyFieldDefinition } from './form-field-definition.class';
import {
  type FormContract,
  type GetFormContractFieldDefs,
  type InferFormValuesFromFormContract
} from './form-contract.class';
import { FormBuilderSanitizer, type FormBuilderSanitizerDefinition } from './form-builder-sanitizer.class';
import type { FormBuilderEvent } from './form-builder.events';
import { type FormBuilderDefinitionBase } from './form-builder.common.types';
import type { Prettify } from '@oms/shared/util-types';
import type { FormRendererEventBase } from './form-builder.events.renderer';
import type {
  FormBuilderPartialTemplateProps,
  FormBuilderTemplateMapperKey
} from './components/templates/form-builder.template-mapper';
import { type DependencyContainer } from 'tsyringe';
import { UUID } from '@oms/shared/util';
import { type GQLResult } from '../../graphql/graphql-result';
import { type ExtendedAllGQLResponseErrors } from '../../graphql/graphql-result';
import { type EnhancedFormOptions } from '../types';
import type { FoundationWorkspace } from '../../workspace/workspace.common';

export type FormBuilderChangeContext<
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord
> = {
  notify: (event: FormRendererEventBase<TOutputContract, TFormFieldValues>) => void;
  container: DependencyContainer;
  workspace: FoundationWorkspace;
};

export type FormBuilderEffectContext<TFormFieldValues extends AnyRecord> = {
  formApi: EnhancedFormOptions<TFormFieldValues>;
  container: DependencyContainer;
  workspace: FoundationWorkspace;
};

export type FormBuilderFormTearDown = () => void;

export type FormBuilderChangeFnResult<TFormFieldValues extends AnyRecord> =
  | void
  | { [k in keyof TFormFieldValues]: string }
  | GQLResult<any, ExtendedAllGQLResponseErrors>
  | undefined;

export type FormBuilderChangeFn<TOutputContract extends AnyRecord, TFormFieldValues extends AnyRecord> = (
  event: FormBuilderEvent<TOutputContract, TFormFieldValues>,
  ctx: Prettify<FormBuilderChangeContext<TOutputContract, TFormFieldValues>>
) => FormBuilderChangeFnResult<TFormFieldValues> | Promise<FormBuilderChangeFnResult<TFormFieldValues>>;

export type FormBuilderEffectFn<TFormFieldValues extends AnyRecord> = (
  ctx: FormBuilderEffectContext<TFormFieldValues>
) => Promise<FormBuilderFormTearDown> | FormBuilderFormTearDown;

export type FormBuilderDefinition<
  TInputContract extends AnyRecord,
  TOutputContract extends AnyRecord,
  TFormContract extends FormContract<TOutputContract, Record<string, AnyFieldDefinition>>,
  TFormFieldValues extends AnyRecord
> = FormBuilderDefinitionBase & {
  formBuilderId: string;
  sanitizerBuilder?: FormBuilderSanitizer<TInputContract, TOutputContract, TFormFieldValues>;
  sanitizer: FormBuilderSanitizerDefinition<TInputContract, TOutputContract, TFormFieldValues>;
  effect?: FormBuilderEffectFn<TFormFieldValues>;
  change: FormBuilderChangeFn<TOutputContract, TFormFieldValues>;
  contract: TFormContract;
  template: FormBuilderTemplateMapperKey | undefined;
  templateProps: FormBuilderPartialTemplateProps[keyof FormBuilderPartialTemplateProps] | undefined;
};

export type AnyFormBuilderDefinition = FormBuilderDefinition<any, any, any, any>;

/**
 * Form Builder
 */
export class FormBuilder<
  TInputContract extends AnyRecord,
  TOutputContract extends AnyRecord,
  TFormContract extends FormContract<TOutputContract, Record<string, AnyFieldDefinition>>,
  TFormFieldDefinitions extends Record<string, AnyFieldDefinition> = AnyRecord,
  TFormFieldValues extends AnyRecord = AnyRecord
> {
  private constructor(
    private _formBuilderDef: Partial<
      FormBuilderDefinition<TInputContract, TOutputContract, TFormContract, TFormFieldValues>
    >
  ) {}

  static create<
    TInputContract extends AnyRecord,
    TOutputContract extends AnyRecord,
    TFormContract extends FormContract<TOutputContract, Record<string, AnyFieldDefinition>> = FormContract<
      TOutputContract,
      Record<string, AnyFieldDefinition>
    >
  >(name: string) {
    return new FormBuilder<TInputContract, TOutputContract, TFormContract>({ formBuilderId: name });
  }

  static createFromDefinition<
    TInputContract extends AnyRecord,
    TOutputContract extends AnyRecord,
    TFormContract extends FormContract<TOutputContract, Record<string, AnyFieldDefinition>>,
    TFormFieldDefinitions extends Record<string, AnyFieldDefinition>,
    TFormFieldValues extends AnyRecord
  >(
    name: string,
    def: FormBuilderDefinition<TInputContract, TOutputContract, TFormContract, TFormFieldValues>
  ) {
    return new FormBuilder<
      TInputContract,
      TOutputContract,
      TFormContract,
      TFormFieldDefinitions,
      TFormFieldValues
    >({ ...def, formBuilderId: name });
  }

  public contract<
    T extends TFormContract,
    TFormFieldDefinitions extends Record<string, AnyFieldDefinition> = GetFormContractFieldDefs<T>,
    TFormFieldValues extends AnyRecord = InferFormValuesFromFormContract<T>
  >(formContract: T) {
    const contractDef = formContract.build();
    return new FormBuilder<TInputContract, TOutputContract, T, TFormFieldDefinitions, TFormFieldValues>({
      ...this._formBuilderDef,
      contract: formContract,
      template: contractDef.template ?? this._formBuilderDef.template,
      templateProps: contractDef.templateProps ?? this._formBuilderDef.templateProps
    } as Partial<FormBuilderDefinition<TInputContract, TOutputContract, T, TFormFieldValues>>);
  }

  public sanitizer(
    cb: (
      sanitizer: FormBuilderSanitizer<TInputContract, TOutputContract, TFormFieldValues>
    ) => FormBuilderSanitizer<TInputContract, TOutputContract, TFormFieldValues>
  ) {
    this._formBuilderDef.sanitizerBuilder = cb(
      new FormBuilderSanitizer<TInputContract, TOutputContract, TFormFieldValues>()
    );
    return this;
  }

  /**
   * Run custom logic and have access to the formApi (Try to avoid using this and use the change method instead)
   * Note: This will ONLY work if your form-builder is added to the formBuilderMapper (which is done automatically if you are using `appgen`)
   *
   * @param cb - FormBuilderEffectFn<TFormFieldValues>
   * @returns FormBuilderEffectFn<TFormFieldValues>
   */
  public effect(cb: FormBuilderEffectFn<TFormFieldValues>) {
    if (this._formBuilderDef.effect) {
      console.warn('Effect already defined, overwriting');
    }
    this._formBuilderDef.effect = cb;
    return this;
  }

  public change(cb: FormBuilderChangeFn<TOutputContract, TFormFieldValues>) {
    if (this._formBuilderDef.change) {
      console.warn('Change already defined, overwriting');
    }
    this._formBuilderDef.change = cb;
    return this;
  }

  public type(formType: string) {
    this._formBuilderDef.formType = formType;
    return this;
  }

  public build(): FormBuilderDefinition<TInputContract, TOutputContract, TFormContract, TFormFieldValues> {
    if (!this._formBuilderDef.contract) {
      throw new Error('Missing sanitizer');
    }
    const { schema } = this._formBuilderDef.contract.build();

    if (!this._formBuilderDef.sanitizerBuilder) {
      throw new Error('Missing sanitizer');
    }

    if (!this._formBuilderDef.change) {
      throw new Error('Missing change');
    }

    return {
      schema,
      sanitizer: this._formBuilderDef.sanitizerBuilder.build(),
      sanitizerBuilder: this._formBuilderDef.sanitizerBuilder,
      change: this._formBuilderDef.change,
      effect: this._formBuilderDef.effect,
      contract: this._formBuilderDef.contract,
      template: this._formBuilderDef.template,
      templateProps: this._formBuilderDef.templateProps,
      formBuilderId: this._formBuilderDef.formBuilderId || UUID(),
      formType: this._formBuilderDef.formType
    };
  }

  public clone() {
    return new FormBuilder<
      TInputContract,
      TOutputContract,
      TFormContract,
      TFormFieldDefinitions,
      TFormFieldValues
    >({
      ...this._formBuilderDef
    });
  }
}

/**
 * Infer types from FormBuilder
 */
export type AnyFormBuilder = FormBuilder<any, any, any, any, any>;

type InferInputContractFromFormBuilder<T extends AnyFormBuilder> = T extends FormBuilder<
  infer TInputContract,
  any,
  any,
  any,
  any
>
  ? TInputContract
  : never;

type InferOutputContractFromFormBuilder<T extends AnyFormBuilder> = T extends FormBuilder<
  any,
  infer TOutputContract,
  any,
  any,
  any
>
  ? TOutputContract
  : never;

type InferFormContractFromFormBuilder<T extends AnyFormBuilder> = T extends FormBuilder<
  any,
  any,
  infer TFormContract,
  any,
  any
>
  ? TFormContract
  : never;

type InferFormFieldDefinitionsFromFormBuilder<T extends AnyFormBuilder> = T extends FormBuilder<
  any,
  any,
  any,
  infer TFormFieldDefinitions,
  any
>
  ? TFormFieldDefinitions
  : never;

type InferFormFieldValuesFromFormBuilder<T extends AnyFormBuilder> = T extends FormBuilder<
  any,
  any,
  any,
  any,
  infer TFormFieldValues
>
  ? TFormFieldValues
  : never;

export type InferInfoFromFormBuilder<T extends AnyFormBuilder> = {
  inputContract: InferInputContractFromFormBuilder<T>;
  outputContract: InferOutputContractFromFormBuilder<T>;
  formContract: InferFormContractFromFormBuilder<T>;
  fieldDefinitions: InferFormFieldDefinitionsFromFormBuilder<T>;
  fieldValues: InferFormFieldValuesFromFormBuilder<T>;
};
