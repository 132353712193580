export enum CellBadgeClassEnum {
  Capital = 'ag-cell-badge--capital'
}

export enum RowStatusClassEnum {
  NoAction = 'ag-row-order-status ag-row-order-status--no-action',
  PendingAction = 'ag-row-order-status ag-row-order-status--pending-action',
  TakeAction = 'ag-row-order-status ag-row-order-status--take-action',
  Disabled = 'ag-row-order-status ag-row-order-status--disabled',
  Error = 'ag-row-order-status ag-row-order-status--error',
  Warn = 'ag-row-order-status ag-row-order-status--warn'
}
