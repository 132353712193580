import { type CheckboxListControl } from '@valstro/fixatdl-core';
import { type AtdlFieldPartial, type ControlTransformer, getListItems } from './control.transformer.types';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const checkboxListControl: ControlTransformer<CheckboxListControl> = (
  checkboxListControl,
  _query,
  formValues
) => {
  const component: AtdlFieldPartial = {
    component: fixatdlFormComponentTypes.CHECKBOX_LIST,
    name: checkboxListControl.id,
    initialValue: AtdlFormats.coerceArray(formValues[checkboxListControl.id], { format: 'array' }) as any[],
    label: checkboxListControl.label,
    options: getListItems(checkboxListControl.listItems),
    orientation: checkboxListControl.orientation
  };

  return component;
};
