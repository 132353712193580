import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type { FieldContract, INumberFormatField, ModifiedFieldForOptions } from '@oms/frontend-foundation';
import type { AnyRecord } from '@valstro/workspace';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import { SET_PRICE_FROM_VALUE } from '@app/widgets/user/user-preferences/order-settings/actions/set-price-from-value.action';
import { makeCommonRequiredProps } from '../shared-field-props';

// ------------------------------------------------------------------------ /

const requiredOptions: Parameters<typeof makeCommonRequiredProps>[0] = {};

const commonRequiredPropsAnd = (...additionalValidators: Validator[]) =>
  makeCommonRequiredProps({ ...requiredOptions, additionalValidators });

// ------------------------------------------------------------------------ /

export const createPriceFrom = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<INumberFormatField>
) =>
  fc.field(key, 'number-format').options({
    label: 'Price from',
    format: 'decimal',
    isDisabled: true,
    isReadOnly: true,
    actions: {
      resolveProps: [SET_PRICE_FROM_VALUE]
    },
    ...overrides
  });

export const createPriceTo = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<INumberFormatField>
) =>
  fc.field(key, 'number-format').options({
    label: 'Price to',
    format: 'decimal',
    ...overrides
  });

export const createOrderSize = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<INumberFormatField>
) =>
  fc.field(key, 'number-format').options({
    label: 'Order size',
    ...commonRequiredPropsAnd(),
    ...overrides
  });
