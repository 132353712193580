import i18next, { TOptions } from 'i18next';
import { useTranslation as realUseTranslation } from 'react-i18next';
import en_US_long from '../locales/en-US/long.json';
import en_US_short from '../locales/en-US/short.json';
import en_GB_long from '../locales/en-GB/long.json';
import en_GB_short from '../locales/en-GB/short.json';
import type { LiteralUnion, Paths } from './util.types';

export const OMS_APP_TRANSLATION_DEFAULT = 'en-US';
export const OMS_APP_TRANSLATION_DEFAULT_NS = 'long';
export const OMS_APP_TRANSLATION_SHORT_NS = 'short';

export const omsAppTranslationResources = {
  'en-US': {
    long: en_US_long,
    short: en_US_short
  },
  'en-GB': {
    long: en_GB_long,
    short: en_GB_short
  }
} as const;

export type SupportedLocales = keyof typeof omsAppTranslationResources;
export type SupportedNamespaces = keyof typeof omsAppTranslationResources[SupportedLocales];

type TranslationPaths = Paths<typeof en_US_long, 4>;
type LiteralTranslationPaths = LiteralUnion<TranslationPaths>;

type OptionOverrides = {
  lng?: SupportedLocales;
  lngs?: readonly SupportedLocales[];
  ns?: SupportedNamespaces | readonly SupportedNamespaces[];
} & Record<string, any>;

export const t = i18next.t as (
  key: LiteralTranslationPaths | LiteralTranslationPaths[],
  options?: TOptions<OptionOverrides>
) => string;

export const useTranslation = realUseTranslation;
