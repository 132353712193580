import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import type {
  AnyRecord,
  ComboBoxItem,
  FieldContract,
  IAdvancedSelectField,
  ModifiedFieldForOptions } from '@oms/frontend-foundation';
import type { TradeCounterpartyInfoFragment } from '@oms/generated/frontend';

export const createTradeCounterpartyField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedSelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'advanced-select').advancedSelectOptions<TradeCounterpartyInfoFragment>({
    autoSizeWidthToTrigger: false,
    width: 200,
    label: 'Trade cpty',
    query: ADVANCED_SELECT_QUERY_ENUM.TRADE_COUNTERPARTY_QUERY,
    ...overrides
  });

export const createTradeCounterpartyComboboxItem = <TValue>(
  tradeCpty: Partial<TradeCounterpartyInfoFragment>,
  useIdOnly?: boolean
): ComboBoxItem<TValue> => {
  return {
    id: tradeCpty.id || '',
    label: tradeCpty.tradeCounterpartyCode || '',
    sublabel: tradeCpty.tradeCounterpartyDescription || '',
    type: 'item',
    value: (useIdOnly ? tradeCpty.id || '' : tradeCpty) as TValue
  };
};
