import { ComponentType } from 'react';
import {
  INLINE_ACTION_BUTTON,
  InlineActionButtonCell
} from '../actions/components/inline.action.button.cell';
import { INLINE_DRAG_ACTION, InlineDragActionCell } from '../actions/components/inline.drag.action.cell';
import {
  INLINE_ICON_ACTION_BUTTON,
  InlineIconActionButtonCell
} from '../actions/components/inline.icon.action.button.cell';
import {
  INLINE_SPLIT_BUTTON_ACTION,
  InlineSplitButtonAction
} from '../actions/components/inline.split.button.action';
import {
  STANDARD_DATE_CELL_RENDERER,
  STANDARD_DATE_TIME_CELL_RENDERER,
  standardDateRenderer,
  standardDateTimeRenderer
} from './date-renderers';
import { ICON_BUTTON_GROUP_CELL, IconButtonGroupCell } from './icon.button.group.cell';
import { NEGATIVE_NUM_CELL_RENDERER, negativeNumRenderer } from './number-renderers';
import {
  PRICE_SLIDER_52_WEEK_RENDERER,
  PRICE_SLIDER_RENDERER,
  PriceSlider52WeekRenderer,
  PriceSliderRenderer
} from './price-slider-renderer';
import { PROGRESS_RENDERER, ProgressRenderer } from './progress-renderer';
import { RULE_COUNT, RuleCountCell } from './rule.cell';
import { TEXT_RENDERER, TextRenderer } from './text-renderer';
import { UPPER_CASE_CELL_RENDERER, uppercaseRenderer } from './uppercase.renderer';
import { ICellRendererParams } from '@ag-grid-community/core';

export type DefaultCellRenderers =
  | typeof NEGATIVE_NUM_CELL_RENDERER
  | typeof UPPER_CASE_CELL_RENDERER
  | typeof STANDARD_DATE_CELL_RENDERER
  | typeof STANDARD_DATE_TIME_CELL_RENDERER
  | typeof PROGRESS_RENDERER
  | typeof PRICE_SLIDER_RENDERER
  | typeof PRICE_SLIDER_52_WEEK_RENDERER
  | typeof ICON_BUTTON_GROUP_CELL
  | typeof RULE_COUNT
  | typeof INLINE_ACTION_BUTTON
  | typeof INLINE_ICON_ACTION_BUTTON
  | typeof INLINE_DRAG_ACTION
  | typeof TEXT_RENDERER
  | typeof INLINE_SPLIT_BUTTON_ACTION;

export const DEFAULT_CELL_RENDERERS = {
  [NEGATIVE_NUM_CELL_RENDERER]: negativeNumRenderer,
  [UPPER_CASE_CELL_RENDERER]: uppercaseRenderer,
  [STANDARD_DATE_CELL_RENDERER]: standardDateRenderer,
  [STANDARD_DATE_TIME_CELL_RENDERER]: standardDateTimeRenderer,
  [PROGRESS_RENDERER]: ProgressRenderer,
  [PRICE_SLIDER_RENDERER]: PriceSliderRenderer,
  [PRICE_SLIDER_52_WEEK_RENDERER]: PriceSlider52WeekRenderer,
  [ICON_BUTTON_GROUP_CELL]: IconButtonGroupCell,
  [RULE_COUNT]: RuleCountCell,
  [INLINE_ACTION_BUTTON]: InlineActionButtonCell,
  [INLINE_ICON_ACTION_BUTTON]: InlineIconActionButtonCell,
  [INLINE_DRAG_ACTION]: InlineDragActionCell,
  [TEXT_RENDERER]: TextRenderer,
  [INLINE_SPLIT_BUTTON_ACTION]: InlineSplitButtonAction
} as Record<DefaultCellRenderers, ComponentType<ICellRendererParams>>;
