import { type AnyRecord } from '@valstro/workspace';
import {
  type FieldContract,
  type IAdvancedSelectField,
  type ModifiedFieldForOptions
} from '@oms/frontend-foundation';
import { type InstrumentDetailsSimpleFragment, type InstrumentDetails } from '@oms/generated/frontend';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import { type ComboBoxItem } from '@oms/frontend-foundation';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';

export const createSymbolField = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedSelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'advanced-select').advancedSelectOptions<InstrumentDetailsSimpleFragment>({
    label: 'Symbol',
    query: ADVANCED_SELECT_QUERY_ENUM.LOOKUP_INSTRUMENTS_SIMPLE_QUERY,
    autoSizeWidthToTrigger: false,
    filterStrategy: 'us-symbol',
    width: 400,
    inputProps: {
      style: {
        textTransform: 'uppercase'
      }
    },
    ...overrides
  });

export const createInstrumentComboboxItem = <TValue>(
  instrument: Partial<InstrumentDetails | InstrumentDetailsSimpleFragment>,
  useIdOnly?: boolean
): ComboBoxItem<TValue> => {
  return {
    type: 'item',
    id: instrument?.id || '',
    label: instrument?.mappings?.displayCode || instrument?.longName || '',
    sublabel: instrument?.longName,
    value: (useIdOnly ? instrument?.id || '' : instrument) as TValue
  };
};
