import { useMemo } from 'react';
import useRxScopedActorCollection from '@app/data-access/offline/hooks/use-rx-scoped-actor-collection.hook';
import { MONTAGE_COLLECTION_NAME } from '../montage.collection.constants';
import type { MontageDocType } from '../montage.collection.types';

export const useMontageState = (): MontageDocType => {
  const { scopedActorId, data } = useRxScopedActorCollection<MontageDocType>(MONTAGE_COLLECTION_NAME, {
    pageSize: 1
  });

  const { instrumentId, instrumentTrackingEnabled, investorAccountId } = data || {};

  return useMemo(
    () => ({
      instrumentId,
      instrumentTrackingEnabled,
      investorAccountId,
      scopedActorId
    }),
    [instrumentId, instrumentTrackingEnabled, investorAccountId, scopedActorId]
  );
};
