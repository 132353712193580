import { inject, singleton } from 'tsyringe';
import {
  type Observable,
  catchError,
  combineLatest,
  firstValueFrom,
  from,
  map,
  of,
  startWith,
  merge,
  take,
  share,
  Subject,
  type Subscription,
  type OperatorFunction
} from 'rxjs';
import { uniqBy } from 'lodash';
import type { ApolloQueryResult, FetchPolicy, FetchResult } from '@apollo/client';
import type { Maybe, Optional } from '@oms/shared/util-types';
import { Logger, cleanMaybe, compactMap } from '@oms/shared/util';
import {
  type AwaitGQLResultType,
  type DataSourceCommon,
  asObservableDataSource
} from '@oms/frontend-foundation';
import {
  ApplyManualMarkRequestDocument,
  type ApplyManualMarkRequestMutation,
  type ApplyManualMarkRequestMutationVariables,
  CreatePositionAdjustmentDocument,
  type CreatePositionAdjustmentMutation,
  type CreatePositionAdjustmentMutationVariables,
  GetPositionsTreeForAccountDocument,
  type GetPositionsTreeForAccountQuery,
  type GetPositionsTreeForAccountQueryVariables,
  GetSimplePositionDocument,
  type GetSimplePositionQuery,
  type GetSimplePositionQueryVariables,
  OnPositionsValuationUpdatedDocument,
  type PositionsTreeForAccountFragment,
  PreviewPositionAdjustmentDocument,
  type PreviewPositionAdjustmentQuery,
  type PreviewPositionAdjustmentQueryVariables,
  PreviewPositionTransferDocument,
  type PreviewPositionTransferQuery,
  type PreviewPositionTransferQueryVariables,
  RemoveManualMarkRequestDocument,
  type RemoveManualMarkRequestMutation,
  type RemoveManualMarkRequestMutationVariables,
  TransferPositionRequestDocument,
  type TransferPositionRequestMutation,
  type TransferPositionRequestMutationVariables,
  type PositionPreviewFragment,
  type CreatePositionAdjustmentInput,
  type TransferPositionRequestInput,
  type ApplyManualMarkRequestInput,
  type RemoveManualMarkRequestInput,
  type OnPositionsValuationUpdatedSubscriptionVariables,
  type OnPositionsValuationUpdatedSubscription,
  type PositionsValuationUpdatedFragment
} from '@oms/generated/frontend';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import { type SimpleInvestorAccount } from '@app/common/types/accounts/types';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import type { NestedTreeData } from './common/tree-grid/types/tree-data.types';
import PositionTransferFormState from './helpers/position-transfer-form-state.internal.class';
import type { ExtractedTreeIdMaps } from './util/types';
import { extractTreeIds } from './util/positions.util';
import PositionRowTool from './helpers/position-row-tool.internal.class';

type UnsubscribeWrapper = {
  unsubscribe: () => void;
};

@singleton()
export class PositionsService {
  protected name: string = 'PositionsService';
  protected logger: Logger;

  protected positionsSubject = new Subject<Partial<PositionsValuationUpdatedFragment>>();
  protected subscription?: Subscription;

  protected fetchPolicy: FetchPolicy = 'cache-first';
  protected pollInterval: number = 5000;

  protected transferState: PositionTransferFormState;

  // 🏗️ Constructor ------------------------------------------------------- /

  constructor(
    @inject(ApolloClientRPC) protected apolloClient: ApolloClientRPC,
    @inject(GQLResponse) protected gqlResponse: GQLResponse
  ) {
    this.logger = Logger.labeled(this.name);
    this.transferState = new PositionTransferFormState();
  }

  // 🍱 Grid ------------------------------------------------------------- /

  /**
   * Initializes the subscription to Positions data as needed.
   * This can be safely called multiple times and will only subscribe on the first usage.
   * > **IMPORTANT:** Remember to use the supplied `unsubscribe` function to clean up.
   *
   * @returns An object containing a function that can be called to clean up the subscription.
   */
  public subscribe(): UnsubscribeWrapper {
    const unsubscribe = this.unsubscribe.bind(this);
    if (this.subscription) return { unsubscribe };
    this.subscription = this.subscribeToPositionsValuationUpdates()
      .pipe(map(({ data }) => cleanMaybe(data?.positionsValuationUpdated, {})))
      .subscribe((data) => {
        this.positionsSubject.next(data);
      });
    return { unsubscribe };
  }

  /**
   * Data for the Positions Account (upper) grid
   * > **IMPORTANT:** The Positions subscription must be started with the `subscribe` method.
   *
   * @returns An Observable of Positions Tree data for the Positions Account (upper) grid.
   */
  public positionsTree$(): Observable<DataSourceCommon<NestedTreeData<PositionRow>>> {
    return merge(
      this.watchQuery_GetPositionsTreeForAccountQuery$().pipe(
        map((trees) => PositionRowTool.for(trees).getTreeDataPositionRows()),
        take(1)
      ),
      this.positionsTreeSubscription$()
    ).pipe(
      asObservableDataSource({
        onError: (e) => {
          this.logger.scope('watchAll$').error(e);
        }
      })
    );
  }

  /**
   * Data for the Positions Instrument (lower) grid
   * > **IMPORTANT:** The Positions subscription must be started with the `subscribe` method.
   *
   * @param accountId - Pass the account ID (if any), selected on the Positions Account (upper) grid.
   * @returns An Observable of Positions data for the Positions Instrument (lower) grid.
   */
  public positionsForAccount$(accountId?: string): Observable<DataSourceCommon<PositionRow>> {
    return merge(
      this.watchQuery_GetPositionsTreeForAccountQuery$(accountId).pipe(
        map((trees) => PositionRowTool.for(trees).getAccountPositionRows(accountId)),
        take(1)
      ),
      this.positionsForAccountSubscription$(accountId)
    ).pipe(
      asObservableDataSource({
        onError: (e) => {
          this.logger.scope('getPositionsForAccount$').error(e);
        }
      })
    );
  }

  /** For the current user: get a simple account/position mapping */
  public async getAccountPositionMapping(): Promise<Maybe<ExtractedTreeIdMaps>> {
    return await firstValueFrom(
      this.watchQuery_GetPositionsTreeForAccountQuery$().pipe(
        map((trees) => (trees.length ? extractTreeIds(trees) : null))
      )
    );
  }

  // 💁 Position info ------------------------------------------------------- /

  public async getSimplePositionInfo(id: string): Promise<Optional<PositionPreviewFragment>> {
    const result = await this.gqlResponse
      .wrapQuery<GetSimplePositionQuery, GetSimplePositionQueryVariables>({
        query: GetSimplePositionDocument,
        variables: { id },
        fetchPolicy: this.fetchPolicy
      })
      .exec();
    return result.mapTo(
      ({ data }) => cleanMaybe(data.position),
      (errors) => {
        errors.forEach((e) => {
          this.logger.scope('getSimplePositionInfo').error(e);
        });
        return undefined;
      }
    );
  }

  public simplePositionInfo$(id: string): Observable<Optional<PositionPreviewFragment>> {
    return this.apolloClient
      .watchQuery<GetSimplePositionQuery, GetSimplePositionQueryVariables>({
        query: GetSimplePositionDocument,
        variables: { id },
        fetchPolicy: this.fetchPolicy
      })
      .pipe(
        map(({ data }) => cleanMaybe(data.position)),
        startWith(undefined),
        catchError((e) => {
          this.logger.scope('simplePositionInfo$').error(e);
          return of(undefined);
        })
      );
  }

  // ✏️ Forms ------------------------------------------------------- /

  public getInvestorAccounts$(accountId?: string): Observable<DataSourceCommon<SimpleInvestorAccount>> {
    return this.watchQuery_GetPositionsTreeForAccountQuery$(accountId).pipe(
      map((trees) => PositionRowTool.for(trees).simpleInvestorAccounts),
      asObservableDataSource({
        onError: (e) => {
          this.logger.scope('getInvestorAccounts$').error(e);
        }
      })
    );
  }

  public getInvestorAccountsFor$(
    type: Optional<'buyer' | 'seller' | 'any'>,
    accountId?: string
  ): Observable<DataSourceCommon<SimpleInvestorAccount>> {
    switch (type) {
      case 'buyer':
        return this.getInvestorAccountsDisablingOtherId$(this.transferState.sellerId$, accountId);
      case 'seller':
        return this.getInvestorAccountsDisablingOtherId$(this.transferState.buyerId$, accountId);
      default:
        return this.getInvestorAccounts$(accountId);
    }
  }

  public updateTransferFormState(formValues: Parameters<typeof this.transferState.update>[0]): void {
    this.transferState.update(formValues);
  }

  public resetTransferFormState(): void {
    this.transferState.reset();
  }

  // 🔧 Adjustment ------------------------------------------------------- /

  public async createPositionAdjustment(
    input?: CreatePositionAdjustmentInput
  ): AwaitGQLResultType<CreatePositionAdjustmentMutation> {
    return await this.gqlResponse
      .wrapMutate<CreatePositionAdjustmentMutation, CreatePositionAdjustmentMutationVariables>({
        mutation: CreatePositionAdjustmentDocument,
        variables: { input },
        refetchQueries: [GetPositionsTreeForAccountDocument],
        awaitRefetchQueries: true
      })
      .exec();
    // TODO: Move this to form/widget logic
    // if (result.isSuccess()) {
    //   this._platformApiService.notification
    //     .notify({
    //       title: isFlatten
    //         ? t('app.positions.dialog.flatten.title')
    //         : t('app.positions.dialog.adjustment.title'),
    //       body: isFlatten
    //         ? t('app.positions.dialog.flatten.body')
    //         : t('app.positions.dialog.adjustment.body'),
    //       status: 'success'
    //     })
    //     .catch((e) => {
    //       this.logger.error(e);
    //     });
    // }
  }

  public previewPositionAdjustment$(
    data: CreatePositionAdjustmentInput
  ): Observable<ApolloQueryResult<PreviewPositionAdjustmentQuery>> {
    return this.apolloClient.watchQuery<
      PreviewPositionAdjustmentQuery,
      PreviewPositionAdjustmentQueryVariables
    >({
      query: PreviewPositionAdjustmentDocument,
      variables: { data },
      fetchPolicy: this.fetchPolicy
    });
  }

  // 🚚 Transfer ------------------------------------------------------- /

  public async transferPosition(
    input?: TransferPositionRequestInput
  ): AwaitGQLResultType<TransferPositionRequestMutation> {
    return await this.gqlResponse
      .wrapMutate<TransferPositionRequestMutation, TransferPositionRequestMutationVariables>({
        mutation: TransferPositionRequestDocument,
        variables: { input },
        refetchQueries: [GetPositionsTreeForAccountDocument],
        awaitRefetchQueries: true
      })
      .exec();
    // TODO: Move this to form/widget logic
    // if (result.isSuccess()) {
    //   this._platformApiService.notification
    //     .notify({
    //       title: 'Position transfer',
    //       body: 'Position transfer has been created',
    //       status: 'success'
    //     })
    //     .catch((e) => {
    //       this.logger.error(e);
    //     });
    // }
  }

  public previewPositionTransfer$(
    data: TransferPositionRequestInput
  ): Observable<ApolloQueryResult<PreviewPositionTransferQuery>> {
    return from(
      this.apolloClient.query<PreviewPositionTransferQuery, PreviewPositionTransferQueryVariables>({
        query: PreviewPositionTransferDocument,
        variables: { data },
        fetchPolicy: this.fetchPolicy
      })
    );
  }

  // ✔️ Mark ------------------------------------------------------- /

  public async markPosition(
    input: ApplyManualMarkRequestInput
  ): AwaitGQLResultType<ApplyManualMarkRequestMutation> {
    return await this.gqlResponse
      .wrapMutate<ApplyManualMarkRequestMutation, ApplyManualMarkRequestMutationVariables>({
        mutation: ApplyManualMarkRequestDocument,
        variables: { input },
        refetchQueries: [GetPositionsTreeForAccountDocument],
        awaitRefetchQueries: true
      })
      .exec();
    // TODO: Move this to form/widget logic
    // if (result.isSuccess()) {
    //   this._platformApiService.notification
    //     .notify({
    //       title: 'Set valuation price',
    //       body: 'Valuation price has been set',
    //       status: 'success'
    //     })
    //     .catch((e) => {
    //       this.logger.error(e);
    //     });
    // }
  }

  // 🗙 Unmark ------------------------------------------------------- /

  public async unmarkPosition(
    input: RemoveManualMarkRequestInput
  ): AwaitGQLResultType<RemoveManualMarkRequestMutation> {
    return await this.gqlResponse
      .wrapMutate<RemoveManualMarkRequestMutation, RemoveManualMarkRequestMutationVariables>({
        mutation: RemoveManualMarkRequestDocument,
        variables: { input },
        refetchQueries: [GetPositionsTreeForAccountDocument],
        awaitRefetchQueries: true
      })
      .exec();
    // TODO: Move this to form/widget logic
    // if (result.isSuccess()) {
    //   this._platformApiService.notification
    //     .notify({
    //       title: 'Valuation price removed',
    //       body: 'Valuation has been removed',
    //       status: 'success'
    //     })
    //     .catch((e) => {
    //       this.logger.error(e);
    //     });
    // }
  }

  // 🔒 Protected --------------------------------------------------------- /

  protected unimplemented(feature: string) {
    this.logger.log(`Feature not yet implemented: ${feature}`);
  }

  // 📩 Positions subscriptions -------------------------------- /

  protected subscribeToPositionsValuationUpdates(): Observable<
    FetchResult<OnPositionsValuationUpdatedSubscription>
  > {
    return this.apolloClient.subscribe<
      OnPositionsValuationUpdatedSubscription,
      OnPositionsValuationUpdatedSubscriptionVariables
    >({
      query: OnPositionsValuationUpdatedDocument,
      fetchPolicy: this.fetchPolicy
    });
  }

  protected positionsTreeSubscription$(): Observable<NestedTreeData<PositionRow>[]> {
    return this.positionsSubject.asObservable().pipe(
      this.getPositionsTreesFromValuationUpdate(),
      map((trees) => PositionRowTool.for(trees).getTreeDataPositionRows())
    );
  }

  protected positionsForAccountSubscription$(accountId?: string): Observable<PositionRow[]> {
    return this.positionsSubject.asObservable().pipe(
      this.getPositionsTreesFromValuationUpdate(),
      map((trees) => PositionRowTool.for(trees).getAccountPositionRows(accountId)),
      map((rows) => uniqBy(rows, ({ id }) => id))
    );
  }

  // 🧹 Cleanup ---------- /

  /**
   * Note that this will *not* actually remove the Positions subscription until all Positions windows are unsubscribed.
   */
  protected unsubscribe() {
    if (this.positionsSubject.observed) return;
    if (this.subscription) {
      this.subscription.unsubscribe();
      delete this.subscription;
    }
  }

  // 🔍 Watch queries and queries -------------------------------- /

  protected watchQuery_GetPositionsTreeForAccountQuery$(
    accountId?: string
  ): Observable<PositionsTreeForAccountFragment[]> {
    return this.apolloClient
      .watchQuery<GetPositionsTreeForAccountQuery, GetPositionsTreeForAccountQueryVariables>({
        query: GetPositionsTreeForAccountDocument,
        variables: {
          accountId: accountId ?? ''
        },
        fetchPolicy: this.fetchPolicy
      })
      .pipe(
        map(({ data }) => compactMap(cleanMaybe(data?.getPositionsTreeForAccount, []))),
        share()
      );
  }

  // 🛠️ Utility -------------------------------- /

  protected getInvestorAccountsDisablingOtherId$(
    otherId$: Observable<Optional<string>>,
    accountId?: string
  ): Observable<DataSourceCommon<SimpleInvestorAccount>> {
    return combineLatest([this.getInvestorAccounts$(accountId), otherId$]).pipe(
      map(([data, otherId]) => {
        if (!otherId) return data;
        const { results, ...rest } = data;
        if (!results) return data;
        return {
          results: results.map(({ id, ...accountRest }) => ({
            id,
            ...accountRest,
            isDisabled: id === otherId
          })),
          ...rest
        };
      })
    );
  }

  // 📥 Operator functions -------------------------------- /

  protected getPositionsTreesFromValuationUpdate(): OperatorFunction<
    Partial<PositionsValuationUpdatedFragment>,
    PositionsTreeForAccountFragment[]
  > {
    return (observable$) => observable$.pipe(map(({ rollup }) => compactMap(cleanMaybe(rollup, []))));
  }

  // Static ------------------------------------------------------------------------------ /

  /**
   * Use for subscriptions
   *
   * @param fromSubscription Pass subscription result
   * @returns An array of Positions trees
   */
  protected static extractTree(
    fromSubscription: OnPositionsValuationUpdatedSubscription
  ): PositionsTreeForAccountFragment[];

  /**
   * Use for queries
   *
   * @param fromQuery Pass query result
   * @returns An array of Positions trees
   */
  protected static extractTree(fromQuery: GetPositionsTreeForAccountQuery): PositionsTreeForAccountFragment[];

  // Implementation only ------- /
  protected static extractTree(
    data?: OnPositionsValuationUpdatedSubscription | GetPositionsTreeForAccountQuery
  ): PositionsTreeForAccountFragment[] {
    const possiblePositionsTree = (() => {
      if (!data) return;
      switch (data.__typename) {
        case 'Subscription':
          return data.positionsValuationUpdated?.rollup;
        case 'Query':
          return data.getPositionsTreeForAccount;
      }
    })();
    return compactMap(cleanMaybe(possiblePositionsTree, []));
  }
}

export default PositionsService;
