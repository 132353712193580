import { OrderSide } from '@oms/generated/frontend';
import {
  type CrossPrincipalFillRow,
} from '@app/widgets/trading/cross-principal-fill/cross-principal-fill.form-common';
import { type CrossPrincipalFormValues } from '@app/widgets/trading/cross-principal-fill/cross-principal-fill.form-contract';
import { getState$ } from './cross-principal-fill.state';
import { getUpdatedRows, inputChanged, totalQuantityChanged, totalQuantityEnriched } from './cross-principal-fill.state.utils';

export const handleOnValueChanged = (formId: string, values: CrossPrincipalFormValues) => {
  const newState = structuredClone(getState$(formId).getValue());
  newState.formValues = values;

  if (inputChanged(formId, values)) {
    const quantityChanged = totalQuantityChanged(formId, values) && !totalQuantityEnriched(formId, values)

    if (quantityChanged) {
      // reset manually updated quantities
      newState.buySideRows.concat(newState.sellSideRows).forEach(row => {
        row.state.fillQuantity = undefined
        row.state.isManualQuantity = false
      })
    }

    const { buySideRows, sellSideRows } = getUpdatedRows(structuredClone(newState));

    newState.buySideRows = structuredClone(buySideRows)
    newState.sellSideRows = structuredClone(sellSideRows)
  }

  getState$(formId).next(newState);
};

export const handleOnRowClicked = (formId: string, row: CrossPrincipalFillRow) => {
  const newState = structuredClone(getState$(formId).getValue());
  const orderSide = row.order.side;
  const rows = orderSide === OrderSide.Buy ? newState.buySideRows : newState.sellSideRows;
  const updatedRowData = rows.find((item) => item.order.id === row.order.id);

  if (!updatedRowData) {
    return;
  }

  updatedRowData.state.isChecked = !row.state.isChecked;
  updatedRowData.state.isExcluded = !updatedRowData.state.isChecked
  updatedRowData.state.isManualQuantity = false

  getState$(formId).next(newState);
};

export const handleOnCellValueChanged = (formId: string, row: CrossPrincipalFillRow) => {
  const newState = structuredClone(getState$(formId).getValue());

  const rows = row.order.side === OrderSide.Buy ? newState.buySideRows : newState.sellSideRows;
  const updatedRowData = rows.find((item) => item.order.id === row.order.id);

  if (updatedRowData) {
    updatedRowData.state.isManualQuantity = true
  }

  getState$(formId).next(newState);
}
