import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { openRouteOrder } from '@app/generated/sdk';
import { FORM_ENUM } from '@app/generated/common';
import {
  type VisibleTradingOrderInfoFragment as TOFragment,
  TradingOrderStatus
} from '@oms/generated/frontend';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { PROCESS_ID } from '@valstro/workspace';

export const modifyTradingOrderOnChange = (
  ctx: ActionContext<TOFragment, ActionComponentConfig<TOFragment>>
) => {
  const { lifecycle, data, workspace } = ctx;
  const selectedRow = data[0];
  const isMoreThanOneRowSelected = data.length > 1;
  const id = selectedRow?.id;
  const symbol = selectedRow?.instrument?.mappings?.displayCode;

  ctx.notify({ isDisabled: selectedRow?.status !== TradingOrderStatus.Active || isMoreThanOneRowSelected });

  if (lifecycle === 'change' && id) {
    openRouteOrder(PROCESS_ID.LEADER, {
      title: `Modify Trading Order ${symbol}`,
      windowOptions: {
        isPinnable: true,
        meta: {
          widgetType: `${FORM_ENUM.ROUTE_ORDER}_MODIFY` // Differentiate the widgetType so remember window feature saves this type of window separately
        }
      },
      form: {
        formId: `modify-trading-order-${id}`,
        input: {
          tradingOrderId: id
        }
      }
    }).catch((e) => {
      openMessageDialog(`Error: ${e}`, workspace).catch(console.error);
      console.error(e);
    });
  }
};

export const modifyTradingOrderAction: ActionDefFactory<TOFragment> = (builder) =>
  builder
    .name('modify_trading_order')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('modify_trading_order_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: 'Modify'
        })
    )
    .menu((m) => m.name('Modify').visible(({ rowData }) => rowData?.status === TradingOrderStatus.Active))
    .onChange<ActionComponentConfig<TOFragment>>(modifyTradingOrderOnChange);
