import React, { useCallback, useMemo } from 'react';
import type { Field } from '@data-driven-forms/react-form-renderer';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { Box, Button, Flex, Icon } from '@oms/shared-frontend/ui-design-system';
import type { CommonWindowActorSchema as WinSchema } from '@valstro/workspace';
import { PlusIcon, ResetIcon } from '@radix-ui/react-icons';
import { type ICommonField } from '@oms/frontend-foundation';
import { useFieldApi } from '@oms/frontend-foundation';
import type { FieldProps } from '@oms/frontend-foundation';
import { useFormBuilderTemplate } from '@oms/frontend-foundation';
import type { FORM_COMPONENT_TYPE } from '@app/forms/form-builder/common/form.contracts';
import { DIALOG_ENUM, OBJECT_CATEGORY } from '@app/generated/common';
import { useCurrentWidget, useCurrentWindow } from '@valstro/workspace-react';
import { type IAllFields } from './all-fields.component';
import { allFields$ } from './all-fields-events';
import * as styles from './additional-fields.css';
import { useOpenConfirmation } from '@app/generated/sdk';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';

export interface IAdditionalFields extends ICommonField<typeof FORM_COMPONENT_TYPE.ADDITIONAL_FIELDS> {
  fields: Field[];
}

export const AdditionalFields: React.FC<FieldProps<IAdditionalFields>> = (props) => {
  const { formId, formType } = useFormBuilderTemplate();
  const widget = useCurrentWidget();
  const window = useCurrentWindow();
  const { renderForm, getState } = useFormApi();
  const { fields = [] } = useFieldApi<IAdditionalFields>(props);

  const [openConfirmationDialog, triggerProps] = useOpenConfirmation();

  const openAllFieldsDialog = useCallback(async () => {
    const { meta = {} } = await window.context();
    await widget.spawnChild<WinSchema>({
      type: 'window',
      context: {
        title: 'All Fields',
        width: 800,
        height: 700,
        // Adding meta details for "remember window" feature
        // We remember the window based on the widget type / category
        meta: {
          widgetCategory: OBJECT_CATEGORY.TRADING,
          widgetType: getWidgetTypeFromMeta(meta)
        }
      },
      id: `${formId}-all-fields-dialog-window`,
      children: [
        {
          type: 'widget',
          id: `${formId}-all-fields-dialog-widget`,
          context: {
            componentId: DIALOG_ENUM.ALL_FIELDS,
            componentProps: {
              fields: [{ ...fields[0], renderStrategy: 'dialog-form' }],
              formId,
              formType,
              formValues: getState().values
            }
          }
        }
      ]
    });
  }, [fields, formId, formType, getState, window, widget]);

  const useAllFields = useMemo(() => {
    const allFields = fields[0] as IAllFields;

    return !!allFields?.fields?.find(([enrichedField]) => {
      return !allFields.fixedFields?.includes(enrichedField.field.name);
    });
  }, [fields]);

  const handleOpenAllFields = useCallback(() => {
    openAllFieldsDialog().catch(console.error);
  }, [openAllFieldsDialog]);

  const showConfirmationAndResetValues = useCallback(async () => {
    const [_, api] = await openConfirmationDialog({
      componentProps: {
        message: 'Are you sure you want to reset the form?'
      }
    });

    const result = await api.awaitFirstEvent;

    if (result.type === DIALOG_EVENT_TYPE.OK) {
      allFields$.next({
        type: 'RESET_VALUES',
        payload: {},
        meta: {
          formId
        }
      });
    }
  }, [formId, openConfirmationDialog]);

  const handleResetAllValues = useCallback(() => {
    showConfirmationAndResetValues().catch((error) => {
      console.error('Failed to reset values:', error);
    });
  }, [showConfirmationAndResetValues]);

  return (
    <Box>
      {renderForm(fields)}
      {useAllFields && (
        <Flex
          justify="space-between"
          sx={{ backgroundColor: 'layout.level1', paddingY: 'medium', paddingTop: 7 }}
        >
          <Button
            variant="link"
            onClick={handleOpenAllFields}
            sx={{ marginLeft: 'small', marginBottom: 1 }}
            type="button"
          >
            <Icon
              label="Add"
              as={PlusIcon}
              className={styles.icon}
              sx={{ marginBottom: 0.5, marginRight: 1 }}
            />
            All Fields
          </Button>
          <Button
            variant="ghost"
            onClick={handleResetAllValues}
            type="button"
            sx={{ marginRight: 'small', color: 'PureGrey.100' }}
            className={styles.resetButton}
            {...triggerProps}
          >
            <Icon
              label="Reset"
              as={ResetIcon}
              className={styles.icon}
              sx={{ marginBottom: 0.5, marginRight: 1 }}
            />
            Reset values
          </Button>
        </Flex>
      )}
    </Box>
  );
};

const ALL_FIELDS_DIALOG_TYPE = 'ALL_FIELDS_DIALOG';

function getWidgetTypeFromMeta(meta: Record<string, any>) {
  const widgetType = 'widgetType' in meta ? String(meta.widgetType) : '';
  return widgetType ? `${widgetType}_${ALL_FIELDS_DIALOG_TYPE}` : ALL_FIELDS_DIALOG_TYPE;
}
