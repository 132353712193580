import type { GridApi } from '@ag-grid-community/core';
import { type CustomFilterProps, type CustomFloatingFilterProps } from '@ag-grid-community/react';
import {
  FormRenderer,
  useFormApi,
  type FormTemplateRenderProps,
  type Schema
} from '@data-driven-forms/react-form-renderer';
import { FORM_COMPONENT_MAPPER, useEnhancedFormApi } from '@oms/frontend-foundation';
import { Box } from '@oms/shared-frontend/ui-design-system';
import { useCallback, useEffect } from 'react';
import { pairwise } from 'rxjs';
import type { AnyRecord } from '@valstro/workspace';
import type { Sprinkles } from '@oms/shared-frontend/ui-design-system';

const options = [
  {
    type: 'item',
    label: 'Today',
    value: 'today',
    id: 'today'
  },
  {
    type: 'item',
    label: 'Yesterday',
    value: 'yesterday',
    id: 'yesterday'
  },
  {
    type: 'item',
    label: 'Last 7 days',
    value: '7-days',
    id: '7-days'
  },
  {
    type: 'item',
    label: 'Last 30 days',
    value: '30-days',
    id: '30-days'
  },
  {
    type: 'item',
    label: 'Last 90 days',
    value: '90-days',
    id: '90-days'
  },
  {
    type: 'item',
    label: 'Date range',
    value: 'range',
    id: 'range'
  }
];

const mainFilterSchema: Schema = {
  fields: [
    {
      name: 'period',
      component: 'select',
      options,
      hideFormControls: true
    },
    {
      name: 'from',
      component: 'native-date-picker',
      condition: { when: 'period', is: 'range' },
      hideFormControls: true
    },
    {
      name: 'to',
      component: 'native-date-picker',
      condition: { when: 'period', is: 'range' },
      hideFormControls: true
    }
  ]
};

const floatingFilterSchema: Schema = {
  fields: [
    {
      name: 'period',
      component: 'select',
      options,
      hideFormControls: true
    }
  ]
};

type FilterFormTemplateProps = {
  columnId: string;
  api: GridApi;
  onModelChange: (model: AnyRecord) => void;
  showParentFilter?: () => void;
  sx?: Sprinkles;
} & FormTemplateRenderProps;

export const FilterFormTemplate = ({
  columnId,
  onModelChange,
  showParentFilter,
  api,
  schema,
  sx
}: FilterFormTemplateProps) => {
  const { renderForm } = useFormApi();
  const formApi = useEnhancedFormApi();

  useEffect(() => {
    // when a filter updates a model, we need to update the corresponding filter (floating or main)
    const handleFilterChanged = () => {
      const filterModel = api.getFilterModel()[columnId] as AnyRecord | undefined;

      if (!filterModel) return;

      const values = formApi.getState().values;

      for (const [key, filterValue] of Object.entries(filterModel)) {
        if (values[key] !== filterValue) {
          formApi.change(key, filterValue);
        }
      }
    };

    api.addEventListener('filterChanged', handleFilterChanged);

    return () => {
      api.removeEventListener('filterChanged', handleFilterChanged);
    };
  }, [api, columnId, formApi]);

  useEffect(() => {
    const sub = formApi
      .get$({ values: true })
      .pipe(pairwise())
      .subscribe(([{ values: prevValues }, { values }]) => {
        onModelChange(values);

        if (showParentFilter) {
          if (values.period === 'range' && prevValues.period !== 'range') {
            showParentFilter();
          }
        }
      });

    return () => {
      sub.unsubscribe();
    };
  }, [columnId, formApi, onModelChange, showParentFilter]);

  return <Box sx={sx}>{renderForm(schema.fields)}</Box>;
};

export const DateFilter = (params: CustomFilterProps) => {
  const { onModelChange, column, api } = params;
  const columnId = column.getColId();

  const Template = useCallback(
    (props: FormTemplateRenderProps) => {
      return (
        <FilterFormTemplate
          {...props}
          columnId={columnId}
          onModelChange={onModelChange}
          api={api}
          sx={{ padding: 'small' }}
        />
      );
    },
    [columnId, onModelChange, api]
  );

  return (
    <Box style={{ minHeight: '100px' }}>
      <FormRenderer
        schema={mainFilterSchema}
        FormTemplate={Template}
        componentMapper={FORM_COMPONENT_MAPPER}
      />
    </Box>
  );
};

export const FloatingDateFilter = (params: CustomFloatingFilterProps) => {
  const { onModelChange, showParentFilter, column, api } = params;
  const columnId = column.getColId();

  const Template = useCallback(
    (props: FormTemplateRenderProps) => {
      return (
        <FilterFormTemplate
          {...props}
          columnId={columnId}
          onModelChange={onModelChange}
          showParentFilter={showParentFilter}
          api={api}
          sx={{ marginTop: '-0.5' }}
        />
      );
    },
    [columnId, onModelChange, showParentFilter, api]
  );

  return (
    <FormRenderer
      schema={floatingFilterSchema}
      FormTemplate={Template}
      componentMapper={FORM_COMPONENT_MAPPER}
    />
  );
};
