import { type HiddenFieldControl } from '@valstro/fixatdl-core';
import { type ControlTransformer } from './control.transformer.types';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const hiddenFieldControl: ControlTransformer<HiddenFieldControl> = (
  hiddenFieldControl,
  _query,
  formValues
) => {
  return {
    component: fixatdlFormComponentTypes.HIDDEN_FIELD,
    name: hiddenFieldControl.id,
    initialValue: AtdlFormats.coerceValue(formValues[hiddenFieldControl.id]),
    label: hiddenFieldControl.label
  };
};
