/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getTextWidth(text: string, font: string) {
  // re-use canvas object for better performance
  const canvas: HTMLCanvasElement =
    (getTextWidth as any).canvas || ((getTextWidth as any).canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d') as any;
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

export function getCssStyle(element: HTMLElement, prop: string) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

export function getCanvasFont(el = document.body) {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';
  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

/**
 * Wait for an element to be added to the DOM
 * @param selector The selector to wait for
 * @param options - { timeout?: number; container?: HTMLElement }
 * @returns The element or null if the timeout is reached
 * @see https://stackoverflow.com/questions/32553158/detect-click-outside-an-element
 */
export function waitForElm<T extends HTMLElement>(
  selector: string,
  options?: { timeout?: number; container?: HTMLElement }
) {
  return new Promise<T | null>((resolve) => {
    const c = options?.container || document.body;
    let interval: NodeJS.Timeout | undefined;

    if (options?.timeout) {
      interval = setTimeout(() => {
        observer.disconnect();
        resolve(null);
      }, options.timeout);
    }

    if (c.querySelector(selector)) {
      clearInterval(interval);
      return resolve(c.querySelector(selector) as T);
    }

    const observer = new MutationObserver(() => {
      if (c.querySelector(selector)) {
        clearInterval(interval);
        resolve(c.querySelector(selector) as T);
        observer.disconnect();
      }
    });

    observer.observe(c, {
      childList: true,
      subtree: true
    });
  });
}

/**
 * Recursively find the closest parent element with the given tag
 *
 * @param el - The element to start from
 * @param tag - The tag to find eg. BUTTON, DIV, SPAN etc
 * @param limit - The limit to stop looking up the DOM tree
 * @returns HTMLElement | null
 */
export function findUpTag(el: HTMLElement, tag: string, limit = 5) {
  let count = 0;
  while (el?.parentElement || count < limit) {
    el = el?.parentElement as HTMLElement;
    count++;
    if (el?.tagName === tag) return el;
  }
  return null;
}

const focusableElements =
  'button:not([disabled]):not([tabindex="-1"]), a:not([disabled]):not([tabindex="-1"]), input:not([disabled]), select:not([disabled]), textarea:not([disabled])';

/**
 * Get all focusable elements within a container
 */
export function getAllFocusableElements(
  searchContainer: HTMLElement | Document = document
): Array<HTMLElement | null> {
  return Array.from(searchContainer.querySelectorAll(focusableElements) as NodeListOf<HTMLElement>);
}

/**
 * Get the next focusable element, based on the current active element
 *
 * @returns HTMLElement | null
 */
export function getNextPrevFocusableElement(
  currentFocus?: Element | null,
  dir: 'next' | 'prev' = 'next',
  searchContainer: HTMLElement | Document = document
): HTMLElement | null {
  const focusable = Array.from(searchContainer.querySelectorAll(focusableElements));

  currentFocus = currentFocus || document.activeElement;

  if (!currentFocus || currentFocus === document.body) {
    return focusable[0] as HTMLElement;
  } else {
    const currentIndex = focusable.indexOf(currentFocus as HTMLElement);
    if (currentIndex === -1) {
      return focusable[0] as HTMLElement | null;
    } else {
      const index =
        dir === 'next' ? (currentIndex + 1 < focusable.length ? currentIndex + 1 : 0) : currentIndex - 1;

      return focusable[index] ? (focusable[index] as HTMLElement) : null;
    }
  }
}
