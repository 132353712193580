import { Button } from '@oms/shared-frontend/ui-design-system';
import { useCurrentWidgetActorProps } from '@valstro/workspace-react';
import type { ExampleComponentProps } from './example.widget.config';
import { useOpenExampleForm } from '@app/generated/sdk';

export const ExampleWidgetComponent: React.FC = () => {
  const [props] = useCurrentWidgetActorProps<ExampleComponentProps>(); // 👈 This is how you get the props from the widget actor
  const open = useOpenExampleForm();

  const handleOpenForm = () => {
    open('current', {
      form: {
        input: props.order
      }
    }).catch(console.error);
  };

  return <Button onClick={handleOpenForm}>Open Form</Button>;
};

export default ExampleWidgetComponent;
