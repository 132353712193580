import {
  type ActionDefFactory,
  INLINE_SPLIT_BUTTON_ACTION,
  type SplitButtonActionComponentConfig,
  type SplitButtonActionConfig
} from '@oms/frontend-vgrid';
import {
  type VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment,
  InvestorOrderStatus
} from '@oms/generated/frontend';
import { acceptInvestorOrderOnChange } from './accept.investor-order.action';
import { rejectInvestorOrderOnChange } from './reject.investor-order.action';
import { cancelInvestorOrderOnChange } from './cancel.investor-order.action';
import { viewInvestorOrderOnChange } from './view.investor-order.action';
import { openRouteInvestorOrderOnChange } from './open-route.investor-order.action';

export const inlineInvestorOrderActions: ActionDefFactory<IOFragment> = (builder) =>
  builder
    .name('inline_order_split_button')
    .inline((col) =>
      col
        .header('Actions')
        .initialWidth(140)
        .filter(false)
        .cell((c) =>
          c
            .dependsOn(['id', 'status', 'openQuantity'])
            .renderer(INLINE_SPLIT_BUTTON_ACTION, { variant: 'secondary' })
        )
    )
    .onChange<SplitButtonActionComponentConfig<IOFragment>>(async (ctx) => {
      switch (ctx.lifecycle) {
        case 'init':
        case 'refresh': {
          const [rowData] = ctx.data;
          ctx.notify({ actionsConfig: rowActions(rowData) });
          break;
        }
        case 'change': {
          const { selectedId } = ctx.state;
          switch (selectedId) {
            case 'inline_view_investor_order':
              return viewInvestorOrderOnChange(ctx);
            case 'inline_accept_investor_order':
              return await acceptInvestorOrderOnChange(ctx);
            case 'inline_reject_investor_order':
              return await rejectInvestorOrderOnChange(ctx);
            case 'inline_route_investor_order':
              return openRouteInvestorOrderOnChange(ctx);
            case 'inline_cancel_investor_order':
              return await cancelInvestorOrderOnChange(ctx);
            default:
              throw new Error(`onChange not defined for ${selectedId}`);
          }
        }
      }
    });

function hasOpenQuanity(rowData: IOFragment) {
  return rowData?.openQuantity !== null && rowData?.openQuantity !== undefined && rowData?.openQuantity > 0;
}

function rowActions(rowData: IOFragment): SplitButtonActionConfig[] {
  switch (rowData.status) {
    case InvestorOrderStatus.Unaccepted:
      return [
        { id: 'inline_accept_investor_order', title: 'Accept' },
        { id: 'inline_reject_investor_order', title: 'Reject' }
      ];

    case InvestorOrderStatus.Active:
      return [
        ...(hasOpenQuanity(rowData)
          ? [{ id: 'inline_route_investor_order', title: 'Route Order' }]
          : [{ id: 'inline_view_investor_order', title: 'View Order' }]),
        { id: 'inline_cancel_investor_order', title: 'Cancel' }
      ];

    // TODO Inactive and PendingModifications

    case InvestorOrderStatus.ModifyRequested:
      return [
        { id: 'inline_accept_modification', title: 'Accept' },
        { id: 'inline_reject_modification', title: 'Reject' }
      ];

    default:
      return rowData.id
        ? [
            {
              id: 'inline_view_investor_order',
              title: 'View Order'
            }
          ]
        : [];
  }
}
