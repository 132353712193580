import { type Field, type UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import { type InvestorOrderEntryValues } from '../investor-order-entry.form-contract';
import { type EnhancedFormOptions } from '@oms/frontend-foundation';

export const IO_RUNWAY_DISABLE_ACTION = 'ioRunwayDisableAction';

export type IORunwayDisable = { checkParentTradingOrder?: boolean };

export const ioRunwayDisableAction =
  (opts: IORunwayDisable | undefined) =>
  (
    _field: Field,
    _fieldApi: UseFieldApiConfig,
    formOptions: EnhancedFormOptions<InvestorOrderEntryValues>
  ) => {
    const { checkParentTradingOrder = false } = opts || {};
    const { getState } = formOptions;
    const { values } = getState();
    const isDisabled =
      values.hiddenFormInfo?.type === 'update' && (checkParentTradingOrder ? !!values.parentTradingOrderId : true);
    return {
      isDisabled
    };
  };
