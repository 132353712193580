import React from 'react';
import { type IAdvancedMultiSelectField } from './advanced-multi-select.types';
import { useAdvancedSelectQuery } from './advanced-select-query.hook';
import isEqual from 'lodash/isEqual';
import type { FieldProps } from '../../types';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { Autocomplete } from '../../../popovers/autocomplete/popover.autocomplete';

export const AdvancedMultiSelectField: React.FC<FieldProps<IAdvancedMultiSelectField<any>>> = React.memo(
  (props) => {
    const { fieldWrapperProps, autoCompleteProps } = useAdvancedSelectQuery(props, true);

    return (
      <FieldWrapper {...fieldWrapperProps}>
        <Autocomplete
          {...autoCompleteProps}
          onChange={props.onChange || autoCompleteProps.onChange}
          value={props.value || autoCompleteProps.value}
        />
      </FieldWrapper>
    );
  },
  isEqual
);
