import React from 'react';
import { type IAdvancedSelectField } from './advanced-select.types';
import type { FieldProps } from '../../types';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { useAdvancedSelectQuery } from './advanced-select-query.hook';
import { Autocomplete } from '../../../popovers/autocomplete/popover.autocomplete';

export const AdvancedSelectField: React.FC<FieldProps<IAdvancedSelectField<any>>> = (props) => {
  const { fieldWrapperProps, autoCompleteProps } = useAdvancedSelectQuery(props);

  return (
    <FieldWrapper {...fieldWrapperProps}>
      <Autocomplete {...autoCompleteProps} />
    </FieldWrapper>
  );
};
