import { Avatar, Box } from '@oms/shared-frontend/ui-design-system';
import { type Counted, type Labeled, type Sourced } from '@oms/shared/util-types';
import clsx from 'clsx';
import { Command } from './cmdk';
import { uniqBy } from 'lodash';
import { type FC, useMemo } from 'react';
import { useComboBoxContext } from './combo-box.context';
import { comboBoxIconMap, type ComboBoxIconUnion } from './combo-box.icons';
import {
  type ComboBoxItem as IComboBoxItem,
  type ComboBoxItemProps,
  type ComboBoxItemWithValueProps
} from './combo-box.types';
import * as styles from './styles/styles.css';

const CLASS_NAMES = {
  item: {
    wrapper: 'combo-box-item-wrapper',
    avatarWrapper: 'combo-box-item-avatar-wrapper',
    labelWrapper: 'combo-box-item-label-wrapper'
  }
};

type ComboBoxAvatarProps = Labeled & Partial<Sourced> & Partial<Counted>;

const ComboBoxAvatar: FC<ComboBoxAvatarProps> = ({ label, src, count = 1 }) => (
  <Box className={clsx(CLASS_NAMES.item.avatarWrapper, styles.itemAvatarWrapper)}>
    <Avatar palette="primary" size="md" name={label} count={count} src={src} isFullName={true} />
  </Box>
);

/**
 * Decides which item type to render
 */
export const ComboBoxItem: React.FC<ComboBoxItemProps> = ({ item, isLastItem }) => {
  const { strategy } = useComboBoxContext();
  const { type } = item;

  switch (type) {
    case 'item': {
      const { showAvatar, avatarSrc, avatarCount, label, sublabel } = item;
      return (
        <ComboBoxItemWithValue item={item}>
          <Box className={clsx(CLASS_NAMES.item.wrapper, styles.itemWrapper)}>
            {showAvatar && <ComboBoxAvatar label={label} src={avatarSrc} count={avatarCount} />}
            <Box className={clsx(CLASS_NAMES.item.labelWrapper, styles.itemLabelWrapper)}>
              {label}
              {sublabel && <small>{sublabel}</small>}
            </Box>
          </Box>
        </ComboBoxItemWithValue>
      );
    }

    case 'group': {
      // Instead of rendering a group, render command, which when selected, will drilldown into the group
      if (strategy === 'nested-commands' && item.isDrilldown === true) {
        return (
          <ComboBoxItemWithValue
            item={{
              ...item,
              id: item.label,
              value: item.label,
              type: 'item'
            }}
          >
            {item.label}
          </ComboBoxItemWithValue>
        );
      }

      // Render the standard group pattern of CMDK
      return (
        <>
          <Command.Group heading={item.label}>
            {item.items.map((innerItem, i) => (
              <ComboBoxItem
                isLastItem={i === item.items.length - 1}
                item={{ ...innerItem } as IComboBoxItem<any>}
                key={innerItem.type === 'item' ? innerItem.id : item.label + i}
              />
            ))}
          </Command.Group>
          {!isLastItem && <Command.Separator />}
        </>
      );
    }

    case 'sep': {
      return <Command.Separator />;
    }

    default: {
      return <div>Unknown item</div>;
    }
  }
};

/**
 * Handles setting selected values & wraps value-based items in handy shortcut helper
 */
export const ComboBoxItemWithValue: React.FC<React.PropsWithChildren<ComboBoxItemWithValueProps>> = ({
  children,
  item
}) => {
  const {
    setSelectedItems,
    setItems,
    setInputValue,
    selectedItems,
    strategy,
    submitInstantly,
    onSubmit,
    prevItemsRef,
    onItemClick
  } = useComboBoxContext();

  const isDisabled = useMemo(() => {
    const { id, isDisabled } = item;
    if (isDisabled === true) return true;
    if (isDisabled === false) return false;
    return !!selectedItems?.find((i) => i?.id === id);
  }, [selectedItems, item]);

  const onSelect = () => {
    onItemClick?.(item);
    if (setSelectedItems) {
      const itemClone = structuredClone(item);
      const shouldUseSingleSelectedStrategy = !!setSelectedItems && strategy === 'single-select';
      const shouldUseNestedStrategy =
        !!setItems && strategy === 'nested-commands' && !!itemClone?.items?.length;

      if (shouldUseSingleSelectedStrategy) {
        const newSelectedItems = [itemClone];
        setSelectedItems(newSelectedItems);
        if (setInputValue) setInputValue('');
        if (submitInstantly && onSubmit) onSubmit(newSelectedItems);
        return;
      }

      if (shouldUseNestedStrategy) {
        itemClone.prevItems = prevItemsRef?.current;
        setItems(itemClone.items || []);
        if (prevItemsRef?.current) {
          prevItemsRef.current = itemClone.items || [];
        }
      }

      const newSelectedItems = uniqBy([...(selectedItems || []), itemClone], 'id');
      setSelectedItems(newSelectedItems);
      if (setInputValue) setInputValue('');
      if (!shouldUseNestedStrategy && submitInstantly && onSubmit) onSubmit(newSelectedItems);
    }
  };

  return (
    <Command.Item
      key={item.id}
      data-id={item.id}
      aria-label={item.label}
      value={Array.isArray(item.searchValues) ? item.searchValues.join(' ') : item.searchValues || item.label}
      disabled={isDisabled}
      style={{ opacity: isDisabled ? 0.5 : 'inherit' }}
      onSelect={onSelect}
      with-sublabel={item.sublabel ? '' : undefined}
    >
      <ComboBoxIcon iconId={item.iconId} />
      {children}
      {item.shortcut && (
        <div cmdk-valstro-shortcuts="">
          {item.shortcut.split(' ').map((key) => {
            return <kbd key={key}>{key}</kbd>;
          })}
        </div>
      )}
    </Command.Item>
  );
};

export const ComboBoxIcon: React.FC<{ iconId?: ComboBoxIconUnion }> = ({ iconId }) => {
  if (!iconId) {
    return null;
  }
  const Icon = comboBoxIconMap[iconId];
  if (Icon) {
    return <Icon />;
  } else {
    return null;
  }
};
