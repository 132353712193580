import { type ParameterQuery, type Strategy, StrategyQuery } from '@valstro/fixatdl-core';
import { STRATEGY_FIELD } from '../utils';
import type {
  FIXATDLddfValues,
  FIXatdlStrategyFormValues
} from '@app/widgets/trading/route-order/fixatdl-strategy-field/fixatdl-strategy-field.contracts';

export const getStrategyFormState = (
  formValues: FIXatdlStrategyFormValues
): { errors: string[]; values: FIXATDLddfValues } => {
  const strategyString = formValues[STRATEGY_FIELD];

  const result: ReturnType<typeof getStrategyFormState> = {
    errors: [],
    values: { controls: {}, parameters: {} }
  };

  if (!strategyString) {
    return result;
  }

  const strategy: Strategy = JSON.parse(String(strategyString)) as Strategy;
  const strategyQuery = StrategyQuery.from(strategy);

  const dateTimeParams = new Map<string, ParameterQuery>(
    strategyQuery
      .parameters()
      .filter((p) => p.isDateTimeFormat())
      .map((p) => [p.parameter.name, p])
  );

  const { values, errors } = strategyQuery.state(formValues);
  delete values[STRATEGY_FIELD];

  Object.entries(values).forEach(([key, val]) => {
    if (dateTimeParams.has(key)) {
      values[key] = (dateTimeParams.get(key) as ParameterQuery).state(val, {
        coercion: { formats: { date: { format: 'iso' }, time: { format: 'iso-date-time' } } }
      }).value as unknown;
    }
  });

  result.values.controls = values;

  strategyQuery.parameters().forEach((p) => {
    if (values[p.parameter.name] !== undefined) {
      result.values.parameters[p.parameter.name] = values[p.parameter.name];
    }
    delete result.values.controls[p.parameter.name];
  });

  result.errors = errors;

  return result;
};
