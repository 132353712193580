// compare with typeahead: apps\oms-app\src\app\forms\common\fields\currency-field\currency.field.queries.ts
import type { CountryFragment } from '@oms/generated/frontend';
import type {
  AdvancedSelectQueryFn,
  AdvancedSelectQueryPayload,
  ComboBoxItem
} from '@oms/frontend-foundation';
import { CountriesService } from '@app/data-access/services/reference-data/countries/countries.service';
import { map } from 'rxjs';

export const watchAllCountriesQuery: AdvancedSelectQueryFn<CountryFragment> = (container) => {
  const countriesService = container.resolve(CountriesService);
  return {
    type: 'watchAll',
    preload: true,
    query: () => {
      return countriesService.watchAll$().pipe(
        map((data) => {
          const comboBoxItems = data?.results?.map((country: CountryFragment) => {
            const comboItem: ComboBoxItem<CountryFragment> = {
              id: country?.id || '',
              label: country?.id || '',
              sublabel: country?.longName || '',
              type: 'item',
              value: country ?? undefined
            };
            return comboItem;
          });
          const shape: AdvancedSelectQueryPayload<CountryFragment> = {
            isFetching: false,
            results: comboBoxItems
          };
          return shape;
        })
      );
    }
  };
};

export default watchAllCountriesQuery;
