import { useCallback } from 'react';
import {
  TradingOrderInvestorOrdersService,
  type TradingOrderInvestorOrderRow
} from '@app/data-access/services/trading/trading-orders/trading-order-investor-orders.service';
import type { VisibleTradingOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { useVGrid, VGrid, type GridBuilder } from '@oms/frontend-vgrid';
import { tradingOrderInvestorOrdersColumnLibrary } from './trading-order-view.investor-orders.columns';
import { OfflineGridEventHandler } from '@app/data-access/services/system/grids/offline-grid.event-handler';

export const TradingOrderInvestorOrdersGrid = ({
  id
}: {
  id: VisibleTradingOrderInfoWithAllocationsFragment['id'];
}) => {
  const toInvestorOrdersService = useService(TradingOrderInvestorOrdersService);

  const builder = useCallback(
    (b: GridBuilder<TradingOrderInvestorOrderRow>) =>
      b
        .columnLibrary(tradingOrderInvestorOrdersColumnLibrary)
        .datasource((d) => d.source(toInvestorOrdersService.watchAll$(id)).rowId((r) => r.data.id))
        .injectEvents([OfflineGridEventHandler])
        .sideBar(),
    [toInvestorOrdersService, id]
  );

  const props = useVGrid('trading-order-investor-orders', builder, [toInvestorOrdersService]);
  return <VGrid {...props} />;
};

export default TradingOrderInvestorOrdersGrid;
