import { useDeepMemo } from '@oms/shared-frontend/ui-design-system';
import { EMPTY, map } from 'rxjs';
import { MarketDataService } from '../marketdata.service';
import { useService } from '@oms/frontend-foundation';
import { useObservableState } from 'observable-hooks';

export const useRxMarketDataSubscription = (ticker: string | undefined) => {
  const marketDataService = useService(MarketDataService);

  const tempSubscription$ = useDeepMemo(() => {
    if (!ticker) {
      return EMPTY;
    }

    return marketDataService.observe(ticker).pipe(map((e) => e.data));
  }, [ticker]);

  return useObservableState(tempSubscription$, undefined);
};
