import * as React from 'react';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useCallbackRef } from './use-callback-ref';

export function isFunction<T extends Function = Function>(value: any): value is T {
  return typeof value === 'function';
}

export function runIfFn<T, U>(valueOrFn: T | ((...fnArgs: U[]) => T), ...args: U[]): T {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}

export function useControllableProp<T>(prop: T | undefined, state: T) {
  const isControlled = prop !== undefined;
  const value = isControlled && typeof prop !== 'undefined' ? prop : state;
  return [isControlled, value] as const;
}

export interface UseControllableStateProps<T> {
  /**
   * The value to used in controlled mode
   */
  value?: T;
  /**
   * The initial value to be used, in uncontrolled mode
   */
  defaultValue?: T | (() => T);
  /**
   * The callback fired when the value changes
   */
  onChange?: (value: T) => void;
  /**
   * The function that determines if the state should be updated
   */
  shouldUpdate?: (prev: T, next: T) => boolean;
  /**
   * A callback applying a format on change
   */
  formatValue?: (value: T) => T;
}

interface Additional<T> {
  rawValue: T;
}

/**
 * React hook for using controlling component state.
 * @param props
 */
export function useControllableState<T>(
  props: UseControllableStateProps<T>
): [value: T, setValue: Dispatch<SetStateAction<T>>, additional: Additional<T>] {
  const {
    value: valueProp,
    defaultValue,
    onChange,
    formatValue,
    shouldUpdate = (prev, next) => prev !== next
  } = props;
  const onChangeProp = useCallbackRef(onChange);
  const shouldUpdateProp = useCallbackRef(shouldUpdate);

  const _defaultValue = useMemo(
    () =>
      formatValue
        ? formatValue(
            typeof defaultValue === 'function'
              ? formatValue((defaultValue as () => T)())
              : (defaultValue as T)
          )
        : defaultValue,
    [formatValue, defaultValue]
  );

  const _valueProp = useMemo(
    () => (formatValue ? formatValue(valueProp) : valueProp),
    [formatValue, valueProp]
  );

  const [valueState, setValue] = React.useState(_defaultValue);
  const [rawValue, setRawValue] = React.useState(_valueProp);

  const isControlled = useMemo(() => typeof _valueProp !== 'undefined', [_valueProp]);

  const value = !isControlled || formatValue ? valueState : _valueProp;

  const valueIn = !isControlled ? valueState : (_valueProp as T);

  useEffect(() => {
    if (isControlled) setValue(_valueProp);
  }, [_valueProp, isControlled, setValue]);

  const updateValue: Dispatch<SetStateAction<T>> = useCallback(
    (next) => {
      const nextValue = runIfFn(next, valueIn);

      if (!shouldUpdateProp(valueIn, nextValue)) {
        return;
      }

      setRawValue(nextValue);

      const formattedValue = formatValue ? formatValue(nextValue) : nextValue;

      if (!isControlled || formatValue) {
        setValue(formattedValue);
      }

      onChangeProp(formattedValue);
    },
    [valueIn, shouldUpdateProp, formatValue, isControlled, onChangeProp]
  );
  return [value, updateValue, { rawValue }];
}
