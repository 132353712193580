import { openRejectInvestorOrder } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import {
  InvestorOrderStatus,
  type VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment
} from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';

export const rejectInvestorOrderOnChange = async (
  ctx: ActionContext<IOFragment, ActionComponentConfig<IOFragment>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];
  const isOnlyOneRowSelected = data.length === 1;

  const isUnacceptedIO =
    isOnlyOneRowSelected &&
    selectedRow &&
    'status' in selectedRow &&
    selectedRow?.status === InvestorOrderStatus.Unaccepted &&
    selectedRow?.__typename === 'VisibleInvestorOrder';

  ctx.notify({ isDisabled: !isUnacceptedIO });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    await openRejectInvestorOrder(PROCESS_ID.LEADER, {
      form: {
        formId: `reject-investor-order-${id}`,
        input: {
          id
        }
      }
    });
  }
};

export const rejectInvestorOrderAction: ActionDefFactory<IOFragment> = (builder) =>
  builder
    .name('reject_order')
    .toolbar((t) =>
      t.component('action-button').id('reject_order_button').location('HorizontalToolbarRight').props({
        isDisabled: true,
        content: 'Reject'
      })
    )
    .menu((m) =>
      m
        .name('Reject')
        .visible(
          ({ rowData }) =>
            rowData?.status === InvestorOrderStatus.Unaccepted &&
            rowData?.__typename === 'VisibleInvestorOrder'
        )
    )
    .onChange<ActionComponentConfig<IOFragment>>(rejectInvestorOrderOnChange);
