import { Center, HStack, Spinner } from '@oms/shared-frontend/ui-design-system';
import { LogoComponent } from '@app/components/logo/logo';

export function SplashScreenContainer() {
  return (
    <Center sx={{ backgroundColor: 'layout.backdrop' }}>
      <HStack spacing={3}>
        <Spinner />
        <LogoComponent />
      </HStack>
    </Center>
  );
}
