import type { ICellRendererParams } from '@ag-grid-community/core';
import { chargeGroupTotal } from '@app/common/types/charges/charges.utils';
import { ChargeGroup, type VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { DisplayGrid, type DisplayGridProps } from '@oms/shared-frontend/ui-design-system';

const gridProps: DisplayGridProps['gridProps'] = {
  rows: 1,
  columns: 1,
  columnGap: 0,
  rowGap: 0
};

export const ChargeFeeTotalCellRenderer: React.FC<
  ICellRendererParams<VisibleInvestorOrderInfoWithAllocationsFragment, any>
> = (params) => {
  if (!params?.data?.compositeCharges?.length) return null;

  const items = chargeGroupTotal(ChargeGroup.Fee, params?.data);

  return <DisplayGrid items={items} gridProps={gridProps} labelSize="small" />;
};

export const ChargeMarketTotalCellRenderer: React.FC<
  ICellRendererParams<VisibleInvestorOrderInfoWithAllocationsFragment, any>
> = (params) => {
  if (!params?.data?.compositeCharges?.length) return null;

  const items = chargeGroupTotal(ChargeGroup.Market, params?.data);

  return <DisplayGrid items={items} gridProps={gridProps} labelSize="small" />;
};
