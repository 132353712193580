import { comboBoxItemsFrom } from '@oms/frontend-foundation';
import { FieldContract } from '@oms/frontend-foundation';
import {
  createGTDTimestampField,
  createTIFField,
  createTIFFields,
  createTifDurationField
} from '@app/forms/common/fields/TIF-field/TIF-field.common';
import { createVenueField } from '@app/forms/common/fields/venue-field/venue.field.common';
import type { MontagePreferencesFieldContract } from './montage-preferences.types';
import { OrderSideType, PriceType, QuantityType } from '@oms/generated/frontend';
import { getOrderSideTypeLabel, getPriceTypeLabel, getQuantityLabel } from './montage-preferences.util';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
const msfc = FieldContract.create<MontagePreferencesFieldContract, AdditionalFormFieldUnion>();

type BidAskField = Parameters<typeof msfc.field>[0];

const makeInitiateOrder = (fieldName: BidAskField) =>
  msfc.field(fieldName, 'checkbox').options({
    label: 'Initiate order'
  });

const makeSideType = (fieldName: BidAskField) =>
  msfc.field(fieldName, 'select').options({
    label: 'Side',
    options: comboBoxItemsFrom.enum(OrderSideType, {
      label: getOrderSideTypeLabel
    })
  });

const makeOrderSize = (fieldName: BidAskField) =>
  msfc.field(fieldName, 'advanced-select').options({
    label: 'Order size',
    isDisabled: true,
    validate: [{ type: 'required' }]
  });

const makeQuantity = (fieldName: BidAskField) =>
  msfc.field(fieldName, 'select').options({
    label: 'Quantity',
    options: comboBoxItemsFrom.enum(QuantityType, {
      label: getQuantityLabel
    })
  });

const makeTimeInForce = (fieldName: BidAskField) => createTIFField(msfc, fieldName);

export const bidTifFields = createTIFFields(msfc, 'bidTimeInForceType', { label: 'TIF' });
export const askTifFields = createTIFFields(msfc, 'askTimeInForceType', { label: 'TIF' });

const makeGtdTimestamp = (fieldName: BidAskField) => (conditionalField: string) =>
  createGTDTimestampField(msfc, fieldName, conditionalField);

const makeTifDuration = (fieldName: BidAskField) => (conditionalField: string) =>
  createTifDurationField(msfc, fieldName, conditionalField);

export const makeQuantityValue = (fieldName: BidAskField) =>
  msfc.field(fieldName, 'number-format').options({
    label: 'Quantity value',
    format: 'number',
    validate: [{ type: 'required' }]
  });

const makePrice = (fieldName: BidAskField) =>
  msfc.field(fieldName, 'select').options({
    label: 'Price',
    options: comboBoxItemsFrom
      .enum(PriceType, {
        label: getPriceTypeLabel
      })
      .filter(({ value }) => value !== PriceType.Blank)
  });

const makeDisplaySize = (fieldName: BidAskField) =>
  msfc.field(fieldName, 'number-format').options({
    label: 'Display size',
    format: 'number'
  });

const makeDestinationId = (fieldName: BidAskField) => createVenueField(msfc, fieldName);

export const makeStrategy = (fieldName: BidAskField) => (venueId: BidAskField) =>
  msfc.field(fieldName, 'fixatdl-strategy').options({
    label: 'Strategy',
    venueIdKey: venueId,
    strategyFieldKey: fieldName,
    isDialogEnabled: false
  });

// TODO: used text-field until fixatdl-strategy is migrated
const makeStrategyParameters = (fieldName: BidAskField) =>
  msfc.field(fieldName, 'text-field').options({
    label: 'Strategy presets'
  });

export const hideOddLots = msfc.field('hideOddLots', 'checkbox').options({
  label: 'Hide odd lots'
});

export const displayQuotesInShares = msfc.field('displayQuotesInShares', 'checkbox').options({
  label: 'Display quotes in shares'
});

export const sendAttributable = msfc.field('sendAttributable', 'checkbox').options({
  label: 'Send Attributable'
});

export const bidInitiateOrder = makeInitiateOrder('bidInitiateOrder');
export const askInitiateOrder = makeInitiateOrder('askInitiateOrder');

export const bidSideType = makeSideType('bidSideType');
export const askSideType = makeSideType('askSideType');

export const bidQuantityType = makeQuantity('bidQuantityType');
export const askQuantityType = makeQuantity('askQuantityType');

export const bidTimeInForceType = makeTimeInForce('bidTimeInForceType');
export const askTimeInForceType = makeTimeInForce('askTimeInForceType');

export const bidGtdTimestamp = makeGtdTimestamp('bidGtdTimestamp')(bidTimeInForceType.name);
export const askGtdTimestamp = makeGtdTimestamp('askGtdTimestamp')(askTimeInForceType.name);

export const bidTifDuration = makeTifDuration('bidTifDuration')(bidTimeInForceType.name);
export const askTifDuration = makeTifDuration('askTifDuration')(askTimeInForceType.name);

export const bidQuantityValue = makeQuantityValue('bidQuantityValue');
export const askQuantityValue = makeQuantityValue('askQuantityValue');

export const bidOrderSize = makeOrderSize('bidOrderSize');
export const askOrderSize = makeOrderSize('askOrderSize');

export const bidPriceType = makePrice('bidPriceType');
export const askPriceType = makePrice('askPriceType');

export const bidDisplaySize = makeDisplaySize('bidDisplaySize');
export const askDisplaySize = makeDisplaySize('askDisplaySize');

export const bidDestinationId = makeDestinationId('bidDestinationId');
export const askDestinationId = makeDestinationId('askDestinationId');

export const bidStrategy = makeStrategy('bidStrategy')(bidDestinationId.name);
export const askStrategy = makeStrategy('askStrategy')(askDestinationId.name);

export const bidStrategyParameters = makeStrategyParameters('bidStrategyParameters');
export const askStrategyParameters = makeStrategyParameters('askStrategyParameters');
