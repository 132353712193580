import { type PositionsTreeForAccountFragment } from '@oms/generated/frontend';

export const testTrees: PositionsTreeForAccountFragment[] = [
  {
    accountsForEntity: {
      id: '1772b1d9-ae78-42e6-a819-48f025139a9a',
      name: 'dev account 1',
      accounts: [],
      children: [
        {
          id: '0afcffc0-f8f1-4a6d-bf28-38433df57f05',
          name: 'dev account 1.1',
          accounts: [
            {
              id: 'c648b404-fc15-44bd-b6b0-d4e87f9639cf',
              name: 'POD_A_FAC',
              accumulationCurrency: 'USD',
              accountType: 'FIRM',
              position: {
                longPosition: 26,
                shortPosition: 0,
                netPosition: 26,
                aggregationFXRate: null,
                accountCurrency: 'USD',
                longExposure: 117,
                shortExposure: 0,
                netExposure: 117,
                combinedExposure: 117,
                unrealizedPNL: -1885,
                realizedPNL: 0,
                totalPNLToday: -1885,
                monthToDatePNL: 0,
                previousMonthPNL: 0,
                quarterToDatePNL: 0,
                previousQuarterPNL: 0,
                yearToDatePNL: 0,
                previousYearPNL: 0,
                longPositionCost: 2002,
                shortPositionCost: 0,
                netPositionCost: 2002,
                combinedPositionCost: 2002,
                __typename: 'AccountRollUp'
              },
              __typename: 'PositionGroupingAccount'
            }
          ],
          children: [
            {
              id: 'cd3aee6f-1104-43ef-8421-d9bce7459490',
              name: 'dev account 1.1.1',
              accounts: [
                {
                  id: 'eecb7015-a982-4b93-8b33-973e65d4cb02',
                  name: 'POD_C_FAC',
                  accumulationCurrency: 'USD',
                  accountType: 'FIRM',
                  position: {
                    longPosition: 0,
                    shortPosition: 0,
                    netPosition: 0,
                    aggregationFXRate: 1,
                    accountCurrency: 'USD',
                    longExposure: 0,
                    shortExposure: 0,
                    netExposure: 0,
                    combinedExposure: 0,
                    unrealizedPNL: 0,
                    realizedPNL: 0,
                    totalPNLToday: 0,
                    monthToDatePNL: 0,
                    previousMonthPNL: 0,
                    quarterToDatePNL: 0,
                    previousQuarterPNL: 0,
                    yearToDatePNL: 0,
                    previousYearPNL: 0,
                    longPositionCost: 0,
                    shortPositionCost: 0,
                    netPositionCost: 0,
                    combinedPositionCost: 0,
                    __typename: 'AccountRollUp'
                  },
                  __typename: 'PositionGroupingAccount'
                }
              ],
              position: {
                longPosition: 0,
                shortPosition: 0,
                netPosition: 0,
                aggregationFXRate: 1,
                accountCurrency: 'USD',
                longExposure: 0,
                shortExposure: 0,
                netExposure: 0,
                combinedExposure: 0,
                unrealizedPNL: 0,
                realizedPNL: 0,
                totalPNLToday: 0,
                monthToDatePNL: 0,
                previousMonthPNL: 0,
                quarterToDatePNL: 0,
                previousQuarterPNL: 0,
                yearToDatePNL: 0,
                previousYearPNL: 0,
                longPositionCost: 0,
                shortPositionCost: 0,
                netPositionCost: 0,
                combinedPositionCost: 0,
                __typename: 'AccountRollUp'
              },
              __typename: 'PositionGroupingAccount',
              children: []
            }
          ],
          parent: null,
          position: {
            longPosition: 26,
            shortPosition: 0,
            netPosition: 26,
            aggregationFXRate: 1,
            accountCurrency: 'USD',
            longExposure: 117,
            shortExposure: 0,
            netExposure: 117,
            combinedExposure: 117,
            unrealizedPNL: -1885,
            realizedPNL: 0,
            totalPNLToday: -1885,
            monthToDatePNL: 0,
            previousMonthPNL: 0,
            quarterToDatePNL: 0,
            previousQuarterPNL: 0,
            yearToDatePNL: 0,
            previousYearPNL: 0,
            longPositionCost: 2002,
            shortPositionCost: 0,
            netPositionCost: 2002,
            combinedPositionCost: 2002,
            __typename: 'AccountRollUp'
          },
          __typename: 'PositionGroupingAccount'
        },
        {
          id: '88abacad-6a10-4406-b6b3-521476a6ae27',
          name: 'dev account 1.2',
          accounts: [
            {
              id: '05a632ca-72c6-4c29-b4a7-4055ff03b835',
              name: 'POD_B_FAC',
              accumulationCurrency: 'USD',
              accountType: 'FIRM',
              position: {
                longPosition: 0,
                shortPosition: 0,
                netPosition: 0,
                aggregationFXRate: 1,
                accountCurrency: 'USD',
                longExposure: 0,
                shortExposure: 0,
                netExposure: 0,
                combinedExposure: 0,
                unrealizedPNL: 0,
                realizedPNL: 0,
                totalPNLToday: 0,
                monthToDatePNL: 0,
                previousMonthPNL: 0,
                quarterToDatePNL: 0,
                previousQuarterPNL: 0,
                yearToDatePNL: 0,
                previousYearPNL: 0,
                longPositionCost: 0,
                shortPositionCost: 0,
                netPositionCost: 0,
                combinedPositionCost: 0,
                __typename: 'AccountRollUp'
              },
              __typename: 'PositionGroupingAccount'
            }
          ],
          children: [],
          parent: null,
          position: {
            longPosition: 0,
            shortPosition: 0,
            netPosition: 0,
            aggregationFXRate: 1,
            accountCurrency: 'USD',
            longExposure: 0,
            shortExposure: 0,
            netExposure: 0,
            combinedExposure: 0,
            unrealizedPNL: 0,
            realizedPNL: 0,
            totalPNLToday: 0,
            monthToDatePNL: 0,
            previousMonthPNL: 0,
            quarterToDatePNL: 0,
            previousQuarterPNL: 0,
            yearToDatePNL: 0,
            previousYearPNL: 0,
            longPositionCost: 0,
            shortPositionCost: 0,
            netPositionCost: 0,
            combinedPositionCost: 0,
            __typename: 'AccountRollUp'
          },
          __typename: 'PositionGroupingAccount'
        }
      ],
      parent: null,
      position: {
        longPosition: 26,
        shortPosition: 0,
        netPosition: 26,
        aggregationFXRate: 1,
        accountCurrency: 'USD',
        longExposure: 117,
        shortExposure: 0,
        netExposure: 117,
        combinedExposure: 117,
        unrealizedPNL: -1885,
        realizedPNL: 0,
        totalPNLToday: -1885,
        monthToDatePNL: 0,
        previousMonthPNL: 0,
        quarterToDatePNL: 0,
        previousQuarterPNL: 0,
        yearToDatePNL: 0,
        previousYearPNL: 0,
        longPositionCost: 2002,
        shortPositionCost: 0,
        netPositionCost: 2002,
        combinedPositionCost: 2002,
        __typename: 'AccountRollUp'
      },
      __typename: 'PositionGroupingAccount'
    },
    accountPositions: [
      {
        accountId: 'c648b404-fc15-44bd-b6b0-d4e87f9639cf',
        accountName: 'POD_A_FAC',
        positions: [
          {
            id: '4e79dc81-da95-52fe-a2c7-065e4c2ed4ed',
            account: {
              id: 'c648b404-fc15-44bd-b6b0-d4e87f9639cf',
              name: 'POD_A_FAC',
              __typename: 'InvestorAccount'
            },
            averagePrice: 77,
            instrument: {
              id: 'BBG000BQPC32',
              active: true,
              longName: 'Ford Motor Company',
              mappings: {
                displayCode: 'F-US',
                __typename: 'InstrumentMapping'
              },
              __typename: 'InstrumentDetails'
            },
            unrealizedPNL: -1885,
            realizedPNLToday: 0,
            totalPNLToday: -1885,
            realizedPNL: 0,
            trueAveragePrice: 77,
            valuationPrice: 4.5,
            positionValuationStrategy: 'USMarketLastTrade',
            lastMarkedDateTime: new Date('2023-10-16T15:03:15.157Z'),
            exposure: 117,
            cost: 2002,
            monthToDatePNL: 0,
            previousMonthPNL: 0,
            quarterToDatePNL: 0,
            previousQuarterPNL: 0,
            yearToDatePNL: 0,
            previousYearPNL: 0,
            estimatedCommission: null,
            quantity: 26,
            currency: {
              id: 'USD',
              longName: 'U.S. Dollar',
              __typename: 'Currency'
            },
            accountAccumulationFxRate: 1,
            manualMark: null,
            __typename: 'Position'
          }
        ],
        __typename: 'PositionsTreeForAccountPos'
      }
    ],
    accountIdsAndNames: [
      {
        accountId: 'eecb7015-a982-4b93-8b33-973e65d4cb02',
        accountName: 'POD_C_FAC',
        __typename: 'AccountIdsAndNames'
      },
      {
        accountId: 'c648b404-fc15-44bd-b6b0-d4e87f9639cf',
        accountName: 'POD_A_FAC',
        __typename: 'AccountIdsAndNames'
      },
      {
        accountId: '05a632ca-72c6-4c29-b4a7-4055ff03b835',
        accountName: 'POD_B_FAC',
        __typename: 'AccountIdsAndNames'
      }
    ],
    __typename: 'PositionsTreeForAccount'
  },
  {
    accountsForEntity: {
      id: 'd0aa2256-3b6a-4a40-a074-9552939c7daa',
      name: 'FIRMENTITY',
      accounts: [],
      children: [
        {
          id: 'dcbc037f-ebef-4fc6-a681-0b8f08b0c3a0',
          name: 'OUTSOURCE',
          accounts: [
            {
              id: '1c42e567-91c2-46b5-8af7-e206fa24a901',
              name: 'OUTS_TRD',
              accumulationCurrency: 'USD',
              accountType: 'FIRM',
              position: {
                longPosition: 0,
                shortPosition: 0,
                netPosition: 0,
                aggregationFXRate: 1,
                accountCurrency: 'USD',
                longExposure: 0,
                shortExposure: 0,
                netExposure: 0,
                combinedExposure: 0,
                unrealizedPNL: 0,
                realizedPNL: 0,
                totalPNLToday: 0,
                monthToDatePNL: 0,
                previousMonthPNL: 0,
                quarterToDatePNL: 0,
                previousQuarterPNL: 0,
                yearToDatePNL: 0,
                previousYearPNL: 0,
                longPositionCost: 0,
                shortPositionCost: 0,
                netPositionCost: 0,
                combinedPositionCost: 0,
                __typename: 'AccountRollUp'
              },
              __typename: 'PositionGroupingAccount'
            }
          ],
          children: [],
          parent: null,
          position: {
            longPosition: 0,
            shortPosition: 0,
            netPosition: 0,
            aggregationFXRate: 1,
            accountCurrency: 'USD',
            longExposure: 0,
            shortExposure: 0,
            netExposure: 0,
            combinedExposure: 0,
            unrealizedPNL: 0,
            realizedPNL: 0,
            totalPNLToday: 0,
            monthToDatePNL: 0,
            previousMonthPNL: 0,
            quarterToDatePNL: 0,
            previousQuarterPNL: 0,
            yearToDatePNL: 0,
            previousYearPNL: 0,
            longPositionCost: 0,
            shortPositionCost: 0,
            netPositionCost: 0,
            combinedPositionCost: 0,
            __typename: 'AccountRollUp'
          },
          __typename: 'PositionGroupingAccount'
        },
        {
          id: '7dc37ed8-8cb2-437b-90d9-2d62e330f7ee',
          name: 'FIRMRISK',
          accounts: [
            {
              id: '3dde1ede-aecf-4227-9179-66c54d8ff3f2',
              name: 'NY_ERR',
              accumulationCurrency: 'USD',
              accountType: 'FIRM',
              position: {
                longPosition: 0,
                shortPosition: 0,
                netPosition: 0,
                aggregationFXRate: 1,
                accountCurrency: 'USD',
                longExposure: 0,
                shortExposure: 0,
                netExposure: 0,
                combinedExposure: 0,
                unrealizedPNL: 0,
                realizedPNL: 0,
                totalPNLToday: 0,
                monthToDatePNL: 0,
                previousMonthPNL: 0,
                quarterToDatePNL: 0,
                previousQuarterPNL: 0,
                yearToDatePNL: 0,
                previousYearPNL: 0,
                longPositionCost: 0,
                shortPositionCost: 0,
                netPositionCost: 0,
                combinedPositionCost: 0,
                __typename: 'AccountRollUp'
              },
              __typename: 'PositionGroupingAccount'
            }
          ],
          children: [
            {
              id: '309851f2-4de5-4912-9ced-e6d3284aaa68',
              name: 'NY_FAC',
              accounts: [
                {
                  id: '6d605af8-e6de-4a31-928b-1f223760f033',
                  name: 'SSF_FAC',
                  accumulationCurrency: 'USD',
                  accountType: 'FIRM',
                  position: {
                    longPosition: 0,
                    shortPosition: 0,
                    netPosition: 0,
                    aggregationFXRate: 1,
                    accountCurrency: 'USD',
                    longExposure: 0,
                    shortExposure: 0,
                    netExposure: 0,
                    combinedExposure: 0,
                    unrealizedPNL: 0,
                    realizedPNL: 0,
                    totalPNLToday: 0,
                    monthToDatePNL: 0,
                    previousMonthPNL: 0,
                    quarterToDatePNL: 0,
                    previousQuarterPNL: 0,
                    yearToDatePNL: 0,
                    previousYearPNL: 0,
                    longPositionCost: 0,
                    shortPositionCost: 0,
                    netPositionCost: 0,
                    combinedPositionCost: 0,
                    __typename: 'AccountRollUp'
                  },
                  __typename: 'PositionGroupingAccount'
                }
              ],
              position: {
                longPosition: 0,
                shortPosition: 0,
                netPosition: 0,
                aggregationFXRate: 1,
                accountCurrency: 'USD',
                longExposure: 0,
                shortExposure: 0,
                netExposure: 0,
                combinedExposure: 0,
                unrealizedPNL: 0,
                realizedPNL: 0,
                totalPNLToday: 0,
                monthToDatePNL: 0,
                previousMonthPNL: 0,
                quarterToDatePNL: 0,
                previousQuarterPNL: 0,
                yearToDatePNL: 0,
                previousYearPNL: 0,
                longPositionCost: 0,
                shortPositionCost: 0,
                netPositionCost: 0,
                combinedPositionCost: 0,
                __typename: 'AccountRollUp'
              },
              __typename: 'PositionGroupingAccount',
              children: []
            },
            {
              id: '555e20da-35d3-4b48-902a-6d0fb4e7a752',
              name: 'NY_TRD',
              accounts: [
                {
                  id: 'bbb3db3a-0c1d-4cc5-a2dd-0746df76a0be',
                  name: 'METALS_TRD',
                  accumulationCurrency: 'USD',
                  accountType: 'FIRM',
                  position: {
                    longPosition: 27,
                    shortPosition: 0,
                    netPosition: 27,
                    aggregationFXRate: null,
                    accountCurrency: 'USD',
                    longExposure: 114.075,
                    shortExposure: 0,
                    netExposure: 114.075,
                    combinedExposure: 114.075,
                    unrealizedPNL: -2126.925,
                    realizedPNL: 0,
                    totalPNLToday: -2126.925,
                    monthToDatePNL: 0,
                    previousMonthPNL: 0,
                    quarterToDatePNL: 0,
                    previousQuarterPNL: 0,
                    yearToDatePNL: 0,
                    previousYearPNL: 0,
                    longPositionCost: 2241,
                    shortPositionCost: 0,
                    netPositionCost: 2241,
                    combinedPositionCost: 2241,
                    __typename: 'AccountRollUp'
                  },
                  __typename: 'PositionGroupingAccount'
                },
                {
                  id: '5bc5fa06-fbda-4519-b8f8-f0722ffa352c',
                  name: 'TECH_TRD',
                  accumulationCurrency: 'USD',
                  accountType: 'FIRM',
                  position: {
                    longPosition: 0,
                    shortPosition: 0,
                    netPosition: 0,
                    aggregationFXRate: 1,
                    accountCurrency: 'USD',
                    longExposure: 0,
                    shortExposure: 0,
                    netExposure: 0,
                    combinedExposure: 0,
                    unrealizedPNL: 0,
                    realizedPNL: 0,
                    totalPNLToday: 0,
                    monthToDatePNL: 0,
                    previousMonthPNL: 0,
                    quarterToDatePNL: 0,
                    previousQuarterPNL: 0,
                    yearToDatePNL: 0,
                    previousYearPNL: 0,
                    longPositionCost: 0,
                    shortPositionCost: 0,
                    netPositionCost: 0,
                    combinedPositionCost: 0,
                    __typename: 'AccountRollUp'
                  },
                  __typename: 'PositionGroupingAccount'
                }
              ],
              position: {
                longPosition: 50,
                shortPosition: 0,
                netPosition: 50,
                aggregationFXRate: 1,
                accountCurrency: 'USD',
                longExposure: 374.325,
                shortExposure: 0,
                netExposure: 374.325,
                combinedExposure: 374.325,
                unrealizedPNL: -3452,
                realizedPNL: 0,
                totalPNLToday: -3452,
                monthToDatePNL: 0,
                previousMonthPNL: 0,
                quarterToDatePNL: 0,
                previousQuarterPNL: 0,
                yearToDatePNL: 0,
                previousYearPNL: 0,
                longPositionCost: 3826,
                shortPositionCost: 0,
                netPositionCost: 3826,
                combinedPositionCost: 3826,
                __typename: 'AccountRollUp'
              },
              __typename: 'PositionGroupingAccount',
              children: [
                {
                  id: '3f9b92de-2d4b-4ddb-86e8-1fa4586c02ce',
                  name: 'NY_CASH',
                  accounts: [
                    {
                      id: '6ac29293-2563-4f87-8557-d047c846a2ec',
                      name: 'SS_FAC',
                      accumulationCurrency: 'USD',
                      accountType: 'FIRM',
                      position: {
                        longPosition: 0,
                        shortPosition: 0,
                        netPosition: 0,
                        aggregationFXRate: 1,
                        accountCurrency: 'USD',
                        longExposure: 0,
                        shortExposure: 0,
                        netExposure: 0,
                        combinedExposure: 0,
                        unrealizedPNL: 0,
                        realizedPNL: 0,
                        totalPNLToday: 0,
                        monthToDatePNL: 0,
                        previousMonthPNL: 0,
                        quarterToDatePNL: 0,
                        previousQuarterPNL: 0,
                        yearToDatePNL: 0,
                        previousYearPNL: 0,
                        longPositionCost: 0,
                        shortPositionCost: 0,
                        netPositionCost: 0,
                        combinedPositionCost: 0,
                        __typename: 'AccountRollUp'
                      },
                      __typename: 'PositionGroupingAccount'
                    }
                  ],
                  position: {
                    longPosition: 0,
                    shortPosition: 0,
                    netPosition: 0,
                    aggregationFXRate: 1,
                    accountCurrency: 'USD',
                    longExposure: 0,
                    shortExposure: 0,
                    netExposure: 0,
                    combinedExposure: 0,
                    unrealizedPNL: 0,
                    realizedPNL: 0,
                    totalPNLToday: 0,
                    monthToDatePNL: 0,
                    previousMonthPNL: 0,
                    quarterToDatePNL: 0,
                    previousQuarterPNL: 0,
                    yearToDatePNL: 0,
                    previousYearPNL: 0,
                    longPositionCost: 0,
                    shortPositionCost: 0,
                    netPositionCost: 0,
                    combinedPositionCost: 0,
                    __typename: 'AccountRollUp'
                  },
                  __typename: 'PositionGroupingAccount',
                  children: []
                },
                {
                  id: '6ccef269-648f-4355-bba3-cb019ef58bb4',
                  name: 'SEC_TRD',
                  accounts: [
                    {
                      id: 'a8bc730d-d32f-410a-bd5b-5d6d609b770a',
                      name: 'HEAD_TRD',
                      accumulationCurrency: 'USD',
                      accountType: 'FIRM',
                      position: {
                        longPosition: 23,
                        shortPosition: 0,
                        netPosition: 23,
                        aggregationFXRate: null,
                        accountCurrency: 'USD',
                        longExposure: 260.25,
                        shortExposure: 0,
                        netExposure: 260.25,
                        combinedExposure: 260.25,
                        unrealizedPNL: -1324.75,
                        realizedPNL: 0,
                        totalPNLToday: -1324.75,
                        monthToDatePNL: 0,
                        previousMonthPNL: 0,
                        quarterToDatePNL: 0,
                        previousQuarterPNL: 0,
                        yearToDatePNL: 0,
                        previousYearPNL: 0,
                        longPositionCost: 1585,
                        shortPositionCost: 0,
                        netPositionCost: 1585,
                        combinedPositionCost: 1585,
                        __typename: 'AccountRollUp'
                      },
                      __typename: 'PositionGroupingAccount'
                    }
                  ],
                  position: {
                    longPosition: 23,
                    shortPosition: 0,
                    netPosition: 23,
                    aggregationFXRate: 1,
                    accountCurrency: 'USD',
                    longExposure: 260.25,
                    shortExposure: 0,
                    netExposure: 260.25,
                    combinedExposure: 260.25,
                    unrealizedPNL: -1325,
                    realizedPNL: 0,
                    totalPNLToday: -1325,
                    monthToDatePNL: 0,
                    previousMonthPNL: 0,
                    quarterToDatePNL: 0,
                    previousQuarterPNL: 0,
                    yearToDatePNL: 0,
                    previousYearPNL: 0,
                    longPositionCost: 1585,
                    shortPositionCost: 0,
                    netPositionCost: 1585,
                    combinedPositionCost: 1585,
                    __typename: 'AccountRollUp'
                  },
                  __typename: 'PositionGroupingAccount',
                  children: []
                }
              ]
            }
          ],
          parent: null,
          position: {
            longPosition: 50,
            shortPosition: 0,
            netPosition: 50,
            aggregationFXRate: 1,
            accountCurrency: 'USD',
            longExposure: 374.325,
            shortExposure: 0,
            netExposure: 374.325,
            combinedExposure: 374.325,
            unrealizedPNL: -3452,
            realizedPNL: 0,
            totalPNLToday: -3452,
            monthToDatePNL: 0,
            previousMonthPNL: 0,
            quarterToDatePNL: 0,
            previousQuarterPNL: 0,
            yearToDatePNL: 0,
            previousYearPNL: 0,
            longPositionCost: 3826,
            shortPositionCost: 0,
            netPositionCost: 3826,
            combinedPositionCost: 3826,
            __typename: 'AccountRollUp'
          },
          __typename: 'PositionGroupingAccount'
        }
      ],
      parent: null,
      position: {
        longPosition: 50,
        shortPosition: 0,
        netPosition: 50,
        aggregationFXRate: 1,
        accountCurrency: 'USD',
        longExposure: 374.325,
        shortExposure: 0,
        netExposure: 374.325,
        combinedExposure: 374.325,
        unrealizedPNL: -3452,
        realizedPNL: 0,
        totalPNLToday: -3452,
        monthToDatePNL: 0,
        previousMonthPNL: 0,
        quarterToDatePNL: 0,
        previousQuarterPNL: 0,
        yearToDatePNL: 0,
        previousYearPNL: 0,
        longPositionCost: 3826,
        shortPositionCost: 0,
        netPositionCost: 3826,
        combinedPositionCost: 3826,
        __typename: 'AccountRollUp'
      },
      __typename: 'PositionGroupingAccount'
    },
    accountPositions: [
      {
        accountId: 'a8bc730d-d32f-410a-bd5b-5d6d609b770a',
        accountName: 'HEAD_TRD',
        positions: [
          {
            id: '844bffac-65d6-5877-81ad-b90fd257c06a',
            account: {
              id: 'a8bc730d-d32f-410a-bd5b-5d6d609b770a',
              name: 'HEAD_TRD',
              __typename: 'InvestorAccount'
            },
            averagePrice: 100,
            instrument: {
              id: 'BBG000BWXBC2',
              active: true,
              longName: 'Walmart Inc.',
              mappings: {
                displayCode: 'WMT-US',
                __typename: 'InstrumentMapping'
              },
              __typename: 'InstrumentDetails'
            },
            unrealizedPNL: -886,
            realizedPNLToday: 0,
            totalPNLToday: -886,
            realizedPNL: 0,
            trueAveragePrice: 100,
            valuationPrice: 11.4,
            positionValuationStrategy: 'USMarketLastTrade',
            lastMarkedDateTime: new Date('2023-10-16T15:03:15.160Z'),
            exposure: 114,
            cost: 1000,
            monthToDatePNL: 0,
            previousMonthPNL: 0,
            quarterToDatePNL: 0,
            previousQuarterPNL: 0,
            yearToDatePNL: 0,
            previousYearPNL: 0,
            estimatedCommission: null,
            quantity: 10,
            currency: {
              id: 'USD',
              longName: 'U.S. Dollar',
              __typename: 'Currency'
            },
            accountAccumulationFxRate: 1,
            manualMark: null,
            __typename: 'Position'
          },
          {
            id: 'fe7ca046-97f0-5f49-840d-a8796cf75e4e',
            account: {
              id: 'a8bc730d-d32f-410a-bd5b-5d6d609b770a',
              name: 'HEAD_TRD',
              __typename: 'InvestorAccount'
            },
            averagePrice: 45,
            instrument: {
              id: 'BBG000DMBXR2',
              active: true,
              longName: 'JPMorgan Chase & Co.',
              mappings: {
                displayCode: 'JPM-US',
                __typename: 'InstrumentMapping'
              },
              __typename: 'InstrumentDetails'
            },
            unrealizedPNL: -439,
            realizedPNLToday: 0,
            totalPNLToday: -439,
            realizedPNL: 0,
            trueAveragePrice: 45,
            valuationPrice: 11.25,
            positionValuationStrategy: 'USMarketLastTrade',
            lastMarkedDateTime: new Date('2023-10-16T15:03:15.160Z'),
            exposure: 146.25,
            cost: 585,
            monthToDatePNL: 0,
            previousMonthPNL: 0,
            quarterToDatePNL: 0,
            previousQuarterPNL: 0,
            yearToDatePNL: 0,
            previousYearPNL: 0,
            estimatedCommission: null,
            quantity: 13,
            currency: {
              id: 'USD',
              longName: 'U.S. Dollar',
              __typename: 'Currency'
            },
            accountAccumulationFxRate: 1,
            manualMark: null,
            __typename: 'Position'
          }
        ],
        __typename: 'PositionsTreeForAccountPos'
      },
      {
        accountId: 'bbb3db3a-0c1d-4cc5-a2dd-0746df76a0be',
        accountName: 'METALS_TRD',
        positions: [
          {
            id: '7b473e8b-613b-5c5f-ae45-cdc8ba903301',
            account: {
              id: 'bbb3db3a-0c1d-4cc5-a2dd-0746df76a0be',
              name: 'METALS_TRD',
              __typename: 'InvestorAccount'
            },
            averagePrice: 83,
            instrument: {
              id: 'BBG000BSJK37',
              active: true,
              longName: 'AT&T Inc.',
              mappings: {
                displayCode: 'T-US',
                __typename: 'InstrumentMapping'
              },
              __typename: 'InstrumentDetails'
            },
            unrealizedPNL: -2127,
            realizedPNLToday: 0,
            totalPNLToday: -2127,
            realizedPNL: 0,
            trueAveragePrice: 83,
            valuationPrice: 4.225,
            positionValuationStrategy: 'USMarketLastTrade',
            lastMarkedDateTime: new Date('2023-10-16T15:03:15.160Z'),
            exposure: 114.075,
            cost: 2241,
            monthToDatePNL: 0,
            previousMonthPNL: 0,
            quarterToDatePNL: 0,
            previousQuarterPNL: 0,
            yearToDatePNL: 0,
            previousYearPNL: 0,
            estimatedCommission: null,
            quantity: 27,
            currency: {
              id: 'USD',
              longName: 'U.S. Dollar',
              __typename: 'Currency'
            },
            accountAccumulationFxRate: 1,
            manualMark: null,
            __typename: 'Position'
          }
        ],
        __typename: 'PositionsTreeForAccountPos'
      }
    ],
    accountIdsAndNames: [
      {
        accountId: '1c42e567-91c2-46b5-8af7-e206fa24a901',
        accountName: 'OUTS_TRD',
        __typename: 'AccountIdsAndNames'
      },
      {
        accountId: '6d605af8-e6de-4a31-928b-1f223760f033',
        accountName: 'SSF_FAC',
        __typename: 'AccountIdsAndNames'
      },
      {
        accountId: '6ac29293-2563-4f87-8557-d047c846a2ec',
        accountName: 'SS_FAC',
        __typename: 'AccountIdsAndNames'
      },
      {
        accountId: 'a8bc730d-d32f-410a-bd5b-5d6d609b770a',
        accountName: 'HEAD_TRD',
        __typename: 'AccountIdsAndNames'
      },
      {
        accountId: 'bbb3db3a-0c1d-4cc5-a2dd-0746df76a0be',
        accountName: 'METALS_TRD',
        __typename: 'AccountIdsAndNames'
      },
      {
        accountId: '5bc5fa06-fbda-4519-b8f8-f0722ffa352c',
        accountName: 'TECH_TRD',
        __typename: 'AccountIdsAndNames'
      },
      {
        accountId: '3dde1ede-aecf-4227-9179-66c54d8ff3f2',
        accountName: 'NY_ERR',
        __typename: 'AccountIdsAndNames'
      }
    ],
    __typename: 'PositionsTreeForAccount'
  }
];
