import { inject, singleton } from 'tsyringe';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import {
  type GetExecutionByIdQuery,
  type GetExecutionByIdQueryVariables,
  GetExecutionByIdDocument,
  type CancelExecutionsMutationVariables,
  CancelExecutionsDocument,
  type CancelExecutionsMutation,
  type GetExecutionsByOrderIdQuery,
  type GetExecutionsByOrderIdQueryVariables,
  GetExecutionsByOrderIdDocument,
  type AllocationMap,
  type ExecutionInfoFragment,
  CancelExecutionsAccountDefaultsDocument,
  type CancelExecutionsAccountDefaultsQuery,
  type CancelExecutionsAccountDefaultsQueryVariables,
  type SettlementFxRateMutation,
  type SettlementFxRateMutationVariables,
  SettlementFxRateDocument,
  type SettlementFxRateInput
} from '@oms/generated/frontend';
import { map } from 'rxjs';

@singleton()
export class InvestorOrderExecutionsService {
  constructor(
    @inject(ApolloClientRPC) private apolloClient: ApolloClientRPC,
    @inject(GQLResponse) private gqlResponse: GQLResponse
  ) {}

  public getById(executionId: string) {
    return this.gqlResponse
      .wrapQuery<GetExecutionByIdQuery, GetExecutionByIdQueryVariables>({
        query: GetExecutionByIdDocument,
        variables: {
          id: executionId
        }
      })
      .exec();
  }

  public async getDefaultAccounts(userId?: string, instrumentId?: string) {
    return await this.apolloClient
      .query<CancelExecutionsAccountDefaultsQuery, CancelExecutionsAccountDefaultsQueryVariables>({
        query: CancelExecutionsAccountDefaultsDocument,
        fetchPolicy: 'no-cache',
        variables: { userId: userId, instrumentId: instrumentId }
      })
      .then((res) => {
        const userDefaultsFirmAccount = res.data.getUserDefaults?.firmAccount;
        const instrumentCoverageFirmAccount = res.data.getInstrumentCoverage?.defaultFirmAccount;

        const userDefaultsIntermediaryAccount = res.data.getUserDefaults?.intermediaryAccount;
        const instrumentCoverageIntermediaryAccount =
          res.data.getInstrumentCoverage?.defaultIntermediaryAccount;

        const intermediaryAccount = userDefaultsIntermediaryAccount || instrumentCoverageIntermediaryAccount;

        const firmAccount = userDefaultsFirmAccount || instrumentCoverageFirmAccount;

        return { intermediaryAccount, firmAccount };
      });
  }

  public pollById$(id?: string, pollInterval = 5000) {
    return this.apolloClient
      .watchQuery<GetExecutionsByOrderIdQuery, GetExecutionsByOrderIdQueryVariables>({
        query: GetExecutionsByOrderIdDocument,
        pollInterval,
        fetchPolicy: 'no-cache',
        variables: {
          id: id ?? ''
        }
      })
      .pipe(
        map((res) => {
          const allocationMapsResults = res.data?.investorOrder?.allocationMaps?.nodes
            ? (res.data.investorOrder.allocationMaps.nodes as AllocationMap[])
            : [];
          const executionsResults = allocationMapsResults.reduce<ExecutionInfoFragment[]>((list, res) => {
            if (res.executions?.nodes?.length > 0) {
              list.push(...(res.executions.nodes as ExecutionInfoFragment[]));
            }
            return list;
          }, []);
          return executionsResults;
        })
      );
  }

  public cancelExecutions(input: {
    orderId: string;
    executionIds: string[];
    investorOrderAccountId: string;
    tradingOrderIntermediaryAccountId: string;
  }) {
    const mutation = this.gqlResponse.wrapMutate<CancelExecutionsMutation, CancelExecutionsMutationVariables>(
      {
        mutation: CancelExecutionsDocument,
        variables: {
          ...input
        },
        refetchQueries: []
      }
    );

    return mutation.awaitAsyncResponse().exec();
  }

  public applySettleFxRate(input: SettlementFxRateInput) {
    const mutation = this.gqlResponse.wrapMutate<SettlementFxRateMutation, SettlementFxRateMutationVariables>(
      {
        mutation: SettlementFxRateDocument,
        variables: {
          settlementFxRate: input
        },
        refetchQueries: []
      }
    );

    return mutation.awaitAsyncResponse().exec();
  }
}
