import { type GridProps, type Sprinkles, useDeepMemo } from '@oms/shared-frontend/ui-design-system';
import React, { useMemo } from 'react';
import { MarketDataDisplay } from './market-data.display';
import type { MarketDataWidgetProps } from './market-data.types';
import { useRxMarketDataSubscription } from './market-data.hook';
import { marketDataWidgetTypeMap } from './market-data.widget.util';
import { isEqual } from 'lodash';

/**
 * MarketDataWidget
 *
 * This component is used to display specific market widgets,
 * that are required in the app.
 *
 * It's a wrapper around the MarketDataDisplay component, that adds
 * business logic and real-time data.
 */
export const MarketDataWidget: React.FC<MarketDataWidgetProps> = React.memo(
  ({ type, values = [], gridProps, instrumentDetails, sx, trackableValues, ...rest }) => {
    /**
     * Get ticker from instrument details
     */
    const ticker = useDeepMemo(
      () => instrumentDetails?.mappings?.displayCode || undefined,
      [instrumentDetails]
    );

    /**
     * Get level 1 market data
     */
    const marketData = useRxMarketDataSubscription(ticker);
    const level1Event = marketData?.level1;

    /**
     * Generate props for the MarketDataDisplay component
     * Note: We're using a map (marketDataWidgetTypeMap) to add props to the component based on the type
     */
    const props = useMemo(
      () => ({
        level1Event,
        instrumentDetails,
        gridProps: gridProps ? gridProps : ({ columns: 2, rows: 8, rowGap: 4 } as GridProps),
        values,
        ...(type !== 'custom' ? marketDataWidgetTypeMap[type] : {}) // using a map (marketDataWidgetTypeMap) to add props to the component based on the type
      }),
      [instrumentDetails, level1Event, type, values, gridProps]
    );

    const omBannerStyles: Sprinkles = {
      backgroundColor: 'layout.level1',
      borderRadius: 'sm',
      padding: 3
    };

    return (
      <MarketDataDisplay
        {...rest}
        {...props}
        trackableValues={trackableValues || props.trackableValues}
        sx={{
          ...(type === 'om-banner' || type === 'om-banner-compact' ? { ...omBannerStyles } : null),
          ...sx
        }}
      />
    );
  },
  isEqual
);
