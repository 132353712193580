import { FC, Fragment, useMemo } from 'react';
import { Text, TextProps } from '../../../../layout/text/text';
import { DISPLAY_FIELD_COMPONENT_TYPE } from '../../display-field.contracts';
import { TextEllipser } from '../../../text-ellipser/text-ellipser';

type ValueProps = string | { text: string; textProps?: TextProps };

export type TextFieldProps = {
  type: typeof DISPLAY_FIELD_COMPONENT_TYPE.Text;
  value: ValueProps | ValueProps[];
  textProps?: TextProps;
};

export const TextField: FC<TextFieldProps> = ({ value, textProps }) => {
  const items = useMemo(() => {
    const values = Array.isArray(value) ? value : [value];
    return values.map((v, index) => {
      const val = typeof v === 'object' ? v?.text : v;

      return (
        <Fragment key={`${val}-${index}`}>
          <Text {...(typeof v === 'object' ? v?.textProps : null || textProps)} data-raw-value={val}>
            {val}
            {index < values.length - 1 ? <>&nbsp;</> : null}
          </Text>
        </Fragment>
      );
    });
  }, [value, textProps]);

  return <TextEllipser>{items}</TextEllipser>;
};
