import {
  ModalWindow,
  type ReactActorComponentProps,
  ReactBrowserModalWindowActorSchemaBuilder
} from '@valstro/workspace-react';
import { CommonAuthWindowComponent, commonAuthWindowSchemaOverrides } from './auth-window.common.actor';
import { COMMON_PLATFORM_NAME } from '@valstro/workspace';
import {
  type AuthWindowContext,
  type AuthWindowOperations,
  COMMON_AUTH_WINDOW,
  type CommonAuthWindowActorSchema
} from '@app/common/auth/auth.contracts';
import type { CommonAuthWindowActorSchemaOptions } from '@app/common/auth/auth.contracts';

export const BROWSER_AUTH_WINDOW_ACTOR_NAME = 'browser-auth-window';

const View: React.FC<ReactActorComponentProps<CommonAuthWindowActorSchema>> = ({ actor }) => {
  return (
    <ModalWindow<CommonAuthWindowActorSchema> actor={actor}>
      <CommonAuthWindowComponent actor={actor} />
    </ModalWindow>
  );
};

const BrowserAuthWindowActorSchemaBuilder = ReactBrowserModalWindowActorSchemaBuilder.extend<
  AuthWindowContext,
  AuthWindowOperations
>((prevSchema) => ({
  ...commonAuthWindowSchemaOverrides(prevSchema),
  type: COMMON_AUTH_WINDOW.TYPE,
  name: BROWSER_AUTH_WINDOW_ACTOR_NAME,
  supportedPlatforms: [COMMON_PLATFORM_NAME.BROWSER],
  selectStrategy: undefined
})).extendView(View);

export const browserAuthWindowActor =
  BrowserAuthWindowActorSchemaBuilder.optionsCreator<CommonAuthWindowActorSchemaOptions>();

export type BrowserAuthWindowActorSchema = typeof BrowserAuthWindowActorSchemaBuilder.schema;
