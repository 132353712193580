import { openTradeModify } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { type TradeRepairRequestFragment } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { TradeModifyEntryType } from '@app/widgets/trading/trade-modify/trade-modify.form-common';

export const openRepairCreateTradeActionOnChange = (
  ctx: ActionContext<TradeRepairRequestFragment, ActionComponentConfig<TradeRepairRequestFragment>>
) => {
  const { lifecycle, data, workspace } = ctx;

  const selectedRow = data[0];
  const onlyOneRowSelected = data.length === 1;

  ctx.notify({ isDisabled: !onlyOneRowSelected });

  if (lifecycle === 'change') {
    if (!selectedRow || !selectedRow.id) {
      return;
    }

    const id = selectedRow.id;
    openTradeModify(PROCESS_ID.LEADER, {
      windowId: `trade-repair-${id}`,
      form: {
        formId: `trade-repair-form-${id}`,
        input: {
          id,
          entryType: TradeModifyEntryType.TradeRepair
        }
      },
      title: 'Repair trade'
    }).catch((e) => {
      openMessageDialog(`Error: ${e}`, workspace).catch(console.error);
      console.error(e);
    });
  }
};

export const repairCreateTradeAction: ActionDefFactory<TradeRepairRequestFragment> = (builder) =>
  builder
    .name('repair_trade')
    .toolbar((t) =>
      t.component('action-button').id('repair_trade_button').location('HorizontalToolbarRight').props({
        isDisabled: true,
        content: 'Repair'
      })
    )
    .menu((m) => m.name('Repair').visible(({ rowData }) => !!rowData))
    .onChange<ActionComponentConfig<TradeRepairRequestFragment>>(openRepairCreateTradeActionOnChange);
