import { useMemo } from 'react';
import type { Observable } from 'rxjs';
import { type DataSourceCommon, useService } from '@oms/frontend-foundation';
import PositionsService from '@app/data-access/services/trading/positions/positions.service';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import type { NestedTreeData } from '@app/data-access/services/trading/positions/common/tree-grid/types/tree-data.types';
import PositionsRowDataHelper from './positions-row-data-helper.class';
import PositionsDatasourceService from './positions.datasource.service';
import type { PrimaryPositionsGridType } from '../types';

export function usePositionsDataSourceService(
  gridType: PrimaryPositionsGridType,
  data$: Observable<DataSourceCommon<NestedTreeData<PositionRow>>>
) {
  const positionsService = useService(PositionsService);
  const dataHelper = useMemo(() => PositionsRowDataHelper.forDataSource(data$, gridType), [data$, gridType]);
  const dataSourceService = useMemo(
    () => new PositionsDatasourceService(gridType, positionsService, dataHelper),
    [gridType, positionsService, dataHelper]
  );
  return useMemo(
    () => ({ positionsService, dataSourceService, dataHelper }),
    [positionsService, dataSourceService, dataHelper]
  );
}

export default usePositionsDataSourceService;
