import { type VisibleTradingOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { TabContent, TabTrigger, Tabs, TabsList } from '@oms/shared-frontend/ui-design-system';
import { useClosestFlexLayoutActorProps } from '@valstro/workspace-react';
import { TradingOrderTradesGrid } from './trading-order-view.trades.grid';
import { TradingOrderInvestorOrdersGrid } from './trading-order-view.investor-orders.grid';
import { TradingOrderAuditTrailGrid } from './trading-order-view.audit-trail.grid';
import { paneStyle as sx } from './trading-order-view.style';

export const TradingOrderViewTabbedGridsWidget = () => {
  const [props] = useClosestFlexLayoutActorProps<{
    id: VisibleTradingOrderInfoWithAllocationsFragment['id'];
  }>();

  if (!props) {
    throw new Error('TradingOrderViewTabbedGridsWidget requires a trading order ID');
  }
  const { id: tradingOrderId } = props;

  return (
    <Tabs
      defaultValue="tabTrades"
      sx={{ ...sx, padding: 0, position: 'absolute', inset: 0, display: 'flex', flexDirection: 'column' }}
    >
      <TabsList style={{ padding: 0, marginTop: 1, marginLeft: 15, marginBottom: 0 }}>
        <TabTrigger value="tabTrades">Trades</TabTrigger>
        <TabTrigger value="tabInvestorOrders">Investor Orders</TabTrigger>
        <TabTrigger value="tabAuditTrail">Audit Trail</TabTrigger>
      </TabsList>

      <TabContent
        value="tabTrades"
        sx={{
          height: 'full',
          flexDirection: 'column',
          borderBottomStyle: 'solid',
          borderBottomWidth: '4px',
          borderColor: 'layout.level1'
        }}
      >
        <TradingOrderTradesGrid id={tradingOrderId} />
      </TabContent>
      <TabContent
        value="tabInvestorOrders"
        sx={{
          height: 'full',
          flexDirection: 'column',
          borderBottomStyle: 'solid',
          borderBottomWidth: '4px',
          borderColor: 'layout.level1'
        }}
      >
        <TradingOrderInvestorOrdersGrid id={tradingOrderId} />
      </TabContent>
      <TabContent
        value="tabAuditTrail"
        sx={{
          height: 'full',
          flexDirection: 'column',
          borderBottomStyle: 'solid',
          borderBottomWidth: '4px',
          borderColor: 'layout.level1'
        }}
      >
        <TradingOrderAuditTrailGrid tradingOrderId={tradingOrderId} />
      </TabContent>
    </Tabs>
  );
};

export default TradingOrderViewTabbedGridsWidget;
