import { get } from 'lodash';

export class DragModel<SourceType, DragModelItemType> {
  public model: DragModelItemType[] = [];
  public log: boolean = false;

  constructor(
    private reducers: {
      input: (source: SourceType) => DragModelItemType[];
      output: (model: DragModelItemType[]) => SourceType;
    },
    private validator: (model: DragModelItemType[]) => boolean = () => true
  ) {}

  // TODO: add dry-run mode to use validator for canDrop() checks
  // dragModel.clone().moveItem(path1, 0, path2, 1).validate()
  public clone(): DragModel<SourceType, DragModelItemType> {
    const clone = new DragModel(this.reducers, this.validator);
    clone.model = structuredClone(this.model);
    return clone;
  }

  public validate(): boolean {
    if (!this.model) {
      throw new Error('Model is not initialized');
    }
    return this.validator(this.model);
  }

  public output(): SourceType {
    if (!this.model) {
      throw new Error('Model is not initialized');
    }
    return this.reducers.output(this.model);
  }

  public input(source: SourceType): void {
    this.model = this.reducers.input(source);
  }

  private _getArray(objectPath: string): DragModelItemType[] {
    if (!this.model) {
      throw new Error('Model is not initialized');
    }
    const array = get(this.model, objectPath) ?? this.model;
    if (!Array.isArray(array)) {
      throw new Error(`Path ${objectPath} does not point to an array in ${this.model}`);
    }
    return array;
  }

  private _dragTo(
    fromPath: string,
    fromIdx: number,
    toPath: string,
    toIdx: number
  ): [DragModelItemType[], number, DragModelItemType, DragModelItemType[]] {
    if (!this.model) {
      throw new Error('Model is not initialized');
    }
    const fromArray = this._getArray(fromPath);
    const toArray = this._getArray(toPath);
    const [fromItem] = fromArray.splice(fromIdx, 1);
    if (!fromItem) {
      throw new Error(`Drag item not found: Index ${fromIdx} not found in ${fromPath}`);
    }
    const mutatedToIdx = fromPath === toPath && fromIdx < toIdx ? toIdx - 1 : toIdx;
    if (!toArray[mutatedToIdx]) {
      throw new Error(`Drop target not found: Index ${mutatedToIdx} not found in ${toPath}`);
    }
    return [toArray, mutatedToIdx, fromItem, fromArray];
  }

  public removeItem(objectPath: string, idx: number): void {
    this._getArray(objectPath).splice(idx, 1);
  }

  public moveItem(fromPath: string, fromIdx: number, toPath: string, toIdx: number): void {
    const [toArray, idx, fromItem] = this._dragTo(fromPath, fromIdx, toPath, toIdx);
    toArray.splice(idx, 0, fromItem);
  }

  public moveItemBeforeTarget(fromPath: string, fromIdx: number, toPath: string, toIdx: number): void {
    const [toArray, idx, fromItem] = this._dragTo(fromPath, fromIdx, toPath, toIdx);
    toArray.splice(idx, 0, fromItem);
  }

  public moveItemAfterTarget(fromPath: string, fromIdx: number, toPath: string, toIdx: number): void {
    const [toArray, idx, fromItem] = this._dragTo(fromPath, fromIdx, toPath, toIdx);
    toArray.splice(idx + 1, 0, fromItem);
  }

  public swapItems(fromPath: string, fromIdx: number, toPath: string, toIdx: number): void {
    const [toArray, idx, fromItem, fromArray] = this._dragTo(fromPath, fromIdx, toPath, toIdx);
    const [toItem] = toArray.splice(idx, 1, fromItem);
    fromArray.splice(fromIdx, 0, toItem);
  }

  public moveGroupAndAppendTarget(fromPath: string, fromIdx: number, toPath: string, toIdx: number): void {
    const [toArray, mutatedToIdx, fromItem] = this._dragTo(fromPath, fromIdx, toPath, toIdx);
    const [toItem] = toArray.splice(mutatedToIdx, 1, fromItem);
    if (!Array.isArray(fromItem)) {
      throw new Error('Dragged item is not a group');
    }
    fromItem.push(toItem);
  }
}
