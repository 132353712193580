import { type WinSizeOptions } from '@valstro/workspace';
import { type IJsonModel } from 'flexlayout-react';

export const expandedMontageLayout: IJsonModel['layout'] = {
  id: 'expanded',
  type: 'row',
  children: [
    {
      type: 'row',
      weight: 100,
      children: [
        {
          type: 'tabset',
          weight: 70,
          children: [
            {
              type: 'tab',
              name: 'Data',
              component: 'MONTAGE_DATA_PANEL'
            }
          ]
        },
        {
          type: 'tabset',
          weight: 30,
          children: [
            {
              type: 'tab',
              name: 'Montage Side Panel',
              component: 'MONTAGE_SIDE_PANEL'
            }
          ]
        }
      ]
    }
  ]
};

export const expandedMontageWindowSize: WinSizeOptions = {
  width: 1300,
  height: 700
};
