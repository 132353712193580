import { bumpPriceRenderer } from '@app/widgets/trading/montage/commands/bump-price/bump.price.renderer';
import { type CommandRenderRegistry } from './command.render.strategy.types';
import { targetTradingOrderRenderer } from '@app/widgets/trading/montage/commands/target-trading-order/target-trading-order.renderer';

/**
 * Import your command's special render strategies, or the default will be used.
 */
export const COMMAND_RENDER_REGISTRY: CommandRenderRegistry = {
  montage_bump_price: bumpPriceRenderer,
  montage_target_trading_order: targetTradingOrderRenderer
};
