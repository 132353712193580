import { type RouteOrderFormValues } from '../route-order.form-contract';

export const validateRouteOrderMatchedInvestorOrders =
  () => (value?: string[], allValues?: RouteOrderFormValues) => {
    const formValues: Partial<RouteOrderFormValues> = allValues ? allValues : {};
    const { hiddenFormInfo } = formValues || {};

    if (hiddenFormInfo?.type === 'create' && (!value || value.length === 0)) {
      return 'No mappable Investor Orders';
    }
    return null;
  };

export const VALIDATE_ROUTE_ORDER_MATCHED_INVESTOR_ORDERS = 'validateRouteOrderMatchedInvestorOrders';
