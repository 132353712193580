import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import {
  type AnyRecord,
  type FieldContract,
  type INumberFormatField,
  type ModifiedFieldForOptions
} from '@oms/frontend-foundation';

export const createNotionalAmountField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<INumberFormatField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'number-format').options<INumberFormatField>({
    label: 'Notional Amount',
    ...overrides,
    validate: [{ type: 'min-number-value', value: 1, message: 'Min $0.01' }, ...(overrides?.validate || [])]
  });
