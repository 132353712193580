import React from 'react';
import { useCurrentWidgetActorProps } from '@valstro/workspace-react';
import type { IAllFields } from './all-fields.component';
import { type Field, FormRenderer } from '@oms/frontend-foundation';
import { container } from 'tsyringe';

export const AllFieldsFormDialogComponent: React.FC = () => {
  const [{ fields, formId, formType, formValues = {} }] = useCurrentWidgetActorProps<IAllFields>();

  return (
    <FormRenderer
      initialValues={formValues}
      schema={{
        // Annoyingly, the app Field is sufficently different from the Field type in the foundation that we need to cast it
        // even though they are the same
        fields: (fields as unknown as Field[]) || []
      }}
      formId={formId + '_all-fields'}
      formType={formType}
      container={container}
      template="simple"
      templateProps={{ showSubmitButton: false, sx: { backgroundColor: 'layout.level1' } }}
    />
  );
};

export default AllFieldsFormDialogComponent;
