import { SnapshotsService } from '@app/data-access/services/system/snapshots/snapshots.service';
import {
  saveSnapshotFormContract,
  type SaveSnapshotFormInput,
  type SaveSnapshotFormContractType,
  type SaveSnapshotFormValues,
  type SaveSnapshotFormOutput
} from './save-snapshot.form-contract';
import { FormBuilder, FORM_EVENT_TYPE } from '@oms/frontend-foundation';
import { Actor, type CommonWindowActorSchema } from '@valstro/workspace';

export const saveSnapshotFormBuilder = FormBuilder.create<SaveSnapshotFormInput, SaveSnapshotFormOutput>(
  'save-snapshot-form'
)
  .contract<SaveSnapshotFormContractType>(saveSnapshotFormContract)
  .type('saveSnapshotForm')
  .sanitizer((s) =>
    s
      .input(function sanitize(input) {
        const formValues: SaveSnapshotFormValues = {
          name: input?.name
        };

        return formValues;
      })
      .output(function sanitize(formValues) {
        if (!formValues.name) return;

        const output: SaveSnapshotFormOutput = {
          name: formValues.name
        };

        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const service = ctx.container.resolve(SnapshotsService);
        if (!event.payload.formValues.name) {
          throw new Error('Name is required');
        }
        await service.save('custom', event.payload.formValues.name);
        const windowId = event.meta.windowId;
        const windowActor = await Actor.get<CommonWindowActorSchema>(windowId);
        await windowActor.operations.close();
      }
    }
  });

export type SaveSnapshotFormBuilderType = typeof saveSnapshotFormBuilder;

export default saveSnapshotFormBuilder;
