import type { RxJsonSchema } from 'rxdb-v15';
import { actorScopedSchemaDefaults as defaults } from '../../constants';
import type { MontageDocType } from './montage.collection.types';

const montageSchema: RxJsonSchema<MontageDocType> = {
  title: 'montage schema',
  description: 'state model for montage widget',
  version: 0,
  keyCompression: defaults.keyCompression,
  primaryKey: defaults.primaryKey,
  type: defaults.type,
  properties: {
    ...defaults.properties,
    instrumentTrackingEnabled: {
      type: 'boolean',
      default: false,
      description: 'Current status of instrument tracking set by the toggle button in the toolbar'
    },
    instrumentId: {
      type: 'string',
      maxLength: 128,
      description: 'Current instrument ID set by the dropdown in the toolbar'
    },
    investorAccountId: {
      type: 'string',
      maxLength: 128,
      description: 'Current investor account ID set by the dropdown in the toolbar'
    }
  },
  required: [...(defaults.required || [])],
  indexes: [...(defaults.indexes || [])]
} as const;

export default montageSchema;
