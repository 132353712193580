import { ApolloError } from '@apollo/client';
import {
  ErrorTypeEnum,
  GeneralError,
  FieldValidationError,
  AsyncError,
  AsyncTimeoutError,
  UnauthorizedError,
  ServerError,
  ServerParseError,
  type GQLErrorMap,
  type ErrorType,
  type GQLErrorInstance,
  type GQLErrorType,
  ValidationError,
  InternalServerError
} from '@oms/shared/oms-common';
import type { AllGQLResponseErrors, GQLMutationResp, GQLQueryResp } from './graphql-response.types';
import { MultipleErrors, asArray, isPromiseLike } from '@oms/shared/util';
import type { Constructable } from '@oms/shared/util-types';
import { BehaviorSubject, type Observable, firstValueFrom } from 'rxjs';
import { ENVELOPE_FEEDBACK_ERROR, EnvelopeFeedbackError } from './graphql-envelope';

/**
 * A type that represents the different ways to map through and handle errors.
 */
export type ExtendedGQLErrorInstance = GQLErrorInstance | Constructable<EnvelopeFeedbackError>;
export type ExtendedErrorType = ErrorType | typeof ENVELOPE_FEEDBACK_ERROR;
export type ExtendedGQLErrorType = GQLErrorType | EnvelopeFeedbackError;
export type ExtendedAllGQLResponseErrors = ExtendedGQLErrorType[];
export type ExtendedGQLErrorMap = GQLErrorMap & { [ENVELOPE_FEEDBACK_ERROR]: EnvelopeFeedbackError };

/**
 * A `GQLResult` type known to hold success type and no error.
 * The `value` type is know to be of type `R`.
 */
export type SuccessGQLResult<R extends GQLQueryResp | GQLMutationResp> = GQLResult<R, never>;

/**
 * A `GQLResult` type known to hold failure type and no success result.
 * The `value` type is know to be of type `E`.
 */
export type FailureGQLResult<E extends AllGQLResponseErrors = AllGQLResponseErrors> = GQLResult<never, E>;

/**
 * Other failure types to represent the different ways to map through and handle errors.
 */
export const gqlInstanceErrorMap: Record<ExtendedErrorType, ExtendedGQLErrorInstance> = {
  [ErrorTypeEnum.GENERAL_ERROR]: GeneralError,
  [ErrorTypeEnum.FIELD_VALIDATION_ERROR]: FieldValidationError,
  [ErrorTypeEnum.VALIDATION_ERROR]: ValidationError,
  [ErrorTypeEnum.ASYNC_ERROR]: AsyncError,
  [ErrorTypeEnum.ASYNC_TIMEOUT_ERROR]: AsyncTimeoutError,
  [ErrorTypeEnum.UNAUTHORIZED]: UnauthorizedError,
  [ErrorTypeEnum.SERVER_ERROR]: ServerError,
  [ErrorTypeEnum.SERVER_PARSE_ERROR]: ServerParseError,
  [ErrorTypeEnum.UNKNOWN_ERROR]: ApolloError,
  [ErrorTypeEnum.INTERNAL_SERVER_ERROR]: InternalServerError,
  [ErrorTypeEnum.ERROR]: Error,
  [ENVELOPE_FEEDBACK_ERROR]: EnvelopeFeedbackError
};

export type FailureGQLErrorHandlerCallback<E, ResultType = void> = (
  errors: E,
  traceId: string | null
) => ResultType extends void ? void : ResultType[] | ResultType;

export type FailureGQLAsyncErrorHandlerCallback<E, ResultType = void> = (
  errors: E
) => Promise<ResultType extends void ? void : ResultType[] | ResultType>;

export const getErrorTypeFromInstance = (error: ExtendedGQLErrorType): ExtendedErrorType => {
  const key = Object.keys(gqlInstanceErrorMap).find(
    (k) => error instanceof gqlInstanceErrorMap[k as ExtendedErrorType]
  );
  return key as ExtendedErrorType | ErrorTypeEnum.ERROR;
};

export type FailureGQLAllErrorsHandler<ResultType = void> = {
  [P in keyof ExtendedGQLErrorMap]?: FailureGQLErrorHandlerCallback<ExtendedGQLErrorMap[P][], ResultType>;
};

export type FailureGQLAsyncAllErrorsHandler<ResultType = void> = {
  [P in keyof ExtendedGQLErrorMap]?: FailureGQLAsyncErrorHandlerCallback<
    ExtendedGQLErrorMap[P][],
    ResultType
  >;
};

export type FailureGQLErrorHandler<E extends ExtendedAllGQLResponseErrors, ResultType = void> =
  | FailureGQLErrorHandlerCallback<E, ResultType>
  | FailureGQLAllErrorsHandler<ResultType>;

export type FailureGQLAsyncErrorHandler<E extends ExtendedAllGQLResponseErrors, ResultType = void> =
  | FailureGQLAsyncErrorHandlerCallback<E, ResultType>
  | FailureGQLAsyncAllErrorsHandler<ResultType>;

/**
 * A wrapper type holding a single value that may be either:
 * 1. A successful result type
 * 2. A failure type (usually an error)
 *
 * The underlying value may be only one of these two. Never both or neither.
 * In this way, success and failure results produce a consistent return type
 * which can be evaluated to handle either outcome.
 *
 * Use the static`success` and `failure` methods to create a `GQLResult` instance:
 *
 * ```ts
 * const result = GQLResult.failure(error);
 * // or
 * const result = GQLResult.success('foo');
 * ```
 * This can be passed to some context that expects either to be possible.
 * There it can be unwrapped and handled:
 *
 * ```ts
 * if (result.isSuccess()) {
 *   const { value } = result;
 *   // Handle... value is known to be success type.
 * }
 *
 * if (result.isFailure()) {
 *   const { value } = result;
 *   // Handle... value is known to be failure type.
 * }
 * ```
 *
 * Alternatively, you could use `map` or `mapAsync` to handle in a similar way.
 */
export class GQLResult<R extends GQLQueryResp | GQLMutationResp, E extends ExtendedAllGQLResponseErrors> {
  protected _value: R;
  protected _errors: E;
  protected _traceId: string | null;
  protected _isSuccess: boolean;

  /** @protected Use static `success` and `failure` methods to create a `GQLResult` instance. */
  protected constructor(isSuccess: boolean, value: R | E, traceid: string | null = null) {
    this._value = null as unknown as R;
    this._errors = null as unknown as E;

    this._isSuccess = isSuccess;
    if (isSuccess) {
      this._value = value as R;
    } else {
      this._errors = value as E;
    }
    this._traceId = traceid;
  }

  /**
   * @param error - A failed result (most likely an error)
   * @returns A `GQLResult` instance holding the failed result
   */
  public static failure<E extends ExtendedAllGQLResponseErrors>(
    error: E,
    traceId: string | null = null
  ): GQLResult<never, E> {
    return new GQLResult(false, error, traceId) as unknown as GQLResult<never, E>;
  }

  /**
   * @param result - A successful result
   * @returns A `GQLResult` instance holding the successful result
   */
  public static success<R extends GQLQueryResp | GQLMutationResp>(
    result: R,
    traceId: string | null = null
  ): GQLResult<R, never> {
    return new GQLResult(true, result, traceId) as unknown as GQLResult<R, never>;
  }

  /**
   * Access to the underlying value, success or falure.
   * Use `isSuccess` and/or `isFailure` methods to handle this
   * as success type or failure type.
   */
  public get value(): R | E {
    return this._isSuccess ? this._value : this._errors;
  }

  public get errors(): E {
    return this._errors || [];
  }

  public get traceId(): string | null {
    return this._traceId;
  }

  /**
   * Type predicate that resolves the `GQLResult` instance as a success or failure
   * for the scope of the block created:
   *
   * ```ts
   * if (result.isSuccess()) {
   *   const { value } = result;
   *   // Handle... value is known to be success type.
   * }
   * ```
   * @returns Type predicate validating if result is a success type
   */
  public isSuccess(): this is SuccessGQLResult<R> {
    return this._isSuccess;
  }

  /**
   * Type predicate that resolves the `GQLResult` instance as a success or failure
   * for the scope of the block created:
   *
   * ```ts
   * if (result.isFailure()) {
   *   const { value } = result;
   *   // Handle... value is known to be failure type.
   * }
   * ```
   * @returns Type predicate validating if result is a failure type
   */
  public isFailure(): this is FailureGQLResult<E> {
    return !this._isSuccess;
  }

  /**
   * Unwraps the success value or throws an error
   *
   * ```ts
   * try {
   *   const value = result.unwrapOrThrow();
   * } catch (e) {
   *   const allErrors = e as MultipleErrors<ExtendedGQLErrorType>;
   *   allErrors.errors.forEach((error) => {
   *     // Handle each error
   *   });
   * }
   * ```
   *
   * @returns The success value if successful
   * @throws A `MultipleErrors<ExtendedGQLErrorType>` error if not successful
   */
  public unwrapOrThrow(): R {
    if (this.isSuccess()) {
      return this.value;
    } else {
      throw new MultipleErrors<E[number]>(this.errors);
    }
  }

  /**
   * Applies handing for success and/or failure results.
   *
   * ```ts
   * result.mapSync(
   *   (result) => { // Handle success },
   *   (error) => { // Handle failure }
   * );
   * ```
   * @param [successHandler] - Callback that handles success result.
   * @param [failureHandler] - Callback that handles failure result.
   * @param [catchRemainingFailureHandler] - Callback that handles other failure results.
   */
  public mapSync(
    successHandler?: (result: R) => void,
    failureHandler?: FailureGQLErrorHandler<E>,
    catchRemainingFailureHandler?: FailureGQLErrorHandlerCallback<E>
  ) {
    if (this._isSuccess && successHandler) successHandler(this._value as R);
    if (!this._isSuccess && failureHandler) {
      this.#errorHandler(this._errors, failureHandler, catchRemainingFailureHandler);
    }
  }

  /**
   * Applies async handing for success and/or failure results.
   *
   * ```ts
   * result.map(
   *   async (result) => { // Handle success },
   *   async (error) => { // Handle failure }
   * );
   * ```
   * @param [successHandler] - Async callback that handles success result.
   * @param [failureHandler] - Async callback that handles failure result.
   * @param [catchRemainingFailureHandler] - Callback that handles other failure results.
   */
  public async mapAsync(
    successHandler?: (result: R) => Promise<void>,
    failureHandler?: FailureGQLAsyncErrorHandler<E>,
    catchRemainingFailureHandler?: FailureGQLAsyncErrorHandlerCallback<E>
  ) {
    if (this._isSuccess && successHandler) await successHandler(this._value as R);
    if (!this._isSuccess && failureHandler) {
      await this.#asyncErrorHandler(this._errors, failureHandler, catchRemainingFailureHandler);
    }
  }

  /**
   * Maps success and/or failure results to a sync return type.
   * > (This could collect multiple results if there are multiple error handlers matched)
   *
   * ```ts
   * const descriptions: string[] = result.mapToAllSync(
   *   (successType) => successType.toString(),
   *   (error) => error.message
   * );
   * ```
   * @param successHandler - Callback that handles success result.
   * @param failureHandler - Callback that handles failure result.
   * @param [catchRemainingFailureHandler] - Callback that handles other failure results.
   */
  public mapToAll<ResultType>(
    successHandler: (result: R) => ResultType,
    failureHandler: FailureGQLErrorHandler<E, ResultType>,
    catchRemainingFailureHandler?: FailureGQLErrorHandlerCallback<E, ResultType>
  ): ResultType[] {
    if (this._isSuccess) {
      return [successHandler(this._value as R)];
    } else {
      return this.#errorHandler(this._errors, failureHandler, catchRemainingFailureHandler);
    }
  }

  /**
   * Maps success and/or failure results to an async return type.
   * > (This could collect multiple results if there are multiple error handlers matched)
   *
   * ```ts
   * const descriptions: string[] = await result.mapToAllAsync(
   *   (successType) => {
   *     // Some async task here
   *     return successType.toString();
   *   },
   *   (error) => {
   *     // Some async task here
   *     return error.toString();
   *   }
   * );
   * ```
   * @param successHandler - Callback that handles success result.
   * @param asyncFailureHandler - Callback that handles failure result.
   * @param [catchRemainingFailureHandler] - Callback that handles other failure results.
   */
  public async mapToAllAsync<ResultType>(
    successHandler: (result: R) => Promise<ResultType>,
    asyncFailureHandler: FailureGQLAsyncErrorHandler<E, ResultType>,
    catchRemainingFailureHandler?: FailureGQLAsyncErrorHandlerCallback<E, ResultType>
  ): Promise<ResultType[]> {
    if (this._isSuccess) {
      return asArray(await successHandler(this._value as R));
    } else {
      return await this.#asyncErrorHandler(this._errors, asyncFailureHandler, catchRemainingFailureHandler);
    }
  }

  /**
   * Maps success and/or failure results to a return type.
   * > (This only returns on result, so could ignore other matched error handlers)
   *
   * ```ts
   * const description: string = result.mapToSync(
   *   (successType) => successType.toString(),
   *   (error) => error.message
   * );
   * ```
   *
   * > **Throws an error if no results returned!**
   *
   * @param successHandler - Callback that handles success result.
   * @param failureHandler - Callback that handles failure result.
   * @param [catchRemainingFailureHandler] - Callback that handles other failure results.
   */
  public mapTo<ResultType>(
    successHandler: (result: R) => ResultType,
    failureHandler: FailureGQLErrorHandler<E, ResultType>,
    catchRemainingFailureHandler?: FailureGQLErrorHandlerCallback<E, ResultType>
  ): ResultType {
    const result = this.mapToAll(successHandler, failureHandler, catchRemainingFailureHandler);
    if (result.length === 0) throw GQLResult.noResultsError;
    return result[0];
  }

  /**
   * Maps success and/or failure results to a return type.
   * > (This only returns on result, so could ignore other matched error handlers)
   *
   * ```ts
   * const description: string = result.mapToAsync(
   *   (successType) => {
   *     // Some async task here
   *     return successType.toString();
   *   },
   *   (error) => {
   *     // Some async task here
   *     return error.toString();
   *   }
   * );
   *
   * > **Throws an error if no results returned!**
   *
   * @param successHandler - Callback that handles success result.
   * @param asyncFailureHandler - Callback that handles failure result.
   * @param [catchRemainingFailureHandler] - Callback that handles other failure results.
   */
  public async mapToAsync<ResultType>(
    successHandler: (result: R) => Promise<ResultType>,
    asyncFailureHandler: FailureGQLAsyncErrorHandler<E, ResultType>,
    catchRemainingFailureHandler?: FailureGQLAsyncErrorHandlerCallback<E, ResultType>
  ): Promise<ResultType> {
    const result = await this.mapToAllAsync(
      successHandler,
      asyncFailureHandler,
      catchRemainingFailureHandler
    );
    if (result.length === 0) throw GQLResult.noResultsError;
    return result[0];
  }

  /**
   * Allows error handling by type.
   * @param errors - The errors to handle from the result
   * @param failureHandler
   */
  #errorHandler<ResultType>(
    errors: E,
    failureHandler: FailureGQLErrorHandler<E, ResultType>,
    catchRemainingFailureHandler?: FailureGQLErrorHandlerCallback<E, ResultType>
  ): ResultType[] {
    const [results, setResults] = GQLResult.makeSyncResultsOf<ResultType>();
    if (typeof failureHandler === 'function') {
      setResults(failureHandler(errors, this._traceId));
    } else {
      const errorHandlers = failureHandler;
      const handledErrorInstances: ExtendedGQLErrorInstance[] = [];

      for (const key in errorHandlers) {
        const errorType = key as ErrorTypeEnum;
        const errorHandler = errorHandlers[errorType] as FailureGQLErrorHandlerCallback<
          ExtendedAllGQLResponseErrors,
          ResultType
        >;

        const errorInstance = gqlInstanceErrorMap[errorType] as ExtendedGQLErrorInstance;
        const filteredErrors = errors
          .filter((e) => e instanceof errorInstance)
          .flatMap((e) => (e instanceof errorInstance ? [e] : []));

        if (errorHandler && filteredErrors.length > 0) {
          setResults(errorHandler(filteredErrors, this._traceId));
          handledErrorInstances.push(errorInstance);
        }
      }

      if (catchRemainingFailureHandler) {
        const filteredErrors = errors.filter((e) => !handledErrorInstances.some((i) => e instanceof i)) as E;
        setResults(catchRemainingFailureHandler(filteredErrors, this._traceId));
      }
    }
    return results;
  }

  /**
   * Allows async error handling by type.
   * @param errors - The errors to handle from the result
   * @param asyncFailureHandler
   */
  async #asyncErrorHandler<ResultType>(
    errors: E,
    asyncFailureHandler: FailureGQLAsyncErrorHandler<E, ResultType>,
    catchRemainingFailureHandler?: FailureGQLAsyncErrorHandlerCallback<E, ResultType>
  ): Promise<ResultType[]> {
    const [results$, setResults] = GQLResult.makeAsyncResultsOf<ResultType>();
    if (typeof asyncFailureHandler === 'function') {
      setResults(await asyncFailureHandler(errors));
    } else {
      const errorHandlers = asyncFailureHandler;
      const handledErrorInstances: ExtendedGQLErrorInstance[] = [];

      for (const key in errorHandlers) {
        const errorType = key as ErrorTypeEnum;
        const errorHandler = errorHandlers[errorType] as FailureGQLAsyncErrorHandlerCallback<
          ExtendedAllGQLResponseErrors,
          ResultType
        >;
        const errorInstance = gqlInstanceErrorMap[errorType];
        const filteredErrors = errors
          .filter((e) => e instanceof errorInstance)
          .flatMap((e) => (e instanceof errorInstance ? [e] : []));

        if (errorHandler && filteredErrors.length > 0) {
          setResults(await errorHandler(filteredErrors));
          handledErrorInstances.push(errorInstance);
        }
      }

      if (catchRemainingFailureHandler) {
        const filteredErrors = errors.filter((e) => !handledErrorInstances.some((i) => e instanceof i)) as E;
        setResults(await catchRemainingFailureHandler(filteredErrors));
      }
    }

    return firstValueFrom(results$);
  }

  private static makeSyncResultsOf<T>(): [T[], (result?: T[] | T | void) => void] {
    const results: T[] = [];
    const setResults = (result?: T[] | T | void) => {
      if (typeof result === 'undefined') return;
      asArray(result as T[] | T).forEach((r) => {
        results.push(r);
      });
    };
    return [results, setResults];
  }

  private static makeAsyncResultsOf<T>(): [
    Observable<T[]>,
    (result?: Promise<T[] | T | void | undefined> | T[] | T | void) => void
  ] {
    const resultsSubject$ = new BehaviorSubject<T[]>([]);
    const setResults = async (result?: Promise<T[] | T | void> | T[] | T | void) => {
      const current = resultsSubject$.getValue();
      if (typeof result === 'undefined') return;
      const results = isPromiseLike(result) ? await result : (result as T[] | T);
      if (typeof results === 'undefined') return;
      resultsSubject$.next([...current, ...asArray(results)]);
    };
    return [resultsSubject$, setResults];
  }

  private static get noResultsError(): Error {
    return new Error('No results were returned.');
  }
}

export default GQLResult;
