import { type LabelControl } from '@valstro/fixatdl-core';
import { type ControlTransformer } from './control.transformer.types';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const labelControl: ControlTransformer<LabelControl> = (labelControl, _query, formValues) => {
  return {
    component: fixatdlFormComponentTypes.LABEL,
    name: labelControl.id,
    initialValue: AtdlFormats.coerceValue(formValues[labelControl.id]) || labelControl.label,
    label: labelControl.label
  };
};
