import { useRef, useMemo } from 'react';
import { type DependencyContainer } from 'tsyringe';
import { useWorkspace } from './workspace.hook';

export const useWorkspaceContainer = (): DependencyContainer => {
  const workspace = useWorkspace();
  const container = useMemo(() => {
    return workspace.meta.container;
  }, [workspace]);

  if (!container) {
    throw new Error(
      'Workspace container is not defined, please add the `foundationWorkspacePlugin` to the workspace.'
    );
  }

  return container;
};

/**
 * Hook to use a service from the currently scoped container
 *
 * @param token: Class to resolve
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const useService = <T extends { new (...args: any[]): {} }>(
  token: T,
  containerOverride?: DependencyContainer
) => {
  const container = useWorkspaceContainer();
  const c = containerOverride || container;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const service = useRef(c.resolve(token as any));
  return service.current as InstanceType<T>;
};
