import type { PropsWithChildren } from 'react';
import type { Observable } from 'rxjs';
import { WindowDecorationComponent as Window } from '@valstro/workspace-react';
import type { Actor, CommonWindowActorSchema } from '@valstro/workspace';
import { COMMON_PLATFORM_NAME, Plugin } from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { browserCommandPaletteActor } from './command-palette.browser.modal.actor';
import { tauriCommandPaletteActor } from './command-palette.tauri.actor';
import {
  type CommandPaletteOpenOptions,
  type CommonCommandPaletteActorSchema,
  COMMON_COMMAND_PALETTE
} from '@app/common/command-palette/command-palette.contracts';

function CommandPaletteDecoration({ children }: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  return <Window.ContentContainer>{children}</Window.ContentContainer>;
}

export type LeaderUnsubscribeFn = undefined | (() => void);

export type CommandPalettePluginOptions = (workspace: AppWorkspace) => {
  open$: Observable<CommandPaletteOpenOptions>;
  update$: Observable<CommandPaletteOpenOptions>;
  close$: Observable<unknown>;
  onLeaderReady?: (workspace: AppWorkspace) => Promise<LeaderUnsubscribeFn> | LeaderUnsubscribeFn;
  onBrowserChildWindowReady?: (workspace: AppWorkspace) => Promise<void> | void;
  onReady?(): void;
  onDispose?(): void;
};

/**
 * Manages commandPalette state in a singe process / window
 */
export const commandPalettePlugin = (optionsFn: CommandPalettePluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-command-palette-plugin',
    pluginFn: ({ workspace }) => {
      const {
        open$,
        update$,
        close$,
        onLeaderReady = () => {
          return undefined;
        },
        onBrowserChildWindowReady = () => {},
        onReady = () => {},
        onDispose = () => {}
      } = optionsFn(workspace);

      const options = {
        windowDecorationComponent: CommandPaletteDecoration,
        open$,
        update$,
        close$
      };

      let leaderUnsubscribe: LeaderUnsubscribeFn;

      // Register the commandPalette window actors
      workspace.getActorRegistry().register(browserCommandPaletteActor(options));
      workspace.getActorRegistry().register(tauriCommandPaletteActor(options));

      async function spawnCommandPaletteActor(
        rootWindowActor: Actor<CommonWindowActorSchema>,
        platformName: string,
        isLeader: boolean
      ) {
        const isBrowser = platformName === COMMON_PLATFORM_NAME.BROWSER;
        const shouldSpawnCommandPaletteActor = isLeader || (isBrowser && !isLeader);

        // This spawns the command palette actor on the leader in desktop & browser
        // but also subsequent browser child windows
        if (shouldSpawnCommandPaletteActor) {
          await rootWindowActor.spawnChild<CommonCommandPaletteActorSchema>({
            type: COMMON_COMMAND_PALETTE.TYPE,
            id: COMMON_COMMAND_PALETTE.getId(rootWindowActor.id)
          });

          // If this is the leader, call the onLeaderReady callback
          if (isLeader) {
            leaderUnsubscribe = await onLeaderReady(workspace);
          }

          // If this is a browser child window, call the onBrowserChildWindowReady callback
          if (isBrowser && !isLeader) {
            await onBrowserChildWindowReady(workspace);
          }
        }

        onReady();
      }

      // Spawn the command palette actor(s) on windowReady
      workspace.addHook('windowReady', ({ platformName, isLeader, rootWindowActor }) => {
        spawnCommandPaletteActor(rootWindowActor, platformName, isLeader).catch(console.error);
      });

      return function unsubscribe() {
        leaderUnsubscribe?.();
        onDispose();
      };
    }
  });
