import * as React from 'react';
import { cloneElement, useRef, useState } from 'react';
import type { CheckboxProps } from './checkbox';
import { Stack } from '../../layout/stack/stack';
import type { StackProps } from '../../layout/stack/stack';
import { getValidChildren } from '../../system/utils/children';
import { useId } from '../../system/hooks/use-id';
import { __DEV__ } from '../../system/utils/assertion';

export type CheckboxGroupProps = {
  /**
   * The id of the checkbox/radio group.
   */
  id?: CheckboxProps['id'];
  /**
   * The name of the checkbox/radio group. This prop is passed to each checbox
   */
  name?: CheckboxProps['name'];
  /**
   * The content of the checkbox group. Must be the `Checkbox` or `Radio` component
   */
  children?: React.ReactNode;
  /**
   * The initial value of the checkbox group
   */
  defaultValue?: Array<CheckboxProps['value']>;
  /**
   * The value of the checkbox group
   */
  value?: Array<CheckboxProps['value']>;
  /**
   * The callback fired when any children Checkbox/Radio is checked or unchecked
   */
  onChange?: (value: Array<CheckboxProps['value']>) => void;
  /**
   * If `true`, the checkboxes/radios will aligned horizontally.
   */
  isInline?: boolean;
  /**
   * Size of all wrapped checkboxes
   */
  size?: CheckboxProps['size'];
} & Omit<StackProps, 'onChange' | 'value' | 'size'>;

export const CheckboxGroup = React.forwardRef<HTMLDivElement, CheckboxGroupProps>((props, ref) => {
  const {
    onChange,
    name,
    size,
    defaultValue,
    isInline,
    value: valueProp,
    children,
    align = 'center',
    ...rest
  } = props;
  const [values, setValues] = useState(defaultValue || []);

  const { current: isControlled } = useRef(valueProp != null);
  const _values = isControlled ? valueProp : values;

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    let newValues = undefined;
    if (checked) {
      newValues = [...(_values || []), value];
    } else {
      newValues = (_values || []).filter((val) => val !== value);
    }

    !isControlled && setValues(newValues);
    onChange && onChange(newValues);
  };

  // If no name is passed, we'll generate a random, unique name
  const fallbackName = `checkbox-${useId()}`;
  const _name = name || fallbackName;

  const validChildren = getValidChildren(children);

  const clones = validChildren.map((child, index) => {
    return (
      <div key={index} className={child.props.className}>
        {cloneElement(child, {
          size,
          color: child.props.palette,
          name: `${_name}-${index}`,
          onChange: _onChange,
          isChecked: (_values || []).includes(child.props.value),
          verticalalign: 'bottom'
        })}
      </div>
    );
  });

  return (
    <Stack role="group" align={align} direction={isInline ? 'horizontal' : 'vertical'} ref={ref} {...rest}>
      {clones}
    </Stack>
  );
});

if (__DEV__) {
  CheckboxGroup.displayName = 'CheckboxGroup';
}
