import { convertToNumber } from '@oms/shared/util';
import ShortUniqueId from 'short-unique-id';
import type { Maybe, OrderSettingsFragment } from '@oms/generated/frontend';
import type { OrderSizeProfile } from './order-settings.types';

export const orderSettingsFieldIndexes = (input: string): number[] => {
  // parsing field name ex: 'orderSettings[0].rows[1].priceFrom' -> ['[0]', '[1]']
  const pattern = /\[(\d+)\]/g;
  const parsed = input.match(pattern) || [];
  // removing brackets for getting indexes ex: ['[0]', '[1]'] -> [0, 1]
  const indexes = parsed.map((match) => parseInt(match.replace(/\[|\]/g, ''), 10));

  return indexes;
};
export const orderSettingsToFormValues = (input: Maybe<OrderSettingsFragment[]>) => {
  return {
    orderSettings: input?.length
      ? input.map((profile) => ({
          id: profile?.id,
          description: profile?.description || '',
          rows:
            profile?.rows?.map((row) => ({
              id: row?.id,
              priceFrom: row?.priceFrom ? row.priceFrom : 0,
              priceTo: row?.priceTo !== -1 ? convertToNumber(row.priceTo) : Number.POSITIVE_INFINITY,
              orderSize: row?.orderSize ? convertToNumber(row.orderSize) : 1
            })) || []
        }))
      : []
  };
};

export const orderSettingsToOutput = (formValues: Maybe<OrderSizeProfile[]>) => {
  const uniqueId = new ShortUniqueId();
  return {
    orderSettings:
      formValues?.map((profile: OrderSizeProfile) => ({
        id: profile.id || uniqueId(),
        description: profile.description,
        rows: profile?.rows?.map((row) => ({
          id: row?.id || uniqueId(),
          priceFrom: convertToNumber(row?.priceFrom, 0),
          priceTo: row?.priceTo === Number.POSITIVE_INFINITY ? -1 : convertToNumber(row?.priceTo, -1),
          orderSize: convertToNumber(row?.orderSize, 1)
        }))
      })) || []
  };
};
