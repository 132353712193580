import { type FC } from 'react';
import { Flex } from '../../layout/flex/flex';
import type { AlertBannerProps } from './types';
import { useInternalProps, useClassName } from './util.internal';
import AlertBannerIcon from './alert-banner-icon.internal.component';
import SimpleTextWrapper from './simple-text-wrapper.internal.component';
import { TypographyStyle } from '../../theme/foundations/typographyStyle';
import { Box } from '../../system/components/box/box';

export const AlertBanner: FC<AlertBannerProps> = (props) => {
  const { messages, icon, title, iconColor, backgroundColor, sx } = useInternalProps(props);

  const wrapperClassName = useClassName('wrapper');
  const iconAndTitleWrapperClassName = useClassName('iconAndTitleWrapper');
  const titleClassName = useClassName('title');
  const messageWrapperClassName = useClassName('messageWrapper');
  const messageClassName = useClassName('message');

  return (
    <Flex className={wrapperClassName} sx={{ ...sx, backgroundColor }} data-testid="alert-banner">
      <Box className={iconAndTitleWrapperClassName}>
        <AlertBannerIcon title={title} icon={icon} color={iconColor} />
        <SimpleTextWrapper type={TypographyStyle.BaseB} className={titleClassName}>
          {title}
        </SimpleTextWrapper>
      </Box>
      <Box className={messageWrapperClassName}>
        {messages.map((message, index) => (
          <SimpleTextWrapper
            key={`${index}${message}`}
            type={TypographyStyle.SmallB}
            className={messageClassName}
          >
            {message}
          </SimpleTextWrapper>
        ))}
      </Box>
    </Flex>
  );
};

export default AlertBanner;
