import { GridReadyEvent } from '@ag-grid-community/core';
import throttle from 'lodash/throttle';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { GridIdService } from './grid-id.service';
import { getCanvasFont, getTextWidth } from '@oms/frontend-foundation';

export const COL_TEXT_ATTR = 'data-title';
export const COL_TEXT_SHORT_ATTR = 'data-short-title';

@scoped(Lifecycle.ContainerScoped)
export class GridColumnHeadersService {
  private _gridIdService: GridIdService;
  private _gridContainerCache: HTMLElement | null = null;

  constructor(@inject(GridIdService) gridIdService: GridIdService) {
    this._gridIdService = gridIdService;
  }

  get gridContainer() {
    this._gridContainerCache = this._gridContainerCache || this._gridIdService.getGridEl();
    return this._gridContainerCache;
  }

  public getColHeaderTextEl(colId: string | undefined): HTMLSpanElement | null {
    if (!colId) {
      return null;
    }
    return this.gridContainer?.querySelector(
      `.ag-header-cell[col-id="${colId}"] .ag-header-cell-text`
    ) as HTMLSpanElement | null;
  }

  public getCurrentColText(colEl: HTMLSpanElement | null): string | null {
    return colEl?.innerText || null;
  }

  public getColHeaderText(colEl: HTMLSpanElement | null): string | null {
    return this._getColHeaderTextByAttr(colEl, COL_TEXT_ATTR);
  }

  public getColHeaderShortText(colEl: HTMLSpanElement | null): string | null {
    return this._getColHeaderTextByAttr(colEl, COL_TEXT_SHORT_ATTR);
  }

  public isEllipsisActive(e: HTMLElement | null, originalW?: number) {
    if (!e) {
      return false;
    }

    const containerWidget = (e.parentElement?.offsetWidth ?? e.offsetWidth) - 24; // 24 represents the sort icon width + padding
    const breakpoint = originalW ?? e.scrollWidth;
    return containerWidget < breakpoint;
  }

  public async onInit(_e: GridReadyEvent) {
    // noop.
    // TODO: A better implementation may be to use a mutation& resize observer to detect changes in all the column header texts
    // TODO: Because it's unreliable to rely on the grid events to detect changes in the DOM.
  }

  /**
   * This method is used to resize the column header text
   */
  public colHeaderTextHandler = (colId?: string | null) => {
    if (!colId) {
      return;
    }

    /**
     * Get the column header element by its ID
     */
    const colEl = this.getColHeaderTextEl(colId);

    if (!colEl) {
      return;
    }

    /**
     * Get the long and short text for the column header from the attributes
     */
    const longText = this.getColHeaderText(colEl);
    const shortText = this.getColHeaderShortText(colEl);

    if (!longText) {
      return;
    }

    /**
     * Calculate the default width of the header with long text
     */
    const font = getCanvasFont(colEl);
    const textWidth = getTextWidth(longText, font);

    /**
     * Using the default text widget, calculate if it is ellipsised
     */
    const isShort = this.isEllipsisActive(colEl, textWidth);

    /**
     * Toggle between short & long text
     */
    if (shortText && isShort) {
      colEl.innerText = shortText;
    } else if (colEl && longText) {
      colEl.innerText = longText;
    }
  };

  /**
   * This method is used to resize the column header text
   */
  public throttledColHeaderTextHandler = throttle(this.colHeaderTextHandler, 100);

  private _getColHeaderTextByAttr(colEl: HTMLSpanElement | null, attr: string): string | null {
    if (!colEl) {
      return null;
    }
    const text = colEl.getAttribute(attr);
    return text || null;
  }
}
