import type { DetailGridBuilder } from '@oms/frontend-vgrid';
import { type VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment } from '@oms/generated/frontend';
import {
  TradingOrdersDetailGrid,
  type TradingOrderDetailGridProps
} from './investor-order-monitor.details.grid';
import { InvestorOrderTradingOrdersStatsGroupHeader } from '../trading-order-monitor/trading-order-monitor.stats-header';

const STATS_HEADER_HEIGHT = 48;

export const investorOrderMasterDetail = (md: DetailGridBuilder<IOFragment, TradingOrderDetailGridProps>) =>
  md
    .groupByColumn('instrument.mappings.displayCode', (d) => !!d?.allocationMaps?.nodes?.length)
    .headerComponent(InvestorOrderTradingOrdersStatsGroupHeader)
    .detailComponent(TradingOrdersDetailGrid)
    .getDetailProps(({ data }) => ({ investorOrderId: data?.id }))
    .getDetailRowHeightParams(({ data }) => {
      const numberOfTradingOrders = (
        data?.allocationMaps?.nodes?.flatMap((v) => (v?.tradingOrder ? [v.tradingOrder] : [])) || []
      ).length;
      return {
        numberOfRows: numberOfTradingOrders,
        headerComponentHeight: STATS_HEADER_HEIGHT,
        hideToolbar: true,
      };
    });
