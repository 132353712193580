import { type RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';
import { convertToNumber } from '@oms/shared/util';

export const VALIDATE_ROUTE_ORDER_QUANTITY = 'validateRouteOrderQuantity';

export const validateRouteOrderQuantity =
  () => (value: string | number | undefined, allValues?: RouteOrderFormValues) => {
    if (!value) {
      return null;
    }

    const formValues: Partial<RouteOrderFormValues> = allValues ? allValues : {};
    const { hiddenFormInfo } = formValues || {};

    switch (hiddenFormInfo?.type) {
      case 'modify':
      case 'create':
        return null;
      case 'route': {
        const originalOrderQuantity = hiddenFormInfo?.investorOrder?.quantity;

        if (originalOrderQuantity === undefined || originalOrderQuantity === null) {
          return null;
        }

        const currentQuantity = convertToNumber(value);

        if (currentQuantity > originalOrderQuantity) {
          return `Quantity cannot be greater than ${originalOrderQuantity}`;
        }

        return null;
      }
      default:
        return null;
    }
  };

export const VALIDATE_ROUTE_ORDER_MINIMAL_QUANTITY = 'validateRouteOrderMinimalQuantity';

export const validateRouteOrderMinimalQuantity = () => (value: string | number | undefined) =>
  convertToNumber(value) >= 1 ? null : 'Min 1 share';
