import type { RxJsonSchema } from 'rxdb-v15';
import type { ActorScoped } from './types';

export const actorScopedSchemaDefaults: RxJsonSchema<ActorScoped> = {
  title: 'actor-scoped schema',
  description: 'actor-scoped state model',
  version: 0,
  keyCompression: false,
  primaryKey: 'scopedActorId',
  type: 'object',
  properties: {
    scopedActorId: {
      type: 'string',
      maxLength: 128
    }
  },
  required: ['scopedActorId'],
  indexes: []
} as const;
