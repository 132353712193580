import { useCallback } from 'react';
import {
  type InvestorOrderAuditTrail,
  InvestorOrderAuditTrailService
} from '@app/data-access/services/trading/investor-orders/investor-order-audit-trail.service';
import { useService } from '@oms/frontend-foundation';
import { useVGrid, VGrid, type GridBuilder } from '@oms/frontend-vgrid';
import { auditTrailColumnLibrary } from './investor-order-view.audit-trail.columns';
import type { VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { OfflineGridEventHandler } from '@app/data-access/services/system/grids/offline-grid.event-handler';

export const InvestorOrderAuditTrailGrid = ({
  investorOrderId
}: {
  investorOrderId: VisibleInvestorOrderInfoWithAllocationsFragment['id'];
}) => {
  const auditTrailService = useService(InvestorOrderAuditTrailService);

  const builder = useCallback(
    (b: GridBuilder<InvestorOrderAuditTrail>) =>
      b
        .columnLibrary(auditTrailColumnLibrary)
        .datasource((d) =>
          d.source(auditTrailService.getAuditTrail$(investorOrderId)).rowId((r) => r.data.id)
        )
        .injectEvents([OfflineGridEventHandler])
        .sideBar(),
    [auditTrailService, investorOrderId]
  );

  const props = useVGrid('investor-order-audit-trail', builder, [auditTrailService]);
  return <VGrid {...props} />;
};

export default InvestorOrderAuditTrailGrid;
