import type { ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';

export const refreshServersideAction: ActionDefFactory<any> = (builder) =>
  builder
    .name('refresh_serverside')
    .menu((m) => m.name('Refresh Data'))
    .onChange<ActionComponentConfig<any>>((ctx) => {
      if (ctx.lifecycle === 'change') {
        ctx.api.refreshServerSide({
          purge: true
        });
      }
    });
