import { FC } from 'react';
import { Box } from '../../system/components/box/box';
import type { LinkProps } from '../link/link';
import type { NumericProps } from './components/numeric/numeric';
import type { TextFieldProps } from './components/text-field/text-field';
import type { BadgeProps } from './components/badge/badge';
import * as classes from './display-field.css';
import clsx from 'clsx';
import { TextEllipser } from '../text-ellipser/text-ellipser';
import type { InWindowPopoverProps } from './components/in-window-popover/in-window-popover';
import type { DisplayCompShape, UseDisplayCompProps } from '../display-grid/display-grid';
import { DISPLAY_FIELD_COMPONENT_TYPE } from './display-field.contracts';
import { displayFieldComponentMapper, type DisplayFieldComponentProps } from './display-field.mapper';
import { FlexProps, Flex } from '../../layout/flex/flex';
import { TextProps, Text } from '../../layout/text/text';

export type DisplayFieldProps<TFieldComps extends DisplayCompShape = undefined> = {
  customDisplayFieldComponents?: {
    [key in TFieldComps['type']]: FC<TFieldComps>;
  };
  label?: string;
  ariaLabel?: string;
  layout?: 'horizontal' | 'vertical';
  component: UseDisplayCompProps<DisplayFieldComponentProps, TFieldComps>;
  sx?: FlexProps['sx'];
  style?: React.CSSProperties;
  labelSize?: 'small' | 'medium' | 'large';
  labelMargin?: number;
  testId?: string;
};

const labelSizeMapping: Record<DisplayFieldProps['labelSize'], TextProps['type']> = {
  small: 'gridTiny',
  medium: 'gridBase',
  large: 'gridLarge'
};

export function DisplayField<TFieldComps extends DisplayCompShape = undefined>({
  label,
  ariaLabel,
  layout = 'horizontal',
  component,
  sx,
  style,
  labelSize,
  labelMargin,
  customDisplayFieldComponents,
  testId
}: DisplayFieldProps<TFieldComps>) {
  const valueComponent = () => {
    const { type } = component;

    switch (type) {
      case DISPLAY_FIELD_COMPONENT_TYPE.Numeric: {
        return displayFieldComponentMapper[type](component as NumericProps);
      }
      case DISPLAY_FIELD_COMPONENT_TYPE.Link: {
        return displayFieldComponentMapper[type](component as LinkProps);
      }
      case DISPLAY_FIELD_COMPONENT_TYPE.Text: {
        return displayFieldComponentMapper[type](component as TextFieldProps);
      }
      case DISPLAY_FIELD_COMPONENT_TYPE.Badge: {
        return displayFieldComponentMapper[type](component as BadgeProps);
      }
      case DISPLAY_FIELD_COMPONENT_TYPE.InWindowPopover: {
        return displayFieldComponentMapper[type](component as InWindowPopoverProps);
      }
      default: {
        const item = customDisplayFieldComponents[type] as FC<TFieldComps>;

        if (!item) {
          return null;
        }

        return item(component as TFieldComps);
      }
    }
  };

  const isHorizontalLayout = layout === 'horizontal';
  const isVerticalLayout = layout === 'vertical';

  return (
    <Flex
      direction={isHorizontalLayout ? 'row' : 'column'}
      align={isHorizontalLayout ? 'center' : 'flex-start'}
      sx={sx}
      style={style}
      className={clsx(classes.displayField)}
      data-testid="display-field"
    >
      {label && (
        <Box
          sx={{
            // flex gives label and value 50/50 of the container width, with a space between them
            // in case label and value need to be closer to each other, custom margin can be passed
            ...(!labelMargin ? { flex: 1 } : null),
            color: sx?.color || 'text.semiMinor',
            userSelect: 'none',
            ...(isVerticalLayout ? { marginBottom: 0.5 } : null),
            ...(isHorizontalLayout ? { marginRight: '-2', overflow: 'hidden' } : null)
          }}
          style={{ marginRight: labelMargin ? labelMargin : null }}
        >
          <TextEllipser>
            <Text type={labelSizeMapping[labelSize] || 'baseB'} data-testid="label">
              {label}
            </Text>
          </TextEllipser>
        </Box>
      )}
      <Box
        sx={{
          flex: 1,
          color: 'white',
          width: 'full',
          ...(isHorizontalLayout ? { overflow: 'hidden' } : null),
          ...(!labelMargin ? { flex: 1 } : null)
        }}
        data-testid={testId ?? 'value'}
        aria-label={ariaLabel || label}
      >
        {valueComponent()}
      </Box>
    </Flex>
  );
}
