/* eslint-disable react/jsx-no-bind */
import { forwardRef, KeyboardEvent, useCallback, useMemo } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useFormControl } from '../form-control/form-control';
import { Input } from '../input/input';
import './date-picker.scss';

import { useControllableState } from '../../hooks/use-controllable';
import { DatePickerProps } from './date-picker.types';
import { PreciseTimeInput } from '../shared/precise-time-input/precise-time-input.component';
import { CalendarIcon } from '@radix-ui/react-icons';
import { Icon } from '../icon/icon';
import { __DEV__ } from '../../system/utils/assertion';
import { Box } from '../../system/components/box/box';

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>((props, ref) => {
  // This hook grabs context from the FormControl component,
  // We can now use this and merge it with the isDisabled that may / may not be passed directly into this comp
  const { isReadOnly, isDisabled, isInvalid, isRequired } = useFormControl(props);

  // These are ALL the date picker props we are interested in
  const {
    id,
    name,
    selected,
    showMonthDropdown,
    showYearDropdown,
    dropdownMode,
    customInput: _customInput,
    customTimeInput: _customTimeInput,
    placeholderText = 'MM/DD/YYYY',
    dateFormat = undefined,
    dateFormatCalendar,
    inline,
    showTimeInput,
    showTimeSelect,
    showTimeSelectOnly,
    timeFormat,
    timeIntervals,
    timeCaption,
    maxDate,
    minDate,
    isClearable = false,
    showPopperArrow = false,
    onCalendarClose,

    // InputProps which we'll combine with FormControl props to give the input
    // the correct values and to style itself through aria attributes
    isReadOnly: _isReadOnly,
    isDisabled: _isDisabled,
    isInvalid: _isInvalid,

    // External controlling props
    onChange: _onChange,
    value: _value,
    open: _open,
    onOpenChange: _onOpenChange,

    // These are also important for DDF to run validators.
    // These actually already match both InputProps and ReactDatePickerProps.
    // However, because we are using our own custom Input, we pass them here.
    onBlur,
    onFocus,

    // Optionally, we can hi-jack the onKeyPress event to format the date,
    // We also need to make sure to run the same event if it gets passed down.
    // By default, if omitted, we allow the default date formatting
    customDateFormatting,
    onKeyPress,

    // The rest are styling props
    tabIndex,
    ...inputAndBoxStylingProps
  } = props;

  // Using this handy hook to merge the incoming value/onChange with an internat setState.
  // This means, if _value / _onChange is passed as props, it will use those, otherwise just an internal useState.
  const [date, onChange] = useControllableState({
    value: _value,
    onChange: _onChange,
    defaultValue: null
  });

  const [open, onOpenChange] = useControllableState({
    value: _open,
    onChange: _onOpenChange,
    defaultValue: false
  });

  const applyCustomFormatting = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    // Making all if statements slightly more verbose to make it easier to understand,
    // if it's not a number, don't do anything
    if (!/^[0-9]/.test(e.key)) {
      e.preventDefault();
    }

    // using current target instead of target for typescript to be happy
    const input = e.currentTarget;
    const inputLength = input.value.length;

    // Adding all conditions in one line, rather than nested if statements,
    // also using key vs keyCode because it's deprecated.
    if (inputLength !== 1 && inputLength !== 3 && e.key === '/') {
      e.preventDefault();
    }

    // Automatically appending the slash if the user has entered the first two digits
    // or has entered the first 5 digits
    if (inputLength === 2 || inputLength === 5) {
      input.value += '/';
    }

    // Adding a limit to the length of the input
    if (inputLength > 9) {
      e.preventDefault();
    }
  }, []);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      // Run onKeyPress if it's passed down from the parent
      if (onKeyPress) onKeyPress(e);
      if (customDateFormatting) applyCustomFormatting(e);
    },
    [applyCustomFormatting, customDateFormatting, onKeyPress]
  );

  const onSelect: ReactDatePickerProps['onSelect'] = useCallback(
    (newDate) => {
      onChange(newDate);
      onOpenChange(false);
    },
    [onChange, onOpenChange]
  );

  const onBlurFn: ReactDatePickerProps['onBlur'] = useCallback(
    (e) => {
      onBlur && onBlur(e);
      if (!showTimeInput) {
        // Disabling for when time input is used as it closes the popover whenever the time input is selected
        onOpenChange(false);
      }
      onChange(date);
    },
    [onBlur, showTimeInput, onOpenChange, onChange, date]
  );

  const onFocusFn: ReactDatePickerProps['onFocus'] = useCallback(
    (e) => {
      onFocus && onFocus(e);
      onOpenChange(true);
    },
    [onFocus, onOpenChange]
  );

  const customInput: ReactDatePickerProps['customInput'] = useMemo(
    () =>
      _customInput ?? (
        <Input
          type="datetime"
          step="1"
          ref={ref}
          onKeyPress={handleKeyPress}
          {...inputAndBoxStylingProps}
          tabIndex={tabIndex}
        />
      ),
    [_customInput, handleKeyPress, inputAndBoxStylingProps, ref, tabIndex]
  );

  const customTimeInput: ReactDatePickerProps['customTimeInput'] = useMemo(() => {
    if (!showTimeInput) return undefined;
    return (
      _customTimeInput ?? (
        <PreciseTimeInput
          min={minDate}
          max={maxDate}
          onValueChange={(_value, date) => {
            onChange(date);
          }}
        />
      )
    );
  }, [showTimeInput, _customTimeInput, minDate, maxDate, onChange]);

  return (
    <Box className="date-picker-wrapper">
      <ReactDatePicker
        selected={date} // ReactDatePicker using selected to control its value
        onChange={onChange} // And onChange to change the value/selected
        onSelect={onSelect} // Also running the onChange as soon as a day is picked
        inline={inline}
        onCalendarClose={onCalendarClose}
        customInput={customInput}
        showTimeInput={showTimeInput}
        customTimeInput={customTimeInput}
        tabIndex={tabIndex}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode={dropdownMode}
        maxDate={maxDate}
        minDate={minDate}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        // These are InputProps which ReactDatePicker adds to the customInput
        id={id}
        name={name}
        placeholderText={placeholderText}
        autoComplete="off"
        open={open}
        onClickOutside={() => onOpenChange(false)}
        onBlur={onBlurFn}
        disabledKeyboardNavigation={false}
        onFocus={onFocusFn}
        readOnly={isReadOnly || _isReadOnly}
        disabled={isDisabled || _isDisabled}
        required={isRequired}
        ariaInvalid={isInvalid || _isInvalid ? 'true' : undefined}
        ariaRequired={isRequired ? 'true' : undefined}
        // Time picker
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeFormat={timeFormat}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        dateFormat={dateFormat}
        // Disable range
        selectsRange={false}
      />
      {!inline && (
        <Icon
          className="date-picker-icon-right"
          label="Calendar"
          as={CalendarIcon}
          sx={{ color: 'icons.semiMinor' }}
        />
      )}
    </Box>
  );
});

if (__DEV__) {
  DatePicker.displayName = 'DatePicker';
}
