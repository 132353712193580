import { type Observable } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import type { MarketData, MarketDataEvent } from './marketdata.common';
import { type IMarketDataService } from './marketdata.common';
import { MdaasMarketDataService } from './mdaas-marketdata.service';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { ProcessState } from '@app/data-access/memory/process-id.subject';

@singleton()
export class MarketDataService implements IMarketDataService {
  protected _service: IMarketDataService;
  protected _workspace: AppWorkspace;
  protected _processService: ProcessState;

  constructor(
    @inject(AppWorkspace) workspace: AppWorkspace,
    @inject(ProcessState) processService: ProcessState
  ) {
    this._workspace = workspace;
    this._processService = processService;
    this._service = new MdaasMarketDataService(this._workspace, this._processService);
  }

  public observe(...tickers: string[]): Observable<MarketDataEvent> {
    return this._service.observe(...tickers);
  }

  public hasAllTickers(...tickers: string[]): boolean {
    return this._service.hasAllTickers(...tickers);
  }

  public read(ticker: string): MarketData | undefined {
    return this._service.read(ticker);
  }

  public readAll(...tickers: string[]): (MarketData | undefined)[] {
    return this._service.readAll(...tickers);
  }
}
