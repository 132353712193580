import { BuilderCallback } from '@oms/shared/util-types';
import { compileActions } from './action.compiler';
import { DEFAULT_ACTIONS } from '../actions/action.registry';
import { AnyRecord } from '@oms/frontend-foundation';
import {
  DefaultActionDefs,
  ActionDef,
  ActionDefKey,
  ActionDefFactory,
  ActionSchema,
  GridActionSettings,
  ActionsConfig
} from '../models/actions.model';

export class GridActionsSchemaBuilder<
  TData extends AnyRecord,
  TActionDefs extends string = DefaultActionDefs
> {
  private _actions: ActionDef<TData>[] = [];
  private _registry: Record<ActionDefKey<TActionDefs>, ActionDefFactory> = {
    ...(DEFAULT_ACTIONS as Record<ActionDefKey<TActionDefs>, ActionDefFactory>)
  };

  public registry(
    registry: Record<TActionDefs, ActionDefFactory>
  ): GridActionsSchemaBuilder<TData, TActionDefs> {
    this._registry = { ...this._registry, ...registry };
    return this;
  }

  public action(schema: ActionSchema<TData, TActionDefs>): GridActionsSchemaBuilder<TData, TActionDefs> {
    const defs = compileActions<TData, TActionDefs>(this._registry, schema);

    this._actions.push(...defs);

    return this;
  }

  public build(): Record<string, ActionDef<TData>> {
    const result: Record<string, ActionDef<TData>> = {};
    this._actions = this._actions?.length ? this._actions : [];
    this._actions.forEach((a) => {
      result[a.name] = a;
    });

    return result;
  }
}

export class GridActionsSettingsBuilder<TData extends AnyRecord> {
  private _gridActionSettings!: GridActionSettings;

  public fromFile(settings: GridActionSettings): GridActionsSettingsBuilder<TData> {
    this._gridActionSettings = settings;

    return this;
  }

  public fromDb(_settingsKey: string): GridActionsSettingsBuilder<TData> {
    return this;
  }

  public build(): GridActionSettings {
    return this._gridActionSettings;
  }
}

export class GridActionsBuilder<TData extends AnyRecord> {
  private _actionsConfig: Partial<ActionsConfig<TData>> = {};

  public schema<TActionDefs extends string = DefaultActionDefs>(
    cb: BuilderCallback<GridActionsSchemaBuilder<TData, TActionDefs>>
  ): GridActionsBuilder<TData> {
    this._actionsConfig.schema = cb(new GridActionsSchemaBuilder<TData, TActionDefs>()).build();
    return this;
  }

  public settings(cb: BuilderCallback<GridActionsSettingsBuilder<TData>>): GridActionsBuilder<TData> {
    this._actionsConfig.settings = cb(new GridActionsSettingsBuilder<TData>()).build();
    return this;
  }

  public build(): ActionsConfig<TData> {
    this._actionsConfig.schema = this._actionsConfig.schema ? this._actionsConfig.schema : {};
    this._actionsConfig.settings = this._actionsConfig.settings ? this._actionsConfig.settings : {};

    return this._actionsConfig as ActionsConfig<TData>;
  }
}
