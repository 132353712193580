import { Cross1Icon } from '@radix-ui/react-icons';
import type { AnyRecord } from '@oms/frontend-foundation';
import { type ActionDefFactory } from '../models/actions.model';

export const CLEAR_GRID_FILTER_ACTION_TYPE = 'clear-grid-filter';

export function createClearGridFilterAction<TData extends AnyRecord>(): ActionDefFactory<TData> {
  return (b) =>
    b
      .name('clear_grid_filters')
      .toolbar((t) =>
        t
          .component('action-button')
          .id('clear_filter_button')
          .location('HorizontalToolbarLeft')
          .props({
            variant: 'link',
            isDisabled: true,
            leftIcon: <Cross1Icon />,
            content: 'Clear All Filters'
          })
      )
      .menu((m) => m.name('Clear filters').visible(({ gridApi }) => !!gridApi.isAnyFilterPresent()))
      .onChange<AnyRecord>((e) => {
        const filtersPresent = e.api.isAnyFilterPresent();
        e.notify({ isDisabled: !filtersPresent });
        if (filtersPresent && e.lifecycle === 'change') {
          e.api.setFilterModel(null);
          e.api.onFilterChanged();
        }
      });
}
