import { useVGrid, VGrid, CLEAR_GRID_FILTER_ACTION_TYPE, type GridBuilder } from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import {
  type InvestorOrderTradingOrderRow,
  InvestorOrderTradingOrdersService
} from '@app/data-access/services/trading/investor-orders/investor-order-trading-orders.service';
import { useCallback } from 'react';
import { investorOrderTradingOrdersColumnLibrary } from './investor-order-view.trading-orders.columns';
import { OfflineGridEventHandler } from '@app/data-access/services/system/grids/offline-grid.event-handler';

export const InvestorOrderTradingOrdersGrid = ({ id }: { id: string }) => {
  const ioTradingOrdersService = useService(InvestorOrderTradingOrdersService);

  const builder = useCallback(
    (b: GridBuilder<InvestorOrderTradingOrderRow>) =>
      b
        .columnLibrary(investorOrderTradingOrdersColumnLibrary)
        .sideBar()
        .datasource((d) => d.source(ioTradingOrdersService.watchAll$(id)).rowId((r) => r.data.id))
        .injectEvents([OfflineGridEventHandler])
        .actions((a) =>
          a.schema((s) => s.action(CLEAR_GRID_FILTER_ACTION_TYPE)).settings((s) => s.fromFile({}))
        ),
    [ioTradingOrdersService, id]
  );

  const props = useVGrid<InvestorOrderTradingOrderRow>('investor-order-trading-orders', builder, [
    ioTradingOrdersService
  ]);
  return <VGrid {...props} />;
};

export default InvestorOrderTradingOrdersGrid;
