import { createContext, useState, useCallback, type PropsWithChildren, useMemo } from 'react';
import type {
  RemoveToolbarActionArgs,
  ToolbarActionArgs,
  ToolbarContextProps,
  VisibleToolbarActions
} from './toolbar-context.types';
import { useToolbar } from './hooks/use-toolbar.hook';

export const ToolbarContext = createContext<ToolbarContextProps | null>(null);

export const useToolbarProvider = () => {
  const [visibleToolbarActions, setVisibleToolbarActions] = useState<VisibleToolbarActions>([]);

  const addToolbarAction = useCallback((options: ToolbarActionArgs) => {
    if (!['window', 'flexlayout-tabset'].includes(options.location)) {
      throw new Error('Invalid location for toolbar action. Must be "window" or "flexlayout-tabset"');
    }

    setVisibleToolbarActions((prevState) => {
      switch (options.location) {
        case 'flexlayout-tabset':
        case 'window': {
          // Prevent duplicate actions from being added
          if (
            prevState.some((prevAction) => prevAction.id === options.id) &&
            typeof options.index === 'undefined'
          ) {
            return prevState;
          }
          if (typeof options.index === 'number') {
            return [...prevState.slice(0, options.index), options, ...prevState.slice(options.index)];
          } else {
            return prevState.concat(options);
          }
        }
        default: {
          return prevState;
        }
      }
    });
  }, []);

  const removeToolbarAction = useCallback(({ location, id }: RemoveToolbarActionArgs) => {
    if (!['window', 'flexlayout-tabset'].includes(location)) {
      throw new Error('Invalid location for toolbar action. Must be "window" or "flexlayout-tabset"');
    }

    setVisibleToolbarActions((prevState) => {
      return prevState.filter((action) => action.location === location && action.id !== id);
    });
  }, []);

  return useMemo(
    () => ({ visibleToolbarActions, addToolbarAction, removeToolbarAction }),
    [visibleToolbarActions, addToolbarAction, removeToolbarAction]
  );
};

export const ToolbarProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const context = useToolbarProvider();
  return <ToolbarContext.Provider value={context}>{children}</ToolbarContext.Provider>;
};

export const FlexLayoutToolbarProvider: React.FC<PropsWithChildren<Partial<ToolbarContextProps>>> = ({
  children
}) => {
  const { addToolbarAction: windowAddToolbarAction, removeToolbarAction: windowRemoveToolbarAction } =
    useToolbar();

  const { visibleToolbarActions, addToolbarAction, removeToolbarAction } = useToolbarProvider();

  const addToolbarActionRouter = useCallback(
    (options: ToolbarActionArgs) => {
      switch (options.location) {
        case 'window': {
          windowAddToolbarAction(options);
          break;
        }
        case 'flexlayout-tabset': {
          addToolbarAction(options);
          break;
        }
        default: {
          return;
        }
      }
    },
    [windowAddToolbarAction, addToolbarAction]
  );

  const removeToolbarActionRouter = useCallback(
    (options: RemoveToolbarActionArgs) => {
      switch (options.location) {
        case 'window': {
          windowRemoveToolbarAction(options);
          break;
        }
        case 'flexlayout-tabset': {
          removeToolbarAction(options);
          break;
        }
        default: {
          return;
        }
      }
    },
    [removeToolbarAction, windowRemoveToolbarAction]
  );

  const context = useMemo(
    () => ({
      visibleToolbarActions,
      addToolbarAction: addToolbarActionRouter,
      removeToolbarAction: removeToolbarActionRouter
    }),
    [visibleToolbarActions, addToolbarActionRouter, removeToolbarActionRouter]
  );

  return <ToolbarContext.Provider value={context}>{children}</ToolbarContext.Provider>;
};
