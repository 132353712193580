import { Checkbox, Flex } from '@oms/shared-frontend/ui-design-system';
import { type ICellRendererParams } from '@ag-grid-community/core';
import { type ColumnBuilderCallback } from '@oms/frontend-vgrid';
import { type CrossPrincipalFillRow } from '../cross-principal-fill.form-common';
import { type ColumnLibrary } from '@oms/frontend-vgrid';
import { sharedIdCol } from '@app/common/grids/columns/generic-cols';
import { sharedInvestorAccountCol } from '@app/common/grids/columns/order-cols';
import { sharedPriceCol } from '@app/common/grids/columns/generic-cols';
import { sharedQuantityCol } from '@app/common/grids/columns/generic-cols';
import { Header } from './header';
import * as styles from './cross-principal-fill-orders-field.css';
import { OrderType } from '@oms/generated/frontend';

const defaultCol: ColumnBuilderCallback<CrossPrincipalFillRow> = (c) => c.resizable().sortable().filter();

export const checkboxColumnRenderer: React.FC<ICellRendererParams> = ({ value }) => {
  return (
    <Flex align="center" sx={{ height: 'full' }}>
      <Checkbox isChecked={value} />
    </Flex>
  );
};

const id: ColumnBuilderCallback<CrossPrincipalFillRow> = (c) =>
  sharedIdCol<CrossPrincipalFillRow>(c, 'order.id').header('Account').sort('asc').hide();

const checkbox: ColumnBuilderCallback<CrossPrincipalFillRow> = (c) =>
  c
    .width(40)
    .cell((c) => c.renderer(checkboxColumnRenderer))
    .field('state.isChecked')
    .filter(false);

const account: ColumnBuilderCallback<CrossPrincipalFillRow> = (c) =>
  sharedInvestorAccountCol<CrossPrincipalFillRow>(c, 'order.investorAccount.name')
    .header('Account')
    .width(160);

const price: ColumnBuilderCallback<CrossPrincipalFillRow> = (c) =>
  sharedPriceCol<CrossPrincipalFillRow>(c, 'order.limitPrice')
    .header('Px')
    .width(75)
    .cell((c) =>
      c.valueFormatter((args) => {
        return args.data?.order.limitPrice?.toString() || OrderType.Market;
      })
    );

const openQuantity: ColumnBuilderCallback<CrossPrincipalFillRow> = (c) =>
  sharedQuantityCol<CrossPrincipalFillRow>(c, 'order.openQuantity')
    .header('Open qty')
    .shortHeader('Open')
    .width(120);

const fillQuantity: ColumnBuilderCallback<CrossPrincipalFillRow> = (c) =>
  sharedQuantityCol<CrossPrincipalFillRow>(c, 'state.fillQuantity')
    .header('Fill qty')
    .shortHeader('Fill')
    .width(120)
    .colDef({
      editable: (params) => {
        return !!params.data?.state.isChecked;
      },
      cellEditor: 'agNumberCellEditor',
      cellClass: (params) => {
        return params.data?.state.isManualQuantity ? styles.edited : styles.notEdited;
      }
    })
    .cell((c) =>
      c
        .valueGetter((args) => args.data)
        .valueFormatter((args) => {
          return args.data?.state.fillQuantity?.toString() || '0';
        })
    );

const colGroup: ColumnBuilderCallback<CrossPrincipalFillRow> = (c) =>
  c.colGroup((g) =>
    g.headerComp(Header).titleBar().columns([id, checkbox, account, price, openQuantity, fillQuantity])
  );

export const buyOrderCrossColumnLibrary: ColumnLibrary<CrossPrincipalFillRow> = {
  defaultColumn: defaultCol,
  columns: [colGroup]
};

export const sellOrderCrossColumnLibrary: ColumnLibrary<CrossPrincipalFillRow> = {
  defaultColumn: defaultCol,
  columns: [colGroup]
};
