import type { DependencyContainer } from 'tsyringe';
import type { TradeInfoFragment } from '@oms/generated/frontend';
import { TradesService } from '@app/data-access/services/trading/trades/trades.service';
import TradeRepairRequestService from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import { TradeModifyEntryType, type TradeModifyFormInfo, type TradeModifyInput } from './trade-modify.form-common';
import type { TradeModifyValues } from './trade-modify.form-contract';
import { createInstrumentComboboxItem } from '@app/forms/common/fields/symbol-field/symbol.field.common';
import { createAccountComboboxItem } from '@app/forms/common/fields/account-field/account.field.common';

export async function getTradeInfo(
  input: TradeModifyInput,
  container: DependencyContainer
): Promise<TradeModifyFormInfo> {
  if (!input.id) {
    throw new Error('Trade ID is required');
  }

  if (!input.entryType) {
    throw new Error('Entry type is required');
  }

  const tradesService = container.resolve(TradesService);
  const repairService = container.resolve(TradeRepairRequestService);

  switch (input.entryType) {
    case TradeModifyEntryType.TradeModify: {
      const response = await tradesService.getById(input.id);
      if (response.isFailure()) {
        throw new Error('Failed to get trade');
      }

      if (response.isSuccess() && response.value.data.trade) {
        return {
          type: TradeModifyEntryType.TradeModify,
          trade: response.value.data.trade
        };
      }
      break;
    }
    case TradeModifyEntryType.TradeRepair: {
      const repairResponse = await repairService.getTradeRepairRequestById(input.id);
      if (repairResponse.isFailure()) {
        throw new Error('Failed to get trade repair');
      }

      if (repairResponse.isSuccess() && repairResponse.value.data.getTradeRepairRequestById) {
        return {
          type: TradeModifyEntryType.TradeRepair,
          tradeRepair: repairResponse.value.data.getTradeRepairRequestById
        };
      }
      break;
    }
    default:
      throw new Error('Invalid entry type');
  }

  throw new Error('Trade information could not be retrieved');
}

export function getInitialCommonFormValues(trade: TradeInfoFragment): Partial<TradeModifyValues> {
  const initialFormValues: Partial<TradeModifyValues> = {};
  const tradingAccount = trade.tradingOrder?.tradingAccount;

  initialFormValues.side = trade.side;
  initialFormValues.instrument = trade.instrument ? createInstrumentComboboxItem(trade.instrument) : undefined;
  initialFormValues.tradeContraAccountId = tradingAccount
    ? createAccountComboboxItem({
        id: tradingAccount.id,
        name: tradingAccount.name
      })
    : undefined;
  initialFormValues.tradePrice = trade.tradePrice || undefined;
  initialFormValues.tradeQuantity = trade.tradeQuantity || undefined;
  initialFormValues.tradeDateTime = trade.createdDateTime || undefined;
  initialFormValues.settleDate = undefined;
  initialFormValues.settleType = undefined;
  initialFormValues.tradeTags = undefined;

  return initialFormValues;
}