import {
  useVGrid,
  VGrid,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  CLEAR_GRID_FILTER_ACTION_TYPE,
  type GridBuilder
} from '@oms/frontend-vgrid';
import type {
  VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment,
  ExecutionInfoFragment
} from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { OfflineGridEventHandler } from '@app/data-access/services/system/grids/offline-grid.event-handler';
import { InvestorOrderExecutionsService } from '@app/data-access/services/trading/investor-orders/investor-order-executions.service';
import { useCallback } from 'react';
import { investorOrderExecutionsColumnLibrary } from './investor-order-view.executions.columns';
import { cancelExecutionsAction } from './grid-actions/cancel.executions.action';
import { applySettlementFXRateAction } from './grid-actions/apply-settlement-fx-rate.action';

export const InvestorOrderExecutionsGrid = ({ id }: { id: IOFragment['id'] }) => {
  const ioExecutionsService = useService(InvestorOrderExecutionsService);

  const builder = useCallback(
    (b: GridBuilder<ExecutionInfoFragment>) =>
      b
        .columnLibrary(investorOrderExecutionsColumnLibrary)
        .rowSelection((c) => c.multiple())
        .sideBar()
        .datasource((d) => d.source(ioExecutionsService.pollById$(id)).rowId((r) => r.data?.id))
        .injectEvents([OfflineGridEventHandler])
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(cancelExecutionsAction)
                .action(applySettlementFXRateAction)
            )
            .settings((s) => s.fromFile({}))
        ),

    [ioExecutionsService, id]
  );

  const props = useVGrid<ExecutionInfoFragment>('investor-order-executions', builder, [ioExecutionsService]);

  return <VGrid {...props} />;
};

export default InvestorOrderExecutionsGrid;
