import React, { cloneElement } from 'react';
import clsx from 'clsx';
import * as styles from './css/button.css';
import { Spinner } from '../spinner/spinner';
import { ButtonGroupProps, useButtonGroupStyle } from './button-group';
import { useButtonType } from './utils/use-button-type';
import { focusOutlineVisible, inputOffsetOutline } from '../../system/styles.css';
import { useMergedRef } from '../../system/hooks/use-merge-ref';
import { __DEV__ } from '../../system/utils/assertion';
import { dataAttr } from '../../system/utils/dom';
import { PolymorphicComponent, polymorphicComponent } from '../../system/utils/polymorphic';
import { Box } from '../../system/components/box/box';

export type ButtonType = 'button' | 'reset' | 'submit';

export type ButtonSpecificProps = {
  isLoading?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  loadingText?: string;
  type?: ButtonType;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  isFullWidth?: boolean;
  isTabbable?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onChange?: React.ChangeEventHandler<HTMLButtonElement>;
};

export type ButtonProps = ButtonSpecificProps & Omit<ButtonGroupProps, 'onChange'>;

export const Button: PolymorphicComponent<'button', ButtonProps> = polymorphicComponent((props, ref) => {
  const styleVariants = useButtonGroupStyle(props);
  const { size, variant, shape, palette } = styleVariants;
  const {
    as = 'button',
    isDisabled,
    isLoading,
    isActive,
    loadingText,
    type,
    leftIcon,
    rightIcon,
    isFullWidth,
    children,
    className,
    isTabbable,
    ...rest
  } = props;

  const { ref: _ref, type: _defaultType } = useButtonType(as);
  const mergedRef = useMergedRef(ref, _ref);

  return (
    <Box
      as={as}
      {...rest}
      className={clsx(
        styles.button,
        styles.buttonRecipe({ size, variant, shape, palette }),
        inputOffsetOutline,
        isFullWidth && styles.buttonFullWidth,
        isTabbable && focusOutlineVisible,
        className
      )}
      disabled={isDisabled || isLoading}
      aria-disabled={dataAttr(isDisabled || isLoading)}
      data-active={dataAttr(isActive)}
      data-loading={dataAttr(isLoading)}
      type={type}
      ref={mergedRef}
    >
      {/** Left Icon */}
      {leftIcon && !isLoading ? cloneElement(leftIcon, { className: styles.buttonLeftIcon }) : null}

      {/** Loading Spinner Icon */}
      {isLoading && <Spinner className={styles.buttonLeftIcon} />}

      {/** Button Content */}
      {isLoading ? loadingText || children : children}

      {/** Right Icon */}
      {rightIcon && !isLoading ? cloneElement(rightIcon, { className: styles.buttonRightIcon }) : null}
    </Box>
  );
});

if (__DEV__) {
  Button.displayName = 'Button';
}
