import {
  InvestorOrderStatus,
  TimeInForce,
  type VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment
} from '@oms/generated/frontend';
import { t } from '@oms/codegen/translations';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { openConfirmation } from '@app/generated/sdk';
import { PROCESS_ID } from '@valstro/workspace';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { openMessageDialog } from '@app/common/dialog/dialog.common';

const isDoneForDayVisible = (rowData?: IOFragment): boolean =>
  rowData !== undefined &&
  rowData?.status === InvestorOrderStatus.Active &&
  rowData?.timeInForce !== TimeInForce.Day &&
  rowData?.timeInForce !== TimeInForce.Ioc;

const getTitle = () => t('app.orders.doneForDay.doneForDay');

export const investorOrderDoneForDayOnChange = async (
  ctx: ActionContext<IOFragment, ActionComponentConfig<IOFragment>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];
  const isMoreThanOneRowSelected = data.length > 1;

  ctx.notify({ isDisabled: !isDoneForDayVisible(selectedRow) || isMoreThanOneRowSelected });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }

    const orderService = ctx.appContainer.resolve(InvestorOrdersService);

    try {
      const [_, api] = await openConfirmation(ctx.workspace, PROCESS_ID.LEADER, {
        title: t('app.orders.doneForDay.doneForDay'),
        componentProps: {
          autoClose: true,
          message: t('app.orders.doneForDay.doneForDayMessage'),
          confirmButtonText: t('app.common.yes')
        }
      });
      const event = await api.awaitFirstEvent;
      switch (event.type) {
        case DIALOG_EVENT_TYPE.OK: {
          ctx.notify({ isLoading: true, rowData: selectedRow });
          const resp = await orderService.sendDoneForDay(selectedRow.id);
          if (resp.isSuccess()) {
            ctx.notify({ isLoading: false, isDisabled: true, rowData: selectedRow });
          } else {
            ctx.notify({ isLoading: false, rowData: selectedRow });
            const msgs = resp?.errors.map((e) => e.message).join(', ');
            openMessageDialog(`Error: ${msgs}`, ctx.workspace).catch(console.error);
            throw new Error(msgs);
          }
          break;
        }
      }
    } catch (e) {
      ctx.notify({ isLoading: false, rowData: selectedRow });
      openMessageDialog(`Error: ${e}`, ctx.workspace).catch(console.error);
      console.error(e);
    }
  }
};

export const investorOrderDoneForDayAction: ActionDefFactory<IOFragment> = (builder) =>
  builder
    .name('investor_order_done_for_day')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('investor_order_done_for_day_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: getTitle()
        })
    )
    .menu((m) => m.name('Done For Day').visible(({ rowData }) => isDoneForDayVisible(rowData)))
    .onChange<ActionComponentConfig<IOFragment>>(investorOrderDoneForDayOnChange);
