import {
  InvestorOrderStatus,
  type VisibleInvestorOrderInfoWithAllocationsFragment
} from '@oms/generated/frontend';
import { useVGrid, VGrid, CLEAR_GRID_FILTER_ACTION_TYPE } from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { InvestorOrderMonitorDatasourceService } from '../investor-order-monitor/investor-order-monitor.datasource.service';
import { newOrdersColumnLibrary } from './new-orders.columns';
import { inlineNewOrdersActions } from './grid-actions/inline.new-orders.action';
import { broadcastGridSelection } from '@app/data-access/memory/grid.events';
import { refreshServersideAction } from '@app/common/grids/actions/refresh-serverside.action';

export const NEW_ORDERS = 'new-orders';

export const NewOrdersGridWidget = () => {
  const datasourceService = useService(InvestorOrderMonitorDatasourceService);

  const gridProps = useVGrid<VisibleInvestorOrderInfoWithAllocationsFragment>(
    NEW_ORDERS,
    (b) =>
      b
        .columnLibrary(newOrdersColumnLibrary)
        .datasource((d) =>
          d
            .source(
              datasourceService.createServerSideDatasource({
                filterOverrides: {
                  status: {
                    in: [InvestorOrderStatus.Unaccepted]
                  }
                }
              })
            )
            .rowId((r) => r.data.id || '')
        )
        .rowSelection((c) => c.single().broadcast(broadcastGridSelection()).selectRowsOnFirstDataRender([0]))
        .rowStateRules({
          takeAction: (params) => params.data?.status === InvestorOrderStatus.Unaccepted
        })
        .sideBar()
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(inlineNewOrdersActions)
                .action(refreshServersideAction)
            )
            .settings((s) => s.fromFile({}))
        ),
    [datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default NewOrdersGridWidget;
