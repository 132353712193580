import type { FieldValue } from '@oms/frontend-foundation';
import { type AnyFormValues } from '@oms/frontend-foundation';
import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';

const ALL_FIELDS_EVENT_TYPE = {
  VALUES_CHANGE: 'VALUES_CHANGE',
  RESET_VALUES: 'RESET_VALUES'
} as const;

export type ValuesChangeEvent = {
  type: typeof ALL_FIELDS_EVENT_TYPE.VALUES_CHANGE;
  payload: {
    fieldName: string;
    value: FieldValue;
    formValues: AnyFormValues;
  };
  meta: {
    renderStrategy: 'main-form' | 'dialog-form';
    formId: string;
    formType: string;
  };
};

export type ResetValuesEvent = {
  type: typeof ALL_FIELDS_EVENT_TYPE.RESET_VALUES;
  payload: {};
  meta: {
    formId: string;
  };
};

export type AllFieldsEventUnion = ValuesChangeEvent | ResetValuesEvent;

export const allFields$ = new BroadcastSubject<AllFieldsEventUnion>('all-fields');
