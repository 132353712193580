import { isEqual } from 'lodash';
import React, { type FC, useMemo } from 'react';
import { mapFixAtdlAdvancedListOptions, mapOptionToComboboxItem } from '../../utils';
import { type EditableDropdownListProps } from '../../fixatdl-form.types';
import { AdvancedSelectField, type FieldProps } from '@oms/frontend-foundation';

export const EditableDropdownList: FC<FieldProps<EditableDropdownListProps>> = React.memo(
  ({ initialValue, options = [], ...props }) => {
    const initialComboboxItem = useMemo(() => {
      const option = options.find((o) => o.enumID === initialValue);

      if (!option) {
        console.error(`Provided initial value '${initialValue}' doesn't match any option`);
        return undefined;
      }

      return mapOptionToComboboxItem(option);
    }, [initialValue, options]);

    return (
      <AdvancedSelectField
        {...props}
        initialValue={initialComboboxItem}
        options={mapFixAtdlAdvancedListOptions(options)}
        allowAnyValue={true}
        component="advanced-select"
      />
    );
  },
  isEqual
);
