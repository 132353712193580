import { type UseFieldApiConfig, type Validator, useFieldApi } from '@data-driven-forms/react-form-renderer';
import { Input, InputMulti, type InputProps, NumericInput } from '@oms/shared-frontend/ui-design-system';
import React, { useCallback, useMemo } from 'react';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { type ICommonField } from '../../types';

export interface InputMultiValue {
  input?: string;
  selected: string[];
}

export const inputComponentTypes = {
  NUMERIC: 'numeric',
  DEFAULT: 'default'
};

export const inputComponentMap = {
  [inputComponentTypes.DEFAULT]: Input,
  [inputComponentTypes.NUMERIC]: NumericInput
};

export interface CustomInput {
  component: string;
  props: any;
}

export interface IInputMultiField<TValidate = Validator>
  extends ICommonField<typeof FORM_COMPONENT_TYPE.INPUT_MULTI, InputMultiValue, TValidate> {}

export const InputMultiField: React.FC<UseFieldApiConfig> = (props) => {
  const { input, meta, inputComponent, componentMapper = inputComponentMap, ...rest } = useFieldApi(props);

  const customInput = useMemo(() => {
    return inputComponent as CustomInput;
  }, [inputComponent]);

  const currentValue = useMemo(() => {
    return (input.value || { selected: [] }) as InputMultiValue;
  }, [input]);

  const customInputCb = useCallback(
    (ip: InputProps) => {
      const Component = componentMapper[customInput.component];
      const customComponentProps = { ...customInput.props, ...ip };
      return <Component {...customComponentProps} />;
    },
    [componentMapper, customInput.component, customInput.props]
  );

  const updateInputOnChange = useCallback(
    (val: string) => {
      input.onChange({ ...currentValue, input: val });
    },
    [currentValue, input]
  );

  const inputProps = {
    ...input,
    ...{
      value: currentValue.selected,
      inputValue: currentValue.input,
      onChange: updateInputOnChange
    },
    ...rest
  };

  const onInputSelectionChange = useCallback(
    (values: string[]) => {
      input.onChange({ selected: values || [] });
    },
    [input]
  );

  return (
    <FieldWrapper {...rest}>
      <InputMulti input={customInputCb} onSelectionChange={onInputSelectionChange} {...inputProps} />
    </FieldWrapper>
  );
};
