import { delay, inject, Lifecycle, scoped } from 'tsyringe';
import { ActionsService } from '../services/actions.service';
import { EventHandler } from './event.handler';
import { EventSource, GridEvent } from './event.source';

@scoped(Lifecycle.ContainerScoped)
export class ActionsEventHandler implements EventHandler<any> {
  public name = 'actions-event-handler';

  private _actionsService: ActionsService<any>;

  constructor(@inject(delay(() => ActionsService)) actionsService: ActionsService<any>) {
    this._actionsService = actionsService;
  }

  public addEvents(eventSource: EventSource<keyof GridEvent, any>): void {
    eventSource
      .add('onGridReady', () => {
        this._actionsService.start();
      })
      .add('onRowDataUpdated', (e) => {
        this._actionsService.updateState({ lifecycle: 'onRowDataUpdated', event: e }).catch(console.error);
      })
      .add('onRowDoubleClicked', (e) => {
        this._actionsService.updateState({ lifecycle: 'onRowDoubleClicked', event: e }).catch(console.error);
      })
      .add('onSelectionChanged', (e) => {
        this._actionsService.updateState({ lifecycle: 'onSelectionChanged', event: e }).catch(console.error);
      })
      .add('onFilterChanged', (e) => {
        this._actionsService.updateState({ lifecycle: 'onFilterChanged', event: e }).catch(console.error);
      })
      .add('onRowDragEnd', (e) => {
        this._actionsService.updateState({ lifecycle: 'onRowDragEnd', event: e }).catch(console.error);
      })
      .add('onSortChanged', (e) => {
        this._actionsService.updateState({ lifecycle: 'onSortChanged', event: e }).catch(console.error);
      })
      .add('onStoreUpdated', (e) => {
        this._actionsService.updateState({ lifecycle: 'onStoreUpdated', event: e }).catch(console.error);
      })
      .add('onFirstDataRendered', (e) => {
        this._actionsService.updateState({ lifecycle: 'onFirstDataRendered', event: e }).catch(console.error);
      });
  }

  public removeEvents(): void {
    this._actionsService.destroy();
  }
}
