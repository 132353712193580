import {
  FormContract,
  FieldDefinition,
  type InferFormValuesFromFormContract,
  FieldContract
} from '@oms/frontend-foundation';
import type { RejectInvestorOrderFormSchemaInput } from './reject-investor-order.form-builder.config';
import type { RejectClientOrderMutationVariables } from '@oms/generated/frontend';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';

// Common
export type RejectInvestorOrderFormInput = RejectInvestorOrderFormSchemaInput;
export type RejectInvestorOrderFormOutput = RejectClientOrderMutationVariables;

// Fields
const ofc = FieldContract.create<RejectInvestorOrderFormOutput, AdditionalFormFieldUnion>();

export const idField = ofc.field('id', 'text-field').options({
  label: 'id',
  isRequired: true,
  isVisible: false,
  validate: [{ type: 'required' }]
});

export const reasonField = ofc.field('rejectText', 'text-field').options({
  label: 'Reject reason',
  isRequired: true,
  validate: [{ type: 'required' }]
});

// Form contract
export const rejectInvestorOrderFormContract = FormContract.create<RejectInvestorOrderFormOutput>()
  .fields({
    id: idField,
    rejectText: reasonField
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box('wrapper', [f.id, f.rejectText], {
        sx: {}
      })
    ]
  }))
  .template('simple', {
    submitLabel: 'Reject',
    cancelLabel: 'Cancel',
    formFieldsSx: { px: 4, pb: 2, marginTop: 4 }
  });

export type RejectInvestorOrderFormContractType = typeof rejectInvestorOrderFormContract;

export type RejectInvestorOrderFormValues =
  InferFormValuesFromFormContract<RejectInvestorOrderFormContractType>;
