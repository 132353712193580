import { type ActionDefFactory } from '@oms/frontend-vgrid';
import { openRouteOrder } from '@app/generated/sdk';
import { type AllActionContextEventsRecord } from '@app/actions/events/types';
import { COMMANDS_TO_FORMS } from '@app/actions/types/action.types';
import { FORM_MAP } from '@app/generated/mappers';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { type FIXatdlStrategyValue } from '@app/widgets/trading/route-order/fixatdl-strategy-field/fixatdl-strategy-field.contracts';
import {
  type RouteOrderFormOutput,
  type RouteOrderFormInfo
} from '@app/widgets/trading/route-order/route-order.form-common';
import {
  type FormRendererEventBase,
  type ComboBoxItem,
  type GQLMutationEnvelopeShape,
  GQLResult,
  extractGQLEnvelope
} from '@oms/frontend-foundation';
import {
  type InstrumentDetailsSimpleFragment,
  type OrderSideType,
  type TimeInForce,
  type CurrencyFragment,
  type RepresentativeCodeInfoFragment,
  type OrderSettleType,
  type TagFragment
} from '@oms/generated/frontend';
import { type FeedbackWrapper } from '@oms/frontend-foundation';
import { CREATE_UNBOUND_TO_ACTION_NAME } from './create-unbound-trading-order.action.types';
import {
  type ActionCommandConfig,
  type ActionCommandContracts
} from '@app/actions/commands/command.registry.types';

// how do we allow users to use either the actionContext, or the selected items in the grid
// i.e. montage would cancel ios via actionContext$; other grids might look at the selected rows.
export const CREATE_UNBOUND_TO_ACTION: ActionDefFactory = (b) =>
  b
    .name(CREATE_UNBOUND_TO_ACTION_NAME)
    .toolbar((t) =>
      t
        .location('LeftVerticalToolbar')
        .component('action-button')
        .id('left_create_unbound_to_button')
        .props({ variant: 'primary', content: 'Unbound TO' })
    )
    .lifecycles('change')
    .onChange(async (e) => {
      const { config, notify, container } = e;
      const createToConfig = config as unknown as ActionCommandConfig<
        ActionCommandContracts['create_unbound_to']
      >;
      const { payload, actionContext$, allowsConfirmation = false, confirmation = true } = createToConfig;
      const shouldCallMutation = allowsConfirmation && !confirmation;
      const gridOverrides: AllActionContextEventsRecord['create_unbound_to'] =
        actionContext$?.getCommand('create_unbound_to') || {};
      let feedback: GQLMutationEnvelopeShape<any>['feedback'] = [];

      notify({ isLoading: true });

      const initialValues: AllActionContextEventsRecord['create_unbound_to'] = {
        ...gridOverrides,
        ...payload,
        hiddenFormInfo: {
          type: 'create',
          options: {
            instrumentId: (gridOverrides || payload)?.instrument?.id || '',
            instrumentDisplayCode: (gridOverrides || payload)?.instrument?.value?.mappings?.displayCode || '',
            category: (gridOverrides || payload)?.category
          }
        },
        matchedInvestorOrderIds: []
      };

      if (shouldCallMutation) {
        const appWorkspace = container.resolve(AppWorkspace);
        const formId = COMMANDS_TO_FORMS['create_unbound_to'] as 'ROUTE_ORDER';
        const formBuilder = FORM_MAP[formId];
        const form = formBuilder.build();
        const formOutput = await form.sanitizer.output(initialValues, {
          ...form,
          formId: form.formBuilderId,
          formSaveType: 'UNKNOWN',
          container
        });

        if (formOutput) {
          const apiResult = await form.change(
            {
              type: 'SUBMIT',
              payload: {
                formValues: payload,
                output: formOutput,
                modifiedFields: []
              },
              meta: {
                ...form,
                formId: form.formBuilderId,
                formSaveType: 'UNKNOWN',
                windowId: '',
                widgetId: ''
              }
            },
            {
              notify: function (
                _event: FormRendererEventBase<
                  RouteOrderFormOutput,
                  {
                    hiddenFormInfo?: RouteOrderFormInfo | undefined;
                    hiddenStrategyOptions?: string[] | undefined;
                    instrument?: ComboBoxItem<InstrumentDetailsSimpleFragment> | undefined;
                    venue?: ComboBoxItem<string> | undefined;
                    trader?: ComboBoxItem<string> | undefined;
                    sideType?: OrderSideType | undefined;
                    quantity?: string | number | undefined;
                    timeInForce?: TimeInForce | null | undefined;
                    gtdTimestamp?: string | null | undefined;
                    locate?: string | undefined;
                    strategy?: ComboBoxItem<FIXatdlStrategyValue> | undefined;
                    limitPrice?: string | number | undefined;
                    customerNotes?: string | undefined;
                    tradeCurrency?: ComboBoxItem<CurrencyFragment> | undefined;
                    repCode?: ComboBoxItem<RepresentativeCodeInfoFragment> | undefined;
                    settlementType?: OrderSettleType | undefined;
                    settlementDate?: string | null | undefined;
                    orderTags?: ComboBoxItem<TagFragment>[] | undefined;
                    matchedInvestorOrderIds?: string[] | undefined;
                  }
                >
              ): void {},
              container,
              workspace: appWorkspace
            }
          );

          if (apiResult instanceof GQLResult) {
            apiResult.mapSync(
              (data) => {
                const envelope = extractGQLEnvelope(data);
                if (envelope?.feedback?.length) feedback = envelope?.feedback;
              },
              {
                ENVELOPE_FEEDBACK_ERROR: (errors) => {
                  if (errors.length) feedback = errors;
                }
              },
              (remainingErrors) => {
                feedback = feedback || [];
                feedback.push(
                  ...remainingErrors.map(
                    (e: Error) =>
                      ({
                        code: e.name,
                        level: 'Error',
                        message: e.message
                      } as FeedbackWrapper)
                  )
                );
              }
            );
          }
        } else {
          feedback.push({
            code: 'MISSING_REQUIRED_FIELDS',
            level: 'Error',
            message: 'Missing required fields.'
          });
        }
      }

      if (feedback.length || !shouldCallMutation) {
        try {
          await openRouteOrder(undefined, {
            form: {
              initialValues,
              initialFeedback: feedback
            }
          });
        } catch (e: unknown) {
          console.error('create unbound to command error', e);
          notify({ isLoading: false });
        }
      }
      notify({ isLoading: false });
    });
