import type { Observable } from 'rxjs';
import type { Identifiable, Optional } from '@oms/shared/util-types';
import PairedValueState, { type PairedValueStateObject } from './paired-value-state.class';

/**
 * We have to approximate this as the actual type is
 * derived internally within the form builder.
 */
type PositionsTransferInternalFormValues = {
  sellFirmAccount?: Identifiable;
  buyFirmAccount?: Identifiable;
};

/**
 * Keeps state for buy/sell firm accounts for the Positions service.
 * This stores values set by the Positions Transfer form.
 */
export class PositionTransferFormState extends PairedValueState<
  string,
  string,
  PositionsTransferInternalFormValues
> {
  public get sellerId(): Optional<string> {
    return this.valueA;
  }

  public get sellerId$(): Observable<Optional<string>> {
    return this.valueA$;
  }

  public get buyerId(): Optional<string> {
    return this.valueB;
  }

  public get buyerId$(): Observable<Optional<string>> {
    return this.valueB$;
  }

  /**
   * Updates state based on the Positions Transfer form
   *
   * @param formValues - Pass form values from the Positions Transfer form on update
   * @returns The current state as a object
   */
  public override update(
    formValues: PositionsTransferInternalFormValues
  ): PairedValueStateObject<string, string> {
    const { sellFirmAccount, buyFirmAccount } = formValues;
    if (sellFirmAccount?.id !== this.sellerId) {
      this.updateA(sellFirmAccount?.id);
    }
    if (buyFirmAccount?.id !== this.buyerId) {
      this.updateB(buyFirmAccount?.id);
    }
    return this.current;
  }
}

export default PositionTransferFormState;
