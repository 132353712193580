/* eslint-disable react/jsx-no-bind */
import React, { PropsWithChildren, Component } from 'react';
import { Rnd, Props as RndProps, RndDragCallback, RndResizeCallback } from 'react-rnd';
import clsx from 'clsx';
import * as styles from './styles.css';

export type DraggableDialogDragCallback = RndDragCallback;
export type DraggableDialogResizeCallback = RndResizeCallback;
export type DraggableDialogInitialBoundsChangedCallback = (options: {
  width: number;
  height: number;
  x: number;
  y: number;
}) => void;

export interface DraggableDialogProps extends RndProps {
  isMaximized?: boolean;
  getMaximizedSize?: () => { width: number; height: number };
  getMaximizedPos?: () => { x: number; y: number };
  initX?: number;
  initY?: number;
  initWidth?: number;
  initHeight?: number;
  order?: number;
  initialBoundsChanged?: DraggableDialogInitialBoundsChangedCallback;
  innerRef: any;
}

export interface DraggableDialogState {
  width: number;
  height: number;
  x: number;
  y: number;
  minWidth?: number | string;
  minHeight?: number | string;
}

export class DraggableDialogPrimitive extends Component<
  PropsWithChildren<DraggableDialogProps>,
  DraggableDialogState
> {
  defaultGetMaximizedSize = () => ({ width: window.innerWidth, height: window.innerHeight });
  defaultGetMaximizedPos = () => ({ x: 0, y: 0 });

  constructor(props: PropsWithChildren<DraggableDialogProps>) {
    super(props);
    this.state = this.getBounds();
  }

  resetBounds() {
    const boundsState = this.getBounds();
    this.setState({ ...boundsState });
  }

  getBounds() {
    const {
      initHeight = 600,
      initWidth = 800,
      initX,
      initY,
      minWidth: _minWidth,
      minHeight: _minHeight
    } = this.props;

    let boundsState = {
      width: initWidth,
      height: initHeight,
      x: initX !== undefined ? initX : window.innerWidth / 2 - initWidth / 2,
      y: initY !== undefined ? initY : window.innerHeight / 2 - initHeight / 2,
      minWidth: _minWidth,
      minHeight: _minHeight
    };

    // Ensure the window fits
    let boundsChanged = false;
    let { width, height, x, y, minHeight, minWidth } = boundsState;

    if (width > window.innerWidth) {
      boundsChanged = true;
      width = window.innerWidth;
      minWidth = window.innerWidth;
      x = 0;
    }

    if (height > window.innerHeight) {
      boundsChanged = true;
      height = window.innerHeight;
      minHeight = window.innerHeight;
      y = 0;
    }

    if (boundsChanged && this.props.initialBoundsChanged) {
      boundsState = {
        width,
        height,
        x,
        y,
        minWidth,
        minHeight
      };

      this.props.initialBoundsChanged({ width, height, x, y });
    }

    return boundsState;
  }

  setRef = () => {
    this.props.innerRef(this);
  };

  render() {
    const {
      onDragStop,
      onResizeStop,
      isMaximized,
      getMaximizedSize = this.defaultGetMaximizedSize,
      getMaximizedPos = this.defaultGetMaximizedPos,
      children,
      className,
      innerRef,
      order = 0,
      style = {},
      initHeight: _initHeight,
      initWidth: _initWidth,
      initX: _initX,
      initY: _initY,
      minWidth: _minWidth,
      minHeight: _minHeight,
      initialBoundsChanged: _initialBoundsChanged,
      ...rest
    } = this.props;
    const { width, height, x, y, minHeight, minWidth } = this.state;
    const size = isMaximized ? getMaximizedSize() : { width, height };
    const position = isMaximized ? getMaximizedPos() : { x, y };
    return (
      <Rnd
        ref={this.setRef}
        className={clsx(styles.draggableDialog, className)}
        size={size}
        position={position}
        onDragStop={(e, d) => {
          this.setState({ x: d.x, y: d.y });
          if (onDragStop) {
            onDragStop(e, d);
          }
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          this.setState({
            width: ref.offsetWidth,
            height: ref.offsetHeight,
            ...position
          });
          if (onResizeStop) {
            onResizeStop(e, direction, ref, delta, position);
          }
        }}
        style={{ ...style, zIndex: order }}
        minHeight={minHeight}
        minWidth={minWidth}
        {...rest}
      >
        {children}
      </Rnd>
    );
  }
}

export const DraggableDialog = React.forwardRef<DraggableDialogPrimitive, DraggableDialogProps>(
  (props, ref) => <DraggableDialogPrimitive innerRef={ref} {...props} />
);
