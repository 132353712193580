import { useDeepMemo } from '@oms/shared-frontend/ui-design-system';
import { useService } from '@oms/frontend-foundation';
import { AuthService } from './auth.service';
import { hasRequiredRoles } from '@app/common/auth/keycloak.helpers';
import type { EntitlementOperator, ValstroEntitlement } from '@app/common/auth/keycloak.types';
import { useObservableState } from 'observable-hooks';

export function useUserRoles() {
  const auth = useAuthState();
  return useDeepMemo(
    () =>
      (requiredRoles: ValstroEntitlement[], operator: EntitlementOperator = 'any') =>
        hasRequiredRoles(auth.roles, requiredRoles, operator),
    [auth]
  );
}

export function useAuthState() {
  const service = useService(AuthService);
  return useObservableState(service.$, service.state);
}

export function useUser() {
  const service = useService(AuthService);
  return service.currentUser;
}

export function useUser$() {
  const service = useService(AuthService);
  return service.currentUser$;
}
