import type { PropsWithChildren } from 'react';
import { createContext, useContext, useState } from 'react';
import type { IAllFields } from './all-fields.component';
import type { IEnrichedField } from '../enriched-field/enriched-field.component';

export type AllFieldsContext = {
  fields: IEnrichedField[][];
  fixedFields: string[];
  columns?: number;
  groups?: string[];
  renderStrategy?: IAllFields['renderStrategy'];
  showIcons?: boolean;
  formType: string;
  formId: string;
};

type AllFieldsContextProps = {
  context: AllFieldsContext;
  setContext: React.Dispatch<React.SetStateAction<AllFieldsContext>>;
};

const AllFieldsContext = createContext<AllFieldsContextProps | undefined>(undefined);

export const AllFieldsContextProvider: React.FC<PropsWithChildren<AllFieldsContext>> = ({
  children,
  ...initialState
}) => {
  const [context, setContext] = useState<AllFieldsContext>(initialState);

  return <AllFieldsContext.Provider value={{ context, setContext }}>{children}</AllFieldsContext.Provider>;
};

export const useAllFieldsContext = (): AllFieldsContextProps => {
  const context = useContext(AllFieldsContext);
  if (!context) {
    throw new Error('useAllFieldsContext must be used within the AllFieldsContextProvider');
  }
  return context;
};
