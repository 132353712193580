import type { InvestorOrderStatus } from '@oms/generated/frontend';
import type { Maybe } from '@oms/shared/util-types';
import { orderStatusMapping } from './order-enums';

export const mapOrderStatus = (
  value?: Maybe<InvestorOrderStatus> | string,
  defaultVal = value || ''
): string => {
  return value && orderStatusMapping[value as InvestorOrderStatus]
    ? orderStatusMapping[value as InvestorOrderStatus]
    : defaultVal;
};
