import { Box } from '@oms/shared-frontend/ui-design-system';
import { type FC } from 'react';
import { MontageFiltersFormWidget } from './montage-filters.form/montage-filters.form-widget';
import { useMontageToolbarCarousel } from '../useMontageToolbarCarousel.hooks';
import { MontageActionsPanel } from './montage-actions-panel';

export const CLASS_NAMES = {
  MontageToolbarWidget: 'montage-toolbar-widget'
} as const;

export const MontageToolbarWidget: FC = () => {
  useMontageToolbarCarousel();

  return (
    <Box
        className={CLASS_NAMES.MontageToolbarWidget}
        sx={{
          position: 'relative',
          width: 'full',
          borderBottomWidth: '4px',
          borderBottomStyle: 'solid',
          borderColor: 'layout.backdrop'
        }}
        style={{ height: '50px' }}
      >
        <MontageFiltersFormWidget />
        <MontageActionsPanel />
      </Box>
  );
};

export default MontageToolbarWidget;
